import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomBonus = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 512 512'
		className='svg-icon'
		{...props}>
		<path
			d='m152.288 283.696-89.84 107.208 56.688-.096 9.376 56.4 90.304-107.76z'
			style={{
				fill: '#333',
			}}
		/>
		<path
			d='m218.816 339.448-90.304 107.76-9.376-56.4 66.416-79.24z'
			style={{
				fill: '#666',
			}}
		/>
		<path d='M340.264 251.032v259.624l-80.552-67.728L179.16 512V251.032z' />
		<path
			d='M179.16 251.032V512l80.552-69.072V251.032z'
			style={{
				fill: '#333',
			}}
		/>
		<path
			d='m449.552 190.4-49.848-40.184 32-55.592-67.632-5.912-5.888-65.056-56.952 27.2L262.112 0l-36.8 52.048-59.336-30.76-3.56 67.424-66.448 3.552 24.92 63.872-51.024 30.752 52.248 39.032-26.104 55.592 65.256 7.096 3.56 66.24 60.488-28.384 40.344 53.224 35.592-53.224 55.768 27.2 4.744-66.24 65.256-1.184-23.728-54.4 46.264-41.44z'
			style={{
				fill: '#e21b1b',
			}}
		/>
		<circle
			cx={259.712}
			cy={189.384}
			r={128.32}
			style={{
				fill: '#fff',
			}}
		/>
		<path
			d='M259.712 84.8c57.752 0 104.576 46.824 104.576 104.576s-46.824 104.576-104.576 104.576-104.568-46.816-104.576-104.568c.056-57.728 46.84-104.52 104.576-104.584m0-8c-62.176 0-112.576 50.4-112.576 112.576s50.4 112.576 112.576 112.576c62.168 0 112.568-50.4 112.576-112.568 0-62.176-50.4-112.576-112.576-112.584z'
			style={{
				fill: '#ccc',
			}}
		/>
		<path d='m277.168 165.224-17.456-53.72-17.464 53.72h-56.496l45.712 33.2L214 252.168l45.712-33.224 45.712 33.224-17.472-53.744 45.712-33.2z' />
	</svg>
);
export default SvgCustomBonus;
