import * as React from 'react';
import { SVGProps } from 'react';
const SvgSportIconBallHockey = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		className='svg-icon'
		viewBox='0 0 1024 1024'
		{...props}>
		<path
			fill='#B8CBCD'
			d='M897.752 884.168c0 45-174.744 86.504-385.344 86.504-210.608 0-377.344-41.504-377.344-86.504 0-45.008 166.728-81.504 377.344-81.504 210.6.008 385.344 36.496 385.344 81.504z'
		/>
		<path
			fill='#F7BB83'
			d='M587.632 108.12a32 32 0 0 1 57 29.112l-271.656 623.88a51.96 51.96 0 0 1-48.568 33.56h-216a52 52 0 1 1 0-104h208L587.632 108.12z'
		/>
		<path
			fill='#6E4123'
			d='M324.408 810.672h-216c-37.496 0-68-30.504-68-68s30.504-68 68-68h197.8l266.92-573.304c8.504-16.664 24.888-26.696 43.032-26.696a48.08 48.08 0 0 1 21.808 5.264 47.672 47.672 0 0 1 23.832 27.96 47.656 47.656 0 0 1-2.68 36.144L387.648 767.496c-9.68 25.544-35.208 43.176-63.24 43.176zm-216-104c-19.848 0-36 16.152-36 36s16.152 36 36 36h216a36.184 36.184 0 0 0 33.616-23.24l271.944-624.584c.128-.304.272-.6.416-.888a15.92 15.92 0 0 0 .976-12.208 15.904 15.904 0 0 0-7.944-9.32c-7.768-3.976-17.632-.656-21.528 6.968L330.912 697.424a16 16 0 0 1-14.504 9.248h-208z'
		/>
		<path fill='#FFF' d='M217.184 794.672h-99.28l56.728-104h99.272z' />
		<path
			fill='#6E4123'
			d='M217.184 810.672h-99.28a16 16 0 0 1-14.048-23.664l56.728-104a16 16 0 0 1 14.048-8.336h99.272a16 16 0 0 1 14.048 23.664l-56.728 104a15.976 15.976 0 0 1-14.04 8.336zm-72.32-32h62.824l39.272-72h-62.824l-39.272 72z'
		/>
		<path
			fill='#F7BB83'
			d='M708.408 690.672h208a52 52 0 1 1 0 104h-216a51.952 51.952 0 0 1-48.568-33.56l-271.656-623.88a32 32 0 1 1 57-29.112l271.224 582.552z'
		/>
		<path
			fill='#6E4123'
			d='M916.408 810.672h-216c-28.032 0-53.56-17.632-63.528-43.88l-271.192-622.76a47.68 47.68 0 0 1-2.672-36.144 47.696 47.696 0 0 1 23.832-27.96 48.184 48.184 0 0 1 21.808-5.256 47.816 47.816 0 0 1 42.776 26.176L718.608 674.68h197.8c37.496 0 68 30.504 68 68s-30.504 67.992-68 67.992zm-507.752-704c-2.528 0-4.968.592-7.256 1.76a15.904 15.904 0 0 0-7.944 9.32 15.92 15.92 0 0 0 .976 12.208c.152.296.288.592.416.888L666.504 754.72c5.568 14.608 19.072 23.952 33.904 23.952h216c19.848 0 36-16.152 36-36s-16.152-36-36-36h-208a16 16 0 0 1-14.504-9.248L422.672 114.872c-2.496-4.856-7.96-8.2-14.016-8.2z'
		/>
		<path fill='#FFF' d='M814.632 794.672h99.272l-56.72-104h-99.28z' />
		<path
			fill='#6E4123'
			d='M913.904 810.672h-99.272a16 16 0 0 1-14.048-8.336l-56.728-104a16.016 16.016 0 0 1 14.048-23.664h99.272a16 16 0 0 1 14.048 8.336l56.728 104a16.016 16.016 0 0 1-14.048 23.664zm-89.768-32h62.824l-39.272-72h-62.824l39.272 72z'
		/>
		<path
			fill='#B9E3ED'
			d='M408.408 902.672c0 19.88 57.184 36 108 36s108-16.12 108-36v-64c0-19.88-57.184-36-108-36s-108 16.12-108 36v64z'
		/>
		<path
			fill='#324654'
			d='M516.408 962.672c-36.712 0-132-10.784-132-60v-64c0-49.216 95.288-60 132-60s132 10.784 132 60v64c0 49.216-95.288 60-132 60zm-84-65.184c9.824 6.696 43.208 17.184 84 17.184s74.168-10.496 84-17.184v-53.624c-9.832-6.696-43.208-17.184-84-17.184s-74.176 10.496-84 17.184v53.624z'
		/>
		<path
			fill='#324654'
			d='M516.408 890.672c-47.944 0-124-14.12-124-52a16 16 0 0 1 31.928-1.536c6.008 7.704 44.848 21.536 92.072 21.536 47.224 0 86.064-13.832 92.072-21.536a16 16 0 0 1 31.928 1.536c0 37.88-76.056 52-124 52z'
		/>
		<path
			fill='#6E4123'
			d='M616.16 90.672a32 32 0 0 1 28.472 46.568l-93.704 215.2 157.48 338.232h208a52 52 0 1 1 0 104h-216a51.952 51.952 0 0 1-48.568-33.56L512.408 440.896 372.976 761.112a51.96 51.96 0 0 1-48.568 33.56h-216a52 52 0 1 1 0-104h208l157.48-338.24-93.704-215.2a32 32 0 0 1 56.992-29.12l75.232 161.576 75.224-161.576a32.016 32.016 0 0 1 28.528-17.44m0-32a63.76 63.76 0 0 0-57.024 34.888l-.512 1.048-46.216 99.272-46.216-99.272-.512-1.048a63.744 63.744 0 0 0-57.016-34.888 64.2 64.2 0 0 0-29.088 7.016 63.6 63.6 0 0 0-31.776 37.28 63.552 63.552 0 0 0 3.376 47.8L438.8 352 296.008 658.672h-187.6c-46.32 0-84 37.68-84 84s37.68 84 84 84h216c34.352 0 65.656-21.424 78.176-53.408l109.824-252.208 109.816 252.208c12.528 31.984 43.832 53.408 78.184 53.408h216c46.32 0 84-37.68 84-84s-37.68-84-84-84h-187.6l-142.784-306.68 87.624-201.232a63.568 63.568 0 0 0 3.376-47.808 63.576 63.576 0 0 0-31.776-37.28 64.344 64.344 0 0 0-29.088-7z'
		/>
	</svg>
);
export default SvgSportIconBallHockey;
