import * as React from 'react';
import { SVGProps } from 'react';
const SvgBtm = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#504C4C' />
			<path
				fill='#FFF'
				d='m10.827 15.376-1.745 3.698.812 3.032 2.066.552 2.564 2.567-.772.775-6.112-1.64L6 18.248l1.23-1.228 5.342-5.343-1.745 3.699-1.745 3.698 5.442-5.442-1.952-1.955-1.745 3.699zm2.805 2.1 5.442 5.442 3.032-.812.554-2.066 2.565-2.564.775.772-1.638 6.114L18.248 26l-6.569-6.572 1.953-1.952zm4.736-2.952-5.442-5.442-3.034.812-.552 2.063-2.565 2.567L6 13.752l1.64-6.114L13.752 6l1.228 1.23 5.34 5.34-1.952 1.954zM20.04 9.34l-2.564-2.565.772-.775 6.114 1.638L26 13.752l-6.572 6.569-1.952-1.953 5.444-5.444-.814-3.032-2.066-.552z'
			/>
		</g>
	</svg>
);
export default SvgBtm;
