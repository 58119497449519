import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomGameList = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		aria-hidden='true'
		className='svg-icon'
		viewBox='0 0 64 64'
		{...props}>
		<path
			fill='#333'
			d='M64 53.8c0 2.6-1.7 4.6-3.9 4.6H3.9c-2.1 0-3.9-2.1-3.9-4.6V13.5C0 11 1.7 8.9 3.9 8.9h56.3c2.1 0 3.9 2.1 3.9 4.6L64 53.8'
		/>
		<path fill='#b2b2b2' d='M3.9 55h17c-1.8-13.8-1.8-28.7 0-42.5h-17C-.6 26.3-.6 41.1 3.9 55' />
		<path
			fill='#d0d0d0'
			d='M2.9 51.3h17.7c-1.2-11.5-1.2-23.7 0-35.2H2.9c-3.1 11.5-3.1 23.7 0 35.2'
		/>
		<path fill='#fff' d='M2 47.6h18.2c-.7-9.2-.7-18.6 0-27.7H2C.1 29 .1 38.4 2 47.6' />
		<path fill='#4187b7' d='M19.6 17H3.5c.4-1.5.8-3.1 1.3-4.6h15.3c-.2 1.6-.4 3.1-.5 4.6' />
		<path fill='#fbbf67' d='M19.7 55c-.4-2.8-4-5.2-8.3-5.2-4.3 0-7.2 2.4-6.3 5.2h14.6' />
		<path
			fill='#b2b2b2'
			d='M23.5 55h17c1.4-13.8 1.4-28.7 0-42.5h-17c-1.4 13.8-1.4 28.6 0 42.5'
		/>
		<path fill='#d0d0d0' d='M23.2 51.3h17.7c.9-11.5.9-23.7 0-35.2H23.2c-1 11.5-1 23.7 0 35.2' />
		<path fill='#fff' d='M22.9 47.6h18.2c.6-9.1.6-18.6 0-27.7H22.9c-.6 9.1-.6 18.5 0 27.7' />
		<path fill='#4187b7' d='M40 17H23.9c.1-1.5.2-3.1.4-4.6h15.3c.2 1.6.3 3.1.4 4.6' />
		<path fill='#fbbf67' d='M39.3 55c.3-2.8-3-5.2-7.3-5.2s-7.6 2.4-7.3 5.2h14.6' />
		<path fill='#b2b2b2' d='M43 55h17c4.5-13.8 4.5-28.7 0-42.5H43c1.8 13.8 1.8 28.6 0 42.5' />
		<path
			fill='#d0d0d0'
			d='M43.5 51.3h17.7c3.1-11.5 3.1-23.7 0-35.2H43.5c1.2 11.5 1.2 23.7 0 35.2'
		/>
		<path fill='#fff' d='M43.8 47.6H62c1.9-9.1 1.9-18.6 0-27.7H43.8c.8 9.1.8 18.5 0 27.7' />
		<path fill='#4187b7' d='M60.5 17H44.4c-.2-1.5-.4-3.1-.6-4.6h15.3c.6 1.6 1 3.1 1.4 4.6' />
		<path fill='#fbbf67' d='M58.9 55c.9-2.8-2-5.2-6.3-5.2s-8 2.4-8.3 5.2h14.6' />
		<path
			fill='#3390ff'
			d='M49.5 40.8c-.1 0-.3-.1-.4-.2s-.1-.3-.1-.4c0-.2.7-4.3 4.2-7.3-.4-.1-.8-.1-1.3-.1-1.7 0-2.8.6-3.1 1.8-.1.2-.3.4-.5.4h-1.4c-.3 0-.5-.2-.5-.5v-5.7c0-.3.2-.5.5-.5h1.4c.3 0 .5.2.5.5.9-.4 2.5-1 4.2-1 .8 0 1.5.1 2.2.3 1.3.4 1.8.8 2.2 1.1.1.1.2.1.2.1s.4-.3.5-.3c.1-.1.2-.1.3-.1h2.5c.2 0 .4.1.5.3.1.2 0 .4-.1.5 0 0-4.2 3.9-4.2 10.6 0 .3-.2.5-.5.5h-7.1'
		/>
		<path
			fill='#161616'
			d='M53.1 28.2c.7 0 1.3.1 2 .3 1.9.6 2.1 1.2 2.5 1.2.2 0 .4-.1.8-.4h2.5s-4.3 4-4.3 10.9h-7.1s.7-4.7 4.9-7.6c0 0-1.1-.3-2.5-.3-1.5 0-3.1.4-3.6 2.2h-1.4v-5.7h1.4v.8c0-.1 2.2-1.4 4.8-1.4m0-1c-1.6 0-3 .4-4 .8-.2-.2-.4-.3-.7-.3H47c-.6 0-1 .4-1 1v5.7c0 .6.4 1 1 1h1.4c.4 0 .8-.3 1-.7.1-.4.4-1.4 2.7-1.4h.1c-3 3-3.5 6.6-3.6 6.8 0 .3 0 .6.2.8.2.2.5.3.8.3h7.1c.6 0 1-.4 1-1 0-6.2 3.7-9.9 4-10.2.2-.2.3-.5.3-.8 0-.6-.4-1-1-1h-2.5c-.2 0-.4.1-.6.2 0 0-.2.1-.3.2-.4-.2-1-.7-2.2-1.1-.7-.2-1.5-.3-2.3-.3z'
		/>
		<path
			fill='#fff'
			d='M27.6 40.8c-.1 0-.3-.1-.4-.2-.1-.1-.1-.3-.1-.4 0-.2.7-4.3 4.2-7.3-.4-.1-.8-.1-1.3-.1-1.7 0-2.8.6-3.1 1.8-.1.2-.3.4-.5.4H25c-.3 0-.5-.2-.5-.5v-5.7c0-.3.2-.5.5-.5h1.4c.3 0 .5.2.5.5.9-.4 2.5-1 4.2-1 .8 0 1.5.1 2.2.3 1.3.4 1.8.8 2.2 1.1.1.1.2.1.2.1s.4-.3.5-.3c.1-.1.2-.1.3-.1H39c.2 0 .4.1.5.3.1.2 0 .4-.1.5 0 0-4.2 3.9-4.2 10.6 0 .3-.2.5-.5.5h-7.1'
		/>
		<path
			fill='#161616'
			d='M31.1 28.2c.7 0 1.3.1 2 .3 1.9.6 2.1 1.2 2.5 1.2.2 0 .4-.1.8-.4H39s-4.3 4-4.3 10.9h-7.1s.7-4.7 4.9-7.6c0 0-1.1-.3-2.5-.3-1.5 0-3.1.4-3.6 2.2H25v-5.7h1.4v.8c0-.1 2.2-1.4 4.7-1.4m0-1c-1.6 0-3 .4-4 .8-.2-.2-.4-.3-.7-.3H25c-.6 0-1 .4-1 1v5.7c0 .6.4 1 1 1h1.4c.4 0 .8-.3 1-.7.1-.4.4-1.4 2.7-1.4h.1c-3 3-3.5 6.6-3.6 6.8 0 .3 0 .6.2.8.2.2.5.3.8.3h7.1c.6 0 1-.4 1-1 0-6.2 3.7-9.9 4-10.2.2-.2.3-.5.3-.8 0-.6-.4-1-1-1h-2.5c-.2 0-.4.1-.6.2 0 0-.2.1-.3.2-.4-.2-1-.7-2.2-1.1-.6-.2-1.4-.3-2.3-.3z'
		/>
		<path
			fill='#ed4c5c'
			d='M5.7 40.8c-.1 0-.3-.1-.4-.2-.1-.1-.1-.3-.1-.4 0-.2.7-4.3 4.2-7.3-.4-.1-.8-.1-1.3-.1-1.7 0-2.8.6-3.1 1.8 0 .2-.2.4-.5.4H3.1c-.3 0-.5-.2-.5-.5v-5.7c0-.3.2-.5.5-.5h1.4c.3 0 .5.2.5.5.9-.4 2.5-1 4.2-1 .8 0 1.5.1 2.2.3 1.3.4 1.8.8 2.2 1.1.1.1.2.1.2.1s.4-.3.5-.3c.1-.1.2-.1.3-.1h2.5c.2 0 .4.1.5.3.1.2 0 .4-.1.5 0 0-4.2 3.9-4.2 10.6 0 .3-.2.5-.5.5H5.7'
		/>
		<path
			fill='#161616'
			d='M9.2 28.2c.7 0 1.3.1 2 .3 1.9.6 2.1 1.2 2.5 1.2.2 0 .4-.1.8-.4H17s-4.3 4-4.3 10.9h-7s.7-4.7 4.9-7.6c0 0-1.1-.3-2.5-.3-1.5 0-3.1.4-3.6 2.2H3.1v-5.7h1.4v.8c0-.1 2.2-1.4 4.7-1.4m0-1c-1.6 0-3 .4-4 .8-.2-.2-.4-.3-.7-.3H3.1c-.6 0-1 .4-1 1v5.7c0 .6.4 1 1 1h1.4c.4 0 .8-.3 1-.7.1-.4.4-1.4 2.7-1.4h.1c-3 3-3.5 6.6-3.6 6.8 0 .3 0 .6.2.8.2.2.5.3.8.3h7.1c.6 0 1-.4 1-1 0-6.2 3.7-9.9 4-10.2.2-.2.3-.5.3-.8 0-.6-.4-1-1-1h-2.5c-.2 0-.4.1-.6.2 0 0-.2.1-.3.2-.4-.2-1-.7-2.2-1.1-.7-.2-1.4-.3-2.3-.3z'
		/>
	</svg>
);
export default SvgCustomGameList;
