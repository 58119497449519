import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionGibraltar = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		viewBox='0 0 512 256'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#da000c' d='M0 0h512v256H0z' />
		<path fill='#fff' d='M0 0h512v171.5H0z' />
		<g stroke='currentColor'>
			<g id='regionGibraltar_svg__a' fill='#da000c' strokeLinecap='square'>
				<path fill='currentColor' stroke='none' d='M196.571 116.303h64v43.993h-64z' />
				<path d='M229.819 153.865H190.75l-8.66 5.7v6.04h47.729M213.469 128.594c5.625 0 10.219 4.689 10.219 10.469v14.78h7.343v-56H190.75v56h12.5v-14.78c0-5.554 4.515-10.47 10.219-10.47z' />
				<path
					fill='currentColor'
					stroke='none'
					d='M204.528 59.962h18.523v33.959h-18.523z'
				/>
				<path d='M223 88.656h-16.209v-5.75h-11.885v5.75h-8.062v-5.75h-4.813v10.438H223m-36.156-.038h34.97v4.538h-34.97z' />
				<path d='M200.719 47.625v35.281h6.072V73.22c0-3.63 2.562-6.6 6.147-6.781.115-.006.226 0 .343 0a6.78 6.78 0 0 1 6.781 6.78v9.688h5.782V47.625zM198.531 42.844v4.781h29.25v-4.781zM194.781 33.688v9.156h35.094v-9.157h-5.406v4.657h-6.531v-4.657h-9.97v4.657h-6.53v-4.657zM182.09 159.564H230M261 156.813h-32.438l-9.78 4.718v7.031H261' />
				<path strokeLinecap='butt' d='M218.771 161.52H262' />
			</g>
			<use xlinkHref='#regionGibraltar_svg__a' transform='matrix(-1 0 0 1 512 0)' />
			<g fill='#f8d80e'>
				<g strokeLinecap='round'>
					<path
						strokeWidth={0.768}
						d='M273.297 150.031c-2.654.883-5.053 2.369-7.469 3.753-3.1 1.831-6.107 3.827-8.904 6.099-.985.649-1.93 1.36-2.69 2.273-1.025.878-2.064 2.055-1.868 3.513.051.643.873-.755 1.414-.823a4.39 4.39 0 0 1 3.142-.471c1.271-1.339 2.91-2.236 4.325-3.406 3.004-2.243 6.236-4.168 9.525-5.961 1.148-.589 2.3-1.186 3.525-1.602l-1-3.375z'
					/>
					<path d='M260.4 157.391v3.912M262.817 155.685v3.883M265.233 154.18v3.858M267.65 152.694v3.981' />
					<path
						strokeWidth={0.768}
						d='m238.891 150.156-1.188 3.313c5.537 2.1 10.81 4.878 15.798 8.06 1.691 1.15 3.467 2.266 4.796 3.846.293.81-.514 1.489-1.314 1.244-.732-.249-1.453-.532-2.226-.004-1.107.503-2.106 2.141-.426 2.69 2.315 1.556 6.082.88 7.195-1.844.581-1.402.618-3.196-.508-4.345-2.063-2.364-4.797-4.008-7.421-5.672-4.69-2.82-9.573-5.374-14.706-7.288z'
					/>
					<path d='m254.05 158.035-.313 3.34M256.832 159.74l-.862 3.269M259.804 162.012l-1.808 2.537M260.715 168.18l-2.61-1.4M261.528 165.429l-2.995.28M257.665 169.765l-.205-2.633M256.568 166.756l-2.473 1.822M244.677 152.937v3.115M250.963 156.244v3.534M247.82 154.593v3.32' />
				</g>
				<path d='M235.781 227.563v8.03h5.031v-4.03h6.844v4.03h5.406v-8.03zm0 11.062v8.063h17.281v-8.063h-5.406v4.031h-6.844v-4.031z' />
				<path d='M253.052 193.651h4.99v58.115h-4.99z' />
				<path d='M253.052 198.679h4.99v50.06h-4.99z' />
				<path
					strokeWidth={1.024}
					d='m255.547 179.406 10.625 6.188-10.625 6.156-10.625-6.156zm-14.219 3.969h-2.781v4.438h2.781l14.219 8.25 14.219-8.25h2.78v-4.438h-2.78l-14.22-8.25z'
				/>
				<path
					strokeWidth={1.024}
					d='M255.344 164.281c-2.419.34-4.226 2.73-4.094 5.134.01 3.738-.018 7.476.014 11.214.129 2.408 2.233 4.566 4.675 4.588 1.985.188 3.979-.972 4.811-2.78l-1.718-1.031c-.444 1.184-1.724 1.918-2.97 1.781-1.634.046-2.958-1.544-2.812-3.134.01-3.713-.019-7.428.015-11.14.09-1.516 1.535-2.755 3.048-2.6.626-.18 1.487.676 1.875-.063.583-.83-.415-1.477-.97-1.937-.613-.085-1.253-.008-1.874-.032zm5.781 3.906c-.396.746-1.216 1.19-1.875 1.58v7.514l2 1.156c-.008-3.204.016-6.408-.011-9.611-.032-.197-.008-.499-.114-.639z'
				/>
			</g>
			<g fill='#da000c'>
				<path
					fill='currentColor'
					stroke='none'
					d='M240.803 38.351h29.329v53.255h-29.329z'
				/>
				<path d='M238.75 38.375v44.563h9.313v-13.22c0-3.048 1.952-7.28 7.937-7.28s7.938 4.232 7.938 7.28v13.22h9.312V38.374zm15.813 5h2.875v15.25h-2.875zm-8.25 3h2.906v11.156h-2.906zm16.468 0h2.906v11.156h-2.906zM235.594 31.969v6.343h40.812V31.97zM231.75 24.594v7.375h48.5v-7.375h-6.094v4.062h-6.875v-4.062h-7.844v4.062h-6.875v-4.062h-7.843v4.062h-6.875v-4.062zM222.75 97.844v4.594h66.5v-4.594z' />
				<path d='M220.031 82.906v14.938h71.938V82.906h-6.813v5.75h-9.062v-5.75h-12.156v5.75h-15.875v-5.75h-12.157v5.75h-9.062v-5.75z' />
				<path
					strokeLinejoin='round'
					d='M228.688 102.438v54.343h12.843v-20.344c0-9.582 6.397-14.093 14.469-14.093 7.759 0 14.469 4.51 14.469 14.094v20.343h12.844v-54.344z'
				/>
			</g>
		</g>
	</svg>
);
export default SvgRegionGibraltar;
