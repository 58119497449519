import * as React from 'react';
import { SVGProps } from 'react';
const SvgSportIconBoxing = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		preserveAspectRatio='xMidYMid'
		viewBox='0 0 255 255'
		className='svg-icon'
		{...props}>
		<defs>
			<linearGradient
				id='sportIconBoxing_svg__linear-gradient-1'
				x1={101.109}
				x2={101.109}
				y1={136.594}
				y2={-0.312}
				gradientUnits='userSpaceOnUse'>
				<stop offset={0} stopColor='#ff5134' />
				<stop offset={1} stopColor='#fd7507' />
			</linearGradient>
			<style>{'.sportIconBoxing_svg__cls-4{fill:#ff5134}'}</style>
		</defs>
		<g id='sportIconBoxing_svg__boxing_glove'>
			<path
				id='sportIconBoxing_svg__path-1'
				fillRule='evenodd'
				d='m133.871 8.803 22.673 21.142L54.926 136.917l-4.388-4.092c-3.232-3.013-3.768-6.59-3.953-11.89-.15-4.299-4.851-55.849 38.115-101.924 24.642-26.425 37.862-20.754 49.171-10.208Z'
				style={{
					fill: 'url(#sportIconBoxing_svg__linear-gradient-1)',
				}}
			/>
			<path
				id='sportIconBoxing_svg__path-2'
				fillRule='evenodd'
				d='m181.293 9.27 62.165 57.97c12.925 12.053 15.091 33.67 6.699 50.001-31.056 60.435-114.446 86.071-114.446 86.071-4.72 1.64-8.563 2.953-13.501-1.651l-71.672-66.836a8 8 0 0 1-.395-11.307l85.923-112.669c12.053-12.925 32.302-13.632 45.227-1.579Z'
				className='sportIconBoxing_svg__cls-4'
			/>
			<path
				id='sportIconBoxing_svg__path-3'
				fillRule='evenodd'
				d='m55.463 137.553 67.285 62.744a7.999 7.999 0 0 1 .394 11.306l-38.191 40.956a8 8 0 0 1-11.307.395L6.359 190.21a8 8 0 0 1-.395-11.307l38.192-40.955a7.999 7.999 0 0 1 11.307-.395Z'
				style={{
					fill: '#fc0231',
				}}
			/>
			<path
				id='sportIconBoxing_svg__path-4'
				fillRule='evenodd'
				d='m35.479 133.59 73.135 68.2a8 8 0 0 1 .395 11.307l-24.552 26.329a8 8 0 0 1-11.307.394L.015 171.621l35.464-38.031Z'
				className='sportIconBoxing_svg__cls-4'
			/>
			<path
				id='sportIconBoxing_svg__path-5'
				fillRule='evenodd'
				d='m30.023 139.441 69.478 64.79a5 5 0 0 1 .247 7.067l-17.732 19.015a5 5 0 0 1-7.067.247L5.471 165.77l24.552-26.329Z'
				style={{
					fill: '#1c8cea',
				}}
			/>
		</g>
	</svg>
);
export default SvgSportIconBoxing;
