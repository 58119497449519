import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar25 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar25_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>{'.avatar25_svg__cls-4{fill:#fbc0aa}.avatar25_svg__cls-9{fill:#515570}'}</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#00adfe',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				fill: '#356cb6',
				opacity: 0.3,
			}}
		/>
		<path
			d='M64 13.88a32 32 0 0 1 32 32v64.79H32V45.88a32 32 0 0 1 32-32Z'
			style={{
				fill: '#393c54',
			}}
		/>
		<circle cx={89} cy={61} r={7} className='avatar25_svg__cls-4' />
		<path
			d='M64 124a59.67 59.67 0 0 0 34.69-11.06l-3.32-9.3A10 10 0 0 0 86 97H42.05a10 10 0 0 0-9.42 6.64l-3.32 9.3A59.67 59.67 0 0 0 64 124Z'
			style={{
				fill: '#f85565',
			}}
		/>
		<path
			d='M73 98.25a9 9 0 0 1-18 0c0-5 4-3 9-3s9-1.97 9 3Z'
			style={{
				fill: '#fff',
			}}
		/>
		<path
			d='M64 84.75V98.5'
			style={{
				strokeWidth: 14,
				strokeLinejoin: 'round',
				stroke: '#fbc0aa',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={39} cy={61} r={7} className='avatar25_svg__cls-4' />
		<path
			d='M64 91a25 25 0 0 1-25-25V49.52a25 25 0 1 1 50 0V66a25 25 0 0 1-25 25Z'
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<path
			d='M36.51 52.12V47.4c0-14.95 11.71-27.61 26.66-28a27.51 27.51 0 0 1 28.32 27.42v-.24a2 2 0 0 1-2 2h-9.37a1.82 1.82 0 0 1-1.55-.87l-2.33-3.8a1.82 1.82 0 0 0-3.24.27L69.89 53a1.83 1.83 0 0 1-1.69 1.14H38.55a2 2 0 0 1-2.04-2.02Z'
			className='avatar25_svg__cls-9'
		/>
		<path
			d='M44.22 38.85a21.67 21.67 0 0 1 19.13-13.51'
			style={{
				stroke: '#fff',
				strokeMiterlimit: 10,
				strokeWidth: 4,
				opacity: 0.1,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={74.67} cy={59.28} r={3} className='avatar25_svg__cls-9' />
		<path
			d='M55 60h-6'
			style={{
				strokeWidth: 2,
				stroke: '#515570',
				strokeLinejoin: 'round',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M63.35 63.75v4.5'
			style={{
				strokeWidth: 5,
				strokeLinejoin: 'round',
				stroke: '#fbc0aa',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M69.15 78.94c0 2-2.3 3.16-5.15 3.16s-5.15-1.52-5.15-3.16c0-2.84 2.48-3.94 5.15-3.15 2.58-.87 5.15.31 5.15 3.15Z'
			style={{
				opacity: 0.5,
				fill: '#f85565',
			}}
		/>
		<path
			d='M58 77.87a16.83 16.83 0 0 0 12 0'
			style={{
				stroke: '#f85565',
				strokeWidth: 2,
				strokeLinejoin: 'round',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M72.39 53H81'
			style={{
				opacity: 0.2,
				strokeWidth: 2,
				stroke: '#515570',
				strokeLinejoin: 'round',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
	</svg>
);
export default SvgAvatar25;
