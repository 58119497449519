import * as React from 'react';
import { SVGProps } from 'react';
const SvgSportIconVolleyball = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 511.979 511.979'
		className='svg-icon'
		{...props}>
		<path
			d='M436.652 437.385c80.309-79.887 100.308-206.63 40.982-309.359C442.386 66.943 385.75 25.906 322.627 8.884c-5.562 1.398-191.412 49.794-66.637 247.12 236.332-9.524 180.881 180.631 180.662 181.381z'
			style={{
				fill: '#f6bb42',
			}}
		/>
		<path
			d='m216.711 113.839-2.266 1.133c-5.515 35.256 3.789 81.324 41.544 141.032 236.333-9.523 180.881 180.631 180.663 181.381 30.358-30.201 52.092-67.105 64.185-106.854.687-9.484 12.421-216.692-284.126-216.692z'
			style={{
				fill: '#4a89dc',
			}}
		/>
		<path
			d='M8.573 321.765c29.03 109.479 128.79 190.194 247.416 190.194 70.528 0 134.4-28.529 180.694-74.684 1.562-5.531 52.576-190.67-180.694-181.271C146.066 465.429 9.112 322.328 8.573 321.765z'
			style={{
				fill: '#5d9cec',
			}}
		/>
		<path
			d='M436.652 437.385c80.309-79.887 100.308-206.63 40.982-309.359C442.386 66.943 385.75 25.906 322.627 8.884c-5.562 1.398-191.412 49.794-66.637 247.12 236.332-9.524 180.881 180.631 180.662 181.381z'
			style={{
				fill: '#f6bb42',
			}}
		/>
		<path
			d='m216.711 113.839-2.266 1.133c-5.515 35.256 3.789 81.324 41.544 141.032 236.333-9.523 180.881 180.631 180.663 181.381 30.358-30.201 52.092-67.105 64.185-106.854.687-9.484 12.421-216.692-284.126-216.692z'
			style={{
				fill: '#4a89dc',
			}}
		/>
		<path
			d='M8.573 321.765c29.03 109.479 128.79 190.194 247.416 190.194 70.528 0 134.4-28.529 180.694-74.684 1.562-5.531 52.576-190.67-180.694-181.271C146.066 465.429 9.112 322.328 8.573 321.765z'
			style={{
				fill: '#5d9cec',
			}}
		/>
		<path
			d='m398.748 293.08.141-2.531c-27.764-22.421-72.324-37.389-142.899-34.545C146.066 465.429 9.112 322.328 8.573 321.765c10.976 41.389 32.061 78.652 60.45 109.01 7.859 5.344 181.444 119.104 329.725-137.695z'
			style={{
				fill: '#f6bb42',
			}}
		/>
		<path
			d='M322.752 8.86C213.406-20.756 93.647 25.304 34.337 128.026-.934 189.108-8.16 258.683 8.659 321.859c3.992 4.109 138.829 140.852 247.331-65.855C129.574 56.092 321.986 9.04 322.752 8.86z'
			style={{
				fill: '#ffce54',
			}}
		/>
		<path
			d='m152.503 361.092 2.117 1.391c33.303-12.858 68.536-43.936 101.37-106.479C129.574 56.092 321.986 9.04 322.752 8.86 281.427-2.335 238.592-2.71 198.11 6.696c-8.554 4.141-193.872 97.581-45.607 354.396z'
			style={{
				fill: '#5d9cec',
			}}
		/>
	</svg>
);
export default SvgSportIconVolleyball;
