import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomRecharge = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		className='svg-icon'
		viewBox='0 0 1024 1024'
		{...props}>
		<path
			fill='#8BC34A'
			d='M725.333 938.667H298.667C275.2 938.667 256 919.467 256 896V170.667C256 147.2 275.2 128 298.667 128h426.666C748.8 128 768 147.2 768 170.667V896c0 23.467-19.2 42.667-42.667 42.667z'
		/>
		<path
			fill='#8BC34A'
			d='M597.333 277.333H426.667c-12.8 0-21.334-8.533-21.334-21.333V106.667c0-12.8 8.534-21.334 21.334-21.334h170.666c12.8 0 21.334 8.534 21.334 21.334V256c0 12.8-8.534 21.333-21.334 21.333z'
		/>
		<path
			fill='#FFEB3B'
			d='M640 512H522.667L569.6 277.333 384 554.667h117.333L454.4 789.333z'
		/>
	</svg>
);
export default SvgCustomRecharge;
