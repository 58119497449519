import React, { useEffect } from 'react';

const DisableScroll = () => {
    useEffect(() => {
        // Save the original overflow value
        const originalOverflow = document.body.style.overflow;

        // Disable scrolling
        document.body.style.overflow = 'hidden';

        // Re-enable scrolling when the component is unmounted
        return () => {
            document.body.style.overflow = originalOverflow;
        };
    }, []);

    return (
       <></>
    );
};

export default DisableScroll;
