import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionAnguilla = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 1200 600'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<clipPath id='regionAnguilla_svg__a'>
			<path d='M0 0v150h700v150H600zm600 0H300v350H0v-50z' />
		</clipPath>
		<path fill='#012169' d='M0 0h1200v600H0z' />
		<path stroke='#fff' strokeWidth={60} d='m0 0 600 300m0-300L0 300' />
		<path
			stroke='#c8102e'
			strokeWidth={40}
			d='m0 0 600 300m0-300L0 300'
			clipPath='url(#regionAnguilla_svg__a)'
		/>
		<path stroke='#fff' strokeWidth={100} d='M300 0v350M0 150h700' />
		<path stroke='#c8102e' strokeWidth={60} d='M300 0v350M0 150h700' />
		<path fill='#012169' d='M0 300h600V0h200v400H0z' />
		<path
			fill='#cc3'
			d='M780.583 154.039c0 148.827 11.873 201.675 52.606 242.479 40.734 40.804 56.166 47.231 67.524 53.483 13.101-7.212 28.197-15.134 65.115-52.052 36.513-36.515 54.173-89.15 54.173-246.452-26.56 11.37-39.786 15.117-63.012 15.05-17.202 1.686-43.74-9.337-59.609-16.547-10.37 6.602-25.415 14.476-52.451 15.36-31.225 1.31-41.12-3.735-64.345-11.322z'
		/>
		<path
			fill='#fff'
			d='M787.775 163.438c0 46.043-2.722 108.794 7.006 145.1 9.77 36.462 12.013 41.803 19.36 54.528l171.206-1.27c10.576-18.319 11.11-29.666 18.953-58.936 7.814-29.165 9.788-94.177 10.016-142.6-17.216 8.725-39.761 14.908-61.643 12.195-17.543-1.275-33.752-5.092-52.627-15.264-16.374 9.353-22.082 13.622-49.119 14.714-21.05.99-38.101 2.615-63.152-8.468z'
		/>
		<path
			fill='#9cf'
			d='M814.51 362.056c12.185 24.448 69.632 71.303 86.433 81.003 19.168-11.067 69.848-57.368 83.973-81.003H814.51z'
		/>
		<path
			fill='#f90'
			d='M904.221 231.606c5.414 12.14 22.77 25.545 27.541 32.114-6.073 6.57-7.287 5.854-6.49 18.06 10.56-10.535 10.75-11.633 17.542-10.035 14.88 14.155 2.663 44.557-9.634 51.166-12.294 7.051-10.062-.244-28.489 8.593 8.449 6.887 18.237-1.01 26.236 1.1 4.347 4.918-2.066 13.879 1.313 22.318 7.07-.65 6.22-14.256 7.866-19.19 5.164-18.076 36.214-30.683 37.793-47.263 6.546-2.93 13.09-.917 21.05 3.345-3.964-15.534-17.079-15.367-20.594-20.218-8.368-12.19-15.785-26.098-33.657-29.706-13.567-2.739-12.553.824-21.247-4.83-5.414-4.018-21.861-11.618-19.23-5.454z'
		/>
		<path
			fill='#f90'
			d='M860.839 283.106c8.73-10.234 13.053-31.127 16.917-38.218 8.93 2.05 8.833 3.408 19.78-2.965-14.703-3.981-15.81-3.628-17.501-10.1 6.177-19.155 40.078-23.06 51.953-15.781 12.282 7.068 4.474 8.596 21.278 20 2.44-10.34-9.457-14.901-11.236-22.63 2.53-5.961 13.836-4.741 20.084-11.532-3.905-5.656-16.127 1.416-21.471 2.334-19.177 4.091-45.373-16.193-61.488-9.778-5.776-4.15-6.965-10.606-6.732-19.307-12.554 10.598-6.272 21.575-9.129 26.805-7.397 12.748-16.83 25.497-11.827 42.248 3.798 12.716 6.629 10.197 5.447 20.166-1.198 6.441-.562 23.856 3.925 18.758z'
		/>
		<path
			fill='#f90'
			d='M931.763 290.302c-13.703-1.828-34.694 5.451-43.059 5.967-2.778-8.347-1.51-8.97-12.893-14.605 4.032 14.055 4.922 14.776-.013 19.504-20.39 4.868-41.432-20.773-41.04-34.226-.009-13.678 5.401-8.097 6.946-27.707-10.543 3.357-8.485 15.424-14.502 20.874-6.66 1.02-11.413-8.837-20.74-10.422-3.058 6.111 9.613 12.426 13.201 16.311 13.592 13.53 8.935 45.397 23.029 55.227-.738 6.86-5.918 11.17-13.85 15.48 16.009 4.76 22.613-6.043 28.785-6.417 15.27-.56 31.594.542 44.043-12.205 9.45-9.676 5.724-10.682 15.285-14.875 6.401-2.355 21.705-11.882 14.808-12.906z'
		/>
		<circle
			cx={266.239}
			cy={122.104}
			r={0.806}
			fill='#fff'
			transform='matrix(3.50845 0 0 3.34488 6.33 -163.011)'
		/>
		<circle
			cx={266.239}
			cy={122.104}
			r={0.806}
			fill='#fff'
			transform='matrix(-1.639 -2.95744 3.10207 -1.56258 914.323 1224.328)'
		/>
		<circle
			cx={266.239}
			cy={122.104}
			r={0.806}
			fill='#fff'
			transform='matrix(-1.80698 2.86712 -3.00733 -1.72273 1749.003 -240.213)'
		/>
	</svg>
);
export default SvgRegionAnguilla;
