import { useLocation } from 'react-router-dom';
import PaymentMethodsComponent from './PaymentMethods';
import HistoryComponent from './History';
import GameHistoryComponent from './GameHistory';
import ThemeContext from '../../context/ThemeContext';
import ProfileDetails from './ProfileDetails';
import ChangePassword from './ChangePassword';
import SportHistory from './SportHistory';
import { useMemo } from 'react';
import WithdrawHistory from './WithdrawHistory';
import InboxMessage from './Inbox';

const UserRouter = () => {
	const location = useLocation();
	const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
	const account = searchParams.get('account');
	const pageType = searchParams.get('page');
	const profile = searchParams.get('profile');

	if (account === 'balance') {
		if (pageType === 'deposit' || pageType === 'withdraw') {
			return <PaymentMethodsComponent type={pageType} />;
		} else if (pageType === 'history') {
			return <HistoryComponent />;
		} else if (pageType === 'withdraws') {
			return <WithdrawHistory />;
		}
	}

	if (account === 'game') {
		return <GameHistoryComponent />;
	}

	if (account === 'profile') {
		if (pageType === 'details') {
			return <ProfileDetails />;
		}
		if (pageType === 'change-password') {
			return <ChangePassword />;
		}
	}

	if (account === 'sport') {
		return <SportHistory />;
	}

	if (account === 'messages') {
		return <InboxMessage />;
	}

	return <PaymentMethodsComponent type={'deposit'} />;
};

export default UserRouter;
