import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomLottery = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		viewBox='0 0 511.984 511.984'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			d='M479.984 16H31.999C14.351 16 0 30.358 0 47.999v234.664c0 17.64 14.351 31.999 31.999 31.999h447.985c17.656 0 32-14.359 32-31.999V47.999c0-17.641-14.344-31.999-32-31.999z'
			style={{
				fill: '#ac92eb',
			}}
		/>
		<path
			d='M330.642 69.342h63.998v63.998h-63.998zM266.652 69.342h63.99v63.998h-63.99zM394.642 69.342h63.998v63.998h-63.998zM330.642 133.342h63.998v63.998h-63.998zM266.652 133.342h63.99v63.998h-63.99zM394.642 133.342h63.998v63.998h-63.998zM330.642 197.342h63.998v63.998h-63.998zM266.652 197.342h63.99v63.998h-63.99zM394.642 197.342h63.998v63.998h-63.998z'
			style={{
				fill: '#656d78',
			}}
		/>
		<path
			d='M405.33 58.67H255.992v213.321h213.336V58.67H405.33zm-63.998 21.328h42.656v42.67h-42.656v-42.67zm-63.996 0h42.654v42.67h-42.654v-42.67zm0 63.997h42.654v42.671h-42.654v-42.671zm42.654 106.669h-42.654v-42.671h42.654v42.671zm21.342-106.669h42.656v42.671h-42.656v-42.671zm42.656 106.669h-42.656v-42.671h42.656v42.671zm63.998 0H405.33v-42.671h42.656v42.671zm0-63.998H405.33v-42.671h42.656v42.671zm-42.656-63.998v-42.67h42.656v42.67H405.33z'
			style={{
				fill: '#434a54',
			}}
		/>
		<path
			d='M138.66 58.67c-47.1 0-85.419 38.327-85.419 85.419 0 47.108 38.319 85.42 85.419 85.42s85.419-38.312 85.419-85.42c0-47.092-38.319-85.419-85.419-85.419z'
			style={{
				fill: '#ffce54',
			}}
		/>
		<path
			d='M213.4 271.991H63.928c-5.891 0-10.672-4.766-10.672-10.655 0-5.891 4.781-10.672 10.672-10.672H213.4c5.891 0 10.664 4.781 10.664 10.672-.001 5.889-4.774 10.655-10.664 10.655z'
			style={{
				fill: '#967adc',
			}}
		/>
		<path
			d='M170.659 154.761h-63.998c-5.891 0-10.664-4.781-10.664-10.672 0-5.89 4.773-10.655 10.664-10.655h63.998c5.89 0 10.671 4.766 10.671 10.655 0 5.89-4.781 10.672-10.671 10.672z'
			style={{
				fill: '#434a54',
			}}
		/>
		<path
			d='M309.334 389.332h-5.625c-12.156-13.625-29.248-21.344-47.717-21.344-18.468 0-35.569 7.719-47.709 21.344h-5.625c-5.891 0-10.664 4.765-10.664 10.655v31.999c0 35.295 28.71 63.998 63.998 63.998 35.279 0 63.998-28.703 63.998-63.998v-31.999c0-5.891-4.781-10.655-10.656-10.655zm-10.688 42.654c0 23.53-85.317 23.53-85.317 0v-21.328a10.68 10.68 0 0 0 8.523-4.25c8.156-10.858 20.593-17.076 34.14-17.076s25.998 6.218 34.139 17.076a10.679 10.679 0 0 0 8.516 4.25l-.001 21.328z'
			style={{
				fill: '#f6bb42',
			}}
		/>
		<path
			d='M255.992 335.989c-35.288 0-63.998 28.718-63.998 63.998 0 35.296 28.71 63.998 63.998 63.998 35.279 0 63.998-28.702 63.998-63.998 0-35.28-28.719-63.998-63.998-63.998z'
			style={{
				fill: '#ffce54',
			}}
		/>
		<path
			d='M255.992 421.33c-5.89 0-10.664-4.781-10.664-10.672v-21.326c0-5.891 4.773-10.672 10.664-10.672s10.664 4.781 10.664 10.672v21.326c0 5.891-4.773 10.672-10.664 10.672z'
			style={{
				fill: '#e8aa3d',
			}}
		/>
	</svg>
);
export default SvgCustomLottery;
