import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomWinner = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		className='svg-icon'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		{...props}>
		<path
			fill='#ffd308'
			d='m27.924 14.807-4.892 11.74H8.969l-4.892-11.74a2.336 2.336 0 0 1-2.14-2.324 2.344 2.344 0 0 1 4.688 0c0 .498-.157.957-.421 1.336a4.65 4.65 0 0 0 3.937 2.18c2.182 0 3.999-1.497 4.522-3.516l.035-.197.131-2.472a2.333 2.333 0 0 1-1.172-2.019 2.343 2.343 0 1 1 4.688 0c0 .866-.474 1.613-1.172 2.019l.082 2.434.084.235c.523 2.018 2.341 3.516 4.522 3.516a4.65 4.65 0 0 0 3.937-2.18 2.33 2.33 0 0 1-.421-1.336 2.344 2.344 0 0 1 4.688 0 2.337 2.337 0 0 1-2.14 2.324z'
		/>
	</svg>
);
export default SvgCustomWinner;
