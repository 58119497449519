import * as React from 'react';
import { SVGProps } from 'react';
const SvgVib = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#ff1f43' />
			<path fill='#fff' d='M7 7h4.2l7.2 12.775V7H22v18h-4.8z' />
		</g>
	</svg>
);
export default SvgVib;
