import * as React from 'react';
import { SVGProps } from 'react';
const SvgParliamentSvgrepoCom = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		className='svg-icon'
		viewBox='0 0 1024 1024'
		{...props}>
		<path
			fill='#009688'
			d='M341.333 384a170.667 170.667 0 1 0 341.334 0 170.667 170.667 0 1 0-341.334 0Z'
		/>
		<path fill='#E89419' d='M341.333 384h341.334v128H341.333z' />
		<path
			fill='#2196F3'
			d='M661.333 106.667V192H614.4c-51.2 0-25.6-21.333-102.4-21.333V85.333c76.8 0 51.2 21.334 102.4 21.334h46.933z'
		/>
		<path fill='#FFB74D' d='M85.333 512h853.334v362.667H85.333z' />
		<path
			fill='#B07424'
			d='M661.333 725.333h85.334V832h-85.334zm-128 0h85.334V832h-85.334zm-128 0h85.334V832h-85.334zm384 0h85.334V832h-85.334zm-512 0h85.334V832h-85.334zm-128 0h85.334V832h-85.334zm512-149.333h85.334v106.667h-85.334zm-128 0h85.334v106.667h-85.334zm-128 0h85.334v106.667h-85.334zm213.334-64h-85.334v-64c0-23.467 19.2-42.667 42.667-42.667s42.667 19.2 42.667 42.667v64zm-128 0h-85.334v-64c0-23.467 19.2-42.667 42.667-42.667s42.667 19.2 42.667 42.667v64zm298.666 64h85.334v106.667h-85.334zm-512 0h85.334v106.667h-85.334zm-128 0h85.334v106.667h-85.334z'
		/>
	</svg>
);
export default SvgParliamentSvgrepoCom;
