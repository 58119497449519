import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomSuit = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 512 512'
		className='svg-icon'
		{...props}>
		<path
			d='M305.42 480.223c-4.217 9.445-18.401 5.835-17.586-4.477 5.247-66.404 16.461-175.572 37.489-252.677 24.715-90.62 51.237-160.451 62.213-187.977 2.833-7.106 10.853-10.599 17.993-7.851l91.285 35.135c16.941 6.52 20.556 28.889 6.531 40.414l-86.076 70.732 60.966 23.473c16.654 6.412 20.507 28.227 7.056 39.953a702.072 702.072 0 0 0-93.306 98.755c-39.646 51.08-67.249 101.249-86.565 144.52zM206.58 480.223c4.217 9.445 18.401 5.835 17.586-4.477-5.247-66.404-16.461-175.572-37.489-252.677-24.715-90.62-51.237-160.451-62.213-187.977-2.833-7.106-10.853-10.599-17.993-7.851L15.187 62.375c-16.941 6.519-20.556 28.888-6.531 40.413l86.076 70.732-60.966 23.473c-16.654 6.412-20.507 28.227-7.056 39.953a702.072 702.072 0 0 1 93.306 98.755c39.646 51.082 67.248 101.251 86.564 144.522z'
			style={{
				fill: '#b9bde6',
			}}
		/>
		<path
			d='m478.233 196.992-10.925-4.207a701.218 701.218 0 0 0-75.326 82.519c-52.443 67.573-83.81 133.545-102.802 184.065-.486 5.694-.935 11.161-1.346 16.366-.815 10.315 13.367 13.932 17.586 4.482 19.316-43.27 46.918-93.438 86.562-144.518a702.02 702.02 0 0 1 93.306-98.755c13.452-11.725 9.599-33.539-7.055-39.952zM120.017 275.305a701.218 701.218 0 0 0-75.326-82.519l-10.925 4.207c-16.654 6.411-20.507 28.227-7.056 39.953a702.017 702.017 0 0 1 93.306 98.755c39.643 51.079 67.246 101.249 86.562 144.517 4.217 9.448 18.401 5.832 17.586-4.482a2519.19 2519.19 0 0 0-1.346-16.366c-18.991-50.521-50.357-116.493-102.801-184.065z'
			style={{
				fill: '#abb0e0',
			}}
		/>
		<path
			d='m256 63.182 66.815-35.588c6.405-3.411 14.137 1.23 14.137 8.487v56.028c0 7.346-7.904 11.979-14.315 8.39L256 63.182zM256 63.102l-66.815-35.588c-6.405-3.411-14.137 1.23-14.137 8.487v56.028c0 7.346 7.904 11.979 14.315 8.39L256 63.102z'
			style={{
				fill: '#f09ea0',
			}}
		/>
	</svg>
);
export default SvgCustomSuit;
