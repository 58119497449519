import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomPrivate = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 511.981 511.981'
		className='svg-icon'
		{...props}>
		<path
			d='m460.202 397-1.812-.516c-26.469-7.125-35.421-13.937-47.202-18.437-41.795-15.921-52.669-27.39-55.45-32.811-.109-.188-.188-.406-.281-.609l-.343-48.546-99.123-1.124-99.121 1.125-.344 48.546c-.094.203-.172.422-.281.609-2.781 5.421-13.655 16.89-55.451 32.811-11.78 4.5-20.733 11.312-47.201 18.437l-1.812.516c-24.093 7.952-40.311 30.312-40.311 55.529v59.451h489.042V452.53c0-25.219-16.218-47.578-40.31-55.53z'
			style={{
				fill: '#434a54',
			}}
		/>
		<path
			d='m324.096 377.173-62.215-36.67-86.668 49.387 33.108 122.09h89.34l36.655-96.591z'
			style={{
				fill: '#ccd1d9',
			}}
		/>
		<path
			d='M278.943 405.296h-44.279l-23.671 88.623 5.78 18.061h80.888l2.609-35.686z'
			style={{
				fill: '#da4453',
			}}
		/>
		<path
			d='M284.756 348.689a10.713 10.713 0 0 0-7.75-3.015l-42.67 1.281c-5.766.188-10.344 4.905-10.344 10.655v47.685c0 5.906 4.781 10.672 10.672 10.672h42.654c5.891 0 10.672-4.766 10.672-10.672V356.33c0-2.875-1.172-5.625-3.234-7.641z'
			style={{
				fill: '#ed5564',
			}}
		/>
		<path
			d='m184.775 400.203 72.028-37.123 73.841 38.091 14.125-20.889 19.312-25.999c-4.938-3.891-7.266-6.921-8.344-9.046-.109-.188-.188-.406-.281-.609l-.343-48.546-99.122-1.125-99.121 1.125-.344 48.546c-.094.203-.172.422-.281.609-1.094 2.156-3.5 5.28-8.609 9.249l37.139 45.717z'
			style={{
				fill: '#e6e9ed',
			}}
		/>
		<path
			d='m234.664 511.98-77.888-203.867-27.968 70.278 32.546 24.998-5.812 24 38.03 84.591zM278.896 511.98l76.311-203.867 29.544 70.278-32.544 24.998 5.811 24-38.03 84.591z'
			style={{
				fill: '#656d78',
			}}
		/>
		<path
			d='M393.531 202.133c0 89.372-85.184 171.634-137.541 171.634-52.342 0-137.542-82.262-137.542-171.634S180.024 53.795 255.99 53.795s137.541 58.966 137.541 148.338z'
			style={{
				fill: '#eac6bb',
			}}
		/>
		<path
			d='M427.905 138.948c-9.952-5.062-23.904-9.531-41.451-13.312-34.966-7.516-81.293-11.672-130.463-11.672s-95.496 4.156-130.464 11.671c-17.546 3.781-31.499 8.25-41.451 13.312-13.327 6.781-20.077 15.093-20.077 24.687 0 9.609 6.75 17.921 20.077 24.702 9.952 5.062 23.905 9.531 41.451 13.312 34.968 7.516 81.294 11.672 130.464 11.672s95.497-4.156 130.463-11.671c17.547-3.781 31.499-8.25 41.451-13.312 13.328-6.781 20.078-15.093 20.078-24.702 0-9.594-6.75-17.906-20.078-24.687z'
			style={{
				fill: '#7f4545',
			}}
		/>
		<path
			d='M362.518 28.655C350.269 9.906 332.441 0 310.988 0c-13.249 0-27.593 3.672-42.655 10.922a144.8 144.8 0 0 0-12.343 6.734C245.616 11.328 223.851 0 200.993 0c-21.452 0-39.279 9.906-51.529 28.655-14.249 21.78-21.468 56.326-21.468 102.668 0 9.109 8.594 14.531 15.062 17.796 6.812 3.453 16.218 6.812 27.233 9.75 14.687 3.89 45.357 10.453 85.699 10.453 40.343 0 71.013-6.562 85.7-10.453 11.016-2.938 20.421-6.296 27.232-9.75 6.468-3.266 15.062-8.688 15.062-17.796 0-46.342-7.218-80.888-21.466-102.668z'
			style={{
				fill: '#965353',
			}}
		/>
	</svg>
);
export default SvgCustomPrivate;
