import * as React from 'react';
import { SVGProps } from 'react';
const SvgSportIconRugbyLeague = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		aria-hidden='true'
		className='svg-icon'
		viewBox='0 0 128 128'
		{...props}>
		<path
			fill='#cf6f24'
			d='M91.48 48.66c-.93 1.87-76.29 67.45-76.29 67.45s5.41 9.12 27.59 8.18c20.14-.85 41.23-10.12 59.9-31.77s21.44-43.69 20.55-56.78c-1.14-16.76-7.58-21.11-9.26-22.79-1.67-1.67-22.49 35.71-22.49 35.71z'
		/>
		<path
			fill='#e07f14'
			d='M31.01 31.49C12.38 49.64-7.44 90.56 12.9 114.08c7.93 9.17 28.75 6.28 48.16-3.55 13.22-6.69 25.35-17.54 33.78-26.5C115.56 62 127.43 19.36 109.96 9.55 88.68-2.39 56.77 6.38 31.01 31.49z'
		/>
		<path
			fill='#af5912'
			d='M11.45 112.28c-1.7-9.19-.49-15.3 3.62-24.97 6.3-14.83 22.99-35.94 34.84-47 12.25-11.42 23.89-20.59 40.9-28.19 12.69-5.67 16.7-4.21 16.99-4.16.45.07 2.79.98 3.15 1.95.19.51.58 1.26-.58 1.18-.04-.01-6.26-.31-17.84 4.86-16.48 7.36-27.81 16.29-39.74 27.43-11.54 10.77-27.76 31.26-33.85 45.58-3.78 8.91-4.97 18.04-3.41 26.42.21 1.14-.81.86-1.79.33-.89-.49-2.1-2.41-2.29-3.43z'
		/>
		<path
			fill='#af5912'
			d='M32.67 76.02c-2.81 0-5.45-.81-7.7-2.43-3.07-2.22-3.79-4.93-4.05-8.49-.69-9.55 8.71-21.2 17.17-29.39C55.68 18.7 68.38 13.2 76.66 19.97c3.91 3.2 5.29 7.95 3.7 12.72-1.5 4.5-6.5 9.4-13.41 16.18-2.01 1.97-4.09 4.01-6.29 6.24-1.62 1.65-3.26 3.47-4.98 5.39-4.34 4.83-8.82 9.83-13.84 12.8-3.05 1.82-6.2 2.72-9.17 2.72zm35.62-55.46c-6.81 0-16.24 5.8-28.18 17.35-11.2 10.84-17.12 20.33-16.66 26.73.18 2.51 1.35 4.51 3.57 6.12 3.46 2.5 8.33 2.33 13.04-.46 4.56-2.7 8.86-7.49 13.02-12.13 1.75-1.96 3.41-3.81 5.1-5.51 2.22-2.25 4.31-4.3 6.33-6.28 6.32-6.19 11.31-11.09 12.54-14.79 1.15-3.44.22-6.6-2.6-8.91-1.73-1.41-3.78-2.12-6.16-2.12zm-8.87 33.33z'
		/>
		<path
			fill='#713604'
			d='M39.55 42.92c-1.34 1.46-1.4 3.47.86 5.17 2.54 1.92 4.57 3.71 5.9 4.91 1.36 1.22 3.71 2.52 5.63.93 1.48-1.23 1.39-3.84-.07-5.24-1.24-1.18-6.2-5.13-7.23-5.9-1.31-.99-3.56-1.52-5.09.13zM47.09 35.06c-1.79 1.72-.78 3.88.68 4.94 1.46 1.06 4.71 3.78 5.77 4.71 1.06.93 3.91 4.18 6.63 1.39 2.05-2.1-.36-4.27-1.46-5.37-1.36-1.38-5.08-4.5-6.39-5.43-1.12-.78-3.5-1.9-5.23-.24zM55.55 28.35c-1.62 1.74-.47 3.67.98 4.7 1.45 1.03 4.19 3.62 5.37 4.6 1.19.98 4.05 3.69 6.05 1.45 1.85-2.08-.04-4.13-1.4-5.48-1.49-1.48-4.57-4.17-6.3-5.32-.96-.64-3.16-1.61-4.7.05zM63.89 22.27c-.95.98-1.37 2.64.36 4.15 2.14 1.87 5.38 4.59 6.15 5.32 1.29 1.24 3.58 1.76 4.7.26 1.29-1.73.15-3.45-1.45-5.44-1.08-1.34-4.24-3.73-5.22-4.35-.98-.61-3.32-1.21-4.54.06zM32.25 51.17c-1.48 1.86-.72 3.67 1.34 5.27 2.07 1.6 5.27 4.13 6.51 4.86s3.02 1.8 4.75-.21c1.96-2.27-.41-4.44-2.17-5.99-1.29-1.14-4.33-3.39-5.79-4.34-1.34-.88-3.46-1.09-4.64.41zM25.51 59.09c-.88 1.12-1.43 3.09 1 5.21 2.43 2.12 4.91 3.88 5.89 4.65.98.78 4.19 2.02 5.79-.05 1.6-2.07-.05-3.82-1.76-5.01s-5.94-4.34-6.77-5.06-2.81-1.45-4.15.26z'
		/>
	</svg>
);
export default SvgSportIconRugbyLeague;
