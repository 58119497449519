import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import ThemeContext from "../../context/ThemeContext";
import LoadingSpinner from "../../component/LoadingSpinner";
import SocketContext from "../../context/SocketContext";
import { toast } from "react-toastify";

const ChangePassword: React.FC = () => {
  const { t } = useTranslation();
  const { mobileDesign } = useContext(ThemeContext);
  const { sendRequest } = useContext(SocketContext);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [newPasswordFocused, setNewPasswordFocused] = useState(false);
  const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const handleSubmit = (values: any, { setSubmitting }: any) => {
    setIsLoading(true);
    sendRequest({ A: "changePassword", D: { updateData: values } }, (response: any) => {
      if (response.E) {
        toast.error(t(response.E));
      } else {
        toast.success(t("passwordChanged"));
      }
      setSubmitting(false);
      setIsLoading(false);
    });
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      new_password: "",
      new_password_confirm: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required(t("Required")),
      new_password: Yup.string()
        .min(8, t("Password should contain at least 8 characters"))
        .required(t("Required")),
      new_password_confirm: Yup.string()
        .oneOf([Yup.ref("new_password"), undefined], t("Passwords must match"))
        .required(t("Required")),
    }),
    onSubmit: handleSubmit,
  });


  if (isLoading) {
    return <LoadingSpinner loading />;
  }

  return (
    <>
      {!mobileDesign && <div className="overlay-header">{t('Change Password')}</div>}
      <div className="u-i-edit-change-password-page-bc">
        <div className="u-i-e-ch-p-content-bc u-i-common-content">
          <form onSubmit={formik.handleSubmit}>
            <div className="u-i-p-control-item-holder-bc">
              <div
                className={`form-control-bc default has-icon ${
                  formik.touched.password && formik.errors.password ? "invalid" : ""
                } ${passwordFocused ? "focused" : ""} ${formik.values.password ? "filled" : ""}`}
              >
                <label className="form-control-label-bc inputs">
                  <input
                    type="password"
                    className="form-control-input-bc"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      setPasswordFocused(false);
                    }}
                    onFocus={() => setPasswordFocused(true)}
                    autoComplete="current-password"
                  />
                  <i className="form-control-input-stroke-bc"></i>
                  <span className="form-control-title-bc ellipsis">
                    {t("Current password")}
                  </span>
                </label>
                {formik.touched.password && formik.errors.password && (
                  <div className="form-control-message-holder-bc">
                    <span className="form-control-message-bc">
                      {formik.errors.password}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="u-i-p-control-item-holder-bc">
              <div
                className={`form-control-bc default has-icon ${
                  formik.touched.new_password && formik.errors.new_password ? "invalid" : ""
                } ${newPasswordFocused ? "focused" : ""} ${formik.values.new_password ? "filled" : ""}`}
              >
                <label className="form-control-label-bc inputs">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    className="form-control-input-bc"
                    name="new_password"
                    value={formik.values.new_password}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      setNewPasswordFocused(false);
                    }}
                    onFocus={() => setNewPasswordFocused(true)}
                    autoComplete="new-password"
                  />
                  <i className="form-control-input-stroke-bc"></i>
                  <span className="form-control-title-bc ellipsis">
                    {t("New password")}
                  </span>
                  <i
                    className={`form-control-icon-bc ${
                      showNewPassword ? "bc-i-eye" : "bc-i-eye-hidden"
                    }`}
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  ></i>
                </label>
                {formik.touched.new_password && formik.errors.new_password && (
                  <div className="form-control-message-holder-bc">
                    <span className="form-control-message-bc">
                      {formik.errors.new_password}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="u-i-p-control-item-holder-bc">
              <div
                className={`form-control-bc default has-icon ${
                  formik.touched.new_password_confirm && formik.errors.new_password_confirm
                    ? "invalid"
                    : ""
                } ${confirmPasswordFocused ? "focused" : ""} ${formik.values.new_password_confirm ? "filled" : ""}`}
              >
                <label className="form-control-label-bc inputs">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="form-control-input-bc"
                    name="new_password_confirm"
                    value={formik.values.new_password_confirm}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      setConfirmPasswordFocused(false);
                    }}
                    onFocus={() => setConfirmPasswordFocused(true)}
                    autoComplete="new-password"
                  />
                  <i className="form-control-input-stroke-bc"></i>
                  <span className="form-control-title-bc ellipsis">
                    {t("Confirm new password")}
                  </span>
                  <i
                    className={`form-control-icon-bc ${
                      showConfirmPassword ? "bc-i-eye" : "bc-i-eye-hidden"
                    }`}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  ></i>
                </label>
                {formik.touched.new_password_confirm && formik.errors.new_password_confirm && (
                  <div className="form-control-message-holder-bc">
                    <span className="form-control-message-bc">
                      {formik.errors.new_password_confirm}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="u-i-p-c-footer-bc">
              <button
                className="btn a-color right-aligned"
                type="submit"
                title={t("Change Password")}
                disabled={formik.isSubmitting || !formik.isValid}
              >
                <span>{t("Change Password")}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
