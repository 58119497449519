import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomRoulette = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 511.984 511.984'
		className='svg-icon'
		{...props}>
		<path
			d='M491.859 156.355c-12.891-30.483-31.342-57.857-54.857-81.372-23.5-23.5-50.889-41.952-81.357-54.858C324.084 6.782 290.553 0 255.992 0c-34.554 0-68.083 6.781-99.645 20.125-30.483 12.906-57.865 31.358-81.373 54.858-23.499 23.515-41.959 50.889-54.85 81.372C6.774 187.916 0 221.447 0 255.992c0 34.56 6.773 68.091 20.125 99.652 12.89 30.484 31.351 57.857 54.85 81.373 23.507 23.5 50.889 41.967 81.373 54.857 31.562 13.344 65.091 20.109 99.645 20.109 34.561 0 68.092-6.766 99.652-20.109 30.469-12.891 57.857-31.357 81.357-54.857 23.516-23.516 41.967-50.889 54.857-81.373 13.344-31.561 20.125-65.092 20.125-99.652 0-34.545-6.781-68.076-20.125-99.637z'
			style={{
				fill: '#ffce54',
			}}
		/>
		<path
			d='M406.83 105.154c-40.279-40.296-93.856-62.483-150.838-62.483s-110.551 22.187-150.847 62.482c-40.287 40.296-62.482 93.872-62.482 150.839 0 56.982 22.195 110.558 62.482 150.853 40.295 40.297 93.864 62.482 150.847 62.482s110.559-22.186 150.838-62.482c40.297-40.295 62.498-93.871 62.498-150.853 0-56.967-22.201-110.543-62.498-150.838z'
			style={{
				fill: '#ed5564',
			}}
		/>
		<path
			d='M174.315 453.189c25.546 10.594 53.186 16.139 81.677 16.139v-213.32l-81.677 197.181zM337.678 58.811c-25.562-10.593-53.188-16.14-81.686-16.14v213.337l81.686-197.197z'
			style={{
				fill: '#434a54',
			}}
		/>
		<path
			d='M337.678 453.189c25.545-10.578 49.014-26.203 69.152-46.344L255.992 256.008l81.686 197.181z'
			style={{
				fill: '#434a54',
			}}
		/>
		<path
			d='M174.315 58.811c-25.554 10.578-49.022 26.202-69.17 46.343l150.847 150.854-81.677-197.197z'
			style={{
				fill: '#434a54',
			}}
		/>
		<path
			d='M453.189 337.678c10.576-25.547 16.139-53.186 16.139-81.685H255.992l197.197 81.685z'
			style={{
				fill: '#a0d468',
			}}
		/>
		<path
			d='M58.795 174.323c-10.585 25.546-16.132 53.186-16.132 81.669h213.329L58.795 174.323zM453.189 174.323c-10.578-25.562-26.203-49.014-46.359-69.169L255.992 255.992l197.197-81.669z'
			style={{
				fill: '#434a54',
			}}
		/>
		<path
			d='M58.795 337.678c10.585 25.561 26.21 49.029 46.35 69.168l150.847-150.854-197.197 81.686z'
			style={{
				fill: '#434a54',
			}}
		/>
		<path
			d='M350.926 256.008c0 52.419-42.498 94.933-94.934 94.933s-94.942-42.514-94.942-94.933c0-52.436 42.507-94.95 94.942-94.95s94.934 42.514 94.934 94.95z'
			style={{
				fill: '#ffce54',
			}}
		/>
		<path
			d='M348.863 135.543c-4.156 4.171-10.92 4.171-15.092 0-4.156-4.156-4.156-10.922 0-15.078 4.172-4.172 10.936-4.172 15.092 0 4.156 4.156 4.156 10.922 0 15.078z'
			style={{
				fill: '#e6e9ed',
			}}
		/>
		<path
			d='M255.992 319.99c-5.89 0-10.664-4.766-10.664-10.656V202.666c0-5.891 4.773-10.671 10.664-10.671s10.664 4.781 10.664 10.671v106.668c0 5.89-4.773 10.656-10.664 10.656z'
			style={{
				fill: '#434a54',
			}}
		/>
		<path
			d='M309.334 266.664H202.658a10.66 10.66 0 0 1-10.664-10.671c0-5.875 4.773-10.656 10.664-10.656h106.676c5.875 0 10.656 4.781 10.656 10.656 0 5.905-4.781 10.671-10.656 10.671z'
			style={{
				fill: '#434a54',
			}}
		/>
		<path
			d='M255.992 223.993c-17.648 0-31.999 14.358-31.999 31.999 0 17.656 14.351 31.998 31.999 31.998 17.656 0 31.998-14.342 31.998-31.998 0-17.64-14.342-31.999-31.998-31.999z'
			style={{
				fill: '#656d78',
			}}
		/>
	</svg>
);
export default SvgCustomRoulette;
