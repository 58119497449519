import * as React from 'react';
import { SVGProps } from 'react';
const SvgEqli = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#C9A35E' />
			<path
				fill='#FFF'
				d='M10.027 19.38a.372.372 0 0 1-.334.21H5.62a.368.368 0 0 1-.315-.182.37.37 0 0 1-.01-.372L12.699 5.69c.133-.239.515-.239.648 0l5.772 10.378a.396.396 0 0 1-.01.372.346.346 0 0 1-.315.181h-1.688a.381.381 0 0 1-.324-.19l-3.75-6.82-4.196 7.755h1.602a.38.38 0 0 1 .315.171.378.378 0 0 1 .019.363l-.744 1.478zm16.674-.344a.35.35 0 0 1-.01.372.346.346 0 0 1-.314.181H15.264a.372.372 0 0 1-.372-.372V17.74c0-.21.171-.372.372-.372h7.908L18.984 9.64l-.792 1.583c-.133.248-.543.248-.667 0l-.744-1.478a.407.407 0 0 1 0-.334l1.85-3.71a.39.39 0 0 1 .324-.201.39.39 0 0 1 .334.19l7.412 13.346zm-4.817 1.478 1.84 3.339a.37.37 0 0 1 0 .372.346.346 0 0 1-.314.181H8.587a.381.381 0 0 1-.325-.19.37.37 0 0 1 .01-.373l2.966-4.817 2.957-5.18c.067-.123.2-.2.334-.19a.35.35 0 0 1 .325.2l.744 1.479a.358.358 0 0 1-.01.353l-3.758 6.486h8.337l-.773-1.288a.35.35 0 0 1-.01-.372.381.381 0 0 1 .325-.19h1.85c.134 0 .258.076.325.19z'
			/>
		</g>
	</svg>
);
export default SvgEqli;
