import * as React from 'react';
import { SVGProps } from 'react';
const SvgCdn = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#f70808' />
			<path
				fill='#fff'
				fillRule='nonzero'
				d='m16 6-1.66 3.016c-.188.328-.525.298-.863.115l-1.2-.607.894 4.633c.189.847-.415.847-.713.48L10.36 11.35l-.34 1.162c-.04.153-.212.313-.471.275l-2.65-.544.695 2.468c.15.549.266.776-.15.92l-.945.434 4.563 3.612a.575.575 0 0 1 .208.605l-.4 1.278c1.572-.177 2.98-.442 4.552-.606.139-.014.371.209.37.366L15.585 26h.764l-.12-4.67c-.001-.157.21-.39.348-.376 1.572.164 2.98.43 4.551.606l-.399-1.278a.574.574 0 0 1 .208-.605l4.563-3.612-.945-.433c-.416-.145-.3-.372-.15-.921l.696-2.468-2.651.544c-.259.038-.432-.122-.47-.275l-.341-1.162-2.097 2.288c-.298.366-.902.366-.713-.48l.895-4.634-1.201.607c-.338.183-.675.213-.863-.115z'
			/>
		</g>
	</svg>
);
export default SvgCdn;
