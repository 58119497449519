import React, { lazy } from "react";
import { RouteProps } from "react-router-dom";
import { sportPagesMenu } from "../menu";

const NavigationList = {
  SPORTNAVIGATION: lazy(
    () => import("../pages/_layout/Navigation/SportNavigation")
  ),
};

const Navigations: RouteProps[] = [
  {
    path: sportPagesMenu.sports.path + '/*',
    element: <NavigationList.SPORTNAVIGATION />,
  },
  { path: "*", element: null },
];

export default Navigations;
