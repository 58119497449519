import React from 'react';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';

interface TextInputProps {
	label: string;
	name: string;
	type?: string;
	readOnly?: boolean;
	id: string;
}

const TextInput: React.FC<TextInputProps> = ({ label, name, type = 'text', readOnly = false, id }) => {
	const [field, meta, helpers] = useField(name);
	const { setFieldValue } = useFormikContext();
	const [focused, setFocused] = React.useState(false);

	const handleFocus = () => setFocused(true);
	const handleBlur = (e: React.FocusEvent<any>) => {
		field.onBlur(e);
		setFocused(false);
	};



	return (
		<div
			className={`form-control-bc default has-icon ${
				meta.touched && meta.error ? 'invalid' : ''
			} ${focused ? 'focused' : ''} ${field.value ? 'filled' : ''}`}>
			<label className='form-control-label-bc inputs'>
				<input
					{...field}
					type={type}
					id={id}
					className='form-control-input-bc'
					readOnly={readOnly}
					onFocus={handleFocus}
					onBlur={handleBlur}
				/>
				<i className='form-control-input-stroke-bc'></i>
				<span className='form-control-title-bc ellipsis'>{label}</span>
			</label>
			{/* {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null} */}
		</div>
	);
};

export default TextInput;
