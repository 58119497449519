import React, { createContext, useState, useMemo, FC, ReactNode, useCallback } from 'react';
import PropTypes from 'prop-types';
import useDeviceScreen from '../hooks/useDeviceScreen';

export interface IThemeContextProps {
  mobileDesign: boolean;
  smallView: boolean;
  fullScreenStatus: boolean;
  setFullScreenStatus: (status: boolean) => void;
  showBetCoupon: boolean;
  setShowBetCoupon: (status: boolean) => void;
  direction: string;
  setDirection?: (direction: string) => void;
  multiView: boolean;
  toggleMultiView: () => void;
}

const ThemeContext = createContext<any>({} as IThemeContextProps);

interface IThemeContextProviderProps {
  children: ReactNode;
}

export const ThemeContextProvider: FC<IThemeContextProviderProps> = ({ children }) => {
  const deviceScreen = useDeviceScreen();
  const mobileDesign = deviceScreen?.width <= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE);

  const smallView = deviceScreen?.width <= 1280;

  const [fullScreenStatus, setFullScreenStatus] = useState<boolean>(false);
  const [showBetCoupon, setShowBetCoupon] = useState<boolean>(false);
  const [direction, setDirection] = useState("ltr");
  const [showFooter, setShowFooter] = useState<boolean>(false);
  const [multiView, setMultiView] = useState<boolean>(false);
 


  const toggleMultiView = useCallback(() => {
    setMultiView(!multiView);
  },[multiView]);

  const values: IThemeContextProps = useMemo(
    () => ({
      mobileDesign,
      smallView,
      multiView,
      showFooter,
      setShowFooter,

      toggleMultiView,
      fullScreenStatus,
      setFullScreenStatus,
      showBetCoupon,
      setShowBetCoupon,
      direction,
	  setDirection,
    }),
    [smallView, showFooter, setShowFooter, multiView, toggleMultiView, mobileDesign, fullScreenStatus, showBetCoupon, direction],
  );

  return <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>;
};

ThemeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeContext;
