import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionYemen = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 9 6'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path d='M0 0h9v6H0z' />
		<path fill='#fff' d='M0 0h9v4H0z' />
		<path fill='#ce1126' d='M0 0h9v2H0z' />
	</svg>
);
export default SvgRegionYemen;
