import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomCoupon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		className='svg-icon'
		viewBox='0 0 1024 1024'
		{...props}>
		<path
			fill='#F5B146'
			d='M908.056 171.944c-1.512 40.128-35.696 72.24-77.88 72.24-42.192 0-76.456-32.112-77.88-72.24H597.024c-1.512 40.128-35.64 72.24-77.88 72.24-42.192 0-76.344-32.112-77.784-72.24H284.584c-1.408 40.128-35.656 72.24-77.848 72.24-42.264 0-76.384-32.112-77.856-72.24H0v680.104h128.88c1.472-40.136 35.592-72.24 77.856-72.24 42.2 0 76.44 32.112 77.848 72.24h156.768c1.44-40.136 35.592-72.24 77.784-72.24 42.24 0 76.368 32.112 77.88 72.24H752.28c1.432-40.136 35.688-72.24 77.88-72.24 42.184 0 76.368 32.112 77.88 72.24H1024V171.944H908.056z'
		/>
		<path fill='#E5226B' d='M173.96 519.384h640.424v131.328H173.96z' />
		<path fill='#0092D2' d='M173.96 379.704h279.168v65.68H173.96z' />
		<path fill='#0085BF' d='M174.36 408.184h278.296v37.192H174.36z' />
		<path fill='#C9005B' d='M174.36 583.48h640.896v67.232H174.36z' />
	</svg>
);
export default SvgCustomCoupon;
