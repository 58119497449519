import Helpers from "../../../hooks/helperFunctions";

export const cmdCreator = (cmdType: string, subData?: any) => {
  const cmd: any = {
    command: "get",
    params: {
      source: "betting",
      what: {},
      where: {},
      subscribe: true,
    },
  };

  switch (cmdType) {
    case "liveCounts":
      cmd.params.what = { game: "@count" };
      cmd.params.where = {
        game: {
          type: 1,
        },
        sport: {
          type: {
            "@nin": [1, 4],
          },
        },
      };

      break;
    case "prematchCounts":
      cmd.params.what = { game: "@count" };
      cmd.params.where = {
        sport: {
          type: {
            "@nin": [1, 4],
          },
        },
        game: {
          "@or": [
            {
              visible_in_prematch: 1,
            },
            {
              type: {
                "@in": [0, 2],
              },
            },
          ],
        },
      };
      break;
    case "matchCalendarSports":
      cmd.params.what.sport = ["id", "name", "alias", "order"];
      cmd.params.where.game = {
        type: 2,
      };
      break;
    case "loadEventsByDate":
      cmd.params.what.game = [
        [
          "id",
          "markets_count",
          "is_blocked",
          "is_stat_available",
          "show_type",
          "sport_alias",
          "team1_name",
          "team2_name",
          "sportcast_id",
          "start_ts",
          "_parent_id",
          "region_alias",
        ],
      ];
      cmd.params.where.game = {
        type: 2,
        start_ts: {
          "@gte": subData.gte,
          "@lt": subData.lt,
        },
        show_type: {
          "@ne": "OUTRIGHT",
        },
      };
      cmd.params.where.sport = {
        id: {
          "@in": subData.sport,
        },
      };
      break;
    case "loadMarketsByDate":
      cmd.params.what = {
        game: ["id"],
        market: ["type", "name", "display_key", "base", "id", "express_id"],
        event: ["id", "price", "type_1", "name", "base", "order"],
      };

      cmd.params.where = {
        sport: {
          id: {
            "@in": subData.sport,
          },
        },
        game: {
          type: 2,
          start_ts: {
            "@gte": subData.gte,
            "@lt": subData.lte,
          },
          show_type: {
            "@ne": "OUTRIGHT",
          },
        },
        market: subData.marketCmd,
      };

      break;
    case "eventDetail":
      cmd.params.what = {
        sport: ["name"],
        region: ["name"],
        competition: ["name"],
        game: [
          "id",
          "stats",
          "info",
          "is_neutral_venue",
          "add_info_name",
          "text_info",
          "markets_count",
          "type",
          "start_ts",
          "is_stat_available",
          "team1_id",
          "team1_name",
          "team2_id",
          "team2_name",
          "last_event",
          "live_events",
          "match_length",
          "sport_alias",
          "sportcast_id",
          "region_alias",
          "is_blocked",
          "show_type",
          "game_number",
        ],
        market: [
          "id",
          "group_id",
          "group_name",
          "group_order",
          "type",
          "name_template",
          "sequence",
          "point_sequence",
          "name",
          "order",
          "display_key",
          "col_count",
          "express_id",
          "extra_info",
          "cashout",
          "is_new",
          "has_early_payout",
          "mobile_col_count",
        ],
        event: [
          "id",
          "type_1",
          "price",
          "name",
          "base",
          "home_value",
          "away_value",
          "display_column",
          "order",
        ],
      };
      cmd.params.where = {
        game: {
          id: Number(subData?.gameId),
        },
        sport: {
          alias: subData?.sportAlias,
        },
        region: {
          alias: subData?.regionAlias,
        },
        competition: {
          id: Number(subData?.competitionId),
        },
      };
      break;
    case "liveEvents":
      cmd.params.what = {
        sport: ["id", "name", "alias", "order"],
        competition: ["id", "order", "name"],
        region: ["id", "name", "alias", "order"],
        game: [
          "id",
          "start_ts",
          "team1_name",
          "team2_name",
          "is_stat_available",
          "text_info",
        ],
      };
      cmd.params.where = {
        game: {
          type: 1,
        },
        sport: {
          type: {
            "@nin": [1, 4],
          },
        },
      };
      break;
    case "liveEventsMarkets":
      cmd.params.what = {
        event: ["id", "price", "type_1", "name", "base", "order"],
        game: [
          [
            "id",
            "markets_count",
            "is_blocked",
            "show_type",
            "team1_name",
            "is_live",
            "team2_name",
            "sportcast_id",
            "info",
            "stats",
          ],
        ],
        market: ["type", "name", "display_key", "base", "id", "express_id"],
      };

      cmd.params.where = {
        sport: {
          alias: subData?.sportAlias,
        },
        game: {
          type: 1,
        },
        market: subData.marketCmd,
      };
      break;
    case "Prematch":
      const { specialSection, prematchRange, selectedTimeRange } = subData;

      cmd.params.what = {
        sport: ["id", "name", "alias", "order"],
        competition: ["id", "order", "name", "alias"],
        region: ["id", "name", "alias", "order"],
        game: [
          "id",
          "start_ts",
          "team1_name",
          "team2_name",
          "is_stat_available",
          "text_info",
          "info",
          "sport_alias",
          "region_alias",
          "_parent_id",
        ],
      };
      cmd.params.where.game = {
        "@or": [{ visible_in_prematch: 1 }, { type: { "@in": [0, 2] } }],
      };

      if (specialSection) {
        if (specialSection === "popularComp") {
          cmd.params.where = {
            competition: { favorite: true },
          };
          cmd.params.what.game = "@count";
          cmd.params.what.market = undefined;
          cmd.params.what.event = undefined;
        }

        if (specialSection === "popularMatch") {
          cmd.params.where.game = { promoted: true };
          cmd.params.where.sport = {
            type: {
              "@ne": 0,
            },
          };
          cmd.params.where.market = {
            "@or": [
              {
                display_key: {
                  "@in": ["HANDICAP", "TOTALS"],
                },
                display_sub_key: "MATCH",
                main_order: 1,
              },
              {
                display_key: "WINNER",
                display_sub_key: "MATCH",
              },
            ],
          };
        }

        if (specialSection === "upcoming") {
          const timeRanges: any = {
            1: { gte: 0, lt: 900 },
            2: { gte: 900, lt: 1800 },
            3: { gte: 1800, lt: 3600 },
          };
          const { gte, lt } = timeRanges[selectedTimeRange] || 0;

          cmd.params.what.game = [
            [
              "id",
              "markets_count",
              "is_blocked",
              "is_stat_available",
              "show_type",
              "sport_alias",
              "team1_name",
              "team2_name",
              "sportcast_id",
              "start_ts",
              "_parent_id",
              "region_alias",
              "competition_alias",
            ],
          ];

          cmd.params.what.event = [
            "id",
            "price",
            "type_1",
            "name",
            "base",
            "order",
          ];
          cmd.params.what.market = [
            "type",
            "name",
            "display_key",
            "base",
            "id",
            "express_id",
          ];

          cmd.params.where.game = {
            "@limit": 100,
            start_ts: {
              "@now": {
                "@gte": gte,
                "@lt": lt,
              },
            },
            show_type: {
              "@ne": "OUTRIGHT",
            },
          };
          cmd.params.where.market = {
            "@or": [
              {
                display_key: {
                  "@in": ["HANDICAP", "TOTALS"],
                },
                display_sub_key: "MATCH",
                main_order: 1,
              },
              {
                display_key: "WINNER",
                display_sub_key: "MATCH",
              },
            ],
          };

          cmd.params.where.sport = {
            type: {
              "@nin": [1, 4],
            },
          };
        }
      } else {
        cmd.params.what.game = "@count";
        cmd.params.what.market = undefined;
        cmd.params.what.event = undefined;

        if (prematchRange !== "All") {
          const timeCmd = getTimeRangeCommand(prematchRange);
          if (timeCmd) {
            cmd.params.where.game.start_ts = timeCmd;
          }
        }
      }
      break;
    case "eventSearch":
      cmd.params.where = {
        "@or": [
          {
            game: {
              "@limit": 25,
              "@or": [
                {
                  team1_name: {
                    "@like": { [subData.lang]: subData.searchText },
                  },
                },
                {
                  team2_name: {
                    "@like": { [subData.lang]: subData.searchText },
                  },
                },
              ],
            },
          },
          {
            competition: {
              name: { "@like": { [subData.lang]: subData.searchText } },
            },
            game: { "@limit": 25 },
          },
        ],
      };
      cmd.params.what = {
        sport: ["id", "name", "alias", "order"],
        region: ["id", "name", "alias"],
        competition: ["id", "name"],
        game: ["type", "start_ts", "team1_name", "team2_name", "id", "is_live"],
      };
      cmd.params.subscribe = false;

      break;

    case "widgetEvent":
      cmd.params.where.game = {
        id: Number(subData.eventId),
      };
      cmd.params.what = {
        game: ["type", "start_ts", "team1_name", "team2_name", "id", "is_live"],
      };
      cmd.params.subscribe = false;

      break;

    case "couponEvent":
      cmd.params.what = {
        game: [
          "team1_name",
          "team2_name",
          "is_blocked",
          "is_live",
          "sport_alias",
          "region_alias",
          "express_min_len",
          "_parent_id",
          "start_ts",
          "visible_in_prematch",
        ],
        market: ["id", "name", "express_id", "type", "extra_info"],
        event: [
          "id",
          "price",
          "base",
          "name",
          "type_1",
          "ew_allowed",
          "sp_enabled",
        ],
      };
      cmd.params.where = subData;
      break;
    default:
      cmd.send = false;
      break;
  }

  return cmd;
};

export function getCombinations(arr: any[], k: number): any[][] {
  if (k > arr.length || k <= 0) return [];
  if (k === arr.length) return [arr];
  if (k === 1) return arr.map(item => [item]);

  const combs: any[][] = [];
  for (let i = 0; i <= arr.length - k; i++) {
    const tailCombs = getCombinations(arr.slice(i + 1), k - 1);
    for (const tailComb of tailCombs) {
      combs.push([arr[i], ...tailComb]);
    }
  }
  return combs;
}

export const getFirstGameOfSport = (data: any) => {
  const sport = data[0] || null;
  const region = sport?.regions[0] || null;
  const competition = region?.competitions[0] || null;
  const game = competition?.games[0] || null;

  return { sport, region, competition, game };
};

export const getGameValuesFromSport = (data: any) => {
  if (!data || !data.sport) return [];
  const sports = data.sport;

  const sortEntities = (entities: any, orderKey: string) =>
    Object.values(entities || {})
      .filter((entity: any) => entity != null)
      .sort((a: any, b: any) => a[orderKey] - b[orderKey]);

  const parsedData = sortEntities(sports, "order").map((sport: any) => {
    if (!sport) return false;

    const modifiedSport = { ...sport, games: [] }; // Oyunlar için boş bir liste ekle
    if (sport.region) {
      modifiedSport.region = sortEntities(sport.region, "order").map(
        (region: any) => {
          const flag = region?.alias?.toLowerCase().replace(/ /g, "");
          let modifiedRegion = { ...region, flag };

          if (region.competition) {
            modifiedRegion.competition = sortEntities(
              region.competition,
              "order"
            ).map((competition: any) => {
              const modifiedCompetition = { ...competition, flag };

              if (competition.game) {
                modifiedCompetition.game = sortEntities(
                  competition.game,
                  "start_ts"
                ).map((game: any) => {
                  const modifiedGame = {
                    ...game,
                    flag,
                    regionName: region.name, // Region adını ekle
                    regionAlias: region.alias, // Region aliasını ekle
                    competitionId: competition.id, // Competition id'sini ekle
                    sportAlias: sport.alias, // Sport aliasını ekle
                    competitionName: competition.name, // Competition adını ekle
                  };
                  modifiedSport.games.push(modifiedGame); // Oyunu sporun oyunlar listesine ekle
                  return modifiedGame;
                });
              }
              return modifiedCompetition;
            });
          }
          return modifiedRegion;
        }
      );
    }
    return modifiedSport;
  });

  return parsedData.filter((sport: any) => sport != null);
};

export const processBettingData = (data: any) => {
  if (!data) return [];
  const groupedData: any = {};



  data.forEach((group: any) => {
    if (!groupedData[group.group_name]) {
      groupedData[group.group_name] = {
        groupId: group.group_id,
        groupName: group.group_name,
        groupOrder: group.group_order,
        bets: [],
      };
    }

    const bet: any = {
      id: group.id,
      type: group.type,
      nameTemplate: group.name_template,
      sequence: group.sequence,
      pointSequence: group.point_sequence,
      name: group.name,
      order: group.order,
      displayKey: group.display_key,
      cashout: group.cashout,
      colCount: group.col_count,
      groupKey: group.groupKey,
      isFavorite: group.isFavorite,
      events: [],
      statsWidgetAvailable: group.statsWidgetAvailable,
      headers: group.headers,
    };

    group.event.forEach((event: any) => {
      bet.events.push({
        id: event.id,
        type: event.type_1,
        price: event.price,
        name: event.name,
        order: event.order,
        marketId: event.marketId,
        marketOrder: event.marketOrder,
      });
    });

    groupedData[group.group_name].bets.push(bet);
  });

  return groupedData;
};

export const CleanText = (str: string) => {
  const regex = /[^a-zA-Z0-9-]/g; // Burada, a-z, A-Z, 0-9 ve '-' dışındaki tüm karakterleri kapsar.

  // replace() fonksiyonu ile bu karakterleri boş string ile değiştiririz.
  return str?.replace(regex, "") || "";
};

export const getTimeRangeCommand = (selectedTimeRange: string) => {
  const now = new Date();
  const endOfDay = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    59,
    999
  );
  const totalSeconds = Math.round((endOfDay.getTime() - now.getTime()) / 1000);
  const timeRanges: any = {
    All: null,
    Today: {
      "@now": {
        "@gte": 0,
        "@lt": totalSeconds,
      },
    },
    "3Hours": {
      "@now": {
        "@gte": 0,
        "@lt": 10800,
      },
    },
    "6Hours": {
      "@now": {
        "@gte": 0,
        "@lt": 21600,
      },
    },
    "12Hours": {
      "@now": {
        "@gte": 0,
        "@lt": 43200,
      },
    },
    "24Hours": {
      "@now": {
        "@gte": 0,
        "@lt": 86400,
      },
    },
    "48Hours": {
      "@now": {
        "@gte": 0,
        "@lt": 172800,
      },
    },
    "72Hours": {
      "@now": {
        "@gte": 0,
        "@lt": 259200,
      },
    },
    "1Week": {
      "@now": {
        "@gte": 0,
        "@lt": 604800,
      },
    },
  };
  return timeRanges[selectedTimeRange];
};

export const formatName = (name = "", team1 = "", team2 = "") => {
  const substitutions: any = {
    "Player 1": "team1_name",
    "Player 2": "team2_name",
    "player 1": "team1_name",
    "player 2": "team2_name",
    "Team 1": "team1_name",
    "team 1": "team1_name",
    "Team 2": "team2_name",
    "team 2": "team2_name",
    team1: "team1_name",
    team2: "team2_name",
    W1: "team1_name",
    W2: "team2_name",
    "W 1": "team1_name",
    "W 2": "team2_name",
    Team1: "team1_name",
    Team2: "team2_name",
    Home: "team1_name",
    Away: "team2_name",
    "Ком. 1": "team1_name",
    Ком1: "team1_name",
    "Ком. 2": "team2_name",
    Ком2: "team2_name",
    П1: "team1_name",
    П2: "team2_name",
    "Игрок 1": "team1_name",
    "Игрок 2": "team2_name",
    "Թիմ 1": "team1_name",
    "Թիմ 2": "team2_name",
    Հ1: "team1_name",
    Հ2: "team2_name",
    G1: "team1_name",
    G2: "team2_name",
    "Ev Sahibi": "team1_name",
    Deplasman: "team2_name",
    "第 1 隊": "team1_name",
    "第 2 隊": "team2_name",
    "主場 ": "team1_name",
    "客場 ": "team2_name",
    主場: "team1_name",
    客場: "team2_name",
    "Home ": "team1_name",
    "Away ": "team2_name",
    選手1: "team1_name",
    選手2: "team2_name",
    "球員 1": "team1_name",
    "球員 2": "team2_name",
    主隊: "team1_name",
    客隊: "team2_name",
    选手1: "team1_name",
    选手2: "team2_name",
    "主队 ": "team1_name",
    "客队 ": "team2_name",
    主队: "team1_name",
    客队: "team2_name",
    "选手 1": "team1_name",
    "选手 2": "team2_name",
    球队1: "team1_name",
    球队2: "team2_name",
    "球队 1": "team1_name",
    "球队 2": "team2_name",
    プレーヤー1: "team1_name",
    プレーヤー2: "team2_name",
    "선수 1": "team1_name",
    "선수 2": "team2_name",
    "Jugador 1": "team1_name",
    "Jugador 2": "team2_name",
    "jugador 1": "team1_name",
    "jugador 2": "team2_name",
    "Spieler 1": "team1_name",
    "Spieler 2": "team2_name",
    S1: "team1_name",
    S2: "team2_name",
    "Joueur 1": "team1_name",
    "Joueur 2": "team2_name",
    "Equipo 1": "team1_name",
    "Equipo 2": "team2_name",
    "equipo 1": "team1_name",
    "equipo 2": "team2_name",
  };

  const homeRunExceptions = new Set([
    "First Home Run Of Game Will Be",
    "Solo Home Run",
    "2-run Home Run",
    "3-run Home Run",
    "No Home Run Scored",
  ]);

  if (homeRunExceptions.has(name)) return name;

  const teamNames: any = { team1_name: team1, team2_name: team2 };
  const regex = new RegExp(Object.keys(substitutions).join("|"), "g");

  return name.replace(regex, (matched) => teamNames[substitutions[matched]]);
};

export const generateHeaders = ({
  marketType,
  nameTemplate,
  events,
  team1Name,
  team2Name,
  colCount,
}: any) => {
  const key = `${marketType}_${nameTemplate}`;

  const gln = new Set([
    "Handicap_Goals Handicap 3 Way",
    "FirstHalfHandicap_1st Half Goals Handicap 3 Way",
    "SecondHalfHandicap_2nd Half Goals Handicap 3 Way",
    "undefined_3-Way Handicap",
  ]);

  const vln = new Set([
    "HomeTeamToWinWithExactMargin_Team 1 Win By Exact ({h}) Goal",
    "AwayTeamToWinWithExactMargin_Team 2 Win By Exact ({h}) Goal",
    "MatchHomeTeamToWinAndScoreExactGoal_Team 1 Will Win and Score Exact ({h}) Goal",
    "MatchAwayTeamToWinAndScoreExactGoal_Team 2 Will Win and Score Exact ({h}) Goal",
    "FirstHalfHomeTeamToWinAndScoreExactGoal_1st Half Team 1 Win By Exact ({h}) Goal",
    "FirstHalfAwayTeamToWinAndScoreExactGoal_1st Half Team 2 Win By Exact ({h}) Goal",
    "SecondHalfHomeTeamToWinAndScoreExactGoal_2nd Half Team 1 Win By Exact ({h}) Goal",
    "SecondHalfAwayTeamToWinAndScoreExactGoal_2nd Half Team 2 Win By Exact ({h}) Goal",
    "BothHalvesTotalOver_Both Halves Total Over ({h})",
    "EachTeamToScoreOver_Each Team To Score Over ({h})",
    "EachTeamToScoreUnder_Each Team To Score Under ({h})",
    "Team1BothHalvesTotalOver_{t2} Both Halves Total Over ({h})",
    "Team2BothHalvesTotalOver_{t2} Both Halves Total Over ({h})",
    "MatchBothTeamToScore_Both Team To Score at Least ({h}) Points",
    "QuarterBothTeamToScore_{sw} Quarter Both Team To Score at Least ({h}) Points",
  ]);
  const mln = new Set([
    "OverUnder_Total Goals",
    "Team1OverUnder_Team 1 Total Goals",
    "Team2OverUnder_Team 2 Total Goals",
    "OverUnder_Total Goals Asian",
    "HomeTeamCornersOverUnder_Corners: Team 1 Total",
    "AwayTeamCornersOverUnder_Corners: Team 2 Total",
    "HalfTimeCornersOverUnder_Corners: 1st Half Total",
    "HalfTimeTeam1CornersOverUnder_Corners: 1st Half Team 1 Total",
    "HalfTimeTeam2CornersOverUnder_Corners: 1st Half Team 2 Total",
    "2ndHalfCornersOver/Under_Corners: 2nd Half Total",
    "Corners:2ndHalfTeam1Total_Corners: 2nd Half Team 1 Total",
    "Corners:2ndHalfTeam2Total_Corners: 2nd Half Team 2 Total",
    "YellowCardsOverUnder_Yellow Cards: Total",
    "Team1YellowCardsOverUnder_Yellow Cards: Team 1 Total",
    "Team2YellowCardsOverUnder_Yellow Cards: Team 2 Total",
    "HalfTimeOverUnder_1st Half Total Goals",
    "HalfTimeTeam1OverUnder_1st Half Team 1 Total Goals",
    "HalfTimeTeam2OverUnder_1st Half Team 2 Total Goals",
    "HalfTimeOverUnderAsian_1st Half Total Goals Asian",
    "2ndHalfTotalOver/Under_2nd Half Total Goals",
    "SecondHalfHomeTeamTotalGoalsOverUnder_2nd Half Team 1 Total Goals",
    "SecondHalfAwayTeamTotalGoalsOverUnder_2nd Half Team 2 Total Goals",
    "1-15OverUnder_1-15 Min. Total Goals",
    "1-15MinutesHomeTeamTotalGoalsOverUnder_1-15 Min. Team 1 Total Goals",
    "1-15MinutesAwayTeamTotalGoalsOverUnder_1-15 Min. Team 2 Total Goals",
    "1-30OverUnder_1-30 Min. Total Goals",
    "1-30MinutesHomeTeamTotalGoalsOverUnder_1-30 Min. Team 1 Total Goals",
    "1-30MinutesAwayTeamTotalGoalsOverUnder_1-30 Min. Team 2 Total Goals",
    "1-60OverUnder_1-60 Min. Total Goals",
    "1-60MinutesHomeTeamTotalGoalsOverUnder_1-60 Min. Team 1 Total Goals",
    "1-60MinutesAwayTeamTotalGoalsOverUnder_1-60 Min. Team 2 Total Goals",
    "1-75OverUnder_1-75 Min. Total Goals",
    "1-75MinutesHomeTeamTotalGoalsOverUnder_1-75 Min. Team 1 Total Goals",
    "1-75MinutesAwayTeamTotalGoalsOverUnder_1-75 Min. Team 2 Total Goals",
    "Total3_Total Goals 3 Way",
    "Team1TotalOverUnderAsian_Team 1 Total Goals Asian",
    "Team2TotalOverUnderAsian_Team 2 Total Goals Asian",
    "HalfTimeOverUnderAsian_1st Half Total Goals Asian",
    "HalfCommonTotalOverUnder2WayAsian_{sw} Half Total Goals Asian",
    "HalfCommonTotalOverUnder3Way_{sw} Half Total Goals 3 Way",
    "CornerTotal3_Corners: Total 3 Way",
    "MatchTotal2_Total Goals (Regular Time)",
    "MatchTotal2Asian_Total Goals Asian (Regular Time)",
    "MatchTotal(IncludingOvertime)_Total Goals (Including Overtime)",
    "HomeTeamTotal_Team 1 Total Goals (Regular Time)",
    "Team1TotalGoals(IncludingOvertime)_Team 1 Total Goals (Including Overtime)",
    "AwayTeamTotal_Team 2 Total Goals (Regular Time)",
    "Team2TotalGoals(IncludingOvertime)_Team 2 Total Goals (Including Overtime)",
    "PeriodTotal2_{sw} Period Total Goals",
    "PeriodHomeTeamTotal_{sw} Period Team 1 Total Goals",
    "PeriodAwayTeamTotal_{sw} Period Team 2 Total Goals",
    "MatchTotal3_Total Goals 3 Way (Regular Time)",
    "TotalGamesOver/Under_Total Games",
    "SetOverUnder_{sw} Set Total Games",
    "GameTotalPoints_{sw} Set {pw} Game Total Points",
    "SetTieBreakTotalPoints_{sw} Set Tie Break Total Points",
    "SetOutcomeAndTotal_{sw} Set Winner and Total Points ({h})",
    "Player1Over/Under_Player 1 Total Points",
    "Player2Over/Under_Player 2 Total Points",
    "TotalofSets_Total Sets",
    "SetTotalPointsOverUnder_{sw} Set Total Points",
    "SetTotalPointsOverUnder_{sw} Set Total Points Asian",
    "MatchTotalPointsOverUnder_Total Points",
    "TotalPointsOver/Under_Total Points",
    "HomeTeamOver/Under_Team 1 Total Points",
    "AwayTeamOver/Under_Team 2 Total Points",
    "SetTotalOverUnder_{sw} Set Total Points",
    "Team1SetTotalOverUnder_{sw} Set Team 1 Total Points",
    "Team2SetTotalOverUnder_{sw} Set Team 2 Total Points",
    "MatchTotal_Total Points",
    "MatchHomeTeamTotal2_Team 1 Total Points",
    "MatchAwayTeamTotal2_Team 2 Total Points",
    "QuarterTotal_{sw} Quarter Total Points",
    "QuarterTotal3_{sw} Quarter Total Points 3 Way",
    "QuarterHomeTeamTotal2_{sw} Quarter Team 1 Total Points",
    "QuarterAwayTeamTotal2_{sw} Quarter Team 2 Total Points",
    "HalfTotal_{sw} Half Total Points",
    "HalfHomeTeamTotal2_{sw} Half Team 1 Total Points",
    "HalfAwayTeamTotal2_{sw} Half Team 2 Total Points",
    "MatchTotal_Match Total Points",
    "1-5Total_First Five(1-5) Shot Total Points",
    "HomeTeamTotalPoints_Team 1 Total Points",
    "AwayTeamTotalPoints_Team 2 Total Points",
    "QuarterHomeTeamTotal2Way_{sw} Quarter Team 1 Total Points",
    "QuarterAwayTeamTotal2Way_{sw} Quarter Team 2 Total Points",
    "TotalLegs_Total Legs",
    "Total180`S_Total 180`s",
    "FrameTotal_{sw} Frame Total Points",
    "TotalFramesOver/Under_Total Frames",
    "Over/Under_Total Goals",
    "HomeTeamOver/Under_Team 1 Total Goals",
    "AwayTeamOver/Under_Team 2 Total Goals",
    "QuarterTotal_{sw} Quarter Total Goals",
    "GameTotalPoints_{sw} Game Total Points",
    "InningTotal_{sw} Inning Total Runs",
    "TotalRunsOver/Under_Total Runs",
    "HomeTeamTotalRunsOver/Under_Team 1 Total Runs",
    "AwayTeamTotalRunsOver/Under_Team 2 Total Runs",
    "GamesTotal_Total Games",
    "MapsTotal_Maps Total",
    "Game1KillsTotal_Game 1 Total Kills",
    "Game2KillsTotal_Game 2 Total Kills",
    "Game3KillsTotal_Game 3 Total Kills",
    "OverUnder_Over/Under ({h})",
    "HomeOverUnder_{t1} Over/Under ({h})",
    "AwayOverUnder_{t2} Over/Under ({h})",
    "undefined_3-Way Total",
    "FirstHalfTotal_First Half Over/Under ({h})",
    "FirstHalfHomeTeamTotal_First Half {t1} Over/Under ({h})",
    "FirstHalfAwayTeamTotal_First Half {t2} Over/Under ({h})",
    "FirstHalfHomeTeamTotal_First Half {t1} Over/Under ({h}) Asian",
    "FirstHalfAwayTeamTotal_First Half {t2} Over/Under ({h}) Asian",
    "undefined_Over/Under ({h})",
    "undefined_{t1} Over/Under ({h})",
    "undefined_{t2} Over/Under ({h})",
    "undefined_Rounds of Kicks Over/Under ({h})",
    "MatchPointsTotal2Way_Match Points Total",
    "RoundMissedAttemptsOverUnder_{sw} Set Missed Attempts Total",
    "RoundPointsTotal2Way_{sw} Set Points Total",
    "RoundPointTotalOverUnder2Way_{sw} Set Points Total",
  ]);
  const Aln = new Set([
    "AsianHandicap_Goals Handicap",
    "AsianHandicap_Goals Asian Handicap",
    "CornerHandicap_Corners: Handicap",
    "CornersOverUnder_Corners: Total",
    "HalfTimeCornerHandicap_Corners: 1st Half Handicap",
    "1stHalfCornersOver/Under_Corners: 1st Half Total",
    "Corners:2ndHalfAsianHandicap_Corners: 2nd Half Handicap",
    "YellowCardsHandicap_Yellow Cards: Handicap",
    "HalfTimeYellowCardHandicap_Yellow Cards: 1st Half Handicap",
    "HalfTimeAsianHandicap_1st Half Goals Handicap",
    "HalfTimeAsianHandicap_1st Half Goals Asian Handicap",
    "2ndHalfAsianHandicap_2nd Half Goals Handicap",
    "1-15Handicap_1-15 Min. Goals Handicap",
    "1-30Handicap_1-30 Min. Goals Handicap",
    "1-60Handicap_1-60 MIn. Goals Handicap",
    "1-75Handicap_1-75 Min. Goals Handicap",
    "HalfHandicap2WayAsian_{sw} Half Goals Asian Handicap",
    "FirstHalfVsSecondHalfHandicap_First Half Total Goals Vs Second Half Total Goals Handicap",
    "Handicap_Goals Asian Handicap",
    "MatchHandicap2_Goals Handicap (Regular Time)",
    "Handicap(IncludingOvertime)_Goals Handicap (Including Overtime)",
    "PeriodHandicap2_{sw} Period Goals Handicap",
    "MatchHandicap2Asian_Goals Asian Handicap (Regular Time)",
    "Handicap_Games Handicap",
    "SetHandicap_{sw} Set Games Handicap",
    "GameHandicapPoints_{sw} Set {pw} Game Points Handicap",
    "MatchPointHandicap_Points Handicap",
    "SetPointsHandicap_{sw} Set Points Handicap",
    "PointHandicap_Points Handicap",
    "SetPointHandicap_Sets Handicap",
    "SetHandicap_{sw} Set Points Handicap",
    "MatchHandicap_Points Handicap",
    "QuarterHandicap_{sw} Quarter Points Handicap",
    "HalfHandicap_{sw} Half Points Handicap",
    "Handicap_Points Handicap",
    "HandicapLegs_Legs Handicap",
    "Handicap_Frames Handicap",
    "Handicap_Goals Handicap",
    "QuarterHandicap_{sw} Quarter Goals Handicap",
    "GameHandicap_{sw} Game Points Handicap",
    "RunLine_Run Line",
    "GamesHandicap_Games Handicap",
    "MapsHandicap_Maps Handicap",
    "Map1RoundsHandicap_Map 1 Rounds Handicap",
    "Map2RoundsHandicap_Map 2 Rounds Handicap",
    "Map3RoundsHandicap_Map 3 Rounds Handicap",
    "Game1KillsHandicap_Game 1 Kills Handicap",
    "Game2KillsHandicap_Game 2 Kills Handicap",
    "Game3KillsHandicap_Game 3 Kills Handicap",
    "AsianHandicap_Asian Handicap",
    "FirstHalfAsianHandicap_First Half Asian Handicap",
    "FirstHalfAsianHandicap_1st Half Goals Asian Handicap",
    "undefined_Asian Handicap",
    "undefined_{sw} Set Points Handicap 2Way",
    "RoundPointHandicap2Way_{sw} Set Points Handicap",
  ]);
  const Cln = new Set(["MatchRaceTo_Goals Race To ({h})"]);
  const yln = new Set([
    ...Array.from(mln),
    ...Array.from(Aln),
    ...Array.from(vln),
    ...Array.from(Cln),
  ]);

  const Qoe = (e: any) => e.id > 0;
  const eo = (e: any) => (t: any) => t[e];
  const checkSizeOfUniqueProperties = (e: any, t: any) =>
    new Set(t.filter(Qoe).map(eo("name"))).size === e;
  return yln.has(key) ||
    (gln.has(key) && checkSizeOfUniqueProperties(colCount, events))
    ? events
        .slice(0, colCount)
        .map((c: any) =>
          Qoe(c) ? formatName(c.name, team1Name, team2Name) : ""
        )
    : [];
};

type Comparator = (a: any, b: any) => number;

function bNe(): Comparator {
  return (a: any, b: any): number => {
    if (a === b) return 0;
    return a < b ? -1 : 1;
  };
}

type Transformer = (data: any[], criteria: string[]) => any[];

function oin(): Transformer {
  return (data: any[], criteria: string[]): any[] => {
    // Gerçek uygulamada veriler üzerinde dönüşüm yapılabilir
    return data;
  };
}

function yNe(): (
  data: any[],
  criteria: string[],
  compare: Comparator
) => any[] {
  return (data: any[], criteria: string[], compare: Comparator): any[] => {
    return data.sort((a: any, b: any): number => {
      for (let criterion of criteria) {
        const result = compare(a[criterion], b[criterion]);
        if (result !== 0) return result;
      }
      return 0;
    });
  };
}

function ENe() {
  const sortData = yNe();
  const prepareData = oin();
  const compareData = bNe();

  return (data: any[], criteria: string[]): any[] => {
    const preparedData = prepareData(data, criteria);
    return sortData(preparedData, criteria, compareData);
  };
}

// Eventleri belirli kurallara göre sırala ve filtrele
export function sortAndFilterMarketEvents({
  sportAlias,
  displayKey,
  marketType,
  marketEvents,
  colCount,
  customizable = true,
}: any) {
  const hr = {
    ALL: "All",
    SOCCER: "Soccer",
    BASKETBALL: "Basketball",
    CYBER_FOOTBALL: "CyberFootball",
    TENNIS: "Tennis",
    E_TENNIS: "ETennis",
    ICE_HOCKEY: "IceHockey",
    BASEBALL: "Baseball",
    Boxing: "Boxing",
    TABLE_TENNIS: "TableTennis",
    SNOOKER: "Snooker",
    BASKETBALL_SHOTS: "BasketballShots",
    AIR_HOCKEY: "AirHockey",
    COUNTER_STRIKE: "CounterStrike",
    DOTA_2: "Dota2",
    LEAGUE_OF_LEGENDS: "LeagueOfLegends",
    E_BASKETBALL: "EBasketball",
    OVERWATCH: "Overwatch",
    STARCRAFT: "StarCraft",
    STARCRAFT_2: "StarCraft2",
    ROCKET_LEAGUE: "RocketLeague",
    KING_OF_GLORY: "KingOfGlory",
    RAINBOW_SIX: "RainbowSix",
    ARENA_OF_VALOR: "ArenaofValor",
    VALORANT: "Valorant",
    WARCRAFT_3: "WarcraftIII",
    CALL_OF_DUTY: "CallOfDuty",
    HEARTHSTONE: "Hearthstone",
    PUBG: "PUBG",
    MOBILELEGENDS: "MobileLegends",
    ARCHERY: "Archery",
    ARCHERY_H2H: "ArcheryH2H",
    COMPOUND_ARCHERY: "CompoundArchery",
    TEQBALL: "TeqBall",
    PISTOL_SHOOTING: "PistolShooting",
    SHOOTING: "Shooting",
    VOLLEYBALL: "Volleyball",
    HORSE_RACING: "HorseRacing",
    GREYHOUNDS: "SISGreyhound",
    PISTOL_SHOOTING_H2H: "PistolHead2Head",
  };

  const R5n = new Set([
    hr.SOCCER,
    hr.TENNIS,
    hr.ICE_HOCKEY,
    hr.BASEBALL,
    hr.TABLE_TENNIS,
    hr.SNOOKER,
    hr.AIR_HOCKEY,
    hr.COUNTER_STRIKE,
    hr.DOTA_2,
    hr.LEAGUE_OF_LEGENDS,
  ]);

  const N5n = new Set(["YellowCardCorrectScore", "CornerCorrectScore"]);

  const k5n = new Set([
    "MatchWinningMargin",
    "SetWinningMargin",
    "WinningMargin",
    "CorrectScore",
    "Firstset/match",
    "SetsEffectiveness",
    "SeriesCorrectScore",
    "SeriesCorrectScoreAfterGame3",
    "SeriesCorrectScoreAfterGame4",
    "CurrectScoreGroup",
    "MatchBettingAndTeamsToScore",
    "HalfTimeFullTime",
    "HalfTimeFullTimeDoubleChance",
    "ExtraTimeHomeTeamCorrectTotal",
    "ExtraTimeAwayTeamCorrectTotal",
    "OutcomeandBothTeamToScore",
    "DoubleChanceAndBothTeamToScore",
    "DoubleChanceAndBothTeamToScore",
    "TotalAndBothTeamsToScore",
    "FirstHalfOutcomeAndBothTeamToScore",
    "SecondHalfOutcomeAndBothTeamToScore",
    "1stHalf-2ndHalfBothToScore",
    "GameCorrectScore",
    "MatchTieBreakCorrectScore",
    "SetTieBreakCorrectScore",
    "1stSet-Match",
    "1stGame/2ndGameWinner",
    "2ndGame/3thGameWinner",
    "3thGame/4thGameWinner",
    "4thGame/5thGameWinner",
    "5thGame/6thGameWinner",
    "6thGame/7thGameWinner",
    "7thGame/8thGameWinner",
    "8thGame/9thGameWinner",
    "9thGame/10thGameWinner",
    "10thGame/11thGameWinner",
    "11thGame/12thGameWinner",
    "SetScore",
    "SetCorrectScore",
    "5thSetCorrectScore",
    "MatchTieBreakCorrectScore",
    "OutcomeAndTotal15",
    "OutcomeAndTotal25",
    "OutcomeAndTotal35",
    "OutcomeAndTotal45",
    "GameWinningMargin",
    "CurrectScoreTennisKined",
    "CurrectScoreFootballKined",
    "MatchBettingBothTeamsToScore",
    "CorrecctScoreInsp",
    "HalfTimeCorrecctScore",
    "CorrecctScoreGroup",
    "TeamScorecast",
  ]);

  if (customizable) {
    // Eğer belirli spor türleri ve market türleri için özel kurallar varsa, bu kurallara göre işlem yap
    if (
      (displayKey === "CORRECT SCORE" || N5n.has(marketType)) &&
      R5n.has(sportAlias)
    ) {
      return applySpecialSorting(marketEvents, colCount);
    }
    if (k5n.has(marketType)) {
      return anotherSortingApproach(marketEvents, colCount);
    }
  }
  return ENe()(marketEvents, ["marketOrder", "order"]);
}

interface MarketItem {
  group_id?: string;
  group_name: string;
  group_order: number;
  is_new: boolean;
  type: string;
  name_template: string;
  sequence: number;
  point_sequence: number;
  event: { [key: string]: any };
  col_count: number;
  mobile_col_count?: number;
  name: string;
  id: string;
  order: number;
}

interface MarketGroup {
  id: string;
  name: string;
  count: number;
  order: number;
  is_new: boolean;
}

export function groupMarketsByType({
  marketList,
  team1Name,
  team2Name,
  sportAlias,
  statisticsAvailable,
}: {
  marketList: { [key: string]: MarketItem };
  team1Name: string;
  team2Name: string;
  sportAlias: string;
  statisticsAvailable: boolean;
}) {
  const groupedMarkets: { [key: string]: MarketGroup } = {};
  const marketDetails: { [key: string]: any } = {};
  const marketCustomFlags: { [key: string]: boolean } = {};

  const constants: any = {
    OTHERS: -3,
    FAVORITES: -2,
    ALL: -1,
  };

  const v1 = {
    Overview: { name: "Overview", value: "Overview" },
    Details: { name: "Details", value: "Details" },
    Individual: { name: "Individual", value: "Individual" },
    H2H: { name: "H2H", value: "H2H" },
    Team1: { name: "Team 1", value: "Team1" },
    Team2: { name: "Team 2", value: "Team2" },
  };

  const vBe: any = {
    HalfTimeFullTime: {
      template: "HalfTimeFullTime",
      tabs: [v1.Team1, v1.Team2],
    },
    HalfTimeResult: {
      template: "MatchResult",
      tabs: [v1.Overview, v1.Details],
    },
    P1XP2: { template: "MatchResult", tabs: [v1.Overview, v1.Details] },
    CorrectScore: { template: "MatchResult", tabs: [v1.Overview, v1.Details] },
    "To Qualify": { template: "ToQualify", tabs: [] },
    ToQualify: { template: "ToQualify", tabs: [] },
    "1stHalfBothTeamsToScore": {
      template: "ScoredGoals",
      tabs: [v1.H2H, v1.Individual],
      dataPropKeys: {
        Individual: "FirstHalfGoals",
        H2H: "BothTeamsFirstHalfGoals",
      },
      requestType: 3,
    },
    "2ndHalfBothTeamsToScore": {
      template: "ScoredGoals",
      tabs: [v1.H2H, v1.Individual],
      dataPropKeys: {
        Individual: "SecondHalfGoals",
        H2H: "BothTeamsSecondHalfGoals",
      },
      requestType: 3,
    },
    "1stHalf-2ndHalfBothToScore": {
      template: "ScoredGoals",
      tabs: [v1.H2H, v1.Individual],
      dataPropKeys: {
        Individual: "FullTimeGoals",
        H2H: "BothTeamsFullTimeGoals",
      },
      requestType: 3,
    },
    BothTeamsToScore: {
      template: "ScoredGoals",
      tabs: [v1.Individual],
      dataPropKeys: { Individual: "BothTeamsFullTimeGoals" },
      requestType: 1,
    },
    "Team1ScoreYes/no": {
      template: "ScoredGoals",
      tabs: [v1.H2H],
      dataPropKeys: { H2H: "FullTimeGoals" },
      requestType: 2,
    },
    "Team2ScoreYes/No": {
      template: "ScoredGoals",
      tabs: [v1.H2H],
      dataPropKeys: { H2H: "FullTimeGoals" },
      requestType: 2,
    },
    Team1TotalGoalsExact: {
      template: "ScoredGoals",
      tabs: [v1.H2H, v1.Individual],
      dataPropKeys: {
        Individual: "FullTimeGoalsAvg",
        H2H: "FullTimeScoredGoalsAvg",
      },
      requestType: 3,
    },
    Team2TotalGoalsExact: {
      template: "ScoredGoals",
      tabs: [v1.H2H, v1.Individual],
      dataPropKeys: {
        Individual: "FullTimeGoalsAvg",
        H2H: "FullTimeScoredGoalsAvg",
      },
      requestType: 3,
    },
    Team1TotalGoals: {
      template: "ScoredGoals",
      tabs: [v1.H2H, v1.Individual],
      dataPropKeys: {
        Individual: "FullTimeGoalsAvg",
        H2H: "FullTimeScoredGoalsAvg",
      },
      requestType: 3,
    },
    Team2TotalGoals: {
      template: "ScoredGoals",
      tabs: [v1.H2H, v1.Individual],
      dataPropKeys: {
        Individual: "FullTimeGoalsAvg",
        H2H: "FullTimeScoredGoalsAvg",
      },
      requestType: 3,
    },
    HomeTeamGoals: {
      template: "ScoredGoals",
      tabs: [v1.H2H, v1.Individual],
      dataPropKeys: {
        Individual: "FullTimeGoalsAvg",
        H2H: "FullTimeScoredGoalsAvg",
      },
      requestType: 3,
    },
    AwayTeamGoals: {
      template: "ScoredGoals",
      tabs: [v1.H2H, v1.Individual],
      dataPropKeys: {
        Individual: "FullTimeGoalsAvg",
        H2H: "FullTimeScoredGoalsAvg",
      },
      requestType: 3,
    },
    Team1OverUnder: {
      template: "ScoredGoals",
      tabs: [v1.Individual],
      dataPropKeys: { Individual: "FullTimeGoalsAvg" },
      requestType: 1,
    },
    Team2OverUnder: {
      template: "ScoredGoals",
      tabs: [v1.Individual],
      dataPropKeys: { Individual: "FullTimeGoalsAvg" },
      requestType: 1,
    },
    Team1TotalOverUnderAsian: {
      template: "ScoredGoals",
      tabs: [v1.Individual],
      dataPropKeys: { Individual: "FullTimeGoalsAvg" },
      requestType: 1,
    },
    Team2TotalOverUnderAsian: {
      template: "ScoredGoals",
      tabs: [v1.Individual],
      dataPropKeys: { Individual: "FullTimeGoalsAvg" },
      requestType: 1,
    },
    SecondHalfHomeTeamTotalGoalsOverUnder: {
      template: "ScoredGoals",
      tabs: [v1.Individual],
      dataPropKeys: { Individual: "SecondHalfGoalsAvg" },
      requestType: 1,
    },
    SecondHalfAwayTeamTotalGoalsOverUnder: {
      template: "ScoredGoals",
      tabs: [v1.Individual],
      dataPropKeys: { Individual: "SecondHalfGoalsAvg" },
      requestType: 1,
    },
    OverUnder: {
      template: "ScoredGoals",
      tabs: [v1.H2H],
      dataPropKeys: { H2H: "FullTimeGoalsAvg" },
      requestType: 2,
    },
    TotalGoals: {
      template: "ScoredGoals",
      tabs: [v1.H2H],
      dataPropKeys: { H2H: "FullTimeGoalsAvg" },
      requestType: 2,
    },
    EvenOddTotal: {
      template: "ScoredGoals",
      tabs: [v1.H2H],
      dataPropKeys: { H2H: "FullTimeGoalsAvg" },
      requestType: 2,
    },
    "2ndHalfTotalOver/Under": {
      template: "ScoredGoals",
      tabs: [v1.H2H],
      dataPropKeys: { H2H: "SecondHalfGoalsAvg" },
      requestType: 2,
    },
    HalfTimeOverUnder: {
      template: "ScoredGoals",
      tabs: [v1.H2H],
      dataPropKeys: { H2H: "FirstHalfScoredGoalsAvg" },
      requestType: 2,
    },
    HalfTimeOverUnderAsian: {
      template: "ScoredGoals",
      tabs: [v1.H2H],
      dataPropKeys: { H2H: "FirstHalfScoredGoalsAvg" },
      requestType: 2,
    },
    SecondHalfAwayTeamTotalGoalsInterval: {
      template: "ScoredGoals",
      tabs: [v1.H2H],
      dataPropKeys: { H2H: "SecondHalfScoredGoalsAvg" },
      requestType: 2,
    },
    SecondHalfTotalGoalsExact: {
      template: "ScoredGoals",
      tabs: [v1.H2H],
      dataPropKeys: { H2H: "SecondHalfScoredGoalsAvg" },
      requestType: 2,
    },
    SecondHalfHomeTeamTotalGoalsExact: {
      template: "ScoredGoals",
      tabs: [v1.H2H],
      dataPropKeys: { H2H: "SecondHalfScoredGoalsAvg" },
      requestType: 2,
    },
    SecondHalfAwayTeamTotalGoalsExact: {
      template: "ScoredGoals",
      tabs: [v1.H2H],
      dataPropKeys: { H2H: "SecondHalfScoredGoalsAvg" },
      requestType: 2,
    },
    SecondHalfEvenOddTotal: {
      template: "ScoredGoals",
      tabs: [v1.H2H],
      dataPropKeys: { H2H: "SecondHalfScoredGoalsAvg" },
      requestType: 2,
    },
  };

  const allMarkets = Helpers.objectToArray(marketList).sort(
    Helpers.byOrderSortingFunc
  );
  for (const marketItem of allMarkets) {
    const groupId = marketItem.group_id || constants.OTHERS;

    if (!groupedMarkets[groupId]) {
      groupedMarkets[groupId] = {
        id: groupId,
        name: marketItem.group_name,
        count: 0,
        order: marketItem.group_order,
        is_new: marketItem.is_new,
      };
    }

    groupedMarkets[groupId].count++;
    groupedMarkets[groupId].is_new =
      groupedMarkets[groupId].is_new || marketItem.is_new;

    const marketKey = `${marketItem.type}_${marketItem.name_template}_${marketItem.sequence}_${marketItem.point_sequence}`;

    if (!marketDetails[marketKey]) {
      const { event, col_count, mobile_col_count, ...restDetails } = marketItem;
      marketCustomFlags[marketKey] =
        !mobile_col_count || mobile_col_count === col_count;
      marketDetails[marketKey] = {
        ...restDetails,
        name: formatName(marketItem.name, team1Name, team2Name),
        col_count: mobile_col_count || col_count,
        group_id: groupId,
        groupKey: marketKey,
        isFavorite: false,
        events: [],
        statsWidgetAvailable: statisticsAvailable && vBe[marketItem.type],
        headers: [],
      };
    }

    marketDetails[marketKey].events.push(
      ...Helpers.objectToArray(marketItem.event).map((eventItem: any) => ({
        ...eventItem,
        shortName: eventItem.name,
        name: formatName(eventItem.name, team1Name, team2Name),
        displayBase: eventItem.base && displayEventBase(eventItem),
        marketId: marketItem.id,
        marketOrder: marketItem.order,
        marketType: marketItem.type,
      }))
    );
  }


  for (const [key, details] of Object.entries(marketDetails)) {
    details.events = sortAndFilterMarketEvents({
      sportAlias,
      displayKey: details.display_key,
      marketType: details.type,
      marketEvents: details.events,
      colCount: details.col_count,
      customizable: marketCustomFlags[key],
    });

    if (marketCustomFlags[key]) {
      details.headers = generateHeaders({
        nameTemplate: details.name_template,
        marketType: details.type,
        colCount: details.col_count,
        events: details.events,
        team1Name,
        team2Name,
      });
    }
  }

  const allGroups = Object.values(groupedMarkets);
  const allDetails = Object.values(marketDetails);

  return { markets: allDetails, marketGroups: allGroups };
}

// Özel sıralama kuralları uygula
function applySpecialSorting(events: any, count: any) {
  const high = [];
  const neutral = [];
  const low = [];

  for (const event of events) {
    if (event.home_value > event.away_value) {
      high.push(event);
    } else if (event.home_value < event.away_value) {
      low.push(event);
    } else {
      neutral.push(event);
    }
  }

  return mergeAndSort(
    [high, neutral, low],
    ["home_value", "away_value"],
    count
  );
}

export function displayEventBase(event: any, initialBase = null): string {
  var baseFieldName = initialBase ? "baseInitial" : "base";
  var prefix =
    event?.marketType &&
    event.marketType.substr(-8) === "Handicap" &&
    event[baseFieldName] > 0
      ? "+"
      : "";

  return (
    prefix +
    (event?.eventBases ? event.eventBases.join("-") : event[baseFieldName])
  );
}

// Bir diğer sıralama yaklaşımı
function anotherSortingApproach(events: any, columnCount: any) {
  const firstColumn = [];
  const secondColumn = [];
  const thirdColumn = [];

  if (columnCount === 2) {
    for (const event of events) {
      if (event.display_column === 1) {
        firstColumn.push(event);
      } else {
        thirdColumn.push(event);
      }
    }
  } else {
    for (const event of events) {
      switch (event.display_column) {
        case 1:
          firstColumn.push(event);
          break;
        case 2:
          secondColumn.push(event);
          break;
        case 3:
          thirdColumn.push(event);
          break;
      }
    }
  }

  return mergeAndSort(
    [firstColumn, secondColumn, thirdColumn],
    "order",
    columnCount
  );
}

// Event listelerini birleştir ve sırala
function mergeAndSort(arrays: any, sortBy: any, count: any) {
  const combined = [];
  let index = -1;

  if (count === 3) {
    const maxLength = Math.max(...arrays.map((arr: any) => arr.length));
    while (++index < maxLength) {
      combined.push(
        arrays[0][index] || {},
        arrays[1][index] || {},
        arrays[2][index] || {}
      );
    }
  } else {
    const combinedSecondary = arrays[1].concat(arrays[2]);
    const maxLength = Math.max(arrays[0].length, combinedSecondary.length);
    while (++index < maxLength) {
      combined.push(arrays[0][index] || {}, combinedSecondary[index] || {});
    }
  }

  return combined;
}

// Bahis türlerini tanımlayan enum
enum BetType {
  Winner = "WINNER",
  Handicap = "HANDICAP",
  Outright = "OUTRIGHT",
  Totals = "TOTALS",
  OddEven = "ODD/EVEN",
  DoubleChance = "DOUBLE CHANCE",
  CorrectScore = "CORRECT SCORE",
  BothTeamToScore = "BOTHTEAMTOSCORE",
}

// Bahis türlerine göre eventTypes döndüren fonksiyon
export const getEventTypes = (betType: any, games?: any): string[] => {
  switch (betType) {
    case BetType.Winner:
      const eventCounts: any = [];

      // Collect the number of events for each market that has a winner
      games &&
        games.forEach((game: any) => {
          if (game.markets) {
            game.markets.forEach((market: any) => {
              eventCounts.push(market.events.length);
            });
          }
        });

      // Determine the available bet options based on event counts
      if (eventCounts.some((count: any) => count === 3)) {
        return ["W1", "X", "W2"]; // Three outcomes possible
      } else {
        return ["W1", "W2"]; // Two outcomes possible
      }
    case BetType.Handicap:
      return ["Home", "Away"];
    case BetType.Totals:
      return ["Over", "Under"];
    case BetType.OddEven:
      return ["Odd", "Even"];
    case BetType.DoubleChance:
      return ["1X", "12", "X2"];
    case BetType.BothTeamToScore:
      return ["Yes", "No"];
    default:
      return [];
  }
};
