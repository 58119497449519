import * as React from 'react';
import { SVGProps } from 'react';
const SvgSportIconFormula1 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		viewBox='0 0 511.99 511.99'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			d='M479.991 319.993c-5.891 0-10.656-4.781-10.656-10.672v-21.326c0-5.891 4.766-10.672 10.656-10.672s10.656 4.781 10.656 10.672v21.326c0 5.891-4.765 10.672-10.656 10.672z'
			style={{
				fill: '#e6e9ed',
			}}
		/>
		<path
			d='M501.334 330.665h-42.687c-5.875 0-10.656-4.781-10.656-10.672s4.781-10.672 10.656-10.672h42.687c5.875 0 10.656 4.781 10.656 10.672s-4.781 10.672-10.656 10.672zM309.338 255.995c-5.906 0-10.688-4.781-10.688-10.672v-10.656c0-5.891 4.781-10.672 10.688-10.672 5.875 0 10.655 4.781 10.655 10.672v10.656c0 5.891-4.78 10.672-10.655 10.672zM234.66 213.324c-5.891 0-10.664-4.766-10.664-10.656v-10.672c0-5.891 4.773-10.672 10.664-10.672 5.89 0 10.671 4.781 10.671 10.672v10.672c0 5.89-4.781 10.656-10.671 10.656z'
			style={{
				fill: '#e6e9ed',
			}}
		/>
		<path
			d='M490.147 254.183c-9.906-5.938-23.375-10.641-40.062-13.984-27.391-5.469-54.296-5.531-55.437-5.531-5.874 0-10.655 4.766-10.655 10.656v42.671c0 5.891 4.781 10.672 10.655 10.672h106.686c5.875 0 10.656-4.781 10.656-10.672 0-9.453-3.781-22.984-21.843-33.812z'
			style={{
				fill: '#ed5564',
			}}
		/>
		<path
			d='M394.648 234.667H255.995v-31.999c0-5.891-4.773-10.672-10.664-10.672-109.443 0-162.27 45.499-164.465 47.437a10.751 10.751 0 0 0-3.43 6.078l-13.266 72.56a10.631 10.631 0 0 0 2.305 8.75 10.635 10.635 0 0 0 8.188 3.844h234.675c2.297 0 4.531-.75 6.391-2.141l42.671-31.998c4.703-3.531 5.656-10.219 2.125-14.938-2.656-3.547-7.109-4.953-11.156-3.938l45.279-21.655c5.906 0 10.688-4.781 10.688-10.672s-4.781-10.656-10.688-10.656z'
			style={{
				fill: '#ed5564',
			}}
		/>
		<path
			d='M255.995 234.667v-31.999c0-5.891-4.773-10.672-10.664-10.672-109.443 0-162.27 45.499-164.465 47.437a10.751 10.751 0 0 0-3.43 6.078l-13.266 72.56a10.631 10.631 0 0 0 2.305 8.75c2.023 2.438 11.016-75.482 14.18-75.482l175.34-16.672z'
			style={{
				fill: '#da4453',
			}}
		/>
		<path
			d='M33.343 287.995H10.664C4.773 287.995 0 283.214 0 277.323v-95.998c0-5.891 4.773-10.656 10.664-10.656h85.334c5.891 0 10.664 4.766 10.664 10.656v21.344c0 2.5-.883 4.937-2.492 6.843l-62.655 74.671a10.643 10.643 0 0 1-8.172 3.812z'
			style={{
				fill: '#e6e9ed',
			}}
		/>
		<path
			d='M74.662 234.667c-29.406 0-53.327 23.921-53.327 53.328 0 29.404 23.921 53.326 53.327 53.326 29.414 0 53.335-23.922 53.335-53.326 0-29.406-23.921-53.328-53.335-53.328z'
			style={{
				fill: '#434a54',
			}}
		/>
		<path
			d='M82.209 295.542c-4.164 4.156-10.922 4.156-15.086 0-4.164-4.172-4.164-10.922 0-15.094 4.164-4.156 10.922-4.156 15.086 0 4.164 4.172 4.164 10.922 0 15.094z'
			style={{
				fill: '#f5f7fa',
			}}
		/>
		<path
			d='M282.655 234.665h10.687v96h-10.687zM234.66 298.667h-63.999c-5.891 0-10.664-4.781-10.664-10.672s4.773-10.672 10.664-10.672h63.999c5.89 0 10.671 4.781 10.671 10.672s-4.781 10.672-10.671 10.672z'
			style={{
				fill: '#da4453',
			}}
		/>
		<path
			d='M394.648 234.667c-29.405 0-53.312 23.921-53.312 53.328 0 29.404 23.906 53.326 53.312 53.326 29.421 0 53.343-23.922 53.343-53.326 0-29.406-23.922-53.328-53.343-53.328z'
			style={{
				fill: '#434a54',
			}}
		/>
		<path
			d='M402.211 295.542c-4.172 4.156-10.937 4.156-15.093 0-4.156-4.172-4.156-10.922 0-15.094 4.156-4.156 10.921-4.156 15.093 0 4.156 4.172 4.156 10.922 0 15.094z'
			style={{
				fill: '#f5f7fa',
			}}
		/>
	</svg>
);
export default SvgSportIconFormula1;
