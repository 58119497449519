import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar3 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar3_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar3_svg__cls-3{fill:#8f5653}.avatar3_svg__cls-4{fill:#393c54}.avatar3_svg__cls-8{fill:none;stroke-linecap:round;stroke:#515570;stroke-linejoin:round;stroke-width:2px;opacity:.4}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.3,
				fill: '#fff',
			}}
		/>
		<circle cx={91.32} cy={60.43} r={7.93} className='avatar3_svg__cls-3' />
		<path
			d='M64 124a59.77 59.77 0 0 0 39.83-15.14l-2.23-5.22c-1.71-4-6.22-6.64-11.29-6.64H37.69c-5.07 0-9.58 2.66-11.29 6.64l-2.23 5.22A59.77 59.77 0 0 0 64 124Z'
			className='avatar3_svg__cls-4'
		/>
		<path
			d='M77.43 98.73a13.43 13.43 0 0 1-26.86 0c0-5 6-3.25 13.43-3.25s13.43-1.8 13.43 3.25Z'
			style={{
				fill: '#ff8475',
			}}
		/>
		<path
			d='M64 84.75V98.5'
			style={{
				stroke: '#8f5653',
				strokeMiterlimit: 10,
				strokeWidth: 20,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={36.68} cy={60.43} r={7.93} className='avatar3_svg__cls-3' />
		<path
			d='M64 94.37a28.31 28.31 0 0 1-28.32-28.32V47.43a28.32 28.32 0 1 1 56.64 0v18.62A28.31 28.31 0 0 1 64 94.37Z'
			style={{
				fill: '#b56b63',
			}}
		/>
		<circle cx={76.67} cy={59.28} r={3} className='avatar3_svg__cls-4' />
		<circle cx={49.67} cy={59.28} r={3} className='avatar3_svg__cls-4' />
		<path d='M74.39 51 84 52.75M53 51l-9.61 1.75' className='avatar3_svg__cls-8' />
		<path
			d='m63.11 60-4.67 10.69a1 1 0 0 0 .92 1.4h9.34a1 1 0 0 0 .91-1.4L64.94 60a1 1 0 0 0-1.83 0Z'
			className='avatar3_svg__cls-3'
		/>
		<path
			d='M92.32 47.43a28.32 28.32 0 1 0-56.64 0V53h.93c3.87 0 7-11.13 7-15v.17c3.52 3.76 11.38 6.38 20.52 6.38 9.46 0 17.54-2.8 20.87-6.77V38c0 3.87 3.13 15 7 15h.32Z'
			style={{
				opacity: 0.4,
				fill: '#8f5653',
			}}
		/>
		<path
			d='M70.21 76a.79.79 0 0 1 .78.89 7 7 0 0 1-14 0 .79.79 0 0 1 .78-.89Z'
			style={{
				fill: '#fff',
			}}
		/>
		<path d='M60.17 86h7.66' className='avatar3_svg__cls-8' />
	</svg>
);
export default SvgAvatar3;
