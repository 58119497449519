import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import SelectInput from '../../component/SelectInput';
import TextInput from '../../component/TextInput';
import useCombine from '../../hooks/useCombine';
import CountryList from '../../common/CountryList';
import LoadingSpinner from '../../component/LoadingSpinner';
import SocketContext from '../../context/SocketContext';
import { toast } from 'react-toastify';

const ProfileDetails: React.FC = () => {
	const { t } = useTranslation();
	const { userProfile, setUserProfile, mobileDesign } = useCombine();
	const { sendRequest } = useContext(SocketContext);
	const [isLoading, setIsLoading] = React.useState(false);

	if (!userProfile.customerId || isLoading) {
		return <LoadingSpinner loading />;
	}

	const handleSubmit = (values: any, { setSubmitting }: any) => {
		setIsLoading(true);
		sendRequest({ A: 'updateProfile', D: { updateData: values } }, (response: any) => {
			if (response.E) {
				toast.error(t(response.E));
			} else {
				toast.success(t('profileUpdated'));
			}
			setUserProfile(response.R);
			setSubmitting(false);
			setIsLoading(false);
		});
	};

	return (
		<>
			{!mobileDesign && <div className='overlay-header'>{t('Profile Details')}</div>}
			<div
				className='u-i-e-p-p-content-bc u-i-common-content user-profile'
				data-scroll-lock-scrollable=''>
				<Formik
					initialValues={{
						id: userProfile.customerId,
						username: userProfile.username,
						name: userProfile.name || '',
						surname: userProfile.surname || '',
						birthDay: userProfile.birthDay || '',
						gender: userProfile.gender || '',
						email: userProfile.email || '',
						doc_number: userProfile.docId || '',
						Country: userProfile.Country || '',
						City: userProfile.City || '',
						Address: userProfile.Address || '',
						Phone: userProfile.Phone || '',
						subscribeEmail: userProfile.subscribeEmail || 'false',
						subscribeSMS: userProfile.subscribeSMS || 'false',
						password: '',
					}}
					validationSchema={Yup.object({
						password: Yup.string().required(t('Required to save changes')),
					})}
					onError={(e: any) => {
						console.log('error', e);
					}}
					onSubmit={handleSubmit}>
					{({
						setFieldValue,
						setFieldTouched,
						handleChange,
						isSubmitting,
						isValid,
						values,
					}: any) => (
						<Form>
							<div className='u-i-p-control-item-holder-bc'>
								<div className='eligible-bonus'>
									<i className='bc-i-player-info'></i>
									<span className='eligible-bonus-title'>
										{t('Eligible for bonus')}:{' '}
									</span>
									<b className='eligible-bonus-status green'>{t('Yes')}</b>
								</div>
							</div>
							<div className='userProfile-content' data-scroll-lock-scrollable=''>
								<div className='userProfileWrapper-bc userProfileSection-0'>
									<div className='u-i-p-control-item-holder-bc'>
										<TextInput label={t('ID')} name='id' readOnly id='id' />
									</div>
									<div className='u-i-p-control-item-holder-bc'>
										<TextInput
											label={t('Username')}
											name='username'
											id='username'
											readOnly
										/>
									</div>
									<div className='u-i-p-control-item-holder-bc'>
										<TextInput label={t('First Name')} name='name' id='name' />
									</div>
									<div className='u-i-p-control-item-holder-bc'>
										<TextInput
											label={t('Last Name')}
											name='surname'
											id='surname'
										/>
									</div>
									<div className='u-i-p-control-item-holder-bc'>
										<TextInput
											label={t('Birth Date')}
											name='birthDay'
											id='birthDay'
											type='date'
										/>
									</div>
									<SelectInput
										options={[
											{ value: 'Male', label: t('Male') },
											{ value: 'Female', label: t('Female') },
										]}
										id='gender'
										name='gender'
										label={t('Gender')}
										value={values.gender}
										onChange={(value) => setFieldValue('gender', value)}
									/>
									<div className='u-i-p-control-item-holder-bc'>
										<TextInput
											label={t('E-mail')}
											name='email'
											readOnly
											id='email'
										/>
									</div>
									<div className='u-i-p-control-item-holder-bc'>
										<TextInput
											label={t('Passport / ID')}
											name='doc_number'
											id='doc_number'
										/>
									</div>
									<SelectInput
										options={CountryList.map((country) => ({
											value: country,
											label: t(country),
										}))}
										id='Country'
										name='Country'
										label={t('Country')}
										value={values.Country}
										onChange={(value) => setFieldValue('Country', value)}
									/>
									<div className='u-i-p-control-item-holder-bc'>
										<TextInput label={t('City')} name='City' id='City' />
									</div>
									<div className='u-i-p-control-item-holder-bc'>
										<TextInput
											label={t('Address')}
											name='Address'
											id='Address'
										/>
									</div>
									<div className='u-i-p-control-item-holder-bc'>
										<TextInput
											label={t('Phone number')}
											name='Phone'
                      id="Phone" 
											type='tel'
										/>
									</div>
								</div>
								<div className='userProfileWrapper-bc userProfileSection-1'>
									<div className='u-i-p-control-item-holder-bc'>
										<div className='entrance-f-item-bc'>
											<div className='entrance-f-error-message-bc'>
												{t('Subscribe for')}
											</div>
										</div>
									</div>
									<div className='u-i-p-control-item-holder-bc'>
										<hr className='subscribeBelowSeparator' />
									</div>
									{['subscribeEmail', 'subscribeSMS'].map((field) => (
										<div className='u-i-p-control-item-holder-bc' key={field}>
											<div className='radio-v-bc undefined'>
												<div className='form-control-title-static-bc ellipsis'>
													{t(
														field
															.replace(/_/g, ' ')
															.replace(/\b\w/g, (l) =>
																l.toUpperCase(),
															),
													)}
												</div>
												<div className='radio-control-bc'>
													<label className='radio-control-content-bc'>
														<input
															type='radio'
															className='radio-control-input-bc'
															name={field}
															value='true'
															checked={values[field] === 'true'}
															onChange={handleChange}
														/>
														<span className='radio-control-input-holder-bc'></span>
														<p className='radio-control-text-bc'>
															{t('Yes')}
														</p>
													</label>
												</div>
												<div className='radio-control-bc'>
													<label className='radio-control-content-bc'>
														<input
															type='radio'
															className='radio-control-input-bc'
															name={field}
															value='false'
															checked={values[field] === 'false'}
															onChange={handleChange}
														/>
														<span className='radio-control-input-holder-bc'></span>
														<p className='radio-control-text-bc'>
															{t('No')}
														</p>
													</label>
												</div>
											</div>
										</div>
									))}
								</div>
								<div className='userProfileWrapper-bc userProfileSection-2'>
									<div className='u-i-p-control-item-holder-bc'>
										<hr className='passwordAboveSeparator' />
									</div>
									<div className='u-i-p-control-item-holder-bc'>
										<div className='entrance-f-item-bc'>
											<div className='entrance-f-error-message-bc'>
												{t('Enter your password to save changes')}
											</div>
										</div>
									</div>
									<div className='u-i-p-control-item-holder-bc'>
										<TextInput
											label={t('Current password')}
											name='password'
											type='password'
                      id="password" 
										/>
									</div>
								</div>
							</div>
							<div className='u-i-p-c-footer-bc'>
								<button
									className='btn a-color right-aligned'
									type='submit'
									title={t('Save Changes')}
									disabled={isSubmitting || !isValid}>
									<span>{t('Save Changes')}</span>
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
};

export default ProfileDetails;
