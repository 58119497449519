import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar34 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar34_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar34_svg__cls-1{fill:#f85565}.avatar34_svg__cls-6{fill:#fff}.avatar34_svg__cls-8{opacity:.5}.avatar34_svg__cls-5{fill:#fba875}.avatar34_svg__cls-7{fill:#356cb6}.avatar34_svg__cls-15,.avatar34_svg__cls-8{fill:#393c54}.avatar34_svg__cls-12,.avatar34_svg__cls-17{fill:none;stroke-linecap:round}.avatar34_svg__cls-10{fill:#ffbb94}.avatar34_svg__cls-12{stroke:#515570}.avatar34_svg__cls-12,.avatar34_svg__cls-17{stroke-linejoin:round}.avatar34_svg__cls-12{stroke-width:2px;opacity:.1}.avatar34_svg__cls-16{fill:#f8dc25}.avatar34_svg__cls-17{stroke:#f2bc0f;stroke-width:3px}'
				}
			</style>
		</defs>
		<circle cx={64} cy={64} r={60} className='avatar34_svg__cls-1' />
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.2,
				fill: '#fff',
			}}
		/>
		<path
			d='M34 77.5V50.86c0-16.38 12.84-30.25 29.21-30.67A30.07 30.07 0 0 1 94 50.34V77.5a5.5 5.5 0 0 1-5.5 5.5h-49a5.5 5.5 0 0 1-5.5-5.5Z'
			style={{
				fill: '#f2bc0f',
			}}
		/>
		<path
			d='M34 50.46V58h60v-8.12A8.89 8.89 0 0 0 85.11 41H43a9 9 0 0 0-9 8.77v.69Z'
			style={{
				opacity: 0.5,
				fill: '#fff',
			}}
		/>
		<circle cx={88.5} cy={63.5} r={7.5} className='avatar34_svg__cls-5' />
		<path
			d='M64 124.1a59.78 59.78 0 0 0 40-15.28l-2.39-5.68c-1.71-4-6.22-6.64-11.29-6.64H37.69c-5.07 0-9.58 2.66-11.29 6.64l-2.4 5.68a59.78 59.78 0 0 0 40 15.28Z'
			className='avatar34_svg__cls-6'
		/>
		<path
			d='M47 96.5h-9.31c-5.07 0-9.58 2.66-11.29 6.64l-2.4 5.68a59.84 59.84 0 0 0 33.07 14.87Z'
			className='avatar34_svg__cls-7'
		/>
		<path
			d='m46.98 96.5 9.7 26.67L40.88 108l5.39-2.68-8.58-8.82h9.29z'
			className='avatar34_svg__cls-8'
		/>
		<path
			d='M81.11 96.5h9.29c5.06 0 9.58 2.66 11.29 6.64l2.39 5.68A59.84 59.84 0 0 1 71 123.69Z'
			className='avatar34_svg__cls-7'
		/>
		<path
			d='m81.11 96.5-9.7 26.67L87.2 108l-5.38-2.68 8.58-8.82h-9.29z'
			className='avatar34_svg__cls-8'
		/>
		<path
			d='M81.72 98.25a3.06 3.06 0 0 0-3.08-2.88H49.36a3.07 3.07 0 0 0-3.08 2.93v.32l10.52 16.64 7.2-7.21 7.17 7.17S81.73 98.49 81.73 99c0 .26 0-.51-.01-.75Z'
			className='avatar34_svg__cls-6'
		/>
		<path
			d='M64 86v12'
			style={{
				stroke: '#fba875',
				strokeMiterlimit: 10,
				strokeWidth: 20,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={63.5} cy={86.5} r={9.5} className='avatar34_svg__cls-10' />
		<circle cx={38.5} cy={63.5} r={7.5} className='avatar34_svg__cls-5' />
		<path
			d='M38 73.68V51.61c0-13.22 9.7-24.7 22.82-26.25A26 26 0 0 1 90 51.12v22.56A18.32 18.32 0 0 1 71.68 92H56.32A18.32 18.32 0 0 1 38 73.68Z'
			className='avatar34_svg__cls-10'
		/>
		<path
			d='M60.19 84.14a6.43 6.43 0 0 1 7.33 0'
			style={{
				opacity: 0.4,
				strokeWidth: 2,
				strokeLinejoin: 'round',
				stroke: '#515570',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path d='M64 71a3 3 0 0 1-3-3V50h6v18a3 3 0 0 1-3 3Z' className='avatar34_svg__cls-5' />
		<path d='M70.65 86.31a6.87 6.87 0 0 1-13.74 0' className='avatar34_svg__cls-12' />
		<path
			d='M60.16 123.86c1.27.08 2.55.14 3.84.14s2.57-.06 3.84-.14L67 115h-6Z'
			className='avatar34_svg__cls-1'
		/>
		<path
			d='M64.58 117.22h-1.32a4.58 4.58 0 0 1-4.58-4.58V108h10.49v4.64a4.58 4.58 0 0 1-4.59 4.58Z'
			style={{
				fill: '#ff8475',
			}}
		/>
		<path
			d='m64 108 10.17-12.62h4.47l-7.47 19.84L64 108zM64 108 53.83 95.38h-4.47l7.47 19.84L64 108z'
			className='avatar34_svg__cls-6'
		/>
		<path
			d='M81.72 98.25a3.06 3.06 0 0 0-3.08-2.88h-4.47L64 108 53.83 95.37h-4.47a3.06 3.06 0 0 0-3.08 2.92v.32l10.52 16.64 7.2-7.2 7.17 7.17L81.73 99c0-.26 0-.51-.01-.75Z'
			style={{
				opacity: 0.1,
				fill: '#356cb6',
			}}
		/>
		<circle cx={50.5} cy={58.5} r={5.5} className='avatar34_svg__cls-6' />
		<circle cx={51} cy={59} r={3} className='avatar34_svg__cls-15' />
		<path
			d='M30 25h40.9a16.86 16.86 0 0 1 16.87 16.86A8.14 8.14 0 0 1 79.63 50H53.78A23.78 23.78 0 0 1 30 26.22Z'
			className='avatar34_svg__cls-16'
		/>
		<path
			d='M80.5 44.5h-26a18.42 18.42 0 0 1-5.23-.75A18.2 18.2 0 0 1 36.5 26.18V23.5'
			className='avatar34_svg__cls-17'
		/>
		<path d='M76.5 38.5H54.26a12.92 12.92 0 0 1-8.56-3.21' className='avatar34_svg__cls-17' />
		<circle cx={77.5} cy={58.5} r={5.5} className='avatar34_svg__cls-6' />
		<path d='M56 58.5a5.5 5.5 0 0 1-11 0' className='avatar34_svg__cls-5' />
		<circle cx={77} cy={59} r={3} className='avatar34_svg__cls-15' />
		<path d='M83 58.5a5.5 5.5 0 0 1-11 0' className='avatar34_svg__cls-5' />
		<path
			d='M85.8 52H71.63l-1 4H83.8l2-4zM42.23 52H56.4l1 4H44.23l-2-4z'
			className='avatar34_svg__cls-16'
		/>
		<path
			d='m57.23 66.73-1 2.71a3.54 3.54 0 0 1-3.32 2.33h0a3.52 3.52 0 0 0-3.38 2.5l-1.07 3.52M70.47 66.73l1 2.71a3.52 3.52 0 0 0 3.31 2.33h0a3.52 3.52 0 0 1 3.38 2.5l1.07 3.52M85 59h2M85.17 61.75l2.2.91M42 59h-2M42.47 61.75l-2.2.91'
			className='avatar34_svg__cls-12'
		/>
		<path
			d='M72 80.68 70 78H58l-2 2.68'
			style={{
				stroke: '#ff8475',
				strokeWidth: 2,
				strokeLinejoin: 'round',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
	</svg>
);
export default SvgAvatar34;
