import React, { lazy } from "react";
import { RouteProps } from "react-router-dom";
import { atoxPagesMenu } from "../menu";

const FooterList = {
  SPORTFOOTER: lazy(() => import("../pages/_layout/Footer/Footer")),
};

const footers: RouteProps[] = [
  { path: atoxPagesMenu.login.path, element: null },
  { path: atoxPagesMenu.signUp.path, element: null },
  { path: atoxPagesMenu.forgotPassword.path, element: null },
  { path: atoxPagesMenu.casino.path + "/*", element: null },
  { path: atoxPagesMenu.liveCasino.path + "/*", element: null }, 
  { path: "*", element: <FooterList.SPORTFOOTER /> },
];

export default footers;
