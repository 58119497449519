import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar26 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar26_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar26_svg__cls-14,.avatar26_svg__cls-2{fill:#f85565}.avatar26_svg__cls-2{opacity:.4}.avatar26_svg__cls-5{fill:#fbc0aa}.avatar26_svg__cls-7{fill:#356cb6;opacity:.3}.avatar26_svg__cls-12,.avatar26_svg__cls-16{fill:none;stroke-linecap:round}.avatar26_svg__cls-16{stroke-linejoin:round}.avatar26_svg__cls-12{stroke:#fff;stroke-miterlimit:10}.avatar26_svg__cls-12,.avatar26_svg__cls-16{stroke-width:2px}.avatar26_svg__cls-12,.avatar26_svg__cls-14{opacity:.1}.avatar26_svg__cls-13{fill:#515570}.avatar26_svg__cls-16{stroke:#515570;opacity:.2}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#ff8475',
			}}
		/>
		<circle cx={64} cy={64} r={48} className='avatar26_svg__cls-2' />
		<path
			d='M64 14a32 32 0 0 1 32 32v41a6 6 0 0 1-6 6H38a6 6 0 0 1-6-6V46a32 32 0 0 1 32-32Z'
			style={{
				fill: '#7f3838',
			}}
		/>
		<path
			d='M62.73 22h2.54A23.73 23.73 0 0 1 89 45.73v42.82A4.45 4.45 0 0 1 84.55 93h-41.1A4.45 4.45 0 0 1 39 88.55V45.73A23.73 23.73 0 0 1 62.73 22Z'
			style={{
				fill: '#393c54',
				opacity: 0.4,
			}}
		/>
		<circle cx={89} cy={65} r={7} className='avatar26_svg__cls-5' />
		<path
			d='M64 124a59.67 59.67 0 0 0 34.69-11.06l-3.32-9.3A10 10 0 0 0 86 97H42.05a10 10 0 0 0-9.42 6.64l-3.32 9.3A59.67 59.67 0 0 0 64 124Z'
			style={{
				fill: '#4bc190',
			}}
		/>
		<path
			d='m45 110 5.55 2.92-2.55 8.92a60.14 60.14 0 0 0 9 1.74V96.5l-12.38 10.25A2 2 0 0 0 45 110ZM71 96.5v27.09a60.14 60.14 0 0 0 9-1.74l-2.54-8.93L83 110a2 2 0 0 0 .41-3.25Z'
			className='avatar26_svg__cls-7'
		/>
		<path
			d='M57 123.68a58.54 58.54 0 0 0 14 0V98H57Z'
			style={{
				fill: '#fff',
			}}
		/>
		<path
			d='M64 88.75v9.75'
			style={{
				strokeWidth: 14,
				strokeLinejoin: 'round',
				stroke: '#fbc0aa',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={39} cy={65} r={7} className='avatar26_svg__cls-5' />
		<path
			d='M64 91a25 25 0 0 1-25-25V49.52a25 25 0 1 1 50 0V66a25 25 0 0 1-25 25Z'
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<path
			d='M91.49 51.12V46.4c0-14.95-11.71-27.61-26.66-28a27.51 27.51 0 0 0-28.32 27.42v5.33a2 2 0 0 0 2 2h6.81a8 8 0 0 0 6.5-3.33l4.94-6.88a18.45 18.45 0 0 1 1.37 1.63A22.84 22.84 0 0 0 76 53.15h13.45a2 2 0 0 0 2.04-2.03Z'
			style={{
				fill: '#bc5b57',
			}}
		/>
		<path
			d='M62.76 36.94C67 45.68 73.47 47.15 78.85 47.15h5M71 35c2.52 5.22 6.39 6.09 9.6 6.09h3'
			className='avatar26_svg__cls-12'
		/>
		<circle cx={76} cy={62.28} r={3} className='avatar26_svg__cls-13' />
		<circle cx={52} cy={62.28} r={3} className='avatar26_svg__cls-13' />
		<ellipse cx={50.42} cy={69.67} className='avatar26_svg__cls-14' rx={4.58} ry={2.98} />
		<ellipse cx={77.58} cy={69.67} className='avatar26_svg__cls-14' rx={4.58} ry={2.98} />
		<path
			d='M64 67v4'
			style={{
				strokeWidth: 4,
				strokeLinejoin: 'round',
				stroke: '#fbc0aa',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path d='M55 56h-9.25M82 56h-9.25' className='avatar26_svg__cls-16' />
		<path d='M64 84c5 0 7-3 7-3H57s2 3 7 3Z' className='avatar26_svg__cls-2' />
		<path
			d='m65.07 78.93-.55.55a.73.73 0 0 1-1 0l-.55-.55c-1.14-1.14-2.93-.93-4.27.47L57 81h14l-1.66-1.6c-1.34-1.4-3.13-1.61-4.27-.47Z'
			style={{
				fill: '#f85565',
			}}
		/>
	</svg>
);
export default SvgAvatar26;
