import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomLimbo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		aria-hidden='true'
		className='svg-icon'
		viewBox='0 0 64 64'
		{...props}>
		<g fill='#ff9d27'>
			<path d='M10.9 48.7c4-4 4.4-5 6.9-2.5s1.5 2.8-2.5 6.9c-3 3-6.8 2.4-6.8 2.4s-.6-3.8 2.4-6.8' />
			<path d='M18.5 52.8c1.6-4.2 2.1-4.7-.2-6-2.3-1.3-2.3-.4-3.8 3.8-1.2 3.1.2 5.9.2 5.9s2.7-.5 3.8-3.7' />
		</g>
		<path
			fill='#fdf516'
			d='M16.2 48.9c.9-2.3.9-2.8 2.1-2.1 1.3.7 1 1 .1 3.3-.6 1.7-2.1 2.1-2.1 2.1s-.7-1.5-.1-3.3'
		/>
		<path
			fill='#ff9d27'
			d='M17.1 45.7c-1.3-2.3-1.8-1.8-6-.2-3.1 1.2-3.7 3.8-3.7 3.8s2.8 1.4 5.9.2c4.2-1.6 5.1-1.6 3.8-3.8'
		/>
		<g fill='#fdf516'>
			<path d='M15 47.8c2.3-.9 2.8-.9 2.1-2.1-.7-1.3-1-1-3.3-.1-1.7.6-2.1 2.1-2.1 2.1s1.6.7 3.3.1' />
			<path d='M13.9 47.6c2.2-2.2 2.4-2.8 3.8-1.4s.8 1.6-1.4 3.8c-1.7 1.7-3.8 1.3-3.8 1.3s-.2-2 1.4-3.7' />
		</g>
		<path fill='#3baacf' d='M18.5 38C12.3 27.6 2 31.9 2 31.9s14.7-14.7 24.6-4.8L18.5 38z' />
		<path fill='#428bc1' d='m23.3 30.3 3.2-3.2C16.7 17.2 2 31.9 2 31.9s12.9-9.2 21.3-1.6' />
		<path fill='#3baacf' d='M26 45.5C36.4 51.7 32.1 62 32.1 62s14.7-14.7 4.8-24.6L26 45.5z' />
		<path fill='#428bc1' d='m33.7 40.7 3.2-3.2c9.9 9.9-4.8 24.6-4.8 24.6s9.2-13 1.6-21.4' />
		<path
			fill='#c5d0d8'
			d='M48.8 30.9C37.1 42.5 24.2 48.8 19.7 44.3c-4.5-4.5 1.8-17.4 13.4-29.1 13.6-13.6 28.7-13 28.7-13s.5 15.1-13 28.7'
		/>
		<path
			fill='#dae3ea'
			d='M45.8 27.6C34.2 39.2 22.6 46.8 19.9 44.1c-2.7-2.7 4.9-14.3 16.5-25.9C50 4.6 62 2 62 2s-2.6 12-16.2 25.6z'
		/>
		<path
			fill='#c94747'
			d='M24.3 47.5c-.5.5-1.3.5-1.8 0l-6-6c-.5-.5-.5-1.4 0-1.9l1.8-1.8 7.8 7.8-1.8 1.9'
		/>
		<path
			fill='#f15744'
			d='M22.6 45.7c-.5.5-1.1.7-1.4.4l-3.4-3.4c-.3-.3-.1-.9.4-1.4l1.8-1.8 4.4 4.4-1.8 1.8'
		/>
		<path
			fill='#3e4347'
			d='M20.9 48.2c-.3.3-1 .3-1.3 0l-3.9-3.9c-.3-.3-.2-.9.1-1.2l1.2-1.2 5.1 5.1-1.2 1.2'
		/>
		<path
			fill='#62727a'
			d='M20.1 47.4c-.3.3-.9.4-1.1.2l-2.7-2.7c-.2-.2-.1-.7.3-1l1.2-1.2 3.5 3.5-1.2 1.2'
		/>
		<path fill='#c94747' d='M61.8 2.2S56.4 2 49.1 4.8l10.1 10.1C62 7.6 61.8 2.2 61.8 2.2' />
		<path fill='#f15744' d='M61.8 2.2s-4.3.9-10.8 4.6l6.2 6.2c3.7-6.5 4.6-10.8 4.6-10.8' />
		<circle cx={43.5} cy={20.5} r={5} fill='#edf4f9' />
		<circle cx={43.5} cy={20.5} r={3.3} fill='#3baacf' />
		<circle cx={33.5} cy={30.5} r={5} fill='#edf4f9' />
		<circle cx={33.5} cy={30.5} r={3.3} fill='#3baacf' />
		<g fill='#fff'>
			<path d='M48.9 6.9c-.3.3-.9.3-1.2 0-.3-.3-.3-.9 0-1.2.3-.3.9-.3 1.2 0 .3.3.3.9 0 1.2' />
			<circle cx={50.6} cy={8.6} r={0.8} />
			<circle cx={53} cy={11} r={0.8} />
			<circle cx={55.3} cy={13.4} r={0.8} />
			<circle cx={57.7} cy={15.7} r={0.8} />
		</g>
	</svg>
);
export default SvgCustomLimbo;
