import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionKiribati = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		viewBox='0 0 600 300'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.regionKiribati_svg__str1{stroke:#000;stroke-width:.46;stroke-miterlimit:22.9256}.regionKiribati_svg__fil3{fill:none}'
				}
			</style>
		</defs>
		<path fill='#c81010' d='M0 0h600v162H0z' />
		<g id='regionKiribati_svg__bird'>
			<path
				id='regionKiribati_svg__bird_outline'
				fill='#f8d000'
				d='M305.75 27.34c-19.08-7.89-30.76-9.34-43.81-9.47-18.9.13-27.92 8.25-45.94 9 7.14 7.69 33.02 9.57 43.93-.53 16.22 1.69 19.9 6.07 24.25 11.52-6.77-1.07-14.57-3.02-21.04 4.14-5.46 1.1-7.99 0-17.33 0-4.36 0-5.87 2.14-5.81 6 2.82-3.9 22.31-1.6 27.09 0 3.67 1.23 7.57 6.32 12.22 7.15 12.19 2.17 29.68-4.2 45.81-8.54L360 42h-23.93L366 36h-32.54c-.69-.79-2.34-1.81-3.66-2.68 8.37-5.39 15.77-15.19 17.66-19.92 15.09-.15 24.83-1.55 36.54-4.29-77.18-8.54-59.61.67-78.25 18.23z'
				className='regionKiribati_svg__str1'
			/>
			<path
				id='regionKiribati_svg__eye'
				fill='currentColor'
				d='M270.06 39.89c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5c-.82 0-1.5-.67-1.5-1.5s.68-1.5 1.5-1.5z'
			/>
			<path
				id='regionKiribati_svg__eye_outline'
				fill='none'
				fillRule='nonzero'
				stroke='currentColor'
				strokeMiterlimit={22.926}
				strokeWidth={0.15}
				d='M270.06 39.89c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5c-.82 0-1.5-.67-1.5-1.5s.68-1.5 1.5-1.5z'
			/>
			<path
				d='M290.13 38.29S283.86 42 279.7 42m45.42 4.61S330.85 42 336.07 42M233.82 24.6s4.66-2.44 6.86-3.09m12.45-.39s7.39-3.19 15.62-3.03m4.36 3.81s9.69-1.63 18.64.36m-7.5 1.98s8.04-1.35 15.75.83m-7.48 1.62s8.19.11 12.26 1.69M216 26.87s11.49 3.14 20.83-1.35m-19.37 2.71s14.92 3.68 21.74-3.15m-16.74 5.73s11.24 3.62 20.08-5.72m-9.64 7.99s9.08-2.6 12.52-7.95m-5.42 8.1s6.09-4.22 8.28-7.98m70.18 3.22s2.38 2.99 11.34 4.85m-9.76-6.26s3.85 3.85 11.87 4.79m-9.93-6.6s3.91 3.36 12.42 4.61m-10.49-6.54s5.98 3.99 12.79 4.45m-10.43-6.97s4.19 3.91 12.5 4.88m-10.74-6.89s4.91 4.22 12.63 4.8m-10.9-6.9s3.01 3.48 12.94 4.39m-11.18-6.66s3.35 3.68 12.78 4.48m-11.41-6.35s5.9 4.45 12.73 4.36m-11.56-6.06s7.8 4.68 12.45 4.53m-11.03-6.69s6.2 3.86 11.98 4.71m-38.62 16.98s2.5 1.89 4.84 1.81m-3.4-3.21s2.17 1.85 5.15 1.84m-3.4-3.56s2.46 2.3 5.41 1.88m-3.75-3.54s2.94 2 5.57 1.94m-3.73-3.83s2.18 1.89 5.63 2.07m-3.99-4.15s2.56 2.27 5.82 2.35m-4.09-3.85s2.48 1.59 5.99 1.89m-4.42-3.64s3.35 1.18 6.42 1.47m-4.61-3.61s2.31 1.62 6.41 1.52m-4.78-3.66s2.74 1.82 6.26 1.86m-5.06-3.89s3.68 2.06 6.85 1.6m-5.51-4.03s2.33 2.55 6.67 2.46m-5.73-3.56s4.18 2.35 7.01 1.75m-5.24-3.08s3.17 1.93 6.07 1.85m-2.85-2.82s5.65 2.88 13.29-.24m-8.48 2.29s11.42 2.62 16.37-1.96m-7.41 2.64s7.85.82 16.63-1.96m-11.41 2.01s11.92 2.4 32.63.09M243.24 32.96s5.33-3.51 7.54-7.55m-3.55 6.9s4.29-4.16 5.54-6.74m-1.58 5.67s3.53-3.17 4.31-5.39m-21.55-.19s7.66-1.67 25.98.68m-18.9-3.74s6.42-3.11 12.14-4.06m-5.76 2.77s4.69-2.21 11.61-3.31m-.95 2.89s6.49-2.77 14.43-2.51m-6.22 2.56s8.9-1.99 17.1-.87m6.73 5.22s6.79-.31 14.6 1.63m-26.21-4.36s9.73-.52 16.4.71m43.77-14.7s3.36 2.91 12.72 4.76m-7.44-4.29s5.26 2.99 13.56 4.03m-8.04-3.99s7.38 3.21 15.18 3.41m-7.01-2.71s7.52 1.81 16.84 1.32m-112.07 9.68s5.1-2.1 14.14-1.93m19.49 9.57s3.06-.37 6.86 1.44'
				className='regionKiribati_svg__fil3 regionKiribati_svg__str1'
			/>
			<path
				d='M284.18 37.86c2.11.33 4.12.58 5.95.43 7.16-3.91 12.05-7.58 15.62-10.95'
				className='regionKiribati_svg__fil3 regionKiribati_svg__str1'
			/>
		</g>
		<g fill='#f8d000' stroke='currentColor' strokeMiterlimit={22.926} strokeWidth={0.46}>
			<g id='regionKiribati_svg__rays_6'>
				<g id='regionKiribati_svg__rays_2' transform='rotate(-84.706 300 156)'>
					<path
						id='regionKiribati_svg__wavy'
						d='M290.63 105.87A50 50 0 0 1 300 66a50 50 0 0 0 9.37 39.87'
						transform='rotate(13.4 300 156)'
					/>
					<path id='regionKiribati_svg__straight' d='M290.63 105.87 300 63l9.37 42.87' />
				</g>
				<use xlinkHref='#regionKiribati_svg__rays_2' transform='rotate(21.18 300 156)' />
				<use xlinkHref='#regionKiribati_svg__rays_2' transform='rotate(42.35 300 156)' />
			</g>
			<use xlinkHref='#regionKiribati_svg__rays_6' transform='rotate(63.53 300 156)' />
			<use xlinkHref='#regionKiribati_svg__rays_6' transform='rotate(127.06 300 156)' />
			<circle cx={300} cy={156} r={51} />
		</g>
		<path fill='#183070' d='M0 162h600v138H0z' />
		<path
			id='regionKiribati_svg__wave'
			fill='none'
			stroke='#fff'
			strokeWidth={24}
			d='M-36 168q30-12 60 0t60 0 60 0 60 0q30-12 63 0t66 0q33-12 63 0t60 0 60 0 60 0 60 0'
		/>
		<use xlinkHref='#regionKiribati_svg__wave' y={48} />
		<use xlinkHref='#regionKiribati_svg__wave' y={96} />
	</svg>
);
export default SvgRegionKiribati;
