import React, { useState } from "react";

const CopyButton: React.FC<any> = (props: any) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (text: any) => {
    if (text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
        console.log("Copied to clipboard successfully!", text);
      } catch (err) {
        setIsCopied(false);
        console.error("Could not copy text: ", err);
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <i
      className={
        isCopied
          ? "u-i-p-p-u-i-d-user-id-copy-bc bc-i-checked"
          : "u-i-p-p-u-i-d-user-id-copy-bc bc-i-copy"
      }
      onClick={() => copyToClipboard(props.copyText)}
      style={{ cursor: "pointer" }}
    ></i>
  );
};

export default CopyButton;
