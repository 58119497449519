import * as React from 'react';
import { SVGProps } from 'react';
const SvgRay = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#6C45DC' />
			<g fill='#FFF'>
				<path d='M24.505 13.256v7.673l-8.508 4.927-8.512-4.927v-9.854L16 6.14l6.539 3.79.988-.57L16 5l-9.5 5.5v11L16 27l9.5-5.5v-8.814z' />
				<path d='M13.617 20.936h-1.422v-4.79h4.745a1.693 1.693 0 0 0 1.195-.508c.317-.32.492-.753.496-1.203a1.657 1.657 0 0 0-.5-1.196 1.682 1.682 0 0 0-.544-.37 1.6 1.6 0 0 0-.647-.128h-4.745v-1.457h4.748c.83.004 1.63.34 2.214.928.59.59.92 1.388.927 2.223a3.08 3.08 0 0 1-.555 1.783c-.337.498-.812.89-1.367 1.124a5.485 5.485 0 0 1-1.697.257h-2.848v3.337zm6.433-.121h-1.66l-1.281-2.24a5.359 5.359 0 0 0 1.484-.306l1.456 2.546zm3.463-9.153.982.546.98-.546v-1.158l-.98-.57-.982.57z' />
			</g>
		</g>
	</svg>
);
export default SvgRay;
