import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar32 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar32_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar32_svg__cls-3{fill:#393c54}.avatar32_svg__cls-4{fill:#fba875}.avatar32_svg__cls-5{fill:#fff}.avatar32_svg__cls-8,.avatar32_svg__cls-9{fill:none;stroke-linecap:round}.avatar32_svg__cls-9{stroke:#fba875;stroke-miterlimit:10}.avatar32_svg__cls-8{stroke:#515570;stroke-linejoin:round;stroke-width:2px;opacity:.4}.avatar32_svg__cls-9{stroke-width:4px}.avatar32_svg__cls-11{fill:#515570}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#4bc190',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.3,
				fill: '#356cb6',
			}}
		/>
		<path
			d='M31.08 61.57V45.92a32.92 32.92 0 0 1 65.84 0v15.65Z'
			className='avatar32_svg__cls-3'
		/>
		<circle cx={91.32} cy={60.43} r={7.93} className='avatar32_svg__cls-4' />
		<path
			d='M64 124.1a59.78 59.78 0 0 0 40-15.28l-2.39-5.68c-1.71-4-6.22-6.64-11.29-6.64H37.69c-5.07 0-9.58 2.66-11.29 6.64l-2.4 5.68a59.78 59.78 0 0 0 40 15.28Z'
			className='avatar32_svg__cls-5'
		/>
		<path
			d='M81.72 98.25a3.06 3.06 0 0 0-3.08-2.88H49.36a3.07 3.07 0 0 0-3.08 2.93v.32c-.17 7.32 10.52 16.64 10.52 16.64l7.2-7.21 7.17 7.17s10.56-9 10.56-16.22c0-.26 0-.51-.01-.75Z'
			className='avatar32_svg__cls-5'
		/>
		<path
			d='M64 84.75V98.5'
			style={{
				strokeWidth: 20,
				strokeMiterlimit: 10,
				stroke: '#fba875',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={36.68} cy={60.43} r={7.93} className='avatar32_svg__cls-4' />
		<path
			d='M64 94.37a28.31 28.31 0 0 1-28.32-28.32V47.43a28.32 28.32 0 1 1 56.64 0v18.62A28.31 28.31 0 0 1 64 94.37Z'
			style={{
				fill: '#ffbb94',
			}}
		/>
		<circle cx={76.67} cy={59.28} r={3} className='avatar32_svg__cls-3' />
		<circle cx={49.67} cy={59.28} r={3} className='avatar32_svg__cls-3' />
		<path d='m74.39 53 9.61-.25M53 53l-9.61-.25' className='avatar32_svg__cls-8' />
		<path
			d='M71.55 74a1 1 0 0 1 .94 1.07 8.56 8.56 0 0 1-17 0 1 1 0 0 1 .96-1.07Z'
			className='avatar32_svg__cls-3'
		/>
		<path d='M60 86h8' className='avatar32_svg__cls-8' />
		<path d='M63.35 60.75v6.5M66 68h-5' className='avatar32_svg__cls-9' />
		<path
			d='M59 80.91a8.52 8.52 0 0 0 10.08 0 5.79 5.79 0 0 0-10.08 0Z'
			style={{
				fill: '#f85565',
			}}
		/>
		<path
			d='M69 76H59a1.84 1.84 0 0 1-1.73-2h13.5A1.84 1.84 0 0 1 69 76Z'
			className='avatar32_svg__cls-5'
		/>
		<path
			d='M64 16.85a30 30 0 0 0-30 30V53a4 4 0 0 0 4-4v-7.44a4.18 4.18 0 0 1 4.18-4.18h7.36A20.61 20.61 0 0 0 64 42.77a20.61 20.61 0 0 0 14.41-5.39h7.36A4.18 4.18 0 0 1 90 41.56v7.35a4 4 0 0 0 4 4v-6.07a30 30 0 0 0-30-29.99Z'
			className='avatar32_svg__cls-11'
		/>
		<path
			d='M60.16 123.86c1.27.08 2.55.14 3.84.14s2.57-.06 3.84-.14L67 115h-6Z'
			className='avatar32_svg__cls-3'
		/>
		<path
			d='M52.19 31.87A16.93 16.93 0 0 0 64 36.29a16.93 16.93 0 0 0 11.81-4.42h7.58'
			style={{
				opacity: 0.1,
				stroke: '#fff',
				strokeWidth: '3.28px',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M64.58 117.22h-1.32a4.58 4.58 0 0 1-4.58-4.58V108h10.49v4.64a4.58 4.58 0 0 1-4.59 4.58Z'
			className='avatar32_svg__cls-11'
		/>
		<path
			d='m64 108 10.17-12.62h4.47l-7.47 19.84L64 108zM64 108 53.83 95.38h-4.47l7.47 19.84L64 108z'
			className='avatar32_svg__cls-5'
		/>
		<path
			d='M81.72 98.25a3.06 3.06 0 0 0-3.08-2.88h-4.47L64 108 53.83 95.37h-4.47a3.06 3.06 0 0 0-3.08 2.92v.32c-.17 7.32 10.52 16.64 10.52 16.64l7.2-7.2 7.17 7.17s10.56-9 10.56-16.22c0-.26 0-.51-.01-.75Z'
			style={{
				opacity: 0.1,
				fill: '#356cb6',
			}}
		/>
	</svg>
);
export default SvgAvatar32;
