import * as React from 'react';
import { SVGProps } from 'react';
const SvgOmni = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#1c347a' />
			<path
				fill='#fff'
				fillRule='nonzero'
				d='M10.065 6.888a10.93 10.93 0 0 0-3.19 3.196V6.888zm15.004 3.11a10.93 10.93 0 0 0-3.134-3.11h3.134zm-3.088 15.084a10.933 10.933 0 0 0 3.088-3.08v3.08zM6.875 21.916a10.93 10.93 0 0 0 3.144 3.166H6.875zM26 16c0 5.514-4.486 10-10 10S6 21.514 6 16 10.486 6 16 6s10 4.486 10 10zm-10 7.292c4.02 0 7.292-3.271 7.292-7.292 0-4.02-3.271-7.292-7.292-7.292-4.02 0-7.292 3.271-7.292 7.292 0 4.02 3.271 7.292 7.292 7.292z'
			/>
		</g>
	</svg>
);
export default SvgOmni;
