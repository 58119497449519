import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionBahamas = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 6 3'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#00778B' d='M0 0h6v3H0z' />
		<path fill='#FFC72C' d='M0 1h6v1H0z' />
		<path d='M0 0v3l2.598-1.5z' />
	</svg>
);
export default SvgRegionBahamas;
