import * as React from 'react';
import { SVGProps } from 'react';
const SvgZest = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#07BC9C' fillRule='nonzero' />
			<g fill='#FFF'>
				<path d='M23.897 6.695c-.569-.142-1.895-.123-2.302.436.407.227.691.577 1.108.587.417.01.9-.389 1.43-.161-.227 0-1.25.19-1.316.502.143.235.315.451.511.644.266.218.294.294.692.076a2.53 2.53 0 0 0 1.042-1.317c0-.284.085-.095-.123-.331a2.302 2.302 0 0 0-1.042-.436zm-5.343.502a3.534 3.534 0 0 1 2.51-.313 3.6 3.6 0 0 0-1.193-2.368c.82.51 1.397 1.33 1.6 2.273.465-.075.948-.255 1.393-.322a3.846 3.846 0 0 0-1.127-2.396C21.424 3.777 20.543 3 20.04 3s-1.156 1.137-1.345 1.601a4.15 4.15 0 0 0-.142 2.596z' />
				<path d='M18.43 8.684c-.17-.407.578-.568.806-.54.407.12.78.334 1.09.625.596.446 1.77 1.763 2.633 1.45.862-.313-.161-1.838-.635-2.236a3.42 3.42 0 0 0-2.937-.88c-.478.143-.87.49-1.07.946-.057.209-.265.635.114.635z' />
				<path
					fillRule='nonzero'
					d='M22.39 14.368c-.53-.322-.72-1.27-1.051-1.79-.455-.73-1.118-1.326-1.516-2.084-.398-.758-.445-1.336-1.127-1.696a1.819 1.819 0 0 0-1.696 0c-.847.384-1.738.664-2.653.834a9.17 9.17 0 0 0-1.193.37 6.679 6.679 0 0 1-1.014.463c-.872.123-2.482-.947-2.33.71 0 .304.312.465.217.853-.094.389-.511.73-.71 1.033a9.294 9.294 0 0 0-.948 2.188 7.579 7.579 0 0 0 .294 5.277c.35.82.817 1.586 1.383 2.274.277.323.52.672.73 1.042.16.438.36.86.597 1.26a1.78 1.78 0 0 0 1.752.635 4.86 4.86 0 0 0 .948-.445 6.461 6.461 0 0 1 1.591-.408 8.526 8.526 0 0 0 5.125-2.842 8.195 8.195 0 0 0 1.431-2.539 8.482 8.482 0 0 0 .407-1.591c.048-.341-.056-1.204.19-1.43.16-.153.54-.19.654-.333a2.96 2.96 0 0 0 .198-.587c.18-.559-.871-1.004-1.278-1.194zm.72 1.355c-.142.322-.7.512-.947.853a5.76 5.76 0 0 1-1.392 1.667 2.757 2.757 0 0 1-3.44-.739 2.937 2.937 0 0 1-.644-1.847c0-.521.569-1.829 0-2.113-.568-.284-.833.635-1.051.948a3.354 3.354 0 0 1-1.649 1.392 3.117 3.117 0 0 1-3.135-.682 3.174 3.174 0 0 1-.446-2.728 6.082 6.082 0 0 0-.123-1.393c0-.682.597-.436 1.08-.341a8.47 8.47 0 0 1 3.335 1.374 7.03 7.03 0 0 0 3.268 1.515c.805.076 1.603.212 2.388.408.388.114.748.303 1.127.436.4.13.788.297 1.156.502.256.113.625.34.483.71l-.01.038z'
				/>
			</g>
		</g>
	</svg>
);
export default SvgZest;
