import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomChatIco = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 64 64'
		className='svg-icon'
		{...props}>
		<style>{'.CustomChatIco_svg__st3{fill:#fff}.CustomChatIco_svg__st4{fill:#4f5d73}'}</style>
		<g id='CustomChatIco_svg__Layer_1'>
			<circle
				cx={32}
				cy={32}
				r={32}
				style={{
					fill: '#77b3d4',
				}}
			/>
			<g
				style={{
					opacity: 0.2,
				}}>
				<path
					d='M52 32c0-9.9-9-18-20-18s-20 8.1-20 18c0 9.6 8.3 17.4 18.8 17.9.7 3.7 1.2 6.1 1.2 6.1s5-3 9.6-8.2C47.8 44.7 52 38.8 52 32z'
					style={{
						fill: '#231f20',
					}}
				/>
			</g>
			<path
				d='M49 28.8C49 43.8 32 54 32 54s-9.4-42 0-42 17 7.5 17 16.8z'
				className='CustomChatIco_svg__st3'
			/>
			<ellipse cx={32} cy={30} className='CustomChatIco_svg__st3' rx={20} ry={18} />
			<circle cx={32} cy={30} r={2} className='CustomChatIco_svg__st4' />
			<circle cx={40} cy={30} r={2} className='CustomChatIco_svg__st4' />
			<circle cx={24} cy={30} r={2} className='CustomChatIco_svg__st4' />
		</g>
	</svg>
);
export default SvgCustomChatIco;
