import * as React from 'react';
import { SVGProps } from 'react';
const SvgXcp = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#ED1650' />
			<path
				fill='#FFF'
				d='M23.48 10h-4.044a.616.616 0 0 0-.44.186l-7.375 7.529a.616.616 0 0 1-.44.186H9.829a.616.616 0 0 1-.44-.186l-.956-.974a.639.639 0 0 1-.183-.447v-1.377a.64.64 0 0 1 .183-.448l.956-.974a.617.617 0 0 1 .44-.186h1.352c.165 0 .323.067.44.186l.587.598a.614.614 0 0 0 .88 0l1.419-1.444a.642.642 0 0 0 0-.896l-1.54-1.567a.617.617 0 0 0-.44-.186H8.483a.617.617 0 0 0-.44.186l-2.86 2.912a.64.64 0 0 0-.182.448v4.118c0 .168.066.33.182.448l2.86 2.913a.617.617 0 0 0 .44.185h4.045a.617.617 0 0 0 .44-.186l7.375-7.528a.617.617 0 0 1 .44-.187h1.352c.165 0 .323.067.44.186l.956.974a.64.64 0 0 1 .183.448v1.377a.64.64 0 0 1-.183.447l-.956.974a.616.616 0 0 1-.44.186h-1.352a.616.616 0 0 1-.44-.186l-.592-.603a.614.614 0 0 0-.875-.005l-1.75 1.742a.64.64 0 0 0-.187.453v4.04c0 .35.279.634.622.634h2.04a.628.628 0 0 0 .623-.633v-1.5c0-.35.279-.633.622-.633h2.635a.617.617 0 0 0 .44-.185l2.86-2.913a.64.64 0 0 0 .183-.448v-4.118a.64.64 0 0 0-.182-.448l-2.86-2.912a.617.617 0 0 0-.44-.186'
			/>
		</g>
	</svg>
);
export default SvgXcp;
