import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionPitcairn = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 30 15'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<clipPath id='regionPitcairn_svg__a'>
			<path d='M0 0v75h350v75h-50zm300 0H150v200H0v-50z' />
		</clipPath>
		<path fill='#012169' d='M0 0h30v15H0z' />
		<path stroke='#fff' strokeWidth={1.5} d='m0 0 15 7.5M15 0 0 7.5' />
		<path
			stroke='#c8102e'
			strokeWidth={20}
			d='m0 0 300 150m0-150L0 150'
			clipPath='url(#regionPitcairn_svg__a)'
			transform='scale(.05)'
		/>
		<path stroke='#fff' strokeWidth={2.5} d='M7.5 0v10M0 3.75h17.5' />
		<path stroke='#c8102e' strokeWidth={1.5} d='M7.5 0v10M0 3.75h17.5' />
		<path fill='#012169' d='M15 0v7.5H0V15h30V0z' />
		<g fill='#F7E017' stroke='currentColor' strokeWidth={0.015}>
			<path d='M20.018 7.954c-.32.271-.625.667-.7 1.025-.211 1.007-.491 1.231-.923 1.015 0 .541.474.582.682.266 0 .507.184.947.599 1.322.175.158.208.05.125-.125-.083-.175-.083-.74-.249-1.064.266.191.657.083.624-.441-.341.2-.68.192-.715-.283-.043-.557.174-1.366.557-1.715zm-.405-4.005c.023-.2-.02-.405-.166-.534-.282-.248-.558-.166-.764.034-.308-.166-.471.423-.82.324.058.191.15.274.324.208-.183.166 0 .366-.216.565.391.125.599-.091.582-.466.15.133.382.125.507-.025-.208-.067-.209-.247-.125-.416.125-.249.711-.239.678.31z' />
			<path
				fill='#337321'
				d='M20.515 6.842c-.441.233-1.247.133-1.322-.441-.075-.574.482-.832.632-.906.233-.117.416.091.341.333a.377.377 0 0 0 .191-.565c.341.025.657-.233.79-.599-.12.114-.446.037-.714.02.083-.091.082-.269.057-.361-.282.29-.69.157-1.23.997.083-.291.241-.873.333-1.256.009-.038.016-.076.021-.115.032-.549-.553-.559-.678-.309.137.154.065.334.016.607-.058.324-.183 1.073-.291 1.322-.033-.391-.291-.416-.341-.607-.075.05-.116.183-.1.274-.092-.1-.408.025-.524-.125-.125.249.075.499.283.607-.121.008-.15.154-.283.154.179.208.362.291.574.308.212.017.37.096.499.353.208.417 1 .687 1.746.309z'
			/>
			<path d='M21.613 6.334c-.249.69-.931 1.181-1.239 1.256-.524.127-1.592.646-1.878.913a.332.332 0 0 1-.118-.048c-.141-.1-.258-.424-.008-.699.782-.732 1.622-.416 2.146-.915-.441.233-1.247.133-1.322-.441a2.029 2.029 0 0 0 2.012-.599c.091.101.357.417.407.533z' />
			<path
				fill='#337321'
				d='M20.018 7.954c-.395.335-.6 1.158-.558 1.715.012.155.055.26.121.324.029-.316.249-1.133.81-1.497.474-.308 1.214-.998 1.489-1.788a.634.634 0 0 0-.291-.341c-.273.746-1.002 1.104-1.571 1.587z'
			/>
			<path
				fill='#316D3A'
				d='M21.359 6.014a1.854 1.854 0 0 1-.769.62.403.403 0 0 0 .091-.249c-.391.15-1.173.133-1.489.017a2.029 2.029 0 0 0 2.012-.599c.068.074.126.14.155.211z'
			/>
			<path
				fill='#337321'
				d='M18.37 7.756c-.636.595-.216 1.114.033 1.231-.066.416.324.366.316.715.158-.091.2-.333.175-.524.2.216.707-.025.815.374.067-.441-.274-.881-.698-.84.175-.158.075-.416-.091-.482-.033.324-.399.324-.541.225-.142-.1-.259-.424-.009-.699z'
			/>
			<path d='M19.746 11.54c-.069.04-.064.129-.021.187.087.116-.062.38.22.437.083.017.147-.034.179-.129.087-.262-.191-.291-.216-.416-.025-.125-.104-.112-.162-.079z' />
			<path d='M19.983 12.031c-.093.043-.175.104-.137.403.015.124.008.424-.1.428.066.033.208.069.266-.008.056.094.183.067.233-.05.066.054.158-.037.158-.116.058.037.175-.017.145-.195.071.025.162-.025.195-.071-.104-.021-.366-.162-.428-.274-.062-.113-.224-.167-.332-.117z' />
			<path
				fill='none'
				d='M18.542 3.62c.042-.062.089-.12.141-.171m1.441 1.272c.073-.042.185-.047.309-.039m-.421 8.172c-.029-.042-.04-.216-.019-.322m.252.272a.493.493 0 0 1-.148-.262m.306.146c-.058-.027-.2-.21-.231-.328m.376.132a.825.825 0 0 1-.366-.297'
			/>
			<path d='M24.382 7.765c1.023.857 1.089 1.538.931 1.938-.083-.374-.507-.956-.948-1.106l.017-.832z' />
			<path
				fill='#337321'
				d='M22.553 7.282h1.896v2.245c0 1.913-1.064 3.044-2.146 3.642-1.081-.599-2.146-1.73-2.146-3.642V7.282h1.331a.729.729 0 0 0 .15.466c.314-.031.669-.266.915-.466z'
			/>
			<path
				fill='#006EC7'
				d='M24.449 7.282v2.245c0 .577-.097 1.084-.262 1.525l-1.883-3.455-1.883 3.455a4.327 4.327 0 0 1-.262-1.525V7.282h4.29z'
			/>
			<path
				stroke='none'
				d='M24.244 10.887a4.274 4.274 0 0 1-.14.369l-1.8-3.346-1.8 3.346a3.969 3.969 0 0 1-.14-.369l1.941-3.598 1.939 3.598z'
			/>
			<path d='M23.159 12.118c.05.025.142.071.183.088.1-.2.116-.482.083-.732-.108.275-.474.266-.574.432.061.031.118.071.167.106-.125.125-.417.334-.583.384v-1.784c0-.125-.042-.229-.042-.333V9.91c0-.083-.017-.204-.091-.204-.075 0-.091.121-.091.204v.369c0 .104-.042.225-.042.333v1.784c-.091-.266-.45-.175-.616-.441a.466.466 0 0 1 .2-.008c-.125-.424-.466-.457-.532-.582 0 .175-.075.582.042.757a.437.437 0 0 1 .113-.092c.141.308.777.309.927.816.12-.187.553-.403.856-.728z' />
			<path d='M22.303 10.634c.141 0 .59-.046.753-.046.042 0 .077-.063.077-.141s-.034-.141-.077-.141c-.162 0-.611-.046-.753-.046-.141 0-.59.046-.753.046-.042 0-.077.063-.077.141s.034.141.077.141c.163 0 .612.046.753.046z' />
			<path d='M22.212 9.903a.18.18 0 0 0-.113-.039c-.094 0-.171.07-.171.156s.076.156.171.156c.094 0 .17-.07.17-.156 0-.067.097-.067.097 0 0 .135-.12.244-.267.244-.148 0-.267-.109-.267-.244s.12-.244.267-.244c.044 0 .086.01.123.027-.003.022-.01.064-.01.1zm-.661.685c.042 0 .076-.063.076-.141s-.034-.141-.076-.141' />
			<path d='M21.613 10.588c.042 0 .076-.063.076-.141s-.034-.141-.076-.141m.411.311c.051 0 .092-.076.092-.17 0-.094-.041-.17-.092-.17m.095.347c.053 0 .096-.08.096-.178 0-.098-.043-.178-.096-.178m.32.358c.054 0 .097-.081.097-.18 0-.099-.044-.18-.097-.18m.1.355c.053 0 .096-.078.096-.174 0-.096-.043-.174-.096-.174m.447.317c.043 0 .078-.064.078-.144s-.035-.144-.078-.144m-1.61 1.728a.618.618 0 0 1 .178-.074m1.514.091-.049-.035m.14.106c.116-.125.213-.266.267-.428' />
			<path
				fill='#FFF'
				d='M21.922 8.87c0-.041.033-.058.072-.087.047-.035.066-.052.108-.052h.546c.04 0 .05.01.05.048v.741c0 .037-.009.048-.05.048h-.726v-.706'
			/>
			<path
				fill='#E5E5E5'
				d='M22.513 9.633c.072 0 .059-.002.109-.052.053-.052.048-.048.048-.096v-.679c0-.035-.009-.045-.046-.045h-.505c-.04 0-.057.016-.1.049-.036.027-.066.044-.066.082v.741h.56z'
			/>
			<path
				fill='#FFF'
				d='M22.567 9.617c0 .037-.009.048-.05.048h-.546c-.041 0-.049-.01-.049-.048v-.742c0-.037.009-.048.049-.048h.546c.041 0 .05.01.05.048v.742z'
			/>
			<path
				fill='#96877D'
				d='M23.625 6.847a.419.419 0 0 1 .083.434h-.158c.066-.2.025-.39-.258-.415-.423-.037-.915.782-1.655.881-.216-.258-.205-.749.07-.982-.142-.409-.441-.859-.649-1.053a2.03 2.03 0 0 0-.394-.027c.108-.233.416-.478.666-.578.04-.059.084-.116.124-.167.025-.32 1.547-.17 1.871.029.001.359.142 1.554.3 1.878z'
			/>
			<path
				fill='currentColor'
				stroke='none'
				d='M21.781 7.066c-.036-.554-.26-1.011-.575-1.322.15.042.457.042.665-.108.274-.183.99-.503 1.252-.366.021.046.033.15.017.216-.062-.166-.753.091-.948.17-.187.087-.27.204-.204.466-.108-.091-.071-.179-.183-.245.045.108.082.303.095.461-.042-.112-.116-.353-.279-.495.141.274.264.933.206 1.283.112 0 .36-.11.459-.189-.087.125-.339.212-.501.245-.073.087-.123.312-.119.437-.011-.154.037-.529.115-.553zm1.844-.219c-.138-.153-.395-.236-.723-.147.05-.025.158-.067.254-.083-.012-.087-.091-.462-.104-.553l.092-.037c.046.225.104.482.141.586.029.008.096.021.154.05-.025-.087-.166-.715-.162-.782l.125-.067c.049.341.128.836.223 1.033z'
			/>
			<path
				fill='#96877D'
				d='M23.551 7.282c.066-.2.025-.39-.258-.415-.219-.019-.457.194-.738.415h.996zM21.713 5.49a1.648 1.648 0 0 1-.545-.054.64.64 0 0 1 .041-.118c-.087.058-.253.172-.316.251.2.042.612.063.82-.079z'
			/>
			<path
				fill='none'
				d='M21.708 6.766c.046.133.076.261.08.375m-.457-2.033c-.075.11-.141.228-.163.328.141.046.42.071.545.054'
			/>
			<path
				fill='none'
				d='M21.059 5.713c.054.009.104.019.147.031.15.042.457.042.665-.108.208-.15.773-.615 1.414-.665'
			/>
			<path
				fill='none'
				d='M20.894 5.569c.2.042.612.062.819-.079.482-.328.948-.52 1.414-.545'
			/>
			<path
				fill='#337321'
				d='M24.382 7.765c.698.482 1.214.948 1.364 1.696.15.748.433.948.782.748-.083.432-.516.482-.881.15.033.499-.166 1.098-.682 1.314-.033-.299.165-.536.108-.782-.025-.108-.033-.358.108-.474-.274.091-.707-.083-.748-.491.316.15.723.175.881-.225.157-.398.091-1.08-.932-1.936zm.882-3.892c.216-.565.782-.507.973-.266.557-.316.632.399 1.106.258-.008.133-.166.258-.341.249.183.166-.075.416.316.565-.333.166-.798-.008-.923-.466-.108.216-.499.216-.657.042.341-.091.316-.407.125-.532-.193-.126-.533-.075-.599.15zm-1.687 1.245c.155.5.476.966.822 1.283.399.366 1.002.274 1.339-.075.008.696-.713.75-1.114.532-.177-.096-.32-.046-.15.108.258.233.822.406 1.488.549 1.164.249.749 1.173.408 1.164.098-.002.178-.104.037-.231-1.01-.909-3.064-.551-3.031-2.58-.216.532-1.106.324-.69-.308.133.108.366.116.433-.05.052-.129.018-.354-.215-.548.038-.003.121 0 .112-.096.01.056.061.139.192.111.037.059.077.098.13.077.01-.004.042-.02.029-.079.01.112.141.151.21.143z'
			/>
			<path d='M24.623 6.858c.482.316 1.028.316 1.53-.266.158-.183.407-.341.582-.358.175-.017.166-.183.299-.216-.083-.025-.116-.125-.241-.112.312-.108.22-.358.374-.478-.125.046-.32-.141-.499.096.046-.108-.017-.237-.062-.287.017.125-.245.15-.295.466-.028.177-.125.166-.141-.108-.012-.201-.108-.757-.224-1.039-.116-.283-.143-.671.015-.729a.308.308 0 0 0-.098-.102c-.193-.126-.532-.075-.599.15-.216.565.391.89.341 1.505-.091-.457-.832-.574-.823-1.023-.241.108-.208.308-.116.466-.15-.233-.491.15-.782-.166-.033.416.333.574.632.59-.15.266.05.491.249.565.008-.466.963-.275.973.524.008.68-.724.738-1.115.522zm1.339.657c1.164.249.749 1.173.408 1.164-.217-.005-.424-.187-.437-.345-.249.133-.183.395.008.499-.524-.062-.744.283-.757.661.096-.175.387-.187.495-.15.108.037.387.058.499-.067-.092.079.058.262-.071.403.437-.017.628-.424.553-.603.566-.514.458-1.354-.698-1.562zm-.886 3.951c.076.047.066.147.015.21-.103.126.052.427-.265.478-.094.015-.163-.045-.194-.152-.086-.296.226-.316.259-.455.034-.138.121-.121.185-.081z' />
			<path d='M24.79 12.003c.102.052.19.124.136.456-.023.138-.028.473.093.482-.076.034-.235.067-.297-.021-.067.102-.207.066-.258-.066-.077.057-.175-.049-.171-.137-.067.039-.194-.026-.154-.224-.08.025-.18-.035-.215-.087.117-.019.415-.165.49-.288.074-.122.257-.175.376-.115z' />
			<path
				fill='none'
				d='M24.721 12.921c.034-.045.054-.24.035-.359m-.292.293a.545.545 0 0 0 .176-.286m-.348.149c.066-.028.232-.226.272-.357m-.426.133c.077-.013.318-.157.421-.316'
			/>
			<path d='M23.376 5.869c-.014.852.34 1.284.831 1.564-.182-.109-.103-.483-.349-.604.058-.004.15.079.295.046-.071-.158-.187-.399-.457-.432.071-.008.225.017.324-.021-.141-.217-.515-.113-.644-.553zm-.088-.811c-.03-.01-.055-.04-.08-.079-.131.028-.182-.055-.192-.111.008.096-.074.092-.113.095.234.194.268.419.216.548-.067.166-.299.158-.433.05-.017.353.441.47.607.025 0 .075.096.067.121.166.118-.191.028-.466-.126-.694z' />
			<path
				fill='#337321'
				d='M22.557 3.37a.994.994 0 0 1-.295-.694c.008-.183.083-.383.349-.299-.083 0-.025.166-.112.183.071.025.166-.033.179-.071.017.058.125.042.133.108.042-.033-.004-.195-.066-.229.046-.025.071-.154.05-.216-.042.008-.104.067-.116.15.021-.067-.004-.233-.121-.258-.033.046-.042.158.004.22-.108-.025-.266.046-.304.137.008-.11.024-.267.092-.366.023-.033-.033-.091-.077-.012-.081-.158-.297-.241-.393-.162s-.224-.037-.303.087c-.079.125-.347.138-.333.27.008.079-.011.201-.05.245-.096.108.046.187.071.283-.017-.316.486-.931.99-.69-.046.079-.086.237-.1.428-.021.283.021.682.295.915l.107-.029z'
			/>
			<path
				fill='#c8102e'
				d='M22.706 2.38c-.003-.075.127-.172.301-.17.21.002.372.193.54.175.168-.019.085.075.05.1-.035.025-.056.062-.044.108.012.046-.009.076-.077.033-.15-.094-.299.037-.497-.035-.169-.064-.268-.099-.273-.211z'
			/>
			<path
				fill='#96877D'
				d='M21.85 4.343c-.083-.262-.399-.549-.553-.615l-.008-.141c.083-.033.283-.191.374-.279.624.299 1.472-.125 1.863-.565l.15.091-.075.1-.079-.042a4.777 4.777 0 0 1-.561.523c.145.037.344.062.399.059.349-.25.623-.474.756-.491l.112.15-.1.071-.079-.046c-.374.308-.798.981-.965 1.43-.232-.05-1.064-.017-1.234-.245z'
			/>
			<path
				fill='none'
				d='M22.961 3.415a.607.607 0 0 1-.129-.045c-.067.062-.233.116-.445.125'
			/>
			<path
				fill='none'
				d='M21.883 4.251c-.056-.31.033-.653.191-.757.345.229.936.229 1.285-.021'
			/>
			<path
				fill='none'
				d='M21.883 4.089a8.87 8.87 0 0 1 .985.058c.141.021.378.079.345.15m-1.207-.252.077-.076-.077-.076-.076.075.076.077z'
			/>
			<path
				fill='#337321'
				d='M22.187 2.497c-.096-.133-.359-.175-.416.004-.025.079-.062.162-.125.2-.062.037-.048.151-.021.2.066.121.008.237.137.316 0-.096.141-.195.262-.22.121-.025.303-.133.328-.254.026-.122.068-.222-.165-.246z'
			/>
			<path
				fill='#96877D'
				d='M21.588 3.694h.033l.001.227a.075.075 0 0 1 .02.01l.196-.113.017.029-.197.114c.001.007.002.015.001.022l.196.113-.017.029-.198-.112a.047.047 0 0 1-.019.012v.226h-.033v-.227a.075.075 0 0 1-.02-.01l-.196.113-.017-.029.197-.114c-.001-.007-.002-.015-.001-.022l-.196-.113.017-.029.197.113a.047.047 0 0 1 .019-.012v-.227z'
			/>
			<path
				fill='#96877D'
				d='M21.884 4.25a.393.393 0 1 1 .033-.517.887.887 0 0 0-.046.211.27.27 0 1 0-.268.296.276.276 0 0 0 .263-.202c.001.052.003.171.018.212z'
			/>
			<path
				fill='#337321'
				d='M21.438 4.625c-.025-.029-.071-.108-.066-.166a.217.217 0 0 1 .141.054c-.004-.042-.021-.133-.012-.175a.38.38 0 0 1 .175.133.603.603 0 0 1-.008-.216c.05.029.133.108.15.162a.716.716 0 0 1 .058-.254c.037.037.096.071.133.121.008-.067.042-.146.091-.166a.483.483 0 0 1 .108.245c.037-.012.087-.075.108-.108a.228.228 0 0 1 .029.162c.046-.042.1-.1.112-.15a.319.319 0 0 1 .112.154c-.008-.054-.004-.15-.021-.204a.24.24 0 0 1 .125.154c.017-.058.054-.162.087-.187a.455.455 0 0 1 .062.212c.021-.042.054-.116.087-.133.029.046.025.146.017.2a.4.4 0 0 1 .116-.108.348.348 0 0 1 0 .112c.037-.021.1-.133.121-.191a.29.29 0 0 1 .121.179.452.452 0 0 0 .15-.133c0 .029.013.108-.004.17.025-.025.05-.075.062-.096.017.042.004.141-.013.195a.219.219 0 0 1 .125-.054c.004.071-.046.195-.092.287-.398-.111-1.234-.153-2.074-.199z'
			/>
			<path
				stroke='none'
				d='M22.83 2.423c-.002-.031.066-.1.189-.099.147.001.283.109.4.101.118-.008.06.031.035.041-.025.01-.039.026-.031.044.009.019-.006.031-.054.014-.105-.038-.21.015-.348-.014-.119-.027-.188-.041-.191-.087z'
			/>
			<path d='M21.567 4.875c-.125.15-.249.125-.274.029-.025-.096.067-.141.033-.225-.033-.083.054-.121.096-.083.042.037.175-.083.233.042.058.125.104.212.071.262-.034.05-.134.025-.159-.025zm.536-.225c-.023-.055.106-.16.173-.085.067.075.171-.081.229.037.058.119.077.181.106.247.029.067-.158.102-.216.044.008.039-.162.07-.208-.042-.025-.059-.052-.128-.084-.201zm.865.069c-.031-.058.121-.141.2-.035.021-.044.171-.052.181.075l.019.252c.004.05-.091.096-.16-.031-.123.025-.179-.044-.193-.116a.54.54 0 0 0-.047-.145z' />
			<path
				fill='#337321'
				d='M21.648 4.636c-.019-.042.106-.125.191-.062.085.062.203-.054.254.058.102.227.108.243.077.287-.031.044-.164.027-.198-.033-.006.042-.121.035-.181.002-.068-.038-.116-.177-.143-.252zm.875.01c-.029-.064.166-.121.252-.008.044-.04.139-.042.193.081.054.123.062.191.023.222-.028.022-.129.039-.162-.017-.046.029-.187.004-.218-.075-.031-.078-.058-.147-.088-.203zm.828.123c-.004-.058.102-.085.141 0 .04.085.166.008.177.15.002.027-.015.131-.054.181s-.233 0-.245-.106a2.419 2.419 0 0 1-.019-.225z'
			/>
			<path
				fill='none'
				d='M21.567 4.875c-.046-.062-.108-.162-.187-.054m.593.064a.49.49 0 0 1-.075-.139m.497.148a.345.345 0 0 1-.096-.106m.058-.113c.073.008.123.077.145.108.023.031.071.087.108.094m.219.048c-.025-.04-.05-.075-.056-.104m.002-.183a.483.483 0 0 1 .058.098m.374.243a.345.345 0 0 0-.129-.143m.113-.086c.004.123.168.131.175.218m.126-.199a.238.238 0 0 1 .025.114'
			/>
		</g>
	</svg>
);
export default SvgRegionPitcairn;
