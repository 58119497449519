import * as React from 'react';
import { SVGProps } from 'react';
const SvgSys = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#0082c6' />
			<path
				fill='#fff'
				d='M18.046 15.103a.117.117 0 0 0-.114.041.108.108 0 0 0 .023.156C22.334 18.478 16.101 25.754 4 18.011c10.26 9.655 24.547-1.01 14.046-2.908zm-5.844-4.248c-9.086 2.907 1.323 9.763 2.226 6.3a.11.11 0 0 0-.083-.137.12.12 0 0 0-.064.001c-1.337.435-8.701-1.624-2.08-6.164zM28 12.602c-7.867-5.61-22.628-.984-15.013 2.977a.119.119 0 0 0 .157-.047.11.11 0 0 0 .003-.098C10.05 8.89 25.781 10.88 28 12.602zm-6.528 8.151c4.159-1.89.777-8.361-4.724-7.112a.116.116 0 0 1-.138-.085.107.107 0 0 1 .04-.11c4.28-3.216 12.314 4.292 4.822 7.307z'
			/>
		</g>
	</svg>
);
export default SvgSys;
