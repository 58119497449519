import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomVip = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 455.354 455.354'
		className='svg-icon'
		{...props}>
		<path
			d='m194.517 92.559 33.159-57.433 33.159 57.433z'
			style={{
				fill: '#f4ca60',
			}}
		/>
		<path
			d='m322.214 21.272-51.927 117.333-11.097-33.333-31.512 33.333-31.511-33.333-11.097 33.333-51.927-117.333L98.876 179.98h257.6z'
			style={{
				fill: '#f4ca60',
			}}
		/>
		<path
			d='M138.023 236.984c0 24.442-19.834 44.276-44.276 44.276a43.988 43.988 0 0 1-21.571-5.622c-13.536-7.552-22.705-22.053-22.705-38.654 0-14.477 6.949-27.338 17.735-35.42a44.032 44.032 0 0 1 26.541-8.856c24.442 0 44.276 19.834 44.276 44.276zM405.883 236.984c0 24.442-19.834 44.276-44.276 44.276-1.81 0-3.595-.12-5.356-.338-21.909-2.63-38.919-21.305-38.919-43.938 0-24.442 19.834-44.276 44.276-44.276.41 0 .796 0 1.182.024 23.911.627 43.093 20.22 43.093 44.252z'
			style={{
				fill: '#e5cf98',
			}}
		/>
		<path
			d='M260.661 351.53v25.45c0 1.84-.15 3.65-.44 5.41-.58 3.56-1.74 6.93-3.39 10.01-5.5 10.4-16.46 17.51-29.08 17.51-12.64 0-23.58-7.11-29.1-17.51-1.64-3.07-2.8-6.42-3.39-9.97-.29-1.77-.44-3.59-.44-5.45v-25.45h65.84z'
			style={{
				fill: '#fce7a7',
			}}
		/>
		<path
			d='M260.661 351.53v25.45c0 1.84-.15 3.65-.44 5.41a134.75 134.75 0 0 1-32.54 3.96c-11.18 0-22.03-1.36-32.42-3.92-.29-1.77-.44-3.59-.44-5.45v-25.45h65.84z'
			style={{
				fill: '#e5cf98',
			}}
		/>
		<path
			d='M362.441 196v45.6c0 23.58-6.07 45.75-16.71 65.03-22.94 41.56-67.2 69.72-118.05 69.72-50.86 0-95.12-28.16-118.06-69.72-10.64-19.28-16.71-41.45-16.71-65.03V196c0-14.33 2.24-22.073 6.38-35.023 6.74-21.05 18.5-32.28 33.89-47.4 7.6-7.48 16.07-14.06 25.26-19.58 14.21-8.52 30.12-14.5 47.11-17.3 7.2-1.19 14.59-1.81 22.13-1.81 7.81 0 15.46.66 22.91 1.94 17.12 2.93 33.14 9.09 47.4 17.84 9.03 5.52 17.35 12.09 24.8 19.51l.18.18c15.64 15.64 27.46 27.53 33.92 49.31 3.61 12.16 5.55 18.993 5.55 32.333z'
			style={{
				fill: '#fce7a7',
			}}
		/>
		<path
			d='M227.673 315.906a26.425 26.425 0 0 1-25.245-18.425 3 3 0 1 1 5.714-1.83c2.73 8.526 10.579 14.255 19.53 14.255s16.8-5.729 19.53-14.255a3 3 0 0 1 5.714 1.83 26.42 26.42 0 0 1-25.243 18.425z'
			style={{
				fill: '#ea535e',
			}}
		/>
		<path
			d='m196.764 270 30.913-13 30.913 13z'
			style={{
				fill: '#e5cf98',
			}}
		/>
		<path
			d='M165.189 265.72c-14.095 0-25.563-11.468-25.563-25.563 0-14.095 11.468-25.563 25.563-25.563s25.563 11.468 25.563 25.563c-.001 14.095-11.468 25.563-25.563 25.563zM290.161 265.72c-14.095 0-25.563-11.468-25.563-25.563 0-14.095 11.468-25.563 25.563-25.563 14.095 0 25.563 11.468 25.563 25.563 0 14.095-11.468 25.563-25.563 25.563z'
			style={{
				fill: '#fff',
			}}
		/>
		<path
			d='M173.937 240.157a8.748 8.748 0 0 1-4.892-16.001 16.45 16.45 0 0 0-3.856-.472c-9.098 0-16.474 7.375-16.474 16.474s7.376 16.474 16.474 16.474 16.474-7.375 16.474-16.474c0-1.33-.175-2.617-.472-3.856a8.742 8.742 0 0 1-7.254 3.855zM298.909 240.157a8.748 8.748 0 0 1-4.892-16.001 16.45 16.45 0 0 0-3.856-.472c-9.098 0-16.474 7.375-16.474 16.474s7.376 16.474 16.474 16.474c9.098 0 16.474-7.375 16.474-16.474 0-1.33-.175-2.617-.472-3.856a8.74 8.74 0 0 1-7.254 3.855z'
			style={{
				fill: '#4d4d4d',
			}}
		/>
		<path
			d='M292.5 439.929c48.49-24.465 79.072-74.15 79.072-128.462v-47.846c0-9.434-10.498-15.076-18.366-9.871l-23.191 15.342a84.573 84.573 0 0 1-74.496 9.326 84.57 84.57 0 0 0-55.667 0 84.573 84.573 0 0 1-74.496-9.326l-23.191-15.342c-7.868-5.205-18.366.437-18.366 9.871v47.846c0 54.312 30.582 103.997 79.072 128.462a143.883 143.883 0 0 0 129.629 0z'
			style={{
				fill: '#fbb03b',
			}}
		/>
		<path
			d='M227.685 294c-18.569 0-33.623 15.053-33.623 33.623 0 5.731 4.646 10.377 10.377 10.377h46.491c5.731 0 10.377-4.646 10.377-10.377 0-18.57-15.053-33.623-33.622-33.623z'
			style={{
				fill: '#fce7a7',
			}}
		/>
		<path
			d='M228.585 321.862c-4.174 0-8.348-.972-12.32-2.915a3 3 0 0 1 2.637-5.389c6.336 3.099 13.032 3.099 19.366 0a3 3 0 0 1 2.637 5.389c-3.973 1.943-8.147 2.915-12.32 2.915z'
			style={{
				fill: '#ea535e',
			}}
		/>
		<path
			d='M355.309 157.61v8.43H100.874v-11.12a134.973 134.973 0 0 1 33.474-54.98l8.939 24.73 16.011-44.31c14.035-8.52 29.75-14.5 46.531-17.3l22.263 61.61 22.224-61.48a131.036 131.036 0 0 1 46.818 17.84l15.774 43.64 8.721-24.13.178.18a135.011 135.011 0 0 1 33.502 56.89z'
			style={{
				fill: '#e5cf98',
			}}
		/>
		<path
			d='M99.293 158.708 76.53 190.917c-6.15 8.702-7.328 20.169-2.476 29.657 3.87 7.568 11.015 13.46 20.222 15.302 5.618 1.124 11.03-2.687 11.853-8.357 3.128-21.547 13.274-68.811 42.098-68.811M356.894 158.708l22.763 32.209c6.15 8.702 7.328 20.169 2.476 29.657-3.87 7.568-11.015 13.46-20.222 15.302-5.618 1.124-11.03-2.687-11.853-8.357-3.128-21.547-13.274-68.811-42.098-68.811'
			style={{
				fill: '#e29a3b',
			}}
		/>
		<path
			d='M356.476 157.61v1.1h-257.6v-3.79c17.37-54.33 68.28-93.67 128.39-93.67 37.22 0 70.9 15.09 95.29 39.47 15.64 15.64 27.46 35.11 33.92 56.89z'
			style={{
				fill: '#e29a3b',
			}}
		/>
		<path
			d='m356.477 0-42.934 117.333L270.61 0l-42.934 117.333L184.742 0l-42.933 117.333L98.875 0v158.708h257.602z'
			style={{
				fill: '#fcd983',
			}}
		/>
		<path
			d='M195.591 240.685h-59.358a9 9 0 0 1-9-9V219a9 9 0 0 1 9-9h59.358a9 9 0 0 1 9 9v12.685a9 9 0 0 1-9 9zM319.119 240.685h-59.358a9 9 0 0 1-9-9V219a9 9 0 0 1 9-9h59.358a9 9 0 0 1 9 9v12.685a9 9 0 0 1-9 9z'
			style={{
				fill: '#e29a3b',
			}}
		/>
	</svg>
);
export default SvgCustomVip;
