import * as React from 'react';
import { SVGProps } from 'react';
const SvgBlz = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#18578c' />
			<path
				fill='#fff'
				fillRule='nonzero'
				d='M11.633 20.336c.662.675.662 1.769 0 2.444s-1.736.677-2.4.003c-.32-.324-.5-.765-.5-1.225 0-.46.18-.9.5-1.225a1.676 1.676 0 0 1 2.4.003zm11.063-11.61a1.73 1.73 0 0 1 1.048 1.599c0 .7-.414 1.33-1.048 1.598s-1.365.12-1.85-.375a1.745 1.745 0 0 1-.498-1.223c0-.46.179-.9.498-1.223a1.675 1.675 0 0 1 1.85-.375zM11.41 19.003l1.637-1.676a3.263 3.263 0 0 0 1.796 1.83l-1.641 1.671zm9.697-6.137-1.641 1.672a3.226 3.226 0 0 0-.71-1.098 3.227 3.227 0 0 0-1.081-.727l1.64-1.672zm-.687 7.942a1.698 1.698 0 0 1 1.569-1.067c.687 0 1.306.421 1.569 1.067.262.647.117 1.39-.368 1.885a1.68 1.68 0 0 1-1.201.508 1.68 1.68 0 0 1-1.2-.508 1.753 1.753 0 0 1-.369-1.885zM9.035 9.51a1.698 1.698 0 0 1 1.569-1.068c.687 0 1.306.421 1.569 1.068.262.646.117 1.39-.368 1.885a1.68 1.68 0 0 1-1.201.507 1.68 1.68 0 0 1-1.2-.507 1.753 1.753 0 0 1-.369-1.885zM19.18 20.754l-1.645-1.667a3.263 3.263 0 0 0 1.796-1.83l1.64 1.672zm-5.841-9.81 1.64 1.673a3.17 3.17 0 0 0-1.077.723c-.308.314-.551.69-.714 1.102l-1.64-1.672zm2.942-1.481c-.939 0-1.7-.775-1.7-1.732S15.342 6 16.281 6c.45 0 .883.182 1.202.507.319.325.498.765.497 1.224 0 .957-.76 1.732-1.7 1.732zm1.7 14.806c0 .956-.762 1.731-1.7 1.731s-1.7-.775-1.7-1.731.761-1.732 1.7-1.732c.45 0 .883.182 1.202.507.319.325.498.765.497 1.225zm-1.083-13.9v2.144a3.075 3.075 0 0 0-1.07 0v-2.144zm0 9.212v2.145h-1.07V19.58c.354.063.716.063 1.07 0zm5.332-3.392a1.755 1.755 0 0 1 .369-1.887 1.677 1.677 0 0 1 1.852-.376c.635.268 1.049.9 1.049 1.6 0 .46-.179.9-.497 1.225a1.683 1.683 0 0 1-2.773-.562zM6.5 15.82c0-.956.76-1.731 1.7-1.731.938 0 1.699.774 1.7 1.73 0 .46-.18.9-.498 1.225a1.683 1.683 0 0 1-1.202.507c-.939 0-1.7-.775-1.7-1.73zm15.083.545-2.104-.002a3.25 3.25 0 0 0 0-1.09h2.104zm-8.643 0h-2.105v-1.09h2.105a3.25 3.25 0 0 0 0 1.09zm3.29 2.037c-1.331 0-2.41-1.099-2.41-2.454s1.079-2.455 2.41-2.455c1.33 0 2.409 1.1 2.41 2.455 0 1.355-1.08 2.454-2.41 2.454z'
			/>
		</g>
	</svg>
);
export default SvgBlz;
