import * as React from 'react';
import { SVGProps } from 'react';
const SvgNgc = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#f80000' />
			<path
				fill='#fff'
				fillRule='nonzero'
				d='M20.5 16.842 13.804 26l.247-7.441H12.5l.634-6.532 7.084-.976-2.326 5.925zm-5.498 6.498v-1.75c0-.13-.11-.236-.246-.236s-.247.105-.247.235v1.751c0 .13.11.236.247.236s.246-.106.246-.236zm-1.832-5.286h1.41l-.065 2.693c-.001.071.027.14.079.19s.123.08.197.08h.012a.27.27 0 0 0 .275-.258l.065-3.177h-1.41l.473-5.219c.005-.06-.016-.12-.059-.165s-.103-.07-.167-.07h-.04a.223.223 0 0 0-.226.197zm0-6.6L15.778 9.3c.14-2.155-1.27-2.492-1.27-2.492L14.72 6c2.326.673 1.974 3.232 1.974 3.232l3.383 1.414z'
			/>
		</g>
	</svg>
);
export default SvgNgc;
