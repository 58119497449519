import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar50 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar50_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar50_svg__cls-1{fill:#a7aece}.avatar50_svg__cls-4{fill:#fff}.avatar50_svg__cls-3{fill:#fba875}.avatar50_svg__cls-7{fill:none;stroke-linecap:round;stroke:#515570;stroke-linejoin:round;stroke-width:2px;opacity:.4}.avatar50_svg__cls-8{fill:#356cb6}.avatar50_svg__cls-11,.avatar50_svg__cls-9{fill:#393c54}.avatar50_svg__cls-9{opacity:.2}.avatar50_svg__cls-12{fill:#f85565}.avatar50_svg__cls-14{fill:#ff8475}'
				}
			</style>
		</defs>
		<circle cx={64} cy={64} r={60} className='avatar50_svg__cls-1' />
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.3,
				fill: '#fff',
			}}
		/>
		<circle cx={91.32} cy={60.43} r={7.93} className='avatar50_svg__cls-3' />
		<path
			d='M64 124.1a59.73 59.73 0 0 0 39.8-15.1l-2.2-5.36c-1.71-4-6.22-6.64-11.29-6.64H37.69c-5.07 0-9.58 2.66-11.29 6.64L24.2 109A59.73 59.73 0 0 0 64 124.1Z'
			className='avatar50_svg__cls-4'
		/>
		<path d='M74 100H54l-.78-5.48h21.56L74 100z' className='avatar50_svg__cls-1' />
		<path
			d='M64 84.75V98.5'
			style={{
				strokeWidth: 18,
				stroke: '#fba875',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={36.68} cy={60.43} r={7.93} className='avatar50_svg__cls-3' />
		<path
			d='M64 94.37a28.31 28.31 0 0 1-28.32-28.32V53.43a28.32 28.32 0 1 1 56.64 0v12.62A28.31 28.31 0 0 1 64 94.37Z'
			style={{
				fill: '#ffbb94',
			}}
		/>
		<path d='m74.39 53 9.61-.25M53 53l-9.61-.25' className='avatar50_svg__cls-7' />
		<path d='M41.5 27.7h45v17.1h-45z' className='avatar50_svg__cls-4' />
		<path
			d='M91.09 21.15a35.14 35.14 0 0 0-62.22 22.38c0 6.4 3.82 12.68 6.81 17.85.43-.1 12.32-26.38 55.41-40.23Z'
			className='avatar50_svg__cls-8'
		/>
		<path
			d='M36.77 21.15A35.14 35.14 0 0 1 99 43.53c0 6.28-3.79 12.58-6.68 17.68-.47.19-14.47-26.21-55.55-40.06Z'
			className='avatar50_svg__cls-8'
		/>
		<path
			d='M36.77 21.15A35.14 35.14 0 0 1 99 43.53c0 6.28-3.79 12.58-6.68 17.68-.47.19-14.47-26.21-55.55-40.06Z'
			className='avatar50_svg__cls-9'
		/>
		<path
			d='M63.86 8.4a35 35 0 0 0-15.17 3.43C77.48 22 92.52 38.31 98.75 46.37a16.94 16.94 0 0 0 .25-2.84A35.13 35.13 0 0 0 63.86 8.4Z'
			className='avatar50_svg__cls-9'
		/>
		<path
			d='M64 103.3a11 11 0 0 1-10.78-8.8 11.4 11.4 0 0 0-.22 2.2 11 11 0 0 0 22 0 11.4 11.4 0 0 0-.22-2.2A11 11 0 0 1 64 103.3Z'
			className='avatar50_svg__cls-4'
		/>
		<path
			d='M64 103.3a11 11 0 0 1-10.78-8.8 11.4 11.4 0 0 0-.22 2.2 11 11 0 0 0 22 0 11.4 11.4 0 0 0-.22-2.2A11 11 0 0 1 64 103.3Z'
			style={{
				opacity: 0.1,
				fill: '#393c54',
			}}
		/>
		<path
			d='M71.55 76a1 1 0 0 1 1 1.06 8.55 8.55 0 0 1-17 0 1 1 0 0 1 1-1.06ZM48.91 75c-1.15 0-2-.37-1.89-.78 1.06-3 17-5.38 17-5.38s15.92 2.35 17 5.38'
			className='avatar50_svg__cls-11'
		/>
		<path
			d='M64 84.66a8.51 8.51 0 0 0 6.29-2.79 9.06 9.06 0 0 0-12.58 0A8.51 8.51 0 0 0 64 84.66Z'
			className='avatar50_svg__cls-12'
		/>
		<path d='M58 76a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2Z' className='avatar50_svg__cls-4' />
		<circle cx={78} cy={59.28} r={3} className='avatar50_svg__cls-11' />
		<circle cx={50} cy={59.28} r={3} className='avatar50_svg__cls-11' />
		<path
			d='M64 60.75v8.5'
			style={{
				strokeWidth: 6,
				stroke: '#fba875',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M38 118.09a59.92 59.92 0 0 0 11 4V97H38ZM79 122.11a59.92 59.92 0 0 0 11-4V97H79Z'
			className='avatar50_svg__cls-12'
		/>
		<circle cx={64} cy={114} r={1.5} className='avatar50_svg__cls-14' />
		<circle cx={64} cy={120} r={1.5} className='avatar50_svg__cls-14' />
	</svg>
);
export default SvgAvatar50;
