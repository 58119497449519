import * as React from 'react';
import { SVGProps } from 'react';
const SvgSportIconArchery = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 512 512'
		className='svg-icon'
		{...props}>
		<path
			d='M454.906 284.481c0 125.619-101.761 227.453-227.453 227.453-45.025 0-86.995-13.093-122.346-35.715-.073 0-.146-.073-.218-.146a226.625 226.625 0 0 1-38.26-30.768 188.468 188.468 0 0 1-3.782-3.928c-8.801-9.165-16.803-19.13-23.931-29.677 0-.073 0-.073-.073-.073-1.018-1.527-2.036-3.055-2.982-4.583-.073-.073-.146-.146-.146-.218-.946-1.383-1.819-2.837-2.691-4.364l-.073-.073c-5.673-9.311-10.765-19.13-14.984-29.314l-.073-.073c-1.382-3.346-2.764-6.764-4.001-10.255-.654-1.746-1.309-3.491-1.891-5.237-.364-1.237-.8-2.474-1.164-3.637-.873-2.765-1.746-5.528-2.473-8.293-1.382-4.582-2.473-9.31-3.418-14.038-.146-.727-.291-1.454-.436-2.11C1.527 314.885 0 299.901 0 284.481c0-62.846 25.531-119.727 66.701-160.826 41.17-41.169 97.979-66.628 160.825-66.628 2.473 0 5.019.073 7.492.146 2.546.073 5.019.146 7.492.364 2.473.145 4.946.363 7.42.581.291 0 .582.073.873.073 2.182.218 4.365.51 6.547.801 1.818.218 3.637.509 5.455.8 1.673.218 3.346.509 5.019.8a221.716 221.716 0 0 1 48.298 14.402 174.76 174.76 0 0 1 9.966 4.51c.072 0 .145 0 .218.073 3.273 1.528 6.474 3.201 9.602 4.947 3.128 1.6 6.11 3.346 9.092 5.164.146.073.291.218.436.291a282.83 282.83 0 0 1 7.783 4.946 225.347 225.347 0 0 1 29.605 23.422c.654.51 1.237 1.164 1.891 1.673 1.018 1.092 2.109 2.11 3.201 3.274.145.073.291.218.436.363 1.164 1.165 2.4 2.401 3.564 3.637.582.582 1.164 1.237 1.818 1.818 9.674 10.402 18.475 21.749 26.113 33.897 2.691 4.363 5.31 8.728 7.71 13.238 17.457 32.222 27.349 69.101 27.349 108.234z'
			style={{
				fill: '#fff',
			}}
		/>
		<path
			d='M227.493 468.215c-101.3 0-183.713-82.413-183.713-183.713s82.413-183.713 183.713-183.713 183.713 82.413 183.713 183.713-82.414 183.713-183.713 183.713z'
			style={{
				fill: '#ef8051',
			}}
		/>
		<path
			d='M227.493 424.474c-77.181 0-139.972-62.791-139.972-139.972 0-77.181 62.791-139.972 139.972-139.972s139.972 62.791 139.972 139.972c0 77.181-62.791 139.972-139.972 139.972z'
			style={{
				fill: '#22aeb0',
			}}
		/>
		<path
			d='M227.493 380.733c-53.062 0-96.231-43.169-96.231-96.231s43.169-96.231 96.231-96.231 96.231 43.169 96.231 96.231c-.001 53.062-43.169 96.231-96.231 96.231z'
			style={{
				fill: '#e9e950',
			}}
		/>
		<path
			d='M227.493 336.991c-28.943 0-52.489-23.547-52.489-52.49s23.546-52.489 52.489-52.489c28.943 0 52.489 23.547 52.489 52.489 0 28.944-23.546 52.49-52.489 52.49z'
			style={{
				fill: '#e88f52',
			}}
		/>
		<path
			d='m388.351 123.655-92.814 92.814-30.987 30.914-40.152 40.152-.218.291-64.665 64.665-30.987 30.914-30.841 30.914-27.277 27.204-3.783 3.783a188.468 188.468 0 0 1-3.782-3.928c-8.801-9.237-16.803-19.13-23.931-29.677 0-.073 0-.073-.073-.073-.946-1.527-1.964-2.983-2.836-4.51-1.091-1.528-2.037-3.055-2.982-4.655l-.073-.073c-5.673-9.383-10.692-19.13-14.984-29.314l-.073-.073c-1.382-3.346-2.764-6.764-4.001-10.255-.654-1.746-1.309-3.491-1.891-5.237-.364-1.237-.8-2.474-1.164-3.637-.873-2.765-1.746-5.528-2.473-8.293-1.309-4.655-2.473-9.31-3.418-14.038-.146-.727-.291-1.454-.436-2.11a229.192 229.192 0 0 1-4.437-44.952c0-62.846 25.458-119.654 66.628-160.826 41.17-41.169 97.979-66.628 160.825-66.628 2.473 0 5.019.073 7.492.146 2.546.073 5.019.146 7.492.364 2.473.145 4.946.363 7.42.581.291 0 .582.073.873.073 2.182.218 4.365.51 6.547.801 1.818.218 3.637.509 5.455.8 1.673.218 3.346.509 5.019.8a221.716 221.716 0 0 1 48.298 14.402 174.76 174.76 0 0 1 9.966 4.51c.072 0 .145 0 .218.073 3.273 1.528 6.474 3.201 9.602 4.947 3.128 1.6 6.11 3.346 9.092 5.164.146.073.291.218.436.291a282.83 282.83 0 0 1 7.783 4.946 225.347 225.347 0 0 1 29.605 23.422c.582.51 1.237 1.091 1.819 1.746 1.091 1.019 2.182 2.037 3.273 3.201.143.071.289.217.435.361z'
			style={{
				opacity: 0.1,
				fill: '#fff',
			}}
		/>
		<path
			d='M427.59 176.266 234.484 290.249c-1.763 1.048-3.808 1.329-5.801.818a7.63 7.63 0 0 1-4.702-3.527c-2.147-3.629-.92-8.357 2.709-10.504l193.132-114.008a227.857 227.857 0 0 1 7.768 13.238z'
			style={{
				opacity: 0.1,
				fill: '#040000',
			}}
		/>
		<path
			d='m467.199 44.808-60.519 60.518-1.527-5.747-.728-2.836-9.528-36.224 56.881-56.955L455.342 0l2.547 9.675 7.055 26.549.727 2.837z'
			style={{
				fill: '#b55a6d',
			}}
		/>
		<path
			d='m406.656 105.338 44.835 11.789L512 56.618l-44.835-11.789z'
			style={{
				fill: '#b55a6d',
			}}
		/>
		<path
			d='M477.164 45.68 233.49 289.354c-.946.947-2.036 1.6-3.273 1.891-.727.291-1.454.364-2.182.364-2.036 0-4-.8-5.382-2.255a7.618 7.618 0 0 1-.073-10.838l98.27-98.27 30.914-30.841 31.059-31.059 21.603-21.603 60.519-60.518 1.382-1.382c1.382-1.454 3.346-2.254 5.383-2.254s4 .8 5.455 2.254c2.981 2.982 2.981 7.855-.001 10.837z'
			style={{
				fill: '#53431e',
			}}
		/>
		<path
			d='M224.426 287.569a5.11 5.11 0 0 1 0-7.228L468.099 36.667a5.11 5.11 0 1 1 7.228 7.229L231.655 287.569a5.112 5.112 0 0 1-7.229 0z'
			style={{
				fill: '#53431e',
			}}
		/>
	</svg>
);
export default SvgSportIconArchery;
