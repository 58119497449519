import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionMongolia = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 1200 600'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='#f9cf02'>
			<path fill='#da2032' d='M0 0h1200v600H0z' />
			<path fill='#0066b3' d='M400 0h400v600H400z' />
			<g fill='#ffd900' transform='translate(400) scale(.25)'>
				<path d='M-1239.998 1080h200v960h-200zm680 0h200v960h-200zm-440 840 200 120 200-120zm0-120h400v80h-400zm0-720 200 120 200-120zm0 160h400v80h-400zM-705 1384.216a118.04 118.04 0 0 1 22.968 69.768c.03 65.176-52.796 118.028-117.968 118.044a94 94 0 0 0-93.988 93.988A94 94 0 0 0-800 1760c110.452 0 200-89.544 200-200 0-73.424-40.392-140.908-105-175.784zm-95 241.8a40 40 0 0 1 40 40 40 40 0 0 1-40 40 40 40 0 0 1-40-40 40 40 0 0 1 40-40z' />
				<path d='M-895 1735.784a118.04 118.04 0 0 1-22.968-69.768c-.03-65.176 52.796-118.028 117.968-118.044a94 94 0 0 0 93.988-93.988A94 94 0 0 0-800 1360c-110.452 0-200 89.544-200 200 0 73.424 40.392 140.908 105 175.784zm95-241.8a40 40 0 0 1-40-40 40 40 0 0 1 40-40 40 40 0 0 1 40 40 40 40 0 0 1-40 40zm-219.602-678.364a220 220 0 0 0-.4 4.38 220 220 0 0 0 220 220 220 220 0 0 0 220-220 220 220 0 0 0 0-4 240 240 0 0 1-219.844 143.984 240 240 0 0 1-219.764-144.376z' />
				<circle cx={-799.835} cy={759.997} r={160} />
				<path d='M-783.186 240.05c-19.88 9.02-27.308 25.284-28.908 41.484-1 13.64 5.02 29.004 5.62 42.344 0 22.956-23.752 30.516-23.752 63.28 0 11.26 10.4 23.668 10.4 52.892-1.8 15.34-10.36 18.8-20 20a20 20 0 0 1-20-20 20 20 0 0 1 5.54-13.76 20 20 0 0 1 2.04-2c4.56-4.6 10.82-6.38 10.78-18.52 0-6.24-4.04-11.92-7.9-22.968-3.64-10.72-1-28.64 7.66-39.22-14 5.4-22.628 18.82-27.028 30.86-4.64 14.8-.6 23.324-6.96 35.624-3.88 7.96-8.5 11.26-12.88 17.9-5.18 7.24-11.26 24.172-11.26 32.032a100 100 0 0 0 100 100 100 100 0 0 0 100-100c0-7.86-6-24.792-11.18-32.032-4.38-6.64-9.08-9.94-12.96-17.9-6.28-12.3-2.24-20.824-6.88-35.624-4.4-12.04-13.04-25.456-27.032-30.86 8.66 10.58 11.3 28.5 7.66 39.22-3.88 11.04-7.96 16.72-7.96 22.968 0 12.14 6.22 13.92 10.78 18.52a20 20 0 0 1 2.04 2 20 20 0 0 1 5.54 13.76 20 20 0 0 1-20 20c-11.06-1.4-19-6.56-20-20 0-38.504 16.48-40.96 16.48-69.452 0-40.684-36.484-59.944-36.484-89.688 0-10.08 2.36-27.26 16.64-40.86z' />
			</g>
		</g>
	</svg>
);
export default SvgRegionMongolia;
