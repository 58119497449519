import React, { useContext, useEffect } from "react";
import { ReactNotifications } from "react-notifications-component";
import Portal from "./layout/Portal/Portal";
import "./App.scss";
import ThemeContext from "./context/ThemeContext";
import Wrapper from "./layout/Wrapper/Wrapper";
import classNames from "classnames";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PopupComponent from "./component/Popup";
import UserControl from "./pages/user/UserControl";
import { ToastContainer } from "react-toastify";
import { ToastCloseButton } from "./component/Toasts";
import { useSelector } from "react-redux";
import {
  getSessionLoad,
  getSocketConnected,
} from "./pages/sport/sportSelector";
import SocketContext from "./context/SocketContext";
import { changeFavicon, updateMetaTags } from "./helpers/helpers";
import UserPanel from "./component/UserPanel";
import NavigationMenu from "./pages/home/NavigationMenu";
import SwiperCore from "swiper";
import { Autoplay, Pagination } from "swiper/modules";
SwiperCore.use([Autoplay]);

function App() {
  const { direction, mobileDesign, showBetCoupon } = useContext(ThemeContext);

  const { welcomeMessage } = useContext(SocketContext);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (welcomeMessage.title) {
      document.title = welcomeMessage.title;
    }

    if (welcomeMessage.description) {
      updateMetaTags(welcomeMessage.description, welcomeMessage.keywords);
    }

    if (welcomeMessage.favicon) {
      changeFavicon(welcomeMessage.favicon);
    }
  }, [welcomeMessage]);

  const searchParams = new URLSearchParams(location.search);
  const profilOpen = searchParams.get("profile");
  const openGame = searchParams.get("openGames");
  const sessionLoad = useSelector(getSessionLoad);
  const getRoute = location.pathname.split("/");
  const firstRoute = getRoute[1];
  const secondRoute = getRoute[2];
  const thirdRoute = getRoute[3];
  const fourthRoute = getRoute[4];
  const { gamePageName } = useParams();

  return (
    <div
      dir="ltr"
      className={classNames([
        mobileDesign ? "mobileAtox is-mobile" : "desktopAtox is-web",
        "webp webp-alpha webp-animation webp-lossless",
      ])}
    >
      <div
        className={classNames([
          "layout-bc",
          "theme-default",
          "page-full-size",
          { "casino-lock-scroll": openGame },
          { prematch: secondRoute === "prematch" },
          { live: secondRoute === "live" },
          { "compact-footer": firstRoute },
          // { "page-full-size": !mobileDesign },
          {
            "casino-slots": firstRoute === "casino" && secondRoute === "slots",
          },
          {
            "live-casino-games":
              firstRoute === "casino" && secondRoute === "live",
          },
          {
            "mobileGameOpened":
              firstRoute === "casino" && mobileDesign && fourthRoute
          },
          {
            sportsbook:
              firstRoute === "sports" &&
              sessionLoad &&
              thirdRoute !== "results",
          },
          { "betslip-Hidden": !showBetCoupon && mobileDesign },
          { "betslip-Full ": showBetCoupon || mobileDesign },
          // {  "overflowY": firstRoute === "casino" }
        ])}
        dir={direction}
      >
        <Wrapper />
        <Portal id="portal-notification">
          <ReactNotifications />
        </Portal>

        <UserPanel />
        {/* <NavigationMenu /> */}

        <ToastContainer
          position="bottom-right" // Varsayılan pozisyon
          autoClose={5000} // Otomatik kapanma süresi
          newestOnTop={false} // En yeni bildirimi üste koyma
          closeOnClick // Tıklamayla kapatma
          rtl={false} // Sağdan sola yazı yönü
          pauseOnFocusLoss // Pencere odağını kaybedince duraklatma
          theme="dark" // Tema ayarı: light, dark veya colored
          closeButton={ToastCloseButton}
          toastClassName="toast show"
        />
      </div>
    </div>
  );
}

export default App;
