import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomUpcoming = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		viewBox='0 0 58 58'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			d='M46.5 4v4h-7V4h-24v4h-7V4h-8v11h54V4z'
			style={{
				fill: '#f05565',
			}}
		/>
		<path
			d='M8.5 15h-8v43h54V15h-39z'
			style={{
				fill: '#efebde',
			}}
		/>
		<path
			d='M37.5 22h-29v29h38V22h-9zm-9 2h7v7h-7v-7zm7 16h-7v-7h7v7zm-16-7h7v7h-7v-7zm0-9h7v7h-7v-7zm-9 0h7v7h-7v-7zm0 9h7v7h-7v-7zm7 16h-7v-7h7v7zm9 0h-7v-7h7v7zm9 0h-7v-7h7v7zm9 0h-7v-7h7v7zm0-9h-7v-7h7v7zm-7-9v-7h7v7h-7z'
			style={{
				fill: '#d5d0bb',
			}}
		/>
		<path
			d='M8.5 0h7v8h-7zM39.5 0h7v8h-7z'
			style={{
				fill: '#36495e',
			}}
		/>
		<path
			d='M19.5 33h7v7h-7z'
			style={{
				fill: '#7f6e5d',
			}}
		/>
		<path
			d='M56.261 57H32.57a1.238 1.238 0 0 1-1.069-1.861l11.845-20.306a1.237 1.237 0 0 1 2.138 0L57.33 55.139A1.238 1.238 0 0 1 56.261 57z'
			style={{
				fill: '#efc41a',
			}}
		/>
		<path
			d='M44.5 50a1 1 0 0 1-1-1v-8a1 1 0 0 1 2 0v8a1 1 0 0 1-1 1zM44.5 54c-.26 0-.52-.11-.71-.29-.18-.19-.29-.45-.29-.71 0-.26.11-.52.29-.71.38-.37 1.04-.37 1.42 0 .18.19.29.45.29.71 0 .26-.11.52-.29.71-.19.18-.44.29-.71.29z'
			style={{
				fill: '#fff',
			}}
		/>
	</svg>
);
export default SvgCustomUpcoming;
