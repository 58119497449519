import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionVanuatu = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		viewBox='0 0 950 570'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#d21034' d='M0 0h950v285H0z' />
		<path fill='#009543' d='M0 285h950v285H0z' />
		<path d='m0 0 552.813 285L0 570z' />
		<path d='M0 234h950v102H0z' />
		<path fill='#fdce12' d='M0 40.502 474.251 285 0 529.496z' />
		<path fill='#fdce12' d='M0 270h950v30H0z' />
		<path d='M0 74.255 408.782 285 0 495.745z' />
		<path
			fill='#fdce12'
			d='M115.481 339.524V372c28.253 0 83.7-21.65 83.7-91.356 0-69.706-59.144-82.644-76.835-82.644C104.656 198 50 210.674 50 267.706c0 57.032 53.6 62.313 64.161 62.313s50.167-9.506 45.679-52.28c0 12.146-13.73 37.758-42.511 37.758-28.78 0-46.734-21.388-46.734-42.51 0-21.124 17.426-49.376 47.79-49.376s52.543 26.931 52.543 54.128c0 27.196-21.386 61.785-55.447 61.785z'
		/>
		<g id='regionVanuatu_svg__a' fill='#fdce12'>
			<path
				stroke='#fdce12'
				d='m90.728 297.245 1.683 1.089s13.367-30.496 49.309-52.18c-4.555 2.475-28.615 14.456-50.992 51.091z'
			/>
			<path d='M94.193 291.997s-4.158-8.515-.495-12.277c3.664-3.763 1.981 10 1.981 10l1.584-2.277s-2.476-10.397.297-12.575c2.772-2.178 1.386 10 1.386 10l1.683-2.178s-1.782-10.099.891-11.981c2.674-1.881.991 9.506.991 9.506l1.98-2.278s-1.485-9.604 1.782-11.881c3.268-2.278.396 9.109.396 9.109l2.178-2.376s-.99-8.813 1.783-10.793c2.772-1.98.396 8.317.396 8.317l1.98-2.079s-.594-8.218 2.079-10.099c2.674-1.882.198 7.822.198 7.822l2.08-1.981s-.495-8.218 2.871-9.802c3.366-1.584-.594 7.723-.594 7.723l2.277-1.98s.693-9.604 3.565-10.496c2.871-.891-1.287 8.516-1.287 8.516l2.079-1.684s1.089-8.218 4.257-9.208c3.169-.99-1.98 7.525-1.98 7.525l2.079-1.584s2.278-8.218 5.248-9.109c2.971-.891-3.069 7.723-3.069 7.723l1.98-1.386s3.267-7.427 5.941-7.922c2.673-.495-2.971 6.139-2.971 6.139l2.476-1.485s4.257-7.723 5.743-5.347c1.485 2.377-5.941 5.842-5.941 5.842l-2.476 1.386s9.011-4.257 10.1-2.079c1.089 2.178-12.377 3.565-12.377 3.565l-2.178 1.485s9.01-2.476 7.921-.198c-1.089 2.277-10 1.782-10 1.782l-2.08 1.485s9.01-2.178 7.822-.099c-1.188 2.079-10 1.881-10 1.881l-2.178 1.783s8.812-2.278 7.525.396c-1.288 2.673-11.288 2.574-11.288 2.574l-2.475 2.277s9.307-3.168 8.515-.693c-.792 2.476-11.387 3.763-11.387 3.763l-2.376 2.376s10-3.366 7.03-.297c-2.97 3.07-10.198 3.862-10.198 3.862l-2.872 3.465s9.902-5.941 8.515-2.574c-1.386 3.366-11.089 5.644-11.089 5.644l-1.98 2.376s8.911-5.149 8.02-2.277c-.891 2.871-10.397 5.94-10.397 5.94l-1.584 2.179s10.495-6.238 9.604-3.07c-.891 3.169-10.891 5.446-10.891 5.446l-1.287 1.98s9.703-5.544 9.802-2.871c.099 2.673-11.387 5.347-11.387 5.347l-.198-2.278z' />
		</g>
		<use xlinkHref='#regionVanuatu_svg__a' transform='matrix(-1 0 0 1 238.5 0)' />
	</svg>
);
export default SvgRegionVanuatu;
