import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomDeal = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		viewBox='0 0 496 496'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			d='M0 41.678v236.8l35.2 22.4 107.2-168.8z'
			style={{
				fill: '#05365b',
			}}
		/>
		<path
			d='M496 45.678v-4l-142.4 90.4 107.2 168.8 35.2-22.4z'
			style={{
				fill: '#008c61',
			}}
		/>
		<path
			d='M404 335.278c30.4-19.2 38.4-60.8 17.6-92.8l-25.6-40c-20.8-32-61.6-42.4-92-23.2l-91.2 57.6c-30.4 19.2-38.4 60.8-17.6 92.8l25.6 40c20.8 32 61.6 42.4 92 23.2l91.2-57.6z'
			style={{
				fill: '#e29d81',
			}}
		/>
		<path
			d='M392.8 342.478c11.2-7.2 14.4-22.4 7.2-33.6-7.2-11.2-22.4-14.4-33.6-7.2l-168 106.4c-11.2 7.2-14.4 22.4-7.2 33.6 7.2 11.2 22.4 14.4 33.6 7.2l168-106.4z'
			style={{
				fill: '#e29d81',
			}}
		/>
		<path
			d='m196 329.678 25.6 40c20.8 32 61.6 42.4 92 23.2l91.2-57.6c30.4-19.2 38.4-60.8 17.6-92.8l-25.6-40'
			style={{
				fill: '#d3856a',
			}}
		/>
		<path
			d='M288.8 303.278c11.2-7.2 14.4-22.4 7.2-33.6-7.2-11.2-22.4-14.4-33.6-7.2l-168 107.2c-11.2 7.2-14.4 22.4-7.2 33.6 7.2 11.2 22.4 14.4 33.6 7.2l168-107.2z'
			style={{
				fill: '#e29d81',
			}}
		/>
		<path
			d='M324 335.278c11.2-7.2 14.4-22.4 7.2-33.6-7.2-11.2-22.4-14.4-33.6-7.2l-168 107.2c-11.2 7.2-14.4 22.4-7.2 33.6 7.2 11.2 22.4 14.4 33.6 7.2l168-107.2zM288 248.078c11.2-7.2 14.4-22.4 7.2-33.6-7.2-11.2-22.4-14.4-33.6-7.2l-168 107.2c-11.2 7.2-14.4 22.4-7.2 33.6 7.2 11.2 22.4 14.4 33.6 7.2l168-107.2z'
			style={{
				fill: '#e29d81',
			}}
		/>
		<path
			d='M321.6 171.278s-94.4 13.6-102.4 16c-7.2 2.4-29.6 56.8-4 63.2 26.4 6.4 68-27.2 68-27.2l38.4-52z'
			style={{
				fill: '#e29d81',
			}}
		/>
		<path
			d='M388.697 135.711h29.602V308.52h-29.602z'
			style={{
				fill: '#fff',
			}}
			transform='rotate(-32.451 403.477 222.104)'
		/>
		<path
			d='m369.6 141.678-24.8 16 92.8 145.6'
			style={{
				fill: '#dfe2e5',
			}}
		/>
		<path
			d='m496 41.678-142.4 90.4 107.2 168.8'
			style={{
				fill: '#006d49',
			}}
		/>
		<path
			d='m401.6 368.878-15.2-9.6c8.8.8 18.4-3.2 23.2-11.2 7.2-11.2 4-26.4-7.2-33.6l-112-71.2c8-16.8-8-52.8-13.6-55.2-7.2-2.4-93.6-14.4-101.6-16-27.2-8-58.4 4-75.2 30.4l-25.6 40c-20.8 32-12.8 73.6 17.6 92.8l7.2 4.8c.8.8 2.4 2.4 4 3.2l168 107.2c11.2 7.2 26.4 4 33.6-7.2 4-6.4 4.8-13.6 2.4-20l32 20.8c11.2 7.2 26.4 4 33.6-7.2 5.6-8 4.8-18.4 0-25.6l1.6.8c11.2 7.2 26.4 4 33.6-7.2 8.8-13.6 5.6-28.8-6.4-36z'
			style={{
				fill: '#f9bda0',
			}}
		/>
		<path
			d='M279.2 440.878c-4.8-3.2-6.4-8.8-3.2-13.6l3.2-5.6c3.2-4.8 8.8-6.4 13.6-3.2 4.8 3.2 6.4 8.8 3.2 13.6l-3.2 5.6c-2.4 4.8-8.8 6.4-13.6 3.2zM383.2 401.678c-4.8-3.2-6.4-8.8-3.2-13.6l3.2-5.6c3.2-4.8 8.8-6.4 13.6-3.2 4.8 3.2 6.4 8.8 3.2 13.6l-3.2 5.6c-2.4 4.8-8.8 6.4-13.6 3.2zM348 433.678c-4.8-3.2-6.4-8.8-3.2-13.6l3.2-5.6c3.2-4.8 8.8-6.4 13.6-3.2 4.8 3.2 6.4 8.8 3.2 13.6l-3.2 5.6c-3.2 4.8-8.8 6.4-13.6 3.2zM384 347.278c-4.8-3.2-6.4-8.8-3.2-13.6l3.2-5.6c3.2-4.8 8.8-6.4 13.6-3.2 4.8 3.2 6.4 8.8 3.2 13.6l-2.4 5.6c-3.2 4.8-9.6 5.6-14.4 3.2z'
			style={{
				fill: '#ffe4c7',
			}}
		/>
		<path
			d='M77.486 136.065h29.6v172.8h-29.6z'
			style={{
				fill: '#fff',
			}}
			transform='rotate(-147.554 92.286 222.465)'
		/>
		<path
			d='m126.4 141.678 24.8 16-92.8 145.6'
			style={{
				fill: '#dfe2e5',
			}}
		/>
		<path
			d='m0 41.678 142.4 90.4-107.2 168.8'
			style={{
				fill: '#00263d',
			}}
		/>
		<path
			d='M56 195.278c-4.8 8-15.2 9.6-22.4 4.8-8-4.8-9.6-15.2-4.8-22.4 4.8-8 15.2-9.6 22.4-4.8 7.2 4.8 9.6 15.2 4.8 22.4z'
			style={{
				fill: '#ffc114',
			}}
		/>
		<path
			d='m402.4 313.678-112-71.2c8-16.8-8-52.8-13.6-55.2-3.2-1.6-25.6-4.8-48.8-8l-25.6 80 210.4 67.2c-1.6-4.8-4.8-9.6-10.4-12.8z'
			style={{
				fill: '#e8ab92',
			}}
		/>
		<path
			d='M296 192.878c7.2 12 10.4 43.2.8 48.8l-80.8 17.6c-9.6 5.6-22.4 0-29.6-11.2-7.2-12-4.8-25.6 4.8-31.2l57.6-33.6c9.6-5.6 39.2-4 46.4 7.2l.8 2.4z'
			style={{
				fill: '#e29d81',
			}}
		/>
	</svg>
);
export default SvgCustomDeal;
