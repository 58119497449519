// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import sportWebSocketSlice from './features/sportWebSocketSlice';

export const store = configureStore({
  reducer: {
    sportWebSocket: sportWebSocketSlice,
  },
  // Redux DevTools entegrasyonu:
  devTools: process.env.NODE_ENV !== 'production',
});

// RootState ve AppDispatch tiplerini export ediyoruz. Bu tipleri useSelector ve useDispatch kullanırken type-safe yapmak için kullanacağız.
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
