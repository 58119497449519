import * as React from 'react';
import { SVGProps } from 'react';
const SvgOxy = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#2261F6' />
			<g fill='#FFF' transform='translate(6.5 5.5)'>
				<ellipse cx={9.5} cy={19.456} rx={1.583} ry={1.544} />
				<ellipse cx={9.5} cy={1.544} rx={1.583} ry={1.544} />
				<ellipse cx={9.5} cy={6.074} rx={1.267} ry={1.235} />
				<ellipse cx={5.067} cy={3.809} rx={1.267} ry={1.235} />
				<ellipse cx={5.067} cy={17.294} rx={1.267} ry={1.235} />
				<ellipse cx={13.458} cy={8.39} rx={1.267} ry={1.235} />
				<ellipse cx={13.458} cy={12.662} rx={1.267} ry={1.235} />
				<ellipse cx={9.5} cy={14.927} rx={1.267} ry={1.235} />
				<ellipse cx={9.5} cy={10.5} rx={1.003} ry={1} />
				<ellipse cx={5.067} cy={12.713} rx={1.003} ry={1} />
				<ellipse cx={5.067} cy={8.39} rx={1.003} ry={1} />
				<circle cx={0.739} cy={6.074} r={1} />
				<circle cx={0.739} cy={10.5} r={1} />
				<circle cx={0.739} cy={14.926} r={1} />
				<ellipse cx={13.458} cy={3.809} rx={1.583} ry={1.544} />
				<ellipse cx={13.458} cy={17.243} rx={1.583} ry={1.544} />
				<ellipse cx={17.417} cy={6.073} rx={1.583} ry={1.544} />
				<ellipse cx={17.417} cy={14.927} rx={1.583} ry={1.544} />
				<ellipse cx={17.417} cy={10.5} rx={1.583} ry={1.544} />
			</g>
		</g>
	</svg>
);
export default SvgOxy;
