import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar31 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar31_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar31_svg__cls-10{fill:none;stroke-linecap:round;stroke:#393c54;stroke-linejoin:round}.avatar31_svg__cls-7{fill:#393c54}.avatar31_svg__cls-10{stroke-width:6px}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#a7aece',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				fill: '#fff',
				opacity: 0.3,
			}}
		/>
		<path
			d='M40 22a10 10 0 0 0-10 10v81.43a59.62 59.62 0 0 0 20 8.91V32a10 10 0 0 0-10-10Z'
			style={{
				fill: '#7f3838',
			}}
		/>
		<path
			d='M107.85 85.85c0-14.49-9.1-25-21.9-28.94l2-3.31a.59.59 0 0 0-.58-.9l-6 .82a.59.59 0 0 1-.54-1l2.66-3.2a.59.59 0 0 0-.54-1 100 100 0 0 0-21.07 5.4 35 35 0 0 0-21.73 32.13v3.22c0 15.48-1.65 21.18-6.36 26.76a59.94 59.94 0 0 0 72-8.77v-.16a94.24 94.24 0 0 0 2.06-21.05Z'
			style={{
				fill: '#bc5b57',
			}}
		/>
		<path
			d='M88.74 93.46a6.31 6.31 0 0 1-5-7.63 6.55 6.55 0 0 1 7.58-4.72l5.91 1A.8.8 0 0 0 98 81c-3.89-7-13.76-12.64-24-12.64S53.88 74 50 81a.8.8 0 0 0 .83 1.18l5.91-1a6.55 6.55 0 0 1 7.58 4.72 6.31 6.31 0 0 1-5.05 7.63l-8.18 1.37a.8.8 0 0 0-.53 1.23c3.23 5 10.2 8.42 23.44 8.42s20.21-3.38 23.44-8.42a.8.8 0 0 0-.53-1.23Z'
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<path
			d='M81.89 92.19a9 9 0 0 1-2.77 2.21 11.61 11.61 0 0 1-10.24 0'
			style={{
				strokeWidth: 3,
				stroke: '#393c54',
				strokeLinejoin: 'round',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M79.12 86.62c0 2.83-2.29 4.25-5.12 4.25s-5.12-1.42-5.12-4.25c0-1.62 10.24-1.87 10.24 0ZM57.33 87.48l3.05-.73a.71.71 0 0 1 .89.68 4 4 0 0 1-4.28 4 3.9 3.9 0 0 1-3-1.84 3.94 3.94 0 0 1 5.53-5.37.73.73 0 0 1 .08 1.11ZM90.4 87.48l3-.73a.72.72 0 0 1 .89.68 4 4 0 0 1-4.29 4 3.88 3.88 0 0 1-3-1.84 3.94 3.94 0 0 1 5.52-5.37.72.72 0 0 1 .09 1.11ZM92 29H15.28a59.76 59.76 0 0 0-8.19 16H92a8 8 0 0 0 0-16Z'
			className='avatar31_svg__cls-7'
		/>
		<circle
			cx={92}
			cy={37}
			r={8}
			style={{
				fill: '#515570',
			}}
		/>
		<circle
			cx={92}
			cy={37}
			r={4.31}
			style={{
				stroke: '#fff',
				strokeMiterlimit: 10,
				strokeWidth: 2,
				opacity: 0.1,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path d='m13 42 7.5 7.5M66 32.1l8.38-8.38' className='avatar31_svg__cls-10' />
	</svg>
);
export default SvgAvatar31;
