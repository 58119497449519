import * as React from 'react';
import { SVGProps } from 'react';
const SvgOk = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='currentColor' />
			<path
				fill='#FFF'
				d='M27.986 14.669v.064c-1.387.868-2.784 1.726-4.177 2.592a.18.18 0 0 0-.06.075c.372.1.757.175 1.11.337.326.145.66.334.844.651.176.322.22.7.122 1.053-.053.264-.164.512-.264.763-.418 1.002-.838 2.001-1.253 3.007a.585.585 0 0 1-.08.139c-.137-.003-.268-.053-.402-.084-.676-.167-1.356-.331-2.032-.5-.22-.062-.446-.098-.663-.17.006-.137.084-.254.13-.377l.928-2.221c.07-.154.111-.346.011-.496-.125-.214-.376-.295-.598-.356-.816-.21-1.638-.404-2.456-.607-.117-.025-.231-.078-.354-.062-.072.126-.114.268-.172.404-.348.816-.682 1.637-1.028 2.453-.05.114-.08.24-.156.34-.136 0-.264-.053-.395-.084-.699-.172-1.398-.348-2.1-.518-.197-.053-.403-.086-.598-.153a.469.469 0 0 1 .036-.161l3.533-8.47a.181.181 0 0 1 .056-.072c.123.008.24.058.36.086.595.142 1.191.295 1.787.44.309.08.626.142.933.234.016.058-.012.117-.034.172-.24.546-.46 1.1-.693 1.646l-.721 1.732c-.081.203-.187.398-.243.612.104-.036.193-.097.284-.159l4.706-2.95c.086-.054.164-.126.262-.151.144-.011.28.044.417.075.48.103.952.24 1.428.35.516.115 1.022.257 1.532.366zM16.882 12.97c.025.504-.167.983-.364 1.434-.532 1.278-1.07 2.553-1.599 3.831-.192.451-.359.922-.654 1.32-.21.296-.502.524-.84.654a2.884 2.884 0 0 1-1.318.095c-.76-.095-1.5-.3-2.244-.479-1.01-.253-2.027-.498-3.037-.752-.671-.161-1.348-.334-1.972-.64a1.654 1.654 0 0 1-.662-.582 1.114 1.114 0 0 1-.159-.69c.053-.443.217-.864.395-1.27l1.63-3.915c.13-.3.244-.61.411-.893a2.43 2.43 0 0 1 .56-.688c.268-.204.583-.335.916-.382.245-.022.49-.01.735-.005a19.32 19.32 0 0 1 2.247.47c1.036.25 2.069.513 3.104.763.476.117.95.237 1.415.396.311.114.629.23.902.428.31.201.508.536.534.905zm-3.62.891c.02-.147-.085-.273-.208-.337-.25-.136-.535-.186-.807-.256-.722-.18-1.444-.358-2.17-.534a1.442 1.442 0 0 0-.634-.04c-.245.07-.368.318-.451.535-.41.975-.81 1.952-1.222 2.924-.045.114-.09.228-.1.35 0 .14.097.262.216.32.179.092.374.143.563.193l2.255.554c.262.061.537.128.805.07.225-.073.34-.304.423-.507l1.105-2.651c.087-.2.198-.398.226-.62z'
			/>
		</g>
	</svg>
);
export default SvgOk;
