import * as React from 'react';
import { SVGProps } from 'react';
const SvgSportIconCricket = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 512 512'
		className='svg-icon'
		{...props}>
		<path
			d='M510.042 128.159 273.389 364.787c-8.713 8.713-22.841 8.713-31.554 0-13.07-13.068-34.261-13.068-47.33 0l-92.632 92.622a5.577 5.577 0 0 0-.079 7.807c2.157 2.245 2.335 5.714.134 7.915l-35.552 35.547c-4.356 4.356-11.42 4.356-15.777 0L3.268 461.353c-4.356-4.356-4.356-11.419 0-15.775l35.498-35.494a5.578 5.578 0 0 1 7.807-.08c2.245 2.157 5.714 2.334 7.916.134l92.684-92.675c13.07-13.068 13.07-34.257 0-47.326-8.713-8.713-8.713-22.839 0-31.55L383.827 1.958c8.713-8.713 44.031 12.476 78.885 47.325s56.044 70.163 47.33 78.876z'
			style={{
				fill: '#ffb150',
			}}
		/>
		<path
			d='M486.264 104.364 249.611 340.993c-8.713 8.713-22.841 8.713-31.554 0-13.07-13.068-34.261-13.068-47.33 0l-92.632 92.622a5.577 5.577 0 0 0-.079 7.807c2.157 2.245 2.335 5.714.134 7.915l-35.552 35.547c-4.356 4.356-11.42 4.356-15.777 0L3.086 461.153c-4.165-4.37-4.112-11.283.181-15.575l35.498-35.494a5.577 5.577 0 0 1 7.807-.079c2.245 2.157 5.714 2.334 7.916.134l92.684-92.675c13.07-13.068 13.07-34.257 0-47.326-8.713-8.713-8.713-22.839 0-31.55L383.827 1.958c7.706-7.706 36.221 7.985 66.819 35.82 27.71 30.509 43.305 58.9 35.618 66.586z'
			style={{
				fill: '#d18839',
			}}
		/>
		<path
			d='m194.559 364.842-92.632 92.622a5.578 5.578 0 0 0-.08 7.807c2.157 2.245 2.335 5.713.134 7.915l-35.552 35.547c-4.356 4.356-11.42 4.356-15.777 0l-47.33-47.326c-4.356-4.356-4.356-11.419 0-15.775l35.498-35.494a5.578 5.578 0 0 1 7.807-.08c2.245 2.157 5.714 2.334 7.916.134l92.684-92.675c11.718-11.717 12.922-29.955 3.629-43.022 13.124-12.217 33.667-11.943 46.45.838l39.442 39.438c12.782 12.78 13.056 33.321.838 46.444-13.069-9.294-31.309-8.09-43.027 3.627z'
			style={{
				fill: '#007dbc',
			}}
		/>
		<path
			d='M30.899 488.981 3.322 461.407c-4.356-4.356-4.356-11.419 0-15.775l35.498-35.494a5.577 5.577 0 0 1 7.807-.079c2.245 2.157 5.714 2.334 7.916.132l92.684-92.675c11.718-11.717 12.922-29.955 3.629-43.022 13.124-12.217 33.667-11.943 46.45.838l4.004 4.004c-.223.196-.447.387-.667.59 9.293 13.067 8.09 31.307-3.629 43.022L82.152 437.8c-2.202 2.202-5.671 2.023-7.916-.134a5.579 5.579 0 0 0-7.807.08l-35.498 35.495c-4.345 4.345-4.355 11.381-.032 15.74z'
			style={{
				fill: '#004859',
			}}
		/>
		<path
			d='M405.441 186.104a8.357 8.357 0 0 1-.006 11.814l-.789.789a8.345 8.345 0 0 1-11.803-.003l-.002-.002a8.357 8.357 0 0 1 .006-11.814l.788-.788a8.345 8.345 0 0 1 11.806.004zm70.208-70.2-.002-.002a8.344 8.344 0 0 0-11.803-.002l-47.33 47.326a8.358 8.358 0 0 0-.003 11.815l.001.001a8.344 8.344 0 0 0 11.803.002l47.33-47.325a8.36 8.36 0 0 0 .004-11.815z'
			style={{
				fill: '#fff',
			}}
		/>
	</svg>
);
export default SvgSportIconCricket;
