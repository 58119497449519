import React, { useCallback, useContext, useEffect, useRef } from "react";
import ThemeContext from "../context/ThemeContext";
import classNames from "classnames";
import DisableScroll from "./DİsableScroll";
import { useLocation, useNavigate } from "react-router-dom";
import UserControl from "../pages/user/UserControl";
import MobileUserControl from "../pages/user/MobileUserControl";

interface UserPanelProps {}

const UserPanel: React.FC<UserPanelProps> = () => {
  const popupRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const profilOpen = searchParams.get("profile");
  const { mobileDesign } = useContext(ThemeContext);
  const toggleProfile = () => {
    if (profilOpen === "open") {
      searchParams.delete("profile");
      navigate({ search: searchParams.toString() });
    } else {
      searchParams.set("profile", "open");
      navigate({ search: searchParams.toString() });
    }
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        toggleProfile();
      }
    },
    [toggleProfile]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  if (!profilOpen) return null;
  
  return (
    <>
      <DisableScroll />
      {!mobileDesign && (
        <div
          className={classNames([
            "popup-holder-bc",
            "user-profile-container",
            //   { windowed: !fullSize },
            //   { "full-size": fullSize },

            //   { hidden: mobileDesign },
          ])}
        >
          <div className="popup-middleware-bc">
            <div className="popup-inner-bc"
            
            ref={popupRef}
            >
              <i
                className="e-p-close-icon-bc bc-i-close-remove"
                onClick={toggleProfile}
              ></i>

              <UserControl />
            </div>
          </div>
        </div>
      )}

      {mobileDesign && (
        <div
          className="overlay-sliding-wrapper-bc user-profile-container"
          style={{
            transform: "translateY(0px)",
            opacity: 1,
          }}
        >
          <div className="overlay-sliding-w-c-content-slider-bc">
            <MobileUserControl />
          </div>
        </div>
      )}
    </>
  );
};

export default UserPanel;
