import * as React from 'react';
import { SVGProps } from 'react';
const SymbolLogo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<path
			d='M6.89 9.305h.926c1.375-.008 2.7.086 3.938.758a5.448 5.448 0 0 1 1.004.645c.047.034.094.073.144.108.473.371.875.82 1.223 1.309l.043.059c.637.894 1.187 2.136 1.145 3.254h.406c.047 0 .094.003.136.003.282 0 .555-.004.832-.035l-.003-.105c0-.156.027-.309.054-.465l.016-.09c.219-1.125.707-2.191 1.465-3.059l.066-.074c.07-.082.14-.16.215-.238l.047-.05c1.148-1.216 2.808-1.954 4.48-2.016.094 0 .188-.004.285-.004h.102c.211.004.418.015.629.043.203.023.387.027.59 0 1.75-.227 3.484.351 4.867 1.414.316.25.617.52.875.832l.07.078c.926 1.07 1.547 2.531 1.563 3.957v.078c.008.59.004 1.156-.133 1.73l-.02.09a6.529 6.529 0 0 1-1.593 2.989 2.864 2.864 0 0 0-.172.199c-.102.117-.219.215-.34.316l-.086.074a6.678 6.678 0 0 1-4.32 1.59h-.102a5.605 5.605 0 0 1-.629-.043 2.293 2.293 0 0 0-.59 0 6.379 6.379 0 0 1-3.777-.715l-.18-.093a5.97 5.97 0 0 1-1.332-.985c-.05-.047-.097-.097-.148-.144-.773-.746-1.309-1.703-1.648-2.715-.028.172-.04.336-.04.508v.086c-.011.894.07 1.707.352 2.562.137.422.273.871.082 1.297-.246.414-.664.684-1.125.809-.555.093-1.078.004-1.555-.305-.261-.203-.386-.512-.433-.832-.047-.637.152-1.21.344-1.813.046-.144.09-.289.136-.437l.031-.098c.102-.32.157-.617.149-.957v-.082c0-.07-.004-.136-.004-.207l-.023.051c-.352.77-.766 1.434-1.352 2.043l-.05.055c-.43.453-.91.82-1.45 1.133l-.059.035c-.609.351-1.285.578-1.972.722l-.067.016c-.496.094-1 .113-1.504.11l-.175.003h-.368c-.125-.004-.25 0-.37 0-2.157.008-4.012-.46-5.598-2-.094-.094-.176-.191-.262-.293l-.07-.078c-.926-1.07-1.547-2.531-1.563-3.957v-.078c-.008-.59-.004-1.156.133-1.73l.02-.09a6.526 6.526 0 0 1 1.59-2.985c.066-.07.132-.144.195-.215.105-.125.222-.23.351-.335l.047-.04C3.641 9.801 5.2 9.308 6.891 9.305Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#fecd53',
				fillOpacity: 1,
			}}
		/>
		<path
			d='m23.313 9.309.101-.004c.211.004.418.015.629.043.203.023.387.027.59 0 1.75-.227 3.484.351 4.867 1.414.316.25.617.52.875.832l.07.078c.926 1.07 1.547 2.531 1.563 3.957v.078c.008.59.004 1.156-.133 1.73l-.02.09a6.529 6.529 0 0 1-1.593 2.989 2.864 2.864 0 0 0-.172.199c-.102.117-.219.215-.34.316l-.086.074a6.678 6.678 0 0 1-4.32 1.59h-.102a5.605 5.605 0 0 1-.629-.043 2.293 2.293 0 0 0-.59 0 6.379 6.379 0 0 1-3.777-.715l-.18-.093a5.97 5.97 0 0 1-1.332-.985c-.05-.047-.097-.097-.148-.144-.688-.66-1.566-1.82-1.656-2.8.008-.071.008-.071.07-.134.262-.054.46.059.688.188.062.031.125.066.187.101l.086.047a.73.73 0 0 0 .445.07c.14-.054.215-.148.282-.28.148-.364.19-.712.195-1.102v-.086c0-.332-.059-.63-.29-.883-.519-.484-1.245-.41-1.905-.399a2.824 2.824 0 0 1 .05-.601l.016-.09c.219-1.125.707-2.191 1.465-3.059l.066-.074c.07-.082.14-.16.215-.238l.047-.05c1.219-1.294 2.992-2.02 4.762-2.02Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#ac92ea',
				fillOpacity: 1,
			}}
		/>
		<path
			d='M23.34 11.332h.066c1.274.004 2.39.531 3.293 1.414.871.879 1.305 2.074 1.309 3.3v.106c0 .239-.028.461-.07.692l-.02.117c-.14.828-.594 1.621-1.168 2.227l-.047.05c-.773.828-1.871 1.367-3.012 1.426h-.504c-.39 0-.773-.035-1.148-.152l-.082-.024a4.93 4.93 0 0 1-1.3-.644l-.055-.035a4.557 4.557 0 0 1-1.48-1.75c-.028-.059-.06-.114-.09-.172-.184-.34-.177-.688-.16-1.067.007-.277.01-.511-.126-.758a.717.717 0 0 1-.074-.382v-.075c.039-1.199.695-2.296 1.547-3.105a4.932 4.932 0 0 1 1.86-1.008l.077-.023a4.16 4.16 0 0 1 1.184-.137Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#e5e8ec',
				fillOpacity: 1,
			}}
		/>
		<path
			d='M6.941 25.3h.102c.113-.003.223-.003.332 0 .078-.003.156-.003.234-.003h.485c.207.004.414 0 .62 0h1.122c.226 0 .45.039.629.191a.76.76 0 0 1 .223.524.73.73 0 0 1-.282.55.947.947 0 0 1-.511.133H9.77l-.133.004H9.5c-.117 0-.238.004-.355.004-.122.004-.247.004-.368.008-.238 0-.476.004-.714.008a11.475 11.475 0 0 0 .867.937c.199.203.41.387.633.563.03.027.066.054.097.082 1.938 1.664 4.52 2.484 7.059 2.308 1.277-.105 2.59-.422 3.718-1.047l.083-.046c.378-.207.753-.422 1.105-.672l.07-.051c.336-.238.657-.488.961-.762l.051-.043c.223-.199.438-.41.648-.62l.07-.071c.45-.45.864-.957 1.204-1.496.137-.219.273-.399.527-.477.223-.043.41 0 .594.125.148.117.234.227.262.422.035.645-.57 1.23-.961 1.695a2.27 2.27 0 0 0-.13.157c-.14.18-.292.34-.452.496-.063.058-.063.058-.094.125h-.063v.062h-.062l-.023.055a.538.538 0 0 1-.153.172c-.09.074-.176.156-.258.238-.117.121-.25.23-.382.336-.09.074-.176.148-.262.223-.746.62-1.578 1.12-2.453 1.539l-.07.035c-1.29.613-2.774.996-4.204 1.004h-.644c-1.528.008-3-.363-4.395-.977-.023-.011-.047-.02-.074-.031a9.506 9.506 0 0 1-1.645-.938l-.054-.039a15.75 15.75 0 0 1-1.39-1.097c-.04-.035-.079-.07-.118-.102l-.063-.054-.054-.043c-.04-.04-.04-.04-.04-.102l-.093-.031v-.063l-.05-.02c-.102-.058-.177-.144-.255-.234l-.047-.05-.117-.133-.031-.032v.055c0 .18-.004.363-.008.547v.203c0 .098-.004.195-.004.293v.094a.796.796 0 0 1-.207.566l-.066.051-.063.055c-.152.11-.347.094-.527.074a.742.742 0 0 1-.469-.375.649.649 0 0 1-.035-.246v-.195l-.004-.11V28.016c0-.223-.004-.446-.004-.668v-.516c0-.102 0-.203-.004-.309v-.445c0-.25.051-.422.223-.605.234-.153.461-.176.734-.172Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#93793a',
				fillOpacity: 1,
			}}
		/>
		<path
			d='M15.988-.008h.102c.52 0 1.023.02 1.535.102l.086.011c2.203.34 4.219 1.368 5.883 2.833l.066.058c.219.192.422.395.625.598l.102.101c.078.082.16.16.238.243v-.051c.004-.18.004-.36.008-.535 0-.067.004-.133.004-.2 0-.097 0-.191.004-.289v-.09c.004-.207.027-.394.168-.562l.125-.102c.187-.129.378-.136.597-.109.2.074.352.227.442.418a.705.705 0 0 1 .035.238v.305c.004.117.004.234.004.352v1.355c.004.191.004.383.004.57V5.926c0 .23-.043.39-.192.574l-.101.086c-.2.117-.407.11-.625.11h-.438l-.23.003h-.489c-.207-.004-.414 0-.625 0h-1.125c-.273 0-.507-.05-.707-.242-.144-.156-.18-.344-.172-.55.043-.212.141-.348.305-.489.156-.094.32-.113.5-.117h.254c.164-.004.328-.004.492-.008.121 0 .242-.004.364-.004.238-.004.472-.004.71-.008l-.066-.07-.082-.094-.086-.094c-.062-.07-.125-.14-.187-.214a8.18 8.18 0 0 0-.407-.418c-.035-.04-.035-.04-.074-.079a8.674 8.674 0 0 0-.785-.687l-.086-.07c-.453-.36-.941-.676-1.445-.961l-.059-.035c-2.152-1.223-4.8-1.497-7.18-.86-1.316.367-2.535.98-3.605 1.832l-.09.07c-.16.126-.308.254-.457.391-.05.043-.098.09-.148.133a8.66 8.66 0 0 0-.512.488l-.07.067c-.141.14-.278.293-.41.445l-.083.094c-.304.355-.59.734-.843 1.129a.875.875 0 0 1-.41.332c-.235.03-.403.008-.598-.125a.633.633 0 0 1-.266-.453c-.027-.551.461-1.043.793-1.446.043-.05.082-.105.125-.156.106-.133.219-.262.332-.387.04-.043.082-.09.121-.136.157-.184.329-.356.5-.528l.082-.082a6.35 6.35 0 0 1 .446-.414l.187-.176c1.73-1.554 4.008-2.496 6.301-2.746l.082-.011c.344-.032.688-.028 1.027-.028Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#b9a96c',
				fillOpacity: 1,
			}}
		/>
		<path
			d='M6.555 12.992h.11c.917.012 1.897.098 2.605.738.23.239.378.532.386.864l.004.094c.012.332-.082.609-.187.917-.278.758-.278.758-.286 1.551l.012.067c.024.129.055.257.09.386.027.094.055.192.078.286.047.175.094.347.14.523.095.352.138.805-.023 1.14-.171.282-.386.422-.703.505-.465.097-.98.144-1.406-.094-.133-.094-.258-.188-.313-.344-.03-.234.051-.367.172-.566.157-.247.157-.247.172-.528a.597.597 0 0 0-.386.235l-.043.047c-.098.109-.188.222-.278.332-.273.335-.566.613-.98.761-.086.008-.16.012-.246.012l-.067-.004c-.422-.012-.746-.164-1.05-.457-.426-.469-.54-1.086-.516-1.7.035-.589.3-1.132.723-1.538.414-.344.851-.453 1.378-.438.075 0 .145 0 .215.004.114 0 .227.004.336.004.11.004.219.004.328.004l.102.004c.207 0 .355-.027.516-.172.082-.125.066-.195.05-.34-.043-.195-.136-.437-.3-.566-.176-.043-.176-.043-.34.015l-.067.047H6.72l-.024.055c-.039.074-.082.117-.14.176-.075.07-.145.144-.211.226a.8.8 0 0 1-.469.293c-.453.024-.898-.035-1.254-.336-.195-.218-.238-.433-.226-.722.03-.43.293-.758.609-1.032.457-.34.992-.453 1.55-.449Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#3e4245',
				fillOpacity: 1,
			}}
		/>
		<path
			d='M14.11 15.402h.367c.203 0 .398.004.601.02.227.015.45.02.676.02h.191c.22 0 .434-.008.649-.028.636-.05 1.418-.094 1.941.328.235.211.36.485.375.797.016.54.016 1.188-.363 1.61-.102.085-.219.081-.348.078-.125-.012-.226-.067-.34-.122l-.07-.035a4.443 4.443 0 0 1-.352-.195c-.14-.082-.277-.078-.437-.063-.117.211-.098.465-.102.704v.082c-.011.894.07 1.707.352 2.558.137.422.273.871.082 1.297-.246.414-.664.684-1.125.809-.555.093-1.078.004-1.555-.305-.261-.203-.386-.512-.433-.832-.047-.637.152-1.21.344-1.813.046-.144.09-.289.136-.437l.031-.098c.114-.359.157-.699.157-1.074 0-.07 0-.137.004-.207 0-.234-.004-.422-.141-.621-.07-.07-.09-.078-.188-.086-.152.004-.218.066-.332.172l-.078.082a.798.798 0 0 1-.62.223h-.075c-.156-.004-.285-.02-.41-.121-.262-.286-.363-.622-.367-1.008v-.211c0-.047-.004-.098-.004-.145-.008-.379.02-.687.293-.972.304-.278.734-.407 1.14-.407Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#958044',
				fillOpacity: 1,
			}}
		/>
		<path
			d='M29.668 10.895c.25.218.496.44.707.699l.07.078c.926 1.07 1.547 2.531 1.563 3.957v.078c.008.59.004 1.156-.133 1.73l-.02.09a6.529 6.529 0 0 1-1.593 2.989 2.864 2.864 0 0 0-.172.199c-.102.117-.219.215-.34.316l-.086.074a6.688 6.688 0 0 1-4.324 1.59h-.098a7.664 7.664 0 0 1-.898-.07l.031-.063c.063-.015.063-.015.148-.03l.094-.016.102-.016c1.484-.281 2.886-1.156 3.812-2.344l.07-.086c.45-.586.762-1.226 1.024-1.914.012-.031.023-.066.04-.097.562-1.555.374-3.403-.302-4.887a6.258 6.258 0 0 0-1.082-1.61c-.039-.042-.074-.085-.113-.128a4.82 4.82 0 0 0-.7-.653l-.058-.047c-.844-.648-1.89-1.148-2.957-1.28l-.078-.017-.031-.062c1.926-.258 3.832.27 5.324 1.52Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#967adc',
				fillOpacity: 1,
			}}
		/>
		<path
			d='M25.332 13.445c.176.133.3.29.387.492.027.227.031.461-.094.657-.246.308-.55.562-.86.808-.238.2-.238.2-.37.473a.479.479 0 0 0 .136.375l.059.07c.183.211.387.395.598.578.402.344.863.754.914 1.32.011.302-.07.493-.274.716-.316.277-.707.34-1.11.32-.261-.02-.507-.086-.687-.285-.168-.282-.172-.555-.16-.871.004-.067.008-.13.008-.196.004-.058.004-.113.008-.172a.488.488 0 0 0-.137-.293c-.16-.085-.352-.078-.531-.062-.114.04-.168.066-.239.16-.054.113-.05.207-.046.324V18c.003.07.003.145.007.215.02.648.02.648-.183.879-.258.242-.598.234-.938.226-.386-.015-.777-.136-1.05-.422-.16-.207-.23-.43-.22-.691.071-.492.552-.875.923-1.156.453-.324.453-.324.691-.805a.599.599 0 0 0-.129-.457 2.718 2.718 0 0 0-.418-.344c-.832-.601-.832-.601-.93-1.101a1.1 1.1 0 0 1 .25-.844.763.763 0 0 1 .567-.23h.14c.336.011.614.113.856.355.262.313.328.727.422 1.113l.027.11.024.097c.027.09.058.145.12.211.095.02.095.02.188 0 .176-.14.192-.347.219-.562.008-.098.012-.192.016-.29.011-.327.093-.593.328-.835.445-.367 1.02-.32 1.488-.024Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#ac92eb',
				fillOpacity: 1,
			}}
		/>
		<path
			d='M6.656 9.313c.297-.004.59-.004.887-.004.137 0 .273-.004.414-.004 1.324-.008 2.606.113 3.797.758a5.448 5.448 0 0 1 1.004.645c.047.034.094.073.144.108.473.371.875.82 1.223 1.309l.043.059c.648.91 1.184 2.152 1.145 3.285l-.07-.008c-.93-.078-.93-.078-1.837.101l-.094.032-.003-.059c-.075-.754-.23-1.46-.528-2.16l-.031-.07a6.241 6.241 0 0 0-1.207-1.82 2.864 2.864 0 0 1-.172-.2 3.259 3.259 0 0 0-.34-.316l-.047-.04a6.593 6.593 0 0 0-1.515-.96l-.114-.051c-.851-.379-1.77-.55-2.699-.574Zm7.969 8.445c.137.05.191.164.258.281.066.258.015.465-.102.7l-.035.081A6.894 6.894 0 0 1 12 21.812l-.059.036c-.609.351-1.285.578-1.972.722l-.067.016a5.367 5.367 0 0 1-.246.039l-.117.016c-.547.074-1.101.054-1.652.05h-.418l-.813-.003v-.032l.067-.004c1.656-.086 3.199-.59 4.422-1.742.062-.055.125-.11.19-.164.122-.101.22-.219.321-.34l.07-.078c.5-.578.872-1.246 1.153-1.95.039-.097.082-.19.121-.284l.11.062c.14.07.261.082.421.078h.07c.227 0 .383-.07.551-.222l.082-.082c.125-.121.211-.196.391-.172Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#f6bb42',
				fillOpacity: 1,
			}}
		/>
		<path
			d='M12.469 12.656h2c.332.63.332.63.426.871l.035.086c.215.578.402 1.239.383 1.856l-.07-.008c-.93-.078-.93-.078-1.837.101l-.094.032-.003-.059c-.094-.945-.301-1.98-.84-2.785Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#f5ba41',
				fillOpacity: 1,
			}}
		/>
		<path
			d='M12.469 12.656h2c.324.614.324.614.441.918l.035.094c.086.227.086.227.086.332h-2l-.281-.688c-.098-.199-.098-.199-.203-.398-.047-.101-.047-.101-.078-.258Zm2.156 5.102c.137.05.191.164.258.281.066.266.012.473-.11.715l-.035.078c-.082.176-.175.34-.27.512h-2a1.23 1.23 0 0 1 .145-.383c.14-.27.25-.55.356-.836.094 0 .094 0 .176.047a.851.851 0 0 0 .382.062h.075c.226 0 .382-.07.55-.222l.082-.082c.125-.121.211-.196.391-.172Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#e8aa3d',
				fillOpacity: 1,
			}}
		/>
		<path
			d='M7.168 17.117c.05.04.05.04.078.102.004.062.004.062-.055.14-.304.25-.691.422-1.09.407a.377.377 0 0 1-.226-.141.402.402 0 0 1 .098-.27c.355-.293.75-.44 1.195-.238Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#faca53',
				fillOpacity: 1,
			}}
		/>
		<path
			d='M13.313 15.344h2v.125l-.07-.008c-.93-.078-.93-.078-1.837.101l-.094.032Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#e0a53b',
				fillOpacity: 1,
			}}
		/>
		<path
			d='M14.621 17.766c.117.027.16.101.223.203V18h-.625c.066-.195.207-.242.402-.234Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#f4b941',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SymbolLogo;
