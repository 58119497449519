import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomUserAdd = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 512.005 512.005'
		className='svg-icon'
		{...props}>
		<path
			d='M418.628 198.257a162.147 162.147 0 0 0-46.686-14.112 164.323 164.323 0 0 0-23.343-1.676c-8.405 0-16.664.638-24.73 1.869a161.972 161.972 0 0 0-41.421 12.137 163.542 163.542 0 0 0-28.605 16.372 165.639 165.639 0 0 0-11.084 8.663 164.985 164.985 0 0 0-25.53 27.348 164.465 164.465 0 0 0-10.302 15.702 160.438 160.438 0 0 0-3.033 5.504 159.373 159.373 0 0 0-2.91 5.783 161.913 161.913 0 0 0-6.731 16.435 162.329 162.329 0 0 0-2.25 6.908 162.918 162.918 0 0 0-5.133 23.344 164.63 164.63 0 0 0-1.673 23.343c0 9.546.826 18.902 2.407 28.001a162.307 162.307 0 0 0 6.939 26.467 163.73 163.73 0 0 0 4.754 11.911 162.054 162.054 0 0 0 5.641 11.432 163.17 163.17 0 0 0 7.226 12.02 164.784 164.784 0 0 0 8.197 11.323c29.951 37.891 76.302 62.249 128.24 62.249 90.101 0 163.404-73.303 163.404-163.404-.003-65.058-38.221-121.348-93.377-147.619z'
			style={{
				fill: '#95d6a6',
			}}
		/>
		<path
			d='M178.968 2.723c-53.847 0-97.655 43.808-97.655 97.655 0 53.846 43.808 97.652 97.655 97.652s97.654-43.806 97.654-97.652c0-53.847-43.807-97.655-97.654-97.655z'
			style={{
				fill: '#efc27b',
			}}
		/>
		<path
			d='M23.345 447.027h197.013a164.234 164.234 0 0 1-15.421-23.343 162.83 162.83 0 0 1-10.397-23.343c-6.044-17.045-9.347-35.376-9.347-54.468 0-7.923.58-15.716 1.673-23.343a162.497 162.497 0 0 1 5.132-23.343 164.78 164.78 0 0 1 2.25-6.908 161.913 161.913 0 0 1 6.731-16.435c.929-1.95 1.906-3.875 2.91-5.783a163.847 163.847 0 0 1 13.335-21.205 179.527 179.527 0 0 0-38.258-4.136C80.284 244.717 0 325.001 0 423.683c.002 12.892 10.453 23.344 23.345 23.344z'
			style={{
				fill: '#a4e276',
			}}
		/>
		<path
			d='M395.284 322.528h-23.343v-23.343c0-12.892-10.453-23.343-23.343-23.343-12.892 0-23.343 10.452-23.343 23.343v23.343H301.91c-12.892 0-23.343 10.452-23.343 23.343 0 12.893 10.452 23.343 23.343 23.343h23.343v23.343c0 12.893 10.452 23.343 23.343 23.343 12.89 0 23.343-10.45 23.343-23.343v-23.343h23.343c12.89 0 23.343-10.45 23.343-23.343.003-12.891-10.451-23.343-23.341-23.343z'
			style={{
				fill: '#368b4c',
			}}
		/>
		<path
			d='M200.981 275.841a161.913 161.913 0 0 0-6.731 16.435 162.329 162.329 0 0 0-2.25 6.908 162.363 162.363 0 0 0-5.132 23.343 164.63 164.63 0 0 0-1.673 23.343c0 19.092 3.302 37.423 9.347 54.468a162.559 162.559 0 0 0 10.397 23.343 164.178 164.178 0 0 0 15.421 23.343c29.951 37.891 76.302 62.249 128.24 62.249v-93.374c-12.892 0-23.343-10.45-23.343-23.343v-23.343h-23.343c-12.892 0-23.343-10.45-23.343-23.343 0-12.892 10.452-23.343 23.343-23.343h23.343v-23.343c0-12.892 10.452-23.343 23.343-23.343v-93.374c-53.794 0-101.58 26.151-131.371 66.386a163.551 163.551 0 0 0-13.335 21.205 154.72 154.72 0 0 0-2.913 5.783z'
			style={{
				fill: '#64c37d',
			}}
		/>
		<path
			d='M81.313 100.378c0 53.844 43.808 97.652 97.653 97.652V2.723c-53.845 0-97.653 43.808-97.653 97.655z'
			style={{
				fill: '#ecb45c',
			}}
		/>
		<path
			d='M.002 423.683c0 12.892 10.452 23.343 23.343 23.343h155.623v-202.31C80.286 244.717.002 325.001.002 423.683z'
			style={{
				fill: '#64c37d',
			}}
		/>
	</svg>
);
export default SvgCustomUserAdd;
