import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionSyrianArabRepublic = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 900 600'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path d='M0 0h900v600H0z' />
		<path fill='#fff' d='M0 0h900v400H0z' />
		<path fill='#ce1126' d='M0 0h900v200H0z' />
		<path
			fill='#007a3d'
			d='m251.264 375 48.738-150 48.738 150-127.6-92.705h157.72M551.264 375l48.738-150 48.738 150-127.6-92.705h157.72'
		/>
	</svg>
);
export default SvgRegionSyrianArabRepublic;
