import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar29 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar29_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar29_svg__cls-3{fill:#393c54}.avatar29_svg__cls-4{fill:#eaa78f}.avatar29_svg__cls-7{fill:#f7bda4}.avatar29_svg__cls-14,.avatar29_svg__cls-19,.avatar29_svg__cls-9{fill:none;stroke-linecap:round;stroke-linejoin:round}.avatar29_svg__cls-9{stroke:#515570}.avatar29_svg__cls-19,.avatar29_svg__cls-9{stroke-width:2px}.avatar29_svg__cls-9{opacity:.5}.avatar29_svg__cls-15,.avatar29_svg__cls-16{fill:#f85565}.avatar29_svg__cls-13{fill:#f8dc25}.avatar29_svg__cls-14{stroke:#393c54}.avatar29_svg__cls-15{opacity:.2}.avatar29_svg__cls-18{fill:#ff8475}.avatar29_svg__cls-19{stroke:#f8dc25;stroke-dasharray:.1 3}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.3,
				fill: '#fff',
			}}
		/>
		<path
			d='M32.48 115.05h63c1.48-.92 2.93-1.89 4.33-2.93l-3-65.73A32.89 32.89 0 0 0 64 13.5a32.89 32.89 0 0 0-32.89 32.89l-3 65.73c1.44 1.04 2.89 2.01 4.37 2.93Z'
			className='avatar29_svg__cls-3'
		/>
		<circle cx={89} cy={61} r={7} className='avatar29_svg__cls-4' />
		<path
			d='M64 124a59.67 59.67 0 0 0 34.69-11.06l-3.32-9.3A10 10 0 0 0 86 97H42.05a10 10 0 0 0-9.42 6.64l-3.32 9.3A59.67 59.67 0 0 0 64 124Z'
			style={{
				fill: '#4bc190',
			}}
		/>
		<path
			d='M82 98.5a14.91 14.91 0 0 0-.08-1.5H46.08a14.91 14.91 0 0 0-.08 1.5 18 18 0 0 0 36 0Z'
			style={{
				fill: '#356cb6',
				opacity: 0.3,
			}}
		/>
		<path
			d='M77 98.5a14.74 14.74 0 0 0-.09-1.5H51.09a14.74 14.74 0 0 0-.09 1.5 13 13 0 0 0 26 0Z'
			className='avatar29_svg__cls-7'
		/>
		<path
			d='M64 84.75V98.5'
			style={{
				strokeWidth: 14,
				stroke: '#eaa78f',
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
		<circle cx={39} cy={61} r={7} className='avatar29_svg__cls-4' />
		<path
			d='M64 91a25 25 0 0 1-25-25V49.52a25 25 0 1 1 50 0V66a25 25 0 0 1-25 25Z'
			className='avatar29_svg__cls-7'
		/>
		<path
			d='m73 53 5.18-2.36a4.6 4.6 0 0 1 4.67.5L84 52M55 53l-5.18-2.36a4.6 4.6 0 0 0-4.67.5L44 52'
			className='avatar29_svg__cls-9'
		/>
		<path
			d='M35 54.64v-4.5C35 34.36 47.35 21 63.12 20.54A29 29 0 0 1 93 49.53v5.11s-23.06-4.53-28-12.93a1.22 1.22 0 0 0-2 0c-4.74 8.4-28 12.93-28 12.93Z'
			className='avatar29_svg__cls-3'
		/>
		<path
			d='M64 63.75v4.5'
			style={{
				strokeWidth: 4,
				stroke: '#eaa78f',
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
		<path
			d='M69.15 78.94c0 2-2.3 3.16-5.15 3.16s-5.15-1.52-5.15-3.16c0-2.84 2.48-3.94 5.15-3.15 2.58-.87 5.15.31 5.15 3.15Z'
			style={{
				fill: '#f85565',
				opacity: 0.5,
			}}
		/>
		<path
			d='M58 77.87a16.83 16.83 0 0 0 12 0'
			style={{
				stroke: '#f85565',
				strokeWidth: 2,
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
		<path d='M85.43 73.83a4.57 4.57 0 1 1 9.14 0' className='avatar29_svg__cls-13' />
		<circle cx={86.5} cy={76} r={1.17} className='avatar29_svg__cls-13' />
		<circle cx={90} cy={76} r={1.17} className='avatar29_svg__cls-13' />
		<circle cx={93.5} cy={76} r={1.17} className='avatar29_svg__cls-13' />
		<path d='M33.43 73.83a4.57 4.57 0 0 1 9.14 0' className='avatar29_svg__cls-13' />
		<circle cx={34.5} cy={76} r={1.17} className='avatar29_svg__cls-13' />
		<circle cx={38} cy={76} r={1.17} className='avatar29_svg__cls-13' />
		<circle cx={41.5} cy={76} r={1.17} className='avatar29_svg__cls-13' />
		<circle cx={76.25} cy={60.28} r={3} className='avatar29_svg__cls-3' />
		<path d='M70.75 61.37a6.61 6.61 0 0 1 11.5-1.31' className='avatar29_svg__cls-14' />
		<circle cx={51.75} cy={60.28} r={3} className='avatar29_svg__cls-3' />
		<path d='M57.25 61.37a6.61 6.61 0 0 0-11.5-1.31' className='avatar29_svg__cls-14' />
		<ellipse cx={51} cy={67} className='avatar29_svg__cls-15' rx={5.08} ry={3} />
		<ellipse cx={77} cy={67} className='avatar29_svg__cls-15' rx={5.08} ry={3} />
		<circle cx={64} cy={51} r={2.33} className='avatar29_svg__cls-16' />
		<path
			d='M66.8 45.2a2.8 2.8 0 0 1-5.6 0c0-1.55 1.25-5.2 2.8-5.2s2.8 3.65 2.8 5.2Z'
			className='avatar29_svg__cls-13'
		/>
		<circle
			cx={64}
			cy={45}
			r={1.5}
			style={{
				fill: '#f2bc0f',
			}}
		/>
		<path
			d='M86 97h-9l-25.72 25.63a59.88 59.88 0 0 0 47.41-9.69l-3.32-9.3A10 10 0 0 0 86 97Z'
			className='avatar29_svg__cls-16'
		/>
		<path
			d='M86 97h-9l-25.72 25.63a58.81 58.81 0 0 0 10 1.3L88 97.22a9.9 9.9 0 0 0-2-.22ZM90.83 101.52l-4 1.16a.51.51 0 0 0-.22.86l2.84 2.84a.51.51 0 0 0 .85-.22l1.16-4a.51.51 0 0 0-.63-.64ZM86.38 106l-4 1.16a.51.51 0 0 0-.22.85l2.84 2.82a.51.51 0 0 0 .86-.22l1.16-4a.51.51 0 0 0-.64-.61ZM81.92 110.43l-4 1.16a.51.51 0 0 0-.22.86l2.84 2.84a.51.51 0 0 0 .85-.22l1.17-4a.52.52 0 0 0-.64-.64ZM77.47 114.89l-4 1.16a.51.51 0 0 0-.22.85l2.84 2.84a.51.51 0 0 0 .86-.22l1.16-4a.51.51 0 0 0-.64-.63ZM86 118.89l4-1.16a.51.51 0 0 0 .22-.85l-2.8-2.88a.51.51 0 0 0-.85.22l-1.16 4a.5.5 0 0 0 .59.67ZM90.5 114.44l4-1.16a.52.52 0 0 0 .22-.86l-2.84-2.84a.52.52 0 0 0-.86.22l-1.16 4a.52.52 0 0 0 .64.64ZM95.85 105a.5.5 0 0 0-.37.36l-1.16 4a.5.5 0 0 0 .68.64l2.43-.7Z'
			className='avatar29_svg__cls-18'
		/>
		<path d='M64 16v22M68 41s14 5 28 5M60 41s-14 5-28 5' className='avatar29_svg__cls-19' />
	</svg>
);
export default SvgAvatar29;
