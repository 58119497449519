import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar2 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar2_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar2_svg__cls-2{fill:#fff;opacity:.3}.avatar2_svg__cls-3{fill:#393c54}.avatar2_svg__cls-4{fill:#8f5653}.avatar2_svg__cls-5{fill:#a7aece}.avatar2_svg__cls-10,.avatar2_svg__cls-12,.avatar2_svg__cls-14,.avatar2_svg__cls-8{fill:none}.avatar2_svg__cls-10{stroke:#8f5653}.avatar2_svg__cls-10,.avatar2_svg__cls-14,.avatar2_svg__cls-8{stroke-linecap:round}.avatar2_svg__cls-10,.avatar2_svg__cls-12{stroke-miterlimit:10}.avatar2_svg__cls-14,.avatar2_svg__cls-8{stroke:#515570;stroke-linejoin:round}.avatar2_svg__cls-8{stroke-width:2px;opacity:.4}.avatar2_svg__cls-10{stroke-width:5px}.avatar2_svg__cls-12{stroke:#4bc190;stroke-width:3px}.avatar2_svg__cls-14{stroke-width:2.22px}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#f2bc0f',
			}}
		/>
		<circle cx={64} cy={64} r={48} className='avatar2_svg__cls-2' />
		<path
			d='M79.19 23.51V12.42a4 4 0 0 0-2.72-3.8A41.08 41.08 0 0 0 64 6.68a41 41 0 0 0-41 41v6a29.75 29.75 0 0 0 29.66 29.69h24.68A27.66 27.66 0 0 0 105 55.71v-8a40.89 40.89 0 0 0-3.62-16.86 4 4 0 0 0-3.65-2.33H84.17a5 5 0 0 1-4.98-5.01Z'
			className='avatar2_svg__cls-3'
		/>
		<circle cx={91.32} cy={64.43} r={7.93} className='avatar2_svg__cls-4' />
		<path
			d='M64 124a59.77 59.77 0 0 0 39.83-15.14l-2.23-5.22c-1.71-4-6.22-6.64-11.29-6.64H37.69c-5.07 0-9.58 2.66-11.29 6.64l-2.23 5.22A59.77 59.77 0 0 0 64 124Z'
			className='avatar2_svg__cls-3'
		/>
		<path
			d='M77.43 99.73a13.43 13.43 0 0 1-26.86 0c0-5 6-3.25 13.43-3.25s13.43-1.8 13.43 3.25Z'
			className='avatar2_svg__cls-5'
		/>
		<path
			d='M64 85.75V99.5'
			style={{
				strokeWidth: 20,
				strokeMiterlimit: 10,
				strokeLinecap: 'round',
				stroke: '#8f5653',
				fill: 'none',
			}}
		/>
		<circle cx={36.68} cy={64.43} r={7.93} className='avatar2_svg__cls-4' />
		<path
			d='M36 70.27V52.54c0-13.67 9.69-25.69 23.19-27.94A28 28 0 0 1 92 52.2v18.41a28 28 0 0 1-32.81 27.6C45.69 96 36 84 36 70.27Z'
			style={{
				fill: '#b56b63',
			}}
		/>
		<path d='m74.69 53 9.62 1.75' className='avatar2_svg__cls-8' />
		<path
			d='M70.21 79a.79.79 0 0 1 .78.89c-.43 3.47-3.4 4.16-7 4.16s-6.56-.69-7-4.16a.79.79 0 0 1 .78-.89Z'
			style={{
				fill: '#fff',
			}}
		/>
		<path
			d='M75 80.4c-.07-5-6-5.08-11-5.08s-10.92.11-11 5.08v15.35a4 4 0 0 0 2.85 3.84 28.5 28.5 0 0 0 16.3 0A4 4 0 0 0 75 95.75V80.4Zm-5 9h-2.22a1 1 0 0 1-1-.75l-.62-2.49a1 1 0 0 0-1-.76h-2.38a1 1 0 0 0-1 .76l-.62 2.49a1 1 0 0 1-1 .75H58a2 2 0 0 1-2-2v-4.91a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v4.91a2 2 0 0 1-2 2Z'
			className='avatar2_svg__cls-3'
		/>
		<path d='m53.31 53-9.62 1.75' className='avatar2_svg__cls-8' />
		<path d='M64 61v13' className='avatar2_svg__cls-10' />
		<path
			d='m37.61 24-5.54 5.8a5 5 0 0 0 .13 7.07l12.48-13a5 5 0 0 0-7.07.13Z'
			style={{
				fill: '#4bc190',
			}}
		/>
		<path
			d='m33.24 35.79 5.77 5.55M36.71 32.19l5.77 5.54M40.17 28.58l5.77 5.55M43.64 24.98l5.77 5.55'
			className='avatar2_svg__cls-12'
		/>
		<path
			d='M31.23 23.45h0l5.05 4.86h0l-5.05-4.86z'
			style={{
				strokeWidth: 4,
				stroke: '#4bc190',
				strokeLinejoin: 'round',
				strokeLinecap: 'round',
				fill: 'none',
			}}
		/>
		<path d='M67 73h-6' className='avatar2_svg__cls-10' />
		<path
			d='m33 57.41 3 4.87 3.48-11.6A11 11 0 0 1 50 42.86l38.15.1a47.5 47.5 0 0 0 3.74 18.44l.11.27 3-4.26-3.7-15a20 20 0 0 0-8.79-12.18l-4-2.47a27 27 0 0 0-25.95-1.49L48.89 28a20.84 20.84 0 0 0-11 12.91Z'
			className='avatar2_svg__cls-3'
		/>
		<circle cx={77.67} cy={62.28} r={7.5} className='avatar2_svg__cls-5' />
		<path
			d='M77.67 54.78h-.64l-6.83 6.86v.64a7.42 7.42 0 0 0 1.12 3.93L81.6 55.9a7.42 7.42 0 0 0-3.93-1.12ZM74.22 68.93l10.1-10.1a7.66 7.66 0 0 0-1.17-1.66L72.57 67.76a7.37 7.37 0 0 0 1.65 1.17Z'
			className='avatar2_svg__cls-2'
		/>
		<circle cx={77.67} cy={62.28} r={7.5} className='avatar2_svg__cls-14' />
		<circle cx={50.67} cy={62.28} r={7.5} className='avatar2_svg__cls-5' />
		<path
			d='M50.67 54.78h-.64l-6.83 6.86v.64a7.42 7.42 0 0 0 1.12 3.93L54.6 55.9a7.42 7.42 0 0 0-3.93-1.12ZM47.22 68.93l10.1-10.1a7.66 7.66 0 0 0-1.17-1.66L45.57 67.76a7.37 7.37 0 0 0 1.65 1.17Z'
			className='avatar2_svg__cls-2'
		/>
		<circle cx={50.67} cy={62.28} r={7.5} className='avatar2_svg__cls-14' />
		<path d='M58.17 62.28h12' className='avatar2_svg__cls-14' />
	</svg>
);
export default SvgAvatar2;
