import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar36 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar36_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar36_svg__cls-13{fill:#515570}.avatar36_svg__cls-3{fill:#fba875}.avatar36_svg__cls-4{fill:#ffbb94}.avatar36_svg__cls-5{fill:#393c54}.avatar36_svg__cls-12,.avatar36_svg__cls-14,.avatar36_svg__cls-7{fill:none;stroke-linecap:round}.avatar36_svg__cls-12,.avatar36_svg__cls-7{stroke:#fba875;stroke-miterlimit:10}.avatar36_svg__cls-7{stroke:#4bc190}.avatar36_svg__cls-14,.avatar36_svg__cls-7{stroke-width:3px}.avatar36_svg__cls-9{fill:#f85565}.avatar36_svg__cls-11{fill:#fff}.avatar36_svg__cls-12{stroke-width:4px}.avatar36_svg__cls-14{stroke:#fff;stroke-linejoin:round}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#a7aece',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.2,
				fill: '#515570',
			}}
		/>
		<circle cx={91.32} cy={62.43} r={7.93} className='avatar36_svg__cls-3' />
		<path
			d='M64 124a59.77 59.77 0 0 0 39.83-15.14l-2.23-5.22c-1.71-4-6.22-6.64-11.29-6.64H37.69c-5.07 0-9.58 2.66-11.29 6.64l-2.23 5.22A59.77 59.77 0 0 0 64 124Z'
			className='avatar36_svg__cls-4'
		/>
		<path
			d='M36 117.05a59.88 59.88 0 0 0 56 0v-20a15.65 15.65 0 0 0-1.69-.1H37.69a15.65 15.65 0 0 0-1.69.1Z'
			className='avatar36_svg__cls-5'
		/>
		<path d='M49 97v1a15 15 0 0 0 30 0v-1Z' className='avatar36_svg__cls-4' />
		<path
			d='M64 84.75V98.5'
			style={{
				strokeWidth: 20,
				strokeMiterlimit: 10,
				stroke: '#fba875',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={36.68} cy={62.43} r={7.93} className='avatar36_svg__cls-3' />
		<path
			d='M64 95.37a28.31 28.31 0 0 1-28.32-28.32V51.43a28.32 28.32 0 1 1 56.64 0v15.62A28.31 28.31 0 0 1 64 95.37Z'
			className='avatar36_svg__cls-4'
		/>
		<circle cx={76.67} cy={61.28} r={3} className='avatar36_svg__cls-5' />
		<circle cx={49.67} cy={61.28} r={3} className='avatar36_svg__cls-5' />
		<path d='M74.39 56 84 54.75M53 56l-9.61-1.25' className='avatar36_svg__cls-7' />
		<path
			d='M92.28 50a28.32 28.32 0 0 0-55.39-6.73A33.89 33.89 0 0 0 35.68 52v.65l.07.08h.15a7 7 0 0 0 6.1-3.56l3.16-5.59a10.84 10.84 0 0 1 11.49-5.32 33.77 33.77 0 0 0 6.41.74h1.86a33.77 33.77 0 0 0 6.41-.74 10.84 10.84 0 0 1 11.49 5.32L86 49.17a7 7 0 0 0 6.11 3.58h.22v-1.32c-.01-.43-.02-.97-.05-1.43Z'
			style={{
				opacity: 0.15,
				fill: '#393c54',
			}}
		/>
		<path
			d='M73 15H55l3.18 19.07A5.9 5.9 0 0 0 64 39a5.9 5.9 0 0 0 5.82-4.93Z'
			className='avatar36_svg__cls-9'
		/>
		<path
			d='m70 15-3.18 19.07a5.88 5.88 0 0 1-4.32 4.71A5.72 5.72 0 0 0 64 39a5.9 5.9 0 0 0 5.82-4.93L73 15Z'
			style={{
				fill: '#356cb6',
				opacity: 0.3,
			}}
		/>
		<circle cx={74.5} cy={28.5} r={1.5} className='avatar36_svg__cls-9' />
		<circle cx={72} cy={32} r={1} className='avatar36_svg__cls-9' />
		<circle cx={54} cy={28} r={1} className='avatar36_svg__cls-9' />
		<path
			d='M71.55 78a1 1 0 0 1 1 1.06 8.55 8.55 0 0 1-17 0 1 1 0 0 1 1-1.06Z'
			className='avatar36_svg__cls-5'
		/>
		<path
			d='M64 86.66a8.51 8.51 0 0 0 6.29-2.79 9.06 9.06 0 0 0-12.58 0A8.51 8.51 0 0 0 64 86.66Z'
			className='avatar36_svg__cls-9'
		/>
		<path d='M58 78a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2Z' className='avatar36_svg__cls-11' />
		<path d='M64 65v6M67 72h-6' className='avatar36_svg__cls-12' />
		<path
			d='M84 97.5c0 .83-.9 1.5-2 1.5s-2-.67-2-1.5.9-4.5 2-4.5 2 3.67 2 4.5ZM90 97.5c0 .83-.9 1.5-2 1.5s-2-.67-2-1.5.9-4.5 2-4.5 2 3.67 2 4.5ZM44 97.5c0 .83.9 1.5 2 1.5s2-.67 2-1.5-.9-4.5-2-4.5-2 3.67-2 4.5ZM38 97.5c0 .83.9 1.5 2 1.5s2-.67 2-1.5-.9-4.5-2-4.5-2 3.67-2 4.5ZM84 105.5c0 .83-.9 1.5-2 1.5s-2-.67-2-1.5.9-4.5 2-4.5 2 3.67 2 4.5ZM90 105.5c0 .83-.9 1.5-2 1.5s-2-.67-2-1.5.9-4.5 2-4.5 2 3.67 2 4.5ZM44 105.5c0 .83.9 1.5 2 1.5s2-.67 2-1.5-.9-4.5-2-4.5-2 3.67-2 4.5ZM38 105.5c0 .83.9 1.5 2 1.5s2-.67 2-1.5-.9-4.5-2-4.5-2 3.67-2 4.5ZM84 114.5c0 .83-.9 1.5-2 1.5s-2-.67-2-1.5.9-4.5 2-4.5 2 3.67 2 4.5ZM90 114.5c0 .83-.9 1.5-2 1.5s-2-.67-2-1.5.9-4.5 2-4.5 2 3.67 2 4.5ZM44 114.5c0 .83.9 1.5 2 1.5s2-.67 2-1.5-.9-4.5-2-4.5-2 3.67-2 4.5ZM38 114.5c0 .83.9 1.5 2 1.5s2-.67 2-1.5-.9-4.5-2-4.5-2 3.67-2 4.5Z'
			className='avatar36_svg__cls-13'
		/>
		<path d='M32.5 58.5 29 55M94.5 57.5l3-3' className='avatar36_svg__cls-14' />
		<circle cx={96.5} cy={61.5} r={1} className='avatar36_svg__cls-11' />
	</svg>
);
export default SvgAvatar36;
