import * as React from 'react';
import { SVGProps } from 'react';
const SvgTau = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#7b346e' />
			<path
				fill='#fff'
				fillRule='nonzero'
				d='m8.094 13.678 2.11 2.11H6zm2.11 2.55-2.11 2.094L6 16.228zm2.852-2.853-2.11 2.094v-4.203zm-2.55 2.094-2.093-2.094 2.094-2.11zm2.854-2.413-2.094-2.094h4.203zm0-4.643 2.109 2.109h-4.203zm4.962-.304-2.11 2.094V6zm-2.55 2.094L13.678 8.11 15.772 6zm-2.716 8.437-2.11 2.094v-4.203zm-4.643 0 2.094-2.109v4.203zm10.212-5.584-2.094-2.094h4.203zm-2.094-2.534 2.094-2.11 2.11 2.11zM15.47 21.493l-2.11 2.094-2.093-2.094zm-2.11-2.55 2.11 2.11h-4.203zm10.228-5.568-2.109 2.094v-4.203zm-4.643 0 2.094-2.11v4.204zm-5.266 10.53 2.094-2.108V26zm2.534-2.108 2.11 2.109L16.212 26zm2.413 1.79-2.094-2.094h4.203zm0-4.643 2.11 2.11H16.53zm2.853-2.413 2.11 2.11-2.11 2.093zm-2.534 2.11 2.094-2.11v4.203zm4.947-.32-2.094-2.093H26zm0-4.643L26 15.788h-4.203z'
			/>
		</g>
	</svg>
);
export default SvgTau;
