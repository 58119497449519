// sportWebSocketSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
interface SportWebSocketState {
  sportConnected: boolean;
  sessionLoad: boolean;
  socketError: boolean;
  responseData: Record<any, any>;
}

const initialState: SportWebSocketState = {
  sportConnected: false,
  sessionLoad: false,
  socketError: false,
  responseData: {},
};

const callbacks = new Map<string, (data: any) => void>();

export const sportWebSocketSlice = createSlice({
  name: 'sportWebSocket',
  initialState,
  reducers: {
    setSportConnected: (state, action: PayloadAction<boolean>) => {
      state.sportConnected = action.payload;
    },
    setSessionLoad: (state, action: PayloadAction<boolean>) => {
      state.sessionLoad = action.payload;
    },
    setSocketError: (state, action: PayloadAction<boolean>) => {
      state.socketError = action.payload;
    },
    receiveMessage: (state, action: PayloadAction<{rid: string, data: any}>) => {
      const { rid, data } = action.payload;


      if (data && data.subid) {
        state.responseData[data.subid] = _.merge({}, state.responseData[data.subid], data.data);
      } else if (rid === "0" && data) {
        Object.keys(data).forEach(key => {
          state.responseData[key] = _.merge({}, state.responseData[key], data[key]);
        });
      }
      const callback = callbacks.get(rid);
    
      if (callback) {
        callback(data);
        callbacks.delete(rid);
      }
    },
    setCallback: (state, action: PayloadAction<{ rid: string, callback: (data: any) => void }>) => {
      const { rid, callback } = action.payload;
      callbacks.set(rid, callback);
    },
    clearCallback: (state, action: PayloadAction<string>) => {
      callbacks.delete(action.payload);
    },
    deleteResponseData: (state, action: PayloadAction<string>) => {
      delete state.responseData[action.payload];
    },
    clearAllResponseData: (state) => {
      state.responseData = {};
    }
  },
});

export const {
  setSportConnected,
  setSessionLoad,
  receiveMessage,
  setSocketError,
  setCallback,
  clearCallback,
  deleteResponseData,
  clearAllResponseData,
} = sportWebSocketSlice.actions;

export default sportWebSocketSlice.reducer;
