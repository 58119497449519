import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar12 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar12_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar12_svg__cls-2,.avatar12_svg__cls-9{fill:#fff}.avatar12_svg__cls-2{opacity:.3}.avatar12_svg__cls-3{fill:#8f5653}.avatar12_svg__cls-4{fill:#4bc190}.avatar12_svg__cls-8{fill:#393c54}.avatar12_svg__cls-10{fill:none;stroke:#515570;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;opacity:.2}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#a7aece',
			}}
		/>
		<circle cx={64} cy={64} r={48} className='avatar12_svg__cls-2' />
		<path
			d='m110.08 80.5-.88-2.69c-2.89-7.83-17.3-46.91-17.78-48l-.5-1.29h-.12a30 30 0 0 0-53.6 0h-.13l-.52 1.36C35.51 32.44 19.07 77.1 19 77.42a47.49 47.49 0 0 0-1.54 24.39 60 60 0 0 0 93.16 0 49.66 49.66 0 0 0-.5-21.31Z'
			className='avatar12_svg__cls-3'
		/>
		<path
			d='M23.45 108.21a60 60 0 0 0 81.1 0 43.38 43.38 0 0 0 .84-29.09l-.14-.46c-4.87-13.2-16.91-45.81-17.49-47.22l-.05-.1a26 26 0 0 0-47.42 0v.08c-.57 1.38-16.82 45.28-17.5 47.26a43.4 43.4 0 0 0 .7 29.52Z'
			className='avatar12_svg__cls-4'
		/>
		<path
			d='M99.57 80.59C93.23 63.4 83 35.81 82.23 33.78a20 20 0 0 0-36.46 0c-1.14 2.94-16.67 44.89-17.33 46.81a37.42 37.42 0 0 0 7 36.2 60.05 60.05 0 0 0 57.06 0 37.41 37.41 0 0 0 7.16-35.89Z'
			style={{
				opacity: 0.5,
				fill: '#f8dc25',
			}}
		/>
		<path
			d='M99.57 80.59C93.23 63.4 83 35.81 82.23 33.78a20 20 0 0 0-36.46 0c-1.14 2.94-16.67 44.89-17.33 46.81a37.42 37.42 0 0 0 7 36.2 60.05 60.05 0 0 0 57.06 0 37.41 37.41 0 0 0 7.16-35.89Z'
			className='avatar12_svg__cls-2'
		/>
		<path
			d='M64 122a29.49 29.49 0 0 1-28-38.87c.67-1.93 15.5-42 17.11-46.24a12 12 0 0 1 21.72 0c1.07 2.79 9.4 25.32 17.13 46.26v.12A29.49 29.49 0 0 1 64 122Z'
			style={{
				opacity: 0.5,
				fill: '#fff',
			}}
		/>
		<circle
			cx={64}
			cy={92.5}
			r={20.5}
			style={{
				fill: '#515570',
			}}
		/>
		<circle cx={64} cy={92.5} r={11} className='avatar12_svg__cls-8' />
		<circle cx={79.66} cy={56.5} r={7.49} className='avatar12_svg__cls-9' />
		<path
			d='M48.34 68a11.46 11.46 0 0 1-9.1-4.48M79.84 68a11.52 11.52 0 0 0 9.85-5.56'
			className='avatar12_svg__cls-10'
		/>
		<circle cx={48.34} cy={56.5} r={7.49} className='avatar12_svg__cls-9' />
		<path
			d='M64 113a20.49 20.49 0 0 0 20.13-16.7A22.3 22.3 0 0 0 72 93.17c-.54 0-1.08 0-1.63.11A2.07 2.07 0 0 0 68.63 95a1.2 1.2 0 0 1-1.81.67 3.12 3.12 0 0 0-2.95-.67 22.58 22.58 0 0 0-12.49 13.66A20.36 20.36 0 0 0 64 113Z'
			style={{
				fill: '#f85565',
			}}
		/>
		<circle cx={79.66} cy={56.5} r={4.09} className='avatar12_svg__cls-8' />
		<circle cx={48.34} cy={56.5} r={4.09} className='avatar12_svg__cls-8' />
		<path
			d='M68.29 95.5a1.16 1.16 0 0 1-1.47.13 4.87 4.87 0 0 0-.59-.36c.43 3.56 1.66 9.24 5.21 13.08a1 1 0 0 0 .73.32 1 1 0 0 0 .68-.27 1 1 0 0 0 .06-1.41c-3-3.24-4.17-8.18-4.62-11.49Z'
			style={{
				opacity: 0.2,
				fill: '#fff',
			}}
		/>
		<path
			d='M61.68 59.51 60 64.28A2 2 0 0 0 61.93 67h4.19a2 2 0 0 0 1.93-2.72l-1.67-4.77a2.49 2.49 0 0 0-4.7 0Z'
			className='avatar12_svg__cls-4'
		/>
		<path
			d='M61.68 59.51 60 64.28A2 2 0 0 0 61.93 67h4.19a2 2 0 0 0 1.93-2.72l-1.67-4.77a2.49 2.49 0 0 0-4.7 0Z'
			className='avatar12_svg__cls-3'
		/>
	</svg>
);
export default SvgAvatar12;
