import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar16 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar16_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar16_svg__cls-3{fill:#bc5b57}.avatar16_svg__cls-4{fill:#fba875}.avatar16_svg__cls-11,.avatar16_svg__cls-19,.avatar16_svg__cls-9{fill:none;stroke-linecap:round}.avatar16_svg__cls-9{stroke:#bc5b57;stroke-width:4px}.avatar16_svg__cls-11,.avatar16_svg__cls-19,.avatar16_svg__cls-9{stroke-linejoin:round}.avatar16_svg__cls-11,.avatar16_svg__cls-19{stroke:#515570}.avatar16_svg__cls-11{stroke-width:1.89px;opacity:.4}.avatar16_svg__cls-15{fill:#f85565;opacity:.1}.avatar16_svg__cls-16{fill:#393c54}.avatar16_svg__cls-19{stroke-width:2px}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#00adfe',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.3,
				fill: '#fff',
			}}
		/>
		<path
			d='M32.86 66.94v-14.8C32.86 34.94 46.8 28 64 28s31.14 6.94 31.14 24.14v14.8Z'
			className='avatar16_svg__cls-3'
		/>
		<circle cx={89.84} cy={70.87} r={7.5} className='avatar16_svg__cls-4' />
		<path
			d='M64 124a59.75 59.75 0 0 0 37.53-13.2l-2-5.53C98 101.5 93.68 99 88.89 99H39.11c-4.79 0-9.07 2.51-10.68 6.28l-2 5.53A59.75 59.75 0 0 0 64 124Z'
			style={{
				fill: '#4bc190',
			}}
		/>
		<path
			d='M74.26 100.86c0 5.66-4.6 9.25-10.26 9.25s-10.26-3.59-10.26-9.25 4.6-3.42 10.26-3.42 10.26-2.25 10.26 3.42Z'
			style={{
				fill: '#7cdbcd',
			}}
		/>
		<path
			d='M64 88.88v10'
			style={{
				strokeWidth: 16,
				stroke: '#fba875',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M93.85 29.41 87.91 31a1 1 0 0 1-1.26-.95l-.13-8.17a1 1 0 0 0-1.52-.57l-4.57 2.58a1 1 0 0 1-1.43-.58l-1.66-5.47a1 1 0 0 0-1.63-.46l-4.47 4a1 1 0 0 1-1.59-.35l-2.52-5.83a1 1 0 0 0-1.75-.2l-3.89 5.84a1 1 0 0 1-1.68 0L56.73 16a1 1 0 0 0-1.64-.07L49.72 23a1 1 0 0 1-1.32.26l-6-3.65a1 1 0 0 0-1.4.66l.28 9.36a1 1 0 0 1-1.32 1l-5.66-1.94a1 1 0 0 0-1.43.9v23.63h62.27V30.36a1 1 0 0 0-1.29-.95Z'
			className='avatar16_svg__cls-3'
		/>
		<circle cx={38.16} cy={70.87} r={7.5} className='avatar16_svg__cls-4' />
		<path
			d='M64 98a26.78 26.78 0 0 1-26.79-26.81v-9.62a26.79 26.79 0 0 1 53.58 0v9.62A26.78 26.78 0 0 1 64 98Z'
			style={{
				fill: '#ffbb94',
			}}
		/>
		<path d='M37.51 64.68V45.76M90.49 65.68V45.81' className='avatar16_svg__cls-9' />
		<path
			d='M63.15 30.12C47.92 30.57 36 43.47 36 58.7a2 2 0 0 0 2 2 2.72 2.72 0 0 0 2.6-2.1 9.28 9.28 0 0 1 9.13-7.6h28.54a9.28 9.28 0 0 1 9.13 7.6 2.72 2.72 0 0 0 2.6 2.06 2 2 0 0 0 2-2v-.6a28 28 0 0 0-28.85-27.94Z'
			style={{
				fill: '#db7a72',
			}}
		/>
		<path d='m74.12 63.84 9.09-.24M53.88 63.84l-9.09-.24' className='avatar16_svg__cls-11' />
		<path
			d='M64 72v6'
			style={{
				strokeWidth: '3.74px',
				stroke: '#fba875',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M66.36 79.03h-4.72'
			style={{
				strokeWidth: '3.78px',
				stroke: '#fba875',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M64.95 78.08h1.89'
			style={{
				stroke: '#f85565',
				strokeWidth: '1.89px',
				strokeLinejoin: 'round',
				fill: 'none',
				strokeLinecap: 'round',
				opacity: 0.3,
			}}
		/>
		<ellipse cx={51.15} cy={78.72} className='avatar16_svg__cls-15' rx={4.34} ry={2.82} />
		<ellipse cx={76.85} cy={78.72} className='avatar16_svg__cls-15' rx={4.34} ry={2.82} />
		<path
			d='M71.55 84a1 1 0 0 1 .94 1.07 8.56 8.56 0 0 1-17 0 1 1 0 0 1 .96-1.07Z'
			className='avatar16_svg__cls-16'
		/>
		<path
			d='M59 90.91a8.52 8.52 0 0 0 10.08 0C68 89.16 66.17 89 64 89s-4 .16-5 1.91Z'
			style={{
				fill: '#f85565',
			}}
		/>
		<path
			d='M69 86H59a1.84 1.84 0 0 1-1.73-2h13.5A1.84 1.84 0 0 1 69 86Z'
			style={{
				fill: '#fff',
			}}
		/>
		<path d='M60 84h2v3h-2z' className='avatar16_svg__cls-16' />
		<path
			d='M71.67 72.28a3 3 0 0 1 6 0M56 72.28a3 3 0 0 0-6 0'
			className='avatar16_svg__cls-19'
		/>
	</svg>
);
export default SvgAvatar16;
