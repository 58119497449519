import React, { useState, useContext, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import classNames from 'classnames';
import ThemeContext from '../context/ThemeContext';
import { useTranslation } from 'react-i18next';
import './Filter.scss';
import SelectInput from './SelectInput';

interface FilterItem {
	label: string;
	name: string;
	value: any;
	type: 'date' | 'string' | 'number' | 'select';
	options?: any[];
	placeholder?: string;
}

interface FilterProps {
	items: FilterItem[];
	onSubmit: (values: any) => void;
	onChange?: (name: string, value: any) => void;
}

const FilterComponent: React.FC<FilterProps> = ({ items, onSubmit, onChange }) => {
	const { mobileDesign } = useContext(ThemeContext);
	const { t } = useTranslation();
	const [filterOpen, setFilterOpen] = useState(false);
	const [initialValues, setInitialValues] = useState<any>({});

	useEffect(() => {
		const initial: any = {};
		items.forEach((item) => {
			initial[item.name] = item.value !== undefined ? item.value : ''; // Boş değer atama
		});
		setInitialValues(initial);
	}, [items]);

	const renderField = (item: FilterItem, setFieldValue: any) => {
		switch (item.type) {
			case 'date':
				return (
					<Field name={item.name}>
						{({ field }: any) => (
							<div
								className={`form-control-bc default has-icon ${
									field.touched && field.error ? 'invalid' : ''
								} ${field.focused ? 'focused' : ''} ${
									field.value ? 'filled' : ''
								}`}>
								<label className='form-control-label-bc inputs'>
									<input
										className={classNames(['form-control-input-bc'])}
										type='date'
										{...field}
										placeholder={item.placeholder}
										value={field.value || ''}
										onChange={(e) => {
											setFieldValue(item.name, e.target.value);
											if (onChange) {
												onChange(item.name, e.target.value);
											}
										}}
									/>
									<span className='form-control-title-bc ellipsis'>
										{item.label}
									</span>
								</label>
							</div>
						)}
					</Field>
				);
			case 'string':
			case 'number':
				return (
					<Field name={item.name}>
						{({ field }: any) => (
							<input
								type={item.type}
								{...field}
								placeholder={item.placeholder}
								value={field.value || ''}
							/>
						)}
					</Field>
				);
			case 'select':
				return (
					<Field name={item.name}>
						{({ field }: any) => (
							<SelectInput
								name={item.name}
								value={field.value}
								options={item.options ? item.options: []}
								onChange={(value) => {
									setFieldValue(item.name, value);
									if (onChange) {
										onChange(item.name, value);
									}
								}}
								label={item.label}
								id={item.name}
							/>

							// <select
							// 	{...field}
							// 	value={field.value || ''}
							// 	onChange={(e) => {
							// 		setFieldValue(item.name, e.target.value);
							// 		if (onChange) {
							// 			onChange(item.name, e.target.value);
							// 		}
							// 	}}>
							// 	{item.options?.map((option) => (
							// 		<option key={option.value} value={option.value}>
							// 			{option.label}
							// 		</option>
							// 	))}
							// </select>
						)}
					</Field>
				);
			default:
				return null;
		}
	};

	return (
		<div className='results-filter-bc'>
			<Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
				{({ setFieldValue }) => (
					<Form>
						<div className='componentFilterWrapper-bc'>
							{mobileDesign && (
								<div
									className={classNames([
										'componentFilterLabel-bc',
										{ active: filterOpen },
									])}
									onClick={() => setFilterOpen(!filterOpen)}>
									<i className='componentFilterLabel-filter-i-bc bc-i-filter'></i>
									<div className='componentFilterLabel-filter-bc'>
										<p className='ellipsis'>{t('Filter')}</p>
									</div>
									<i
										className={classNames([
											'componentFilterChevron-bc',
											{ 'bc-i-small-arrow-down': !filterOpen },
											{ 'bc-i-small-arrow-up': filterOpen },
										])}></i>
								</div>
							)}
							<div className='componentFilterBody-bc'>
								{mobileDesign ? (
									<div className='componentFilterElsWrapper-bc'>
										{filterOpen && (
											<div className='filter-form-w-bc'>
												{items.map((item) => (
													<div
														className='u-i-p-control-item-holder-bc'
														key={item.name}>
														{renderField(item, setFieldValue)}
													</div>
												))}
												<div className='u-i-p-c-filter-footer-bc'>
													<button
														className='btn a-color'
														type='submit'
														title='show'>
														<span>{t('Show')}</span>
													</button>
												</div>
											</div>
										)}
									</div>
								) : (
									<div className='componentFilterElsWrapper-bc'>
										<div className='filter-form-w-bc'>
											<div className='componentFilterBody-content'>
												{items.map((item) => (
													<div
														className='u-i-p-control-item-holder-bc'
														key={item.name}>
														{renderField(item, setFieldValue)}
													</div>
												))}
											</div>
											<div className='u-i-p-c-filter-footer-bc'>
												<button
													className='btn a-color'
													type='submit'
													title='show'>
													<span>{t('Show')}</span>
												</button>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default FilterComponent;
