import * as React from 'react';
import { SVGProps } from 'react';
const SvgAppc = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#fd875e' />
			<path
				fill='#fefefe'
				d='m13.66 17.386 2.386-7.112 2.43 7.112zm10.112 4.497-1.973-5.309h1.178a.849.849 0 0 0 .85-.836c0-.21-.086-.413-.237-.561s-.358-.233-.573-.233h-1.84l-.295-.826h2.095a.839.839 0 0 0 .846-.807c.001-.222-.088-.434-.248-.59s-.376-.244-.602-.243h-2.72L18.78 8.51a3.576 3.576 0 0 0-1.057-1.461 2.499 2.499 0 0 0-1.663-.548 2.57 2.57 0 0 0-1.671.548 3.609 3.609 0 0 0-1.06 1.46l-1.543 4.004H9.082a.81.81 0 0 0-.817.797c0 .458.379.829.847.829h2.053l-.316.804h-1.84a.809.809 0 0 0-.818.8c0 .454.374.821.836.823h1.181l-2.006 5.316c-.123.3-.191.618-.202.941.02.453.222.88.56 1.19a2.04 2.04 0 0 0 1.34.483 1.8 1.8 0 0 0 1.84-1.305l.776-2.269h7.144l.777 2.323a1.813 1.813 0 0 0 1.84 1.24c.31.007.615-.07.884-.22.233-.17.44-.37.614-.598a1.6 1.6 0 0 0 .225-.844 5.446 5.446 0 0 0-.228-.927z'
			/>
		</g>
	</svg>
);
export default SvgAppc;
