import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionDenmark = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 370 280'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#c60c30' d='M0 0h370v280H0z' />
		<path fill='#fff' d='M120 0h40v280h-40z' />
		<path fill='#fff' d='M0 120h370v40H0z' />
	</svg>
);
export default SvgRegionDenmark;
