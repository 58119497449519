import React from "react";
import useCombine from "../../hooks/useCombine";
import CopyButton from "../../component/CopyButton";

const AvatarComponent: React.FC = () => {
  const { userProfile } = useCombine();

  return (
    <div className="u-i-p-p-u-i-edit-button-bc">
      <span className="u-i-p-p-u-i-avatar-holder-bc">
        {userProfile?.name?.charAt(0)}
        {userProfile?.surname?.charAt(0)}
      </span>
      <p className="u-i-p-p-u-i-identifiers-bc">
        <span className="u-i-p-p-u-i-d-username-bc ellipsis">
          {userProfile?.username || userProfile?.email}
        </span>
        <span className="u-i-p-p-u-i-d-user-id-bc ellipsis">
          {userProfile?.customerId}
          <CopyButton copyText={userProfile?.customerId} />
        </span>
      </p>
    </div>
  );
};

export default AvatarComponent;
