import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionEswatini = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		viewBox='0 0 900 600'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#3e5eb9' d='M0 0h900v600H0z' />
		<path fill='#ffd900' d='M0 112.5h900v375H0z' />
		<path fill='#b10c0c' d='M0 150h900v300H0z' />
		<g fill='#ffd900' stroke='currentColor' strokeWidth={1.008} transform='scale(1.5)'>
			<g id='regionEswatini_svg__a'>
				<path fill='#fff' d='m492.85 154.28-24.285 8.571 24.285 8.571 24.285-8.571z' />
				<rect width={357.13} height={5.714} x={122.85} y={160} rx={3.106} ry={2.857} />
				<path d='M471.44 165.71c1.427 0 2.859-1.429 2.859-2.857 0-1.428-1.431-2.857-2.858-2.857m-2.861 5.714c1.427 0 2.859-1.429 2.859-2.857 0-1.428-1.431-2.857-2.858-2.857m-2.861 5.714c1.427 0 2.859-1.429 2.859-2.857 0-1.428-1.431-2.857-2.858-2.857' />
			</g>
			<use
				xlinkHref='#regionEswatini_svg__a'
				width='100%'
				height='100%'
				x={-23.27}
				y={17.15}
			/>
			<rect
				width={485.7}
				height={8.571}
				x={59.812}
				y={195.72}
				strokeWidth={1.08}
				rx={4.224}
				ry={4.286}
			/>
		</g>
		<path
			fill='#fff'
			stroke='currentColor'
			strokeWidth={1.512}
			d='M664.275 300c-42.856 42.853-100.002 128.566-214.29 128.566-100.002 0-171.42-85.712-214.29-128.566 42.857-42.861 114.282-128.574 214.29-128.574 114.282 0 171.42 85.713 214.29 128.574z'
		/>
		<path d='M435.03 216.15c11.402 12.823 1.881 17.994 13.95 18.835 12.727.935 5.512 13.551 16.218 13.99 7.485.327-.747 30.226 6.457 40.093 7.34 10.286 13.487 3.014 13.616 10.444.127 7.686-19.976 6.911-20.328 30.585-.59 13.644-16.965 14.517-17.747 23.297-.972 8.257 32.261 12.892 31.817 20.284-.456 7.37-35.883 6.216-37.425 14.621-.79 7.55 48.669 13.737 52.647 35.57-7.38 2.413-28.379 4.685-44.249 4.697-99.985.072-171.42-85.72-214.275-128.574 42.857-42.86 114.282-128.574 214.29-128.574 0 0-29.683 27.123-14.956 44.723z' />
		<g stroke='#fff' transform='scale(1.5)'>
			<g id='regionEswatini_svg__d' strokeWidth={6.927}>
				<g id='regionEswatini_svg__c'>
					<path
						id='regionEswatini_svg__b'
						d='M213.933 192.377V169.52m13.85 0v22.857m13.86-22.857v22.857'
					/>
					<use xlinkHref='#regionEswatini_svg__b' width='100%' height='100%' y={38.1} />
				</g>
				<use xlinkHref='#regionEswatini_svg__c' width='100%' height='100%' x={41.56} />
			</g>
		</g>
		<use
			xlinkHref='#regionEswatini_svg__d'
			width='100%'
			height='100%'
			x={104.76}
			stroke='currentColor'
			transform='scale(1.5)'
		/>
		<g fill='#a70000'>
			<path
				fill='#3d5da7'
				stroke='currentColor'
				strokeWidth={1.282}
				d='M767.025 294.9c-6.492 11.244 4.982-3.74 32.978 15.084 5.188 3.497 9.721 12.734 9.721 19.139-1.317-.881-2.295-2.841-3.745-4.115-2.031 2.035 1.939 11.366 2.52 15.775-4.377-2.449-3.956-3.609-5.41-6.619.29 3.639-.751 13.66.99 17.52-3.45-.986-3.22-3.762-5.021-4.32 1.225 4.368-1.068 9.122-.46 14.033-2.015-1.995-4.163-3.558-5.1-4.364-.157 2.338-3.877 8.484-3.961 10.695-1.71-1.033-2.175-2.644-2.46-3.729-2.126 2.671-9.681 12.537-10.165 15.546-5.822-5.142-20.735-17.663-22.676-24.309-1.716 3.772-4.2 5.046-9.11 7.8-1.939-10.533-9.194-22.372-5.314-31.401-2.75 1.63-5.013 3.261-7.761 5.267 2.604-15.255 15.88-33.143 34.979-42.002z'
			/>
			<path d='M742.815 350.58c2.695-5.388 5.178-7.096 6.92-10.566 3.076-6.098 3.515-10.949 6.196-10.238 2.68.711 2.677 3.209-.795 9.204-3.473 5.996-5.124 7.277-12.321 11.6zm18.225 12.84c-.347-4.103.843-5.816.627-8.457-.37-4.649-2.271-7.839.201-8.066 2.472-.226 3.627 1.36 3.634 6.044.008 4.685-.717 5.915-4.46 10.48zm13.83 8.04c-1.08-5.727-.271-8.267-.963-11.957-1.203-6.492-3.583-10.765-1.293-11.359 2.29-.594 3.674 1.506 4.54 8.09.868 6.583.41 8.392-2.284 15.226zm14.385-40.125c-3.378-2.564-5.349-2.736-7.527-4.383-3.837-2.89-5.778-5.985-7.013-4.449-1.234 1.536-.358 3.216 3.663 5.883 4.02 2.667 5.382 2.877 10.878 2.949zm-2.385 19.005c-1.78-3.697-3.654-4.635-4.806-7.014-2.035-4.18-2.038-7.797-4.246-6.87-2.208.927-2.385 2.82-.024 6.867 2.36 4.046 3.597 4.779 9.076 7.017z' />
			<path
				fill='#3d5da7'
				stroke='currentColor'
				strokeWidth={1.6}
				d='M121.174 305.73c-.363-3.274.338-3.666-1.677-6.135 2.933 1.227 3.044 4.488 6.61 2.174 1.309-.678 1.88-.7.289-4.337 3.718.173 15.894 4.348 17.844 4.444 5.12.236 14.466-5.378 20.953 1.525 6.227 6.294 4.148 12.826 4.148 21.543-2.502-1.199-1.296-1.79-3.951-4.907 1.99 7.619-.106 21.356-.106 29.436-1.022-1.948-.872-1.103-1.871-3.12-2.655 7.375-5.837 8.893-5.837 17.61-.958-3.418-.033-2.696-1.099-4.494-2.58 5.6-19.89 10.385-13.22 16.029-6.02-3.53-8.66-3.159-11.39-6.334-1.177.759-2.111 2.196-3.205 4.165-10.585-4.87-6.8-15.375-15.093-22.392-1.435 2.942-.781 2.516-2.332 7.304-1.679-6.651-2.138-10.877-4.026-15.767-1.629 2.71-1.484 1.671-4.442 5.768-1.224-8.102-3.216-10.014-2.428-15.375-3.144 2.218-1.086 1.32-4.229 4.05 2.978-20.76 15.837-36.101 25.065-31.188z'
			/>
			<path d='M108.151 339.615c2.728-6.684 5.186-8.783 6.949-13.086 3.112-7.563 3.619-13.61 6.233-12.687 2.615.922 2.573 4.037-.925 11.467-3.498 7.433-5.135 9.008-12.257 14.304zm10.326 16.725c1.094-5.992 2.819-8.175 3.528-12.034 1.259-6.786.557-11.84 2.987-11.573 2.43.266 2.979 2.828 1.362 9.578-1.617 6.751-2.733 8.349-7.878 14.029zm12.351 11.625c.308-5.81 1.67-8.077 1.874-11.82.369-6.582-.898-11.293 1.419-11.32 2.317-.027 3.143 2.34 2.433 8.929-.71 6.59-1.567 8.234-5.726 14.211zm26.522-37.995c-5.071-5.052-8.137-5.996-11.409-9.245-5.763-5.706-8.547-11.064-10.599-9.165-2.052 1.9-.817 4.746 5.25 10.176 6.068 5.43 8.178 6.222 16.758 8.234zm-3.09 19.53c-3.303-5.073-5.727-6.387-7.86-9.651-3.76-5.736-5.054-10.665-7.185-9.444-2.133 1.221-1.656 3.796 2.42 9.357 4.075 5.559 5.714 6.582 12.625 9.74z' />
			<path
				fill='#3d5da7'
				stroke='currentColor'
				strokeWidth={1.239}
				d='M380.7 194.25c-5.937 11.482 10.395-3.819 35.997 15.404 4.745 3.571 11.546 17.68 11.546 24.22-5.451-1.16-15.104-6.54-15.104-6.54s11.328 11.346 11.328 24.426c-4.002-2.502-6.004-2.127-7.335-5.199 0 4.234 3.56 6.933 3.56 13.473a52.938 52.938 0 0 0-7.78-5.19c3.776 6.54-6.88 19.227-1.547 23.462-9.327-1.349-18.87-7.308-22.646-13.848-2.001 1.348-2.218 3.466-2.296 5.723.294.245-14.198-10.756-12.866-14.982-1.944 2.727-2.217 4.226-2.661 7.3-5.323-5.25-10.203-10.5-11.979-17.288-2.365 3.073-2.514 3.073-4.88 6.147-1.774-10.757-1.774-10.373 1.775-19.592-2.514 1.665-4.584 3.33-7.098 5.38 2.38-15.578 14.523-33.845 31.988-42.892z'
			/>
			<path d='M366.06 250.35c.95-7.912 3.018-10.893 3.636-15.988 1.103-8.961-.206-15.512 2.97-15.345 3.176.165 4.09 3.47 2.52 12.414-1.569 8.941-2.893 11.113-9.126 18.92zm16.575 14.49c-.643-6.675.59-9.517.18-13.818-.705-7.566-3.042-12.691-.274-13.162 2.767-.471 4.167 2.067 4.465 9.706.299 7.64-.441 9.675-4.372 17.274zm25.875 11.82c-2.814-5.777-5.232-7.499-7.048-11.217-3.209-6.534-3.88-11.87-6.354-10.904-2.475.968-2.339 3.728 1.236 10.124 3.574 6.396 5.19 7.701 12.166 11.997zm8.955-51.735c-7.167-3.773-10.848-3.82-15.466-6.245-8.133-4.25-12.867-9.052-14.547-6.409-1.682 2.643.562 5.271 8.947 9.135s10.96 4.047 21.067 3.519zm2.625 21.615c-5.218-4.341-8.304-4.98-11.67-7.773-5.93-4.9-8.88-9.714-10.861-7.762-1.98 1.951-.657 4.528 5.566 9.145 6.224 4.617 8.352 5.19 16.965 6.39z' />
		</g>
	</svg>
);
export default SvgRegionEswatini;
