import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionMacao = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		viewBox='0 0 660 440'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#00785E' d='M0 0h660v440H0z' />
		<path fill='#FBD116' d='m330 82.958-14.208 43.735 37.203-27.027h-45.99l37.203 27.027z' />
		<g id='regionMacao_svg__a'>
			<path
				fill='#FFF'
				d='M330 303.99h-93.937c-1.184-1.2-2.35-2.423-3.488-3.667H330a1.962 1.962 0 0 1 1.306 1.736A1.968 1.968 0 0 1 330 303.99zm0-28.725c.545-1.114 1.607-3.694 1.125-6.921a11.356 11.356 0 0 0-1.125-3.492c-5.271 4.988-15.175 12.981-29.785 17.401a74.286 74.286 0 0 1-21.548 3.173h-57.872a132.363 132.363 0 0 0 5.364 7.333h55.945c18.242.001 34.954-6.58 47.896-17.494zm-100.461-22.637a29.595 29.595 0 0 1-8.887 1.926c13.629 15.415 33.545 25.144 55.723 25.144 21.066 0 40.091-8.775 53.625-22.867a404.194 404.194 0 0 0 4.167-53.998A404.18 404.18 0 0 0 330 140.801c-6.045 5.419-17.371 17.128-22.71 35.079a74.291 74.291 0 0 0-3.072 21.203c0 15.291 4.623 29.506 12.547 41.329-9.731-12.57-15.526-28.338-15.526-45.454 0-11.369 2.558-22.144 7.126-31.783a29.782 29.782 0 0 1-6.904-11.902c-6.121 10.804-9.618 23.284-9.618 36.581 0 16.539 5.409 31.819 14.552 44.172-15.923-16.693-38.377-27.099-63.25-27.099-1.336 0-2.667.034-3.989.093a29.924 29.924 0 0 1 6.188 8.157c24.742.038 47.078 10.377 62.964 26.949-15.171-12.672-34.698-20.303-55.995-20.303-11.902 0-23.25 2.382-33.594 6.696 10.629 28.085 37.78 48.075 69.574 48.075 2.059 0 4.1-.093 6.116-.258a74.7 74.7 0 0 1-13.22 1.174c-20.061 0-38.269-7.956-51.65-20.882zM330 334.01h-48.684c15.067 5.988 31.492 9.281 48.684 9.281a10.003 10.003 0 0 0 1.146-4.542A10.016 10.016 0 0 0 330 334.01zm0-22.458h-85.83a132.307 132.307 0 0 0 7.169 5.729H330c.197-.201 1.167-1.236 1.167-2.865s-.97-2.663-1.167-2.864zm0 11.458h-70.304a131.655 131.655 0 0 0 13.242 7.333H330a6.927 6.927 0 0 0 1.063-3.844A6.902 6.902 0 0 0 330 323.01z'
			/>
			<path
				fill='#FBD116'
				d='m237.639 150.67-13.442 28.839 27.822-15.433-31.575-3.874 23.268 21.699zM260.663 112.27l9.303 30.429 10.362-30.092-26.068 18.254 31.819.552z'
			/>
		</g>
		<use xlinkHref='#regionMacao_svg__a' transform='matrix(-1 0 0 1 660 0)' />
	</svg>
);
export default SvgRegionMacao;
