import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar40 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar40_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar40_svg__cls-3{fill:#fbc0aa}.avatar40_svg__cls-11,.avatar40_svg__cls-4{fill:#ffd8c9}.avatar40_svg__cls-10,.avatar40_svg__cls-12,.avatar40_svg__cls-15{fill:none}.avatar40_svg__cls-10,.avatar40_svg__cls-11,.avatar40_svg__cls-12,.avatar40_svg__cls-15{stroke-linecap:round}.avatar40_svg__cls-10,.avatar40_svg__cls-11,.avatar40_svg__cls-15{stroke-linejoin:round}.avatar40_svg__cls-6{fill:#8f5653}.avatar40_svg__cls-7{fill:#fff}.avatar40_svg__cls-9{fill:#393c54}.avatar40_svg__cls-10,.avatar40_svg__cls-11{stroke:#8f5653}.avatar40_svg__cls-10,.avatar40_svg__cls-12{stroke-width:5px}.avatar40_svg__cls-10{opacity:.2}.avatar40_svg__cls-11{stroke-width:3px}.avatar40_svg__cls-12{stroke:#ff8475;stroke-miterlimit:10;opacity:.4}.avatar40_svg__cls-15{stroke:#515570}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#515570',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				fill: '#6b718e',
			}}
		/>
		<circle cx={94.32} cy={60.43} r={7.93} className='avatar40_svg__cls-3' />
		<path
			d='M64 124.1a59.77 59.77 0 0 0 39.83-15.1l-2.23-5.32c-1.71-4-6.22-6.64-11.29-6.64H37.69c-5.07 0-9.58 2.66-11.29 6.64L24.17 109A59.77 59.77 0 0 0 64 124.1Z'
			className='avatar40_svg__cls-4'
		/>
		<path
			d='M64 84.75V98.5'
			style={{
				strokeLinejoin: 'round',
				strokeLinecap: 'round',
				stroke: '#fbc0aa',
				strokeWidth: 20,
				fill: 'none',
			}}
		/>
		<circle cx={32.68} cy={60.43} r={7.93} className='avatar40_svg__cls-3' />
		<path
			d='M94.1 59h-2.15V36h2.15a3.88 3.88 0 0 1 3.85 3.87v15.25A3.88 3.88 0 0 1 94.1 59ZM33.88 36H36v23h-2.12A3.88 3.88 0 0 1 30 55.13V39.88A3.88 3.88 0 0 1 33.88 36Z'
			className='avatar40_svg__cls-6'
		/>
		<path
			d='M64 94.49a30.67 30.67 0 0 1-30.66-30.67V46.66a30.66 30.66 0 0 1 61.32 0v17.16A30.67 30.67 0 0 1 64 94.49Z'
			className='avatar40_svg__cls-4'
		/>
		<path
			d='M70.21 76a.79.79 0 0 1 .78.89 7 7 0 0 1-14 0 .79.79 0 0 1 .78-.89Z'
			className='avatar40_svg__cls-7'
		/>
		<path
			d='M55.57 77a1.06 1.06 0 0 1-1.06-1.19 9.56 9.56 0 0 1 19 0A1.06 1.06 0 0 1 72.43 77h-6.31L64 76l-2.25 1Z'
			className='avatar40_svg__cls-6'
		/>
		<path
			d='M70.17 66a6.17 6.17 0 0 1-12.34 0c0-3.41 2.76-8.7 6.17-8.7s6.17 5.3 6.17 8.7Z'
			style={{
				fill: '#ff8475',
			}}
		/>
		<circle cx={77.5} cy={59.28} r={2.5} className='avatar40_svg__cls-9' />
		<circle cx={50.5} cy={59.28} r={2.5} className='avatar40_svg__cls-9' />
		<path d='M75.5 50.5h8M44.5 49.5l8 2' className='avatar40_svg__cls-10' />
		<path
			d='M73 24.28a27.24 27.24 0 0 0-18 0M73 29.56a23.28 23.28 0 0 0-18 0'
			className='avatar40_svg__cls-11'
		/>
		<path d='M44.5 66.5h7M76.5 66.5h7' className='avatar40_svg__cls-12' />
		<path
			d='M63.5 85.5h5'
			style={{
				strokeWidth: 2,
				opacity: 0.1,
				stroke: '#515570',
				strokeLinejoin: 'round',
				strokeLinecap: 'round',
				fill: 'none',
			}}
		/>
		<path
			d='M33.34 45.86v-4.11H32V52.5h.34V58a1 1 0 0 0 1 1H35a1.64 1.64 0 0 0 1.64-1.65v-8.21A1.64 1.64 0 0 0 35 47.5a1.64 1.64 0 0 1-1.66-1.64ZM94.64 45.86v-4.11H96V52.5h-.34V58a1 1 0 0 1-1 1H93a1.65 1.65 0 0 1-1.64-1.65v-8.21A1.65 1.65 0 0 1 93 47.5a1.65 1.65 0 0 0 1.64-1.64Z'
			className='avatar40_svg__cls-6'
		/>
		<g
			style={{
				opacity: 0.3,
			}}>
			<path d='M64 120.21a3 3 0 1 1 5.33-2.75' className='avatar40_svg__cls-15' />
			<path
				d='M64.58 116.8a3 3 0 0 1 1.71-5.75M70.79 113a3 3 0 1 1 5.55-2.26M76.49 116.82a3 3 0 1 1 1.87-5.7M54.73 115.77a3 3 0 0 1 4.65 3.79'
				className='avatar40_svg__cls-15'
			/>
		</g>
		<path
			d='M86 98a22 22 0 0 1-44 0v-1h-3v21.74a60 60 0 0 0 50 0V97h-3v1Z'
			className='avatar40_svg__cls-7'
		/>
	</svg>
);
export default SvgAvatar40;
