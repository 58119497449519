import * as React from 'react';
import { SVGProps } from 'react';
const SvgWicc = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#5783CB' />
			<g fill='#FFF'>
				<path d='M15.24 4h1.24a11.584 11.584 0 0 1 7.28 3.04c.34.22.34.76-.06.9-.34.08-.6-.22-.86-.4a10.74 10.74 0 0 0-14.8 1.72 10.5 10.5 0 0 0 11.6 16.54 10.8 10.8 0 0 0 7.04-8c.176-.729.29-1.472.34-2.22a.422.422 0 0 1 .84.08l-.04 1.24a11.66 11.66 0 0 1-3.44 7.66 11.92 11.92 0 0 1-7.8 3.44H15.4A12.02 12.02 0 0 1 4 16.56v-1.12A12.06 12.06 0 0 1 15.24 4z' />
				<path d='M25 10.22c.8-.88 2.46-.4 2.64.78.26 1.06-.76 1.96-1.8 1.88-1.38 1.4-2.48 3.06-3.42 4.8-.38.52.1 1.2-.2 1.74-.36 1.06-1.92 1.28-2.6.4-.5-.56-.36-1.34-.1-1.96a8.084 8.084 0 0 0-1.5-2.14l-.6.02a19.28 19.28 0 0 0-3.22 4.86c.14.54.24 1.18-.14 1.66-.5.82-1.82.9-2.44.14-.46-.52-.4-1.26-.16-1.88a18.8 18.8 0 0 0-2.92-5.2c-.78-.04-1.6-.5-1.7-1.32-.26-1.12 1.02-2.2 2.08-1.7.94.34 1.2 1.54.74 2.36a19.14 19.14 0 0 0 3.14 5.28A16.954 16.954 0 0 0 16.26 15c-.16-.54-.26-1.16.1-1.66.54-.88 1.98-.9 2.54-.02.42.54.28 1.26.04 1.84a8.4 8.4 0 0 0 1.46 2.16l.6-.04c1.46-1.54 2.8-3.24 3.7-5.18-.1-.64-.22-1.4.32-1.88H25z' />
			</g>
		</g>
	</svg>
);
export default SvgWicc;
