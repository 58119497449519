import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar13 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar13_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar13_svg__cls-3{fill:#fbc0aa}.avatar13_svg__cls-5{fill:#f2bc0f}.avatar13_svg__cls-12,.avatar13_svg__cls-16{fill:none;stroke-linecap:round;stroke-linejoin:round}.avatar13_svg__cls-12{stroke:#fbc0aa}.avatar13_svg__cls-14,.avatar13_svg__cls-19{fill:#515570}.avatar13_svg__cls-17{fill:#f85565}.avatar13_svg__cls-12{stroke-width:4px}.avatar13_svg__cls-16{stroke-width:2px}.avatar13_svg__cls-14{opacity:.1}.avatar13_svg__cls-15{fill:#393c54}.avatar13_svg__cls-16{stroke:#515570}.avatar13_svg__cls-18{fill:#ff8475}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#4bc190',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.3,
				fill: '#356cb6',
			}}
		/>
		<circle cx={91.32} cy={60.43} r={7.93} className='avatar13_svg__cls-3' />
		<path
			d='M101.6 103.14c-1.71-4-6.22-6.64-11.29-6.64h-1.44C87.57 84.8 76.93 82 64 82s-23.57 2.8-24.87 14.5h-1.44c-5.07 0-9.58 2.66-11.29 6.64l-2.4 5.68a60 60 0 0 0 80 0Z'
			style={{
				fill: '#f8dc25',
			}}
		/>
		<path d='M64 124.1h1V112a1 1 0 0 0-2 0v12.08Z' className='avatar13_svg__cls-5' />
		<path
			d='M76 100a12 12 0 1 0-20.93 8H55l7.51 8.35a2 2 0 0 0 3 0L73 108a11.89 11.89 0 0 0 3-8Z'
			style={{
				fill: '#fff',
			}}
		/>
		<path
			d='M64 84.75V98.5'
			style={{
				strokeWidth: 20,
				stroke: '#fbc0aa',
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
		<circle cx={36.68} cy={60.43} r={7.93} className='avatar13_svg__cls-3' />
		<path
			d='M64 96c-11.53 0-24.12-9.93-27.6-29.8-3.27-18.66-1-23.74-1-23.74 0-16.41 57.32-16.41 57.32 0 0 0 1.22 7.16-1 23.74C89.19 84 75.55 96 64 96Z'
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<path
			d='M73.17 88.08H54.83c0-1.6-1.08-8.44.17-11.22 2.71-6 9-8.69 9-8.69s6.65 2.53 9.32 8.69c1.09 2.49-.15 9.76-.15 11.22Z'
			style={{
				opacity: 0.2,
				fill: '#515570',
			}}
		/>
		<path
			d='M64 81c5 0 7-3 7-3H57s2 3 7 3Z'
			style={{
				opacity: 0.4,
				fill: '#f85565',
			}}
		/>
		<path
			d='M75 78c-.07-5-6-5.08-11-5.08S53.08 73 53 78v16.16a28.48 28.48 0 0 0 22 0V78Zm-5 9h-2.22a1 1 0 0 1-1-.76l-.62-2.48a1 1 0 0 0-1-.76h-2.38a1 1 0 0 0-1 .76l-.62 2.48a1 1 0 0 1-1 .76H58a2 2 0 0 1-2-2v-4.92a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2V85a2 2 0 0 1-2 2Z'
			style={{
				fill: '#7f3838',
			}}
		/>
		<path d='M64 59.75v12.5M66.5 71h-5' className='avatar13_svg__cls-12' />
		<path
			d='M66 70h1'
			style={{
				strokeWidth: 2,
				stroke: '#f85565',
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				opacity: 0.3,
			}}
		/>
		<path
			d='M91.61 66.29A9.71 9.71 0 0 0 83 76v9.83a38.42 38.42 0 0 0 8.61-19.54ZM36.42 66.31C37.89 74.62 41 81.17 44.88 86V76a9.72 9.72 0 0 0-8.46-9.69Z'
			className='avatar13_svg__cls-14'
		/>
		<path
			d='M70.41 56h12.18A2.41 2.41 0 0 1 85 58.41V62a6 6 0 0 1-6 6h-4.43A6.57 6.57 0 0 1 68 61.43v-3A2.41 2.41 0 0 1 70.41 56ZM53.43 68H49a6 6 0 0 1-6-6v-3.56A2.41 2.41 0 0 1 45.41 56h12.18A2.41 2.41 0 0 1 60 58.41v3A6.57 6.57 0 0 1 53.43 68Z'
			className='avatar13_svg__cls-15'
		/>
		<path
			d='M70.41 56h12.18A2.41 2.41 0 0 1 85 58.41V62a6 6 0 0 1-6 6h-4.43A6.57 6.57 0 0 1 68 61.43v-3A2.41 2.41 0 0 1 70.41 56ZM53.43 68H49a6 6 0 0 1-6-6v-3.56A2.41 2.41 0 0 1 45.41 56h12.18A2.41 2.41 0 0 1 60 58.41v3A6.57 6.57 0 0 1 53.43 68Z'
			className='avatar13_svg__cls-16'
		/>
		<path
			d='M60 62a4 4 0 0 1 8 0M85 58.41l7.32-5.91M43 58.41l-7.32-5.84'
			className='avatar13_svg__cls-16'
		/>
		<rect width={60} height={15} x={34} y={31} className='avatar13_svg__cls-15' rx={2} />
		<path d='M76 25.33H51.61l-11 7H87l-11-7z' className='avatar13_svg__cls-15' />
		<path d='M34 32h60' className='avatar13_svg__cls-16' />
		<path
			d='m25.198 36.356 16.667-16.667 3.33 3.33a2.36 2.36 0 0 1-.057 3.296L31.817 39.637a2.36 2.36 0 0 1-3.338 0l-3.281-3.281Z'
			className='avatar13_svg__cls-15'
		/>
		<rect
			width={28.28}
			height={3.54}
			x={18.94}
			y={25.82}
			className='avatar13_svg__cls-17'
			rx={1.77}
			transform='rotate(-45 33.083 27.577)'
		/>
		<path
			d='m30.77 20.73-8.94 8.94a2.35 2.35 0 0 0 0 3.33l1.67 1.67L40.17 18l-.41-.41A2.36 2.36 0 0 0 37 17.2l-5.66 3.14a2.53 2.53 0 0 0-.57.39Z'
			className='avatar13_svg__cls-18'
		/>
		<path
			d='M96.179 39.635 82.857 26.313a2.36 2.36 0 0 1 0-3.337l3.281-3.281 16.667 16.666-3.303 3.303a2.36 2.36 0 0 1-3.323-.029Z'
			className='avatar13_svg__cls-15'
		/>
		<rect
			width={28.28}
			height={3.54}
			x={80.77}
			y={25.82}
			className='avatar13_svg__cls-17'
			rx={1.77}
			transform='rotate(-135 94.917 27.581)'
		/>
		<path
			d='m97.23 20.73 8.94 8.94a2.35 2.35 0 0 1 0 3.33l-1.67 1.67L87.83 18l.41-.41a2.36 2.36 0 0 1 2.81-.39l5.66 3.14a2.53 2.53 0 0 1 .52.39Z'
			className='avatar13_svg__cls-18'
		/>
		<path d='M71 26H57l-2-7h18l-2 7z' className='avatar13_svg__cls-15' />
		<path d='M53 19h22' className='avatar13_svg__cls-16' />
		<path
			d='m95.38 51.81-10-9.24 4.07-4.41 10 9.23a3 3 0 0 1 .18 4.24 3 3 0 0 1-4.25.18ZM32.38 51.81l10-9.24-4.07-4.41-10 9.23a3 3 0 0 0-.17 4.24 3 3 0 0 0 4.24.18Z'
			className='avatar13_svg__cls-19'
		/>
		<g
			style={{
				opacity: 0.1,
			}}>
			<path
				d='m48 48 11 2 12-1 11-1M47 51l13 3 9-1h13M56 43l5 15M65 43l-1 15'
				className='avatar13_svg__cls-16'
			/>
		</g>
		<path
			d='M37.69 96.5a14.18 14.18 0 0 0-3.08.34S36 106 44 110a19.32 19.32 0 0 1-4.87-13.5ZM84 110c8-4 9.39-13.16 9.39-13.16a14.18 14.18 0 0 0-3.08-.34h-1.44A19.32 19.32 0 0 1 84 110Z'
			className='avatar13_svg__cls-5'
		/>
	</svg>
);
export default SvgAvatar13;
