import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar41 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar41_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar41_svg__cls-1{fill:#ffd8c9}.avatar41_svg__cls-5{fill:#fff}.avatar41_svg__cls-3{fill:#fbc0aa}.avatar41_svg__cls-9{fill:none;stroke-linecap:round;stroke-linejoin:round}.avatar41_svg__cls-6{fill:#00adfe}.avatar41_svg__cls-12,.avatar41_svg__cls-7{fill:#f85565}.avatar41_svg__cls-7{opacity:.2}.avatar41_svg__cls-9{stroke:#515570;stroke-width:2px}.avatar41_svg__cls-10{fill:#f2bc0f}'
				}
			</style>
		</defs>
		<circle cx={64} cy={64} r={60} className='avatar41_svg__cls-1' />
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.3,
				fill: '#fff',
			}}
		/>
		<circle cx={85.98} cy={63.31} r={6.43} className='avatar41_svg__cls-3' />
		<path
			d='M63 76.75V90.5'
			style={{
				strokeWidth: 12,
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				stroke: '#fbc0aa',
				fill: 'none',
			}}
		/>
		<circle cx={64} cy={21.78} r={9.25} className='avatar41_svg__cls-5' />
		<circle cx={81.14} cy={26.61} r={9.25} className='avatar41_svg__cls-5' />
		<circle cx={93.86} cy={38.97} r={9.25} className='avatar41_svg__cls-5' />
		<circle cx={46.86} cy={26.61} r={9.25} className='avatar41_svg__cls-5' />
		<circle cx={34.14} cy={38.97} r={9.25} className='avatar41_svg__cls-5' />
		<circle cx={40.02} cy={63.31} r={6.43} className='avatar41_svg__cls-3' />
		<path
			d='M64 21.78a37 37 0 0 0-37 37v52.47a59.93 59.93 0 0 0 74 0V58.76a37 37 0 0 0-37-36.98Z'
			className='avatar41_svg__cls-6'
		/>
		<path
			d='M64 25.78a33 33 0 0 0-33 33v48.11a32.85 32.85 0 0 0 1 7.86 59.94 59.94 0 0 0 64 0 32.85 32.85 0 0 0 1-7.86V58.76a33 33 0 0 0-33-32.98Z'
			className='avatar41_svg__cls-5'
		/>
		<circle cx={86.98} cy={63.31} r={6.15} className='avatar41_svg__cls-3' />
		<circle cx={41.02} cy={63.31} r={6.15} className='avatar41_svg__cls-3' />
		<path
			d='M64 87.84a23 23 0 0 1-23-23v-6.08a23 23 0 0 1 46 0v6.11a23 23 0 0 1-23 22.97Z'
			className='avatar41_svg__cls-1'
		/>
		<circle cx={53.01} cy={71.24} r={11.99} className='avatar41_svg__cls-1' />
		<ellipse cx={51.01} cy={73.63} className='avatar41_svg__cls-7' rx={3.99} ry={2.38} />
		<circle cx={74.99} cy={71.24} r={11.99} className='avatar41_svg__cls-1' />
		<ellipse cx={76.99} cy={73.63} className='avatar41_svg__cls-7' rx={3.99} ry={2.38} />
		<path
			d='M64 72v1'
			style={{
				strokeWidth: 4,
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				stroke: '#fbc0aa',
				fill: 'none',
			}}
		/>
		<path d='M72 66a3 3 0 0 0 6 0M50 66a3 3 0 0 0 6 0' className='avatar41_svg__cls-9' />
		<circle cx={60.5} cy={54} r={4.31} className='avatar41_svg__cls-10' />
		<circle cx={67.5} cy={56} r={4.31} className='avatar41_svg__cls-10' />
		<circle cx={71.5} cy={52} r={4.31} className='avatar41_svg__cls-10' />
		<path
			d='M85.42 45.81a175.44 175.44 0 0 0-42.84 0 2 2 0 0 0-1.76 1.77l-.53 4.82a2 2 0 0 0 2.3 2.19 144.94 144.94 0 0 1 42.82 0 2 2 0 0 0 2.3-2.19l-.53-4.82a2 2 0 0 0-1.76-1.77Z'
			style={{
				fill: '#ff8475',
			}}
		/>
		<path
			d='M85.34 45.8a25 25 0 0 0-42.68 0 176.14 176.14 0 0 1 42.68 0ZM72 81a4 4 0 0 0-6-3.46 3.86 3.86 0 0 1-4 0 4 4 0 1 0-4 6.91 11.92 11.92 0 0 0 12 0A4 4 0 0 0 72 81Z'
			className='avatar41_svg__cls-12'
		/>
		<circle
			cx={64}
			cy={83}
			r={4}
			style={{
				fill: '#f8dc25',
			}}
		/>
		<ellipse
			cx={64}
			cy={88}
			rx={5}
			ry={4.83}
			style={{
				stroke: '#00adfe',
				strokeMiterlimit: 10,
				strokeWidth: 2,
				fill: 'none',
			}}
		/>
		<path
			d='M31 75v39.1a59.95 59.95 0 0 0 66 0v-1l-.6-.14C49.3 103 33.41 79.11 31 75Z'
			className='avatar41_svg__cls-6'
		/>
		<path
			d='m31.6 113-.6.14v1a59.95 59.95 0 0 0 66 0L97.2 75c-2.41 4.11-18.5 28-65.6 38Z'
			className='avatar41_svg__cls-6'
		/>
		<path
			d='M31 75v39.1a59.95 59.95 0 0 0 66 0v-1l-.6-.14C49.3 103 33.41 79.11 31 75Z'
			style={{
				opacity: 0.2,
				fill: '#fff',
			}}
		/>
		<path
			d='M61.58 123.94C40.89 113.27 32.68 100.86 31 98v16.1a59.64 59.64 0 0 0 30.58 9.84ZM66.42 123.94A59.64 59.64 0 0 0 97 114.1V98c-1.68 2.86-9.89 15.27-30.58 25.94Z'
			className='avatar41_svg__cls-6'
		/>
		<path
			d='M66.42 123.94A59.64 59.64 0 0 0 97 114.1V98c-1.68 2.86-9.89 15.27-30.58 25.94Z'
			style={{
				opacity: 0.4,
				fill: '#fff',
			}}
		/>
		<path
			d='M61.58 123.94C40.89 113.27 32.68 100.86 31 98v16.1a59.64 59.64 0 0 0 30.58 9.84Z'
			className='avatar41_svg__cls-6'
		/>
		<path
			d='M61.58 123.94C40.89 113.27 32.68 100.86 31 98v16.1a59.64 59.64 0 0 0 30.58 9.84Z'
			style={{
				opacity: 0.6,
				fill: '#fff',
			}}
		/>
	</svg>
);
export default SvgAvatar41;
