import * as React from 'react';
import { SVGProps } from 'react';
const SvgStox = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#7324F0' />
			<path
				fill='#FFF'
				d='M27.675 13.462V13a3.004 3.004 0 0 0-2.99 2.784A2.996 2.996 0 0 0 21.705 13v.462A2.538 2.538 0 0 1 24.243 16a2.531 2.531 0 0 1-2.538 2.538V19a3.004 3.004 0 0 0 2.99-2.784A2.997 2.997 0 0 0 27.685 19v-.462A2.538 2.538 0 0 1 25.148 16a2.53 2.53 0 0 1 2.527-2.538zM11.643 19v-.344L11.633 16h-.443v3m6.453-6c-1.653 0-3 1.348-3 3s1.347 3 3 3a3 3 0 1 0 0-6zm0 5.538a2.538 2.538 0 1 1 0-5.075A2.538 2.538 0 0 1 20.18 16a2.531 2.531 0 0 1-2.537 2.538zM9.538 13c-1.653 0-3 1.348-3 3A2.544 2.544 0 0 1 4 18.538V19c1.652 0 3-1.348 3-3a2.544 2.544 0 0 1 2.538-2.538h3.855V13H9.538z'
			/>
		</g>
	</svg>
);
export default SvgStox;
