import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionQatar = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 1400 550'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#8d1b3d' d='M0 0h1400v550H0z' />
		<path
			fill='#fff'
			d='M400 550H0V0h400l100 30.556L400 61.11l100 30.556-100 30.555 100 30.556-100 30.555 100 30.556-100 30.555L500 275l-100 30.556 100 30.555-100 30.556 100 30.555-100 30.556 100 30.555-100 30.556 100 30.555z'
		/>
	</svg>
);
export default SvgRegionQatar;
