import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import MenuList from "./menuItems";

interface MenuItem {
  title: string;
  iconClass: string;
  page: string;
  account: string;
  subItems?: MenuItem[];
}

const UserProfileNavComponent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeIndexes, setActiveIndexes] = useState<number[]>([]);

  const { t } = useTranslation();

  const menuItems: MenuItem[] = MenuList();

  const toggleAccordion = (index: number) => {
    setActiveIndexes((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((i) => i !== index)
        : [...prevIndexes, index]
    );
  };

  const isActive = (index: number) => activeIndexes.includes(index);

  return (
    <>
      {menuItems.map((item, index) => (
        <div
          key={index}
          className={`user-profile-nav ${isActive(index) ? "active" : ""}`}
        >
          <div
            className="user-profile-nav-header"
            onClick={() => toggleAccordion(index)}
          >
            <i className={`user-profile-nav-icon ${item.iconClass}`}></i>
            <span className="user-profile-nav-title">{item.title}</span>
            <i
              className={`user-profile-nav-arrow ${
                isActive(index)
                  ? "bc-i-small-arrow-up"
                  : "bc-i-small-arrow-down"
              }`}
            ></i>
          </div>
          {isActive(index) && item.subItems && (
            <div className="user-profile-nav-list">
              {item.subItems.map((subItem, subIndex) => {
                return (
                  <span
                    key={subIndex}
                    className={`user-profile-nav-item ${
                      location.search.includes(subItem.page) ? "active" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate({
                        search: `?profile=open&account=${subItem.account}&page=${subItem.page}`,
                      });
                    }}
                  >
                    <span className="user-profile-nav-item-title ellipsis">
                      {subItem.title}
                    </span>
                  </span>
                );
              })}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default UserProfileNavComponent;
