import * as React from 'react';
import { SVGProps } from 'react';
const SvgSkl = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='currentColor' />
			<g fill='#FFF'>
				<path d='M22.514 8.492v.991H9.81v13.034h12.704V24.5l-7.42-.057-7.45-.085-.086-8.443L7.5 7.5h15.014z' />
				<path d='M23.698 10.9c1.126.312 2.108 1.19 2.425 2.182.173.595.087.651-.924.651-.78 0-1.212-.17-1.472-.566-.433-.709-2.397-.794-2.974-.114-.635.765.058 1.332 2.31 1.842 1.126.255 2.31.68 2.627.963 1.444 1.275.866 4.08-1.01 4.845-1.27.51-3.35.51-4.62 0-.866-.368-1.877-1.643-1.877-2.436 0-.51 1.877-.312 2.368.283 1.04 1.162 3.464.992 3.638-.255.144-.963-.405-1.388-2.05-1.587-2.685-.368-3.667-1.19-3.667-3.06 0-2.21 2.425-3.513 5.226-2.748z' />
			</g>
		</g>
	</svg>
);
export default SvgSkl;
