import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar7 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar7_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar7_svg__cls-15,.avatar7_svg__cls-2{fill:#fff}.avatar7_svg__cls-11,.avatar7_svg__cls-2,.avatar7_svg__cls-8{opacity:.3}.avatar7_svg__cls-4{fill:#b56b63}.avatar7_svg__cls-5{fill:#ff8475}.avatar7_svg__cls-10,.avatar7_svg__cls-11,.avatar7_svg__cls-18,.avatar7_svg__cls-6,.avatar7_svg__cls-7,.avatar7_svg__cls-8{fill:none;stroke-linecap:round}.avatar7_svg__cls-6{stroke:#393c54}.avatar7_svg__cls-10,.avatar7_svg__cls-11,.avatar7_svg__cls-18,.avatar7_svg__cls-6,.avatar7_svg__cls-8{stroke-linejoin:round}.avatar7_svg__cls-11,.avatar7_svg__cls-18,.avatar7_svg__cls-6{stroke-width:2px}.avatar7_svg__cls-7{stroke:#7f3838;stroke-miterlimit:10}.avatar7_svg__cls-7,.avatar7_svg__cls-8{stroke-width:6px}.avatar7_svg__cls-10,.avatar7_svg__cls-11,.avatar7_svg__cls-8{stroke:#515570}.avatar7_svg__cls-10{stroke-width:1.64px}.avatar7_svg__cls-12{fill:#7f3838}.avatar7_svg__cls-18{stroke:#8f5653;opacity:.5}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={66}
			r={60}
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<circle cx={64} cy={64} r={48} className='avatar7_svg__cls-2' />
		<path
			d='M64 126a59.92 59.92 0 0 0 48.93-25.29A45.57 45.57 0 0 0 70.18 70.9H57.82a45.57 45.57 0 0 0-42.75 29.81A59.92 59.92 0 0 0 64 126Z'
			style={{
				fill: '#8f5653',
			}}
		/>
		<path
			d='M26.326 45.262a7.94 7.94 0 0 1 11.23 0l10.507 10.507-11.236 11.236-10.5-10.5a7.94 7.94 0 0 1 0-11.23Z'
			className='avatar7_svg__cls-4'
		/>
		<path
			d='m43.91 59.93-9.29-9.29a7.94 7.94 0 0 0-10.57-.59 7.87 7.87 0 0 0 2.28 6.45L36.83 67Z'
			className='avatar7_svg__cls-5'
		/>
		<path
			d='M91.171 67.003 79.935 55.767l10.5-10.5a7.94 7.94 0 0 1 11.23 0 7.94 7.94 0 0 1 0 11.229l-10.5 10.5Z'
			className='avatar7_svg__cls-4'
		/>
		<path
			d='m84.09 59.93 9.29-9.29a7.94 7.94 0 0 1 10.62-.59 7.87 7.87 0 0 1-2.28 6.45L91.17 67Z'
			className='avatar7_svg__cls-5'
		/>
		<path
			d='M28 87.18V76.34a36.15 36.15 0 0 1 36-36.29 36.15 36.15 0 0 1 36 36.29v10.84c0 18.14-18 33.92-36 33.69S28 108 28 87.18Z'
			className='avatar7_svg__cls-4'
		/>
		<path d='M97.68 63.49A36.48 36.48 0 0 1 100 76.34' className='avatar7_svg__cls-6' />
		<path d='M64 40.05a36 36 0 0 1 33.68 23.44' className='avatar7_svg__cls-7' />
		<path d='M30.32 63.49A36.48 36.48 0 0 0 28 76.34' className='avatar7_svg__cls-6' />
		<path d='M64 40.05a36 36 0 0 0-33.68 23.44' className='avatar7_svg__cls-7' />
		<path
			d='M64 40.05a36 36 0 0 1 33.68 23.44M64 40.05a36 36 0 0 0-33.68 23.44'
			className='avatar7_svg__cls-8'
		/>
		<path
			d='M47.67 78.12c0-9 7.31-23.41 16.33-23.41s16.33 14.38 16.33 23.41S73 90.45 64 90.45s-16.33-3.31-16.33-12.33Z'
			className='avatar7_svg__cls-2'
		/>
		<path
			d='M75.94 70c0 3.59-5.35 5.4-11.94 5.4S52.06 73.61 52.06 70c0-2.08 23.88-2.35 23.88 0Z'
			style={{
				fill: '#393c54',
			}}
		/>
		<path d='M64 75.42v9.34M61 85.67s6.2.65 9.83-2.72' className='avatar7_svg__cls-6' />
		<path
			d='M83.51 63.49a2.29 2.29 0 1 1-4.54 0M49 63.49a2.58 2.58 0 0 1-2.27 2.6 2.58 2.58 0 0 1-2.27-2.6'
			className='avatar7_svg__cls-10'
		/>
		<path
			d='M40.63 62.51a6.81 6.81 0 0 1 6.81-6.81M87.37 62.51a6.81 6.81 0 0 0-6.81-6.81'
			className='avatar7_svg__cls-11'
		/>
		<path
			d='M83.09 29.5a101.29 101.29 0 0 0-19.8-1.17 101.29 101.29 0 0 0-19.8 1.17 4 4 0 0 0-4 4V45a4 4 0 0 0 4 4q3.12-.27 6.24-.42a4 4 0 0 0 1.76.42q5.9-.51 11.8-.64 5.91.12 11.8.64a4 4 0 0 0 1.76-.42q3.12.15 6.24.42a4 4 0 0 0 4-4V33.5a4 4 0 0 0-4-4Z'
			className='avatar7_svg__cls-12'
		/>
		<path
			d='M83.09 29.5a101.29 101.29 0 0 0-19.8-1.17 101.29 101.29 0 0 0-19.8 1.17 4 4 0 0 0-4 4V45a4 4 0 0 0 4 4q3.12-.27 6.24-.42a4 4 0 0 0 1.76.42q5.9-.51 11.8-.64 5.91.12 11.8.64a4 4 0 0 0 1.76-.42q3.12.15 6.24.42a4 4 0 0 0 4-4V33.5a4 4 0 0 0-4-4Z'
			style={{
				opacity: 0.7,
				fill: '#515570',
			}}
		/>
		<path
			d='M79.09 25.5a100.28 100.28 0 0 0-31.6 0 4 4 0 0 0-4 4V41a4 4 0 0 0 4 4 183.23 183.23 0 0 1 31.6 0 4 4 0 0 0 4-4V29.5a4 4 0 0 0-4-4Z'
			className='avatar7_svg__cls-12'
		/>
		<path
			d='M79.09 25.5a100.28 100.28 0 0 0-31.6 0 4 4 0 0 0-4 4V41a4 4 0 0 0 4 4 183.23 183.23 0 0 1 31.6 0 4 4 0 0 0 4-4V29.5a4 4 0 0 0-4-4Z'
			className='avatar7_svg__cls-12'
		/>
		<path
			d='M79.09 25.5a100.28 100.28 0 0 0-31.6 0 4 4 0 0 0-4 4V41a4 4 0 0 0 4 4 183.23 183.23 0 0 1 31.6 0 4 4 0 0 0 4-4V29.5a4 4 0 0 0-4-4Z'
			style={{
				fill: '#515570',
				opacity: 0.3,
			}}
		/>
		<path
			d='M79.09 30.5a100.28 100.28 0 0 0-31.6 0 4 4 0 0 0-4 4V46a4 4 0 0 0 4 4 183.23 183.23 0 0 1 31.6 0 4 4 0 0 0 4-4V34.5a4 4 0 0 0-4-4Z'
			className='avatar7_svg__cls-15'
		/>
		<path
			d='M79.09 30.5a100.28 100.28 0 0 0-31.6 0 4 4 0 0 0-4 4V46a4 4 0 0 0 4 4 183.23 183.23 0 0 1 31.6 0 4 4 0 0 0 4-4V34.5a4 4 0 0 0-4-4Z'
			className='avatar7_svg__cls-15'
		/>
		<path
			d='M81.05 31v12a4 4 0 0 1-4 4 183.23 183.23 0 0 0-31.6 0 3.93 3.93 0 0 1-2-.53 4 4 0 0 0 4 3.53 183.23 183.23 0 0 1 31.6 0 4 4 0 0 0 4-4V34.5a4 4 0 0 0-2-3.5Z'
			style={{
				opacity: 0.2,
				fill: '#393c54',
			}}
		/>
		<path
			d='M63.5 42.34 66.62 44a.5.5 0 0 0 .73-.53l-.6-3.47 2.52-2.46a.5.5 0 0 0-.27-.85l-3.49-.51L64 33a.5.5 0 0 0-.9 0l-1.56 3.16-3.54.51a.5.5 0 0 0-.27.85L60.25 40l-.6 3.48a.5.5 0 0 0 .73.53Z'
			style={{
				fill: '#f85565',
			}}
		/>
		<path
			d='M64.35 110.69a4.33 4.33 0 0 0 8.17-2.63 4.33 4.33 0 0 0 7.3-4.51 4.33 4.33 0 0 0 6-6.11M40.21 94.19a4.33 4.33 0 0 0 7.64 3.91 4.33 4.33 0 0 0 8.35 2'
			className='avatar7_svg__cls-18'
		/>
	</svg>
);
export default SvgAvatar7;
