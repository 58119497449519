import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar21 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar21_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar21_svg__cls-1{fill:#515570}.avatar21_svg__cls-3,.avatar21_svg__cls-6{fill:#fff}.avatar21_svg__cls-5{fill:#a7aece}.avatar21_svg__cls-6{opacity:.3}.avatar21_svg__cls-7,.avatar21_svg__cls-8{fill:#393c54}.avatar21_svg__cls-8{opacity:.15}.avatar21_svg__cls-9{fill:#f85565}'
				}
			</style>
		</defs>
		<circle cx={64} cy={64} r={60} className='avatar21_svg__cls-1' />
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				fill: '#6b718e',
			}}
		/>
		<path
			d='M40.55 119.5A59.73 59.73 0 0 0 64 124.1a59.73 59.73 0 0 0 23.45-4.6L92 51H36Z'
			className='avatar21_svg__cls-3'
		/>
		<path
			d='m84.56 51-5.92 54.15A16.41 16.41 0 0 1 67.46 119a89.27 89.27 0 0 1-18.58 3.32A62.17 62.17 0 0 0 64 124.1a59.73 59.73 0 0 0 23.45-4.6L92 51Z'
			style={{
				fill: '#356cb6',
				opacity: 0.1,
			}}
		/>
		<path
			d='M84 32.67H45a3 3 0 0 0-2.76 1.81L38 44.33h53l-4.22-9.85A3 3 0 0 0 84 32.67Z'
			className='avatar21_svg__cls-5'
		/>
		<path
			d='M84 32.67H45a3 3 0 0 0-2.76 1.81L38 44.33h53l-4.22-9.85A3 3 0 0 0 84 32.67Z'
			className='avatar21_svg__cls-6'
		/>
		<path
			d='m64.93 91.891 16.421 4.4a5 5 0 0 1-6.124 3.536l-6.761-1.812a5 5 0 0 1-3.536-6.124ZM46.658 96.293l16.42-4.4a5 5 0 0 1-3.535 6.123l-6.762 1.812a5 5 0 0 1-6.123-3.535Z'
			className='avatar21_svg__cls-7'
		/>
		<rect width={66} height={12} x={31} y={41} className='avatar21_svg__cls-5' rx={2.06} />
		<path
			d='M94.94 41h-9A2.06 2.06 0 0 1 88 43.06v7.88A2.06 2.06 0 0 1 85.94 53h9A2.06 2.06 0 0 0 97 50.94v-7.88A2.06 2.06 0 0 0 94.94 41Z'
			className='avatar21_svg__cls-8'
		/>
		<path
			d='m91.65 91.13 1.8-27a2 2 0 0 0-2-2.13H35.54a2 2 0 0 0-2 2.13l1.8 27a2 2 0 0 0 2 1.87h52.32a2 2 0 0 0 1.99-1.87Z'
			className='avatar21_svg__cls-9'
		/>
		<path
			d='m91.65 91.13 1.8-27a2 2 0 0 0-2-2.13H35.54a2 2 0 0 0-2 2.13l1.8 27a2 2 0 0 0 2 1.87h52.32a2 2 0 0 0 1.99-1.87Z'
			className='avatar21_svg__cls-9'
		/>
		<path
			d='M91.46 62h-8a2 2 0 0 1 2 2.13l-1.8 27a2 2 0 0 1-2 1.87h8a2 2 0 0 0 2-1.87l1.8-27a2 2 0 0 0-2-2.13Z'
			className='avatar21_svg__cls-8'
		/>
		<ellipse cx={76.5} cy={80} className='avatar21_svg__cls-3' rx={8.17} ry={4.61} />
		<ellipse cx={51.5} cy={80} className='avatar21_svg__cls-3' rx={8.17} ry={4.61} />
		<circle cx={76.75} cy={78.8} r={3} className='avatar21_svg__cls-1' />
		<circle cx={51.25} cy={78.8} r={3} className='avatar21_svg__cls-1' />
		<path
			d='M59 104h10'
			style={{
				fill: 'none',
				stroke: '#f85565',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: 2,
			}}
		/>
		<path d='M100 65h10a7 7 0 0 1-7 7H93a7 7 0 0 1 7-7Z' className='avatar21_svg__cls-9' />
		<path
			d='m102.919 71.962 7.071 7.071a7 7 0 0 1-9.9 0l-7.07-7.07a7 7 0 0 1 9.899 0Z'
			className='avatar21_svg__cls-9'
		/>
		<path
			d='M100 72h10a7 7 0 0 1-7 7H93a7 7 0 0 1 7-7Z'
			style={{
				opacity: 0.2,
				fill: '#fff',
			}}
			transform='rotate(45 101.505 75.505)'
		/>
		<path
			d='M98 21.83H83.83a7.5 7.5 0 0 0-7.5 7.5H90.5a7.5 7.5 0 0 0 7.5-7.5ZM94.77 12.49a7.5 7.5 0 0 0-4.44 6.84h13.72a60 60 0 0 0-9.28-6.84Z'
			className='avatar21_svg__cls-6'
		/>
	</svg>
);
export default SvgAvatar21;
