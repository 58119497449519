import * as React from 'react';
import { SVGProps } from 'react';
const SvgTrig = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#30c0f2' />
			<path
				fill='#fff'
				fillRule='nonzero'
				d='M10.123 7h11.774s.312 1.554 1.98 1.658c0 0 5.522 12.85-7.919 18.342 0 0-12.399-3.938-8.023-18.238 0 0 1.876-.104 2.188-1.762zm11.15 1.023h-10.52l-.014.224c-.088.489-.333.83-.692 1.06a1.89 1.89 0 0 1-.732.271l-.184.018-.052.178c-.969 3.31-1.002 6.17-.239 8.61.626 2 1.759 3.64 3.252 4.95a12.48 12.48 0 0 0 2.595 1.753c.31.157.6.286.859.39.157.063.272.104.335.125l.098.03.095-.04c3.706-1.584 5.948-3.978 6.952-6.96.83-2.46.756-5.079.137-7.559a14.225 14.225 0 0 0-.43-1.406 4.272 4.272 0 0 0-.042-.109l-.064-.158-.171-.011c-.486-.032-.795-.23-.986-.548a1.367 1.367 0 0 1-.185-.56zm-10.045.545c6.367.001 9.55.002 9.55.003.044.187.115.373.22.55.256.425.667.712 1.243.794a13.684 13.684 0 0 1 .39 1.29c.596 2.388.666 4.906-.125 7.254-.945 2.807-3.046 5.067-6.551 6.59a10.218 10.218 0 0 1-1.019-.449 11.936 11.936 0 0 1-2.481-1.674c-1.421-1.248-2.497-2.804-3.091-4.703-.718-2.295-.699-4.993.194-8.134a2.556 2.556 0 0 0 .785-.324 2.03 2.03 0 0 0 .885-1.197zm7.36 5.582h-.303c.03-.608-.105-1.932-1.75-2.3a2.612 2.612 0 0 0-1.444.066c-.642.228-1.408.801-1.437 2.234h-.223a.86.86 0 0 0-.86.855v3.575a.86.86 0 0 0 .86.855h5.158a.86.86 0 0 0 .86-.855v-3.575a.86.86 0 0 0-.86-.855zm-2.005 4.042h-1.146l.303-1.507a.673.673 0 0 1 .27-1.291.673.673 0 1 1 .27 1.291zm-2.188-4.042c.028-.468.226-1.576 1.459-1.658 0 0 1.626-.089 1.597 1.658z'
			/>
		</g>
	</svg>
);
export default SvgTrig;
