import * as React from 'react';
import { SVGProps } from 'react';
const SvgVeri = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#F93' />
			<path
				fill='#FFF'
				d='M6 8c1.104.002 2.209.002 3.312 0l2.091 11.18c.033.165.073.328.116.49.164-.65.267-1.314.404-1.97L13.827 8l3.204.001c-1.175 5.232-2.35 10.465-3.527 15.697H9.527C8.35 18.469 7.175 13.24 6 8.01V8zm12.314 4.932c.697-.876 1.811-1.273 2.885-1.343 1.06-.074 2.202.022 3.1.654.795.556 1.231 1.496 1.438 2.431.251 1.172.264 2.376.263 3.57h-5.87c.005.883-.05 1.794.245 2.64.143.416.41.83.834.981.452.147.996.051 1.326-.313.44-.498.532-1.196.628-1.835h2.664c-.06 1.06-.256 2.18-.95 3.013-.696.866-1.818 1.22-2.88 1.263-1.154.044-2.407-.113-3.327-.89-.855-.713-1.228-1.843-1.376-2.926-.148-1.247-.156-2.509-.087-3.762.093-1.222.314-2.516 1.106-3.484zm2.274 1.245c-.454.654-.466 1.496-.458 2.267h3.032c-.047-.733-.042-1.507-.372-2.178-.41-.828-1.706-.831-2.2-.088h-.002z'
			/>
		</g>
	</svg>
);
export default SvgVeri;
