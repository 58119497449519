import * as React from 'react';
import { SVGProps } from 'react';
const SvgSushi = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#D65892' fillRule='nonzero' />
			<path
				fill='#FFF'
				d='M13.794 7.02c1.862.157 4.327 1.18 6.702 2.912 2.366 1.742 4.102 3.815 4.84 5.566.54 1.271.558 2.396.018 3.17l-3.706 5.307c-.54.774-1.593 1.115-2.933 1.005-1.862-.166-4.336-1.18-6.702-2.921-2.375-1.733-4.102-3.806-4.85-5.557-.539-1.271-.557-2.396-.017-3.17l3.715-5.307c.54-.774 1.584-1.115 2.933-1.005zm7.788 12.448a.134.134 0 0 0-.046.264c.732.13 1.387.153 1.934.064a.134.134 0 0 0-.043-.264c-.514.084-1.14.061-1.845-.064zm-1.191-.147a.134.134 0 0 0 .092.166c.176.05.35.095.521.135a.134.134 0 0 0 .06-.26c-.166-.04-.336-.084-.509-.133a.133.133 0 0 0-.164.092zM11.374 8.403l-.018.036c-.405.618-.315 1.493.108 2.488.71 1.677 2.375 3.64 4.633 5.299 2.258 1.658 4.606 2.644 6.378 2.792 1.062.083 1.898-.11 2.34-.691l.026-.046c.432-.609.342-1.512-.08-2.525-.711-1.668-2.376-3.63-4.643-5.29-2.258-1.658-4.606-2.644-6.378-2.791-1.08-.093-1.934.11-2.366.728zm-.744 2.448a.134.134 0 0 0-.09.166c.456 1.564 1.614 3.053 3.435 4.73l.205.188a35 35 0 0 0 .426.38c.056.048.14.042.188-.014a.134.134 0 0 0-.013-.189c-.11-.095-.216-.19-.322-.285l-.207-.188c-1.843-1.683-3.01-3.166-3.457-4.697a.133.133 0 0 0-.165-.091zm5.251-.283c.882.083 2.06.571 3.185 1.4 1.133.83 1.97 1.816 2.32 2.654.208.47.262.885.055 1.17-.198.286-.603.37-1.098.323-.89-.074-2.06-.571-3.193-1.4-1.134-.83-1.97-1.807-2.321-2.645-.198-.47-.252-.885-.054-1.17.198-.286.602-.369 1.106-.332zm-5.397-.778a.134.134 0 0 0-.128.139c.008.182.027.365.059.55a.133.133 0 1 0 .262-.046 4.018 4.018 0 0 1-.055-.515.133.133 0 0 0-.138-.128z'
			/>
		</g>
	</svg>
);
export default SvgSushi;
