import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar22 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar22_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar22_svg__cls-12{fill:#393c54}.avatar22_svg__cls-3{fill:#4bc190}.avatar22_svg__cls-6,.avatar22_svg__cls-9{fill:#356cb6}.avatar22_svg__cls-10,.avatar22_svg__cls-16,.avatar22_svg__cls-17,.avatar22_svg__cls-18{fill:none;stroke-linecap:round;stroke-linejoin:round}.avatar22_svg__cls-9{opacity:.3}.avatar22_svg__cls-10{stroke:#f85565;stroke-width:3px}.avatar22_svg__cls-11{fill:#fff}.avatar22_svg__cls-16,.avatar22_svg__cls-17,.avatar22_svg__cls-18{stroke:#515570}.avatar22_svg__cls-16,.avatar22_svg__cls-18{stroke-width:2px}.avatar22_svg__cls-16{opacity:.1}.avatar22_svg__cls-17{stroke-width:4px}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#515570',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.5,
				fill: '#393c54',
			}}
		/>
		<circle cx={91.32} cy={60.43} r={7.93} className='avatar22_svg__cls-3' />
		<path
			d='M86.94 36.78A5.12 5.12 0 0 0 87 36a6 6 0 0 0-5.08-5.92A6 6 0 0 0 76 25a5.12 5.12 0 0 0-.78.06 6 6 0 0 0-8.67-2A6 6 0 0 0 56 27c0 3.31 2.69 19 6 19 1.29 0 18.69 2 22 2a6 6 0 0 0 2.94-11.22Z'
			style={{
				fill: '#ff8475',
			}}
		/>
		<path
			d='M63 123.32a56.83 56.83 0 0 0 38-14.52l-2.27-5.31c-1.62-3.78-5.91-6.31-10.73-6.31H38c-4.82 0-9.11 2.53-10.73 6.31L25 108.8a56.83 56.83 0 0 0 38 14.52Z'
			className='avatar22_svg__cls-3'
		/>
		<path
			d='M64 124a59.77 59.77 0 0 0 39.83-15.14l-2.23-5.22c-1.71-4-6.22-6.64-11.29-6.64H38.64a1 1 0 0 0-1 .94L37 109.5s-4.78-6.82-6.52-9.24a1 1 0 0 0-1.48-.16 10 10 0 0 0-2.61 3.54l-2.23 5.22A59.77 59.77 0 0 0 64 124Z'
			style={{
				fill: '#00adfe',
			}}
		/>
		<path
			d='M77.43 98.73a13.43 13.43 0 0 1-26.86 0c0-5 6-3.25 13.43-3.25s13.43-1.8 13.43 3.25Z'
			className='avatar22_svg__cls-6'
		/>
		<path
			d='M64 84.75V98.5'
			style={{
				strokeWidth: 20,
				stroke: '#4bc190',
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
		<path
			d='M64 84.75V98.5'
			style={{
				opacity: 0.3,
				stroke: '#356cb6',
				strokeWidth: 20,
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
		<circle cx={36.68} cy={60.43} r={7.93} className='avatar22_svg__cls-3' />
		<circle cx={91.32} cy={60.43} r={7.93} className='avatar22_svg__cls-9' />
		<circle cx={36.68} cy={60.43} r={7.93} className='avatar22_svg__cls-9' />
		<path
			d='M90 42a.22.22 0 0 0 0-.08c-.65 0-1.32.08-2 .08a26 26 0 0 1-25.51-21 3.91 3.91 0 0 0-.49 0 6 6 0 0 0-6 6c0 3.31 2.69 19 6 19 1.29 0 18.69 2 22 2a6 6 0 0 0 6-6Z'
			className='avatar22_svg__cls-9'
		/>
		<path
			d='M86 44a26 26 0 0 1-25.76-22.5 2 2 0 0 0-2.4-1.71 28.32 28.32 0 0 0-22.16 27.64v18c0 15.43 12.1 28.47 27.52 28.89a28.32 28.32 0 0 0 29.12-28.27V47.43c0-.64 0-1.28-.07-1.91a2 2 0 0 0-2.32-1.82A25.42 25.42 0 0 1 86 44Z'
			className='avatar22_svg__cls-3'
		/>
		<path d='M53.65 29.73a23.15 23.15 0 0 0-9 10.61' className='avatar22_svg__cls-10' />
		<circle cx={77.5} cy={60} r={5} className='avatar22_svg__cls-11' />
		<circle cx={77.5} cy={60} r={1.75} className='avatar22_svg__cls-12' />
		<circle cx={50.5} cy={60} r={5} className='avatar22_svg__cls-12' />
		<path d='M56.33 60H44.67a5.83 5.83 0 0 1 11.66 0Z' className='avatar22_svg__cls-3' />
		<path d='M56.33 60H44.67a5.83 5.83 0 0 1 11.66 0Z' className='avatar22_svg__cls-9' />
		<path d='M83.33 60H71.67a5.83 5.83 0 0 1 11.66 0Z' className='avatar22_svg__cls-3' />
		<path d='M83.33 60H71.67a5.83 5.83 0 0 1 11.66 0Z' className='avatar22_svg__cls-9' />
		<path
			d='M60.17 86h3.66'
			style={{
				opacity: 0.4,
				strokeWidth: 2,
				stroke: '#515570',
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
		<path d='M70 83H58a4 4 0 0 1 0-8h12a4 4 0 0 1 0 8Z' className='avatar22_svg__cls-12' />
		<path
			d='M57 75h3v1.5a1.5 1.5 0 0 1-1.5 1.5 1.5 1.5 0 0 1-1.5-1.5V75Z'
			className='avatar22_svg__cls-11'
		/>
		<path
			d='M56.36 82.64A3.9 3.9 0 0 0 58 83h12a3.9 3.9 0 0 0 1.64-.36C71.36 80.05 68.05 78 64 78s-7.36 2.05-7.64 4.64Z'
			style={{
				fill: '#f85565',
			}}
		/>
		<path
			d='m67.62 68.35-2.86-6.55a.61.61 0 0 0-1.12 0l-2.86 6.55'
			style={{
				strokeWidth: 2,
				stroke: '#515570',
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				opacity: 0.5,
			}}
		/>
		<path
			d='M85.75 70.5a7.5 7.5 0 0 0-7.5 7.5v5M42.25 70.5a7.5 7.5 0 0 1 7.5 7.5v5'
			className='avatar22_svg__cls-16'
		/>
		<path d='M69 50h13M46 50h13' className='avatar22_svg__cls-17' />
		<path d='m44 36 5 3M48.44 30.44l4.12 4.12' className='avatar22_svg__cls-18' />
		<path d='M68.5 81.5v4' className='avatar22_svg__cls-10' />
		<path
			d='m29.16 99.52-1 4.69a1 1 0 0 0 .57 1.12L37 109s-4.77-6.81-6.51-9.23a1 1 0 0 0-1.33-.25Z'
			className='avatar22_svg__cls-6'
		/>
	</svg>
);
export default SvgAvatar22;
