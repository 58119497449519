import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionDominica = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		viewBox='0 0 1200 600'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#006b3f' d='M0 0h1200v600H0z' />
		<path fill='#fcd116' d='M0 225h1200v100H0z' />
		<path fill='#fff' d='M0 275h1200v100H0z' />
		<path d='M0 275h1200v50H0z' />
		<path fill='#fcd116' d='M525 0h100v600H525z' />
		<path fill='#fff' d='M575 0h100v600H575z' />
		<path d='M575 0h50v600h-50z' />
		<g fill='#006b3f' transform='translate(600 300)'>
			<circle r={150} fill='#d41c30' />
			<g id='regionDominica_svg__b'>
				<path
					id='regionDominica_svg__a'
					stroke='currentColor'
					d='M-4.81-118.41 0-132.13l4.37 13.5 14.65.22-11.59 8.85 5.25 15.05L0-103.8l-12.46 8.85 4.81-14.61-11.37-8.63z'
				/>
				<use
					xlinkHref='#regionDominica_svg__a'
					width='100%'
					height='100%'
					transform='scale(-1)'
				/>
			</g>
			<use
				xlinkHref='#regionDominica_svg__b'
				width='100%'
				height='100%'
				transform='rotate(36)'
			/>
			<use
				xlinkHref='#regionDominica_svg__b'
				width='100%'
				height='100%'
				transform='rotate(72)'
			/>
			<use
				xlinkHref='#regionDominica_svg__b'
				width='100%'
				height='100%'
				transform='rotate(108)'
			/>
			<use
				xlinkHref='#regionDominica_svg__b'
				width='100%'
				height='100%'
				transform='rotate(144)'
			/>
			<g stroke='currentColor' strokeWidth={2.5}>
				<ellipse
					cx={680.21}
					cy={586.13}
					rx={30.805}
					ry={189.82}
					transform='matrix(.28955 -.03018 .01659 .23226 -186.117 -75.926)'
				/>
				<ellipse
					cx={680.21}
					cy={586.13}
					rx={30.805}
					ry={189.82}
					transform='matrix(.30626 -.03184 .01669 .23225 -188.733 -74.887)'
				/>
				<ellipse
					cx={680.21}
					cy={586.13}
					rx={30.805}
					ry={189.82}
					transform='matrix(.245 -.0259 .0179 .25675 -151.947 -90.936)'
				/>
				<path
					fill='#9c4a00'
					strokeWidth={0.657}
					d='M-44.213 62.565c7.657-.319 4.332-4.416 8.2-6.596 3.864-2.177 9.365-.737 11.015 1.986 1.65 2.722.327 5.397 2.45 5.433 2.126.037 59.928-2.387 62.01-.105 2.085 2.28 2.429 6.776.27 8.524-2.16 1.77-75.235 2.588-77.751.738-2.517-1.824-6.202-9.53-6.194-9.98z'
				/>
				<g fill='#fcd116' strokeWidth={0.633}>
					<path d='M-3.377 68.195c-.139 5.775 8.404 4.14 8.521 8.963-.257 5.114-14.324.313-14.46-9.041.585-9.345 14.354-13.963 14.525-8.625.126 4.435-8.447 2.927-8.586 8.703z' />
					<path d='M1.664 68.261c-.14 5.776 8.403 4.14 8.52 8.964-.257 5.113-14.324.312-14.459-9.042.585-9.345 14.354-13.962 14.524-8.625.127 4.435-8.446 2.928-8.585 8.703z' />
					<path d='M7.07 68.058c-.138 5.775 8.404 4.14 8.522 8.963-.257 5.114-14.324.313-14.46-9.042.585-9.344 14.354-13.962 14.524-8.624.127 4.435-8.446 2.927-8.585 8.703z' />
					<path d='M12.111 68.124c-.138 5.776 8.404 4.14 8.521 8.964-.257 5.113-14.324.312-14.46-9.042.586-9.345 14.355-13.962 14.525-8.625.126 4.435-8.447 2.928-8.586 8.703z' />
				</g>
				<ellipse
					cx={478.38}
					cy={-41.086}
					fill='#9c4a00'
					strokeWidth={0.399}
					rx={3.534}
					ry={3.403}
					transform='matrix(1.37992 .02078 -.03062 1.45444 -625.227 117.205)'
				/>
				<ellipse
					cx={427.11}
					cy={905}
					rx={20.814}
					ry={24.144}
					transform='matrix(.20742 -.0313 .04226 .39514 -119.978 -293.416)'
				/>
				<ellipse
					cx={427.11}
					cy={905}
					rx={20.814}
					ry={24.144}
					transform='matrix(.20742 -.0313 .04226 .39514 -127.335 -293.996)'
				/>
				<ellipse
					cx={427.11}
					cy={905}
					rx={20.814}
					ry={24.144}
					transform='matrix(.20742 -.0313 .04226 .39514 -132.603 -303.411)'
				/>
				<ellipse
					cx={427.11}
					cy={905}
					rx={20.814}
					ry={24.144}
					transform='matrix(.19943 -.03154 .04632 .43593 -125.407 -340.635)'
				/>
				<ellipse
					cx={427.11}
					cy={905}
					rx={20.814}
					ry={24.144}
					transform='matrix(.1995 -.03084 .04356 .40874 -112.65 -316.232)'
				/>
				<ellipse
					cx={427.11}
					cy={905}
					rx={20.814}
					ry={24.144}
					transform='matrix(.1995 -.03084 .04356 .40874 -118.304 -319.639)'
				/>
				<ellipse
					cx={427.11}
					cy={905}
					rx={20.814}
					ry={24.144}
					transform='matrix(.1995 -.03084 .04356 .40874 -125.437 -324.554)'
				/>
				<ellipse
					cx={427.11}
					cy={905}
					rx={20.814}
					ry={24.144}
					transform='matrix(.1995 -.03084 .04356 .40874 -132.042 -327.532)'
				/>
				<path
					fill='#9461c9'
					strokeWidth={0.575}
					d='M-14.734-52.686s-14.655 12.72-13.01 47.144C-25.9 29.078 4.648 45.352 4.648 45.352s7.786-9.827 6.882-37.644C9.347-32.96-5.078-50.929-5.078-50.929z'
				/>
				<ellipse
					cx={427.11}
					cy={905}
					rx={20.814}
					ry={24.144}
					transform='matrix(.20968 .00618 -.02884 .39634 -45.183 -309.401)'
				/>
				<ellipse
					cx={427.11}
					cy={905}
					rx={20.814}
					ry={24.144}
					transform='matrix(.20968 .00618 -.02884 .39634 -52.319 -311.283)'
				/>
				<ellipse
					cx={427.11}
					cy={905}
					rx={20.814}
					ry={24.144}
					transform='matrix(.20968 .00618 -.02884 .39634 -55.825 -321.487)'
				/>
				<ellipse
					cx={427.11}
					cy={905}
					rx={20.814}
					ry={24.144}
					transform='matrix(.20186 .00451 -.03211 .4372 -42.11 -356.832)'
				/>
				<ellipse
					cx={427.11}
					cy={905}
					rx={20.814}
					ry={24.144}
					transform='matrix(.2018 .00521 -.02998 .40996 -33.907 -330.547)'
				/>
				<ellipse
					cx={427.11}
					cy={905}
					rx={20.814}
					ry={24.144}
					transform='matrix(.2018 .00521 -.02998 .40996 -38.863 -334.906)'
				/>
				<ellipse
					cx={427.11}
					cy={905}
					rx={20.814}
					ry={24.144}
					transform='matrix(.2018 .00521 -.02998 .40996 -45.006 -341.014)'
				/>
				<ellipse
					cx={427.11}
					cy={905}
					rx={20.814}
					ry={24.144}
					transform='matrix(.2018 .00521 -.02998 .40996 -50.974 -345.121)'
				/>
				<ellipse
					cx={624.42}
					cy={606.11}
					fill='#d41c30'
					strokeWidth='1pt'
					rx={58.28}
					ry={186.49}
					transform='matrix(.19787 -.07643 .08023 .1871 -155.838 -59.213)'
				/>
				<ellipse
					cx={218.13}
					cy={356.75}
					strokeWidth={1.464}
					rx={10.823}
					ry={12.905}
					transform='matrix(.49828 .00887 -.00657 .36896 -99.51 -162.77)'
				/>
				<ellipse
					cx={218.13}
					cy={356.75}
					strokeWidth={1.546}
					rx={10.823}
					ry={12.905}
					transform='matrix(.44637 .00795 -.00657 .36896 -84.135 -169.3)'
				/>
				<ellipse
					cx={218.13}
					cy={356.75}
					strokeWidth={1.546}
					rx={10.823}
					ry={12.905}
					transform='matrix(.44637 .00795 -.00657 .36896 -90.652 -169.416)'
				/>
				<ellipse
					cx={218.13}
					cy={356.75}
					strokeWidth={1.56}
					rx={10.823}
					ry={12.905}
					transform='matrix(.46714 .00832 -.00617 .34637 -93.888 -166.37)'
				/>
				<ellipse
					cx={218.13}
					cy={356.75}
					strokeWidth={1.56}
					rx={10.823}
					ry={12.905}
					transform='matrix(.46714 .00832 -.00617 .34637 -94.462 -172.017)'
				/>
				<ellipse
					cx={218.13}
					cy={356.75}
					strokeWidth={1.546}
					rx={10.823}
					ry={12.905}
					transform='matrix(.44637 .00795 -.00657 .36896 -96.878 -173.22)'
				/>
				<ellipse
					cx={218.13}
					cy={356.75}
					strokeWidth={1.562}
					rx={10.823}
					ry={12.905}
					transform='matrix(.44637 .00795 -.00644 .36143 -95.469 -176.631)'
				/>
				<ellipse
					cx={218.13}
					cy={356.75}
					strokeWidth={1.464}
					rx={10.823}
					ry={12.905}
					transform='matrix(.49828 .00887 -.00657 .36896 -114.725 -179.37)'
				/>
				<ellipse
					cx={218.13}
					cy={356.75}
					strokeWidth={1.56}
					rx={10.823}
					ry={12.905}
					transform='matrix(.46714 .00832 -.00617 .34637 -98.157 -179.081)'
				/>
				<ellipse
					cx={218.13}
					cy={356.75}
					strokeWidth={1.562}
					rx={10.823}
					ry={12.905}
					transform='matrix(.44637 .00795 -.00644 .36143 -100.291 -183.521)'
				/>
				<ellipse
					cx={218.13}
					cy={356.75}
					strokeWidth={1.546}
					rx={10.823}
					ry={12.905}
					transform='matrix(.44637 .00795 -.00657 .36896 -110.489 -178.906)'
				/>
				<ellipse
					cx={218.13}
					cy={356.75}
					strokeWidth={1.562}
					rx={10.823}
					ry={12.905}
					transform='matrix(.44637 .00795 -.00644 .36143 -108.857 -179.772)'
				/>
				<ellipse
					cx={218.13}
					cy={356.75}
					strokeWidth={1.562}
					rx={10.823}
					ry={12.905}
					transform='matrix(.44637 .00795 -.00644 .36143 -106.807 -183.696)'
				/>
				<ellipse
					cx={528.68}
					cy={564.48}
					strokeWidth={2.545}
					rx={67.438}
					ry={205.64}
					transform='matrix(.21932 -.07743 .08683 .20202 -145.015 -72.556)'
				/>
				<ellipse
					cx={528.68}
					cy={646.07}
					rx={13.321}
					ry={40.796}
					transform='matrix(.20574 -.09785 .09471 .21196 -137.122 -77.403)'
				/>
				<g strokeWidth={0.575}>
					<path d='M31.463 7.799c12.81 15.598 11.193 25.503 9.857 33.883-4.025-9.663-6.223-15.746-19.035-31.347-12.813-15.6-6.418-26.548-5.538-32.316 1.926 4.993 1.903 14.179 14.716 29.78z' />
					<path d='M32.616 20.398C45.426 35.996 43.81 45.9 42.473 54.28c-4.024-9.663-6.222-15.746-19.035-31.347C10.625 7.334 17.021-3.613 17.9-9.382c1.927 4.993 1.903 14.179 14.716 29.78z' />
					<path d='M33.002 30.545c13.12 15.323 11.7 25.26 10.532 33.667-4.217-9.576-6.536-15.61-19.658-30.936-13.122-15.325-6.947-26.405-6.183-32.19 2.026 4.95 2.186 14.134 15.309 29.46z' />
				</g>
				<ellipse
					cx={528.68}
					cy={646.07}
					strokeWidth={1.389}
					rx={13.321}
					ry={40.796}
					transform='matrix(.40784 -.1038 .16694 .37767 -299.57 -196.03)'
				/>
				<ellipse
					cx={528.68}
					cy={646.07}
					strokeWidth={1.64}
					rx={13.321}
					ry={40.796}
					transform='matrix(.3669 -.0631 .12978 .31265 -259.003 -166.871)'
				/>
				<ellipse
					cx={528.68}
					cy={646.07}
					strokeWidth={1.64}
					rx={13.321}
					ry={40.796}
					transform='matrix(.36815 -.05532 .12326 .31531 -261.524 -166.82)'
				/>
				<ellipse
					cx={528.68}
					cy={646.07}
					strokeWidth={2.097}
					rx={13.321}
					ry={40.796}
					transform='matrix(.29658 -.05431 .11151 .23308 -211.07 -135.538)'
				/>
				<ellipse
					cx={528.68}
					cy={646.07}
					strokeWidth={2.097}
					rx={13.321}
					ry={40.796}
					transform='matrix(.29952 -.038 .0968 .23873 -208.27 -143.865)'
				/>
				<path
					strokeWidth={0.575}
					d='M8.62-15.768C12.407-6.513 13.94 1.81 12.04 2.81c-1.899.999-6.513-5.7-10.301-14.956'
				/>
				<ellipse
					cx={528.68}
					cy={646.07}
					strokeWidth={2.097}
					rx={13.321}
					ry={40.796}
					transform='matrix(.29952 -.038 .0968 .23873 -208.894 -154.737)'
				/>
				<ellipse
					cx={528.68}
					cy={646.07}
					strokeWidth={2.097}
					rx={13.321}
					ry={40.796}
					transform='matrix(.30032 -.0321 .09144 .24056 -212.194 -155.987)'
				/>
				<g strokeWidth={0.575}>
					<path d='M2.026-28.338c3.678 6.254 5.658 12.094 4.418 13.037-1.238.942-5.227-3.368-8.905-9.622' />
					<path d='M4.573-32.485C8.25-26.231 10.23-20.391 8.99-19.448c-1.238.942-5.227-3.368-8.905-9.622m15.928-2.537c5.125 8.543 7.883 16.52 6.154 17.81-1.727 1.29-7.288-4.598-12.413-13.14' />
					<path d='M8.452-36.082c3.693 6.904 5.687 13.348 4.45 14.385-1.237 1.036-5.236-3.727-8.929-10.632' />
				</g>
				<g fill='#9461c9'>
					<g strokeWidth={0.591}>
						<path d='M8.125-46.086c-2.466 2.482-.051 7.123 2.205 8.8 2.53 2.295 16.742 17.287 26.769 4.673-8.034-.146-23.027-19.004-28.974-13.473z' />
						<path d='M6.369-53.486c-2.465 2.483-.05 7.124 2.206 8.802 2.53 2.294 16.742 17.286 26.768 4.672-8.033-.146-23.026-19.005-28.974-13.474z' />
						<path d='M4.123-60.576c-2.466 2.482-.051 7.124 2.205 8.801 2.53 2.294 16.742 17.286 26.769 4.672-8.034-.146-23.027-19.004-28.974-13.473z' />
						<path d='M-.759-68.687c-2.465 2.483-.05 7.124 2.206 8.801 2.53 2.295 16.742 17.287 26.769 4.673C20.182-55.36 5.189-74.218-.76-68.687z' />
					</g>
					<path
						strokeWidth={0.628}
						d='M-18.272-76.448c5.08-3.78 14.412-4.884 21.681-1.183 6.056 2.966 16.148 15.073 23.735 17.935-5.34.952-8.59.147-12.002-1.492-6.739 4.88-9.14 5.611-16.008 6.57-9.551 1.378-17.48-1.108-20.266-6.09-2.839-4.699-1.615-12.238 2.86-15.74z'
					/>
				</g>
				<ellipse
					cx={287.23}
					cy={323.04}
					fill='#d41c30'
					rx={14.154}
					ry={14.986}
					transform='matrix(.3091 .0055 -.00301 .16933 -95.304 -125.19)'
				/>
				<ellipse
					cx={204.58}
					cy={348.26}
					fill='#fcd116'
					rx={23.254}
					ry={15.895}
					transform='matrix(.2458 -.09144 .08132 .21925 -99.445 -116.289)'
				/>
				<circle
					cx={283.9}
					cy={333.86}
					r={5.828}
					fill='currentColor'
					stroke='none'
					transform='matrix(.2699 .0048 -.00416 .23342 -82.956 -148.374)'
				/>
				<path
					fill='#fcd116'
					strokeWidth={0.732}
					d='M-17.128-65.515c.402 1.95-1.043 4.583-4.18 5.515-3.195 1.323-5.618 4.322-7.016 9.227-4.856-11.184-1.77-15.916 3.388-17.066 4.436-1.415 7.11-1.236 7.808 2.324z'
				/>
			</g>
		</g>
	</svg>
);
export default SvgRegionDominica;
