import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomBonusc = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		viewBox='0 0 512 512'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			d='M157.494 42.999h197.012v54.672H157.494z'
			style={{
				fill: '#ffb54e',
			}}
		/>
		<path
			d='M213.114 97.671h-55.62V42.999h31.153zM354.506 42.999v54.672h-55.62l24.467-54.672z'
			style={{
				fill: '#ff9a1b',
			}}
		/>
		<path
			d='m258.891 235.298-86.036-192.3h-42.342c-7.466 0-12.453 7.694-9.404 14.509l79.544 177.792h58.238z'
			style={{
				fill: '#ffce8b',
			}}
		/>
		<path
			d='M258.895 235.303h-21.943L150.911 42.999h21.943z'
			style={{
				fill: '#ffb54e',
			}}
		/>
		<path
			d='m253.109 235.298 86.036-192.301h42.342c7.466 0 12.453 7.694 9.404 14.509l-79.544 177.792h-58.238z'
			style={{
				fill: '#ffce8b',
			}}
		/>
		<path
			d='m390.892 57.504-79.54 177.799h-22.087l79.54-177.799c3.049-6.81-1.937-14.505-9.406-14.505h22.087c7.469 0 12.455 7.696 9.406 14.505z'
			style={{
				fill: '#ffb54e',
			}}
		/>
		<path
			d='M374.481 469.002a7.726 7.726 0 0 1-7.009-4.467 7.726 7.726 0 0 1 3.742-10.266c33.86-15.77 58.795-38.031 74.11-66.166 12.287-22.572 18.538-49.087 18.578-78.805.07-51.417-18.694-95.031-18.883-95.466a7.727 7.727 0 0 1 14.157-6.192c.823 1.878 20.137 46.626 20.179 101.188.04 51.132-17.557 120.3-101.618 159.449a7.686 7.686 0 0 1-3.256.725z'
			style={{
				fill: '#83b832',
			}}
		/>
		<path
			d='M452.432 150.892c8.582 24.851 10.865 60.983-.37 64.863-11.236 3.88-31.736-25.96-40.318-50.811-8.582-24.851-1.997-35.303 9.239-39.183 11.235-3.881 22.866.28 31.449 25.131z'
			style={{
				fill: '#bdeb73',
			}}
		/>
		<path
			d='M452.058 215.75c-2.614.906-5.738-.021-9.102-2.291 3.583-13.039.687-37.805-5.707-56.322-6.79-19.652-15.486-26.359-24.391-26.39 2.187-2.291 4.968-3.905 8.123-4.989 11.237-3.874 22.87.281 31.452 25.13 8.582 24.86 10.863 60.988-.375 64.862z'
			style={{
				fill: '#9ad63b',
			}}
		/>
		<path
			d='M472.044 312.189c-5.186 3.833-15.945-.989-27.192-9.571-9.488-7.248-19.329-17.163-26.484-26.838-15.632-21.152-12.477-33.087-2.916-40.158 9.29-6.863 21.214-6.592 36.232 12.851.437.552.864 1.125 1.302 1.719 15.621 21.14 28.618 54.925 19.058 61.997z'
			style={{
				fill: '#8ec737',
			}}
		/>
		<path
			d='M472.044 312.189c-5.186 3.833-15.945-.989-27.192-9.571-.615-4.437-.792-9.446-.531-15.007.573-12.393 3.281-26.64 7.363-39.138.437.552.864 1.125 1.302 1.719 15.621 21.14 28.618 54.925 19.058 61.997z'
			style={{
				fill: '#83b832',
			}}
		/>
		<path
			d='M467.238 248.204c-9.264 24.605-12.543 60.66-1.419 64.848s32.44-25.075 41.704-49.68c9.264-24.605 2.97-35.234-8.155-39.423-11.123-4.188-22.865-.35-32.13 24.255z'
			style={{
				fill: '#bdeb73',
			}}
		/>
		<path
			d='M507.526 263.376c-9.269 24.599-30.577 53.864-41.71 49.677-2.593-.968-4.395-3.676-5.53-7.561 11.102-7.644 24.787-28.671 31.723-47.084 7.301-19.402 4.936-30.108-1.843-35.826 3.072-.323 6.165.219 9.206 1.364 11.122 4.197 17.413 14.82 8.154 39.43z'
			style={{
				fill: '#9ad63b',
			}}
		/>
		<path
			d='M445.216 418.156c-6.28 1.479-14.257-7.207-21.204-19.538-5.863-10.394-10.987-23.401-13.747-35.107-6.02-25.588 1.593-35.326 13.164-38.044 11.258-2.645 22.11 2.312 28.234 26.12.177.677.344 1.364.51 2.072 6.009 25.599 4.614 61.769-6.957 64.497z'
			style={{
				fill: '#8ec737',
			}}
		/>
		<path
			d='M445.216 418.156c-6.28 1.479-14.257-7.207-21.204-19.538 1.177-4.322 2.989-8.988 5.426-13.997 5.426-11.164 13.539-23.172 22.225-33.035.177.677.344 1.364.51 2.072 6.009 25.6 4.614 61.77-6.957 64.498z'
			style={{
				fill: '#83b832',
			}}
		/>
		<path
			d='M466.051 357.466c-18.223 18.952-35.464 50.786-26.896 59.025 8.569 8.239 39.702-10.237 57.925-29.189 18.223-18.952 16.634-31.202 8.065-39.441-8.568-8.24-20.872-9.347-39.094 9.605z'
			style={{
				fill: '#bdeb73',
			}}
		/>
		<path
			d='M497.08 387.298c-18.225 18.954-49.354 37.43-57.926 29.192-1.323-1.271-2.031-3.114-2.208-5.384 13.226-3.885 32.139-16.83 44.814-30.004 17.746-18.454 16.705-30.556 8.727-38.794 5.853-.583 10.664 1.708 14.653 5.551 8.572 8.237 10.166 20.495-8.06 39.439z'
			style={{
				fill: '#9ad63b',
			}}
		/>
		<path
			d='M137.519 469.002a7.689 7.689 0 0 1-3.257-.724C50.202 429.129 32.605 359.961 32.645 308.829c.042-54.562 19.356-99.31 20.179-101.188a7.727 7.727 0 0 1 14.157 6.192c-.189.435-18.954 44.049-18.883 95.466.04 29.718 6.292 56.232 18.578 78.805 15.315 28.135 40.249 50.396 74.11 66.166a7.726 7.726 0 0 1-3.267 14.732z'
			style={{
				fill: '#83b832',
			}}
		/>
		<path
			d='M59.568 150.892c-8.582 24.851-10.865 60.983.37 64.863s31.736-25.96 40.318-50.811 1.997-35.303-9.239-39.183-22.866.28-31.449 25.131z'
			style={{
				fill: '#bdeb73',
			}}
		/>
		<path
			d='M100.256 164.948c-8.582 24.849-29.077 54.687-40.315 50.802-2.76-.948-4.707-3.843-5.936-8.05 10.935-7.821 24.172-29.39 30.64-48.115 6.478-18.767 4.312-29.327-1.979-35.024 2.77-.229 5.572.24 8.352 1.198 11.238 3.884 17.819 14.33 9.238 39.189z'
			style={{
				fill: '#9ad63b',
			}}
		/>
		<path
			d='M39.956 312.189c5.186 3.833 15.945-.989 27.192-9.571 9.488-7.248 19.329-17.163 26.484-26.838 15.632-21.152 12.477-33.087 2.916-40.158-9.29-6.863-21.214-6.592-36.232 12.851a63.105 63.105 0 0 0-1.302 1.719c-15.621 21.14-28.618 54.925-19.058 61.997z'
			style={{
				fill: '#8ec737',
			}}
		/>
		<path
			d='M93.632 275.779c-7.155 9.675-16.996 19.59-26.484 26.838-11.248 8.582-22.006 13.404-27.192 9.571-1.406-1.041-2.333-2.676-2.822-4.749 4.353-1.896 9.259-4.968 14.257-8.779 9.488-7.249 19.329-17.163 26.484-26.838 15.382-20.808 12.57-32.702 3.374-39.804 5.738-1.354 10.831.302 15.299 3.604 9.561 7.07 12.716 19.006-2.916 40.157z'
			style={{
				fill: '#83b832',
			}}
		/>
		<path
			d='M39.956 312.189c5.186 3.833 15.945-.989 27.192-9.571.614-4.437.792-9.446.531-15.007-.573-12.393-3.281-26.64-7.363-39.138a63.105 63.105 0 0 0-1.302 1.719c-15.621 21.14-28.618 54.925-19.058 61.997z'
			style={{
				fill: '#83b832',
			}}
		/>
		<path
			d='M44.762 248.204c9.264 24.605 12.543 60.66 1.419 64.848s-32.44-25.075-41.704-49.68c-9.264-24.605-2.97-35.234 8.155-39.423s22.865-.35 32.13 24.255z'
			style={{
				fill: '#bdeb73',
			}}
		/>
		<path
			d='M46.184 313.053c-2.802 1.052-6.249-.01-9.987-2.593 4.062-12.56.479-38.732-6.749-57.925-6.592-17.496-14.424-24.485-22.402-25.557 1.645-1.271 3.53-2.26 5.582-3.031 11.123-4.187 22.87-.344 32.129 24.255 9.269 24.609 12.549 60.664 1.427 64.851z'
			style={{
				fill: '#9ad63b',
			}}
		/>
		<path
			d='M66.784 418.156c6.28 1.479 14.257-7.207 21.204-19.538 5.863-10.394 10.987-23.401 13.747-35.107 6.02-25.588-1.593-35.326-13.164-38.044-11.258-2.645-22.11 2.312-28.234 26.12a83.894 83.894 0 0 0-.51 2.072c-6.009 25.599-4.614 61.769 6.957 64.497z'
			style={{
				fill: '#8ec737',
			}}
		/>
		<path
			d='M101.735 363.511c-2.76 11.706-7.884 24.714-13.747 35.107-6.946 12.331-14.924 21.017-21.204 19.538-2.958-.698-5.249-3.583-6.926-7.915 4.062-3.468 8.28-9.238 12.164-16.143 5.863-10.394 10.987-23.401 13.747-35.107 4.364-18.538 1.562-28.765-4.707-34.014 2.447-.312 4.968-.104 7.509.489 11.57 2.719 19.183 12.457 13.164 38.045z'
			style={{
				fill: '#83b832',
			}}
		/>
		<path
			d='M66.784 418.156c6.28 1.479 14.257-7.207 21.204-19.538-1.177-4.322-2.989-8.988-5.426-13.997-5.426-11.164-13.539-23.172-22.225-33.035a83.894 83.894 0 0 0-.51 2.072c-6.009 25.6-4.614 61.77 6.957 64.498z'
			style={{
				fill: '#83b832',
			}}
		/>
		<path
			d='M45.949 357.466c18.223 18.952 35.464 50.786 26.896 59.025s-39.702-10.237-57.925-29.189C-3.303 368.35-1.714 356.099 6.855 347.86s20.872-9.346 39.094 9.606z'
			style={{
				fill: '#bdeb73',
			}}
		/>
		<path
			d='M72.845 416.49c-2.739 2.635-7.79 2.541-13.987.562 2.302-11.821-12.935-38.367-28.921-54.989-8.748-9.102-16.132-13.57-22.391-14.851 8.529-7.634 20.662-8.196 38.398 10.258 18.226 18.945 35.472 50.782 26.901 59.02z'
			style={{
				fill: '#9ad63b',
			}}
		/>
		<circle
			cx={256}
			cy={341.677}
			r={123.199}
			style={{
				fill: '#f37d7e',
			}}
		/>
		<circle
			cx={256}
			cy={341.677}
			r={105.907}
			style={{
				fill: '#ed6264',
			}}
		/>
		<circle
			cx={256}
			cy={341.677}
			r={91.484}
			style={{
				fill: '#65bafc',
			}}
		/>
		<path
			d='M347.48 341.677c0 50.53-40.95 91.48-91.48 91.48-3.75 0-7.448-.227-11.074-.67 45.307-5.46 80.406-44.03 80.406-90.81s-35.098-85.35-80.406-90.81a91.33 91.33 0 0 1 11.074-.67c50.53 0 91.48 40.949 91.48 91.48z'
			style={{
				fill: '#2ea8fc',
			}}
		/>
		<path
			d='m304.295 333.508-20.408 19.893 4.811 28.093c.721 4.203-3.688 7.417-7.469 5.429L256 373.654l-25.229 13.269c-3.781 1.988-8.19-1.226-7.469-5.429l2.266-13.248 2.545-14.845-20.408-19.893c-3.049-2.977-1.37-8.169 2.854-8.787l28.206-4.1 12.62-25.559c1.885-3.822 7.345-3.822 9.23 0l12.62 25.559 11.878 1.731 16.328 2.369c4.224.618 5.903 5.81 2.854 8.787z'
			style={{
				fill: '#ffce8b',
			}}
		/>
		<path
			d='m304.295 333.508-20.408 19.893 4.811 28.093c.721 4.203-3.688 7.417-7.469 5.429L256 373.654l-25.229 13.269c-3.781 1.988-8.19-1.226-7.469-5.429l2.266-13.248c37.849-10.477 53.302-31.709 59.544-45.895l16.328 2.369c4.225.619 5.904 5.811 2.855 8.788z'
			style={{
				fill: '#ffb54e',
			}}
		/>
	</svg>
);
export default SvgCustomBonusc;
