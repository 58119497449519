import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomFair = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		aria-hidden='true'
		className='svg-icon'
		viewBox='0 0 36 36'
		{...props}>
		<path
			fill='#66757F'
			d='M3.923 22.923a1 1 0 1 1-1.847-.769L6.693 9.616a1 1 0 1 1 1.846.769L3.923 22.923z'
		/>
		<path
			fill='#66757F'
			d='M13.923 22.154a1 1 0 0 1-1.847.769L7.461 10.385a1 1 0 0 1 1.847-.769l4.615 12.538zm10.001.769a1.001 1.001 0 0 1-1.847-.769l4.616-12.539a1 1 0 0 1 1.846.77l-4.615 12.538z'
		/>
		<path
			fill='#66757F'
			d='M33.923 22.154a1 1 0 1 1-1.847.769l-4.615-12.538a1 1 0 1 1 1.846-.769l4.616 12.538z'
		/>
		<path
			fill='#FFAC33'
			d='M14.857 22H1.143C.512 22 0 22.511 0 23.143c0 2.524 3.582 4.571 8 4.571s8-2.047 8-4.571c0-.632-.512-1.143-1.143-1.143zM24 34H12a1 1 0 0 1-1-1c0-2.209 3.134-4 7-4s7 1.791 7 4a1 1 0 0 1-1 1zm10.857-12H21.143c-.632 0-1.143.511-1.143 1.143 0 2.524 3.581 4.571 8 4.571s8-2.047 8-4.571c0-.632-.511-1.143-1.143-1.143z'
		/>
		<path fill='#FFAC33' d='M19 3a1 1 0 1 0-2 0v27a1 1 0 1 0 2 0V3z' />
		<circle cx={18} cy={4} r={2} fill='#FFAC33' />
		<circle cx={8} cy={10} r={2} fill='#FFAC33' />
		<circle cx={28} cy={10} r={2} fill='#FFAC33' />
		<path fill='#FFAC33' d='M28 10c0 1.104 0 0-10 0S8 11.104 8 10s3-4 10-4 10 2.896 10 4z' />
	</svg>
);
export default SvgCustomFair;
