import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionSaintBarthlemy = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 3 2'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#EC1920' d='M0 0h3v2H0z' />
		<path fill='#fff' d='M0 0h2v2H0z' />
		<path fill='#051440' d='M0 0h1v2H0z' />
	</svg>
);
export default SvgRegionSaintBarthlemy;
