import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomGames = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		aria-hidden='true'
		className='svg-icon'
		viewBox='0 0 64 64'
		{...props}>
		<path
			fill='#d0d0d0'
			d='M60.6 41.9c1.2-2.5 1.7-4.7 1.2-5-.3-.1-1 .5-1.7 1.6l-8.1-14L30 5.1l-5.1-.8c1.5-.9 2.4-1.7 2.2-2.1-.2-.5-2.5 0-5 1.2s-4.3 2.5-4.1 3c.1.2.7.2 1.5.1C18.1 9 10.6 22 10.6 22L8.8 47.5l1.1 1.1c-.6-.3-1-.5-1.2-.3-.4.4.9 2.3 2.8 4.3 2 2 3.9 3.2 4.3 2.8.2-.2 0-.8-.6-1.6L31 56.4l23.1-8.5 3.6-4.1c-.4 1.2-.4 2.1-.1 2.3.5.1 1.8-1.7 3-4.2'
		/>
		<g fill='#e8e8e8'>
			<path d='M44.1 59.8c-1.9 1.9-5.5 2.7-8.2 1.9l-19.7-6c-2.7-.8-3.3-3-1.5-4.8l13.7-13.7c1.9-1.9 5.5-2.7 8.2-1.9l19.7 6c2.7.8 3.3 3 1.5 4.8L44.1 59.8M4.2 19.9c-1.9 1.9-2.7 5.5-1.9 8.2l6 19.7c.8 2.7 3 3.3 4.8 1.5l13.7-13.7c1.9-1.9 2.7-5.5 1.9-8.2l-6-19.7c-.8-2.7-3-3.3-4.8-1.5L4.2 19.9' />
		</g>
		<path
			fill='#f5f5f5'
			d='M55 15.7C54 13 51 10 48.3 9L27.8 2.3c-2.8-.9-4.3.6-3.4 3.4l6.8 20.4c.9 2.8 3.9 5.8 6.7 6.7l20.4 6.8c2.8.9 4.3-.6 3.4-3.4L55 15.7'
		/>
		<path
			fill='#ed4c5c'
			d='M41.1 18.1c-.7 0-1.2.3-1.4.8-.3 1.1.9 2.8 2.8 3.9.9.5 1.8.8 2.5.8s1.2-.3 1.4-.8c.3-1.1-.9-2.8-2.8-3.9-.8-.5-1.8-.8-2.5-.8'
		/>
		<g fill='#333'>
			<path d='M29 48.7c.7.2 1.1.7 1 1.2-.1 1.1-1.8 2.4-3.9 2.8-1.1.2-2 .2-2.7-.1-.7-.2-1.1-.7-1-1.2.1-1.1 1.8-2.4 3.9-2.8 1.1-.2 2.1-.2 2.7.1M39.2 46.4c.7.2 1.1.7 1 1.2-.1 1.1-1.8 2.4-3.9 2.8-1.1.2-2 .2-2.7-.1-.7-.2-1.1-.7-1-1.2.1-1.1 1.8-2.4 3.9-2.8 1.1-.2 2.1-.2 2.7.1M49 44.4c.7.2 1.1.7 1 1.2-.1 1.1-1.8 2.4-3.9 2.8-1.1.2-2 .2-2.7-.1-.7-.2-1.1-.7-1-1.2.1-1.1 1.8-2.4 3.9-2.8 1.1-.2 2-.1 2.7.1' />
		</g>
		<g fill='#333'>
			<path d='M21 26.1c.6-.4 1.1-.5 1.6-.2.9.7 1 2.8.2 4.8-.4 1-.9 1.8-1.5 2.2-.6.4-1.1.5-1.6.2-.9-.7-1-2.8-.2-4.8.4-1 1-1.8 1.5-2.2M9.7 22.6c.6-.4 1.1-.5 1.6-.2.9.7 1 2.8.2 4.8-.4 1-.9 1.8-1.5 2.2-.6.4-1.1.5-1.6.2-.9-.7-1-2.8-.2-4.8.4-1 .9-1.7 1.5-2.2' />
		</g>
	</svg>
);
export default SvgCustomGames;
