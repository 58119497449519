import { createSelector } from "reselect";
import { RootState } from "../../store";
import Helpers from "../../hooks/helperFunctions";
import { getGamePeriod } from "./sportHelper/GameTimeHelper";
import { getEventTypes, groupMarketsByType } from "./sportHelper";
import dayjs from "dayjs";
// Selector to get the entire WebSocket state
const getSportWebSocketState = (state: RootState) => state.sportWebSocket;

// Selector to get the connection status
export const getSportConnected = createSelector(
  getSportWebSocketState,
  (state) => state.sportConnected
);

// Selector to get the session loading status
export const getSessionLoad = createSelector(
  getSportWebSocketState,
  (state) => state.sessionLoad
);

export const getSocketConnected = createSelector(
  getSportWebSocketState,
  (state) => state.sportConnected
);

const getResponseData = (state: RootState) => state.sportWebSocket.responseData;

export const getCountDataBySubId = (subId: string | undefined) =>
  createSelector(getResponseData, (responseData) =>
    subId ? responseData[subId] : null
  );

export const eventDataSelector = (state: RootState, subscriptionId: string) =>
  state.sportWebSocket.responseData[subscriptionId];

export const getSportEvents = createSelector([eventDataSelector], (data) => {
  if (!data || !data.sport) {
    return [];
  }

  return Helpers.objectToArray(data.sport)
    .sort(Helpers.byOrderSortingFunc)
    .map((sport: any) => {
      if (!sport) {
        return null;
      }
      if (!sport || !sport.region) {
        return null;
      }

      const regions = Helpers.objectToArray(sport.region)
        .sort(Helpers.byOrderSortingFunc)
        .map((region: any) => {
          // region.alias replace space and lowercase
          const flag = region.alias
            ? region.alias.toLowerCase().replace(/\s+/g, "")
            : "";
          if (!region.competition)
            return { ...region, flag, competitions: [], gameCount: 0 };

          const competitions = Helpers.objectToArray(region.competition)
            .sort(Helpers.byOrderSortingFunc)
            .map((competition: any) => {
              if (!competition.game || typeof competition.game === "number")
                return {
                  ...competition,
                  games: [],
                  gameCount: competition.game || 0,
                };

              const gameList = Helpers.objectToArray(competition.game);
              const games = editValues(gameList, sport.alias, "WINNER");

              return { ...competition, games, gameCount: games.length };
            });

          return {
            ...region,
            flag,
            competitions,
            gameCount: competitions.reduce(
              (sum, comp) => sum + comp.gameCount,
              0
            ),
          };
        });

      return {
        ...sport,
        regions,
        gameCount: regions.reduce((sum, reg) => sum + reg.gameCount, 0),
      };
    });
});

export const getEventsFromSports = createSelector(
  [eventDataSelector],
  (data: any) => {
    if (!data || !data.sport) {
      return [];
    }
    const eventsList: any = [];
    Helpers.objectToArray(data.sport).forEach((sport: any) => {
      if (!sport.region) {
        return [];
      }
      Helpers.objectToArray(sport.region).forEach((region: any) => {
        region.flag = region.alias.toLowerCase().replace(/\s+/g, "");
        if (!region.competition) {
          return [];
        }
        Helpers.objectToArray(region.competition).forEach(
          (competition: any) => {
            if (!competition.game) {
              return [];
            }
            Helpers.objectToArray(competition.game).forEach((game: any) => {
              const { markets, marketGroups } = groupMarketsByType({
                marketList: game.market,
                team1Name: game.team1_name,
                team2Name: game.team2_name,
                sportAlias: sport.alias,
                statisticsAvailable: game.is_stat_available,
              });
              const selectedMarket = markets.find(
                (m: any) => m.display_key === "WINNER"
              );
              eventsList.push({
                ...game,
                competition,
                region,
                sport,
                selectedMarket,
                markets,
                marketGroups,
              });
            });
          }
        );
      });
    });
    return eventsList;
  }
);

export const getPrematchMenu = createSelector(eventDataSelector, (data) => {
  if (!data || !data.sport) {
    return [];
  }

  return Helpers.objectToArray(data.sport)
    .sort(Helpers.byOrderSortingFunc)
    .map((sport: any) => {
      const regions = Helpers.objectToArray(sport.region)
        .sort(Helpers.byOrderSortingFunc)
        .map((region: any) => {
          const flag = region.alias
            ? region.alias.toLowerCase().replace(/\s+/g, "")
            : "";
          const competitions = Helpers.objectToArray(region.competition)
            .sort(Helpers.byOrderSortingFunc)
            .map((competition: any) => {
              return { ...competition, gameCount: competition.game };
            });

          // Bölge seviyesinde oyun sayısını hesapla
          return {
            ...region,
            flag,
            competitions,
            gameCount: competitions.reduce(
              (sum, comp) => sum + comp.gameCount,
              0
            ),
          };
        });

      // Spor seviyesinde oyun sayısını hesapla
      return {
        ...sport,
        regions,
        gameCount: regions.reduce((sum, reg) => sum + reg.gameCount, 0),
      };
    });
});

export const getPopularTournaments = createSelector(
  [eventDataSelector],
  (tournamentData) => {
    if (!tournamentData || !tournamentData.sport) {
      return [];
    }
    return Helpers.objectToArray(tournamentData.sport)
      .flatMap((sport: any) =>
        Helpers.objectToArray(sport.region).flatMap((region: any) =>
          Helpers.objectToArray(region.competition).map((competition: any) => ({
            ...competition,
            flag: region.alias
              ? region.alias.toLowerCase().replace(/\s+/g, "")
              : "",
            regionAlias: region.alias,
            regionName: region.name,
            sportName: sport.name,
            sportId: sport.id,
            sportAlias: sport.alias,
            order: competition.order,
            favorite_order: competition.favorite_order,
          }))
        )
      )
      .filter((x: any) => x.game > 0)
      .sort((a: any, b: any) => {
        if (a.favorite_order === b.favorite_order) {
          return b.order - a.order; // Ters sıralama
        }
        return a.favorite_order - b.favorite_order;
      });
  }
);

export const getMarketDetails = createSelector(
  [
    eventDataSelector,
    (_, __, gameId) => gameId,
    (_, __, ___, sport) => sport,
    (_, __, ___, ____, displayKey) => displayKey,
  ], // İlk input selector veriyi çeker, ikinci input selector gameId'yi sağlar.
  (marketData, gameId, sport, displayKey) => {
    if (!marketData || !marketData.game) {
      return null; // Eğer uygun veri yoksa boş dizi döndür.
    }
    const gameList: any = Helpers.objectToArray(marketData.game);

    const gameDetails = gameList.find((game: any) => game.id === gameId);
    if (!gameDetails) {
      return null;
    }

    gameDetails.gamePeriod = getGamePeriod(
      gameDetails?.info?.current_game_state,
      sport.alias
    );

    const eventTypes = getEventTypes(displayKey, gameList);

    const { markets, marketGroups } = groupMarketsByType({
      marketList: gameDetails.market,
      team1Name: gameDetails.team1_name,
      team2Name: gameDetails.team2_name,
      sportAlias: gameDetails.sport_alias,
      statisticsAvailable: gameDetails.is_stat_available,
    });

    return {
      ...gameDetails,
      eventTypes,
      markets: markets,
      marketGroups,
      displayKey: displayKey,
      selectedMarket: markets.find(
        (market: any) => market.display_key === displayKey
      ),
    };
  }
);

export const getSingleEventDetails = createSelector(
  [eventDataSelector],
  (eventData: any) => {
    if (!eventData || !eventData.sport) {
      return null;
    }

    const sport: any = Helpers.objectToArray(eventData.sport)[0];
    if (!sport || !sport.region) return null;
    const region: any = Helpers.objectToArray(sport.region)[0];
    const competition: any = Helpers.objectToArray(region.competition)[0];
    const game: any = Helpers.objectToArray(competition.game)[0];

    game.gamePeriod = getGamePeriod(
      game.info.current_game_state,
      game.sport_alias
    );
    const sportAlias = game.sport_alias;
    const statisticsAvailable = game.statisticsAvailable;

    const { markets, marketGroups } = groupMarketsByType({
      marketList: game.market,
      team1Name: game.team1_name,
      team2Name: game.team2_name,
      sportAlias,
      statisticsAvailable,
    });

    return {
      ...game,
      sport,
      region,
      competition,
      markets,
      marketGroups,
    };
  }
);

export const getSportWithGames = createSelector(
  [
    eventDataSelector,
    (_, __, selectedKey) => selectedKey,
    (_, __, ___, selectedSportId) => selectedSportId,
    // (_, __, ___, ____, displayKey) => displayKey,
  ],
  (eventData: any, displayKey, selectedSportId) => {
    if (!eventData || !eventData.sport) {
      return null;
    }

    let totalGameCount = 0;

    const sportsList: any = Helpers.objectToArray(eventData.sport)
      .sort(Helpers.byOrderSortingFunc)
      .map((sport: any) => {
        const games = Helpers.objectToArray(sport.game)
          .sort(Helpers.byOrderSortingFunc)
          .map((game: any) => {
            game.gamePeriod = getGamePeriod(
              game?.info?.current_game_state,
              game.sport_alias
            );
            const { markets, marketGroups } = groupMarketsByType({
              marketList: game.market,
              team1Name: game.team1_name,
              team2Name: game.team2_name,
              sportAlias: sport.sport_alias,
              statisticsAvailable: game.is_stat_available,
            });
            const selectedMarket = markets.find(
              (m: any) => m.display_key === displayKey
            );
            return {
              ...game,
              selectedMarket,
              markets,
              marketGroups,
            };
          });

        const eventTypes = getEventTypes(displayKey, games);
        sport.gameCount = games.length;
        return { ...sport, eventTypes, games };
      });

    const selectedSport = sportsList.find(
      (x: any) => x?.id === selectedSportId || selectedSportId?.id
    );
    const eventTypes = getEventTypes(displayKey, selectedSport?.games || []);

    return { sports: sportsList, selectedSport, eventTypes, totalGameCount };
  }
);

export const getSportsList = createSelector(
  [
    eventDataSelector,
  ],
  (eventData: any) => {
    if (!eventData) {
      return null;
    }



    const sportsList: any = Helpers.objectToArray(eventData.sport)
      .sort(Helpers.byOrderSortingFunc)
      .map((sport: any) => {
        const competitions = Helpers.objectToArray(sport.competition);
        return { ...sport, totalGames: sport.game, competitions };
      });

    return { sports: sportsList };
  }
);

export const getGameResult = createSelector(
  [eventDataSelector],
  (eventData: any) => {
    if (!eventData) {
      return null;
    }

    return eventData;
  }
);

export const getSportWithGamesRegion = createSelector(
  [
    eventDataSelector,
    (_, __, selectedKey) => selectedKey,
    (_, __, ___, selectedSportId) => selectedSportId,
    // (_, __, ___, ____, displayKey) => displayKey,
  ],
  (eventData: any, displayKey, selectedSportId) => {
    if (!eventData || !eventData.sport) {
      return null;
    }

    let totalGameCount = 0;

    const sportsList: any = Helpers.objectToArray(eventData.sport)
      .sort(Helpers.byOrderSortingFunc)
      .map((sport: any) => {
        const regions = Helpers.objectToArray(sport.region)
          .sort(Helpers.byOrderSortingFunc)
          .map((region: any) => {
            const competitions = Helpers.objectToArray(region.competition)
              .sort(Helpers.byOrderSortingFunc)
              .map((competition: any) => {
                const games = Helpers.objectToArray(competition.game)
                  .sort(Helpers.byOrderSortingFunc)
                  .map((game: any) => {
                    game.gamePeriod = getGamePeriod(
                      game?.info?.current_game_state,
                      selectedSportId?.alias
                    );

                    const { markets, marketGroups } = groupMarketsByType({
                      marketList: game.market,
                      team1Name: game.team1_name,
                      team2Name: game.team2_name,
                      sportAlias: sport.sport_alias,
                      statisticsAvailable: game.is_stat_available,
                    });
                    const selectedMarket = markets.find(
                      (m: any) => m.display_key === displayKey
                    );
                    return {
                      ...game,
                      selectedMarket,
                      region: region,
                      competition: competition,
                      sport: sport,
                      markets,
                      marketGroups,
                    };
                  });

                const gameCount = games.length;
                totalGameCount += gameCount;
                const eventTypes = getEventTypes(displayKey, games);
                return { ...competition, games, eventTypes, gameCount };
              });

            return { ...region, competitions };
          });

        const games = regions.flatMap((region: any) =>
          region.competitions.flatMap((competition: any) => competition.games)
        );

        sport.gameCount = games.length;
        return { ...sport, regions, games };
      });

    const selectedSport = sportsList.find(
      (x: any) => x?.id === selectedSportId?.id
    );
    const eventTypes = getEventTypes(displayKey, selectedSport?.games || []);

    return { sports: sportsList, selectedSport, eventTypes, totalGameCount };
  }
);

export const getDisplayGames = createSelector(
  [
    eventDataSelector,
    (_, __, selectedKey) => selectedKey,
    (_, __, ___, mType?) => mType,

    // (_, __, ___, ____, displayKey) => displayKey,
  ],
  (eventData: any, displayKey, mType?) => {
    if (!eventData || !eventData.game) return null;
    const games = Helpers.objectToArray(eventData.game).map((game: any) => {
      game.gamePeriod = getGamePeriod(
        game?.info?.current_game_state,
        game.sport_alias
      );
      const { markets, marketGroups } = groupMarketsByType({
        marketList: game.market,
        team1Name: game.team1_name,
        team2Name: game.team2_name,
        sportAlias: game.sport_alias,
        statisticsAvailable: game.is_stat_available,
      });
      const selectedMarket = markets.find((m: any) =>
        mType ? m.type === displayKey.BasaltKind : m.display_key === displayKey
      );
      return {
        ...game,
        selectedMarket,
        markets,
        marketGroups,
      };
    });
    const eventTypes = getEventTypes(displayKey, games);

    return { eventTypes, games };
  }
);

export const getSportWithGamesR = createSelector(
  [
    eventDataSelector,
    (_, __, selectedKey) => selectedKey,
    (_, __, ___, selectedSportId) => selectedSportId,
    // (_, __, ___, ____, displayKey) => displayKey,
  ],
  (eventData: any, displayKey, selectedSportId) => {
    if (!eventData || !eventData.sport) {
      return null;
    }
    const sportsList: any = Helpers.objectToArray(eventData.sport)
      .sort(Helpers.byOrderSortingFunc)
      .map((sport: any) => {
        sport.gameCount = 0;

        return Helpers.objectToArray(sport.region)
          .sort(Helpers.byOrderSortingFunc)
          .map((region: any) => {
            region.gameCount = 0;

            return Helpers.objectToArray(region.competition)
              .sort(Helpers.byOrderSortingFunc)
              .map((competition: any) => {
                competition.gameCount = 0;

                const games = Helpers.objectToArray(competition.game)
                  .sort(Helpers.byOrderSortingFunc)
                  .map((game: any) => {
                    const { markets, marketGroups } = groupMarketsByType({
                      marketList: game.market,
                      team1Name: game.team1_name,
                      team2Name: game.team2_name,
                      sportAlias: sport.sport_alias,
                      statisticsAvailable: game.is_stat_available,
                    });
                    const selectedMarket = markets.find(
                      (m: any) => m.display_key === displayKey
                    );
                    sport.gameCount++;
                    region.gameCount++;
                    competition.gameCount++;
                    return {
                      ...game,
                      selectedMarket,
                      markets,
                      marketGroups,
                    };
                  });
                return { ...competition, games };
              });
          });

        // const games = Helpers.objectToArray(sport.game)
        //   .sort(Helpers.byOrderSortingFunc)
        //   .map((game: any) => {
        //     const { markets, marketGroups } = groupMarketsByType({
        //       marketList: game.market,
        //       team1Name: game.team1_name,
        //       team2Name: game.team2_name,
        //       sportAlias: sport.sport_alias,
        //       statisticsAvailable: false,
        //     });
        //     const selectedMarket = markets.find(
        //       (m: any) => m.display_key === displayKey
        //     );
        //     sport.gameCount++;
        //     return {
        //       ...game,
        //       selectedMarket,
        //       markets,
        //       marketGroups,
        //     };
        //   });
        // return { ...sport, games };
      });

    const selectedSport = sportsList.find(
      (x: any) => x.id === selectedSportId?.id
    );
    const eventTypes = getEventTypes(displayKey, selectedSport?.games);

    return { sports: sportsList, selectedSport, eventTypes };
  }
);

const getFormattedDate = (timestamp: any) => {
  const date = new Date(timestamp * 1000);

  // dayjs ile tarihi 'günün başlangıcı'na yuvarla ve milisaniye olarak döndür
  const startOfDayUnitTime = dayjs(date).startOf("day").valueOf();

  // Tarihi 'dd.mm.yyyy' formatında dize olarak döndür
  const dayText = dayjs(date).format("DD.MM.YYYY");

  return { dayText, startOfDayUnitTime };
};

export const getPrematchMatch = createSelector(
  [eventDataSelector],
  (tournamentData: any) => {
    if (!tournamentData?.sport) {
      return {};
    }

    const sports: any = Helpers.objectToArray(tournamentData.sport);
    if (!sports?.length) return null;

    const sport: any = sports[0];
    if (!sport.region) return null;

    const regions: any = Helpers.objectToArray(sport.region);
    if (!regions?.length) return null;

    const region: any = regions[0];
    if (!region.competition) return null;

    const competitions: any = Helpers.objectToArray(region.competition);
    if (!competitions?.length) return null;

    const competition: any = competitions[0];

    const games: any = Helpers.objectToArray(competition.game).sort(
      (a: any, b: any) => a.start_ts - b.start_ts
    );

    const gameDates: any = games.reduce((acc: any, game: any) => {
      const gameDate: any = getFormattedDate(game.start_ts);
      const startDay = gameDate.dayText;
      if (!acc[startDay]) {
        acc[startDay] = {
          date: startDay,
          dateMs: gameDate.startOfDayUnitTime,
          games: [],
        };
      }
      // Oyunlar sıralanırken gerekli bilgileri de ekleyin
      acc[startDay].games.push({
        ...game,

        sportAlias: sport.alias,
        regionAlias: region.alias,
        competitionId: competition.id,
        competitionName: competition.name,
      });
      return acc;
    }, {});

    return {
      sport: sport,
      region: region,
      competition: competition,
      sportAlias: sport.alias,
      sportName: sport.name,
      regionAlias: region.alias,
      flag: region.alias ? region.alias.toLowerCase().replace(/\s+/g, "") : "",
      regionName: region.name,
      competitionName: competition.name,
      competitionId: competition.id,
      gameDates: Object.values(gameDates).sort(
        (a: any, b: any) => a.dateMs - b.dateMs
      ),
    };
  }
);

export const getMarketData = createSelector(
  [
    eventDataSelector,
    (_, __, marketType) => marketType,
    (_, __, ___, sportAlias) => sportAlias,
    (_, __, ___, ____, gameData) => gameData,
  ],

  (eventData: any, marketType: any, sportAlias, gameData) => {
    if (!eventData || !eventData.game) {
      return null;
    }

    const gameList = Helpers.objectToArray(eventData.game).map((game: any) => {
      const markets = Helpers.objectToArray(game.market).map((market: any) => {
        if (!market.event) {
          return { ...market, events: [] };
        }
        return {
          ...market,
          events: Helpers.objectToArray(market.event).map(
            (event: any) => event
          ),
        };
      });

      return { ...game, markets, ...gameData };
    });

    return editValues(gameList, sportAlias, marketType);
  }
);

const editValues = (gameList: any, sportAlias: any, marketType: any) => {
  return gameList.map((game: any) => {
    const { markets, marketGroups } = groupMarketsByType({
      marketList: game.market,
      team1Name: game.team1_name,
      team2Name: game.team2_name,
      sportAlias,
      statisticsAvailable: game.is_stat_available,
    });

    let eventTypes, selectedMarket;

    game.gamePeriod = getGamePeriod(game?.info?.current_game_state, sportAlias);

    if (typeof marketType === "string") {
      selectedMarket = markets.find((m: any) => m.display_key === marketType);
      eventTypes = getEventTypes(marketType, gameList);
    } else {
      selectedMarket = markets.find(
        (m: any) => m.type === marketType?.BasaltKind
      );
      eventTypes = marketType.SelectionTypesNames;
    }

    return { ...game, markets, marketGroups, selectedMarket, eventTypes };
  });
};

export const getSingleEventMarketData = createSelector(
  [eventDataSelector],
  (eventData: any) => {
    if (!eventData || !eventData.game) {
      return null;
    }

    const game: any = Helpers.objectToArray(eventData.game)[0];
    if (!game) {
      return null;
    }

    const market: any = Helpers.objectToArray(game.market)[0];
    if (!market) {
      return null;
    }

    const event = Helpers.objectToArray(market.event)[0];
    if (!event) {
      return null;
    }

    return { ...game, market, event };
  }
);
