import * as React from 'react';
import { SVGProps } from 'react';
const SvgGmt = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#DBB358' />
			<g fill='#FFF'>
				<path d='M21.246 11.66a.555.555 0 0 1 .015 1.11H11.31v6.46h9.382v-2.77H14.39a.555.555 0 0 1-.555-.54v-.015c0-.301.24-.547.54-.555h6.872c.302 0 .547.24.555.54v3.896c0 .302-.24.547-.54.555H10.754a.555.555 0 0 1-.555-.54v-7.585c0-.301.24-.547.54-.554h10.507z' />
				<path d='M16 5C9.925 5 5 9.925 5 16s4.925 11 11 11 11-4.925 11-11S22.075 5 16 5zm0 1.11c5.462 0 9.89 4.428 9.89 9.89 0 5.462-4.428 9.89-9.89 9.89-5.462 0-9.89-4.428-9.89-9.89 0-5.462 4.428-9.89 9.89-9.89z' />
				<path d='M17.61 8.402c.302 0 .547.24.555.54v3.273c0 .302-.24.547-.54.555h-3.237a.555.555 0 0 1-.554-.54V8.957a.555.555 0 0 1 1.11-.015v2.718h2.111V8.957c0-.302.24-.547.54-.555h.015zm0 10.828c.302 0 .547.24.555.54v3.273a.555.555 0 0 1-1.11.015V20.34h-2.11v2.703a.555.555 0 0 1-.541.555h-.015a.555.555 0 0 1-.555-.54v-3.273c0-.302.24-.547.54-.555h3.236z' />
			</g>
		</g>
	</svg>
);
export default SvgGmt;
