type ArrayElement = {
  weight?: number;
  ignore?: boolean;
  [key: string]: any;
};

const Helpers = {
  getWeightedRandom: function (array: ArrayElement[], weightFieldName: string = 'weight'): ArrayElement | undefined {
    const variants: ArrayElement[] = [];
    array.forEach(item => {
      if (item.ignore) {
        return;
      }
      for (let i = 0; i < (item[weightFieldName] || 1); i++) {
        variants.push(item);
      }
    });

    const index = Math.floor(Math.random() * variants.length);
    return variants[index];
  },

  cloneDeep: function<T>(data: T): T {
    return JSON.parse(JSON.stringify(data));
  },

  byOrderSortingFunc: function (a: any, b: any): number {
    return a.order - b.order;
  },

  orderByPrice: function (a: { price: number }, b: { price: number }): number {
    return a.price - b.price;
  },

  orderByName: function (a: { name: string }, b: { name: string }): number {
    let nameA = a.name.toUpperCase(),
        nameB = b.name.toUpperCase();
    if (nameA > nameB) {
      return 1;
    } else if (nameA < nameB) {
      return -1;
    }
    return 0;
  },

  byStartTsSortingFunc: function (a: { start_ts: number }, b: { start_ts: number }): number {
    return a.start_ts - b.start_ts;
  },

  createSortingFn: function (field: string, ascending: boolean = true): (a: Record<string, any>, b: Record<string, any>) => number {
    return (a, b) => ((a[field] - b[field]) * (ascending ? 1 : -1));
  },

  objectToArray: function<T extends object>(obj: { [key: string]: T } | null | undefined, addKeyNameAsProperty: string | null = null, calculator?: (item: T) => void): T[] {
    if (!obj) {
      // Eğer obj null veya undefined ise boş bir dizi döner.
      return [];
    }
    return Object.keys(obj).filter(key => obj[key] !== null).map(key => {
      const item: any = { ...obj[key] };
      if (addKeyNameAsProperty) {
        item[addKeyNameAsProperty] = key;
      }
      if (calculator) {
        calculator(item);
      }
      return item;
    });
  },

  firstElement: function<T>(obj: { [key: string]: T }, sortFunc?: ((a: T, b: T) => number) | false): T | any {
    if (sortFunc === false || !Object.keys(obj).length) {
      return null;
    }
    const array = Object.values(obj);
    if (sortFunc) {
      array.sort(sortFunc);
    }
    return array[0];
  },

  mergeRecursive: function (to: any, from: any): any {
    for (let p in from) {
      if (from.hasOwnProperty(p)) {
        try {
          if (from[p].constructor === Object) {
            to[p] = (to[p] && to[p].constructor === Object) ? this.mergeRecursive(to[p], from[p]) : from[p];
          } else {
            to[p] = from[p];
          }
        } catch (e) {
          to[p] = from[p];
        }
      }
    }
    return to;
  },

  getArrayObjectElementHavingFieldValue: function<T extends object>(array: T[], field: keyof T, value: any): T | null {
    return array.find(item => item[field] === value) || null;
  },

  nl2br: function (str: string): string {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br />');
  },

  isNumeric: function (n: any): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
  },

  getMobileOperatingSystem: function (): string {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/windows phone/i.test(userAgent)) {
      return "windows";
    }
    if (/android/i.test(userAgent)) {
      return "android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "ios";
    }
    return "unknown";
  },

  getHashParams: function (): { [key: string]: string } {
    const hashParams: { [key: string]: string } = {};
    const r = /([^?&=]+)=?([^&]*)/g;
    const d = function (s: string) {
      return decodeURIComponent(s.replace(/\+/g, " "));
    };
    const q = window.location.hash.substring(1);
    let e = r.exec(q);
    while (e !== null) {
      hashParams[d(e[1])] = d(e[2]);
      e = r.exec(q); // r.exec(q) ile atama işlemi döngü sonunda gerçekleştirilir
    }
    return hashParams;
  },

  getQueryStringValue: function (name: string, url: string = window.location.href): string | null {
    const regex = new RegExp("[?&]" + name.replace(/[[\]]/g, "\\$&") + "(=([^&#]*)|&|#|$)"); // Gereksiz kaçış karakterleri kaldırıldı
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
};

export default Helpers;
