import * as React from 'react';
import { SVGProps } from 'react';
const SvgNetworkEth = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		aria-label='Ethereum'
		viewBox='0 0 512 512'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<rect width={512} height={512} fill='#fff' rx='15%' />
		<path fill='#3C3C3B' d='M256 362v107l131-185z' />
		<path fill='#343434' d='m256 41 131 218-131 78-132-78' />
		<path fill='#8C8C8C' d='M256 41v158l-132 60m0 25 132 78v107' />
		<path fill='#141414' d='M256 199v138l131-78' />
		<path fill='#393939' d='m124 259 132-60v138' />
	</svg>
);
export default SvgNetworkEth;
