import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomDailySpin = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 512 512'
		className='svg-icon'
		{...props}>
		<circle
			cx={256}
			cy={256}
			r={256}
			style={{
				fill: '#eff2fa',
			}}
		/>
		<circle
			cx={256}
			cy={256}
			r={238.345}
			style={{
				fill: '#d7deed',
			}}
		/>
		<path
			d='M256 256 64.899 145.655a221.144 221.144 0 0 1 80.756-80.756L256 256z'
			style={{
				fill: '#ffe669',
			}}
		/>
		<path
			d='M256 35.31V256L145.655 64.899C178.104 46.018 215.786 35.31 256 35.31z'
			style={{
				fill: '#ffcd64',
			}}
		/>
		<path
			d='M366.345 64.899 256 256V35.31c40.214 0 77.896 10.708 110.345 29.589z'
			style={{
				fill: '#ffaa64',
			}}
		/>
		<path
			d='M447.101 145.655 256 256 366.345 64.899a221.144 221.144 0 0 1 80.756 80.756z'
			style={{
				fill: '#ff8c64',
			}}
		/>
		<path
			d='M476.69 256H256l191.101-110.345c18.881 32.449 29.589 70.13 29.589 110.345z'
			style={{
				fill: '#ff6469',
			}}
		/>
		<path
			d='M476.69 256c0 40.214-10.708 77.895-29.589 110.345L256 256h220.69z'
			style={{
				fill: '#e18caf',
			}}
		/>
		<path
			d='M447.101 366.345a221.152 221.152 0 0 1-80.756 80.756L256 256l191.101 110.345z'
			style={{
				fill: '#c3b4fa',
			}}
		/>
		<path
			d='M366.345 447.101C333.896 465.982 296.214 476.69 256 476.69V256l110.345 191.101z'
			style={{
				fill: '#7dd2f0',
			}}
		/>
		<path
			d='M256 256v220.69c-40.214 0-77.896-10.708-110.345-29.589L256 256z'
			style={{
				fill: '#7de6c3',
			}}
		/>
		<path
			d='M256 256 145.655 447.101a221.152 221.152 0 0 1-80.756-80.756L256 256z'
			style={{
				fill: '#96e393',
			}}
		/>
		<path
			d='M256 256 64.899 366.345C46.018 333.895 35.31 296.214 35.31 256H256z'
			style={{
				fill: '#afe164',
			}}
		/>
		<path
			d='M256 256H35.31c0-40.215 10.708-77.896 29.589-110.345L256 256z'
			style={{
				fill: '#d7e664',
			}}
		/>
		<circle
			cx={256}
			cy={256}
			r={39.724}
			style={{
				fill: '#eff2fa',
			}}
		/>
		<path
			d='m336.833 212.619 31.21-68.662-68.662 31.21 17.24 7.729-110.147 110.147c-3.626-.197-7.317 1.074-10.087 3.843-5.171 5.171-5.171 13.555 0 18.727 5.171 5.172 13.555 5.171 18.727 0 2.77-2.77 4.041-6.461 3.843-10.087l110.147-110.147 7.729 17.24z'
			style={{
				fill: '#fff',
			}}
		/>
		<circle
			cx={256}
			cy={256}
			r={17.655}
			style={{
				fill: '#d7deed',
			}}
		/>
		<g
			style={{
				opacity: 0.5,
			}}>
			<circle
				cx={439.327}
				cy={305.126}
				r={13.241}
				style={{
					fill: '#fff',
				}}
			/>
			<circle
				cx={72.674}
				cy={206.874}
				r={13.241}
				style={{
					fill: '#fff',
				}}
			/>
			<circle
				cx={305.126}
				cy={72.674}
				r={13.241}
				style={{
					fill: '#fff',
				}}
			/>
			<circle
				cx={206.874}
				cy={439.327}
				r={13.241}
				style={{
					fill: '#fff',
				}}
			/>
			<circle
				cx={390.201}
				cy={390.201}
				r={13.241}
				style={{
					fill: '#fff',
				}}
			/>
			<circle
				cx={121.799}
				cy={121.799}
				r={13.241}
				style={{
					fill: '#fff',
				}}
			/>
			<circle
				cx={390.201}
				cy={121.799}
				r={13.241}
				style={{
					fill: '#fff',
				}}
			/>
			<circle
				cx={121.799}
				cy={390.201}
				r={13.241}
				style={{
					fill: '#fff',
				}}
			/>
			<circle
				cx={72.674}
				cy={305.126}
				r={13.241}
				style={{
					fill: '#fff',
				}}
			/>
			<circle
				cx={439.327}
				cy={206.874}
				r={13.241}
				style={{
					fill: '#fff',
				}}
			/>
			<circle
				cx={206.874}
				cy={72.674}
				r={13.241}
				style={{
					fill: '#fff',
				}}
			/>
			<circle
				cx={305.126}
				cy={439.327}
				r={13.241}
				style={{
					fill: '#fff',
				}}
			/>
		</g>
	</svg>
);
export default SvgCustomDailySpin;
