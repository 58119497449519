import * as React from 'react';
import { SVGProps } from 'react';
const SvgHpb = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#1591ca' />
			<g fill='#fff' fillRule='nonzero'>
				<path d='M16.572 15.272s1.327-2.95-1.275-5.8c0 0-1.48-1.9-4.54-1.75l.611-.24c.102-.04.209-.068.317-.082l.602-.078 4.234-.65s1.497-.294 2.632.03c.323.093.617.268.85.506.626.634 1.883 2.311 1.06 4.964 0 0-.817 2.95-3.878 3.65 0 0-.766.2-.613-.55zm-.189 1.644s1.913 2.623 5.742 1.884c0 0 2.422-.279 3.851-2.937l-.102.639c-.017.106-.047.21-.09.309l-.238.547-1.588 3.9s-.505 1.411-1.368 2.203a1.952 1.952 0 0 1-.876.459c-.875.203-2.988.408-4.898-1.638 0 0-2.173-2.192-1.227-5.13 0-.002.214-.747.794-.236zm-1.385-.947s-3.27.344-4.495 3.975c0 0-.944 2.204.714 4.73l-.517-.4a1.3 1.3 0 0 1-.23-.229l-.37-.472L7.416 20.3s-1.007-1.127-1.284-2.25c-.08-.322-.072-.66.022-.978.249-.847 1.106-2.75 3.863-3.372 0 0 3.017-.777 5.162 1.476.002 0 .56.55-.18.793z' />
				<path
					d='M17.106 16.159s3.26-.424 4.39-4.085c0 0 .887-2.227-.837-4.711l.528.387c.087.065.167.14.236.223l.381.462 2.77 3.206s1.034 1.1 1.342 2.218c.087.32.087.656 0 .976-.227.853-1.034 2.778-3.775 3.468 0 0-2.996.85-5.198-1.346.002 0-.57-.536.163-.798zm-1.638.604s-1.401 2.916 1.127 5.829c0 0 1.429 1.935 4.495 1.86l-.618.225a1.35 1.35 0 0 1-.319.073l-.603.064-4.25.546s-1.503.257-2.63-.095a1.952 1.952 0 0 1-.837-.527c-.61-.65-1.823-2.356-.932-4.987 0 0 .89-2.93 3.969-3.554 0 .003.773-.18.598.566zm.109-1.537s-1.952-2.596-5.769-1.8c0 0-2.418.312-3.808 2.99l.092-.641a1.3 1.3 0 0 1 .086-.31l.23-.55 1.53-3.924s.485-1.418 1.336-2.222a1.95 1.95 0 0 1 .872-.469c.872-.217 2.982-.45 4.92 1.569 0 0 2.205 2.161 1.302 5.112 0-.002-.204.748-.791.245z'
					opacity={0.5}
				/>
			</g>
		</g>
	</svg>
);
export default SvgHpb;
