import * as React from 'react';
import { SVGProps } from 'react';
const SvgXvc = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#b50126' />
			<path
				fill='#fff'
				d='M10.118 10.743H8L9.664 8h4.134v10.717L21.714 8H26L14.857 24h-4.739z'
			/>
		</g>
	</svg>
);
export default SvgXvc;
