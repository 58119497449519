import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar43 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar43_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<clipPath id='avatar43_svg__clip-path'>
				<rect
					width={56}
					height={10}
					x={36}
					y={50.04}
					rx={1.99}
					style={{
						fill: 'none',
					}}
				/>
			</clipPath>
			<style>
				{
					'.avatar43_svg__cls-18,.avatar43_svg__cls-19,.avatar43_svg__cls-20,.avatar43_svg__cls-9{fill:none}.avatar43_svg__cls-4{fill:#393c54}.avatar43_svg__cls-5{fill:#fba875}.avatar43_svg__cls-6{fill:#ffbb94}.avatar43_svg__cls-18,.avatar43_svg__cls-19,.avatar43_svg__cls-20,.avatar43_svg__cls-9{stroke-linecap:round}.avatar43_svg__cls-13,.avatar43_svg__cls-8{fill:#fff}.avatar43_svg__cls-18,.avatar43_svg__cls-9{stroke:#515570}.avatar43_svg__cls-18,.avatar43_svg__cls-19,.avatar43_svg__cls-20,.avatar43_svg__cls-9{stroke-linejoin:round}.avatar43_svg__cls-9{stroke-width:1.89px;opacity:.4}.avatar43_svg__cls-10{fill:#f85565}.avatar43_svg__cls-13{opacity:.5}.avatar43_svg__cls-18,.avatar43_svg__cls-19,.avatar43_svg__cls-20{stroke-width:2px}.avatar43_svg__cls-19{stroke:#f85565}.avatar43_svg__cls-20{stroke:#fff}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#4bc190',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				fill: '#356cb6',
				opacity: 0.3,
			}}
		/>
		<path
			d='M64.94 25a31.19 31.19 0 0 0-32.09 31.15V91A2.26 2.26 0 0 0 35 93.2l1.63 3.37a1.07 1.07 0 0 0 1.84 0l2-3.35h1.29l2 3.35a1.07 1.07 0 0 0 1.84 0l2-3.35h32.8l2 3.35a1.07 1.07 0 0 0 1.84 0l2-3.35h1.29l2 3.36a1.07 1.07 0 0 0 1.84 0L93 93.2a2.3 2.3 0 0 0 2.11-2.29v-34.1C95.15 39.86 81.88 25.52 64.94 25Z'
			className='avatar43_svg__cls-4'
		/>
		<circle cx={89.84} cy={70.87} r={7.5} className='avatar43_svg__cls-5' />
		<path
			d='M64 124a59.75 59.75 0 0 0 37.53-13.2l-2-5.53C98 101.5 93.68 99 88.89 99H39.11c-4.79 0-9.07 2.51-10.68 6.28l-2 5.53A59.75 59.75 0 0 0 64 124Z'
			className='avatar43_svg__cls-6'
		/>
		<path
			d='M64 88.88v12'
			style={{
				strokeLinecap: 'round',
				stroke: '#fba875',
				strokeMiterlimit: 10,
				strokeWidth: 14,
				fill: 'none',
			}}
		/>
		<circle cx={38.16} cy={70.87} r={7.5} className='avatar43_svg__cls-5' />
		<path
			d='M64 98a26.78 26.78 0 0 1-26.79-26.81v-9.62a26.79 26.79 0 0 1 53.58 0v9.62A26.78 26.78 0 0 1 64 98Z'
			className='avatar43_svg__cls-6'
		/>
		<path
			d='M87 48.36 91.25 53l4.25-4.64V39.5l-2 .5 2-3.75v-9.14a4.25 4.25 0 0 0-4.25-4.25A4.25 4.25 0 0 0 87 27.11V32l2.5 5-2.5-2.07Z'
			className='avatar43_svg__cls-8'
		/>
		<path d='M91.5 31v24' className='avatar43_svg__cls-9' />
		<path
			d='M91.25 22.86a4.25 4.25 0 0 1 4.25 4.25v2.25L91.25 34 87 29.36v-2.25a4.25 4.25 0 0 1 4.25-4.25Z'
			className='avatar43_svg__cls-10'
		/>
		<path
			d='M91 56.88v-.7c0-14.69-11.5-27.13-26.18-27.56A27 27 0 0 0 37 55.61v1.31a2 2 0 0 0 2 2h50a2 2 0 0 0 2-2.04Z'
			style={{
				fill: '#515570',
			}}
		/>
		<rect
			width={56}
			height={10}
			x={36}
			y={50}
			rx={1.99}
			style={{
				fill: '#f8dc25',
			}}
		/>
		<circle cx={44.48} cy={55.04} r={1.77} className='avatar43_svg__cls-13' />
		<circle cx={54.48} cy={55.04} r={1.77} className='avatar43_svg__cls-13' />
		<circle cx={64.48} cy={55.04} r={1.77} className='avatar43_svg__cls-13' />
		<circle cx={74.48} cy={55.04} r={1.77} className='avatar43_svg__cls-13' />
		<circle cx={84.48} cy={55.04} r={1.77} className='avatar43_svg__cls-13' />
		<g
			style={{
				clipPath: 'url(#avatar43_svg__clip-path)',
			}}>
			<path
				d='m94.11 60.03-4.97-4.93-4.96 4.93-4.97-4.93-4.96 4.93-4.96-4.93-4.97 4.93-4.96-4.93-4.97 4.93-4.96-4.93-4.97 4.93-4.96-4.93-4.5 4.93.42 5.67 57.65-1.56 1.04-4.11z'
				style={{
					fill: '#ff8475',
				}}
			/>
			<path
				d='m29.59 50.13 4.96 4.94 4.97-4.94 4.96 4.94 4.97-4.94 4.96 4.94 4.97-4.94 4.96 4.94 4.96-4.94 4.97 4.94 4.96-4.94 4.97 4.94 4.5-4.94 4.97 4.97 4.74-4.74-1.07-5.66-21.75-4-21.67-.33-24.33 6.83v2.93z'
				style={{
					fill: '#f2bc0f',
				}}
			/>
		</g>
		<path
			d='M74.12 66.84c3.07-2.07 6.1-1.8 9.09-.24M53.88 66.84a8.33 8.33 0 0 0-9.09-.24'
			className='avatar43_svg__cls-9'
		/>
		<path
			d='M64 73v7'
			style={{
				stroke: '#eaa78f',
				strokeWidth: 4,
				strokeLinejoin: 'round',
				strokeLinecap: 'round',
				fill: 'none',
			}}
		/>
		<path
			d='M71.55 85a1 1 0 0 1 .94 1.07 8.56 8.56 0 0 1-17 0 1 1 0 0 1 .96-1.07Z'
			className='avatar43_svg__cls-4'
		/>
		<path
			d='M59 91.91a8.52 8.52 0 0 0 10.08 0C68 90.16 66.17 90 64 90s-4 .16-5 1.91Z'
			className='avatar43_svg__cls-10'
		/>
		<path d='M58 85h12a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2Z' className='avatar43_svg__cls-8' />
		<path d='M80 72.87a3 3 0 0 0-6 0M54 72.87a3 3 0 0 0-6 0' className='avatar43_svg__cls-18' />
		<path d='M71 77h7M71 80h7M50 77h7M50 80h7' className='avatar43_svg__cls-19' />
		<path d='M79 99v0' className='avatar43_svg__cls-20' />
		<path
			d='M78.68 102.07a15 15 0 0 1-29.6-1.5'
			style={{
				strokeDasharray: '.1 3.04',
				stroke: '#fff',
				strokeWidth: 2,
				strokeLinejoin: 'round',
				strokeLinecap: 'round',
				fill: 'none',
			}}
		/>
		<path
			d='M49 99.05v0M65.63 114l.87 6M62.44 114l-.63 6M68.52 113.31l1.23 3.69M59.48 113.31l-1.42 3.25'
			className='avatar43_svg__cls-20'
		/>
	</svg>
);
export default SvgAvatar43;
