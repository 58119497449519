import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar27 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar27_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar27_svg__cls-1{fill:#ffd8c9}.avatar27_svg__cls-4{fill:#fbc0aa}.avatar27_svg__cls-7{fill:#f85565}.avatar27_svg__cls-13,.avatar27_svg__cls-14{fill:none;stroke-linecap:round;stroke-linejoin:round}.avatar27_svg__cls-10{fill:#515570}.avatar27_svg__cls-13,.avatar27_svg__cls-14{stroke:#515570}.avatar27_svg__cls-14{stroke-width:2px;opacity:.2}'
				}
			</style>
		</defs>
		<circle cx={64} cy={64} r={60} className='avatar27_svg__cls-1' />
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				fill: '#fff',
				opacity: 0.3,
			}}
		/>
		<path
			d='M64 14a31 31 0 0 1 31 31v41.07A9.93 9.93 0 0 1 85.07 96H42.93A9.93 9.93 0 0 1 33 86.07V45a31 31 0 0 1 31-31Z'
			style={{
				fill: '#393c54',
			}}
		/>
		<circle cx={89} cy={60} r={7} className='avatar27_svg__cls-4' />
		<path
			d='M64 124a59.7 59.7 0 0 0 34.7-11.07l-3.33-10.29A10 10 0 0 0 86 96H42.05a10 10 0 0 0-9.42 6.64l-3.33 10.29A59.7 59.7 0 0 0 64 124Z'
			style={{
				fill: '#00adfe',
			}}
		/>
		<path
			d='M46.54 121.45a59.93 59.93 0 0 0 34.92 0L79 96H49Z'
			style={{
				fill: '#ff8475',
			}}
		/>
		<path d='M48.13 105h31.74l-.39-4H48.52l-.39 4z' className='avatar27_svg__cls-7' />
		<path d='M76 96a12 12 0 0 1-24 0Z' className='avatar27_svg__cls-1' />
		<path
			d='M64 83v12'
			style={{
				strokeWidth: 14,
				strokeLinejoin: 'round',
				stroke: '#fbc0aa',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={39} cy={60} r={7} className='avatar27_svg__cls-4' />
		<path
			d='M64 90a25 25 0 0 1-25-25V48.52a25 25 0 1 1 50 0V65a25 25 0 0 1-25 25Z'
			className='avatar27_svg__cls-1'
		/>
		<path
			d='M64 64.75v6.5'
			style={{
				strokeWidth: 5,
				strokeLinejoin: 'round',
				stroke: '#fbc0aa',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M64.83 18.35a27.51 27.51 0 0 0-28.32 27.47v4.76a2 2 0 0 0 2 2h.58a1 1 0 0 0 .86-.49L44 45l2.48 4.13a1 1 0 0 0 1.71 0L50.67 45l2.47 4.13a1 1 0 0 0 1.72 0L57.33 45l2.48 4.13a1 1 0 0 0 1.71 0L64 45l2.48 4.13a1 1 0 0 0 1.71 0L70.67 45l2.47 4.13a1 1 0 0 0 1.72 0L77.33 45l2.48 4.13a1 1 0 0 0 1.71 0L84 45l4 7.09a1 1 0 0 0 .86.49h.58a2 2 0 0 0 2-2V46.4c.05-14.95-11.66-27.61-26.61-28.05Z'
			className='avatar27_svg__cls-10'
		/>
		<path
			d='M47.35 113h33.29l-.38-4H47.74l-.39 4zM46.58 121h34.84l-.39-4H46.97l-.39 4z'
			className='avatar27_svg__cls-7'
		/>
		<path
			d='M58.52 79.39c0-.84 11-.84 11 0 0 1.79-2.45 3.25-5.48 3.25s-5.52-1.46-5.52-3.25Z'
			style={{
				opacity: 0.7,
				fill: '#ff8475',
			}}
		/>
		<path
			d='M69.48 79.29c0 .78-11 .78-11 0C58.52 77.5 61 76 64 76s5.48 1.5 5.48 3.29Z'
			style={{
				opacity: 0.7,
				fill: '#f85565',
			}}
		/>
		<circle cx={76.25} cy={58.75} r={3} className='avatar27_svg__cls-10' />
		<path d='M70.75 59.84a6.61 6.61 0 0 1 11.5-1.31' className='avatar27_svg__cls-13' />
		<path
			d='m72.11 51.46 5.68-.4a4.62 4.62 0 0 1 4.21 2.1l.77 1.21'
			className='avatar27_svg__cls-14'
		/>
		<circle cx={51.75} cy={58.75} r={3} className='avatar27_svg__cls-10' />
		<path d='M57.25 59.84a6.61 6.61 0 0 0-11.5-1.31' className='avatar27_svg__cls-13' />
		<path
			d='m55.89 51.45-5.68-.39A4.59 4.59 0 0 0 46 53.17l-.77 1.21'
			className='avatar27_svg__cls-14'
		/>
		<path
			d='M57.25 78.76A17.4 17.4 0 0 0 64 79.88a17.4 17.4 0 0 0 6.75-1.12'
			style={{
				stroke: '#f85565',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
	</svg>
);
export default SvgAvatar27;
