import * as React from 'react';
import { SVGProps } from 'react';
const SvgPoe = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#dcd6cc' />
			<path
				fill='#fff'
				fillRule='nonzero'
				d='M24.758 7.732A4.283 4.283 0 0 0 21.742 6.5c-1.145 0-2.211.44-3.016 1.232L8.87 17.438a.5.5 0 0 0-.145.382v4.996L6 25.5h1.503l2.018-1.987h5.044a.51.51 0 0 0 .378-.162l9.806-9.658.01-.01A4.162 4.162 0 0 0 26 10.724a4.171 4.171 0 0 0-1.242-2.99zM19.026 8.86l.417-.41a3.253 3.253 0 0 1 2.299-.936c.863 0 1.678.334 2.299.936.01.01.02.028.039.038.591.602.911 1.385.911 2.226 0 .85-.34 1.652-.95 2.264l-.427.42h-3.812l2.503-2.465a.514.514 0 0 0 .019-.716c-.01-.01-.01-.02-.02-.029a.539.539 0 0 0-.746 0l-2.522 2.484V8.859zM10.55 22.501l2.056-2.026h3.812l-2.056 2.026zm5.984-5.904 2.435-2.398h3.812l-2.435 2.398zm2.978.822-2.27 2.235H13.43l2.27-2.235zm-3.753-5.34 2.444-2.408v3.802l-2.444 2.417zm-3.104 3.056 2.27-2.235v3.802l-2.27 2.235zm-2.91 2.866 2.085-2.044v3.802l-2.085 2.044z'
			/>
		</g>
	</svg>
);
export default SvgPoe;
