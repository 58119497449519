import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionUruguay = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='-5 -5 945 630'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#fff' d='M-5-5h945v630H-5z' />
		<path
			fill='#0038a8'
			d='M345 65h595v70H345zm0 140h595v70H345zM-5 345h945v70H-5zm0 140h945v70H-5z'
		/>
		<g transform='translate(234.285 -392.52) scale(3.77953)'>
			<g stroke='#7b3f00' strokeMiterlimit={20}>
				<g fill='#fcd116'>
					<path
						strokeLinecap='square'
						strokeWidth={0.611}
						d='m-19.106 157.888 3.066 4.578c-12.597 9.115-4.906 14.506-13.833 17.424 5.552-5.418-.734-6.254 3.944-17.547'
					/>
					<g strokeWidth={0.6}>
						<path
							strokeLinecap='square'
							strokeWidth={0.611}
							d='m-24.894 153.753-1.07 5.405c-15.352-2.461-13.726 6.788-22.101 2.54 7.757.094 4.078-5.01 15.371-9.688'
						/>
						<path
							fill='#7b3f00'
							strokeLinecap='square'
							strokeWidth={0.278}
							d='m-24.894 153.754-2.419-.436.025.1c-6.453 1.582-9.957 4.257-12.701 6.29-1.373 1.017-2.558 1.881-3.842 2.35a5.763 5.763 0 0 1-2.666.32c6.662 2.367 6.221-5.512 20.534-3.218z'
						/>
						<path
							fill='none'
							strokeWidth={0.611}
							d='M-27.361 153.121c-13.014 3.186-14.245 10.862-20.704 8.576'
						/>
					</g>
					<path
						strokeWidth={0.611}
						d='m-17.009 148.833-4.322-4.321-19.448 28.09 28.092-19.447-4.322-4.322-23.77 23.77'
					/>
					<g strokeWidth={0.6}>
						<path
							strokeLinecap='square'
							strokeWidth={0.611}
							d='m-26.064 146.737-4.577 3.065c-9.116-12.596-14.507-4.906-17.424-13.833 5.418 5.552 6.305-.723 17.599 3.955'
						/>
						<path
							fill='#7b3f00'
							strokeLinecap='square'
							strokeWidth={0.278}
							d='m-26.064 146.738-1.402-2.019-.053.088c-5.682-3.445-10.051-4.03-13.43-4.534-1.689-.252-3.137-.478-4.377-1.055a5.763 5.763 0 0 1-2.112-1.658c3.038 6.384 8.297.5 16.796 12.244z'
						/>
						<path
							fill='none'
							strokeWidth={0.611}
							d='M-27.361 144.545c-11.455-6.95-17.753-2.392-20.704-8.576'
						/>
						<path
							strokeWidth={0.611}
							d='M-17.009 148.833v-6.112l-33.615 6.112 33.615 6.112v-6.112h-33.615'
						/>
						<path
							fill='#7b3f00'
							stroke='none'
							d='M-17.009 148.833v6.112-6.112h-33.615l33.615 6.112'
						/>
						<path
							strokeLinecap='square'
							strokeWidth={0.611}
							d='m-21.929 140.948-5.404-1.069c2.46-15.353-6.789-13.727-2.54-22.102-.095 7.757 5.272 4.699 9.95 15.992'
						/>
						<path
							fill='#7b3f00'
							strokeLinecap='square'
							strokeWidth={0.278}
							d='m-21.93 140.949.436-2.42-.1.026c-1.581-6.454-4.257-9.958-6.29-12.702-1.016-1.373-1.88-2.557-2.349-3.842a5.763 5.763 0 0 1-.32-2.665c-2.367 6.661 5.512 6.22 3.218 20.534z'
						/>
						<path
							fill='none'
							strokeWidth={0.611}
							d='M-21.297 138.481c-3.185-13.014-10.862-14.244-8.576-20.704'
						/>
						<path
							strokeWidth={0.611}
							d='m-17.009 148.833 4.322-4.321-28.092-19.448 19.448 28.091 4.322-4.322-23.77-23.77'
						/>
						<path
							fill='#7b3f00'
							stroke='none'
							d='m-17.009 148.833-4.322 4.322 4.322-4.322-23.77-23.77 19.448 28.092'
						/>
						<path
							strokeLinecap='square'
							strokeWidth={0.611}
							d='m-14.912 139.779-3.066-4.578c12.597-9.116 4.906-14.506 13.833-17.424-5.552 5.418.702 6.312-3.976 17.605'
						/>
						<path
							fill='#7b3f00'
							strokeLinecap='square'
							strokeWidth={0.278}
							d='m-14.913 139.778 2.018-1.402-.088-.053c3.445-5.681 4.031-10.05 4.534-13.429.252-1.69.478-3.138 1.056-4.378a5.763 5.763 0 0 1 1.657-2.111c-6.384 3.037-.5 8.296-12.244 16.795z'
						/>
						<path
							fill='none'
							strokeWidth={0.611}
							d='M-12.72 138.481c6.949-11.455 2.391-17.753 8.575-20.704'
						/>
						<path
							strokeWidth={0.611}
							d='M-17.009 148.833h6.112l-6.112-33.615-6.112 33.615h6.112v-33.615'
						/>
						<path
							fill='#7b3f00'
							stroke='none'
							d='M-17.009 148.833h-6.112 6.112v-33.615l-6.112 33.615'
						/>
						<path
							strokeLinecap='square'
							strokeWidth={0.611}
							d='m-9.124 143.913 1.07-5.404c15.352 2.461 13.726-6.789 22.102-2.54-7.758-.094-4.302 5.036-15.595 9.714'
						/>
						<path
							fill='#7b3f00'
							strokeLinecap='square'
							strokeWidth={0.278}
							d='m-9.124 143.912 2.419.436-.025-.1c6.453-1.581 9.957-4.256 12.701-6.29 1.373-1.016 2.558-1.88 3.842-2.349a5.763 5.763 0 0 1 2.666-.32c-6.662-2.367-6.22 5.512-20.534 3.218z'
						/>
						<path
							fill='none'
							strokeWidth={0.611}
							d='M-6.657 144.545c13.014-3.185 14.245-10.862 20.705-8.576'
						/>
						<path
							strokeWidth={0.611}
							d='m-17.009 148.833 4.322 4.322L6.76 125.064l-28.092 19.448 4.322 4.321 23.77-23.77'
						/>
						<path
							fill='#7b3f00'
							stroke='none'
							d='m-17.009 148.833-4.322-4.321 4.322 4.321 23.77-23.77-28.092 19.449'
						/>
						<path
							strokeLinecap='square'
							strokeWidth={0.611}
							d='m-7.954 150.93 4.577-3.065c9.116 12.596 14.507 4.906 17.425 13.832-5.419-5.552-6.436.661-17.73-4.017'
						/>
						<path
							fill='#7b3f00'
							strokeLinecap='square'
							strokeWidth={0.278}
							d='m-7.954 150.93 1.402 2.018.053-.089c5.682 3.445 10.051 4.031 13.43 4.534 1.689.252 3.138.479 4.377 1.056a5.763 5.763 0 0 1 2.112 1.658c-3.037-6.384-8.297-.501-16.796-12.244z'
						/>
						<path
							fill='none'
							strokeWidth={0.611}
							d='M-6.657 153.121c11.455 6.95 17.753 2.392 20.705 8.576'
						/>
						<path
							strokeWidth={0.611}
							d='M-17.009 148.833v6.112l33.615-6.112-33.615-6.112v6.112h33.615'
						/>
						<path
							fill='#7b3f00'
							stroke='none'
							d='M-17.009 148.833v-6.112 6.112h33.615l-33.615-6.112'
						/>
						<path
							strokeLinecap='square'
							strokeWidth={0.611}
							d='m-12.089 156.719 5.405 1.069c-2.462 15.353 6.788 13.726 2.54 22.102.094-7.757-5.014-4.165-9.691-15.458'
						/>
						<path
							fill='#7b3f00'
							strokeLinecap='square'
							strokeWidth={0.278}
							d='m-12.088 156.718-.436 2.419.1-.025c1.582 6.453 4.257 9.957 6.29 12.702 1.016 1.373 1.88 2.557 2.349 3.842a5.763 5.763 0 0 1 .321 2.665c2.367-6.662-5.513-6.22-3.219-20.534z'
						/>
						<path
							fill='none'
							strokeWidth={0.611}
							d='M-12.72 159.186C-9.536 172.2-1.86 173.43-4.146 179.89'
						/>
						<path
							strokeWidth={0.611}
							d='m-17.009 148.833-4.322 4.322 28.092 19.448-19.448-28.091-4.322 4.321 23.77 23.77'
						/>
						<path
							fill='#7b3f00'
							stroke='none'
							d='m-17.009 148.833 4.322-4.321-4.322 4.321 23.77 23.77-19.448-28.091'
						/>
					</g>
				</g>
				<path
					fill='#7b3f00'
					strokeLinecap='square'
					strokeWidth={0.278}
					d='m-19.105 157.888-2.018 1.403.088.053c-3.445 5.681-4.03 10.05-4.534 13.429-.252 1.69-.478 3.138-1.056 4.377a5.763 5.763 0 0 1-1.657 2.112c6.384-3.037.5-8.297 12.244-16.796z'
				/>
				<g strokeWidth={0.611}>
					<path fill='none' d='M-21.297 159.186c-6.95 11.454-2.392 17.752-8.576 20.704' />
					<path
						fill='#fcd116'
						d='M-17.009 148.833h-6.112l6.112 33.616 6.112-33.616h-6.112v33.616'
					/>
				</g>
			</g>
			<path fill='#7b3f00' d='M-17.009 148.833h6.112-6.112v33.616l6.112-33.616' />
			<path
				fill='#7b3f00'
				d='m-17.009 148.833 4.322 4.322-4.322-4.322-23.77 23.77 28.092-19.448'
			/>
			<circle
				cx={-17.009}
				cy={148.833}
				r={11.205}
				fill='#fcd116'
				stroke='#7b3f00'
				strokeMiterlimit={20}
				strokeWidth={0.611}
			/>
			<g fill='#7b3f00'>
				<path d='M-8.758 144.351c-.713.815-1.12-.61-3.667-.61-2.547 0-2.954 1.527-3.362 1.221-.407-.305 2.14-2.139 2.955-2.24.814-.102 3.157.713 4.074 1.63m-2.954.916c.713.611.102 1.936-.611 1.936s-2.037-1.223-1.426-2.038' />
				<path d='M-15.074 146.185c.102-1.223 1.121-1.426 2.75-1.426 1.63 0 2.344 1.222 2.955 1.528-.713 0-1.324-1.019-2.954-1.019-1.63 0-1.63 0-2.75 1.019m.305.203c.408-.61.917.612 2.037.612 1.12 0 1.732-.306 2.445-.815.713-.51-1.019 1.222-2.139 1.222s-2.649-.611-2.343-1.019m-7.945-1.324c-.51.204-1.325 1.223 0 1.936-1.02-.713-.204-1.732 0-1.936z' />
				<path d='M-11.304 145.166c.203.204 1.018 1.223 0 1.936 1.324-.713.509-1.732 0-1.936zm-7.335 4.38c-.917.204-.611 1.528-1.426 1.63-.815.102-1.222 1.019-1.12.917.509-.407 1.63-.713 2.444-.713.815 0 1.019.51 1.732.51s.917-.51 1.732-.51 1.935.306 2.445.713c.101.102-.306-.815-1.12-.917-.816-.102-.51-1.426-1.427-1.63 0 .408.305.306.407.917 0 .51-.916.51-1.12 0 .204.815-.408.815-.917.815s-1.12 0-.917-.815c-.203.51-1.12.51-1.12 0 .102-.611.407-.51.407-.917zm-3.158 3.464c-.815.611-1.018.917-1.12 1.63.244-.428.364-.604.55-.855l-.142-.062c.059.007.112.016.17.024.122-.166.232-.31.542-.737z' />
				<path d='m-22.34 153.747-.013.018c.128.03.263.043.404.044a10.998 10.998 0 0 0-.39-.062zm.391.062c.636.112 1.134.258 1.591.406.57-.042 1.357-.187 3.35-.187s2.778.145 3.348.187c.457-.148.956-.294 1.59-.406-1.122.004-2.618-.8-3.31-.8-.814 0-1.018.204-1.629.204s-.815-.203-1.63-.203c-.691 0-2.187.803-3.31.799zm9.879 0c.142 0 .278-.015.405-.044l-.013-.018c-.136.02-.267.04-.391.062z' />
				<path d='M-11.678 153.747c.057-.008.11-.017.17-.024-.051.021-.093.04-.143.062.186.251.306.427.55.855-.102-.713-.305-1.019-1.12-1.63.31.427.42.57.543.737z' />
				<path d='M-11.65 153.785a.037.037 0 0 0-.006-.007c-1.063.41-1.437.48-2.004.437-.966.313-1.762.629-3.349.629s-2.383-.316-3.349-.629c-.567.042-.941-.027-2.004-.437a.037.037 0 0 1-.005.007c3.2 1.377 2.85 1.873 5.358 1.873s2.158-.496 5.358-1.873zm-10.712-.007a.72.72 0 0 1 .01-.013 1.29 1.29 0 0 1-.157-.042c.058.023.093.034.147.055zm10.706 0c.054-.021.09-.032.148-.055-.05.018-.104.03-.157.042a.72.72 0 0 0 .01.013zm-13.604-9.427c.713.815 1.12-.61 3.667-.61s2.954 1.527 3.362 1.221-2.14-2.139-2.954-2.24-3.158.713-4.075 1.63m2.954.916c-.713.611-.102 1.936.611 1.936s2.038-1.223 1.426-2.038' />
				<path d='M-18.944 146.185c-.102-1.223-1.12-1.426-2.75-1.426s-2.344 1.222-2.955 1.528c.713 0 1.324-1.019 2.954-1.019s1.63 0 2.75 1.019m-.305.203c-.407-.61-.917.612-2.037.612s-1.732-.306-2.445-.815 1.019 1.222 2.14 1.222 2.648-.611 2.342-1.019m2.241 10.085c-.51 0-1.833.306 0 .306s.51-.306 0-.306' />
			</g>
		</g>
	</svg>
);
export default SvgRegionUruguay;
