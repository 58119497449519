import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar42 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar42_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar42_svg__cls-6{fill:#fff}.avatar42_svg__cls-3{fill:#393c54}.avatar42_svg__cls-4{fill:#fba875}.avatar42_svg__cls-10,.avatar42_svg__cls-11,.avatar42_svg__cls-9{fill:none;stroke-linecap:round}.avatar42_svg__cls-10{stroke:#fba875}.avatar42_svg__cls-10,.avatar42_svg__cls-11{stroke-miterlimit:10}.avatar42_svg__cls-9{stroke:#515570;opacity:.4;stroke-linejoin:round;stroke-width:2px}.avatar42_svg__cls-10,.avatar42_svg__cls-11{stroke-width:4px}.avatar42_svg__cls-11{stroke:#393c54}.avatar42_svg__cls-15{opacity:.1;fill:#f85565}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#00adfe',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.3,
				fill: '#fff',
			}}
		/>
		<path
			d='M31.08 61.57V45.92a32.92 32.92 0 0 1 65.84 0v15.65Z'
			className='avatar42_svg__cls-3'
		/>
		<circle cx={91.32} cy={60.43} r={7.93} className='avatar42_svg__cls-4' />
		<path
			d='M64 124.1a59.78 59.78 0 0 0 40-15.28l-2.39-5.68c-1.71-4-6.22-6.64-11.29-6.64H37.69c-5.07 0-9.58 2.66-11.29 6.64l-2.4 5.68a59.78 59.78 0 0 0 40 15.28Z'
			style={{
				fill: '#b56b63',
			}}
		/>
		<path
			d='M81.72 98.25a3.06 3.06 0 0 0-3.08-2.88H49.36a3.07 3.07 0 0 0-3.08 2.93v.32c-.17 7.32 10.52 16.64 10.52 16.64l7.2-7.21 7.17 7.17s10.56-9 10.56-16.22c0-.26 0-.51-.01-.75Z'
			className='avatar42_svg__cls-6'
		/>
		<path
			d='M64 84.75V98.5'
			style={{
				strokeWidth: 20,
				strokeMiterlimit: 10,
				stroke: '#fba875',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={36.68} cy={60.43} r={7.93} className='avatar42_svg__cls-4' />
		<path
			d='M64 94.37a28.31 28.31 0 0 1-28.32-28.32V47.43a28.32 28.32 0 1 1 56.64 0v18.62A28.31 28.31 0 0 1 64 94.37Z'
			style={{
				fill: '#ffbb94',
			}}
		/>
		<circle cx={77.5} cy={59.28} r={3} className='avatar42_svg__cls-3' />
		<circle cx={50.5} cy={59.28} r={3} className='avatar42_svg__cls-3' />
		<path d='m74.69 53 9.62-.25M53.31 53l-9.62-.25' className='avatar42_svg__cls-9' />
		<path
			d='M92.32 64.81h-1.1A3.22 3.22 0 0 0 88 68a3.22 3.22 0 0 1-3.22 3.22H43.22A3.22 3.22 0 0 1 40 68a3.22 3.22 0 0 0-3.22-3.22h-1.1v17.5c0 15.43 12.1 28.47 27.52 28.9a28.32 28.32 0 0 0 29.12-28.31Z'
			className='avatar42_svg__cls-3'
		/>
		<path
			d='M71.55 75a1 1 0 0 1 .94 1.07 8.56 8.56 0 0 1-17 0 1 1 0 0 1 .96-1.07Z'
			className='avatar42_svg__cls-6'
		/>
		<path d='M64 60.75v6.5M66.5 68h-5' className='avatar42_svg__cls-10' />
		<path d='M36 56V36M92 56V35' className='avatar42_svg__cls-11' />
		<path
			d='M31 23.83A15.17 15.17 0 0 0 46.17 39H91.5a1 1 0 0 0 1-1.29A29.92 29.92 0 0 0 64 17H31Z'
			style={{
				fill: '#515570',
			}}
		/>
		<path
			d='M70.5 33H47.73a10 10 0 0 1-10-10'
			style={{
				opacity: 0.1,
				stroke: '#fff',
				strokeWidth: 4,
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M65 67h2'
			style={{
				stroke: '#f85565',
				strokeLinejoin: 'round',
				strokeWidth: 2,
				fill: 'none',
				strokeLinecap: 'round',
				opacity: 0.3,
			}}
		/>
		<ellipse cx={50.42} cy={67.67} className='avatar42_svg__cls-15' rx={4.58} ry={2.98} />
		<ellipse cx={77.58} cy={67.67} className='avatar42_svg__cls-15' rx={4.58} ry={2.98} />
	</svg>
);
export default SvgAvatar42;
