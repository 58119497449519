import * as React from 'react';
import { SVGProps } from 'react';
const SvgIotx = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#00D4D5' />
			<g fill='#FFF'>
				<path d='M16.519 5v5.457l4.738-2.725z' />
				<path d='M21.257 7.732v5.457l4.737-2.732z' opacity={0.9} />
				<path
					d='M16.519 10.457v5.457l4.738-2.725zm4.738 2.733v5.456l4.737-2.732z'
					opacity={0.8}
				/>
				<path d='M16.519 15.914v5.457l4.738-2.725z' opacity={0.8} />
				<path d='M21.257 18.646v5.457l4.737-2.732z' />
				<path d='M4.273 10.273v5.457l4.738-2.732z' opacity={0.4} />
				<path d='M10.25 12.302v5.457l4.73-2.725z' opacity={0.2} />
				<path d='M6.738 15.753v5.457l4.737-2.732z' opacity={0.3} />
				<path d='M9.86 19.871v5.457l4.73-2.732z' opacity={0.9} />
				<path d='M16.48 22.22v5.458l4.73-2.733z' opacity={0.7} />
				<path d='M11.039 9.607v5.458l4.73-2.725z' opacity={0.9} />
				<path d='M16.519 5v5.457l-4.738-2.725z' opacity={0.8} />
				<path d='M11.039 7.32v5.456L6.3 10.044z' opacity={0.6} />
				<path d='M15.777 10.02v5.458l-4.738-2.733z' opacity={0.6} />
				<path d='M10.228 12.302v5.457L5.49 15.034z' opacity={0.95} />
				<path d='M16.519 15.914v5.457l-4.73-2.725z' opacity={0.6} />
				<path d='M6.738 17.185v5.457L2 19.909z' opacity={0.55} />
				<path d='M25.994 10.457v5.457l-4.737-2.725z' />
				<path d='M21.257 13.19v5.456l-4.738-2.732z' opacity={0.95} />
				<path d='M25.994 15.914v5.457l-4.737-2.725z' opacity={0.9} />
				<path d='M21.257 18.646v5.457l-4.738-2.732z' opacity={0.7} />
				<path d='M15.47 20.92v5.457l-4.737-2.725z' opacity={0.4} />
				<path d='M21.257 7.732v5.457l-4.738-2.732z' />
			</g>
		</g>
	</svg>
);
export default SvgIotx;
