import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar28 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar28_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar28_svg__cls-1{fill:#ffd8c9}.avatar28_svg__cls-18,.avatar28_svg__cls-7{fill:#fff}.avatar28_svg__cls-3{fill:#393c54}.avatar28_svg__cls-15,.avatar28_svg__cls-4{fill:#f85565}.avatar28_svg__cls-6{fill:#fbc0aa}.avatar28_svg__cls-12,.avatar28_svg__cls-13,.avatar28_svg__cls-14{fill:none;stroke-linecap:round;stroke-linejoin:round}.avatar28_svg__cls-11{fill:#515570}.avatar28_svg__cls-12,.avatar28_svg__cls-13,.avatar28_svg__cls-14{stroke:#515570}.avatar28_svg__cls-13,.avatar28_svg__cls-14{stroke-width:2px}.avatar28_svg__cls-13,.avatar28_svg__cls-15,.avatar28_svg__cls-18{opacity:.2}.avatar28_svg__cls-17{fill:#f8dc25}'
				}
			</style>
		</defs>
		<circle cx={64} cy={64} r={60} className='avatar28_svg__cls-1' />
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.3,
				fill: '#fff',
			}}
		/>
		<path
			d='M106 61.17c0 23.2-84 23.2-84 0a42 42 0 0 1 84 0Z'
			className='avatar28_svg__cls-3'
		/>
		<path d='M49 95h30v10H49z' className='avatar28_svg__cls-4' />
		<path
			d='M49 95h30v10H49z'
			style={{
				fill: '#356cb6',
				opacity: 0.3,
			}}
		/>
		<circle cx={86} cy={64} r={7} className='avatar28_svg__cls-6' />
		<path
			d='M46.54 121.45a59.93 59.93 0 0 0 34.92 0L81 99H47Z'
			className='avatar28_svg__cls-7'
		/>
		<circle cx={64} cy={29} r={21} className='avatar28_svg__cls-3' />
		<path
			d='M64 83v15'
			style={{
				strokeWidth: 14,
				stroke: '#fff',
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
		<path
			d='M64 83v15'
			style={{
				stroke: '#fbc0aa',
				opacity: 0.4,
				strokeWidth: 14,
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
		<circle cx={42} cy={64} r={7} className='avatar28_svg__cls-6' />
		<path
			d='M64 94a25 25 0 0 1-25-25V52.52a25 25 0 1 1 50 0V69a25 25 0 0 1-25 25Z'
			className='avatar28_svg__cls-7'
		/>
		<path
			d='M64 68.75v4.5'
			style={{
				strokeWidth: 5,
				stroke: '#fbc0aa',
				opacity: 0.4,
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
		<circle cx={76.25} cy={62.75} r={3} className='avatar28_svg__cls-11' />
		<path d='M70.75 63.84a6.61 6.61 0 0 1 11.5-1.31' className='avatar28_svg__cls-12' />
		<path
			d='m72.11 55.46 5.68-.4a4.62 4.62 0 0 1 4.21 2.1l.77 1.21'
			className='avatar28_svg__cls-13'
		/>
		<circle cx={51.75} cy={62.75} r={3} className='avatar28_svg__cls-11' />
		<path d='M57.25 63.84a6.61 6.61 0 0 0-11.5-1.31' className='avatar28_svg__cls-12' />
		<path
			d='m55.89 55.45-5.68-.39A4.59 4.59 0 0 0 46 57.17l-.77 1.21'
			className='avatar28_svg__cls-13'
		/>
		<path
			d='M64 82.38a2.42 2.42 0 0 0-3.6.19 2.52 2.52 0 0 0 .26 3.34 4.71 4.71 0 0 0 6.68 0 2.52 2.52 0 0 0 .26-3.34 2.42 2.42 0 0 0-3.6-.19Z'
			className='avatar28_svg__cls-4'
		/>
		<path
			d='M39 60.74S43 35 64 47c21-12 25 12.75 25 12.75L95 45 83 30l-19-8-21 7-11 18Z'
			className='avatar28_svg__cls-3'
		/>
		<path d='M59.08 83.42a19.37 19.37 0 0 0 9.42 0' className='avatar28_svg__cls-12' />
		<path
			d='M94 19.17 79.44 27M97 24.17 81.44 28M32.44 19.17 47 27M29.44 24.17 45 28'
			className='avatar28_svg__cls-14'
		/>
		<circle cx={51} cy={74} r={5.08} className='avatar28_svg__cls-15' />
		<circle cx={77} cy={74} r={5.08} className='avatar28_svg__cls-15' />
		<path
			d='M64 124a59.74 59.74 0 0 0 33.6-10.28L78.88 95l-15 15L49 95l-18.74 18.62A59.74 59.74 0 0 0 64 124Z'
			className='avatar28_svg__cls-4'
		/>
		<path
			d='m61.88 116.24 4.24-4.24L49 95l-4.24 4.24 17.12 17z'
			style={{
				fill: '#f2bc0f',
			}}
		/>
		<path
			d='M43.48 24.52S51 19 64 19s20.52 5.52 20.52 5.52L85 29s-12-4-21-4-21 4-21 4Z'
			className='avatar28_svg__cls-4'
		/>
		<path
			d='m58.62 123.75 24.5-24.51L78.88 95l-27.63 27.63a61.45 61.45 0 0 0 7.37 1.12Z'
			className='avatar28_svg__cls-17'
		/>
		<circle cx={44.15} cy={25.62} r={2.46} className='avatar28_svg__cls-1' />
		<circle cx={44.15} cy={30.54} r={2.46} className='avatar28_svg__cls-1' />
		<circle cx={41.7} cy={28.08} r={2.46} className='avatar28_svg__cls-1' />
		<circle cx={46.61} cy={28.08} r={2.46} className='avatar28_svg__cls-1' />
		<circle cx={44.15} cy={28.08} r={1.84} className='avatar28_svg__cls-4' />
		<circle cx={84} cy={25.54} r={2.46} className='avatar28_svg__cls-1' />
		<circle cx={84} cy={30.46} r={2.46} className='avatar28_svg__cls-1' />
		<circle cx={81.54} cy={28} r={2.46} className='avatar28_svg__cls-1' />
		<circle cx={86.46} cy={28} r={2.46} className='avatar28_svg__cls-1' />
		<circle cx={84} cy={28} r={1.84} className='avatar28_svg__cls-4' />
		<circle cx={80.25} cy={32} r={2} className='avatar28_svg__cls-4' />
		<circle cx={80.25} cy={36} r={2} className='avatar28_svg__cls-4' />
		<circle cx={78.25} cy={34} r={2} className='avatar28_svg__cls-4' />
		<circle cx={82.25} cy={34} r={2} className='avatar28_svg__cls-4' />
		<circle cx={80.25} cy={34} r={1.5} className='avatar28_svg__cls-1' />
		<path
			d='m38.06 30-2 3.48a.5.5 0 0 0 .43.75h4a.5.5 0 0 0 .43-.75l-2-3.48a.5.5 0 0 0-.86 0ZM38.06 35l-2 3.48a.5.5 0 0 0 .43.75h4a.5.5 0 0 0 .43-.75l-2-3.48a.5.5 0 0 0-.86 0ZM38.06 40l-2 3.48a.5.5 0 0 0 .43.75h4a.5.5 0 0 0 .43-.75l-2-3.48a.5.5 0 0 0-.86 0ZM38.06 45l-2 3.48a.5.5 0 0 0 .43.75h4a.5.5 0 0 0 .43-.75l-2-3.48a.5.5 0 0 0-.86 0Z'
			className='avatar28_svg__cls-17'
		/>
		<path
			d='m76.5 116.24 16.57-7.05-4.07-4.07-12.5 11.12zM34.78 109.13l16.72 7.11-12.59-11.21-4.13 4.1z'
			className='avatar28_svg__cls-18'
		/>
	</svg>
);
export default SvgAvatar28;
