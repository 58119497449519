import React, { useContext, useEffect, useState, useCallback } from "react";
import SocketContext from "../../context/SocketContext";
import SelectInput from "../../component/SelectInput";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

interface BetHistoryItem {
  _id: string;
  betId: number;
  createdAt: string;
  betAmount: number;
  betAmountUSD: number;
  gameName: string;
  marketName: string;
  status: string;
}

const BetHistory: React.FC = () => {
  const [betHistory, setBetHistory] = useState<BetHistoryItem[]>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const { sendRequest } = useContext(SocketContext);

  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [filters, setFilters] = useState<any>({
    bet_type: "all",
    period: "24h",
  });

  const loadBetHistory = useCallback(
    async (page: number, filters: any) => {
      setLoading(true);
      sendRequest(
        { A: "getHistory", D: { ...filters, page, perPage } },
        (response: any) => {

          console.log('resp', response);
          const { myGameHistory, totalDocuments, totalPages } = response.R;
          setTotalPages(totalPages);
          setBetHistory((prev) => (page === 1 ? myGameHistory : [...prev, ...myGameHistory]));
          setLoading(false);
        }
      );
    },
    [sendRequest, perPage]
  );

  useEffect(() => {
    loadBetHistory(1, filters);
  }, [filters, loadBetHistory]);

  const handleFilterChange = (name: string, value: any) => {
    setFilters((prev: any) =>
      prev.map((filter: any) =>
        filter.name === name ? { ...filter, value } : filter
      )
    );
    setCurrentPage(1);
    setBetHistory([]);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setBetHistory([]);
    setCurrentPage(1);
    loadBetHistory(1, filters);
  };

  const betTypeOptions = [
    { value: "all", label: t("ALL") },
    { value: "single", label: t("SINGLE") },
    { value: "multiple", label: t("MULTIPLE") },
    { value: "system", label: t("SYSTEM") },
    { value: "chain", label: t("CHAIN") },
  ];

  const periodOptions = [
    { value: "24h", label: t("24HOURS") },
    { value: "7d", label: t("7DAYS") },
    { value: "1m", label: t("1MONTH") },
  ];

  return (
    <div className="my-profile-info-block">
      <div className="overlay-header">{t("ALL")}</div>
      <div className="u-i-e-p-p-content-bc u-i-common-content">
        <div className="componentFilterWrapper-bc">
          <div className="componentFilterBody-bc">
            <div className="componentFilterElsWrapper-bc">
              <form className="filter-form-w-bc" onSubmit={handleSubmit}>
                <div className="componentFilterBody-content">
                  <div className="u-i-p-control-item-holder-bc">
                    <div className="form-control-bc default valid">
                      <label className="form-control-label-bc inputs">
                        <input
                          type="text"
                          inputMode="decimal"
                          className="form-control-input-bc"
                          name="bet_id"
                          step="0"
                        />
                        <i className="form-control-input-stroke-bc"></i>
                        <span className="form-control-title-bc ellipsis">
                          {t("BETID")}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="u-i-p-control-item-holder-bc">
                    <div className="form-control-bc default valid filled">
                      <label className="form-control-label-bc inputs">
                        <input
                          type="text"
                          className="form-control-input-bc"
                          name="name"
                        />
                        <i className="form-control-input-stroke-bc"></i>
                        <span className="form-control-title-bc ellipsis">
                          {t("SPORTNAME")}
                        </span>
                      </label>
                    </div>
                    <i className="sport-search-icon bc-i-close-remove"></i>
                  </div>
                  <div className="u-i-p-control-item-holder-bc">
                    <SelectInput
                      name="bet_type"
                      id="bet_type"
                      value={filters.bet_type}
                      options={betTypeOptions}
                      onChange={(value) =>
                        handleFilterChange("bet_type", value)
                      }
                      label={t("BETTYPE")}
                    />
                  </div>
                  <div className="u-i-p-control-item-holder-bc">
                    <SelectInput
                      name="period"
                      id="period"
                      value={filters.period}
                      options={periodOptions}
                      onChange={(value) => handleFilterChange("period", value)}
                      label={t("PERIOD")}
                    />
                  </div>
                </div>
                <div className="u-i-p-c-filter-footer-bc">
                  <button
                    className="btn a-color"
                    type="submit"
                    title={t("SHOW")}
                  >
                    <span>{t("SHOW")}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="betHistoryList-table">
          <div className="betHistoryList-thead">
            <div className="betHistoryListEl">
              <div className="betHistoryListEl-list-item">
                <p className="ellipsis">{t("DATEANDID")}</p>
              </div>
              <div className="betHistoryListEl-list-item">
                <p className="ellipsis">{t("BETTYPE")}</p>
              </div>
              <div className="betHistoryListEl-list-item">
                <p className="ellipsis">{t("STAKE")}</p>
              </div>
              <div className="betHistoryListEl-list-item">
                <p className="ellipsis">{t("ODDS")}</p>
              </div>
              <div className="betHistoryListEl-list-item">
                <p className="ellipsis">{t("OUTCOME")}</p>
              </div>
              <div className="betHistoryListEl-list-item">
                <p className="ellipsis">{t("ACTIONS")}</p>
              </div>
              <div className="betHistoryListEl-list-item">
                <p className="ellipsis"></p>
              </div>
            </div>
          </div>
        </div>

        <div
          id="scrollableDiv"
          style={{
            height: "100%",
            overflowY: "auto",
          }}
        >
          <InfiniteScroll
            dataLength={betHistory.length}
            next={() => {
              const nextPage = currentPage + 1;
              setCurrentPage(nextPage);
              loadBetHistory(nextPage, filters);
            }}
            hasMore={totalPages > currentPage}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {betHistory.map((bet, index) => (
              <div className="betHistoryList-table" key={bet._id}>
                <div className="betHistoryList-tbody">
                  <ul className="betHistoryListEl">
                    <li className="betHistoryListEl-list-item">
                      <div className="betHistoryListElId">
                        <i className="bc-i-unsettled" data-status="2"></i>
                        <div className="betHistoryListEl-info">
                          <p className="betHistory-day">
                            <time>
                              {dayjs(bet.createdAt).format("YYYY-MM-DD HH:mm")}
                            </time>
                          </p>
                          <p className="betHistory-Id">
                            <span>ID:</span>
                            {bet.betId}
                            <i className="bc-i-copy"></i>
                          </p>
                          <p className="bethistoryListEl-item-status">
                            <span className="ellipsis" data-status="2">
                              {bet.status}
                            </span>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="betHistoryListEl-list-item">
                      <div className="betHistoryListEl-types">
                        <i className="bet-history-express-icon bc-i-single-bet"></i>
                        <div className="bet-history-info-block">
                          <h3 className="bet-history-info-title-block ellipsis">
                            <span className="ellipsis">{bet.gameName}</span>
                          </h3>
                          <div className="bet-history-bonus-bet-icons"></div>
                        </div>
                      </div>
                    </li>
                    <li className="betHistoryListEl-list-item">
                      <div className="bet-history-stake-odds">
                        <b className="bet-history-stake-money">
                          {bet.betAmount}
                        </b>
                      </div>
                    </li>
                    <li className="betHistoryListEl-list-item">
                      <div className="bet-history-odds-title ellipsis">
                        <span>Decimal</span>
                      </div>
                      <b className="bet-history-odds-coeff">
                        {bet.betAmountUSD}
                      </b>
                    </li>
                    <li className="betHistoryListEl-list-item">
                      <div className="bet-history-cashout-block">
                        <div className="bet-history-possible-win-block">
                          <span className="bet-history-title">
                            {t("POSSIBLEWIN")}
                          </span>
                          <span className="bet-history-possible-win">
                            {bet.betAmountUSD} <span>$</span>
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="betHistoryListEl-list-item">
                      <div className="betHistoryListEl-types">
                        <div className="betHistoryListEl-list-item-actions">
                          <div className="bet-history-t-b-row-hk-bc bet-history-actions">
                            <div className="bet-history-cashout">
                              <button
                                className="btn a-color bet-history-cashout-btn"
                                type="button"
                              >
                                <span>{t("CASHOUT")}</span>
                                <span className="bet-history-cashout-btn-money">
                                  {bet.betAmountUSD} $
                                </span>
                              </button>
                            </div>
                            <i
                              className="bet-history-function-icon bc-i-repeat"
                              title={t("REPEATBET")}
                            ></i>
                            <i
                              className="bet-history-function-icon bc-i-edit"
                              title={t("EDITBET")}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="betHistoryListEl-list-item">
                      <i className="competition-icon-arrow-bc bc-i-small-arrow-down"></i>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default BetHistory;
