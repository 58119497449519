import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar4 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar4_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar4_svg__cls-3{fill:#393c54}.avatar4_svg__cls-4{fill:#8f5653}.avatar4_svg__cls-6{fill:#fff}.avatar4_svg__cls-11,.avatar4_svg__cls-9{fill:none;stroke-linecap:round}.avatar4_svg__cls-11{stroke-miterlimit:10;stroke:#515570}.avatar4_svg__cls-9{stroke:#515570;stroke-linejoin:round;stroke-width:1.89px;opacity:.4}.avatar4_svg__cls-11{stroke-width:2px}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#a7aece',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				fill: '#515570',
				opacity: 0.2,
			}}
		/>
		<path
			d='M99.72 51.16a4.33 4.33 0 0 0-1.15-8.5 4.33 4.33 0 0 0-3.14-8 4.33 4.33 0 0 0-5-7 4.33 4.33 0 0 0-6.43-5.6 4.33 4.33 0 0 0-7.64-3.91 4.33 4.33 0 0 0-8.35-2 4.33 4.33 0 0 0-8.58.13l-.72.09a4.34 4.34 0 0 0-8.29 2.25 4.34 4.34 0 0 0-7.48 4.21 4.33 4.33 0 0 0-6.26 5.86A4.33 4.33 0 0 0 32 35.91 4.33 4.33 0 0 0 29.14 44a4.33 4.33 0 0 0-.82 8.54A4.33 4.33 0 0 0 29.56 61a4.33 4.33 0 0 0 3.23 8 4.33 4.33 0 0 0 5 7 4.33 4.33 0 0 0 6.55 5.54 4.33 4.33 0 0 0 7.68 3.83 4.33 4.33 0 0 0 8.37 1.88A4.33 4.33 0 0 0 69 87a4.33 4.33 0 0 0 8.29-2.21 4.33 4.33 0 0 0 7.52-4.12 4.34 4.34 0 0 0 6.33-5.8 4.33 4.33 0 0 0 4.76-7.14 4.33 4.33 0 0 0 2.91-8.07 4.33 4.33 0 0 0 .91-8.53Z'
			className='avatar4_svg__cls-3'
		/>
		<circle cx={89.84} cy={70.87} r={7.5} className='avatar4_svg__cls-4' />
		<path
			d='M64 124a59.75 59.75 0 0 0 37.53-13.2l-2-5.53C98 101.5 93.68 99 88.89 99H39.11c-4.79 0-9.07 2.51-10.68 6.28l-2 5.53A59.75 59.75 0 0 0 64 124Z'
			style={{
				fill: '#ff8475',
			}}
		/>
		<path
			d='M74.26 100.86c0 5.66-4.6 9.25-10.26 9.25s-10.26-3.59-10.26-9.25 4.6-3.42 10.26-3.42 10.26-2.25 10.26 3.42Z'
			className='avatar4_svg__cls-6'
		/>
		<path
			d='M64 88.88v10'
			style={{
				strokeMiterlimit: 10,
				stroke: '#8f5653',
				strokeWidth: 16,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={38.16} cy={70.87} r={7.5} className='avatar4_svg__cls-4' />
		<path
			d='M64 98a26.78 26.78 0 0 1-26.79-26.81v-9.62a26.79 26.79 0 0 1 53.58 0v9.62A26.78 26.78 0 0 1 64 98Z'
			style={{
				fill: '#b56b63',
			}}
		/>
		<path
			d='M63.15 30.12C47.92 30.57 36 43.47 36 58.7a2 2 0 0 0 2 2 2.72 2.72 0 0 0 2.6-2.1 9.28 9.28 0 0 1 9.13-7.6h28.54a9.28 9.28 0 0 1 9.13 7.6 2.72 2.72 0 0 0 2.6 2.06 2 2 0 0 0 2-2v-.6a28 28 0 0 0-28.85-27.94Z'
			className='avatar4_svg__cls-3'
		/>
		<path d='m74.12 63.84 9.09-.24M53.88 63.84l-9.09-.24' className='avatar4_svg__cls-9' />
		<path
			d='M71.55 84a1 1 0 0 1 .94 1.07 8.56 8.56 0 0 1-17 0 1 1 0 0 1 .96-1.07Z'
			className='avatar4_svg__cls-3'
		/>
		<path
			d='M59 90.91a8.52 8.52 0 0 0 10.08 0C68 89.16 66.17 89 64 89s-4 .16-5 1.91Z'
			style={{
				fill: '#f85565',
			}}
		/>
		<path
			d='M69 86H59a1.84 1.84 0 0 1-1.73-2h13.5A1.84 1.84 0 0 1 69 86Z'
			className='avatar4_svg__cls-6'
		/>
		<path d='M60 84h2v3h-2z' className='avatar4_svg__cls-3' />
		<path
			d='m63.39 72.24-3.25 7.44a.69.69 0 0 0 .64 1h6.49a.69.69 0 0 0 .64-1l-3.25-7.44a.7.7 0 0 0-1.27 0Z'
			className='avatar4_svg__cls-4'
		/>
		<path d='M54 72a3 3 0 0 0-6 0M80 72a3 3 0 0 0-6 0' className='avatar4_svg__cls-11' />
	</svg>
);
export default SvgAvatar4;
