import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionIraq = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 900 600'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#ce1126' d='M0 0h900v600H0z' />
		<path fill='#fff' d='M0 200h900v400H0z' />
		<path d='M0 400h900v200H0z' />
		<g fill='#007a3d' transform='translate(-1.009 -.584) scale(2.18978)'>
			<path d='M246.026 120.178c-.558-.295-1.186-.768-1.395-1.054-.314-.438-.132-.456 1.163-.104 2.318.629 3.814.383 5.298-.873l1.308-1.103 1.54.784c.848.428 1.748.725 2.008.656.667-.176 2.05-1.95 2.005-2.564-.054-.759.587-.568.896.264.615 1.631-.281 3.502-1.865 3.918-.773.201-1.488.127-2.659-.281-1.438-.502-1.684-.494-2.405.058-1.618 1.239-3.869 1.355-5.894.299zm5.734-5.242c-.563-.717-1.239-3.424-1.021-4.088.192-.576.391-.691.914-.527.918.287 1.13.92.993 3.064-.107 1.747-.366 2.206-.886 1.551zm-67.516-1.946c-.185 1.311 2.325 4.568 3.458 5.158-.77.345-1.728.188-2.434.576-3.948 3.948-18.367 18.006-21 21.366 7.799.154 16.448-.106 23.761-.44-.007-5.299 5.018-5.572 8.381-7.502 1.73 2.725 6.075 2.516 6.618 6.617 0 4.91.009 12.307.009 17.646h-66.625c-1.172 5.176-5.844 9.125-12.354 7.5 2.014-2.104 5.405-2.827 6.619-5.734 1.024-6.365-2.046-10.296-4.031-13.906 3.284-1.195 3.782-1.494 7.121-3.737-2.344 7.12 6.091 6.338 12.353 6.175.211-2.417.089-5.271-1.766-5.624 2.396-.87 2.794-1.168 6.619-4.412v9.593c14.886 0 30.942-.111 46.139-.111 0-3.002.795-7.824-1.581-7.824-2.269 0-.107 6.173-1.87 6.173h-35.63c0-1.328-.034-4.104-.034-6.104 1.51-1.51 1.331-1.379 11.648-11.697 1.028-1.031 8.266-7.568 14.599-13.713zm89.06-.254c2.487 1.339 4.457 3.191 7.502 3.972-.354 1.261-1.476 1.759-1.77 3.087v26.91c3.402.75 4.118-1.178 5.737-2.205.442 4.307 3.186 8.529 3.088 11.91h-14.559c.002-14.555.002-29.114.002-43.674zm-19.412 14.412s5.296-4.471 5.296-4.643v23.484l3.814-.006c0-8.947-.118-18.022-.118-26.338 1.548-1.549 4.58-3.791 5.338-5.358v42.06c-10.746 0-30.793.012-33.443.012-.493-8.729-.577-17.771 9.6-15.826v-3.563c-.311-.609-.868.147-.998-.645 1.615-1.617 2.163-2.029 6.538-5.852 0 4.612.08 15.5.08 15.5 1.07 0 3.153.004 3.857.004.001.002.036-18.227.036-18.829zm-12.553 18.603c.716 1.075 3.154 1.056 3.04-.755-.411-1.493-3.616-.924-3.04.755z' />
			<circle cx={144.527} cy={161.369} r={2.042} />
			<path d='M207.549 112.779c2.488 1.339 4.457 3.191 7.502 3.971-.353 1.26-1.476 1.76-1.768 3.087v26.911c3.401.749 4.117-1.18 5.736-2.206.441 4.308 3.185 8.528 3.088 11.91h-14.56c.002-14.556.002-29.114.002-43.673z' />
		</g>
	</svg>
);
export default SvgRegionIraq;
