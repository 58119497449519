import * as React from 'react';
import { SVGProps } from 'react';
const SvgNdz = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#622fba' fillRule='nonzero' />
			<path
				fill='#fff'
				d='M18.586 17.376c-.087.027-.171.06-.253.098l-3.676-3.821a1.91 1.91 0 0 0-1.14-2.794V8.186l-.984-.55 2.273-1.315a2.384 2.384 0 0 1 2.388 0l4.519 2.616-1.786 1.033a1.911 1.911 0 0 0-2.686 1.747 1.91 1.91 0 0 0 1.345 1.824zm1.138 0V13.54a1.91 1.91 0 0 0 1.092-2.773l2.03-1.174.97.56A2.372 2.372 0 0 1 25 12.207v7.586c0 .846-.451 1.628-1.185 2.053l-4.09 2.368v-3.192a1.91 1.91 0 0 0-.001-3.646zm-2.227.869a1.91 1.91 0 0 0 1.089 2.777v3.851l-1.392.806a2.384 2.384 0 0 1-2.388 0L9.94 22.863l1.828-1.428a1.91 1.91 0 0 0 1.231.447 1.911 1.911 0 0 0 1.914-1.91 1.91 1.91 0 0 0-1.397-1.838v-3.598c.105-.029.207-.067.304-.113zm-5.118-.079a1.91 1.91 0 0 0-1.226 2.308L8.882 22.25l-.697-.403A2.372 2.372 0 0 1 7 19.793v-7.586c0-.846.451-1.628 1.185-2.053l3.205-1.856.99.553v2.04a1.91 1.91 0 0 0-.001 3.613zM13 13.523a.827.827 0 1 1-.002-1.653.827.827 0 0 1 .002 1.653zm0 7.275a.827.827 0 1 1-.002-1.653.827.827 0 0 1 .002 1.653zm6.155-8.255a.827.827 0 1 1-.002-1.653.827.827 0 0 1 .002 1.653zm0 7.482a.827.827 0 1 1-.002-1.654.827.827 0 0 1 .002 1.654z'
			/>
		</g>
	</svg>
);
export default SvgNdz;
