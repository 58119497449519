import * as React from 'react';
import { SVGProps } from 'react';
const SvgSnx = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#5FCDF9' />
			<path
				fill='#FFF'
				d='M11.092 12.264a.617.617 0 0 0-.493-.24h-3.98a.13.13 0 0 1-.086-.035.11.11 0 0 1-.033-.077v-2.8a.11.11 0 0 1 .033-.077.097.097 0 0 1 .086-.034h4.206c1.06 0 1.975.454 2.747 1.359l1.023 1.303-1.992 2.534-1.511-1.933zm7.351-1.917c.768-.896 1.688-1.346 2.76-1.346h4.193a.102.102 0 0 1 .078.026.1.1 0 0 1 .025.085v2.8c0 .03-.008.056-.025.077a.084.084 0 0 1-.078.034h-3.98a.617.617 0 0 0-.492.24L17.99 15.99l2.945 3.752c.12.141.296.227.477.223h3.98a.09.09 0 0 1 .077.034.14.14 0 0 1 .025.09v2.8a.12.12 0 0 1-.025.077.084.084 0 0 1-.078.034h-4.189c-1.072 0-1.984-.454-2.748-1.359l-2.44-3.108-2.439 3.108c-.768.905-1.688 1.36-2.76 1.36H6.62a.09.09 0 0 1-.078-.035.121.121 0 0 1-.025-.09v-2.8c0-.03.009-.056.025-.077a.084.084 0 0 1 .078-.034h3.98c.189 0 .37-.09.493-.24l2.879-3.67 4.472-5.707z'
			/>
		</g>
	</svg>
);
export default SvgSnx;
