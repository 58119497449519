import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionChristmasIsland = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		viewBox='0 0 1000 500'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#0021ad' d='M0 0h1000v500H0z' />
		<path fill='#1c8a42' d='M0 0h1000v500z' />
		<circle cx={500} cy={250} r={90.32} fill='#ffc639' />
		<path
			fill='#1c8a42'
			d='M444.84 209.43c6.179 8.477 15.542 22.689 23.072 18.979 5.876.02 8.91.433 9.706 4.344 13.86 2.359 37.153-3.487 51.647-22.366 0 0 1.22.161.797-7.015.08-3.125 4.273-2.5 4.374-1.482.585 1.553.484 2.712 1.27 2.752 1.784-.595 4.223-4.596 6.27-7.136.554-1.119.251-2.298.382-3.71 1.079-2.65 3.77-2.076 4.335-.685.514.897.524 1.583 1.028 2.48 2.731 1.824 7.66.12 8.043.12.484-2.227 1.915-2.015 1.915-2.015 1.824-.403 1.109-.242 2.308.383-1.058 12.005 2.359 12.539 2.077 18.697.12 6.874-2.077 8.83-2.077 11.45.696 3.205 10.835 3.316 7.267 6.027-3.134 1.673.01 4.808-4.707 6.028-13.728 6.965-16.359 12.942-16.359 12.942s-3.447 6.511-3.83 6.511c-2.298 4.344-5.21 1.976-6.834 4.092-.806 2.661-1.713 8.558-.1 11.622.806 4.213-.101 6.512-1.11 10.724-.906 8.79-4.394 10.12-4.797 13.204-1.613 3.296.343 18.778-1.19 18.778-10.22.202-18.051-1.955-22.093-2.761 3.94-16.984 2.409-31.901 2.409-33.433-1.008-12.146-18.193-9.193-20.824-10.805-2.178-.464-3.548-2.238-4.314-3.004-2.43-.262-3.337-.816-5.766-1.068-1.21.604-.484 1.23-3.185 2.066-6.854.826-9.878-5.977-9.878-5.977.333-2.288-15.41.463-23.969-1.522-3.507 1.955-5.04 7.74-7.912 8.426-.08 1.794-4.727-1.472-5.665-3.235-.191-5.13 4.476-7.399 4.476-7.399 3.709-2.61 5.906-3.034 7.69-4.838.897-4.465.444-7.77 2.349-11.128 1.612-2.56 3.991-1.36 5.604-2.51 1.733-1.149 2.47-8.668.867-10.724 0 0-6.935-6.259-7.318-6.642-2.258-6.47 2.64-10.644 4.042-10.17z'
		/>
		<path
			fill='#ffc639'
			d='M877.93 97.555c-4.092-16.194-40.721-51.071-68.247-73.265-6.572-4.477-10.855-1.81-9.999 4.573 3.427 5.716 5.998 12 9.425 17.716.957 3.81 2.761 6.477 3.719 10.286 0 0 .282 6.573.857 7.144 8.567 9.434 9.716 17.432 9.716 17.432 4.949 9.526 9.334 16.478 18.002 24.29 9.717 6.098 2.57 25.047 2.853 35.147 0 6.38-4.566 5.625-8.568 4.858-31.528-28.958-62.774-29.049-90.3-37.435-10.766-1.139-10.957 4.002-7.43 6.854 19.243 20.582 37.335 34.582 61.152 46.294l12.005 7.439c4.566 3.81 9.142 7.62 13.708 11.43 10.573 6.854 11.44 13.143 11.44 13.708.282 12.861-6.582 22.86-8.578 26.861-3.608 13.637-10.855 16.006-10.855 16.006-58.874 39.723-89.737 50.004-185.18 37.717-1.431-.756-10.573.766 0 4.576 24.291 8.094 83.961 21.076 141.69-6.26 13.86-9.716 23.132-6.53 33.171-12.346 16.49-10.17 40.035-22.83 44.32-24.251 12.86-6.864 48.864-14.575 57.16-21.44 9.514-.765 19.392-2.015 20.057-10.2 3.125-2.025 7.7-.544 11.097-7.216 7.55-1.31 6.28-4.012 6.28-4.012-1.905-5.332-8.961-7.529-14-11.43-7.44-2.48-12.58-3.245-18.012-.574-1.714.766-3.427 1.522-5.141 2.288 0 0-8.003-1.15-8.003-1.714-17.78-.977-16.046-59.902-22.336-84.478z'
		/>
		<path
			fill='#1c8a42'
			d='M919.63 194.01a4.402 2.751 15.947 0 1-8.396-2.637 4.402 2.751 15.947 0 1 8.396 2.637z'
		/>
		<g fill='#fff'>
			<path
				id='regionChristmasIsland_svg__a'
				d='m188.16 190.94-12.751-11.936-12.904 11.77 1.383-17.405-17.249-2.753 14.475-9.774-8.606-15.197 16.668 5.22 6.518-16.205 6.31 16.287 16.734-5.007-8.8 15.086 14.348 9.96-17.283 2.53 1.158 17.424z'
			/>
			<path d='m233.39 335.53-13.752-9.167-13.391 9.664 4.712-15.568-13.582-9.415 16.667-.446 4.988-15.496 5.595 15.3 16.667-.156-13.21 9.902 5.307 15.382z' />
			<use xlinkHref='#regionChristmasIsland_svg__a' x={2.522} y={269.061} />
			<use xlinkHref='#regionChristmasIsland_svg__a' x={-112.066} y={123.223} />
			<use xlinkHref='#regionChristmasIsland_svg__a' x={108.427} y={85.027} />
		</g>
	</svg>
);
export default SvgRegionChristmasIsland;
