import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar48 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar48_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar48_svg__cls-7{fill:#393c54}.avatar48_svg__cls-4{fill:#fba875}.avatar48_svg__cls-10{fill:#f85565}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#515570',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.5,
				fill: '#393c54',
			}}
		/>
		<path
			d='M106 104.41C104.1 100 99.05 97 93.39 97H34.61C29 97 23.9 100 22 104.41l-.48 2a60 60 0 0 0 85 0Z'
			style={{
				fill: '#8f5653',
			}}
		/>
		<circle cx={92.56} cy={60.67} r={8.29} className='avatar48_svg__cls-4' />
		<circle cx={35.44} cy={60.67} r={8.29} className='avatar48_svg__cls-4' />
		<path
			d='M64 96.15a29.61 29.61 0 0 1-29.6-29.61V47.08a29.6 29.6 0 1 1 59.2 0v19.46A29.61 29.61 0 0 1 64 96.15Z'
			style={{
				fill: '#ffbb94',
			}}
		/>
		<path
			d='M34.4 53.54v-6.46a29.6 29.6 0 1 1 59.2 0v6.46Z'
			style={{
				opacity: 0.7,
				fill: '#8f5653',
			}}
		/>
		<path
			d='M44.56 97c2.1 8.36 10 23.59 19.44 23.59S81.34 105.36 83.44 97Z'
			className='avatar48_svg__cls-7'
		/>
		<path
			d='M64 99.9c-12.79 0-26.38-12.24-26.38-27.34l-1-24.74a27.34 27.34 0 0 1 54.68 0l-1 24.74C90.38 87.66 77.28 99.9 64 99.9Z'
			style={{
				fill: '#fff',
			}}
		/>
		<path
			d='M76 23.25a27.28 27.28 0 0 1 9.34 20.57l-1 24.74c0 15.1-13.1 27.34-26.38 27.34a23.62 23.62 0 0 1-10.44-2.59A25.77 25.77 0 0 0 64 99.9c13.28 0 26.38-12.24 26.38-27.34l1-24.74A27.34 27.34 0 0 0 76 23.25Z'
			style={{
				opacity: 0.2,
				fill: '#393c54',
			}}
		/>
		<path
			d='M60 17h8v7a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1v-7ZM94 44v8h-5a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h5ZM40 45v6a1 1 0 0 1-1 1h-5v-8h5a1 1 0 0 1 1 1Z'
			className='avatar48_svg__cls-7'
		/>
		<circle cx={75.5} cy={59.5} r={5.5} className='avatar48_svg__cls-7' />
		<circle cx={52.5} cy={59.5} r={5.5} className='avatar48_svg__cls-7' />
		<path d='m52 50 12 5 11-5H52z' className='avatar48_svg__cls-10' />
		<path
			d='M61.68 66.51 60 71.28A2 2 0 0 0 61.93 74h4.19a2 2 0 0 0 1.93-2.72l-1.67-4.77a2.49 2.49 0 0 0-4.7 0Z'
			style={{
				fill: '#356cb6',
				opacity: 0.3,
			}}
		/>
		<path d='m57 67-10.5 9H53l4-9zM70.5 67 81 76h-6.5l-4-9z' className='avatar48_svg__cls-10' />
		<circle cx={68} cy={82} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={75} cy={82} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={81} cy={84} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={68} cy={88} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={68} cy={94} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={60} cy={82} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={53} cy={82} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={47} cy={84} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={60} cy={88} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={60} cy={94} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={53.5} cy={30.5} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={53.5} cy={36.5} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={53.5} cy={42.5} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={45.5} cy={36.5} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={45.5} cy={42.5} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={60.5} cy={44.5} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={74.5} cy={30.5} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={74.5} cy={36.5} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={74.5} cy={42.5} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={82.5} cy={36.5} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={82.5} cy={42.5} r={1.5} className='avatar48_svg__cls-7' />
		<circle cx={67.5} cy={44.5} r={1.5} className='avatar48_svg__cls-7' />
	</svg>
);
export default SvgAvatar48;
