import * as React from 'react';
import { SVGProps } from 'react';
const SvgChips = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#598182' fillRule='nonzero' />
			<path
				fill='#fff'
				d='M13.548 25.408a295794.354 295794.354 0 0 0 2.536-3.277c.949.148 1.884.064 2.778-.324.323-.14.612-.358.916-.542.043-.026.083-.06.116-.085l3.072 3.038c-.255.17-.508.354-.777.512a9.368 9.368 0 0 1-3.379 1.17 10.47 10.47 0 0 1-2.122.08 11.919 11.919 0 0 1-3.14-.572zm-.966-.376a9.992 9.992 0 0 1-.996-.514 8.953 8.953 0 0 1-3.71-4.15 9.975 9.975 0 0 1-.787-2.86 12.322 12.322 0 0 1-.062-2.308c.123-1.975.724-3.79 1.935-5.387 1.118-1.474 2.586-2.483 4.323-3.13a9.661 9.661 0 0 1 2.565-.59c.663-.057 1.333-.114 1.996-.085.41.017.813.057 1.21.12a683038.836 683038.836 0 0 1-2.572 3.734 5.29 5.29 0 0 0-2.41.816c-1.33.844-2.126 2.06-2.5 3.56a7.241 7.241 0 0 0-.216 1.934c.009.342.038.677.089 1.004l-.308.447c-.248.36.057.836.498.778l.112-.014c.258.729.649 1.41 1.19 2.035.336.386.711.71 1.124.97l-1.445 3.491a.49.49 0 0 0-.036.15zm7.443-18.698a8.188 8.188 0 0 1 2.496 1.141c.138.094.264.205.396.308.025.019.051.036.083.058-.042.048-.066.08-.095.108-.911.898-1.824 1.793-2.732 2.694-.1.1-.163.116-.284.03a4.28 4.28 0 0 0-1.201-.596l1.283-3.375.034-.087a.488.488 0 0 0 .02-.281zm-3.704 10.948-4.753.621L19.476 6.42l.044.02-3.055 8.031 5.575-.88-8.899 11.51-.043-.03 3.223-7.789z'
			/>
		</g>
	</svg>
);
export default SvgChips;
