import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionPoland = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 16 10'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#fff' d='M0 0h16v10H0z' />
		<path fill='#dc143c' d='M0 5h16v5H0z' />
	</svg>
);
export default SvgRegionPoland;
