import * as React from 'react';
import { SVGProps } from 'react';
const SvgNlg = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#2AB0FD' />
			<path
				fill='#FFF'
				d='M25.515 14.804c.165-.002.27.02.336.08.07.064.098.168.113.331.466 5.379-3.602 10.241-8.998 10.74-5.564.515-10.394-3.5-10.922-9.08C5.53 11.456 9.599 6.54 15.03 6.046c3.076-.28 5.732.686 7.968 2.816.023.022.018.114-.008.14a119.48 119.48 0 0 1-1.52 1.521c-.029.028-.133.024-.165-.005-2.307-2.114-4.98-2.74-7.903-1.675C10.446 9.92 8.755 12.15 8.42 15.29c-.45 4.249 2.757 7.994 7.035 8.317 3.878.292 7.392-2.453 8.035-6.285.006-.038-.08-.133-.124-.133-2.395-.007-4.79-.005-7.184-.014-.062 0-.177-.115-.179-.178a53.477 53.477 0 0 1 0-2.008c.001-.06.108-.168.167-.169 1.596-.009 7.826.005 9.344-.017z'
			/>
		</g>
	</svg>
);
export default SvgNlg;
