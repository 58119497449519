import * as React from 'react';
import { SVGProps } from 'react';
const SvgQnt = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='currentColor' />
			<g fill='#FFF'>
				<path d='m10.556 6.744 5.226 6.251 3.506-2.106S15.054 5.113 13.93 5.52a186.291 186.291 0 0 1-3.374 1.223zm8.864 4.145s.795-1.427 1.192-3.33c0 0 3.969 1.63 2.447 5.708l-3.638-2.378z' />
				<path d='m23.06 13.267.33 4.28 1.522-.203s.397-.136.33-.883c-.066-.816-.198-2.515-.198-2.515s.066-.407-.86-.543c-1.125-.204-1.125-.136-1.125-.136zm.33 4.281-3.043 2.514 1.39 2.786s.33 1.087 1.323-.136c.926-1.223 1.323-1.427 1.256-2.174-.066-.748-.264-2.515-.926-2.99zm-3.11 2.514-4.167-1.903-3.639 7.543s-.264.543.662.611c.926.136 2.448.476 3.242-.34.794-.747 3.903-5.911 3.903-5.911zm-4.167-1.971-.397-5.028-8.997.612s-.53.068-.463 1.087c.066 1.359.198 2.854.198 2.854s-.066.611 1.125.68c1.19.067 8.402.067 8.534-.205z' />
			</g>
		</g>
	</svg>
);
export default SvgQnt;
