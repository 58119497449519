import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomAnonim = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		id='CustomAnonim_svg___x32_'
		width='1em'
		height='1em'
		viewBox='0 0 512 512'
		className='svg-icon'
		{...props}>
		<style>{'.CustomAnonim_svg__st0{fill:#000}'}</style>
		<path
			d='M179.335 226.703c22.109.219 37.484 21.172 44.047 27.047 1.578 1.828 3.875 2.984 6.469 2.984a8.639 8.639 0 0 0 8.641-8.641c0-2.656-1.219-5.031-3.125-6.609l.016-.031c-5-4.781-20.547-25.688-55.734-25.688s-43.609 26.406-44.5 29.594c-.016.156-.094.297-.094.453a2.422 2.422 0 0 0 2.438 2.438c1.094 0 1.844-.875 2.266-1.813 2.732-5.39 10.623-20.031 39.576-19.734zM331.554 216.813c-35.188 0-50.734 20.875-55.734 25.656l.016.047c-1.906 1.578-3.125 3.922-3.125 6.594 0 4.781 3.875 8.641 8.625 8.641a8.614 8.614 0 0 0 6.516-2.969c6.531-5.891 21.906-26.828 44.016-27.063 28.953-.281 36.844 14.344 39.578 19.75.422.922 1.172 1.797 2.281 1.797a2.424 2.424 0 0 0 2.422-2.422c0-.172-.063-.328-.094-.469-.892-3.187-9.314-29.562-44.501-29.562z'
			className='CustomAnonim_svg__st0'
		/>
		<path
			d='m331.054 370.563-36.141-2.063-17.172-10.781s-10.031 5.922-12.328 7.297h-18.188c-2.297-1.375-12.297-7.297-12.297-7.297l-.375.234c-.266-.25-.438-.563-.75-.797-3.25-2.344-5.047-4.656-4.906-6.313.297-3.438 6.609-8.219 11.063-10.391l4.141-1.953v-50.094c0-9.156-6.094-18.391-17.594-26.688-12.266-8.844-30.875-16.375-41.094-12.953a7.157 7.157 0 0 0-4.547 9.078c1.188 3.781 5.344 5.875 9.109 4.688 3.156-.953 16.75 2.641 28.5 11.313 6.969 5.109 11.094 10.547 11.094 14.563v41.266c-5.438 3.375-14.25 10.281-15.125 19.859-.375 4.25.719 10.313 7.297 16.469l-4 2.5-36.156 2.063s-36.203-28.922-40.297-34.813l24.578 58.234s64.594.906 67.234.609c12.313-10.016 23.219-21.391 23.219-21.391s10.906 11.375 23.203 21.391c2.656.297 67.25-.609 67.25-.609l24.563-58.234c-4.078 5.891-40.281 34.813-40.281 34.813z'
			className='CustomAnonim_svg__st0'
		/>
		<path
			d='M181.772 319.344c20.031 0 32.766-16.594 32.766-22.219s-12.734-22.203-32.766-22.203-32.781 16.578-32.781 22.203 12.75 22.219 32.781 22.219zM325.335 319.344c20.031 0 32.781-16.594 32.781-22.219s-12.75-22.203-32.781-22.203-32.766 16.578-32.766 22.203 12.735 22.219 32.766 22.219z'
			className='CustomAnonim_svg__st0'
		/>
		<path
			d='m482.46 167.234-88.891-22.219s-11-76.734-12.781-89.219c-1.766-12.453-12.484-46.344-51.703-46.344H182.897c-39.188 0-49.906 33.891-51.703 46.344-1.734 12.484-12.75 89.219-12.75 89.219l-88.922 22.219c-37.766 8.906-39.344 34.719-4.453 34.719h70.734c-14.891 42.609-48.75 141.25-73.266 227.125L69.022 419v58.594l46.484-22.219 18.188 42.438 21.406-42.844c28.813 31.219 65.484 47.578 101.219 47.578 36.109 0 72.266-14.031 100.656-43.172l19.25 38.438 18.188-42.438 46.469 22.219V419l46.484 10.078c-24.547-85.875-58.375-184.516-73.266-227.125h72.813c34.906 0 33.344-25.812-4.453-34.719zm-95 130.266c0 120.625-61.375 176.75-124.359 180.484l28.359-43.953h-72.828l28.219 43.734c-60.625-5.938-121.688-68.625-121.703-180.656-1.297-40.516 4.797-72.406 17.969-95.156H369.508c13.186 22.719 19.28 54.641 17.952 95.547z'
			className='CustomAnonim_svg__st0'
		/>
	</svg>
);
export default SvgCustomAnonim;
