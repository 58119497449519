import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomCrash = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		aria-hidden='true'
		className='svg-icon'
		viewBox='0 0 128 128'
		{...props}>
		<path
			fill='#ffa000'
			stroke='#f44336'
			strokeMiterlimit={10}
			strokeWidth={3}
			d='m68.27 8.51 15.84 33.16 35.33-31.88-18.06 45.64 19.67-5.31-18.65 26.51 17.03 5.87-17.4 8.27 16.93 29.03-32.19-19.91-6.9 21.54L69.65 98.4l-29 21.78 8.82-24.85-39.97 8.04L39.42 81.1 7.89 58.4l35.96 3.11L10.6 10.52 64 45.51z'
		/>
		<radialGradient
			id='CustomCrash_svg__a'
			cx={77.587}
			cy={75.735}
			r={26.365}
			gradientUnits='userSpaceOnUse'>
			<stop offset={0} stopColor='#fffde7' />
			<stop offset={1} stopColor='#fff176' />
		</radialGradient>
		<path
			fill='url(#CustomCrash_svg__a)'
			stroke='#ffeb3b'
			strokeMiterlimit={10}
			strokeWidth={2}
			d='m72.63 34.77 9.54 24.71 18.88-21.31-14.19 29.74 17.21-5.17-15.95 15.85 13.36 4.39-12.8 1.89 10.19 14.44-16.56-10.58-3.81 12.32-5.87-14.09-14.65 11.16L64 84.45l-23 5.02 16.95-10.28-22.11-10.63 24.49 2.28-20.11-31.55L71.1 60.17z'
		/>
	</svg>
);
export default SvgCustomCrash;
