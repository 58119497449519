import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionSerbia = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 756.13 504.09'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<defs>
			<clipPath id='regionSerbia_svg__a'>
				<path fill='none' d='M0 167.88h756.13v336.26H0z' />
			</clipPath>
			<clipPath id='regionSerbia_svg__b'>
				<path fill='none' d='M0 335.51h756.13v168.58H0z' />
			</clipPath>
		</defs>
		<path fill='#e40046' fillRule='evenodd' d='M0 0h756.13v504.08H0z' />
		<g clipPath='url(#regionSerbia_svg__a)'>
			<path fill='#012169' fillRule='evenodd' d='M0 167.62h756.13v336.46H0z' />
		</g>
		<g clipPath='url(#regionSerbia_svg__b)'>
			<path fill='#fff' fillRule='evenodd' d='M0 334.79h756.13v169.29H0z' />
		</g>
		<g fillRule='evenodd'>
			<g fill='#ffc72c' stroke='#21231e' strokeWidth={0.667}>
				<path
					strokeMiterlimit={23.107}
					d='M336.045 119.953c1.051-2.281.2-5.461-.136-6.18-.176-.363.34-1.164.562-1.48 7.153-10.055 7.278-10.945 2.344-14.715-4.445-3.4-14.5-6.7-21.687-8.93-1.5-1.133-2.449-2.676-3.809-3.949 13.817.988 28.79 6.558 36.34 13.066 3.988 3.442-.863 10.391-6.809 20.961-3.671 2.633-5.394 3.86-6.8 1.227z'
				/>
				<path
					strokeMiterlimit={22.925}
					d='M338.22 120.751a18.817 18.817 0 0 0 .691-2.593 8.555 8.555 0 0 0-.328-4.485 70.3 70.3 0 0 0 4.832-7.332c.953-1.785 1.692-3.773 1.129-5.808-.6-2.172-2.488-3.649-4.2-4.957a35.592 35.592 0 0 0-7.544-4.106c-6.191-2.551-11.114-3.8-14.723-3.91l2.289 2.109c6.715 2.157 14.637 5 18.446 7.911 4.933 3.765 4.808 4.66-2.344 14.715-.222.312-.738 1.117-.566 1.48a9.6 9.6 0 0 1-.074 6.316c.617 1.153 1.511.551 2.39.661z'
				/>
				<path
					strokeMiterlimit={22.925}
					d='M277.792 75.986c-.063-1.273.023-1.054.215.907a.876.876 0 0 1 .8-.567c.582.012.934.891.989 2.871-.032.512-.071 1.016-.106 1.52 18.325-.406 29.187 2.355 31.988 7.656 2.305 4.363-1.613 11.586-7.527 19.832-.715 1-.695.914 0 1.738 1 1.188 2.078 3.2 1.269 4.969a13.179 13.179 0 0 0 11.567.82 6.836 6.836 0 0 1 3.043-3.144c.223-.148 1.016-.539 1.1-.793 1.953-6.242 3.918-12.016 5.6-17.391 5.289-16.879-41.694-18.426-48.933-18.418z'
				/>
				<g strokeLinejoin='round'>
					<path d='M326.18 148.6a53.471 53.471 0 0 1 6.258-10.277c-6.512 4.809-9.933 4.086-10.41-2.175a4.618 4.618 0 0 0-1.429-.137q-7.454 11.536-14.5-3.195c-6.4 10.09-11.886 9.91-16.195-.618-7.379 9.2-13.594 9.231-18.649.2v-1.148c.285.125.09.164-.57.071l-.109-.207c-.2.039-.379.074-.543.1a22.717 22.717 0 0 1-.547-.1l-.106.207c-.66.093-.855.054-.574-.071v1.15c-5.051 9.031-11.266 9-18.644-.2-4.313 10.527-9.8 10.707-16.2.618q-7.042 14.73-14.5 3.195a4.612 4.612 0 0 0-1.43.137c-.477 6.261-3.9 6.984-10.41 2.175a53.622 53.622 0 0 1 6.258 10.277c18.757-.777 37.453-1.75 56.144-2.726 18.692.976 37.383 1.949 56.145 2.726z' />
					<path d='m333.87 133.491-2.043-.339c-3.918 9.562-7.433 9.8-8.1.7.879-.317.532-1.836 1.524-1.231.523.321 1.183-.586.75-1.086a2.152 2.152 0 0 0-3.07.219c-.457-1.453-1.579-2.129-2.977-1.519-.605.265-.4 1.355.207 1.293.981-.1.586.914.895 1.6-6.957 9.063-10.512 7.985-9.645-3.922a39.583 39.583 0 0 1-5.308-1.293c-6.4 12.84-10.953 13.395-14.641 1.872.789-.59.566-2.067 1.719-1.59.652.265 1.2-.879.617-1.371-1.348-1.133-2.809-.75-3.793.707-.785-1.571-2.187-2.137-3.668-1.18-.637.41-.242 1.617.438 1.434 1.3-.348.718 1.363 1.707 1.949-2.829 10.832-17.11 11.562-14.774-4.477l-3.672.133-3.676-.133c2.336 16.039-11.941 15.309-14.769 4.477.988-.586.4-2.3 1.707-1.949.676.183 1.074-1.024.433-1.434-1.48-.957-2.878-.391-3.668 1.18-.98-1.457-2.441-1.84-3.789-.707-.582.492-.035 1.636.614 1.371 1.156-.477.929 1 1.722 1.59-3.687 11.523-8.242 10.969-14.64-1.872a39.781 39.781 0 0 1-5.309 1.293c.867 11.906-2.691 12.984-9.644 3.922.3-.683-.086-1.7.89-1.6.61.062.817-1.028.211-1.293a2.066 2.066 0 0 0-2.976 1.519 2.153 2.153 0 0 0-3.071-.219c-.433.5.227 1.407.746 1.086.993-.605.649.914 1.524 1.231-.66 9.1-4.176 8.863-8.094-.7l-2.043.339a10.171 10.171 0 0 0 2.11 6.395c5.879 7.457 10.253 5.766 10.413-2.586 7.7 9.02 13.516 7.875 14.559-1.633 6.476 12 16.867 6.258 17.081-1.019 2.852 10.574 18.657 10.5 19.668-1.656 1.011 12.156 16.812 12.23 19.667 1.656.215 7.277 10.606 13.016 17.079 1.019 1.047 9.508 6.867 10.652 14.562 1.633.16 8.352 4.531 10.043 10.414 2.586a10.164 10.164 0 0 0 2.109-6.395z' />
				</g>
				<path
					strokeMiterlimit={22.926}
					d='M283.2 121.291c-.867-.543-1.039-.4-1.316-.754a2.587 2.587 0 0 1-.25-1.93c.453-2.906-1.3-2.5-3.262-1.215a27.992 27.992 0 0 0-2.816 2.914c-.844.778-1.547.844-1.758 1.153-.024 1.113-.031 2.078-.024 2.515-.008.434 0 1.4.024 2.512.211.308.914.375 1.758 1.156a28.252 28.252 0 0 0 2.816 2.91c1.965 1.285 3.715 1.692 3.262-1.215a2.588 2.588 0 0 1 .25-1.929c.277-.356.449-.211 1.316-.754a3.843 3.843 0 0 0 1.266-1.235 8.2 8.2 0 0 0 0-2.9 3.87 3.87 0 0 0-1.266-1.228z'
				/>
			</g>
			<path
				fill='#21231e'
				d='m273.71 124.421.074.489a9.665 9.665 0 0 1 5.293 2.394 7.974 7.974 0 0 0-5.367-2.883m0-.89.074-.488a9.7 9.7 0 0 0 5.293-2.4 7.973 7.973 0 0 1-5.367 2.886'
			/>
			<g stroke='#21231e'>
				<g fill='#ffc72c' strokeWidth={0.667}>
					<path
						strokeMiterlimit={22.926}
						d='M281.662 117.281a5.618 5.618 0 0 0-1.9 1.1c-.886.856-.312 2.27.832 3.645a3.829 3.829 0 0 1 0 3.894c-1.144 1.371-1.718 2.789-.832 3.645a5.665 5.665 0 0 0 1.9 1.1 4.177 4.177 0 0 0-.032-1.324 2.586 2.586 0 0 1 .25-1.926c.278-.359.45-.215 1.317-.757a3.839 3.839 0 0 0 1.265-1.235c.282-.457.129-.953.129-1.449a2.537 2.537 0 0 0-.129-1.449 3.878 3.878 0 0 0-1.265-1.235c-.867-.539-1.039-.4-1.317-.75a2.605 2.605 0 0 1-.25-1.933 4.158 4.158 0 0 0 .032-1.321z'
					/>
					<path
						strokeMiterlimit={23.107}
						d='M204.027 119.956c-1.051-2.281-.2-5.461.141-6.18.172-.363-.34-1.168-.567-1.48-7.152-10.055-7.273-10.946-2.343-14.715 4.449-3.4 14.5-6.7 21.687-8.93 1.5-1.133 2.449-2.676 3.808-3.949-13.812.988-28.785 6.558-36.339 13.066-3.989 3.441.863 10.391 6.808 20.961 3.676 2.633 5.4 3.86 6.8 1.227z'
					/>
					<path
						strokeMiterlimit={22.925}
						d='M201.854 120.751a18.225 18.225 0 0 1-.687-2.593 8.6 8.6 0 0 1 .324-4.485 70.951 70.951 0 0 1-4.832-7.332c-.949-1.785-1.688-3.773-1.129-5.808.6-2.172 2.492-3.649 4.2-4.957a35.494 35.494 0 0 1 7.543-4.106c6.191-2.551 11.114-3.8 14.718-3.91l-2.289 2.109c-6.71 2.157-14.632 5-18.441 7.911-4.934 3.765-4.809 4.66 2.344 14.715.222.312.738 1.117.562 1.48a9.637 9.637 0 0 0 .078 6.316c-.617 1.153-1.515.551-2.394.661z'
					/>
					<path
						strokeMiterlimit={22.925}
						d='M262.282 75.986c.063-1.273-.027-1.054-.215.907a.885.885 0 0 0-.808-.567c-.578.012-.93.891-.988 2.871.035.512.07 1.016.1 1.52-18.32-.406-29.183 2.355-31.984 7.656-2.305 4.363 1.613 11.586 7.527 19.832.715 1 .692.914 0 1.738-1 1.188-2.074 3.2-1.265 4.969a13.187 13.187 0 0 1-11.571.82 6.829 6.829 0 0 0-3.039-3.144c-.223-.148-1.02-.539-1.1-.793-1.957-6.242-3.918-12.016-5.6-17.391-5.285-16.879 41.7-18.426 48.937-18.418z'
					/>
					<path
						strokeMiterlimit={24.827}
						d='m265.467 145.836-.656 18.292c18.148.508 39.644 1.328 57.789 1.836l3.621-17.082c-43.625-2.754-63.73-3.625-60.753-3.047z'
					/>
					<g strokeMiterlimit={22.925}>
						<path d='M265.462 145.841v.1q-14.537.645-51.605 2.949l3.621 17.082c16.433-.461 35.609-1.175 52.562-1.687 16.95.512 36.129 1.226 52.563 1.687l3.617-17.082q-37.107-2.343-51.606-2.949v-.1c.981-.191-.539-.226-4.57-.085-4.035-.141-5.551-.106-4.574.085z' />
						<path d='M270.042 147.861h-4.656l-.664.008h-1.2c-.484.008-.968.012-1.453.02h-.254l-.617.008h-.453l-.637.012h-.023l-.664.011h-.211l-.445.012h-.145l-.515.012-.661.015h-.031l-.629.016h-.285c-.785.015-1.566.039-2.347.058l-.317.008-.894.028h-.1l-.789.027-.519.016-.372.011h-.1l-.789.024h-.047l-.652.023h-.031l-.625.024-.422.015-.883.035q-1.746.071-3.481.149l-.164.008q-2.56.123-5.093.262l-.1.008-.254.011q-5.086.294-10.114.668l-.125.008-.043.008q-7.365.551-14.589 1.3c-2.743.258-3.266-2.973 0-3.273q7.224-.75 14.589-1.305h.043q10.108-.762 20.45-1.16l.566-.024h.09l.187-.008.653-.023h.05q4.226-.147 8.5-.234l.446-.008h.023l.758-.012h.117l.637-.008.262-.008h.4l.5-.008h.394l1.754-.024h1.328c.645-.008 1.289-.011 1.938-.015h5.441c.645 0 1.293.008 1.938.015h1.328l1.754.024h.395l.5.008h.4l.262.008.636.008h.124l.754.012h.024l.445.008q4.266.081 8.5.234h.043l.656.023.188.008h.086l.57.024q10.342.392 20.449 1.16h.04q7.365.557 14.589 1.305c3.27.3 2.742 3.531 0 3.273q-7.224-.744-14.589-1.3l-.04-.008-.125-.008q-5.026-.381-10.113-.668l-.254-.011-.094-.008q-2.543-.141-5.1-.262l-.164-.008q-1.74-.077-3.48-.149l-.883-.035-.426-.015-.625-.024h-.027l-.656-.023h-.043l-.793-.024h-.1l-.371-.011-.519-.016-.793-.027h-.1l-.895-.028-.32-.008c-.777-.019-1.562-.043-2.344-.058h-.285l-.629-.016h-.031l-.66-.015-.516-.012h-.144l-.445-.012h-.215l-.66-.011h-.024l-.637-.012h-.453l-.617-.008h-.254c-.484-.008-.968-.012-1.453-.02h-1.199l-.664-.008h-4.66z' />
					</g>
				</g>
				<g fill='#fff' strokeWidth={0.667}>
					<path
						strokeMiterlimit={22.926}
						d='M227.286 107.871a3.528 3.528 0 1 1-3.528 3.528 3.53 3.53 0 0 1 3.528-3.528z'
					/>
					<g strokeMiterlimit={22.925}>
						<path d='M197.224 115.261a3.278 3.278 0 1 1-3.277 3.278 3.279 3.279 0 0 1 3.277-3.278z' />
						<path d='M194.094 109.271a3.278 3.278 0 1 1-3.277 3.278 3.277 3.277 0 0 1 3.277-3.278z' />
						<path d='M191.144 103.221a3.278 3.278 0 1 1-3.277 3.278 3.28 3.28 0 0 1 3.277-3.278z' />
					</g>
				</g>
				<path
					fill='#ffc72c'
					strokeMiterlimit={22.926}
					strokeWidth={0.667}
					d='M270.045 119.691a4.286 4.286 0 1 1-4.286 4.282 4.286 4.286 0 0 1 4.286-4.282z'
				/>
				<path
					fill='#ffc72c'
					strokeMiterlimit={22.961}
					strokeWidth={0.668}
					d='M249.056 114.467c1.895-.128 1.426 3.243 1.051 3.993-.488.98-.941 1.011-1.555.105-.472-.695-1.386-3.969.5-4.1z'
				/>
				<path
					fill='#fff'
					strokeMiterlimit={22.978}
					strokeWidth={0.667}
					d='M249.173 116.288c4.449-.3 3.347 7.617 2.469 9.378-1.145 2.305-2.211 2.375-3.657.25-1.109-1.632-3.265-9.328 1.188-9.629z'
				/>
				<path
					fill='#ffc72c'
					strokeMiterlimit={24.041}
					strokeWidth={0.668}
					d='M213.293 119.227c1.808-.574 2.156 2.809 1.973 3.625-.243 1.07-.676 1.207-1.489.477-.621-.563-2.293-3.527-.484-4.1z'
				/>
				<path
					fill='#fff'
					strokeMiterlimit={24.058}
					strokeWidth={0.667}
					d='M213.845 120.969c4.253-1.356 5.07 6.593 4.636 8.519-.566 2.508-1.582 2.832-3.5 1.109-1.465-1.316-5.39-8.277-1.14-9.629z'
				/>
				<g strokeWidth={0.667}>
					<path
						fill='#ffc72c'
						strokeLinejoin='round'
						d='M260.092 79.053A62.3 62.3 0 0 0 231.112 83c-8.148 3.234-7.351 12.68 2.586 25.793a13.789 13.789 0 0 0-1.492 6.109c1.051-.058 1.789-.031 2.715-.055-.719-1.585.086-3.531 1-4.9.6-.9.715-.738 0-1.738-5.921-8.249-9.836-15.472-7.531-19.835 2.8-5.3 13.663-8.063 31.984-7.656-.032-.485-.067-.973-.1-1.465a8.363 8.363 0 0 0-.184-.2z'
					/>
					<g fill='#fff' strokeMiterlimit={22.925}>
						<path d='M213.754 81.74a3.4 3.4 0 1 1-3.4 3.4 3.4 3.4 0 0 1 3.4-3.4z' />
						<path d='M220.433 79.561a3.4 3.4 0 1 1-3.4 3.4 3.4 3.4 0 0 1 3.4-3.4z' />
					</g>
				</g>
				<g fill='#ffc72c'>
					<path
						strokeMiterlimit={22.925}
						strokeWidth={0.667}
						d='M221.313 82.463c.336.45.676.9 1.016 1.352-5.375 2.949-7.6 6.059-7.074 9.184.09.539 1.723 6.261 5.375 17.355a32.363 32.363 0 0 1 5.578 4.629c-.137.152.4.636.238.769.371 1.7-1.539 1.352-2.82.93a10.718 10.718 0 0 0-3.586-4.094c-.223-.148-1.019-.539-1.1-.793-1.957-6.242-3.918-12.016-5.6-17.391-1.692-5.4 1.973-9.227 7.973-11.941z'
					/>
					<path
						strokeLinejoin='round'
						strokeWidth={0.667}
						d='m200.1 122.558-.2 1.089a10.58 10.58 0 0 1-4.015.274c1.859-1.817 3.242-2.2 4.219-1.364z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.668}
						d='M199.932 120.071c.981.219 1.074.383 1.5.457a2.242 2.242 0 0 0 1.828-.4c2.129-1.273 2.176 1.024 1.348 2.742a4.176 4.176 0 0 0 .277 3.723c.676 1.262.535 1.836.059 2.176a45.1 45.1 0 0 1-.934.6c-.57.676-1.008.559-1.086-.324-2.125-.875-2.828-2.91-3.461-4.7-.933-2.621-4.308-3.242.469-4.269z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.667}
						d='M200.764 120.161c1.852.657 2.059-.367 3.3-.316-.492 2.828-.989 4.293-3.942 2.777a3.389 3.389 0 0 0-4.109 1.3c-.141.332-1.09-.359-1.438-1.117-1.449-3.168 3.707-3.523 6.184-2.641z'
					/>
				</g>
				<g fill='#fff' strokeWidth={0.667}>
					<g strokeMiterlimit={22.926}>
						<path d='M257.125 71.744a3.387 3.387 0 1 1-3.387 3.391 3.387 3.387 0 0 1 3.387-3.391z' />
						<path d='M250.185 71.744a3.387 3.387 0 1 1-3.391 3.391 3.387 3.387 0 0 1 3.391-3.391zm-6.94.547a3.387 3.387 0 1 1-3.387 3.387 3.384 3.384 0 0 1 3.387-3.387z' />
						<path d='M236.845 73.514a3.389 3.389 0 1 1-3.386 3.391 3.393 3.393 0 0 1 3.386-3.391z' />
					</g>
					<g strokeMiterlimit={22.925}>
						<path d='M194.754 90.967a3.4 3.4 0 1 1-3.4 3.4 3.4 3.4 0 0 1 3.4-3.4z' />
						<path d='M200.584 87.291a3.4 3.4 0 1 1-3.4 3.4 3.4 3.4 0 0 1 3.4-3.4z' />
						<path d='M207.214 84.182a3.4 3.4 0 1 1-3.4 3.4 3.4 3.4 0 0 1 3.4-3.4zM190.244 96.5a3.4 3.4 0 1 1-3.4 3.4 3.4 3.4 0 0 1 3.4-3.4z' />
					</g>
				</g>
				<path
					fill='#ffc72c'
					strokeMiterlimit={22.925}
					strokeWidth={0.667}
					d='m257.432 155.181 12.6-6.3 12.606 6.3-12.606 6.3z'
				/>
				<path
					fill='#012169'
					strokeMiterlimit={22.926}
					strokeWidth={0.667}
					d='M270.04 151.32c3.133 0 5.668 1.695 5.668 3.781s-2.535 3.781-5.668 3.781-5.672-1.691-5.672-3.781 2.539-3.781 5.672-3.781z'
				/>
				<path
					fill='#ffc72c'
					strokeMiterlimit={22.968}
					strokeWidth={0.667}
					d='m229.42 156.769 12.2-7.055 12.965 5.52-12.2 7.055z'
				/>
				<path
					fill='#e40046'
					strokeMiterlimit={22.926}
					strokeWidth={0.666}
					d='M241.758 152.144c3.129-.195 5.765 1.34 5.89 3.426s-2.3 3.929-5.429 4.117-5.762-1.34-5.891-3.426 2.305-3.93 5.43-4.117z'
				/>
				<g strokeMiterlimit={22.925} strokeWidth={0.667}>
					<path
						fill='#ffc72c'
						d='m214.754 151.7 13.175 4.989-10.878 7.277c-2.821-.937-5.055-10.52-2.3-12.266z'
					/>
					<path
						fill='#012169'
						d='M215 154.131c3.117-.32 5.816 1.106 6.027 3.184s-2.145 4.02-5.262 4.336c-2.023.207-3.281-1.563-3.5-3.445s.6-3.86 2.735-4.075z'
					/>
					<path
						fill='#fff'
						d='M256.362 157.511a1.77 1.77 0 1 1-1.769 1.774 1.772 1.772 0 0 1 1.769-1.774zm-27.019 1.5a1.768 1.768 0 1 1-1.769 1.77 1.769 1.769 0 0 1 1.769-1.77zm-.95-8.17a1.77 1.77 0 1 1-1.769 1.77 1.771 1.771 0 0 1 1.769-1.77zm27.899-1.15a1.77 1.77 0 1 1-1.769 1.77 1.771 1.771 0 0 1 1.769-1.77z'
					/>
				</g>
				<g fill='#ffc72c'>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.668}
						d='M208.415 133.841c1.336-.406 3.434 2.555 3.93 2.5.656-1.695-.683-5.742-3.066-8.945-1.618-.527-1.762-1.566-2.1-3.977-.1-.718-1.484-.222-1.508 1a10.2 10.2 0 0 0 .164 3.164 1.13 1.13 0 0 1-.441 1.27c2.519 1 3.137 2.223 3.019 4.98z'
					/>
					<path
						strokeLinejoin='round'
						strokeWidth={0.668}
						d='M207.216 123.461a5.248 5.248 0 0 0-.356 1.856 2.607 2.607 0 0 0 .606 1.773 5.76 5.76 0 0 0 1.664 1.391 4.134 4.134 0 0 1 1.258 1.641 5.881 5.881 0 0 1 .66 2.175c.051 1.969.437 3.688 1.34 4.028a1.774 1.774 0 0 0 1.281.082 4.693 4.693 0 0 0-.4-1.348 5.838 5.838 0 0 1-.871-3.813c.125-.636.539-.875.464-1.46-.14-1.137-1.039-3.313-2.351-3.313s-1.906-.141-2.578-1.855a4.835 4.835 0 0 0-.719-1.157z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.663}
						d='M202.322 128.177a4.617 4.617 0 0 1 1.183-.625 3.21 3.21 0 0 1 2.11-.1c3.109.937 4.472 6.422 1.062 7.738-.668.082-1.289-.52-1.891-1.848a10.2 10.2 0 0 0-2.277-3.707c-.492-.625-.512-1.148-.187-1.461z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.662}
						d='M203.887 128.541c2.989-1.156 5.188 4.528 2.4 5.606-1.5.582-1.645-1.282-1.934-2.317-.394-1.394-3.332-2.176-.465-3.289z'
					/>
				</g>
				<path
					fill='#012169'
					strokeMiterlimit={23.191}
					strokeWidth={0.667}
					d='M204.206 129.384c1.457-.563 3.457 2.824 1.633 3.593a1.881 1.881 0 0 1-2.6-1.183 1.736 1.736 0 0 1 .967-2.41z'
				/>
				<path
					fill='#ffc72c'
					strokeMiterlimit={22.968}
					strokeWidth={0.667}
					d='m310.656 156.769-12.195-7.058-12.961 5.519 12.191 7.059z'
				/>
				<path
					fill='#e40046'
					strokeMiterlimit={22.926}
					strokeWidth={0.666}
					d='M298.313 152.145c-3.125-.192-5.762 1.344-5.891 3.426s2.3 3.929 5.43 4.121 5.762-1.344 5.89-3.43-2.3-3.926-5.429-4.117z'
				/>
				<path
					fill='#ffc72c'
					strokeMiterlimit={22.925}
					strokeWidth={0.667}
					d='m325.32 151.7-13.175 4.989 10.878 7.277c2.821-.937 5.055-10.52 2.3-12.266z'
				/>
				<path
					fill='#012169'
					strokeMiterlimit={22.925}
					strokeWidth={0.667}
					d='M325.07 154.131c-3.113-.32-5.812 1.106-6.023 3.184s2.144 4.02 5.258 4.336c2.027.207 3.285-1.563 3.5-3.445s-.598-3.86-2.735-4.075z'
				/>
				<g fill='#fff' strokeWidth={0.667}>
					<path
						strokeMiterlimit={22.925}
						d='M283.722 157.511a1.77 1.77 0 1 0 1.769 1.774 1.775 1.775 0 0 0-1.769-1.774zm27.009 1.5a1.768 1.768 0 1 0 1.765 1.77 1.766 1.766 0 0 0-1.765-1.77zm.96-8.17a1.77 1.77 0 1 0 1.769 1.77 1.771 1.771 0 0 0-1.769-1.77zm-27.909-1.15a1.77 1.77 0 1 0 1.769 1.77 1.771 1.771 0 0 0-1.769-1.77z'
					/>
					<path
						strokeMiterlimit={22.926}
						d='M224.566 102.091a3.53 3.53 0 1 1-3.532 3.532 3.532 3.532 0 0 1 3.532-3.532z'
					/>
					<path
						strokeMiterlimit={22.926}
						d='M221.986 96.229a3.53 3.53 0 1 1-3.528 3.532 3.529 3.529 0 0 1 3.528-3.532z'
					/>
					<path
						strokeMiterlimit={22.925}
						d='M230.433 75.58a3.4 3.4 0 1 1-3.4 3.4 3.4 3.4 0 0 1 3.4-3.4z'
					/>
					<g strokeMiterlimit={22.926}>
						<path d='M224.716 78.178a3.528 3.528 0 1 1-3.528 3.528 3.53 3.53 0 0 1 3.528-3.528z' />
						<path d='M220.506 83.162a3.53 3.53 0 1 1-3.532 3.532 3.532 3.532 0 0 1 3.532-3.532z' />
						<path d='M219.826 89.443a3.53 3.53 0 1 1-3.532 3.528 3.528 3.528 0 0 1 3.532-3.528z' />
					</g>
				</g>
				<path
					fill='#ffc72c'
					strokeMiterlimit={22.925}
					strokeWidth={0.667}
					d='M271.762 49.963a1.723 1.723 0 0 0 0-3.445 1.723 1.723 0 0 0-3.445 0 1.723 1.723 0 0 0 0 3.445V53.5h-3.043a1.723 1.723 0 0 0-3.445-.032 1.723 1.723 0 0 0 0 3.446 1.724 1.724 0 0 0 3.445.039h3.043V68.71h3.453V56.952h3.043a1.724 1.724 0 0 0 3.445-.039 1.723 1.723 0 0 0 0-3.446 1.723 1.723 0 0 0-3.445.032h-3.043z'
				/>
				<path
					fill='#012169'
					strokeMiterlimit={22.926}
					strokeWidth={0.667}
					d='M270.045 61.061a9.516 9.516 0 1 1-9.516 9.52 9.515 9.515 0 0 1 9.516-9.52z'
				/>
				<path
					fill='#ffc72c'
					strokeMiterlimit={22.925}
					strokeWidth={0.667}
					d='M270.042 61.061a9.655 9.655 0 0 1 1.727.157v7.492h-3.454v-7.492a9.655 9.655 0 0 1 1.727-.157z'
				/>
				<g strokeWidth={0.667}>
					<path
						fill='#ffc72c'
						strokeMiterlimit={22.925}
						d='M279.352 68.647a9.675 9.675 0 0 1 .1 3.278c-3.129-.106-6.261-.18-9.418-.18s-6.289.074-9.418.18a9.688 9.688 0 0 1-.1-1.348 9.808 9.808 0 0 1 .195-1.93c3.1-.109 6.2-.179 9.32-.179s6.223.07 9.317.179z'
					/>
					<path
						fill='#ffc72c'
						strokeLinejoin='round'
						d='M262.652 76.522c-2.015-1.238-2.367.684-2.316 2.618.191 7.277 1.59 14.2-.371 23.117-.785 3.566-2.051 5.574-.821 6.261 3.145 1.75 4.586 3.356 4.086 4.219.93 1.77 3.246 1.426 6.805.86 3.559.566 5.875.91 6.8-.86-.5-.863.941-2.469 4.082-4.219 1.234-.687-.032-2.7-.817-6.261-1.961-8.914-.562-15.84-.371-23.117.047-1.934-.3-3.856-2.316-2.618-3.965 2.446-10.754 2.473-14.766 0z'
					/>
					<g fill='#fff' strokeMiterlimit={22.926}>
						<path d='M270.045 103.921a3.782 3.782 0 1 1-3.782 3.782 3.782 3.782 0 0 1 3.782-3.782z' />
						<path d='M270.045 97.365a3.782 3.782 0 1 1-3.782 3.782 3.785 3.785 0 0 1 3.782-3.782z' />
						<path d='M270.045 91.069a3.78 3.78 0 1 1-3.782 3.782 3.782 3.782 0 0 1 3.782-3.782zm0-17.895a3.78 3.78 0 1 1-3.782 3.778 3.781 3.781 0 0 1 3.782-3.778z' />
						<path d='M270.045 78.717a3.782 3.782 0 1 1-3.782 3.782 3.782 3.782 0 0 1 3.782-3.782z' />
						<path d='M270.045 84.768a3.78 3.78 0 1 1-3.782 3.782 3.782 3.782 0 0 1 3.782-3.782z' />
					</g>
				</g>
				<g fill='#ffc72c' strokeWidth={0.667}>
					<path
						strokeLinejoin='round'
						d='M265.753 112.2q-2.244-4.153-4.765-5.308c2.316-7.239 2.859-16.582 1.5-28.106-.137-1.723-.566-2.465-1.227-2.453-.578.011-.929.89-.988 2.871.422 6.344.938 11.886.649 16.594a53.78 53.78 0 0 1-2.348 11.636c-.152.547.238.977 2.144 2.043a5.1 5.1 0 0 1 2.586 2.789 1.2 1.2 0 0 1 .817-.07c1.41.406 2.008.3 1.633 0zm8.575 0q2.244-4.149 4.765-5.309c-2.316-7.238-2.859-16.582-1.5-28.106.137-1.722.567-2.464 1.227-2.453.582.012.93.891.988 2.875-.422 6.34-.937 11.883-.648 16.59a53.98 53.98 0 0 0 2.347 11.637c.153.547-.238.976-2.144 2.043a5.1 5.1 0 0 0-2.586 2.789 1.206 1.206 0 0 0-.817-.071c-1.41.407-2.007.3-1.632 0z'
					/>
					<path
						strokeMiterlimit={22.926}
						d='M272.724 110.811c.543.868.4 1.04.754 1.317a2.6 2.6 0 0 0 1.93.25c2.906-.453 2.5 1.3 1.218 3.262a27.923 27.923 0 0 1-2.918 2.816c-.777.844-.843 1.547-1.152 1.758a70.295 70.295 0 0 1-2.516.023c-.437.008-1.4 0-2.515-.023-.309-.211-.375-.914-1.153-1.758a28.081 28.081 0 0 1-2.914-2.816c-1.289-1.965-1.691-3.715 1.215-3.262a2.593 2.593 0 0 0 1.93-.25c.355-.278.211-.449.754-1.317a3.827 3.827 0 0 1 1.234-1.265 8.2 8.2 0 0 1 2.9 0 3.839 3.839 0 0 1 1.235 1.265z'
					/>
				</g>
			</g>
			<path
				fill='#21231e'
				d='m269.6 120.3-.488-.07a9.7 9.7 0 0 0-2.4-5.3 8.008 8.008 0 0 1 2.888 5.37m.88 0 .492-.07a9.671 9.671 0 0 1 2.4-5.3 8.024 8.024 0 0 0-2.887 5.367'
			/>
			<path
				fill='#ffc72c'
				stroke='#21231e'
				strokeMiterlimit={22.925}
				strokeWidth={0.667}
				d='M276.722 112.341a5.617 5.617 0 0 1-1.1 1.907c-.855.882-2.269.308-3.64-.836a3.838 3.838 0 0 0-3.9 0c-1.371 1.144-2.789 1.719-3.644.836a5.6 5.6 0 0 1-1.094-1.907 4.152 4.152 0 0 1 1.32.032 2.6 2.6 0 0 0 1.93-.25c.355-.278.211-.45.754-1.313a3.842 3.842 0 0 1 1.234-1.269 8.208 8.208 0 0 1 2.9 0 3.852 3.852 0 0 1 1.234 1.269c.539.863.4 1.035.754 1.313a2.592 2.592 0 0 0 1.929.25 4.158 4.158 0 0 1 1.321-.032z'
			/>
			<path
				fill='#ffc72c'
				stroke='#21231e'
				strokeMiterlimit={22.926}
				strokeWidth={0.667}
				d='M256.88 121.291c.867-.543 1.039-.4 1.316-.754a2.587 2.587 0 0 0 .25-1.93c-.453-2.906 1.293-2.5 3.258-1.215a28.039 28.039 0 0 1 2.821 2.914c.843.778 1.543.844 1.754 1.153.027 1.113.031 2.078.027 2.515 0 .434 0 1.4-.027 2.512-.211.308-.911.375-1.754 1.156s-1.836 2.27-2.821 2.91c-1.965 1.285-3.711 1.692-3.258-1.215a2.588 2.588 0 0 0-.25-1.929c-.277-.356-.449-.211-1.316-.754a3.814 3.814 0 0 1-1.266-1.235c-.285-.457-.128-.953-.128-1.449s-.157-.988.128-1.449a3.841 3.841 0 0 1 1.266-1.23z'
			/>
			<path
				fill='#21231e'
				d='m266.37 124.421-.074.489A9.652 9.652 0 0 0 261 127.3a7.974 7.974 0 0 1 5.367-2.883m.003-.886-.074-.488a9.685 9.685 0 0 1-5.293-2.4 7.973 7.973 0 0 0 5.367 2.886'
			/>
			<path
				fill='#ffc72c'
				stroke='#21231e'
				strokeMiterlimit={22.926}
				strokeWidth={0.667}
				d='M258.412 117.281a5.634 5.634 0 0 1 1.907 1.1c.886.856.312 2.27-.832 3.645a3.135 3.135 0 0 0-.532 1.957 3.183 3.183 0 0 0 .532 1.937c1.144 1.371 1.718 2.789.832 3.645a5.681 5.681 0 0 1-1.907 1.1 4.2 4.2 0 0 1 .036-1.324 2.586 2.586 0 0 0-.25-1.926c-.278-.359-.45-.215-1.317-.757a3.839 3.839 0 0 1-1.265-1.235c-.286-.457-.129-.953-.129-1.449s-.157-.988.129-1.449a3.878 3.878 0 0 1 1.265-1.235c.867-.539 1.039-.4 1.317-.75a2.605 2.605 0 0 0 .25-1.933 4.179 4.179 0 0 1-.036-1.321zm24.788 9.377c-.867.543-1.039.4-1.316.753a2.59 2.59 0 0 0-.25 1.93c.453 2.906-1.3 2.5-3.262 1.215a27.992 27.992 0 0 1-2.816-2.914c-.844-.777-1.547-.844-1.758-1.152-.024-1.114-.031-2.079-.024-2.516-.008-.434 0-1.4.024-2.512.211-.308.914-.375 1.758-1.152a27.992 27.992 0 0 1 2.816-2.914c1.965-1.285 3.715-1.691 3.262 1.215a2.588 2.588 0 0 0 .25 1.93c.277.355.449.21 1.316.753a3.843 3.843 0 0 1 1.266 1.235 8.2 8.2 0 0 1 0 2.9 3.843 3.843 0 0 1-1.266 1.229z'
			/>
			<path
				fill='#21231e'
				d='m273.71 123.531.074-.488a9.7 9.7 0 0 0 5.293-2.4 7.973 7.973 0 0 1-5.367 2.886m0 .892.074.489a9.665 9.665 0 0 1 5.293 2.394 7.974 7.974 0 0 0-5.367-2.883'
			/>
			<g stroke='#21231e' strokeMiterlimit={22.926} strokeWidth={0.667}>
				<g fill='#ffc72c'>
					<path d='M281.662 130.661a5.6 5.6 0 0 1-1.9-1.1c-.886-.856-.312-2.274.832-3.645a3.829 3.829 0 0 0 0-3.894c-1.144-1.375-1.718-2.789-.832-3.645a5.6 5.6 0 0 1 1.9-1.1 4.152 4.152 0 0 1-.032 1.32 2.6 2.6 0 0 0 .25 1.933c.278.352.45.211 1.317.75a3.878 3.878 0 0 1 1.265 1.235 2.537 2.537 0 0 1 .129 1.449c0 .5.153.992-.129 1.449a3.839 3.839 0 0 1-1.265 1.235c-.867.543-1.039.4-1.317.757a2.586 2.586 0 0 0-.25 1.926 4.177 4.177 0 0 1 .032 1.324zm-11.617-10.97a4.286 4.286 0 1 1-4.286 4.282 4.286 4.286 0 0 1 4.286-4.282z' />
					<path d='M270.035 120.821a3.151 3.151 0 1 1-3.149 3.153 3.15 3.15 0 0 1 3.149-3.153z' />
				</g>
				<path
					fill='#012169'
					d='M270.045 121.991a1.983 1.983 0 1 1-1.985 1.981 1.984 1.984 0 0 1 1.985-1.981z'
				/>
			</g>
			<path
				fill='#21231e'
				d='m202.9 127.721.375-.16a3.818 3.818 0 0 1-.711-3.445c-.726.9-.551 2.312.336 3.605'
			/>
			<path
				fill='#ffc72c'
				stroke='#21231e'
				strokeMiterlimit={22.925}
				strokeWidth={0.667}
				d='M230.463 113.231c.738.926.649 1.149 1.047 1.415a2.113 2.113 0 0 0 1.887-.024c2.691-1.023 2.7 1.1 1.91 3.535-.379 1.18-1.527 2.461-2.039 3.465-.5.981-.4 1.719-.621 1.969-.977.16-1.813.277-2.2.324-.375.059-1.207.164-2.167.266-.313-.176-.555-.875-1.442-1.645a31.8 31.8 0 0 1-3.273-2.543c-1.688-1.914-2.586-3.746.066-3.683a1.781 1.781 0 0 0 1.617-.575c.235-.367.059-.535.285-1.609a3.862 3.862 0 0 1 .747-1.676c.339-.41.835-.32 1.293-.406s.878-.355 1.39-.1a4.545 4.545 0 0 1 1.5 1.286z'
			/>
			<path
				fill='#21231e'
				d='M230.08 123.971c-.144 0-.289-.011-.437-.019a12.955 12.955 0 0 0-3.5-5.246 10.355 10.355 0 0 1 3.934 5.265m.773-.11.41-.128a11.013 11.013 0 0 1 .852-5.989c-1.086 1.309-1.531 3.746-1.262 6.117'
			/>
			<path
				fill='#ffc72c'
				stroke='#21231e'
				strokeMiterlimit={22.925}
				strokeWidth={0.667}
				d='M234.653 114.361a6.806 6.806 0 0 1-.594 2.375c-.59 1.129-2.039.7-3.593-.382a2.688 2.688 0 0 0-1.926-.3 2.353 2.353 0 0 0-1.6.886c-.883 1.454-1.945 2.266-2.941 1.438a7.878 7.878 0 0 1-1.524-1.914 2.868 2.868 0 0 1 1.157-.16 1.781 1.781 0 0 0 1.617-.575c.234-.367.058-.531.285-1.609a3.913 3.913 0 0 1 .746-1.676c.34-.41.836-.316 1.293-.4s.879-.359 1.391-.106a4.63 4.63 0 0 1 1.5 1.29c.738.925.644 1.148 1.046 1.418a2.14 2.14 0 0 0 1.887-.028 3.266 3.266 0 0 1 1.254-.258z'
			/>
			<path
				fill='#ffc72c'
				stroke='#21231e'
				strokeMiterlimit={22.926}
				strokeWidth={0.675}
				d='M219.95 124.93c.43-.516.6-.4.68-.726a3.03 3.03 0 0 0-.473-1.708c-1.293-2.515.082-2.3 1.945-1.312a9.879 9.879 0 0 1 1.567 1.16c.547.461 1.094.945 1.547 1.274.926.66 1.5.679 1.765.945a91.01 91.01 0 0 1 .891 2.3c.16.4.492 1.281.855 2.3-.058.285-.589.363-.984 1.093a25.022 25.022 0 0 1-1.176 2.633c-.7.918-1.344 1.465-1.84 1.375s-.851-.812-.988-2.41a2.781 2.781 0 0 0-.816-1.668c-.324-.3-.4-.176-1.192-.617a5.26 5.26 0 0 1-1.3-1.031 3.319 3.319 0 0 1-.563-1.235 3.067 3.067 0 0 1-.387-1.246 2.042 2.042 0 0 1 .469-1.129z'
			/>
			<path
				fill='#21231e'
				d='M228.12 127.651c.039.18.086.352.125.52a4.895 4.895 0 0 0-2.016.965 5.1 5.1 0 0 0-1.343 1.723 3.6 3.6 0 0 1 1.07-2.008 4.917 4.917 0 0 1 2.164-1.2m-.32-.92c-.078-.171-.16-.339-.242-.511a8.222 8.222 0 0 1-2.594-.664 11.908 11.908 0 0 1-2.465-1.469 7.985 7.985 0 0 0 2.391 1.773 8.74 8.74 0 0 0 2.91.871'
			/>
			<path
				fill='#ffc72c'
				stroke='#21231e'
				strokeLinejoin='round'
				strokeWidth={0.667}
				d='M219.734 120.871a5.009 5.009 0 0 1 1.55.973 2.474 2.474 0 0 1 .84 1.621 5.691 5.691 0 0 1-.164 2.137 4.093 4.093 0 0 0 .3 2 5.5 5.5 0 0 0 1.086 1.926c1.355 1.347 2.305 2.773 1.926 3.652a1.817 1.817 0 0 1-.821.988 4.474 4.474 0 0 1-.679-1.18 4.274 4.274 0 0 0-.868-1.921 4.218 4.218 0 0 0-1.23-1.254c-.535-.328-.988-.188-1.348-.629-.7-.86-1.625-2.957-.726-3.918.89-.946 1.207-1.481.457-3.106a4.758 4.758 0 0 1-.32-1.293z'
			/>
			<path
				fill='#ffc72c'
				stroke='#21231e'
				strokeMiterlimit={22.926}
				strokeWidth={0.634}
				d='M243.4 127.7c-.707.6-.886.484-1.082.848a2.522 2.522 0 0 0 .082 1.836c.852 2.613-.754 2.382-2.683 1.382a28.657 28.657 0 0 1-2.949-2.406c-.875-.636-1.493-.633-1.731-.9a68.304 68.304 0 0 1-.519-2.352 76.83 76.83 0 0 1-.492-2.43c.121-.324.723-.473 1.317-1.352a26.38 26.38 0 0 1 1.976-3.359c1.594-1.656 3.195-2.367 3.285.816a3.03 3.03 0 0 0 .574 1.973c.325.332.461.16 1.372.605a4.011 4.011 0 0 1 1.4 1.1 2.8 2.8 0 0 1 .348 1.445 2.681 2.681 0 0 1 .1 1.453 3.5 3.5 0 0 1-.988 1.336z'
			/>
			<path
				fill='#21231e'
				d='M234.43 125.711c-.012-.156-.027-.316-.039-.476a7.822 7.822 0 0 0 4.281-2.988 6.525 6.525 0 0 1-4.242 3.464m.18.83c.055.153.106.305.16.454a9.079 9.079 0 0 1 5.094 1.73 7.663 7.663 0 0 0-5.254-2.184'
			/>
			<g stroke='#21231e'>
				<g fill='#ffc72c'>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.677}
						d='M242.63 131.6a5.592 5.592 0 0 1-1.863-.84c-.922-.711-.653-2.082.137-3.5a3.211 3.211 0 0 0 .152-1.961 3.574 3.574 0 0 0-.824-1.863c-1.3-1.242-2.094-2.617-1.438-3.621a5.2 5.2 0 0 1 1.594-1.434 4.722 4.722 0 0 1 .211 1.407 3.023 3.023 0 0 0 .574 1.972c.324.332.461.16 1.371.606a3.991 3.991 0 0 1 1.4 1.1 2.747 2.747 0 0 1 .344 1.446 2.655 2.655 0 0 1 .1 1.453 3.493 3.493 0 0 1-.992 1.332c-.707.609-.887.488-1.086.851a2.54 2.54 0 0 0 .086 1.836 3.779 3.779 0 0 1 .23 1.211z'
					/>
					<g strokeMiterlimit={22.925} strokeWidth={0.667}>
						<path d='M230.363 122.331a4.827 4.827 0 0 1 4.7 4.2 3.529 3.529 0 0 1-3.382 4.238 4.78 4.78 0 0 1-4.536-4 3.575 3.575 0 0 1 3.218-4.438z' />
						<path d='M230.553 123.491a3.534 3.534 0 0 1 3.434 3.067 2.59 2.59 0 0 1-2.469 3.133 3.532 3.532 0 0 1-3.348-2.957 2.611 2.611 0 0 1 2.383-3.243z' />
					</g>
				</g>
				<path
					fill='#012169'
					strokeMiterlimit={22.925}
					strokeWidth={0.667}
					d='M230.733 124.681a2.223 2.223 0 0 1 2.156 1.918 1.635 1.635 0 0 1-1.546 1.981 2.209 2.209 0 0 1-2.118-1.871 1.644 1.644 0 0 1 1.508-2.027z'
				/>
				<g fill='#fff' strokeWidth={0.667}>
					<path
						strokeMiterlimit={22.926}
						d='M312.794 107.871a3.528 3.528 0 1 0 3.531 3.528 3.527 3.527 0 0 0-3.531-3.528z'
					/>
					<g strokeMiterlimit={22.925}>
						<path d='M342.85 115.261a3.278 3.278 0 1 0 3.277 3.278 3.276 3.276 0 0 0-3.277-3.278z' />
						<path d='M345.98 109.271a3.278 3.278 0 1 0 3.277 3.278 3.277 3.277 0 0 0-3.277-3.278z' />
						<path d='M348.93 103.221a3.278 3.278 0 1 0 3.277 3.278 3.28 3.28 0 0 0-3.277-3.278z' />
					</g>
				</g>
				<path
					fill='#ffc72c'
					strokeMiterlimit={22.961}
					strokeWidth={0.668}
					d='M291.032 114.463c-1.894-.125-1.425 3.243-1.05 4 .484.98.937 1.007 1.554.105.469-.695 1.387-3.973-.5-4.1z'
				/>
				<path
					fill='#fff'
					strokeMiterlimit={22.978}
					strokeWidth={0.667}
					d='M290.9 116.283c-4.453-.3-3.347 7.618-2.472 9.383 1.148 2.3 2.211 2.375 3.66.246 1.112-1.629 3.265-9.328-1.188-9.629z'
				/>
				<path
					fill='#ffc72c'
					strokeMiterlimit={24.041}
					strokeWidth={0.668}
					d='M326.784 119.228c-1.808-.578-2.16 2.809-1.973 3.629.239 1.067.672 1.2 1.485.473.625-.563 2.293-3.523.488-4.1z'
				/>
				<path
					fill='#fff'
					strokeMiterlimit={24.058}
					strokeWidth={0.667}
					d='M326.232 120.968c-4.25-1.351-5.066 6.6-4.636 8.52.566 2.511 1.582 2.836 3.5 1.113 1.464-1.316 5.39-8.281 1.14-9.633z'
				/>
				<g strokeWidth={0.667}>
					<path
						fill='#ffc72c'
						strokeLinejoin='round'
						d='M279.982 79.053A62.315 62.315 0 0 1 308.966 83c8.148 3.234 7.347 12.68-2.586 25.793a13.735 13.735 0 0 1 1.488 6.109c-1.051-.058-1.785-.031-2.715-.055.719-1.585-.086-3.531-1-4.9-.594-.9-.711-.738 0-1.738 5.914-8.246 9.832-15.469 7.527-19.832-2.8-5.3-13.663-8.063-31.988-7.656.036-.485.071-.973.1-1.465.059-.067.117-.133.18-.2z'
					/>
					<g fill='#fff' strokeMiterlimit={22.925}>
						<path d='M326.31 81.74a3.4 3.4 0 1 0 3.4 3.4 3.4 3.4 0 0 0-3.4-3.4z' />
						<path d='M319.651 79.561a3.4 3.4 0 1 0 3.4 3.4 3.4 3.4 0 0 0-3.4-3.4z' />
					</g>
				</g>
				<g fill='#ffc72c'>
					<path
						strokeMiterlimit={22.925}
						strokeWidth={0.667}
						d='M318.761 82.463c-.336.45-.676.9-1.02 1.352 5.375 2.949 7.6 6.059 7.078 9.184-.09.539-1.726 6.261-5.375 17.355a32.574 32.574 0 0 0-5.582 4.629c.141.152-.394.636-.234.769-.371 1.7 1.539 1.352 2.82.93a10.718 10.718 0 0 1 3.586-4.094c.223-.148 1.019-.539 1.1-.793 1.957-6.242 3.918-12.016 5.6-17.391 1.692-5.4-1.973-9.227-7.973-11.941z'
					/>
					<path
						strokeLinejoin='round'
						strokeWidth={0.667}
						d='m339.973 122.561.207 1.09a10.563 10.563 0 0 0 4.016.269c-1.863-1.812-3.246-2.2-4.223-1.359z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.668}
						d='M340.144 120.071c-.98.219-1.074.383-1.5.457a2.244 2.244 0 0 1-1.828-.4c-2.129-1.273-2.176 1.024-1.347 2.742a4.138 4.138 0 0 1-.282 3.723c-.672 1.262-.527 1.836-.05 2.176.332.219.644.422.929.6.571.676 1.008.559 1.09-.324 2.121-.875 2.824-2.91 3.461-4.7.93-2.621 4.305-3.242-.473-4.269z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.667}
						d='M339.311 120.161c-1.847.657-2.054-.367-3.3-.316.488 2.828.988 4.293 3.941 2.777a3.384 3.384 0 0 1 4.106 1.3c.144.332 1.093-.359 1.441-1.117 1.449-3.168-3.707-3.523-6.188-2.641z'
					/>
				</g>
				<g fill='#fff' strokeWidth={0.667}>
					<g strokeMiterlimit={22.926}>
						<path d='M282.954 71.744a3.387 3.387 0 1 0 3.387 3.391 3.386 3.386 0 0 0-3.387-3.391z' />
						<path d='M289.894 71.744a3.387 3.387 0 1 0 3.387 3.391 3.386 3.386 0 0 0-3.387-3.391zm6.94.547a3.387 3.387 0 1 0 3.391 3.387 3.384 3.384 0 0 0-3.391-3.387z' />
						<path d='M303.234 73.514a3.389 3.389 0 1 0 3.387 3.391 3.393 3.393 0 0 0-3.387-3.391z' />
					</g>
					<g strokeMiterlimit={22.925}>
						<path d='M345.32 90.967a3.4 3.4 0 1 0 3.4 3.4 3.4 3.4 0 0 0-3.4-3.4z' />
						<path d='M339.49 87.291a3.4 3.4 0 1 0 3.4 3.4 3.4 3.4 0 0 0-3.4-3.4z' />
						<path d='M332.86 84.182a3.4 3.4 0 1 0 3.4 3.4 3.4 3.4 0 0 0-3.4-3.4zM349.84 96.5a3.4 3.4 0 1 0 3.4 3.4 3.4 3.4 0 0 0-3.4-3.4z' />
					</g>
				</g>
				<g fill='#ffc72c'>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.668}
						d='M331.667 133.841c-1.34-.406-3.433 2.555-3.929 2.5-.661-1.695.683-5.742 3.066-8.945 1.613-.527 1.762-1.566 2.094-3.977.1-.718 1.488-.222 1.511 1a10.168 10.168 0 0 1-.164 3.164 1.121 1.121 0 0 0 .442 1.27c-2.52 1.008-3.141 2.231-3.02 4.988z'
					/>
					<path
						strokeLinejoin='round'
						strokeWidth={0.668}
						d='M332.867 123.461a5.155 5.155 0 0 1 .359 1.856 2.606 2.606 0 0 1-.605 1.773 5.725 5.725 0 0 1-1.668 1.391 4.168 4.168 0 0 0-1.254 1.641 5.788 5.788 0 0 0-.66 2.175c-.051 1.969-.438 3.688-1.34 4.028a1.775 1.775 0 0 1-1.281.082 4.451 4.451 0 0 1 .4-1.348 5.838 5.838 0 0 0 .871-3.813c-.125-.636-.543-.875-.469-1.46.145-1.137 1.039-3.313 2.356-3.313 1.3 0 1.906-.141 2.574-1.855a5.034 5.034 0 0 1 .719-1.157z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.663}
						d='M337.757 128.178a4.683 4.683 0 0 0-1.183-.625 3.208 3.208 0 0 0-2.109-.1c-3.11.933-4.473 6.422-1.067 7.738.672.082 1.293-.52 1.895-1.848a10.2 10.2 0 0 1 2.277-3.707c.492-.625.512-1.148.187-1.461z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.662}
						d='M336.185 128.542c-2.988-1.161-5.188 4.523-2.395 5.6 1.5.582 1.641-1.277 1.934-2.312.391-1.398 3.332-2.179.461-3.288z'
					/>
				</g>
				<path
					fill='#012169'
					strokeMiterlimit={23.191}
					strokeWidth={0.667}
					d='M335.87 129.386c-1.457-.566-3.457 2.825-1.633 3.594a1.88 1.88 0 0 0 2.6-1.183 1.737 1.737 0 0 0-.967-2.411z'
				/>
				<g fill='#fff' strokeWidth={0.667}>
					<path
						strokeMiterlimit={22.926}
						d='M315.514 102.091a3.53 3.53 0 1 0 3.531 3.532 3.529 3.529 0 0 0-3.531-3.532z'
					/>
					<path
						strokeMiterlimit={22.926}
						d='M318.094 96.229a3.53 3.53 0 1 0 3.531 3.532 3.529 3.529 0 0 0-3.531-3.532z'
					/>
					<path
						strokeMiterlimit={22.925}
						d='M309.641 75.58a3.4 3.4 0 1 0 3.4 3.4 3.4 3.4 0 0 0-3.4-3.4z'
					/>
					<g strokeMiterlimit={22.926}>
						<path d='M315.364 78.178a3.528 3.528 0 1 0 3.527 3.528 3.527 3.527 0 0 0-3.527-3.528z' />
						<path d='M319.574 83.162a3.53 3.53 0 1 0 3.531 3.532 3.529 3.529 0 0 0-3.531-3.532z' />
						<path d='M320.254 89.443a3.53 3.53 0 1 0 3.531 3.528 3.529 3.529 0 0 0-3.531-3.528z' />
					</g>
				</g>
			</g>
			<path
				fill='#21231e'
				d='m337.18 127.721-.379-.16a3.806 3.806 0 0 0 .715-3.445c.727.9.547 2.312-.336 3.605'
			/>
			<path
				fill='#ffc72c'
				stroke='#21231e'
				strokeMiterlimit={22.925}
				strokeWidth={0.667}
				d='M309.6 113.231c-.735.926-.645 1.149-1.043 1.415a2.113 2.113 0 0 1-1.887-.024c-2.691-1.023-2.695 1.1-1.914 3.535a26.928 26.928 0 0 0 2.043 3.465c.5.981.394 1.719.617 1.969.981.16 1.817.277 2.2.324.371.059 1.207.164 2.164.266.317-.176.559-.875 1.446-1.645a32.235 32.235 0 0 0 3.273-2.543c1.688-1.914 2.586-3.746-.07-3.683a1.789 1.789 0 0 1-1.617-.575c-.231-.367-.055-.535-.282-1.609a3.85 3.85 0 0 0-.746-1.676c-.339-.41-.836-.32-1.293-.406s-.878-.355-1.39-.1a4.545 4.545 0 0 0-1.5 1.286z'
			/>
			<path
				fill='#21231e'
				d='M309.99 123.971c.149 0 .293-.011.441-.019a12.947 12.947 0 0 1 3.493-5.246 10.376 10.376 0 0 0-3.934 5.265m-.76-.11-.41-.128a11.013 11.013 0 0 0-.852-5.989c1.086 1.309 1.532 3.746 1.262 6.117'
			/>
			<path
				fill='#ffc72c'
				stroke='#21231e'
				strokeMiterlimit={22.925}
				strokeWidth={0.667}
				d='M305.421 114.361a6.806 6.806 0 0 0 .594 2.375c.59 1.129 2.039.7 3.589-.382a2.7 2.7 0 0 1 1.93-.3 2.334 2.334 0 0 1 1.594.886c.887 1.454 1.949 2.266 2.941 1.438a7.9 7.9 0 0 0 1.528-1.914 2.878 2.878 0 0 0-1.161-.16 1.789 1.789 0 0 1-1.617-.575c-.23-.367-.054-.531-.285-1.609a3.864 3.864 0 0 0-.742-1.676c-.34-.41-.836-.316-1.293-.4s-.879-.359-1.391-.106a4.668 4.668 0 0 0-1.5 1.29c-.735.925-.645 1.148-1.047 1.418a2.131 2.131 0 0 1-1.883-.028 3.266 3.266 0 0 0-1.254-.258z'
			/>
			<path
				fill='#ffc72c'
				stroke='#21231e'
				strokeMiterlimit={22.926}
				strokeWidth={0.675}
				d='M320.12 124.93c-.426-.516-.6-.4-.676-.726a2.966 2.966 0 0 1 .473-1.708c1.293-2.515-.082-2.3-1.945-1.312a9.879 9.879 0 0 0-1.567 1.16c-.547.461-1.094.945-1.551 1.274-.925.66-1.492.679-1.761.945a77.453 77.453 0 0 0-.891 2.3c-.156.4-.488 1.281-.855 2.3.062.285.589.363.988 1.093a24.42 24.42 0 0 0 1.176 2.633c.7.918 1.34 1.465 1.84 1.375s.851-.812.984-2.41a2.79 2.79 0 0 1 .824-1.668c.32-.3.391-.176 1.188-.617a5.26 5.26 0 0 0 1.3-1.031 3.31 3.31 0 0 0 .559-1.235 3.016 3.016 0 0 0 .391-1.246 2.071 2.071 0 0 0-.469-1.129z'
			/>
			<path
				fill='#21231e'
				d='M311.95 127.651c-.039.18-.082.352-.121.52a4.895 4.895 0 0 1 2.016.965 5.1 5.1 0 0 1 1.343 1.723 3.592 3.592 0 0 0-1.07-2.008 4.909 4.909 0 0 0-2.168-1.2m.33-.92c.078-.171.156-.339.238-.511a8.273 8.273 0 0 0 2.6-.664 12.013 12.013 0 0 0 2.465-1.469 7.953 7.953 0 0 1-2.4 1.773 8.691 8.691 0 0 1-2.906.871'
			/>
			<path
				fill='#ffc72c'
				stroke='#21231e'
				strokeLinejoin='round'
				strokeWidth={0.667}
				d='M320.35 120.871a5 5 0 0 0-1.55.973 2.474 2.474 0 0 0-.84 1.621 5.686 5.686 0 0 0 .16 2.137 4.036 4.036 0 0 1-.293 2 5.567 5.567 0 0 1-1.086 1.926c-1.355 1.347-2.305 2.773-1.93 3.652a1.839 1.839 0 0 0 .825.988 4.474 4.474 0 0 0 .679-1.18 5.479 5.479 0 0 1 2.1-3.175c.531-.328.984-.188 1.348-.629.7-.86 1.625-2.957.726-3.918-.89-.946-1.211-1.481-.457-3.106a4.8 4.8 0 0 0 .321-1.293z'
			/>
			<path
				fill='#ffc72c'
				stroke='#21231e'
				strokeMiterlimit={22.926}
				strokeWidth={0.634}
				d='M296.679 127.707c.707.605.887.484 1.082.851a2.507 2.507 0 0 1-.082 1.832c-.848 2.617.754 2.387 2.684 1.387a29.179 29.179 0 0 0 2.953-2.41c.875-.637 1.488-.633 1.726-.9.246-1.031.442-1.937.524-2.351s.289-1.34.492-2.426c-.117-.324-.723-.476-1.313-1.352a25.866 25.866 0 0 0-1.98-3.359c-1.594-1.66-3.195-2.371-3.285.813a3.007 3.007 0 0 1-.575 1.972c-.324.332-.46.16-1.367.606a3.989 3.989 0 0 0-1.406 1.1 2.759 2.759 0 0 0-.344 1.45 2.68 2.68 0 0 0-.1 1.453 3.544 3.544 0 0 0 .992 1.332z'
			/>
			<path
				fill='#21231e'
				d='M305.64 125.711c.012-.156.027-.316.035-.476a7.813 7.813 0 0 1-4.277-2.988 6.525 6.525 0 0 0 4.242 3.464m-.18.83c-.055.153-.1.305-.16.454a9.072 9.072 0 0 0-5.094 1.73 7.663 7.663 0 0 1 5.254-2.184'
			/>
			<g stroke='#21231e'>
				<path
					fill='#ffc72c'
					strokeMiterlimit={22.926}
					strokeWidth={0.677}
					d='M297.45 131.6a5.522 5.522 0 0 0 1.863-.84c.926-.711.657-2.082-.136-3.5a3.233 3.233 0 0 1-.153-1.961 3.576 3.576 0 0 1 .828-1.863c1.3-1.242 2.094-2.617 1.434-3.625a5.185 5.185 0 0 0-1.594-1.43 4.874 4.874 0 0 0-.207 1.407 3.046 3.046 0 0 1-.574 1.972c-.324.332-.461.16-1.371.606a3.991 3.991 0 0 0-1.4 1.1 2.787 2.787 0 0 0-.348 1.446 2.653 2.653 0 0 0-.1 1.453 3.515 3.515 0 0 0 .989 1.332c.71.609.886.488 1.085.851a2.538 2.538 0 0 1-.082 1.836 3.92 3.92 0 0 0-.234 1.211z'
				/>
				<g strokeMiterlimit={22.925} strokeWidth={0.667}>
					<path
						fill='#ffc72c'
						d='M309.711 122.331a4.824 4.824 0 0 0-4.7 4.2 3.528 3.528 0 0 0 3.379 4.238 4.784 4.784 0 0 0 4.539-4 3.575 3.575 0 0 0-3.215-4.438z'
					/>
					<path
						fill='#ffc72c'
						d='M309.531 123.491a3.538 3.538 0 0 0-3.438 3.067 2.593 2.593 0 0 0 2.473 3.133 3.532 3.532 0 0 0 3.348-2.957 2.611 2.611 0 0 0-2.383-3.243z'
					/>
					<path
						fill='#012169'
						d='M309.341 124.681a2.221 2.221 0 0 0-2.153 1.918 1.634 1.634 0 0 0 1.547 1.981 2.207 2.207 0 0 0 2.117-1.871 1.643 1.643 0 0 0-1.511-2.027z'
					/>
					<path
						fill='#ffc72c'
						d='M322.57 164.751c1.11.168 1.7.864 1.657 1.575.039.714-.547 1.41-1.66 1.574a4.6 4.6 0 0 1-.848.148 619.624 619.624 0 0 1-103.367 0 4.705 4.705 0 0 1-.848-.148c-1.109-.164-1.695-.86-1.656-1.574-.039-.711.547-1.407 1.656-1.575a5.1 5.1 0 0 1 .848-.144 618.767 618.767 0 0 1 103.367 0 5.069 5.069 0 0 1 .851.144z'
					/>
				</g>
			</g>
		</g>
		<path
			fill='none'
			stroke='#21231e'
			strokeLinecap='round'
			strokeMiterlimit={22.926}
			strokeWidth={0.67}
			d='M231.26 166.976q6.452-.4 12.914-.668t12.926-.394q6.469-.129 12.934-.125t12.934.144q6.463.135 12.926.407t12.922.664m-77.556.082q6.452.4 12.914.668m51.719-.031q6.464-.27 12.922-.665'
		/>
		<path
			fill='#e40046'
			stroke='#fff'
			strokeMiterlimit={22.926}
			strokeWidth={1.334}
			d='M175.52 168.546V321.31a101.544 101.544 0 0 0 27.734 70.145c17.121 17.984 40.75 29.136 66.777 29.136s49.656-11.152 66.777-29.136a101.546 101.546 0 0 0 27.734-70.149V168.542z'
		/>
		<g stroke='#21231e'>
			<g fillRule='evenodd'>
				<g fill='#fff'>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.667}
						d='M270.9 412.7c.816-.136 2.207.035 3.25-.785 1.4-1.094.844-5.512.719-8.3-.645-14.277-1.231-38.308-1.957-72.059a9.407 9.407 0 0 0-2.871.047 9.436 9.436 0 0 0-2.875-.047c-.727 33.751-1.313 57.781-1.953 72.059-.125 2.793-.676 7.211.714 8.3 1.047.821 2.434.649 3.254.785z'
					/>
					<path
						strokeMiterlimit={22.925}
						strokeWidth={0.667}
						d='M279.852 412.291c-.828-.058-2.2.243-3.313-.484-1.484-.961-1.609-5.477-1.734-8.273-.656-14.273-1.981-38.191-4.313-71.867a9.343 9.343 0 0 1 2.864-.219c.129-.031.261-.059.39-.09 5.375 33.316 7.4 56.949 11.082 71.2.508 1.969 1.6 6.9-.1 8.5-.968.91-2.363.863-3.172 1.074z'
					/>
					<path
						strokeMiterlimit={22.925}
						strokeWidth={0.667}
						d='M290.371 410.791c-.832.016-2.164.434-3.343-.183-1.567-.824-1.5-5.336-2.2-8.043-3.68-14.258-5.707-37.9-11.082-71.223a9.462 9.462 0 0 1 2.832-.477l.117-.039c8.543 32.395 11.106 55.234 17.739 69.34.613 1.953 2.285 7.145.687 8.953-.883 1-2.277 1.078-3.059 1.36z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.641}
						d='M300.966 407.8c-.828.1-2.222.871-3.457.371-1.64-.668-1.9-5.488-3.078-8-6.664-14.172-9.218-37.152-17.855-69.785a9.411 9.411 0 0 1 2.773-.75 3.888 3.888 0 0 1 1.8-.695c10.035 32.23 13.3 54.648 22.766 68.559 1.531 2.246 2.648 6.742 1.609 8.179-.777 1.078-2.156 1.3-2.91 1.652z'
					/>
					<path
						strokeMiterlimit={22.925}
						strokeWidth={0.667}
						d='M260.222 412.291c.828-.058 2.192.243 3.309-.484 1.488-.961 1.609-5.477 1.738-8.273.656-14.273 1.981-38.191 4.313-71.867a9.36 9.36 0 0 0-2.868-.219c-.125-.031-.257-.059-.386-.09-5.375 33.316-7.4 56.949-11.082 71.2-.512 1.969-1.6 6.9.1 8.5.968.91 2.363.863 3.168 1.074z'
					/>
					<path
						strokeMiterlimit={22.925}
						strokeWidth={0.667}
						d='M249.7 410.791c.832.016 2.164.434 3.339-.183 1.571-.824 1.508-5.336 2.207-8.043 3.68-14.258 5.707-37.9 11.082-71.223a9.462 9.462 0 0 0-2.832-.477l-.117-.039c-8.547 32.395-11.106 55.234-17.739 69.34-.613 1.953-2.289 7.145-.687 8.953.883 1 2.277 1.078 3.059 1.36z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.641}
						d='M239.1 407.8c.828.1 2.222.867 3.457.367 1.64-.668 1.9-5.488 3.078-8 6.664-14.171 9.218-37.151 17.859-69.784a9.412 9.412 0 0 0-2.773-.75 3.915 3.915 0 0 0-1.8-.695c-10.039 32.23-13.3 54.648-22.77 68.558-1.527 2.246-2.648 6.742-1.609 8.18.777 1.078 2.156 1.3 2.91 1.652z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.607}
						d='M295.366 383.448c-.753.047-1.894.508-3.082-.059-1.578-.75-2.605-5.105-3.453-7.812-6.722-13.254-12.855-36.793-22.918-69.508a7.518 7.518 0 0 1 2.5-.567 7.3 7.3 0 0 1 2.469-.656c10.84 32.371 15.7 55.782 26.3 68.676 1.75 2.129 3.051 6.769 2.219 8.113-.625 1.012-1.883 1.145-2.547 1.445z'
					/>
					<path
						strokeMiterlimit={23.929}
						strokeWidth={0.666}
						d='M286.8 385.131c-.761-.016-1.945.352-3.062-.3-1.485-.875-2.012-5.289-2.539-8.05-2.707-14.106-7.707-37.781-14.8-71.016a7.6 7.6 0 0 1 2.55-.367 7.48 7.48 0 0 1 2.535-.457c8.387 33.027 10.329 57.757 18.262 70.456 1.446 2.313 2.254 6.985 1.274 8.254-.742.961-2.008.989-2.7 1.239z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.66}
						d='M278.065 386.117c-.754-.074-1.976.2-3.011-.543-1.375-.992-1.391-5.426-1.6-8.219-1.07-14.258-3.317-38.219-6.551-71.867a7.592 7.592 0 0 1 2.582-.161 7.534 7.534 0 0 1 2.574-.25c4.543 33.54 7.184 57.828 10.059 71.586.574 2.739 1.441 7.129.316 8.317-.843.894-2.109.82-2.832 1.016z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.607}
						d='M244.709 383.447c.757.047 1.894.508 3.082-.058 1.578-.75 2.609-5.1 3.453-7.809 6.722-13.257 12.855-36.792 22.922-69.511a7.505 7.505 0 0 0-2.5-.57 7.359 7.359 0 0 0-2.465-.653c-10.84 32.375-15.707 55.785-26.3 68.676-1.746 2.129-3.047 6.769-2.215 8.117.625 1.011 1.883 1.14 2.547 1.445z'
					/>
					<path
						strokeMiterlimit={23.929}
						strokeWidth={0.666}
						d='M253.276 385.129c.758-.012 1.945.356 3.063-.3 1.484-.875 2.011-5.292 2.539-8.05 2.707-14.109 7.7-37.781 14.8-71.016a7.516 7.516 0 0 0-2.555-.367 7.426 7.426 0 0 0-2.531-.457c-8.387 33.027-10.328 57.757-18.262 70.456-1.445 2.313-2.258 6.985-1.273 8.254.738.957 2.007.985 2.7 1.239z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.66}
						d='M262.012 386.114c.75-.075 1.973.2 3.012-.543 1.375-.989 1.387-5.426 1.6-8.219 1.07-14.254 3.312-38.218 6.551-71.864a7.651 7.651 0 0 0-2.586-.164 7.522 7.522 0 0 0-2.574-.25c-4.543 33.54-7.184 57.828-10.059 71.586-.57 2.743-1.442 7.129-.317 8.317.848.894 2.11.824 2.832 1.015z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.667}
						d='M270.81 386.414c.738-.137 1.988.039 2.934-.785 1.257-1.094.757-5.512.648-8.309-.582-14.273-1.109-38.3-1.762-72.059a7.681 7.681 0 0 0-2.593.047 7.692 7.692 0 0 0-2.594-.047c-.653 33.755-1.184 57.785-1.762 72.059-.113 2.8-.609 7.215.649 8.309.945.824 2.195.648 2.933.785z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.647}
						d='M289.439 363.822c-.578.043-1.449.5-2.359-.063-1.211-.75-2-5.1-2.649-7.808-5.144-13.254-9.844-36.793-17.55-69.512a4.608 4.608 0 0 1 1.914-.566 4.674 4.674 0 0 1 1.891-.657c8.3 32.376 12.027 55.785 20.144 68.676 1.336 2.129 2.336 6.774 1.7 8.117-.48 1.012-1.445 1.141-1.953 1.446z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.658}
						d='M282.87 365.51c-.578-.015-1.488.356-2.344-.3-1.136-.872-1.539-5.29-1.945-8.051q-3.111-21.159-11.34-71.017a4.546 4.546 0 0 1 1.957-.371 4.658 4.658 0 0 1 1.942-.453c6.425 33.027 7.91 57.758 13.988 70.453 1.105 2.313 1.726 6.984.972 8.258-.562.957-1.535.984-2.066 1.234z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.665}
						d='M276.191 366.493c-.578-.078-1.515.195-2.3-.547-1.059-.988-1.067-5.426-1.227-8.219-.82-14.254-2.539-38.218-5.016-71.864a4.509 4.509 0 0 1 1.977-.164 4.6 4.6 0 0 1 1.973-.25c3.48 33.543 5.5 57.828 7.707 71.587.437 2.742 1.1 7.129.238 8.316-.648.9-1.617.824-2.168 1.02z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.647}
						d='M250.638 363.816c.578.047 1.453.508 2.364-.058 1.207-.754 2-5.106 2.644-7.813 5.149-13.254 9.844-36.792 17.555-69.508a4.581 4.581 0 0 0-1.914-.566 4.635 4.635 0 0 0-1.895-.657c-8.3 32.376-12.027 55.785-20.14 68.676-1.34 2.125-2.336 6.77-1.7 8.114.477 1.011 1.442 1.144 1.95 1.445z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.658}
						d='M257.2 365.5c.582-.012 1.492.355 2.343-.3 1.137-.875 1.539-5.289 1.946-8.051 2.074-14.109 5.9-37.782 11.34-71.015a45.011 45.011 0 0 1-3.9-.821c-6.422 33.024-7.91 57.758-13.988 70.453-1.105 2.313-1.727 6.981-.977 8.254.571.957 1.54.985 2.071 1.235z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.665}
						d='M263.888 366.5c.574-.078 1.515.195 2.3-.547 1.059-.988 1.067-5.426 1.227-8.215.82-14.262 2.539-38.222 5.016-71.871a43.863 43.863 0 0 1-3.95-.41c-3.48 33.543-5.5 57.828-7.707 71.587-.437 2.742-1.1 7.129-.238 8.316.648.895 1.613.82 2.168 1.016z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.667}
						d='M270.638 366.781c.566-.136 1.523.036 2.25-.785.961-1.094.578-5.511.492-8.308-.442-14.277-.848-38.305-1.352-72.055a45.792 45.792 0 0 1-3.968 0c-.5 33.75-.907 57.777-1.348 72.055-.09 2.8-.473 7.214.492 8.308.723.821 1.68.649 2.25.785z'
					/>
				</g>
				<g fill='#ffc72c'>
					<path
						strokeLinejoin='round'
						strokeWidth={0.662}
						d='M230.541 328.1c-1.8.079-4.019 1.11-10.492 6.719-2.828 5.766-5.676 11.567-9.6 16.566a14.943 14.943 0 0 1-3.449 1.843c-1.106.367-9.27.1-9.469-.247-2.539-2.3-6.422-.66-5.125 1.77-1 .953 1.211 4.441 2.992 3 1.633-1.332 7.324-.074 9.219-.762-.719 1.707-5.86 2.817-9.453 3.078-3.282.141-3.063 3.414-1.711 5.926 1.539.981.277-.258.836.457 1.469 2.18 4.613 1.895 4.719-.234.109-2.2 7.777-5.074 8.925-6.766-1.406 3.348-4.648 6.473-6.258 9.746-1.73 1.735-2.777 2.082-.542 5.582 2.48-.281 2.625-.941 4.152.5 3.391-.371 3.941-1.379 3.391-4.062-.582-2.836 1.789-7.961 2.582-9.41.726-1.336.906-2.723 1.613-3.559 1.23-1.457 7.09-4.367 8.59-3.566 2.765 1.468 4.871.582 5.218-2.094-.89-.687-.218-4.484-2.132-4.648-1.563-.129-2.489.351-3.926 1.523-2.262 1.848-3.422 2.828-4.168 2.625-1.762-.488 3.195-2.918 7.707-8.832 6.25-8.195 6.68-12.355 6.387-15.16z'
					/>
					<path
						strokeMiterlimit={23.175}
						strokeWidth={0.667}
						d='M192.786 356.253c-2.266-1.324-3.563-1.273-4.442-.289-.484.543-1.1.262-.738-.59 1.5-3.555 3.9-4 6.058-2.332.6.559 1.086 1.039 1.571 1.519-.36.536-2.157 1.282-2.449 1.692z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.65}
						d='M225.187 353.082c2.406-1.633 4.656-1.953 5.89-1.035.676.5 1.348.14.75-.7-2.492-3.5-5.41-3.679-7.613-1.707-.6.649-1.074 1.2-1.551 1.754a7.63 7.63 0 0 0 2.524 1.684z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.659}
						d='M206.27 373.147c-1.472 2.914-2.25 4.91-1.609 6.473.371.914-.051 1.754-.977.957-3.754-3.219-3.761-6.91-1.359-9.418a21.623 21.623 0 0 1 2.418-1.984c.977.914 1.6 3.832 1.527 3.972z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.614}
						d='M196.9 365.49c-2.235.922-3 2.1-2.922 3.637.047.848-.512 1.164-.824.18-2.731-6.332.1-7.516 4.191-7.286a8.542 8.542 0 0 1-.445 3.469z'
					/>
				</g>
			</g>
			<g fill='none'>
				<path
					strokeMiterlimit={31.033}
					strokeWidth={0.667}
					d='M216.107 351.392c-1.469-.855-1.555-1.8-1.172-2.441.816-1.352 1.637-.7 3.176.547'
				/>
				<path
					strokeMiterlimit={31.033}
					strokeWidth={0.667}
					d='M218.013 349.323c-1.468-.856-1.554-1.805-1.171-2.442.816-1.348 1.636-.7 3.175.547'
				/>
				<path
					strokeMiterlimit={22.926}
					strokeWidth={0.674}
					d='M220.15 347.361c-1.621-1.109-1.719-2.343-1.293-3.175.9-1.754 1.805-.911 3.5.711'
				/>
				<path
					strokeMiterlimit={22.926}
					strokeWidth={0.677}
					d='M222.452 344.891c-1.718-1.257-1.824-2.648-1.371-3.589.953-1.985 1.918-1.028 3.719.8'
				/>
				<path
					strokeMiterlimit={22.926}
					strokeWidth={0.669}
					d='M224.877 342.271c-1.6-1.332-2.1-2.652-1.371-3.812 1.144-1.816 1.918-1.09 3.718.855'
				/>
			</g>
			<g fillRule='evenodd' strokeWidth={0.667}>
				<path
					fill='#ffc72c'
					strokeLinejoin='round'
					d='M246.592 199.868c-1.149 1-4.938 1.015-10.125 1.347-8.36.293-13.2-2.226-14.273-7.57 3.14 3.73 6.879 5.578 11.129 4.527 5.73-1.418 9.011-2.433 10.32-2.183.879.312 2.164 1.906 2.949 3.879z'
				/>
				<g fill='#fff'>
					<path
						strokeMiterlimit={22.925}
						d='M265.942 313.791a652.395 652.395 0 0 0-.094 7.836l-.023.008-5.973 1.239c-.015 2.722 6.719 31.211 10.188 31.211s10.2-28.508 10.188-31.23l-5.973-1.219-.023-.008c-.02-2.758-.051-5.442-.094-7.836a48.6 48.6 0 0 0-4.1 4.5 49.17 49.17 0 0 0-4.1-4.5z'
					/>
					<g strokeLinejoin='round'>
						<path d='m259.022 325.894-5.351-3.028c-2.25 1.614-3.918 7.61-.071 12.508 4.8 6.106 6.739 10.532 7.91 15.473 3.493-4.465 4.7-9.16 2.477-13.743-2.988-6.179-4.938-9.664-4.965-11.211z' />
						<path d='m263.4 321.615-5.352-3.027c-2.254 1.613-6.957 7.421-3.109 12.32 4.8 6.1 9.777 10.718 10.949 15.66 3.493-4.465 4.981-9.16 2.762-13.742-2.988-6.176-5.222-9.66-5.25-11.21zm17.651 4.271 5.352-3.028c2.254 1.618 3.918 7.614.07 12.512-4.793 6.106-6.738 10.528-7.91 15.473-3.492-4.465-4.691-9.164-2.473-13.743 2.988-6.179 4.934-9.664 4.961-11.215z' />
						<path d='m276.676 321.607 5.356-3.023c2.25 1.613 6.957 7.418 3.109 12.316-4.8 6.105-9.777 10.722-10.953 15.664-3.489-4.465-4.977-9.16-2.758-13.742 2.988-6.18 5.223-9.664 5.246-11.214zm-25.095-2.16-5.352-3.027c-2.25 1.617-3.918 7.613-.066 12.512 4.793 6.1 6.738 10.531 7.91 15.472 3.488-4.465 4.691-9.164 2.473-13.742-2.992-6.18-4.938-9.664-4.965-11.215z' />
						<path d='m288.492 319.439 5.356-3.027c2.25 1.617 3.914 7.609.066 12.512-4.8 6.105-6.738 10.527-7.91 15.468-3.488-4.461-4.691-9.16-2.473-13.742 2.989-6.18 4.938-9.664 4.961-11.211z' />
					</g>
					<path
						strokeMiterlimit={22.926}
						d='M226.8 263.045c.1 4.886-.766 37.476-1.039 42.082a13.011 13.011 0 0 1-4.477 9.5 20.428 20.428 0 0 1-3.457-10.789c-.148-2.227.266-33.067.438-39.407 3.656-9.66 6.367-10.027 8.535-1.39z'
					/>
					<path
						strokeMiterlimit={22.926}
						d='m205.58 255.074-1.38 44.809c-.437 3.976 4.746 27.628 7.578 32.3 7.016-3 8.5-19.042 7.176-34.679s.223-29.144 1.293-31.952c.094-3.54-.5-7.231-.535-10.914-4.469.27-9.66.172-14.129.441z'
					/>
					<path
						strokeMiterlimit={22.926}
						d='m197.27 262.4.949 44.824c-.226 3.992 6.184 27.332 9.258 31.832 7.113-5.508 5.684-28.043 4.594-34.86-2.172-13.585-1.805-29.324-1.055-31.879-.09-3.539-.183-7.773-.41-11.453L197.27 262.4z'
					/>
					<path
						strokeMiterlimit={22.926}
						d='m191.1 273.016 11.8-.3c-1 4.891-.2 20.078 1.832 31.652 1.91 10.856 4.574 39-6.746 45.762-7.016-14.851-1.672-29.284-5.211-42.183-2.762-13.707-3.3-25.152-1.672-34.93zm45.1-18.201c-.047 4.891-1.9 37.438-2.312 42.031a13 13 0 0 1-4.766 9.352 20.436 20.436 0 0 1-3.133-10.887c-.074-2.234 1.274-33.046 1.637-39.379 3.945-9.539 6.668-9.82 8.574-1.117zm-20.96-26.55c-.316 10.5-.5 33.281-.43 35.215.149 4.379 1.879 9.949 3.786 12.742a13 13 0 0 0 4.761-9.355c.336-3.758.457-22.23.512-31.488a39.679 39.679 0 0 1-6.812-5.473s-1.747-1.55-1.817-1.64z'
					/>
					<path
						strokeMiterlimit={22.926}
						d='M223.66 235.032c-.211 11.313-.086 29.132-.035 30.578a20.439 20.439 0 0 0 3.129 10.895 13.392 13.392 0 0 0 4.98-9.465c.254-2.816 1.039-14.89 1.11-24.16-2.989-.945-6.762-5.839-9.184-7.847zm-7.25-7.712-8.031-11.559c-1.621 5.7-3.6 46.6-1.277 49.957a20.536 20.536 0 0 0 3.636 10.878c2.223-1.722 5.543-4.82 4.582-9.449s.117-35.054 1.09-39.828z'
					/>
					<path
						strokeMiterlimit={22.926}
						d='M199.95 253.044c-.363 6.328-.621 10.613-.543 12.844a20.451 20.451 0 0 0 3.129 10.9c2.223-1.723 5.055-4.774 4.762-9.36-1.121-17.711.82-34.394 3.078-50.433-1.906-1.4-5.117-2.9-7.024-4.3-3.808 21.6-5.082 35-3.4 40.356z'
					/>
					<path
						strokeLinejoin='round'
						d='m191.91 264.637 2.18 8.238c.148 4.379 2.859 8 4.761 10.789a9.4 9.4 0 0 0 3.676-9.109c-3.258-26.164-2.094-43.472 3.4-64.793-12.43 8.473-18.191 26.344-14.02 54.875z'
					/>
					<path
						strokeMiterlimit={22.926}
						d='m192.55 272.737-11.406-3.781c-1.449 11.386.781 25.687 3.856 44.547 2.636 16.192 1.25 29.008-.766 37.231 12.168-1.664 17.312-17.328 11.211-46.789a131.84 131.84 0 0 1-2.895-31.207z'
					/>
				</g>
			</g>
			<path
				fill='none'
				strokeLinecap='round'
				strokeMiterlimit={22.925}
				strokeWidth={0.667}
				d='M192.2 327.841c.266-7.543-1-15.777-2.433-22.715a141.041 141.041 0 0 1-3.047-28.207'
			/>
			<g fillRule='evenodd'>
				<g fill='#fff'>
					<path
						strokeLinejoin='round'
						strokeWidth={0.667}
						d='m192.1 205.755 7.484 1.258c-.367 1.6-2.265 11.437-2.613 13.113-2.8 13.668-4.644 29.773-2.527 47.438.785 6.554 2.176 11.913 4.219 16.058-8.211-.933-13.473-8.937-12.793-22.9-1.43-16.308-.8-29.278 1.843-39.027.774-2.859 3.266-13.632 4.387-15.941z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.667}
						d='m187.56 207.526 3.676 4.188c-1.043.746.726 3.957-.227 10.195-.613 3.539-2.726 7.715-3.16 12.234-1.449 14.973-1.453 33.723 3.055 45.712-8.211-.934-11.031-8.364-11.3-22.34-.219-11.559-.258-25.184 2.648-40.914a14.422 14.422 0 0 1 5.305-9.074z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.588}
						d='m208.072 218.551-7.578-.882c-1.394 7.152-2.457 12.21-2.719 14.757a23.713 23.713 0 0 0 2.747 13c2.8-1.683 4.093-5.359 5.269-10.508 1.176-5.175 1.473-10.812 2.281-16.363z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.665}
						d='m227.736 234.662-6.726.071c-.231 6.172-.395 10.347-.285 12.523a20.706 20.706 0 0 0 3.105 10.582 12.746 12.746 0 0 0 4.281-9.164c.305-4.481-.332-9.25-.375-14.012z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.664}
						d='m234.979 237.846-6.722.309c-.012 6.175-.035 10.355.152 12.523a20.7 20.7 0 0 0 3.473 10.469 12.772 12.772 0 0 0 3.961-9.3c.148-4.492-.657-9.235-.864-14z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.667}
						d='m220.886 231.2-6.707-.473c-.73 6.133-1.234 10.281-1.305 12.457a20.76 20.76 0 0 0 2.239 10.8 12.752 12.752 0 0 0 5.011-8.785c.668-4.441.418-9.25.762-14z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.667}
						d='m214.6 223.964-7.36-3.051c-1.25 4.969-2.949 21.321.067 28.227a14.21 14.21 0 0 0 5.746-9.7c.828-4.938 1.074-10.188 1.547-15.477z'
					/>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth={0.674}
						d='m204.867 215.852-11.761 1.048a85.222 85.222 0 0 0-5.344 18.126c5.453-.965 9.406-3.641 10.824-8.524a23.8 23.8 0 0 1 6.281-10.644z'
					/>
				</g>
				<g fill='#ffc72c' strokeMiterlimit={22.926}>
					<g strokeWidth={0.663}>
						<path d='M216.79 393.774c4.492 3.594 8.6-4.625 4.617-15.492-2.945-8.039-17.352-4.918-9.328 5.9.379.511.918.269.84-.313-.453-2.219.961-3.5 3.145-3.1 5.968 1.118 6.156 12.427-.332 9.852-1.188-.074-.125 2.2 1.058 3.152zm14.74 0c-4.492 3.594-8.6-4.625-4.617-15.492 2.949-8.039 17.352-4.918 9.328 5.9-.383.511-.914.269-.84-.313.457-2.219-.961-3.5-3.144-3.1-5.965 1.118-6.153 12.427.332 9.852 1.183-.074.125 2.2-1.059 3.152z' />
						<path d='M223.73 358.857v.074c-.586 1.887-2.769 4.243-4.465 6.012a7.036 7.036 0 0 0-.187 9.1 15.984 15.984 0 0 1 3.269 7.226 27.892 27.892 0 0 1 .446 8.731c-.192 2.227-1.539 3.375-2.8 4.371-.641.508.273 1.719 2.394 3.313a2.907 2.907 0 0 1 1.176 1.711c.117.265 1.1.265 1.219 0a2.907 2.907 0 0 1 1.176-1.711c2.121-1.594 3.035-2.805 2.394-3.313-1.265-1-2.613-2.144-2.8-4.371a28.183 28.183 0 0 1 .441-8.731 16.007 16.007 0 0 1 3.277-7.226 7.04 7.04 0 0 0-.187-9.1c-1.7-1.769-3.883-4.125-4.469-6.012v-.074c-.031-.367-.234-.551-.438-.551s-.406.184-.433.551z' />
					</g>
					<path
						strokeWidth={0.667}
						d='M220.92 384.935h6.492a1.993 1.993 0 1 1 0 3.985h-6.492a1.993 1.993 0 1 1 0-3.985z'
					/>
					<g strokeWidth={0.663}>
						<path d='M308.54 393.774c4.488 3.594 8.6-4.625 4.617-15.492-2.949-8.039-17.355-4.918-9.332 5.9.383.511.918.269.844-.313-.457-2.219.961-3.5 3.141-3.1 5.968 1.118 6.156 12.427-.332 9.852-1.184-.074-.125 2.2 1.062 3.152zm14.74 0c-4.492 3.594-8.6-4.625-4.617-15.492 2.945-8.039 17.352-4.918 9.328 5.9-.379.511-.914.269-.84-.313.457-2.219-.961-3.5-3.144-3.1-5.965 1.118-6.153 12.427.336 9.852 1.183-.074.121 2.2-1.063 3.152z' />
						<path d='M315.47 358.857v.074c-.586 1.887-2.769 4.243-4.465 6.012a7.036 7.036 0 0 0-.187 9.1 15.941 15.941 0 0 1 3.269 7.226 27.892 27.892 0 0 1 .446 8.731c-.192 2.227-1.54 3.375-2.805 4.371-.641.508.273 1.719 2.394 3.313a2.907 2.907 0 0 1 1.176 1.711c.117.265 1.1.265 1.219 0a2.925 2.925 0 0 1 1.176-1.711c2.121-1.594 3.035-2.805 2.394-3.313-1.261-1-2.613-2.144-2.8-4.371a28.006 28.006 0 0 1 .445-8.731 15.952 15.952 0 0 1 3.268-7.224 7.044 7.044 0 0 0-.187-9.1c-1.7-1.769-3.883-4.125-4.469-6.012v-.074c-.031-.367-.234-.551-.437-.551s-.407.184-.434.551z' />
					</g>
					<path
						strokeWidth={0.667}
						d='M312.67 384.935h6.492a1.993 1.993 0 1 1 0 3.985h-6.492a1.993 1.993 0 1 1 0-3.985z'
					/>
				</g>
				<g fill='#fff' strokeWidth={0.667}>
					<path
						strokeMiterlimit={22.926}
						d='M313.28 263.045c-.1 4.886.762 37.476 1.035 42.082a13.027 13.027 0 0 0 4.481 9.5 20.419 20.419 0 0 0 3.457-10.789c.144-2.227-.266-33.067-.438-39.407-3.656-9.66-6.367-10.027-8.535-1.39z'
					/>
					<path
						strokeMiterlimit={22.926}
						d='m334.5 255.074 1.387 44.809c.437 3.976-4.75 27.628-7.582 32.3-7.016-3-8.5-19.042-7.176-34.679s-.219-29.144-1.289-31.952c-.094-3.54.5-7.231.535-10.914 4.469.27 9.66.172 14.125.441z'
					/>
					<path
						strokeMiterlimit={22.926}
						d='m342.81 262.4-.953 44.824c.23 3.992-6.184 27.332-9.254 31.832-7.113-5.508-5.684-28.043-4.594-34.86 2.172-13.585 1.805-29.324 1.055-31.879.09-3.539.18-7.773.406-11.453l13.34 1.536z'
					/>
					<path
						strokeMiterlimit={22.926}
						d='m348.98 273.016-11.793-.3c1 4.891.2 20.078-1.836 31.652-1.906 10.856-4.57 39 6.746 45.762 7.02-14.851 1.676-29.284 5.215-42.183 2.758-13.707 3.305-25.156 1.668-34.93zm-45.1-18.201c.043 4.891 1.9 37.438 2.313 42.031a13 13 0 0 0 4.765 9.352 20.4 20.4 0 0 0 3.129-10.887c.078-2.234-1.269-33.046-1.633-39.379-3.945-9.539-6.668-9.82-8.574-1.117zm20.96-26.55c.316 10.5.5 33.281.43 35.215-.153 4.379-1.879 9.949-3.785 12.742a12.981 12.981 0 0 1-4.762-9.355c-.336-3.758-.457-22.23-.516-31.488a39.571 39.571 0 0 0 6.813-5.473s1.75-1.55 1.82-1.64z'
					/>
					<path
						strokeMiterlimit={22.926}
						d='M316.41 235.032c.207 11.313.086 29.132.035 30.578a20.431 20.431 0 0 1-3.129 10.895 13.392 13.392 0 0 1-4.98-9.465c-.254-2.816-1.039-14.89-1.113-24.16 2.988-.945 6.765-5.839 9.187-7.847zm7.25-7.712 8.027-11.559c1.625 5.7 3.606 46.6 1.282 49.957a20.547 20.547 0 0 1-3.641 10.878c-2.223-1.722-5.539-4.82-4.578-9.449s-.117-35.054-1.09-39.828z'
					/>
					<path
						strokeMiterlimit={22.926}
						d='M340.13 253.044c.359 6.328.621 10.613.543 12.844a20.442 20.442 0 0 1-3.129 10.9c-2.223-1.723-5.055-4.774-4.765-9.36 1.121-17.711-.817-34.394-3.075-50.433 1.907-1.4 5.118-2.9 7.024-4.3 3.808 21.6 5.082 35 3.4 40.356z'
					/>
					<path
						strokeLinejoin='round'
						d='m348.16 264.637-2.18 8.238c-.152 4.379-2.859 8-4.761 10.789a9.389 9.389 0 0 1-3.676-9.109c3.258-26.164 2.094-43.472-3.406-64.793 12.434 8.473 18.195 26.344 14.023 54.875z'
					/>
					<path
						strokeMiterlimit={22.926}
						d='m347.52 272.737 11.406-3.781c1.453 11.386-.781 25.687-3.852 44.547-2.636 16.192-1.25 29.008.766 37.231-12.168-1.664-17.312-17.328-11.211-46.789a132.089 132.089 0 0 0 2.891-31.207z'
					/>
				</g>
			</g>
			<path
				fill='none'
				strokeLinecap='round'
				strokeMiterlimit={22.925}
				strokeWidth={0.667}
				d='M347.87 327.841c-.266-7.543 1-15.777 2.433-22.715a141.334 141.334 0 0 0 3.047-28.207'
			/>
			<g fillRule='evenodd'>
				<g fill='#fff'>
					<path
						strokeLinejoin='round'
						strokeWidth={0.667}
						d='m347.98 205.755-7.488 1.258c.371 1.6 2.269 11.437 2.613 13.113 2.8 13.668 4.649 29.773 2.531 47.438-.785 6.554-2.179 11.913-4.219 16.058 8.211-.933 13.473-8.937 12.793-22.9 1.426-16.308.8-29.278-1.843-39.027-.774-2.859-3.266-13.632-4.387-15.941z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.667}
						d='m352.52 207.526-3.68 4.188c1.047.746-.722 3.957.227 10.195.617 3.539 2.727 7.715 3.164 12.234 1.449 14.973 1.453 33.723-3.055 45.712 8.211-.934 11.031-8.364 11.3-22.34.219-11.559.258-25.184-2.648-40.914a14.417 14.417 0 0 0-5.305-9.074z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.588}
						d='m332.006 218.547 7.578-.883c1.395 7.153 2.457 12.211 2.715 14.762a23.708 23.708 0 0 1-2.742 12.993c-2.8-1.684-4.094-5.36-5.27-10.508-1.179-5.176-1.472-10.813-2.281-16.364z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.665}
						d='m312.337 234.654 6.727.07c.23 6.172.4 10.348.289 12.52a20.723 20.723 0 0 1-3.106 10.585 12.751 12.751 0 0 1-4.281-9.164c-.3-4.48.332-9.25.371-14.011z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.664}
						d='m305.1 237.845 6.723.305c.012 6.175.031 10.355-.152 12.527a20.71 20.71 0 0 1-3.473 10.465 12.745 12.745 0 0 1-3.961-9.3c-.148-4.489.656-9.235.863-13.992z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.667}
						d='m319.191 231.19 6.711-.476c.726 6.136 1.234 10.285 1.3 12.461a20.732 20.732 0 0 1-2.239 10.8 12.75 12.75 0 0 1-5.008-8.785c-.671-4.442-.421-9.25-.765-14z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.667}
						d='m325.479 223.957 7.359-3.051c1.254 4.969 2.949 21.321-.062 28.227a14.209 14.209 0 0 1-5.75-9.7c-.825-4.937-1.075-10.191-1.547-15.476z'
					/>
					<g strokeLinejoin='round'>
						<path
							strokeLinecap='round'
							strokeWidth={0.674}
							d='m335.209 215.845 11.758 1.047a84.919 84.919 0 0 1 5.348 18.121c-5.457-.961-9.41-3.641-10.829-8.519a23.814 23.814 0 0 0-6.277-10.649z'
						/>
						<path
							strokeWidth={0.667}
							d='M300.621 232.581a21.609 21.609 0 0 0 2.738-.769c-4.3-2.7-7.871-5.957-12.117-12.457-4.344-6.66 1.906-18.555 4.469-24.863 5.359-3.961 10.406-9.785 12.011-17.461.633-4.129-10.77-2.637-18.613 1.062-9.586 4.524-10.891 3.27-14.309 4.032-1.219 5.871 1.328 6.363 3.774 6.078-5.282 8.461-7.485 16.883-8.54 29-1.054-12.113-3.257-20.535-8.539-29 2.446.285 4.989-.207 3.774-6.078-3.418-.762-4.723.492-14.313-4.032-7.84-3.7-19.246-5.191-18.609-1.062 1.6 7.676 6.652 13.5 12.007 17.461 2.567 6.308 8.817 18.2 4.473 24.863-4.246 6.5-7.816 9.762-12.121 12.457a22.049 22.049 0 0 0 2.739.769c-.649.887-6.231 2.625-6.887 2.165-4.269-3.02-8.433-10.109-12.067-14.227-10.288-11.664-15.425-20.664-13.609-27.926 2.586-7.821 11.8-16.6-.41-15.078s-22.337 18.258-25.587 38.4c-1.207 7.472-1.906 13.727-2.722 18.848 6.668-2.379 9.621-4.747 11.39-7.672a68.5 68.5 0 0 0-1.792 7.933c5.453-.965 9.41-3.64 10.827-8.523a25.511 25.511 0 0 1 2.008-4.985 11.549 11.549 0 0 0 6.492-.086c1 2.391 8.52 10.754 12.555 16.9 4.558 6.938 11.41 12.239 13.663 12.531 1.133.145 1.481-2.359 1.231-5.812 11.914.082 23.82.152 35.5-.242 11.676.394 23.582.324 35.5.242-.25 3.453.1 5.957 1.226 5.812 2.258-.292 9.11-5.593 13.664-12.531 4.039-6.144 11.555-14.508 12.555-16.9a11.548 11.548 0 0 0 6.492.086 25.5 25.5 0 0 1 2.012 4.985c1.418 4.883 5.374 7.558 10.827 8.523a67.544 67.544 0 0 0-1.8-7.933c1.774 2.925 4.727 5.293 11.391 7.672-.812-5.122-1.512-11.375-2.719-18.848-3.25-20.141-13.374-36.883-25.59-38.4s-2.992 7.257-.406 15.078c1.816 7.261-3.32 16.262-13.61 27.926-3.636 4.117-7.8 11.207-12.07 14.227-.653.46-6.235-1.278-6.887-2.165z'
						/>
					</g>
				</g>
				<g fill='#ffc72c'>
					<path
						strokeLinejoin='round'
						strokeWidth={0.667}
						d='M293.487 199.858c1.148 1 4.937 1.02 10.124 1.352 8.36.289 13.2-2.231 14.274-7.575-3.137 3.735-6.875 5.582-11.129 4.532-5.731-1.418-9.012-2.438-10.321-2.184-.879.313-2.164 1.9-2.949 3.875z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.673}
						d='M313.04 177.9c9.371 1.012 8.922 7.137.16 15.79-1.676 1.656-2.254 1.6-2.609-.117-.625-3.008-3.961-4.786-5.711-2.493-1.191 1.559-.926 2.454-4.895 1.9-5.011 4.2-9.093 7.152-2.531 6.91 2.883-.109 5.106 1.57 8.867 1.422-1.113 2.156-1.312 2.406-1.914 2.59-3.644.719-7.484-1.555-11.16-.879-8.058 1.484 12.906-21.751 12.648-22.672-.664-2.336 5.039-6.527 7.145-2.449z'
					/>
				</g>
			</g>
			<path
				fill='none'
				strokeLinecap='round'
				strokeMiterlimit={22.926}
				strokeWidth={0.673}
				d='M312.82 177.7a14.213 14.213 0 0 1-4.445 6.078'
			/>
		</g>
		<path
			fill='#21231e'
			fillRule='evenodd'
			d='M314.82 182.191a.811.811 0 0 0-.887-.679.74.74 0 0 0-.672.847.812.812 0 0 0 .887.68.74.74 0 0 0 .672-.848'
		/>
		<g fill='none' stroke='#21231e'>
			<path
				strokeLinecap='round'
				strokeMiterlimit={23.677}
				strokeWidth={0.889}
				d='M234.744 180.247c2.516-.133 5.094.969 8.442 2.179.941.344 4.218 1.633 5.214 1.481'
			/>
			<path
				strokeLinecap='round'
				strokeMiterlimit={24.134}
				strokeWidth={0.667}
				d='M261.636 188.231a48.89 48.89 0 0 1-5.524-1.16'
			/>
			<path
				strokeMiterlimit={29.599}
				strokeWidth={0.889}
				d='M236.741 180.432c1.059 1.934 2.313 4.559 4.539 5.805 1.676.937 3.539-.543 4.9-3.086'
			/>
		</g>
		<path
			fill='#21231e'
			fillRule='evenodd'
			d='m240.39 185.3-1.234-1.234q-.9-1.143 1.047-3.039c.949.41 1.707.727 2.468 1.043q-.756 2.567-2.281 3.23'
		/>
		<path
			fill='none'
			stroke='#21231e'
			strokeMiterlimit={29.599}
			strokeWidth={0.889}
			d='M303.338 180.432c-1.058 1.933-2.308 4.558-4.535 5.8-1.676.942-3.539-.543-4.9-3.082'
		/>
		<path
			fill='#21231e'
			fillRule='evenodd'
			d='m299.68 185.3 1.234-1.234q.909-1.143-1.043-3.039c-.953.41-1.71.727-2.468 1.043q.756 2.567 2.277 3.23'
		/>
		<g stroke='#21231e'>
			<g fill='none' strokeLinecap='round'>
				<path
					strokeMiterlimit={23.677}
					strokeWidth={0.889}
					d='M305.339 180.248c-2.515-.137-5.093.969-8.437 2.179-.946.34-4.219 1.629-5.219 1.481'
				/>
				<path
					strokeMiterlimit={24.134}
					strokeWidth={0.667}
					d='M278.432 188.233a48.691 48.691 0 0 0 5.527-1.157'
				/>
				<g strokeLinejoin='round' strokeWidth={0.667}>
					<path d='M236.713 231.811c5.348 1.875 10.184 1.5 13.809-2.066.574-.562 1.121-1.1 1.632-1.613' />
					<path d='M252.8 224.871a33.093 33.093 0 0 1-2.692 9.336c3.77-.98 6.75-2.707 8.57-5.308m11.294 7.032a20.594 20.594 0 0 1-4.953-12.328' />
					<path d='M257.932 224.341a18.678 18.678 0 0 0 3.039 9.086 12.136 12.136 0 0 0 4.375-6.824m38.015 5.208c-5.348 1.875-10.18 1.5-13.809-2.066-.57-.562-1.117-1.1-1.629-1.613' />
					<path d='M287.271 224.871a33.094 33.094 0 0 0 2.7 9.336c-3.774-.98-6.75-2.707-8.57-5.308' />
					<path d='M282.132 224.341a18.635 18.635 0 0 1-3.035 9.086 12.116 12.116 0 0 1-4.375-6.824' />
					<path d='M270.1 235.931a20.594 20.594 0 0 0 4.953-12.328' />
				</g>
				<path
					strokeMiterlimit={22.926}
					strokeWidth={0.667}
					d='M200.44 203.387c-4.238 3.027-12.051 24.941-12.652 31.371-1.391 14.871-1.332 33.277 3.113 45.1m148.729-76.471c4.242 3.027 12.055 24.941 12.656 31.371 1.387 14.871 1.332 33.277-3.113 45.1'
				/>
			</g>
			<path
				fill='#ffc72c'
				fillRule='evenodd'
				strokeLinejoin='round'
				strokeWidth={0.673}
				d='M227.04 177.9c-9.375 1.012-8.926 7.137-.164 15.79 1.676 1.656 2.254 1.6 2.609-.117.625-3.008 3.961-4.786 5.711-2.493 1.192 1.559.926 2.454 4.9 1.9 5.015 4.2 9.1 7.152 2.535 6.91-2.887-.109-5.113 1.57-8.871 1.422 1.113 2.156 1.312 2.406 1.914 2.59 3.645.719 7.484-1.555 11.164-.879 8.059 1.484-12.91-21.751-12.652-22.672.664-2.336-5.039-6.527-7.141-2.449z'
			/>
			<path
				fill='none'
				strokeLinecap='round'
				strokeMiterlimit={22.926}
				strokeWidth={0.673}
				d='M227.26 177.7a14.211 14.211 0 0 0 4.441 6.078'
			/>
		</g>
		<path
			fill='#21231e'
			fillRule='evenodd'
			d='M225.26 182.191a.817.817 0 0 1 .891-.679.74.74 0 0 1 .672.847.812.812 0 0 1-.887.68.745.745 0 0 1-.676-.848'
		/>
		<g stroke='#21231e'>
			<g fillRule='evenodd'>
				<g fill='#fff' strokeWidth={0.667}>
					<path
						strokeLinejoin='round'
						d='m233.366 325.092.215-6.145c-2.469-1.25-8.559.035-11.13 5.708-3.207 7.074-6.238 10.839-10.082 14.156 5.574 1.039 10.293-.062 13.336-4.141 4.1-5.5 6.3-8.836 7.66-9.578z'
					/>
					<path
						strokeMiterlimit={25.356}
						d='m237.888 325.9-6.649-2.8c-2.726 5.551-4.582 9.3-5.355 11.332a19.634 19.634 0 0 0-1.227 11 12.617 12.617 0 0 0 7.926-6.562c2.113-3.969 3.418-8.6 5.3-12.976z'
					/>
					<path
						strokeMiterlimit={23.368}
						d='m239.81 325.9-7.129-1.089c-1.289 6.046-2.176 10.136-2.426 12.3a19.583 19.583 0 0 0 1.492 10.969 12.591 12.591 0 0 0 6.086-8.3c1.082-4.367 1.215-9.18 1.977-13.879z'
					/>
					<path
						strokeLinejoin='round'
						d='m240.862 316.209 4.25-.032c2.484 1.231 5.09 6.879 2.078 12.332-3.754 6.8-4.961 11.48-5.324 16.542-4.161-3.847-6.1-8.289-4.649-13.167 1.961-6.578 3.867-14.141 3.645-15.676z'
					/>
					<path
						strokeMiterlimit={24.727}
						d='m242.764 313.247-6.8-2.41c-2.4 5.695-4.039 9.543-4.7 11.621a19.556 19.556 0 0 0-.6 11.051 12.36 12.36 0 0 0 7.9-6.641c1.887-4.082 2.559-9.144 4.191-13.621z'
					/>
					<path
						strokeLinejoin='round'
						d='m238.144 308.51-1.488-9.235c-1.566-.426-3.641 3.227-5.672 5.356a12.921 12.921 0 0 0-1.414 1.554 13.225 13.225 0 0 0-1.926 4.481 35.665 35.665 0 0 1-7.261 15.55c5.668-.117 10.063-2.156 12.211-6.769 2.9-6.226 4.367-9.934 5.55-10.937z'
					/>
				</g>
				<g fill='#ffc72c'>
					<path
						strokeLinejoin='round'
						strokeWidth={0.662}
						d='M309.53 328.092c1.805.074 4.024 1.109 10.492 6.718 2.828 5.766 5.676 11.563 9.6 16.563a15.134 15.134 0 0 0 3.453 1.852c1.109.367 9.27.093 9.469-.247 2.539-2.3 6.422-.66 5.129 1.77 1 .953-1.211 4.441-2.992 2.992-1.637-1.328-7.325-.074-9.223-.761.719 1.707 5.859 2.816 9.453 3.078 3.285.14 3.066 3.418 1.711 5.925-1.539.981-.273-.254-.832.461-1.473 2.18-4.617 1.9-4.723-.234-.109-2.2-7.777-5.074-8.925-6.766 1.406 3.348 4.648 6.469 6.257 9.746 1.731 1.735 2.778 2.082.543 5.582-2.476-.285-2.625-.941-4.148.5-3.395-.371-3.942-1.375-3.395-4.058.582-2.836-1.789-7.961-2.582-9.41-.726-1.336-.906-2.723-1.613-3.563-1.231-1.453-7.09-4.363-8.59-3.562-2.762 1.469-4.871.582-5.219-2.094.891-.687.223-4.488 2.133-4.648 1.563-.129 2.492.351 3.926 1.523 2.262 1.844 3.422 2.828 4.168 2.621 1.762-.484-3.2-2.914-7.707-8.828-6.25-8.195-6.676-12.355-6.387-15.16z'
					/>
					<path
						strokeMiterlimit={23.175}
						strokeWidth={0.667}
						d='M347.3 356.266c2.262-1.328 3.566-1.274 4.441-.289.485.543 1.1.262.739-.59-1.5-3.555-3.907-4-6.059-2.332-.6.558-1.086 1.039-1.57 1.515.359.536 2.16 1.286 2.449 1.7z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.65}
						d='M314.889 353.074c-2.4-1.629-4.653-1.949-5.887-1.031-.68.5-1.348.14-.75-.7 2.488-3.5 5.41-3.683 7.613-1.707.594.649 1.075 1.2 1.547 1.75a7.428 7.428 0 0 1-2.523 1.684z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.659}
						d='M333.817 373.149c1.472 2.91 2.25 4.906 1.613 6.469-.375.914.047 1.754.973.957 3.753-3.219 3.765-6.91 1.363-9.418a22.371 22.371 0 0 0-2.418-1.984c-.981.914-1.6 3.832-1.531 3.976z'
					/>
					<path
						strokeMiterlimit={22.926}
						strokeWidth={0.614}
						d='M343.185 365.48c2.231.926 3 2.1 2.918 3.637-.043.847.516 1.164.828.179 2.731-6.328-.1-7.515-4.191-7.285a8.454 8.454 0 0 0 .445 3.469z'
					/>
				</g>
			</g>
			<g fill='none'>
				<path
					strokeMiterlimit={31.033}
					strokeWidth={0.667}
					d='M323.965 351.385c1.469-.851 1.559-1.8 1.172-2.437-.816-1.352-1.637-.7-3.176.547'
				/>
				<path
					strokeMiterlimit={31.033}
					strokeWidth={0.667}
					d='M322.065 349.326c1.469-.856 1.559-1.805 1.176-2.441-.82-1.348-1.64-.7-3.179.546'
				/>
				<path
					strokeMiterlimit={22.926}
					strokeWidth={0.674}
					d='M319.926 347.361c1.621-1.109 1.719-2.343 1.293-3.175-.9-1.754-1.805-.911-3.5.711'
				/>
				<path
					strokeMiterlimit={22.926}
					strokeWidth={0.677}
					d='M317.623 344.891c1.719-1.257 1.824-2.648 1.375-3.589-.957-1.985-1.918-1.028-3.719.8'
				/>
				<path
					strokeMiterlimit={22.926}
					strokeWidth={0.669}
					d='M315.2 342.271c1.6-1.332 2.1-2.652 1.375-3.812-1.145-1.816-1.918-1.09-3.719.855'
				/>
			</g>
			<g fill='#fff' fillRule='evenodd' strokeWidth={0.667}>
				<path
					strokeLinejoin='round'
					d='m306.714 325.1-.218-6.144c2.472-1.25 8.562.035 11.133 5.707 3.2 7.074 6.238 10.84 10.082 14.157-5.574 1.039-10.293-.059-13.335-4.141-4.106-5.5-6.3-8.836-7.661-9.578z'
				/>
				<path
					strokeMiterlimit={25.356}
					d='m302.181 325.9 6.649-2.8c2.726 5.551 4.582 9.3 5.355 11.333a19.563 19.563 0 0 1 1.227 11 12.607 12.607 0 0 1-7.922-6.563c-2.117-3.969-3.422-8.6-5.309-12.977z'
				/>
				<path
					strokeMiterlimit={23.368}
					d='m300.27 325.9 7.129-1.09c1.289 6.047 2.175 10.136 2.425 12.3a19.566 19.566 0 0 1-1.492 10.969 12.578 12.578 0 0 1-6.085-8.3c-1.083-4.367-1.215-9.176-1.977-13.878z'
				/>
				<path
					strokeLinejoin='round'
					d='m299.2 316.205-4.25-.031c-2.48 1.23-5.09 6.879-2.078 12.332 3.754 6.8 4.961 11.48 5.324 16.546 4.161-3.847 6.1-8.293 4.649-13.172-1.961-6.578-3.867-14.14-3.645-15.675z'
				/>
				<path
					strokeMiterlimit={24.727}
					d='m297.315 313.251 6.8-2.41c2.406 5.692 4.043 9.543 4.7 11.621a19.545 19.545 0 0 1 .594 11.051 12.382 12.382 0 0 1-7.9-6.641c-1.887-4.086-2.559-9.148-4.192-13.621z'
				/>
				<path
					strokeLinejoin='round'
					d='m301.941 308.509 1.489-9.238c1.566-.422 3.64 3.226 5.667 5.355a12.32 12.32 0 0 1 1.418 1.559 13.123 13.123 0 0 1 1.926 4.48 35.674 35.674 0 0 0 7.262 15.547c-5.668-.117-10.062-2.156-12.21-6.769-2.9-6.222-4.367-9.933-5.551-10.933z'
				/>
			</g>
			<path
				fill='#e40046'
				strokeMiterlimit={22.926}
				strokeWidth={1.334}
				d='M307.847 236.6v61.125a40.6 40.6 0 0 1-11.094 28.047 36.823 36.823 0 0 1-26.711 11.648 36.816 36.816 0 0 1-26.707-11.648 40.592 40.592 0 0 1-11.093-28.047V236.6z'
			/>
			<g fill='#fff'>
				<path
					strokeMiterlimit={22.926}
					strokeWidth={0.667}
					d='M307.841 274.091v15.754h-29.93v46.7a35.9 35.9 0 0 1-7.875.872 35.859 35.859 0 0 1-7.871-.872v-46.7h-29.93v-15.754h29.93V236.6h15.75v37.492z'
				/>
				<g fillRule='evenodd' strokeLinejoin='round'>
					<path
						strokeWidth={0.676}
						d='M257.055 245.541v22.145c-4.828 1.3-16.582 5.981-17.195-4.285-.282-4.746 5.89-7.875 7.554-3.683.786 1.984-.07 3.386-1.23 3.843a1.635 1.635 0 0 1-2.168-2.035c-5 2.614 4.469 8.156 8.738.942 1.246-2.106-2.1-4.293-2.1-5.852s3.285-3.891 2.1-5.852c-4.324-7.179-13.734-1.671-8.738.938a1.636 1.636 0 0 1 2.168-2.035c1.16.457 2.016 1.859 1.23 3.844-1.664 4.191-7.836 1.062-7.554-3.684.613-10.262 12.367-5.582 17.195-4.285z'
					/>
					<path
						strokeWidth={0.671}
						d='M283.025 245.541v22.145c4.828 1.3 16.581 5.981 17.2-4.285.285-4.746-5.891-7.875-7.551-3.683-.793 1.984.067 3.386 1.227 3.843a1.634 1.634 0 0 0 2.168-2.035c5 2.614-4.465 8.156-8.739.942-1.246-2.106 2.1-4.293 2.106-5.852s-3.289-3.891-2.106-5.852c4.325-7.179 13.735-1.671 8.739.938a1.635 1.635 0 0 0-2.168-2.035c-1.16.457-2.02 1.859-1.227 3.844 1.66 4.191 7.836 1.062 7.551-3.684-.613-10.262-12.367-5.582-17.2-4.285zm-25.97 72.87v-22.148c-4.828-1.3-16.582-5.976-17.195 4.29-.282 4.746 5.89 7.875 7.554 3.683.786-1.984-.07-3.39-1.23-3.844a1.635 1.635 0 0 0-2.168 2.035c-5-2.609 4.469-8.156 8.738-.941 1.246 2.1-2.1 4.293-2.1 5.852s3.285 3.89 2.1 5.851c-4.324 7.184-13.734 1.672-8.738-.937a1.634 1.634 0 0 0 2.168 2.031c1.16-.453 2.016-1.859 1.23-3.844-1.664-4.191-7.836-1.058-7.554 3.684.613 10.27 12.367 5.586 17.195 4.289zm25.97 0v-22.148c4.828-1.3 16.581-5.976 17.2 4.29.285 4.746-5.891 7.875-7.551 3.683-.793-1.984.067-3.39 1.227-3.844a1.634 1.634 0 0 1 2.168 2.035c5-2.609-4.465-8.156-8.739-.941-1.246 2.1 2.1 4.293 2.106 5.852s-3.289 3.89-2.106 5.851c4.325 7.184 13.735 1.672 8.739-.937a1.633 1.633 0 0 1-2.168 2.031c-1.16-.453-2.02-1.859-1.227-3.844 1.66-4.191 7.836-1.058 7.551 3.684-.613 10.27-12.367 5.586-17.2 4.289z'
					/>
				</g>
			</g>
			<path
				fill='none'
				strokeMiterlimit={22.926}
				strokeWidth={1.334}
				d='M307.847 236.6v61.125a40.6 40.6 0 0 1-11.094 28.047 36.823 36.823 0 0 1-26.711 11.648 36.816 36.816 0 0 1-26.707-11.648 40.592 40.592 0 0 1-11.093-28.047V236.6z'
			/>
		</g>
	</svg>
);
export default SvgRegionSerbia;
