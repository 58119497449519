import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar35 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar35_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar35_svg__cls-1{fill:#515570}.avatar35_svg__cls-3{fill:#fff}.avatar35_svg__cls-11{fill:#393c54}.avatar35_svg__cls-5{fill:#a7aece}.avatar35_svg__cls-8{fill:none;stroke-linecap:round;stroke:#515570;stroke-linejoin:round;stroke-width:2px;opacity:.4}.avatar35_svg__cls-13{opacity:.1;fill:#356cb6}'
				}
			</style>
		</defs>
		<circle cx={64} cy={64} r={60} className='avatar35_svg__cls-1' />
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				fill: '#6b718e',
			}}
		/>
		<path
			d='M34 77.58V106s24.54 3.6 30 3.6 30-3.6 30-3.6V77.58c2.55-4.77-62.55-4.77-60 0Z'
			className='avatar35_svg__cls-3'
		/>
		<path
			d='M30 61.6a33.77 33.77 0 0 0 4 16V106s24.54 3.6 30 3.6 30-3.6 30-3.6V77.58a33.77 33.77 0 0 0 4-16Z'
			style={{
				opacity: 0.2,
				fill: '#393c54',
			}}
		/>
		<path
			d='M64 125.1a59.73 59.73 0 0 0 39.8-15.1l-2.2-5.41c-1.71-4-6.22-6.64-11.29-6.64H37.69c-5.07 0-9.58 2.66-11.29 6.64L24.2 110A59.73 59.73 0 0 0 64 125.1Z'
			className='avatar35_svg__cls-3'
		/>
		<path d='M74 100.95H54l-.78-5.48h21.56l-.78 5.48z' className='avatar35_svg__cls-5' />
		<path
			d='M64 85.7v13.75'
			style={{
				strokeWidth: 18,
				stroke: '#fba875',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path d='M64 125.05h1V107a1 1 0 1 0-2 0v18Z' className='avatar35_svg__cls-5' />
		<path
			d='M64 95.32A28.31 28.31 0 0 1 35.68 67V54.38a28.32 28.32 0 1 1 56.64 0V67A28.31 28.31 0 0 1 64 95.32Z'
			style={{
				fill: '#ffbb94',
			}}
		/>
		<path d='M74.39 51.95 84 53.7M53 51.95l-9.61 1.75' className='avatar35_svg__cls-8' />
		<path
			d='M64 104.25a11 11 0 0 1-10.78-8.8 11.4 11.4 0 0 0-.22 2.2 11 11 0 0 0 22 0 11.4 11.4 0 0 0-.22-2.2 11 11 0 0 1-10.78 8.8Z'
			className='avatar35_svg__cls-3'
		/>
		<path
			d='M64 104.25a11 11 0 0 1-10.78-8.8 11.4 11.4 0 0 0-.22 2.2 11 11 0 0 0 22 0 11.4 11.4 0 0 0-.22-2.2 11 11 0 0 1-10.78 8.8Z'
			style={{
				opacity: 0.1,
				fill: '#393c54',
			}}
		/>
		<path
			d='M67.54 81a9 9 0 0 0-7.32.11'
			style={{
				stroke: '#f85565',
				strokeLinejoin: 'round',
				strokeWidth: 2,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={78} cy={60.23} r={3} className='avatar35_svg__cls-11' />
		<circle cx={50} cy={60.23} r={3} className='avatar35_svg__cls-11' />
		<path
			d='M64 61.7v8.5'
			style={{
				strokeWidth: 6,
				stroke: '#fba875',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={68} cy={113.95} r={1.5} className='avatar35_svg__cls-1' />
		<circle cx={68} cy={120.95} r={1.5} className='avatar35_svg__cls-1' />
		<path
			d='M75 78c-.07-5-6-5.08-11-5.08S53.08 73 53 78v16.16a28.48 28.48 0 0 0 22 0V78Zm-5 9h-2.22a1 1 0 0 1-1-.76l-.62-2.48a1 1 0 0 0-1-.76h-2.38a1 1 0 0 0-1 .76l-.62 2.48a1 1 0 0 1-1 .76H58a2 2 0 0 1-2-2v-4.92a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2V85a2 2 0 0 1-2 2Z'
			className='avatar35_svg__cls-11'
		/>
		<path
			d='M37.69 98a12.7 12.7 0 0 0-10.08 4.59l11.94 6a1 1 0 0 0 1.45-.89V98Z'
			className='avatar35_svg__cls-13'
		/>
		<path
			d='m32 43-4.76 54.29a4 4 0 0 0 2.19 3.93l8.12 4.06a1 1 0 0 0 1.45-.9V46.5Z'
			className='avatar35_svg__cls-3'
		/>
		<path
			d='M90.31 98H85v9.67a1 1 0 0 0 1.45.89l13.35-6.67A13 13 0 0 0 90.31 98Z'
			className='avatar35_svg__cls-13'
		/>
		<path
			d='m96 43 4.76 54.29a4 4 0 0 1-2.19 3.93l-8.12 4.06a1 1 0 0 1-1.45-.9V46.5Z'
			className='avatar35_svg__cls-3'
		/>
		<path
			d='M95 48a31 31 0 0 0-62 0v4a181.28 181.28 0 0 1 62 0v-4Z'
			className='avatar35_svg__cls-3'
		/>
		<circle
			cx={43.5}
			cy={64.5}
			r={1.5}
			style={{
				opacity: 0.4,
				fill: '#515570',
			}}
		/>
		<path
			d='M94.69 34.18a252.1 252.1 0 0 0-61.38 0 2.88 2.88 0 0 0-2.52 2.54L30 43.63a2.86 2.86 0 0 0 3.29 3.14 207.69 207.69 0 0 1 61.38 0A2.86 2.86 0 0 0 98 43.63l-.77-6.91a2.88 2.88 0 0 0-2.54-2.54Z'
			className='avatar35_svg__cls-11'
		/>
	</svg>
);
export default SvgAvatar35;
