import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import CryptoJS from "crypto-js";
import { io, Socket } from "socket.io-client";
import {
  ISocketRequest,
  ISocketResponse,
  SocketContextProps,
} from "../type/socket-type";
import LoadingSpinner from "../component/LoadingSpinner";
import SiteContext from "./SiteContext";

export const SocketContext = createContext<any>({} as SocketContextProps);
interface SocketContextProviderProps {
  children: React.ReactNode;
}

export const SocketContextProvider: React.FC<SocketContextProviderProps> = ({
  children,
}) => {
  const [socket, setSocket] = useState<Socket>();
  const secretKey = "your_secret_key";
  const [isConnected, setIsConnected] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState<any>();
  const [sessionLoaded, setSessionLoaded] = useState(false);
  const [styleString, setStyleString] = useState("");
  const { setSelectedCurrencyId, setCurrencyList, setSelectedFiatId, setDefaultCurrency } =
    useContext(SiteContext);

  useEffect(() => {
    const token = localStorage.getItem("atox_token") || "";

    const newSocket = io("wss://eu.atox.app:8443", {
      transports: ["websocket"],
      query: { cType: "Web3", token, dN: window.location.hostname },
    });

    newSocket.on("connect", () => {
      setIsConnected(true);
      setSocket(newSocket);
    });
    newSocket.on("reconnect", () => {
      setSocket(newSocket);
    });
    newSocket.on("disconnect", () => {
      setIsConnected(false);
      console.log('disconenct')
    });
    newSocket.on("connect_error", (Error: any) => {
      if (Error?.message === "Unauthorized") {
        localStorage.removeItem("atox_token");
        window.location.reload();
      }
    });

    return () => {
      newSocket.close();
    };
  }, []);

  useEffect(() => {
    if (isConnected) {
      sendRequest({ A: "requestSession" }, (response: any) => {
        const resDat = response.R;

        console.log('resDat', resDat )
        if (resDat.welcomeMesssage.Style) {
          const strse = JSON.parse(resDat.welcomeMesssage.Style);
          const str = Object.keys(strse)
            .map((key) => {
              return `${key}: ${strse[key]};`;
            })
            .join("\n");
          setStyleString(str);
        }
        setWelcomeMessage(resDat.welcomeMesssage);
        setCurrencyList(resDat.currencyList);
        setSelectedCurrencyId(resDat.defaultCurrency);
        setDefaultCurrency(resDat.defaultCurrency);
        setSelectedFiatId(resDat.defaultFiat);

        setSessionLoaded(true);
      });
    } else {
      console.log('Session Errror')
      setSessionLoaded(false);
    }
  }, 
  
  [isConnected]);

  const sendRequest = useCallback(
    (
      request: ISocketRequest,
      callback: (response: ISocketResponse) => void
    ) => {
      if (!socket) {
        console.log('Socket Errror')
        return;
      }
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(request),
        secretKey
      ).toString();

      console.log('REquest', request);
      socket.emit("R", encryptedData, (cb: any) => {

     
        const decryptedBytes = CryptoJS.AES.decrypt(cb, secretKey);
        const receivedData = JSON.parse(
          decryptedBytes.toString(CryptoJS.enc.Utf8)
        );
        console.log('receivedData', receivedData);
        callback(receivedData);
      });
    },
    [socket]
  );

  const value = useMemo(
    () => ({
      socket,

      isConnected,
      sessionLoaded,
      sendRequest,
      welcomeMessage,
      setWelcomeMessage,
      styleString,
    }),
    [
      socket,
      isConnected,
      styleString,
      sessionLoaded,
      welcomeMessage,
      sendRequest,
      setWelcomeMessage,
    ]
  );

  if (!isConnected || !sessionLoaded) {
    return (
      <div className="vh-100 vw-100">
        <LoadingSpinner loading backdrop />
      </div>
    );
  }

  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
};

export default SocketContext;
