import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionTrinidadandTobago = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 30 18'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#CE1126' d='M0 0h30v18H0z' />
		<path fill='#FFF' d='m0 0 20.825 18H30L9.175 0z' />
		<path d='m1.53 0 20.824 18h6.117L7.646 0z' />
	</svg>
);
export default SvgRegionTrinidadandTobago;
