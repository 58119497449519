import * as React from 'react';
import { SVGProps } from 'react';
const SvgStrat = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#1387C9' />
			<path
				fill='#FFF'
				fillRule='nonzero'
				d='m16.053 18.373 9.594-5.584-9.594-5.684-9.498 5.684 9.498 5.584zm-10.006-2.92a.76.76 0 0 0 .376.657l9.627 5.608 9.785-5.788a.739.739 0 0 1 1.109.64v2.626a.743.743 0 0 1-.361.637l-10.157 6.063a.732.732 0 0 1-.746.003L5.26 19.773a.53.53 0 0 1-.189-.721.521.521 0 0 1 .716-.19l10.263 6.033 9.847-5.878v-1.9l-9.473 5.603a.732.732 0 0 1-.742.003l-9.783-5.7A1.815 1.815 0 0 1 5 15.453V12.98a.631.631 0 0 1 .947-.548l.608.357-.764.457a.521.521 0 0 1-.784-.364.529.529 0 0 1 .25-.542l10.419-6.236a.732.732 0 0 1 .75-.002l10.211 6.05a.744.744 0 0 1-.005 1.281L16.42 19.377a.732.732 0 0 1-.74-.001L5.42 13.343a.415.415 0 0 0 .608-.239.423.423 0 0 0 .02-.124v2.474z'
			/>
		</g>
	</svg>
);
export default SvgStrat;
