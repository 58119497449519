import * as React from 'react';
import { SVGProps } from 'react';
const SvgBcbc = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#004ab5' />
			<path
				fill='#fff'
				d='M12.483 7.793a8.755 8.755 0 1 0 10.75 12.427l-7.662-4.235L18.68 7.8a8.755 8.755 0 0 0-6.196-.007zm.546 1.573a7.089 7.089 0 0 1 5.018.006l-2.517 6.627 6.204 3.43a7.089 7.089 0 1 1-8.705-10.063zm2.936-4.386c1.191.047 2.366.287 3.48.71L15.53 15.999l9.651 5.335A11.028 11.028 0 1 1 15.965 4.98zm7.529 3.812c.264 0 .51.031.736.095.666.183 1.116.597 1.35 1.241.143.391.174.775.094 1.152-.072.374-.245.71-.519 1.01.514-.023.963.092 1.35.342.383.242.666.613.847 1.111.257.706.18 1.351-.233 1.935-.408.574-1.137 1.053-2.188 1.435l-4.164 1.516-2.931-8.053 3.934-1.432c.644-.234 1.219-.352 1.724-.352zm-.61 1.666c-.24 0-.517.058-.833.173l-1.852.674.69 1.899 1.853-.675c.46-.167.78-.374.958-.622.18-.248.212-.529.097-.843-.114-.314-.318-.505-.612-.572a1.221 1.221 0 0 0-.301-.034zm1.562 3.122c-.252.003-.548.066-.888.19l-2.174.791.724 1.99 2.174-.791c.49-.179.83-.393 1.016-.644.195-.253.23-.548.108-.886-.16-.437-.48-.654-.96-.65z'
			/>
		</g>
	</svg>
);
export default SvgBcbc;
