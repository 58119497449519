import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar45 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar45_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar45_svg__cls-8{fill:#fff}.avatar45_svg__cls-14{opacity:.2}.avatar45_svg__cls-3{fill:#ff6d00}.avatar45_svg__cls-4{fill:#4bc190}.avatar45_svg__cls-5{fill:#fbc0aa}.avatar45_svg__cls-6{fill:#393c54}.avatar45_svg__cls-7{fill:#515570}.avatar45_svg__cls-14{fill:none;stroke-linecap:round;stroke-linejoin:round}.avatar45_svg__cls-12{fill:#ff8b2c}.avatar45_svg__cls-14{stroke:#515570;stroke-width:2px}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#00adfe',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.2,
				fill: '#fff',
			}}
		/>
		<path
			d='M106 57a4 4 0 0 1-4-4V39a13 13 0 0 0-25.9 0l6 18a12 12 0 0 0 24 0Z'
			className='avatar45_svg__cls-3'
		/>
		<circle cx={82} cy={41} r={10} className='avatar45_svg__cls-4' />
		<path
			d='M20 57a4 4 0 0 0 4-4V39h.05A13 13 0 0 1 50 39l-6 18a12 12 0 0 1-24 0Z'
			className='avatar45_svg__cls-3'
		/>
		<circle cx={44} cy={41} r={10} className='avatar45_svg__cls-4' />
		<path
			d='M63 28a29.41 29.41 0 0 1 29.41 29.41v6.12a10.94 10.94 0 0 1-10.94 10.94H44.53a10.94 10.94 0 0 1-10.94-10.94v-6.12A29.41 29.41 0 0 1 63 28Z'
			className='avatar45_svg__cls-3'
		/>
		<circle cx={85.98} cy={74.31} r={6.43} className='avatar45_svg__cls-5' />
		<path
			d='M64 124a59.62 59.62 0 0 0 33-9.92l-2.66-7.44A10 10 0 0 0 85 100H41.05a10 10 0 0 0-9.42 6.64L29.36 113A59.74 59.74 0 0 0 64 124Z'
			className='avatar45_svg__cls-6'
		/>
		<path
			d='M77.92 100H48.09l-2.63 6.3L54 110l-3 5 12 6 11-6-2-5 8-3.7-2.08-6.3z'
			className='avatar45_svg__cls-7'
		/>
		<path
			d='M72 101.25c0 5-4 16-9 16s-9-11-9-16 4-3 9-3 9-1.97 9 3Z'
			className='avatar45_svg__cls-8'
		/>
		<path
			d='M63 87.75v13.75'
			style={{
				strokeWidth: 12,
				strokeLinejoin: 'round',
				stroke: '#fbc0aa',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={40.02} cy={74.31} r={6.43} className='avatar45_svg__cls-5' />
		<path
			d='M63 98.84a23 23 0 0 1-23-23V60.76a23 23 0 0 1 46 0v15.11a23 23 0 0 1-23 22.97Z'
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<path
			d='M44.82 51A19.9 19.9 0 0 1 62.4 38.54'
			style={{
				stroke: '#fff',
				strokeMiterlimit: 10,
				strokeWidth: '3.68px',
				opacity: 0.1,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M88.82 58.82A25.82 25.82 0 0 0 62.27 33c-14.06.39-25.09 12.28-25.09 26.35v4a4.83 4.83 0 0 0 1.48 3.51 5.93 5.93 0 0 0 1.36 1V64a4 4 0 0 1 4-4h5.38a1 1 0 0 0 .9-.55L52 56l1.72 3.45a1 1 0 0 0 .9.55h1.24a1 1 0 0 0 .91-.58l1.65-3.5 1.8 3.53a1 1 0 0 0 .89.55h10.78a1 1 0 0 0 .89-.55l1.8-3.53 1.65 3.5a1 1 0 0 0 .91.58H82a4 4 0 0 1 4 4v3.89a5.93 5.93 0 0 0 1.36-1 4.83 4.83 0 0 0 1.48-3.51Z'
			className='avatar45_svg__cls-12'
		/>
		<path
			d='M62.5 77.5v4'
			style={{
				strokeWidth: '4.71px',
				strokeLinejoin: 'round',
				stroke: '#fbc0aa',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path d='M72 68h7M54 68h-7' className='avatar45_svg__cls-14' />
		<path
			d='M70.55 86a1 1 0 0 1 .94 1.07 8.56 8.56 0 0 1-17 0 1 1 0 0 1 .96-1.07Z'
			className='avatar45_svg__cls-6'
		/>
		<path
			d='M58 92.91a8.52 8.52 0 0 0 10.08 0C67 91.16 65.17 91 63 91s-4 .16-5 1.91Z'
			style={{
				fill: '#f85565',
			}}
		/>
		<path
			d='M68 88H58a1.84 1.84 0 0 1-1.73-2h13.5A1.84 1.84 0 0 1 68 88Z'
			className='avatar45_svg__cls-8'
		/>
		<circle cx={74} cy={74} r={3} className='avatar45_svg__cls-7' />
		<circle cx={52} cy={74} r={3} className='avatar45_svg__cls-7' />
		<circle cx={71} cy={79} r={1} className='avatar45_svg__cls-12' />
		<circle cx={69} cy={82} r={1} className='avatar45_svg__cls-12' />
		<circle cx={63} cy={82} r={1} className='avatar45_svg__cls-12' />
		<circle cx={74} cy={81} r={1} className='avatar45_svg__cls-12' />
		<circle cx={77} cy={78} r={1} className='avatar45_svg__cls-12' />
		<circle cx={55} cy={78} r={1} className='avatar45_svg__cls-12' />
		<circle cx={57} cy={81} r={1} className='avatar45_svg__cls-12' />
		<circle cx={52} cy={80} r={1} className='avatar45_svg__cls-12' />
		<circle cx={47} cy={78} r={1} className='avatar45_svg__cls-12' />
		<circle cx={49} cy={81} r={1} className='avatar45_svg__cls-12' />
	</svg>
);
export default SvgAvatar45;
