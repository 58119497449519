import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomTreasure = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='-0.5 0 46 46'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<path
				fill='#9A5F5F'
				d='M17.763 2.134c-3.912-.393-6.509.468-9.148 2.496-9.182 7.054-4.753 28.272-3.906 30.12 1.256 2.741 19.033 10.436 22.179 10.744 3.147.308 15.155-31.153 15.155-31.153S25.426 2.904 17.763 2.134'
			/>
			<path
				fill='#C27D78'
				d='M37 13.697C22.806 19.54 25.886 44.992 26.887 45.495c1.001.5 13.631-6.105 14.131-6.76.501-.654 9.482-30.598-4.02-25.038'
			/>
			<path
				fill='#F1A969'
				d='M36.478 13.93c2.856-1.356 4.73-1.058 5.942.127l.006-.003s-1.875-3.193-5.303-3.193c-3.161 0-12.621 3.403-14.123 20.847 0 0-8.715-4.12-11.701-5.699-2.986-1.58-8.702-5.094-8.702-5.094s-1.2-19.59 15.166-18.78c0 0-2.397-1.5-4.757-1.426C-1.54 1.162.242 22.89.242 22.89s4.967 4.235 11.552 7.47c7.206 3.54 15.48 6.247 15.48 6.247-1.155-.693-.86-17.898 9.204-22.677'
			/>
			<path
				fill='#F7BF6D'
				d='m2.597 20.915 1.688-.605S2.597 5.908 17.763 2.134C1.397 1.325 2.597 20.915 2.597 20.915'
			/>
			<path
				fill='#FBDF89'
				d='m23 31.708.2-1.89L4.285 20.31l-1.688.605S8.313 24.43 11.3 26.01C14.284 27.59 23 31.71 23 31.71'
			/>
			<path
				fill='#F7BF6D'
				d='M39.974 26.114c-6.066 3.527-11.156 5.277-11.156 5.277s.919.877 1.673.879c2.134.003 10.709-4.92 11.042-5.774l-1.56-.381Z'
			/>
			<path
				fill='#F1A969'
				d='M40.442 17.39s.84 4.022-.468 8.725l1.56.382s1.894-7.335-1.092-9.106'
			/>
			<path
				fill='#FBDF89'
				d='M41.533 26.497c-.36.617-10.332 6.304-11.276 5.737-.9-.54.854-11.552 6.746-14.556 6.767-3.45 4.89 8.202 4.53 8.819M36.478 13.93c-10.063 4.778-10.551 21.936-9.396 22.63 1.212.726 16.019-7.163 16.482-7.954.462-.792 3.889-19.888-7.086-14.676M16.43 32.48l-.594 5.716s1.302-.384 1.74-.884c.437-.501.278-4.231.278-4.231l-1.423-.6Z'
			/>
			<path
				fill='#F1A969'
				d='M8.774 28.754s-.137 5.38.402 6.228c.539.847 6.12 3.599 6.66 3.214.539-.385.595-5.715.595-5.715l-7.657-3.727Z'
			/>
			<path
				fill='#4E433A'
				d='m13.152 35.653-.203-1.622a.85.85 0 0 0 .461-.22c.378-.378.327-1.044-.116-1.487-.443-.442-1.108-.494-1.487-.116-.379.38-.327 1.045.116 1.487.026.026.058.037.085.061l-.482 1.062 1.626.835Z'
			/>
			<path
				fill='#7F5253'
				d='m4.59 15.348-.198 1.219 17.031 7.752zM6.508 10.089l-.546 1.05L23.2 18.985zM10.298 5.662l-.953.824L26.47 14.28zM14.963 3.018l-1.205.516 16.827 7.81z'
			/>
		</g>
	</svg>
);
export default SvgCustomTreasure;
