import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionGrenada = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 500 300'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#ce1126' d='M0 0h500v300H0z' />
		<path fill='#007a5e' d='M42 42h416v216H42z' />
		<path fill='#fcd116' d='M42 42h416L42 258h416z' />
		<circle cx={250} cy={150} r={36} fill='#ce1126' />
		<path
			fill='#ce1126'
			d='M67.749 150.41c4.504 8.39 13.265 17.52 20.916 20.73.123-8.52-2.9-19.44-7.034-28.14z'
		/>
		<path
			fill='#fcd116'
			d='M60.112 121.63c6.529 13.61-16.933 46.08 22.156 53.69-4.822-6.58-7.931-17.44-6.755-26.16 8.201 3.12 16.83 12.25 20.317 19.23 10.23-37.15-26.24-34.89-35.718-46.76zM150 259.5l-11.462 35.276 30.008-21.802h-37.091l30.007 21.802zm100 0-11.462 35.276 30.007-21.802h-37.09l30.007 21.802zm100 0-11.462 35.276 30.008-21.802h-37.091l30.007 21.802zm0-258-11.462 35.276 30.007-21.802h-37.09l30.007 21.802zm-100 0-11.462 35.276 30.007-21.802h-37.09l30.007 21.802zm-100 0-11.462 35.276 30.007-21.802h-37.09l30.007 21.802zM250 117l-19.397 59.697 50.782-36.895h-62.77l50.782 36.895z'
		/>
	</svg>
);
export default SvgRegionGrenada;
