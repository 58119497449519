import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar15 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar15_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar15_svg__cls-1,.avatar15_svg__cls-12{fill:#f85565}.avatar15_svg__cls-7{fill:#fff}.avatar15_svg__cls-3{fill:#4bc190}.avatar15_svg__cls-4{fill:#356cb6;opacity:.3}.avatar15_svg__cls-5{fill:#fbc0aa}.avatar15_svg__cls-10{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke:#515570;stroke-width:1.89px;opacity:.4}.avatar15_svg__cls-12{opacity:.1}.avatar15_svg__cls-13{fill:#393c54}.avatar15_svg__cls-14{fill:#515570}'
				}
			</style>
		</defs>
		<circle cx={64} cy={64} r={60} className='avatar15_svg__cls-1' />
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.2,
				fill: '#fff',
			}}
		/>
		<path
			d='m89 51.35 12.66 14.82A4 4 0 0 1 98.11 72H75.67l-4-23Z'
			className='avatar15_svg__cls-3'
		/>
		<path
			d='m89 51.35 12.66 14.82A4 4 0 0 1 98.11 72H75.67l-4-23Z'
			className='avatar15_svg__cls-4'
		/>
		<circle cx={89.84} cy={70.87} r={7.5} className='avatar15_svg__cls-5' />
		<path
			d='M64 124a59.75 59.75 0 0 0 37.53-13.2l-2-5.53C98 101.5 93.68 99 88.89 99H39.11c-4.79 0-9.07 2.51-10.68 6.28l-2 5.53A59.75 59.75 0 0 0 64 124Z'
			style={{
				fill: '#356cb6',
			}}
		/>
		<path
			d='M74.26 101.86c0 5.66-4.6 9.25-10.26 9.25s-10.26-3.59-10.26-9.25 4.6-3.42 10.26-3.42 10.26-2.25 10.26 3.42Z'
			className='avatar15_svg__cls-7'
		/>
		<path
			d='M64 88.88v12'
			style={{
				strokeWidth: 14,
				strokeLinejoin: 'round',
				stroke: '#fbc0aa',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={38.16} cy={70.87} r={7.5} className='avatar15_svg__cls-5' />
		<path
			d='M64 98a26.78 26.78 0 0 1-26.79-26.81v-9.62a26.79 26.79 0 0 1 53.58 0v9.62A26.78 26.78 0 0 1 64 98Z'
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<circle cx={64} cy={27.38} r={4} className='avatar15_svg__cls-3' />
		<circle cx={64} cy={27.38} r={4} className='avatar15_svg__cls-4' />
		<path d='m74.12 64.84 9.09-.24M53.88 64.84l-9.09-.24' className='avatar15_svg__cls-10' />
		<path
			d='M64 70v5l2.36 4.03h-4.73'
			style={{
				strokeWidth: 3,
				strokeLinejoin: 'round',
				stroke: '#fbc0aa',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<ellipse cx={51.15} cy={78.72} className='avatar15_svg__cls-12' rx={4.34} ry={2.82} />
		<ellipse cx={76.85} cy={78.72} className='avatar15_svg__cls-12' rx={4.34} ry={2.82} />
		<path
			d='M71.55 84a1 1 0 0 1 .94 1.07 8.56 8.56 0 0 1-17 0 1 1 0 0 1 .96-1.07Z'
			className='avatar15_svg__cls-13'
		/>
		<path
			d='M59 90.91a8.52 8.52 0 0 0 10.08 0C68 89.16 66.17 89 64 89s-4 .16-5 1.91Z'
			className='avatar15_svg__cls-1'
		/>
		<path d='M62 84h2v2h-2zM65 84h2v2h-2z' className='avatar15_svg__cls-7' />
		<circle cx={76} cy={70.87} r={3} className='avatar15_svg__cls-14' />
		<circle cx={52} cy={70.87} r={3} className='avatar15_svg__cls-14' />
		<path
			d='M91.49 60.12v-.72c0-14.95-11.71-27.61-26.66-28a27.51 27.51 0 0 0-28.32 27.42v1.33a2 2 0 0 0 2 2h7.38a6.23 6.23 0 0 0 5.35-3l2.52-4.19h13c4.4 4.34 10 7.18 16.21 7.18h6.44a2 2 0 0 0 2.08-2.02Z'
			className='avatar15_svg__cls-13'
		/>
		<path
			d='M90.92 57a2 2 0 0 0 2-2.15c-1.06-14.82-13-27-28-27.45-15.67-.47-28.68 12.13-29.8 27.46a2 2 0 0 0 2 2.14Z'
			className='avatar15_svg__cls-3'
		/>
		<path
			d='M74 57c.56 0 1-1 1-2.15-.52-14.82-3.29-27-10.57-27.45-7.59-.47-15.89 12.13-16.43 27.46C48 56 48.41 57 49 57Z'
			style={{
				opacity: 0.4,
				fill: '#fff',
			}}
		/>
		<path
			d='M70.67 52c0 5-18.07 5-18.07 0a9 9 0 0 1 18.07 0Z'
			className='avatar15_svg__cls-13'
		/>
		<path
			d='M66.08 47.92a6 6 0 0 0-4.45-2h-9.69'
			style={{
				strokeMiterlimit: 10,
				strokeWidth: 4,
				stroke: '#515570',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M48 52h27.5a2.5 2.5 0 0 1 2.5 2.5 2.5 2.5 0 0 1-2.5 2.5H48a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1Z'
			className='avatar15_svg__cls-3'
		/>
		<path
			d='M48 52h27.5a2.5 2.5 0 0 1 2.5 2.5 2.5 2.5 0 0 1-2.5 2.5H48a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1Z'
			className='avatar15_svg__cls-4'
		/>
		<circle cx={74.67} cy={54.5} r={1} className='avatar15_svg__cls-13' />
		<circle cx={70.67} cy={54.5} r={1} className='avatar15_svg__cls-13' />
		<circle cx={66.67} cy={54.5} r={1} className='avatar15_svg__cls-13' />
		<circle cx={62.67} cy={54.5} r={1} className='avatar15_svg__cls-13' />
		<circle cx={58.67} cy={54.5} r={1} className='avatar15_svg__cls-13' />
		<circle cx={54.67} cy={54.5} r={1} className='avatar15_svg__cls-13' />
	</svg>
);
export default SvgAvatar15;
