import * as React from 'react';
import { SVGProps } from 'react';
const SvgStart = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#01aef0' />
			<path
				fill='#fff'
				d='M21.069 18.89c-.013 2.074-1.211 3.84-3.122 4.565-.448.17-.914.295-1.378.411-.277.07-.428.207-.45.51-.028.382-.067.763-.12 1.14-.064.446-.217.548-.627.45-.706-.168-.782-.282-.688-1.023.008-.063.017-.126.024-.19.07-.607-.016-.694-.597-.665-.261.013-.37.142-.395.398-.034.368-.07.737-.13 1.1-.06.372-.216.464-.574.386-.764-.165-.82-.25-.72-1.058.126-1.003.123-.992-.803-1.271-.41-.124-.808-.296-1.209-.455-.244-.096-.324-.278-.258-.549.084-.345.142-.698.214-1.047.124-.595.412-.775.962-.559.974.383 1.974.619 3.015.644.939.023 1.868-.039 2.725-.5.566-.305.982-.752 1.191-1.393.31-.952.032-1.722-.86-2.105-.735-.315-1.524-.49-2.265-.792-.658-.268-1.342-.536-1.91-.957-1.277-.944-1.5-2.34-1.22-3.831.318-1.681 1.347-2.771 2.874-3.35.407-.153.591-.367.598-.824.006-.495.085-.99.145-1.483.046-.379.194-.485.548-.427.752.123.842.242.768 1.023-.029.305-.054.61-.09.913-.03.255.051.372.318.38.609.018.635.007.707-.623.046-.405.08-.811.135-1.215.06-.448.205-.55.618-.466.695.143.772.249.704.973-.03.317-.045.637-.1.95-.063.36.047.527.405.61.681.158 1.355.358 2.024.566.503.156.523.213.426.736-.057.313-.114.627-.188.936-.138.582-.428.755-.982.569-.948-.32-1.9-.609-2.905-.652-.88-.037-1.748-.024-2.512.529-1.076.778-1.082 2.36.062 3.026.728.424 1.569.64 2.358.952.481.19.975.354 1.437.584 1.238.618 1.859 1.651 1.845 3.085'
			/>
		</g>
	</svg>
);
export default SvgStart;
