import * as React from 'react';
import { SVGProps } from 'react';
const SvgRen = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#080817' />
			<path
				fill='#FFF'
				d='m9.44 9.754 1.564-.901.222.363 5.8-3.35-.427-.253-.428-.236-.205-.127L6.5 10.719v.236L9.218 9.39zm0 3.444 4.55-2.623.205.365 5.817-3.352-.426-.253-.427-.236-.207-.127L6.5 14.146v.253l2.718-1.564zm0 1.722 6.036-3.49.205.363 5.817-3.35-.426-.238-.43-.254-.204-.126L6.5 15.87v.25l2.718-1.578zm0 1.708 7.568-4.363.208.366 5.768-3.335-.429-.239-.426-.25-.205-.113L6.5 17.593v.236l2.718-1.564zm0 1.722 9.054-5.23.207.38 5.769-3.334-.43-.254-.408-.238-.222-.125L6.5 19.315v.236l2.718-1.564zm15.675-7.821-.22-.127L6.5 21.037v.236l2.718-1.564.222.363 9.054-5.23.207.364 6.73-3.871v-.616zM7.67 22.318l1.517-.87.221.364 9.04-5.23.22.377 6.764-3.919v-1.216L7.465 22.192zm10.778-4.014.22.38 6.764-3.92v-1.217L8.95 23.06l.204.127 1.55-.901.219.363m-.282 1.391 1.564-.9.204.38 6.04-3.494.218.363 6.765-3.902v-1.232l-14.998 8.659zm1.501.853 1.549-.884.204.363 4.553-2.624.22.363 6.764-3.902v-1.233L11.92 24.783zm1.264.743.221.127 1.55-.902.204.38 3.067-1.77.22.365 6.764-3.905V18.7zm1.707.979 1.55-.884.219.363 1.566-.916.22.38 6.764-3.905v-1.232l-10.526 6.085zm3.413-19.896-.426-.236-.427-.254-.221-.126L6.5 12.44v.236l2.718-1.564.222.363 3.064-1.768.208.363z'
			/>
		</g>
	</svg>
);
export default SvgRen;
