import * as React from 'react';
import { SVGProps } from 'react';
const SvgXsg = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#D21E2B' />
			<path
				fill='#FFF'
				d='M16.14 5.855V3l10.626 6.132-2.457 1.428zm8.332 14.417v-9.408l2.481-1.428V21.7l-2.48-1.428zm-8.332 5.009 8.169-4.704 2.457 1.427-10.626 6.132v-2.855zM5.164 22.004l2.48-1.427 8.145 4.704v2.855L5.164 22.004zM5 9.436l2.457 1.428v9.408L5 21.7zm.164-.304L15.789 3v2.855L7.645 10.56zm10.824-3.008L7.797 10.83v9.455l8.191 4.728 8.168-4.728V10.83l-8.168-4.705zm6.577 8.45-.351.608-2.34-1.31-2.996 1.684 2.995 1.685 2.34-1.31.352.608-1.966 1.1 1.966 1.1-.351.609-1.966-1.1v2.2h-.726V17.85l-2.995-1.685v3.37l2.317 1.287-.351.597-1.966-1.1v2.2H15.8v-2.2l-1.966 1.1-.374-.608 2.34-1.288v-3.358l-2.996 1.685v2.598h-.749v-2.2l-1.942 1.1-.375-.609 1.966-1.1-1.966-1.1.375-.608 2.317 1.31 3.019-1.685-3.02-1.685-2.316 1.31-.375-.608 1.966-1.1-1.966-1.1.375-.608 1.942 1.1v-2.212h.75v2.598l2.995 1.685v-3.358l-2.317-1.288.351-.608 1.966 1.1v-2.2h.726v2.2l1.966-1.1.35.608-2.316 1.288v3.37l2.995-1.685v-2.61h.726v2.2l1.966-1.1.35.609-1.965 1.1 1.966 1.111z'
			/>
		</g>
	</svg>
);
export default SvgXsg;
