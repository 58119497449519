import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar6 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar6_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar6_svg__cls-10{fill:#356cb6}.avatar6_svg__cls-3,.avatar6_svg__cls-9{fill:#fff}.avatar6_svg__cls-3{opacity:.3}.avatar6_svg__cls-11{fill:none;stroke-linecap:round;stroke-linejoin:round}.avatar6_svg__cls-7{fill:#4bc190}.avatar6_svg__cls-8{fill:#393c54}.avatar6_svg__cls-10{opacity:.1}.avatar6_svg__cls-11{stroke:#393c54;stroke-width:2px}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#ff8475',
			}}
		/>
		<path
			d='M113.75 78.27C113.75 73.15 91.47 75 64 75s-49.75-1.85-49.75 3.27S36.53 97.48 64 97.48s49.75-14.09 49.75-19.21Z'
			style={{
				fill: '#356cb6',
			}}
		/>
		<path
			d='M64 19a44 44 0 0 1 44 44v44H20V63a44 44 0 0 1 44-44Z'
			className='avatar6_svg__cls-3'
		/>
		<path
			d='M64 27.18V18'
			style={{
				strokeWidth: 6,
				strokeLinejoin: 'round',
				stroke: '#4bc190',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M64 27.18V18'
			style={{
				stroke: '#356cb6',
				strokeMiterlimit: 10,
				strokeWidth: 6,
				fill: 'none',
				strokeLinecap: 'round',
				opacity: 0.3,
			}}
		/>
		<circle
			cx={64}
			cy={17}
			r={4.75}
			style={{
				fill: '#f8dc25',
			}}
		/>
		<path
			d='M64 115a26 26 0 0 1-26-26V52a26 26 0 0 1 52 0v37a26 26 0 0 1-26 26Z'
			className='avatar6_svg__cls-7'
		/>
		<path
			d='M64 114a18.59 18.59 0 0 1-18.56-18.56V74.56C45.44 64.33 53.77 61 64 61s18.56 3.33 18.56 13.56v20.88A18.59 18.59 0 0 1 64 114Z'
			className='avatar6_svg__cls-3'
		/>
		<path
			d='M72.1 88.31a53.34 53.34 0 0 1-16.25 0 3.75 3.75 0 0 1-3.09-2.93 68.23 68.23 0 0 1-1.12-16.15 3.83 3.83 0 0 1 2.73-3.54 34.22 34.22 0 0 1 19.24 0 3.82 3.82 0 0 1 2.74 3.56 72.18 72.18 0 0 1-1.16 16.09 3.72 3.72 0 0 1-3.09 2.97Z'
			className='avatar6_svg__cls-8'
		/>
		<path
			d='M113.19 76.76s-7 15.87-49.19 15.87-49.19-15.87-49.19-15.87l-9 2.07a60 60 0 0 0 116.3 0Z'
			className='avatar6_svg__cls-9'
		/>
		<path
			d='M113.19 76.76s-7 15.87-49.19 15.87-49.19-15.87-49.19-15.87l-9 2.07a60 60 0 0 0 116.3 0Z'
			className='avatar6_svg__cls-10'
		/>
		<path
			d='M64 100.19c-42.48 0-54.88-15.54-57.68-19.66a60 60 0 0 0 115.36 0c-2.8 4.12-15.2 19.66-57.68 19.66Z'
			className='avatar6_svg__cls-10'
		/>
		<path
			d='M64 36.42v5.41M69 37.42l-2 5.41M73 40.42l-3 3.41M59 37.42l2 5.41M55 40.42l3 3.41'
			className='avatar6_svg__cls-11'
		/>
		<circle cx={64} cy={49.67} r={9} className='avatar6_svg__cls-9' />
		<circle cx={64} cy={49.67} r={4.25} className='avatar6_svg__cls-8' />
		<path d='M73 49.67a9 9 0 0 0-18 0 21.23 21.23 0 0 1 18 0Z' className='avatar6_svg__cls-7' />
		<path
			d='M73 49.67a9 9 0 0 0-18 0 21.23 21.23 0 0 1 18 0Z'
			style={{
				opacity: 0.3,
				fill: '#356cb6',
			}}
		/>
		<path
			d='M75.19 85.34c.32-1.71.56-3.45.75-5.21-2.26-3.41-6.76-4.89-11.95-4.89s-9.77 1.51-12 5c.2 1.75.45 3.47.79 5.16a3.75 3.75 0 0 0 3.09 2.93 53.34 53.34 0 0 0 16.25 0 3.72 3.72 0 0 0 3.07-2.99Z'
			style={{
				fill: '#f85565',
			}}
		/>
		<path
			d='M64 75.24h-1V81a1 1 0 0 0 2 0v-5.73c-.34-.01-.67-.03-1-.03Z'
			style={{
				fill: '#515570',
				opacity: 0.3,
			}}
		/>
		<path
			d='M71.59 85.22a54.36 54.36 0 0 1-15.18 0A1.23 1.23 0 0 0 55 86.43v1.63a4 4 0 0 0 .85.25 53.34 53.34 0 0 0 16.25 0A3.36 3.36 0 0 0 73 88v-1.57a1.23 1.23 0 0 0-1.41-1.21ZM73.61 65.69a34.22 34.22 0 0 0-19.24 0l-.37.14v1a2 2 0 0 0 2.2 2 86.47 86.47 0 0 1 15.6 0 2 2 0 0 0 2.2-2v-1a2.62 2.62 0 0 0-.39-.14Z'
			className='avatar6_svg__cls-9'
		/>
		<path
			d='M42.5 39.5C30.78 51.22 28 63.68 28 56s3.93-15.36 9.79-21.21S51.3 25 59 25s-4.78 2.78-16.5 14.5Z'
			className='avatar6_svg__cls-3'
		/>
		<path
			d='m97.49 51.33.89 2.79.44 1.4 1.33 4.19a2.93 2.93 0 1 0 3.67 1.91l-2.21-7 4.19-1.33 1.33 4.19a2.93 2.93 0 1 0 3.68 1.9l-2.22-7-1.27-4.21Z'
			style={{
				fill: '#515570',
			}}
		/>
	</svg>
);
export default SvgAvatar6;
