import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar5 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar5_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar5_svg__cls-3{fill:#393c54}.avatar5_svg__cls-4{fill:#8f5653}.avatar5_svg__cls-10,.avatar5_svg__cls-5{fill:#f85565}.avatar5_svg__cls-6{fill:#ffd8c9}.avatar5_svg__cls-11,.avatar5_svg__cls-12,.avatar5_svg__cls-9{fill:none;stroke-linecap:round}.avatar5_svg__cls-12{stroke:#8f5653;stroke-miterlimit:10}.avatar5_svg__cls-9{stroke:#393c54}.avatar5_svg__cls-11,.avatar5_svg__cls-9{stroke-linejoin:round}.avatar5_svg__cls-10{opacity:.2}.avatar5_svg__cls-11{stroke:#515570;stroke-width:2px;opacity:.5}.avatar5_svg__cls-12{stroke-width:4px}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#00adfe',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.3,
				fill: '#fff',
			}}
		/>
		<path
			d='M64 8.68a41 41 0 0 1 41 41v8a27.66 27.66 0 0 1-27.66 27.69H50.66A27.66 27.66 0 0 1 23 57.71v-8a41 41 0 0 1 41-41Z'
			className='avatar5_svg__cls-3'
		/>
		<circle cx={89} cy={64} r={7} className='avatar5_svg__cls-4' />
		<path
			d='M64 124a59.69 59.69 0 0 0 32.55-9.61l-3.18-10.75A10 10 0 0 0 84 97H44.05a10 10 0 0 0-9.42 6.64l-3.18 10.75A59.69 59.69 0 0 0 64 124Z'
			className='avatar5_svg__cls-5'
		/>
		<path d='M64 115a18 18 0 0 0 18-18H46a18 18 0 0 0 18 18Z' className='avatar5_svg__cls-6' />
		<path
			d='M64 91.75v7.75'
			style={{
				strokeWidth: 14,
				stroke: '#8f5653',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={39} cy={64} r={7} className='avatar5_svg__cls-4' />
		<path
			d='M64 94a25 25 0 0 1-25-25V51.52a25 25 0 1 1 50 0V69a25 25 0 0 1-25 25Z'
			style={{
				fill: '#b56b63',
			}}
		/>
		<circle cx={77.25} cy={62.28} r={3} className='avatar5_svg__cls-3' />
		<path d='M71.75 63.37a6.61 6.61 0 0 1 11.5-1.31' className='avatar5_svg__cls-9' />
		<circle cx={50.75} cy={62.28} r={3} className='avatar5_svg__cls-3' />
		<path d='M56.25 63.37a6.61 6.61 0 0 0-11.5-1.31' className='avatar5_svg__cls-9' />
		<ellipse cx={51} cy={70} className='avatar5_svg__cls-10' rx={5.08} ry={3} />
		<ellipse cx={77} cy={70} className='avatar5_svg__cls-10' rx={5.08} ry={3} />
		<circle cx={90} cy={72} r={3} className='avatar5_svg__cls-6' />
		<circle cx={38} cy={72} r={3} className='avatar5_svg__cls-6' />
		<path
			d='m73 56 5.18-2.36a4.6 4.6 0 0 1 4.67.5L84 55M55 56l-5.18-2.36a4.6 4.6 0 0 0-4.67.5L44 55'
			className='avatar5_svg__cls-11'
		/>
		<path
			d='M91 31C81 14 64 16 64 16s-17-2-27 15a47.5 47.5 0 0 0-6 33s3-4.72 8-4.72c0-.2 3.65-8.08 5.68-12.44A3.48 3.48 0 0 1 48.8 45 55 55 0 0 0 64 47a55 55 0 0 0 15.2-2 3.48 3.48 0 0 1 4.12 1.89C85.35 51.2 89 59.08 89 59.28c5 0 8 4.72 8 4.72a47.5 47.5 0 0 0-6-33Z'
			className='avatar5_svg__cls-3'
		/>
		<path d='M64 65v8M67 72h-6' className='avatar5_svg__cls-12' />
		<path
			d='M71.55 79a1 1 0 0 1 .94 1.07 8.56 8.56 0 0 1-17 0 1 1 0 0 1 .96-1.07Z'
			className='avatar5_svg__cls-3'
		/>
		<path
			d='M59 85.91a8.52 8.52 0 0 0 10.08 0 5.79 5.79 0 0 0-10.08 0Z'
			className='avatar5_svg__cls-5'
		/>
		<path
			d='M69 81H59a1.84 1.84 0 0 1-1.73-2h13.5A1.84 1.84 0 0 1 69 81Z'
			style={{
				fill: '#fff',
			}}
		/>
	</svg>
);
export default SvgAvatar5;
