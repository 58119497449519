import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ThemeContext from '../../context/ThemeContext';
import SocketContext from '../../context/SocketContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from '../../component/LoadingSpinner';
import FilterComponent from '../../component/Filter';
import dayjs from 'dayjs';
import _ from 'lodash';
import Icon from '../../component/icon/Icon';
import useCombine from '../../hooks/useCombine';
import CopyButton from '../../component/CopyButton';
import './History.scss';

interface FilterItem {
	label: string;
	name: string;
	value: any;
	type: 'date' | 'string' | 'number' | 'select';
	placeholder?: string;
	options?: any[];
}

interface Transaction {
	_id: string;
	Method: string;
	createdAt: string;
	Amount: any;
	currencyId: any;
	transactionMethod: string;
	finalBalance: any;
}

const HistoryComponent: React.FC = () => {
	const { t, i18n } = useTranslation();
	const [transactions, setTransactions] = useState<Transaction[]>([]);
	const [loading, setLoading] = useState(true);
	const todayDate = dayjs().format('YYYY-MM-DD');
	const tomorrowDate = dayjs().add(1, 'day').format('YYYY-MM-DD');
	const { mobileDesign } = useContext(ThemeContext);
	const { sendRequest } = useContext(SocketContext);
	const [perPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalDocuments, setTotalDocuments] = useState(0);
	const [filters, setFilters] = useState<any>({
		type: 0,
		startDate: todayDate,
		endDate: tomorrowDate,
	});

	const defaultFilter: FilterItem[] = [
		{
			name: 'type',
			label: t('TransactionType'),
			type: 'select',
			options: [
				{ name: 'All', label: t('All'), value: 0 },
				{ name: 'Deposit', label: t('Deposit'), value: 'D' },
				{ name: 'Withdrawal', label: t('Withdrawal'), value: 'W' },
			],
			value: filters.type,
		},
		{
			name: 'startDate',
			label: t('startDate'),
			type: 'date',
			placeholder: '...from',
			value: filters.startDate,
		},
		{
			name: 'endDate',
			label: t('endDate'),
			type: 'date',
			placeholder: '...to',
			value: filters.endDate,
		},
	];

	const { parseCurrencyAmount } = useCombine();

	const loadTransactions = useCallback(
		(page: number, filters: any) => {
			if (page === 1) setLoading(true);

			const cloneFilters = _.cloneDeep(filters);
			if (cloneFilters.startDate) {
				// convert string date to Unix
				cloneFilters.startDate = dayjs(cloneFilters.startDate).unix();
				cloneFilters.endDate = dayjs(cloneFilters.endDate).unix();
			}

			sendRequest(
				{ A: 'getTransactions', D: { filter: cloneFilters, page, perPage } },
				(response: any) => {
					const { transactions: newTransactions, totalDocuments } = response.R;

					setTotalDocuments(totalDocuments);
					setTransactions((prev) =>
						page === 1 ? newTransactions : [...prev, ...newTransactions],
					);
					setLoading(false);
				},
			);
		},
		[sendRequest, perPage],
	);

	useEffect(() => {
		loadTransactions(1, filters);
	}, [filters, loadTransactions]);

	const handleFilterChange = (name: string, value: string) => {
		setFilters((prev: any) => ({ ...prev, [name]: value }));
		setCurrentPage(1);
		setTransactions([]);
	};

	const handleSubmit = (values: any) => {
		setFilters(values);
		setTransactions([]);
		setCurrentPage(1);
	};

	const fetchMoreTransactions = () => {
		const nextPage = currentPage + 1;
		setCurrentPage(nextPage);
		loadTransactions(nextPage, filters);
	};

	const LoadingSkeleton = () => {
		return (
			<>
				{[...Array(10)].map((_, index) => (
					<div className='historyListEl' key={index}>
						<div className='historyListEl-list-item'>
							<div className='historyListEl-info'>
								<span className='skeleton-loader-text'></span>
							</div>
						</div>
						<div className='historyListEl-list-item'>
							<div className='historyListEl-info'>
								<span className='skeleton-loader-text'></span>
							</div>
						</div>
						<div className='historyListEl-list-item'>
							<div className='historyListEl-info'>
								<span className='skeleton-loader-text'></span>
							</div>
						</div>
						<div className='historyListEl-list-item'>
							<div className='historyListEl-info'>
								<span className='skeleton-loader-text'></span>
							</div>
						</div>
						<div className='historyListEl-list-item'>
							<div className='historyListEl-info'>
								<span className='skeleton-loader-text'></span>
							</div>
						</div>
					</div>
				))}
			</>
		);
	};

	return (
		<>
			{!mobileDesign && <div className='overlay-header'>{t('transactionHistory')}</div>}
			<div className='u-i-e-p-p-content-bc u-i-common-content'>
				<div className='componentFilterWrapper-bc'>
					<FilterComponent
						items={defaultFilter}
						onSubmit={handleSubmit}
						onChange={handleFilterChange}
					/>
				</div>

				<div className='historyList-table-details' id='scrollableDiv'>
					<div className='historyList-thead'>
						<div className='historyListEl'>
							<div className='historyListEl-list-item'>
								<div className='historyListEl-info'>{t('ID')}</div>
							</div>
							<div className='historyListEl-list-item'>
								<div className='historyListEl-info'>{t('Type')}</div>
							</div>
							<div className='historyListEl-list-item'>
								<div className='historyListEl-info'>{t('SubType')}</div>
							</div>
							<div className='historyListEl-list-item'>
								<div className='historyListEl-info'>{t('Amount')}</div>
							</div>

							<div className='historyListEl-list-item'>
								<div className='historyListEl-info'>{t('finalBalance')}</div>
							</div>
						</div>
					</div>

					{loading ? (
						<LoadingSpinner loading={loading} />
					) : (
						<InfiniteScroll
							dataLength={transactions.length}
							next={fetchMoreTransactions}
							hasMore={totalDocuments > transactions.length}
							loader={<LoadingSkeleton />}
							className='historyList-tbody'
							style={{ overflow: 'hidden' }}
							scrollableTarget='scrollableDiv'>
							{transactions.length > 0 ? (
								transactions.map((transaction) => (
									<div className='historyListEl' key={transaction._id}>
										<div className='historyListEl-list-item'>
											<div className='historyListEl-info'>
												<div>
													<time className='historyListEl-date'>
														{dayjs(transaction.createdAt).format(
															'DD.MM.YYYY HH:mm:ss',
														)}
													</time>
													<div className='d-flex'>
														<div
															className='ellipsis'
															style={{ maxWidth: '100px ' }}>
															ID: {transaction._id}
														</div>
														<CopyButton copyText={transaction._id} />
													</div>
												</div>
											</div>
										</div>
										<div className='historyListEl-list-item'>
											<div className='historyListEl-info'>
												{transaction.Method === 'D'
													? t('Deposit')
													: t('Withdrawal')}
											</div>
										</div>
										<div className='historyListEl-list-item'>
											<div className='historyListEl-info'>
												{t(transaction.transactionMethod)}
											</div>
										</div>
										<div className='historyListEl-list-item'>
											<div className='historyListEl-info'
                      
                      data-status={
                        transaction.Method === 'D' ? '1' : '0'
                      }
                      
                      >
												{
													parseCurrencyAmount(
														transaction.currencyId,
														transaction.Amount,
														i18n.language,
													).str
												}
												&nbsp;{' '}
												<Icon
													icon={transaction.currencyId?.icon}
													className='currencyMenuIcon'
												/>
											</div>
										</div>

										<div className='historyListEl-list-item'>
											<div
												className='historyListEl-info'
												data-status="2">
												{
													parseCurrencyAmount(
														transaction.currencyId,
														transaction.finalBalance?.available +
															transaction.finalBalance?.bonus,
														i18n.language,
													).str
												}
											</div>
										</div>
									</div>
								))
							) : (
								<p className='empty-b-text-v-bc'>{t('NO_DATA_TIMERANGE')}</p>
							)}
						</InfiniteScroll>
					)}
				</div>
			</div>
		</>
	);
};

export default HistoryComponent;
