import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomWallet = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 512 512'
		className='svg-icon'
		{...props}>
		<path
			d='M498.949 366.018H15.167c-7.178 0-13.051-5.873-13.051-13.051V21.159C2.116 9.523 11.637.002 23.273.002h475.676C506.127.002 512 5.875 512 13.053v339.914c0 7.178-5.873 13.051-13.051 13.051z'
			style={{
				fill: '#455644',
			}}
		/>
		<path
			d='M481.253 339.572H34.934c-6.426 0-11.683-5.257-11.683-11.683V40.098c0-10.417 8.523-18.939 18.939-18.939h439.063c6.426 0 11.683 5.257 11.683 11.683v295.047c0 6.425-5.257 11.683-11.683 11.683z'
			style={{
				fill: '#c3d47d',
			}}
		/>
		<path
			d='M469.686 416.795H12.694C5.712 416.795 0 411.082 0 404.101V63.473c0-6.982 5.712-12.694 12.694-12.694h456.992c6.982 0 12.694 5.712 12.694 12.694v340.628c0 6.981-5.712 12.694-12.694 12.694z'
			style={{
				fill: '#376e58',
			}}
		/>
		<path
			d='M440.066 182.481a5.285 5.285 0 0 0-5.289 5.289v30.064c0 2.93 2.37 5.289 5.289 5.289a5.285 5.285 0 0 0 5.289-5.289V187.77c0-2.93-2.37-5.289-5.289-5.289zm0 90.665a5.285 5.285 0 0 0-5.289 5.289v30.064c0 2.92 2.37 5.289 5.289 5.289a5.292 5.292 0 0 0 5.289-5.289v-30.064c0-2.93-2.37-5.289-5.289-5.289zm-316.174 96.046H93.817c-2.92 0-5.289 2.37-5.289 5.289s2.369 5.289 5.289 5.289h30.075c2.919 0 5.289-2.37 5.289-5.289s-2.369-5.289-5.289-5.289zm-79.6 0H14.217c-2.92 0-5.289 2.37-5.289 5.289s2.369 5.289 5.289 5.289h30.075c2.919 0 5.289-2.37 5.289-5.289s-2.369-5.289-5.289-5.289zm163.918 0h-30.075c-2.92 0-5.289 2.37-5.289 5.289s2.369 5.289 5.289 5.289h30.075c2.919 0 5.289-2.37 5.289-5.289s-2.369-5.289-5.289-5.289zm84.201 0h-30.064c-2.93 0-5.289 2.37-5.289 5.289s2.359 5.289 5.289 5.289h30.064c2.93 0 5.289-2.37 5.289-5.289s-2.359-5.289-5.289-5.289zm75.961 0h-30.075c-2.919 0-5.289 2.37-5.289 5.289s2.37 5.289 5.289 5.289h30.075c2.919 0 5.289-2.37 5.289-5.289s-2.369-5.289-5.289-5.289zM399.161 379.77a5.289 5.289 0 0 1-5.287-5.214 5.288 5.288 0 0 1 5.213-5.364c15.836-.227 30.2-9.119 37.486-23.207a5.29 5.29 0 0 1 9.397 4.859c-9.083 17.559-26.99 28.642-46.732 28.924-.026.002-.052.002-.077.002z'
			style={{
				fill: '#f4d9a2',
			}}
		/>
		<path
			d='M445.355 270.106v-30.064c0-2.93-2.37-5.289-5.289-5.289a5.285 5.285 0 0 0-5.289 5.289v30.064c0 2.93 2.37 5.289 5.289 5.289a5.284 5.284 0 0 0 5.289-5.289zm0-90.665v-30.064c0-2.92-2.37-5.289-5.289-5.289a5.292 5.292 0 0 0-5.289 5.289v30.064c0 2.93 2.37 5.289 5.289 5.289a5.283 5.283 0 0 0 5.289-5.289zM129.182 83.396c0-2.92-2.37-5.289-5.289-5.289H93.817c-2.92 0-5.289 2.37-5.289 5.289s2.369 5.289 5.289 5.289h30.075c2.92 0 5.29-2.37 5.29-5.289zm-79.6 0c0-2.92-2.37-5.289-5.289-5.289H14.217c-2.92 0-5.289 2.37-5.289 5.289s2.369 5.289 5.289 5.289h30.075c2.92 0 5.29-2.37 5.29-5.289zm163.917 0c0-2.92-2.37-5.289-5.289-5.289h-30.075c-2.92 0-5.289 2.37-5.289 5.289s2.369 5.289 5.289 5.289h30.075c2.92 0 5.289-2.37 5.289-5.289zm84.201 0a5.284 5.284 0 0 0-5.289-5.289h-30.064c-2.93 0-5.289 2.37-5.289 5.289s2.359 5.289 5.289 5.289h30.064c2.93 0 5.289-2.37 5.289-5.289zm75.961 0c0-2.92-2.37-5.289-5.289-5.289h-30.075c-2.919 0-5.289 2.37-5.289 5.289s2.37 5.289 5.289 5.289h30.075c2.92 0 5.289-2.37 5.289-5.289zM399.237 78.107c19.743.282 37.65 11.365 46.732 28.924a5.289 5.289 0 0 1-2.269 7.128 5.288 5.288 0 0 1-7.128-2.269c-7.286-14.088-21.651-22.979-37.486-23.207a5.287 5.287 0 0 1-5.213-5.364 5.289 5.289 0 0 1 5.287-5.214l.077.002z'
			style={{
				fill: '#f4d9a2',
			}}
		/>
		<path
			d='M512 280.333H320.529c-5.842 0-10.579-4.736-10.579-10.578v-94.149c0-5.842 4.736-10.578 10.579-10.578H512v115.305z'
			style={{
				fill: '#a5bb5a',
			}}
		/>
		<circle
			cx={397.282}
			cy={222.68}
			r={31.265}
			style={{
				fill: '#f3e161',
			}}
		/>
		<path
			d='M29.693 0C18.056 0 8.535 9.521 8.535 21.158v35.34a12.58 12.58 0 0 0-2.116 6.974V404.1c0 6.982 5.713 12.695 12.694 12.695h34.908L470.817 0H29.693z'
			style={{
				opacity: 0.16,
				fill: '#fff',
			}}
		/>
	</svg>
);
export default SvgCustomWallet;
