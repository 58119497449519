import * as React from 'react';
import { SVGProps } from 'react';
const SvgSlr = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#FDA616' />
			<path
				fill='#FFF'
				fillRule='nonzero'
				d='M11.921 22c.117.364 1.565 1.864 3.072 1.864 2.234 0 3.539-1.348 3.539-3.298 0-1.806-.904-2.838-3.188-3.842-2.761-1.118-4.468-2.753-4.468-5.477C10.876 8.237 13.06 6 16.348 6c1.732 0 3.487 1.09 4.069 1.818L19.02 9.273c-.552-.344-1.215-1.209-2.747-1.209-2.309 0-3.188 1.577-3.188 2.896 0 1.807 1.03 2.696 3.363 3.728 2.862 1.262 4.318 2.839 4.318 5.677 0 2.983-1.944 5.635-5.935 5.635-1.632 0-3.375-1.09-4.423-2.424L11.921 22zm1.862-10.303c-.349-.727-.116-1.454-.116-1.454 8.844-1.455 7.215 7.15 7.215 7.15-.698-1.332-1.396-1.575-1.396-1.575.116-4.97-5.703-4.121-5.703-4.121zm3.957 7.879c.35.849-.054 1.732-.054 1.732-10.07 1.298-7.35-7.91-7.353-7.91.534 1.414 1.198 1.745 1.198 1.745-.19 5.766 6.209 4.433 6.209 4.433z'
			/>
		</g>
	</svg>
);
export default SvgSlr;
