import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomLira = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 500 500'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<defs>
			<linearGradient
				id='CustomLira_svg__a'
				x1={249.587}
				x2={249.587}
				y1={0.434}
				y2={496.958}
				gradientTransform='translate(.413 1.304)'
				gradientUnits='userSpaceOnUse'>
				<stop
					offset={0}
					style={{
						stopColor: '#e30a18',
					}}
				/>
				<stop
					offset={1}
					style={{
						stopColor: '#81060e',
					}}
				/>
			</linearGradient>
		</defs>
		<ellipse
			cx={250}
			cy={250}
			rx={247.935}
			ry={248.262}
			style={{
				fill: 'url(#CustomLira_svg__a)',
				stroke: 'url(#CustomLira_svg__gradient-1)',
			}}
		/>
		<text
			x={151.564}
			y={371.35}
			style={{
				fill: '#fff',
				fontFamily: 'Arial,sans-serif',
				fontSize: 350,
				whiteSpace: 'pre',
			}}>
			{'\u20BA'}
		</text>
	</svg>
);
export default SvgCustomLira;
