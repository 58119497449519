import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useCombine from "../../hooks/useCombine";
import ThemeContext from "../../context/ThemeContext";
import { Swiper, SwiperSlide } from "swiper/react";
import Navigation from "swiper";

import "swiper/css";

const BalanceComponent: React.FC = () => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const { mobileDesign } = useContext(ThemeContext);
  const [isBalanceVisible, setIsBalanceVisible] = useState(true);

  const {
    getCurrencyListWithAccount,
    getCurrentAccount,
    parseCurrencyAmount,
    defaultCurrency,
    selectedCurrencyId,
  } = useCombine();

  const currentAccount = getCurrentAccount();

  const availableBalance = parseCurrencyAmount(
    currentAccount,
    currentAccount.account?.balance.available,
    i18n.language
  );

  const bonusBalance = parseCurrencyAmount(
    currentAccount,
    currentAccount.account?.balance.bonus,
    i18n.language
  );

  const lockedBalance = parseCurrencyAmount(
    currentAccount,
    currentAccount.account?.balance.locked,
    i18n.language
  );

  const handleNavigate = (page: string) => {
    navigate({
      search: `?profile=open&account=balance&page=${page}`,
    });
  };

  const toggleBalanceVisibility = () => {
    setIsBalanceVisible(!isBalanceVisible);
  };

  const MainBalance = () => {
    return (
      <div className="u-i-p-amounts-bc withdrawable">
        <div className="u-i-p-a-content-bc">
          <div className="total-balance-r-bc">
            <div className="u-i-p-a-user-balance">
              <span className="u-i-p-a-title-bc ellipsis">
                {t("mainBalance")}
              </span>
              <b className="u-i-p-a-amount-bc">
                {isBalanceVisible ? availableBalance.str : "***"}
              </b>
            </div>
            <i
              className={`u-i-p-a-c-icon-bc ${
                isBalanceVisible ? "bc-i-eye" : "bc-i-eye-hidden"
              }`}
              onClick={toggleBalanceVisibility}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  toggleBalanceVisibility();
                }
              }}
            ></i>
          </div>
          <div className="u-i-p-a-buttons-bc">
            <div
              className="u-i-p-a-deposit-bc ellipsis"
              onClick={() => handleNavigate("deposit")}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleNavigate("deposit");
                }
              }}
            >
              <i className="bc-i-wallet"></i>
              <span className="ellipsis" title={t("Deposit")}>
                {t("DepositNow")}
              </span>
            </div>
            <div
              className="u-i-p-a-withdraw-bc ellipsis"
              onClick={() => handleNavigate("withdraw")}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleNavigate("withdraw");
                }
              }}
            >
              <i className="bc-i-withdraw"></i>
              <span className="ellipsis" title={t("Withdrawal")}>
                {t("WithdrawNow")}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const BonusBalance = () => {
    return (
      <div className="u-i-p-amounts-bc bonuses">
        <div className="u-i-p-a-content-bc">
          <span className="u-i-p-a-title-bc ellipsis">{t("bonusBalance")}</span>
          <span className="u-i-p-a-amount-bc">{bonusBalance.str}</span>
          <div className="bonus-info-section">
            <div>
              <span className="ellipsis">{t("lockedBalance")}</span>
              <b>{lockedBalance.str}</b>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (mobileDesign) {
    return (
      <div className="u-i-p-amount-holder-bc">
        <Swiper
          
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          spaceBetween={10}
         
          slidesPerView={2}
          allowSlideNext
          allowSlidePrev
        >
          <SwiperSlide>
            <MainBalance />
          </SwiperSlide>
          <SwiperSlide>
            <BonusBalance />
          </SwiperSlide>
        </Swiper>
      </div>
    );
  }
  return (
    <div className="u-i-p-amount-holder-bc">
      <MainBalance />
      <BonusBalance />
    </div>
  );
};

export default BalanceComponent;
