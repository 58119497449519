import * as React from 'react';
import { SVGProps } from 'react';
const SvgUma = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#FF4A4A' />
			<g fill='#FFF'>
				<path d='M19.328 18.981h-1.012a.346.346 0 0 1-.347-.347v-2.662l-1.772 1.106a.458.458 0 0 1-.488 0l-1.762-1.106v2.662c0 .188-.15.347-.347.347h-1.003a.346.346 0 0 1-.347-.347v-5.137a.46.46 0 0 1 .46-.46c.093 0 .177.029.252.085l2.794 1.922a.323.323 0 0 0 .385 0l2.793-1.922a.464.464 0 0 1 .722.375v5.137a.33.33 0 0 1-.328.347zm-8.588-.009H4.46a.46.46 0 0 1-.46-.46v-5.165A.354.354 0 0 1 4.356 13H5.37c.187 0 .347.15.347.347v3.928h3.806v-3.928c0-.188.15-.347.347-.347h.984c.188 0 .347.15.347.347v5.166a.46.46 0 0 1-.46.459zM21.184 13h6.282a.46.46 0 0 1 .459.46v5.165c0 .188-.15.347-.338.347h-1.012a.346.346 0 0 1-.347-.347v-3.703a.228.228 0 0 0-.225-.225h-3.347a.228.228 0 0 0-.225.225v3.703c0 .188-.15.347-.347.347h-1.012a.346.346 0 0 1-.347-.347v-5.166a.46.46 0 0 1 .46-.459z' />
				<path d='M23.819 15.784h1.003c.187 0 .347.15.347.347v1.022c0 .188-.15.347-.347.347h-1.003a.346.346 0 0 1-.347-.347v-1.031c0-.188.16-.338.347-.338z' />
			</g>
		</g>
	</svg>
);
export default SvgUma;
