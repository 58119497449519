import * as React from 'react';
import { SVGProps } from 'react';
const SvgFida = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#7C7CFF' />
			<g fill='#FFF'>
				<path d='m16.365 5.365.032 4.971-.664.005-.032-4.971zm4.746 13.17 4.211 2.487-.337.572-4.21-2.487zm-10.2 0 .337.572-4.21 2.487-.337-.572z' />
				<path d='m21.567 13.155-5.577-3.25-5.585 3.237 5.584 3.238 5.578-3.225zm-5.579-2.482 4.256 2.48-4.255 2.46-4.26-2.47 4.26-2.47z' />
				<path d='m16.321 15.805-5.587-3.24.008 6.467 5.58 3.214v-6.441zM11.4 13.718l4.258 2.468v4.911l-4.253-2.45-.005-4.929z' />
				<path d='M21.238 19.045v-6.466l-5.58 3.225v6.44l5.58-3.199zm-.665-5.316v4.93l-4.252 2.44v-4.912l4.252-2.458z' />
				<path d='M25.485 10.5 15.992 5 6.5 10.5v11l9.492 5.5 9.493-5.5v-11zm-9.493-4.733 8.829 5.116v10.234l-8.829 5.115-8.83-5.116V10.884l8.83-5.117z' />
				<path d='M25.002 10.414a.332.332 0 0 1 .394.529l-.062.046-9.342 5.404-9.312-5.405a.332.332 0 0 1 .263-.605l.07.031 8.98 5.21 9.01-5.21z' />
				<path d='M15.994 15.677c.16 0 .295.114.325.265l.007.067v10.607a.332.332 0 0 1-.657.067l-.007-.067V16.01c0-.183.149-.332.332-.332z' />
			</g>
		</g>
	</svg>
);
export default SvgFida;
