import * as React from 'react';
import { SVGProps } from 'react';
const SvgRdn = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#2A2A2A' />
			<path
				fill='#FFF'
				d='M10 6.006h8.111c.098-.03.148.06.212.11a14.88 14.88 0 0 1 2.046 2.39 15.153 15.153 0 0 1 1.972 3.946c.482 1.484.71 3.054.65 4.614h-4.135a5 5 0 0 0 .022-.624c-.043-1.471-.497-2.914-1.212-4.192-.765-1.369-1.833-2.55-3.047-3.531-1.322-1.069-2.81-1.913-4.36-2.595-.086-.04-.176-.072-.259-.118zm3.002 7.327a4715.11 4715.11 0 0 1 4.155 7.533c.333.606.67 1.21 1 1.817C16.436 23.785 14.72 24.897 13 26V13.333z'
			/>
		</g>
	</svg>
);
export default SvgRdn;
