import * as React from 'react';
import { SVGProps } from 'react';
const SvgCnd = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#383939' />
			<path
				fill='#fff'
				d='m19.473 11.479 2.667-.611 1.033-1.701-1.02-1.673-1.306-.902L18.164 6l-1.655.933-2.648-.472-3.636 2.519-.283 2.942-1.543.644.178 2.729-1.077.858 1.076 3.072.162.322 1.138 3.163 2.339.828 1.734 1.764 1.994.698.902-.26 1.431-.165 2.098-.601 3.126-1.765-.818-3.232-1.574-.62-.784.897-2.205.542-3.207-.508-1.035-1.197.36-1.171-1.516-2.399 1.275-1.556.136-2.645 1.826-1.048 1.252-.5 1.7.307z'
			/>
		</g>
	</svg>
);
export default SvgCnd;
