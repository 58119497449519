import * as React from 'react';
import { SVGProps } from 'react';
const SvgAnkr = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#2E6BF6' fillRule='nonzero' />
			<path
				fill='#FFF'
				d='m17.314 6.3 6.62 3.35c.96.5 1.566 1.45 1.566 2.55v1.55h-2.426V12.2c0-.15-.1-.3-.252-.4l-6.62-3.35c-.1-.05-.253-.05-.404 0l-6.62 3.35c-.151.05-.252.2-.252.4v1.55H6.5V12.2c0-1.05.606-2.05 1.566-2.55l6.62-3.35c.809-.4 1.769-.4 2.628 0zm.758 9.6c0-1.15-.96-2.05-2.072-2.05-1.162 0-2.072.9-2.072 2.05s.96 2.05 2.072 2.05 2.072-.9 2.072-2.05zm4.7 4.3c.15-.05.252-.2.252-.4v-1.55h2.425v1.55c0 1.05-.606 2.05-1.566 2.55l-6.62 3.35c-.404.2-.859.3-1.314.3-.454 0-.91-.1-1.313-.3l-6.57-3.35c-.96-.5-1.566-1.45-1.566-2.55v-1.55h2.426v1.55c0 .15.1.3.252.4l5.61 2.8v-2.8c-1.921-.55-3.285-2.25-3.285-4.3 0-2.45 2.02-4.5 4.548-4.5 2.476 0 4.547 2 4.547 4.5 0 2.05-1.414 3.8-3.284 4.3V23l5.457-2.8z'
			/>
		</g>
	</svg>
);
export default SvgAnkr;
