import * as React from 'react';
import { SVGProps } from 'react';
const SvgKmd = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		fill='none'
		viewBox='0 0 96 96'
		className='svg-icon'
		{...props}>
		<path
			fill='#2B6680'
			d='M48 96c26.51 0 48-21.49 48-48S74.51 0 48 0 0 21.49 0 48s21.49 48 48 48z'
		/>
		<g fill='#fff'>
			<path d='M15 48a33 33 0 1 0 66 .127v-.253a33.175 33.175 0 0 0-.903-7.541 3.54 3.54 0 1 0-6.886 1.64 25.934 25.934 0 1 1-19.197-19.186 3.54 3.54 0 0 0 1.64-6.886A32.996 32.996 0 0 0 15 48' />
			<path d='M64.68 23.862c-.048.045-.079.098-.124.146a5.218 5.218 0 0 0-1.402 3.12v.561c0 .13-.028.255-.042.384a9.425 9.425 0 0 1-12.235 8.053 12.14 12.14 0 0 0-11.148 2.91c-.12.112-.252.207-.37.325A12.21 12.21 0 0 0 56.627 56.63c.505-.512.963-1.07 1.368-1.665a12.205 12.205 0 0 0 1.868-9.813 9.374 9.374 0 0 1-.18-.665c0-.033-.02-.064-.028-.098a9.428 9.428 0 0 1 8.397-11.515c.07 0 .138-.02.208-.022.199 0 .398-.023.597-.023h.048a5.224 5.224 0 0 0 3.224-1.517c.205-.212.392-.441.56-.684a5.26 5.26 0 0 0-8.007-6.762' />
		</g>
	</svg>
);
export default SvgKmd;
