import * as React from 'react';
import { SVGProps } from 'react';
const SvgEtn = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#23BEE2' />
			<path
				fill='#FFF'
				d='m19.879 13.469 3.239-3.412a9.225 9.225 0 0 1 2.153 5.938c0 5.117-4.15 9.266-9.271 9.266a9.232 9.232 0 0 1-4.468-1.147l8.238-4.246 1.342-.692-1.243-.859-.888-.614 2.59-1.322 1.288-.658-1.154-.872-1.826-1.382zm-7.758 5.534-3.02 3.18a9.228 9.228 0 0 1-2.372-6.188c0-5.118 4.15-9.267 9.271-9.267a9.23 9.23 0 0 1 4.906 1.404l-8.676 4.472-1.342.693 1.243.858.888.614-2.59 1.323-1.288.657 1.154.873 1.826 1.381zM8.536 22.78l-.625.658A10.95 10.95 0 0 1 5 15.995C5 9.932 9.935 5 16 5c2.493 0 4.796.834 6.643 2.237l-.902.465A10.04 10.04 0 0 0 16 5.906c-5.565 0-10.093 4.526-10.093 10.089 0 2.61.996 4.991 2.63 6.784zM23.684 9.46l.628-.66A10.944 10.944 0 0 1 27 15.995c0 6.062-4.935 10.994-11 10.994a10.941 10.941 0 0 1-6.253-1.954l.924-.477A10.036 10.036 0 0 0 16 26.083c5.565 0 10.093-4.526 10.093-10.088 0-2.49-.907-4.772-2.409-6.534zM10.842 16.9l3.941-2.013-2.137-1.476 11.83-6.098-5.953 6.268 2.635 1.993-3.941 2.013 2.137 1.476-11.83 6.098 5.953-6.268-2.635-1.993z'
			/>
		</g>
	</svg>
);
export default SvgEtn;
