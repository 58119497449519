import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionEstonia = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 33 21'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#FFF' d='M0 0h33v21H0z' />
		<path d='M0 0h33v14H0z' />
		<path fill='#0072CE' d='M0 0h33v7H0z' />
	</svg>
);
export default SvgRegionEstonia;
