import * as React from 'react';
import { SVGProps } from 'react';
const SvgStorm = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#080d98' />
			<path fill='#fff' d='m23 6-12.029 8.25 6.076 3.875L9 26l13.302-9.208-5.994-3.875z' />
		</g>
	</svg>
);
export default SvgStorm;
