import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar44 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar44_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar44_svg__cls-1{fill:#515570}.avatar44_svg__cls-2,.avatar44_svg__cls-7{fill:#393c54}.avatar44_svg__cls-2{opacity:.5}.avatar44_svg__cls-3{fill:#fbc0aa}.avatar44_svg__cls-4{fill:#f8dc25}.avatar44_svg__cls-10{fill:none;stroke-linecap:round;stroke-linejoin:round}.avatar44_svg__cls-8{fill:#8f5653}.avatar44_svg__cls-9{fill:#f2bc0f}.avatar44_svg__cls-10{stroke:#515570;stroke-width:2px;opacity:.4}.avatar44_svg__cls-11{fill:#356cb6}'
				}
			</style>
		</defs>
		<circle cx={64} cy={64} r={60} className='avatar44_svg__cls-1' />
		<circle cx={64} cy={64} r={60} className='avatar44_svg__cls-1' />
		<circle cx={64} cy={64} r={48} className='avatar44_svg__cls-2' />
		<circle cx={91.32} cy={60.43} r={7.93} className='avatar44_svg__cls-3' />
		<path
			d='M64 124a59.75 59.75 0 0 0 39.8-15.12l-2.2-5.24c-1.71-4-6.22-6.64-11.29-6.64H37.69c-5.07 0-9.58 2.66-11.29 6.64l-2.2 5.24A59.75 59.75 0 0 0 64 124Z'
			className='avatar44_svg__cls-4'
		/>
		<path
			d='M64 84.75V98.5'
			style={{
				stroke: '#fbc0aa',
				strokeWidth: 20,
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
		<circle cx={36.68} cy={60.43} r={7.93} className='avatar44_svg__cls-3' />
		<path
			d='M64 94.37a28.31 28.31 0 0 1-28.32-28.32V47.43a28.32 28.32 0 1 1 56.64 0v18.62A28.31 28.31 0 0 1 64 94.37Z'
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<circle cx={76.67} cy={59.28} r={3} className='avatar44_svg__cls-7' />
		<path
			d='m32 46 2.87 15.45a1 1 0 0 0 1.94.1C38.51 55.74 42 46 46 46c5.75 0 2.19-8 2.19-8ZM96 46l-2.87 15.45a1 1 0 0 1-1.94.1C89.49 55.74 86 46 82 46c-5.75 0-2.19-8-2.19-8Z'
			className='avatar44_svg__cls-8'
		/>
		<circle cx={49.67} cy={59.28} r={3} className='avatar44_svg__cls-7' />
		<path d='M33 45v-1.55a31 31 0 0 1 62 0V45' className='avatar44_svg__cls-9' />
		<path d='m74.39 53 9.61-.25M53 53l-9.61-.25M60 86h8' className='avatar44_svg__cls-10' />
		<path d='M39 118.54a60 60 0 0 0 50 0V113H39Z' className='avatar44_svg__cls-11' />
		<path
			d='M56.5 39h15l6.78-23.06a31 31 0 0 0-28.56 0Z'
			style={{
				opacity: 0.2,
				fill: '#393c54',
			}}
		/>
		<path
			d='M64 11.45a69.94 69.94 0 0 0-7.72.43 1.09 1.09 0 0 0-1 1L56.5 44h15l1.25-31.13a1.09 1.09 0 0 0-1-1 69.94 69.94 0 0 0-7.75-.42Z'
			className='avatar44_svg__cls-9'
		/>
		<path d='M39 118.54a60 60 0 0 0 50 0V113H39Z' className='avatar44_svg__cls-2' />
		<path
			d='M39 97h8v17a4 4 0 0 1-4 4 4 4 0 0 1-4-4V97ZM81 97h8v17a4 4 0 0 1-4 4 4 4 0 0 1-4-4V97Z'
			className='avatar44_svg__cls-11'
		/>
		<circle cx={43} cy={114} r={2} className='avatar44_svg__cls-7' />
		<circle cx={85} cy={114} r={2} className='avatar44_svg__cls-7' />
		<path
			d='m63.11 57.23-4.52 10.36a1 1 0 0 0 .89 1.35h9a1 1 0 0 0 .89-1.35l-4.48-10.36a1 1 0 0 0-1.78 0Z'
			className='avatar44_svg__cls-3'
		/>
		<path
			d='M69.5 82h-11a3.5 3.5 0 0 1 0-7h11a3.5 3.5 0 0 1 0 7Z'
			className='avatar44_svg__cls-7'
		/>
		<path
			d='M58 75h12a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2Z'
			style={{
				fill: '#fff',
			}}
		/>
		<path
			d='M60 82h9.5a3.5 3.5 0 0 0 3.5-3.5 4 4 0 0 0 0-.5h-7a6.5 6.5 0 0 0-6 4Z'
			style={{
				fill: '#f85565',
			}}
		/>
		<path
			d='M75.61 49.45H51.39A44.66 44.66 0 0 1 30 44h67a44.66 44.66 0 0 1-21.39 5.45Z'
			className='avatar44_svg__cls-9'
		/>
		<rect width={67} height={4} x={30} y={41} className='avatar44_svg__cls-4' rx={1} />
		<path
			d='M77 75a5 5 0 0 0-5-5H56a5 5 0 0 0-5 5h-6a5 5 0 0 0 8.18 3.86 15.77 15.77 0 0 1 10-3.86h1.68a15.77 15.77 0 0 1 10 3.86A5 5 0 0 0 83 75Z'
			className='avatar44_svg__cls-8'
		/>
	</svg>
);
export default SvgAvatar44;
