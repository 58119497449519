import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SymbolLogo from "./SymbolLogo";

interface LoadingSpinnerInterface {
  loading?: any;
  backdrop?: boolean;
  textStr?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerInterface> = React.memo(
  ({ loading, backdrop, textStr }) => {
    const [dots, setDots] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
      const interval = setInterval(() => {
        setDots((prevDots) => (prevDots.length >= 3 ? "" : prevDots + "."));
      }, 500);

      return () => clearInterval(interval);
    }, []);

    return (
      loading && (
        <div className="loadingSpinner" style={{ position: "relative", height: "100%", width: "100%" }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: backdrop ? "rgba(0, 0, 0, 0.7)" : "",
              zIndex: 1000,
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              minWidth: "200px",
              textAlign: "center",
              transform: "translate(-50%, -50%)",
              zIndex: 1001,
            }}
            className="text-center"
          >
            <SymbolLogo className="loadSpin" style={{ fontSize: "64px" }} />
            {textStr && (
              <div className="loadingText">
                <span className="fs-4">{t(textStr)}</span>{" "}
                <span className="dot-animation">{dots}</span>
              </div>
            )}
          </div>
        </div>
      )
    );
  }
);

LoadingSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
  backdrop: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  backdrop: false,
};

export default LoadingSpinner;
