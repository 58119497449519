import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar46 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar46_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar46_svg__cls-13{fill:#393c54}.avatar46_svg__cls-4{fill:#f85565}.avatar46_svg__cls-11,.avatar46_svg__cls-12,.avatar46_svg__cls-5{fill:none;stroke:#515570;stroke-linecap:round;stroke-linejoin:round}.avatar46_svg__cls-12,.avatar46_svg__cls-5{stroke-width:2px}.avatar46_svg__cls-10,.avatar46_svg__cls-5{opacity:.2}.avatar46_svg__cls-6{fill:#fff}.avatar46_svg__cls-7{fill:#ff8475;opacity:.4}.avatar46_svg__cls-8{fill:#356cb6;opacity:.1}.avatar46_svg__cls-10{fill:#00adfe}.avatar46_svg__cls-11{stroke-width:2.33px}.avatar46_svg__cls-13{opacity:.25}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#515570',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.5,
				fill: '#393c54',
			}}
		/>
		<path
			d='M35 68.36V52.42c0-14.69 10.41-27.62 24.9-30A30 30 0 0 1 95 52v16.78a30 30 0 0 1-35.1 29.58C45.41 96 35 83.05 35 68.36Z'
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<path
			d='M79.62 86H48.38a38.67 38.67 0 0 0-31.08 15.65 60 60 0 0 0 93.4 0A38.67 38.67 0 0 0 79.62 86Z'
			className='avatar46_svg__cls-4'
		/>
		<path d='M56.69 41.56s-6.43.76-10.82 4.56' className='avatar46_svg__cls-5' />
		<path
			d='M93.14 41.91C86.49 32.52 77.69 24.8 65.35 24.8s-22.21 6.59-28.24 16.26c-2 3.18 1.66 2.31 5.15.24S61 37 61 37s-4 2-5.63 4.71c4.26.46 11.4-3.95 11.4-3.95a11.55 11.55 0 0 0-1.55 3.95c4-1.22 6.87-2.58 8.54-4.41s22.47 8.98 19.38 4.61Z'
			className='avatar46_svg__cls-6'
		/>
		<path
			d='M92.7 40.9s-.47-19.73-19.16-25-34.42 4.86-42.07 12.54C24 36 20.69 42.72 19.32 43.33 26 39.16 34.92 39 37.25 41.2c0 0 10-13.67 28.1-13.67A34.59 34.59 0 0 1 92.7 40.9Z'
			className='avatar46_svg__cls-4'
		/>
		<circle cx={78.64} cy={63.09} r={7.02} className='avatar46_svg__cls-7' />
		<circle cx={52.67} cy={63.09} r={7.02} className='avatar46_svg__cls-7' />
		<path
			d='M65 102.59a42.5 42.5 0 0 0 32.31-23.7c10.82-22-3.73-36-3.73-36a109 109 0 0 1 1.09 12.29c.29 7.46-2.48 11.56-5.41 13.9s-7.46 2.34-9.87-2.2S71.3 65.43 65 65.43s-12-3.07-14.39 1.46-6.94 4.54-9.87 2.2-5.7-6.44-5.41-13.9.88-11.35.88-11.35-14.93 13.1-4.1 35.05A43 43 0 0 0 65 102.59Z'
			className='avatar46_svg__cls-6'
		/>
		<path
			d='M33.34 50.19c-.18 4.77.89 8.16 2.44 10.6a22.44 22.44 0 0 1-.44-5.6c.29-7.46.88-11.35.88-11.35A20.68 20.68 0 0 0 33.5 47c-.06 1-.11 2-.16 3.19ZM98.06 49.33c1.85 6.15 2.21 14.52-2.74 24.56l-.33.65c-13.47 26.28-51 26.29-64.54 0l-.33-.66c-.11-.22-.2-.44-.3-.65a40.72 40.72 0 0 0 2.3 5.65A43 43 0 0 0 65 102.59a42.5 42.5 0 0 0 32.31-23.7c6.47-13.11 3.88-23.4.75-29.56Z'
			className='avatar46_svg__cls-8'
		/>
		<ellipse
			cx={65.42}
			cy={63.09}
			rx={6.78}
			ry={4.49}
			style={{
				fill: '#ff8475',
			}}
		/>
		<circle cx={77.91} cy={54.46} r={7.03} className='avatar46_svg__cls-10' />
		<circle cx={52.75} cy={54.46} r={7.03} className='avatar46_svg__cls-10' />
		<circle cx={77.91} cy={54.46} r={7.03} className='avatar46_svg__cls-11' />
		<circle cx={52.75} cy={54.46} r={7.03} className='avatar46_svg__cls-11' />
		<path
			d='M55.45 54.6a2.71 2.71 0 1 1-5.41 0M80.61 54.6a2.71 2.71 0 1 1-5.41 0'
			className='avatar46_svg__cls-12'
		/>
		<path
			d='M70.89 53.7c-.78 1.34-3 2.3-5.55 2.3s-4.72-.94-5.52-2.26M84.09 51.11l7.23-3.68M46.26 51.11l-7.23-3.68'
			className='avatar46_svg__cls-11'
		/>
		<path
			d='M37.25 41.2c-.71-5.26 2.32-10.52 2.32-10.52S29.29 32.86 24.83 41.2c5.83-1.5 11.59-1.88 12.42 0Z'
			className='avatar46_svg__cls-13'
		/>
		<path d='M74.54 42.73s6.44-.41 10.83 3.39' className='avatar46_svg__cls-5' />
		<circle cx={21.23} cy={42.42} r={6.68} className='avatar46_svg__cls-6' />
		<path
			d='M72.55 71a1 1 0 0 1 1 1.06 8.55 8.55 0 0 1-17 0 1 1 0 0 1 1-1.06Z'
			style={{
				fill: '#393c54',
			}}
		/>
		<path
			d='M65 79.66a8.51 8.51 0 0 0 6.29-2.79 9.06 9.06 0 0 0-12.58 0A8.51 8.51 0 0 0 65 79.66Z'
			className='avatar46_svg__cls-4'
		/>
		<circle cx={64} cy={114} r={4} className='avatar46_svg__cls-6' />
		<path
			d='M86.88 119.46a59.79 59.79 0 0 0 6.82-3.34l-4-11.12ZM41.12 119.46 38.3 105l-4 11.12a59.79 59.79 0 0 0 6.82 3.34Z'
			className='avatar46_svg__cls-13'
		/>
	</svg>
);
export default SvgAvatar46;
