import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionColombia = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 6 4'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#CE1126' d='M0 0h6v4H0z' />
		<path fill='#003893' d='M0 0h6v3H0z' />
		<path fill='#FCD116' d='M0 0h6v2H0z' />
	</svg>
);
export default SvgRegionColombia;
