import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar8 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar8_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar8_svg__cls-8{fill:#393c54}.avatar8_svg__cls-3{fill:#fff}.avatar8_svg__cls-4{fill:#00adfe}.avatar8_svg__cls-5,.avatar8_svg__cls-7{fill:#356cb6}.avatar8_svg__cls-6{fill:none;stroke:#393c54;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}.avatar8_svg__cls-7{opacity:.1}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#515570',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.5,
				fill: '#393c54',
			}}
		/>
		<path
			d='M13 101.61a65.28 65.28 0 0 0 102 0 17.87 17.87 0 0 0-14.19-8.9v-.61a15.34 15.34 0 0 0-1.89-7.39 18.84 18.84 0 0 0-4.75-35.18 13 13 0 0 0-17.65-16.75 13 13 0 0 0-25 0 13 13 0 0 0-17.71 16.75 18.84 18.84 0 0 0-4.75 35.18 15.34 15.34 0 0 0-1.89 7.39v.61a17.87 17.87 0 0 0-14.17 8.9Z'
			className='avatar8_svg__cls-3'
		/>
		<path
			d='M100.81 92.71v-.61a15.34 15.34 0 0 0-1.89-7.39 18.84 18.84 0 0 0-4.75-35.18 13 13 0 0 0-17.65-16.75 13 13 0 0 0-25 0 13 13 0 0 0-17.71 16.75 18.84 18.84 0 0 0-4.75 35.18 15.34 15.34 0 0 0-1.89 7.39v.61a17.49 17.49 0 0 0-12.09 6 60 60 0 0 0 97.8 0 17.49 17.49 0 0 0-12.07-6Z'
			className='avatar8_svg__cls-3'
		/>
		<path
			d='M25 60.92a3.26 3.26 0 0 0 3.25 3.25h3a23.91 23.91 0 0 0 16.39-6.51H28.27A3.26 3.26 0 0 0 25 60.92Z'
			className='avatar8_svg__cls-4'
		/>
		<path
			d='M31.32 64.17a23.43 23.43 0 0 0 13.56-4.34H31a3.81 3.81 0 0 0-3.24 1.71 2.75 2.75 0 0 0-.34 2.46 3.2 3.2 0 0 0 1 .17Z'
			className='avatar8_svg__cls-5'
		/>
		<path
			d='M103 60.92a3.26 3.26 0 0 1-3.25 3.25h-3a23.91 23.91 0 0 1-16.39-6.51h19.37a3.26 3.26 0 0 1 3.27 3.26Z'
			className='avatar8_svg__cls-4'
		/>
		<path
			d='M96.68 64.17a23.43 23.43 0 0 1-13.56-4.34h13.93a3.81 3.81 0 0 1 3.24 1.71 2.75 2.75 0 0 1 .29 2.46 3.2 3.2 0 0 1-1 .17Z'
			className='avatar8_svg__cls-5'
		/>
		<path
			d='M84.55 67.21c0 11.35-9.2 33.45-20.55 33.45s-20.55-22.1-20.55-33.45a20.55 20.55 0 0 1 41.1 0Z'
			className='avatar8_svg__cls-4'
		/>
		<path
			d='M77.15 44.22a7.74 7.74 0 0 0-5.08 1.9 9.37 9.37 0 0 0-16.14 0A7.77 7.77 0 1 0 56.82 57a9.39 9.39 0 0 0 14.36 0 7.77 7.77 0 1 0 6-12.74Z'
			className='avatar8_svg__cls-3'
		/>
		<path d='M64 89.49v5.79' className='avatar8_svg__cls-6' />
		<path
			d='M71.82 48.64a12.3 12.3 0 0 1-15.48 1.29 12.29 12.29 0 0 1-11 7.51A7.76 7.76 0 0 0 56.82 57a9.39 9.39 0 0 0 14.36 0 7.75 7.75 0 0 0 10.45 1.37 12.29 12.29 0 0 1-9.81-9.73ZM54 102.08a12.27 12.27 0 0 1-15-3.86 12.31 12.31 0 0 1-12.88 3.48 7.76 7.76 0 0 0 11 3.32 9.38 9.38 0 0 0 13.56 4.71 7.75 7.75 0 0 0 9.43 4.73A12.32 12.32 0 0 1 54 102.08Z'
			className='avatar8_svg__cls-7'
		/>
		<path
			d='M69.55 84.38c0 3.07-2.48 6.78-5.55 6.78s-5.55-3.71-5.55-6.78c0-1.8 11.1-2.03 11.1 0Z'
			className='avatar8_svg__cls-8'
		/>
		<path
			d='M60.74 94.56h6.51v8.68A3.26 3.26 0 0 1 64 106.5a3.26 3.26 0 0 1-3.26-3.26v-8.68Z'
			style={{
				fill: '#f85565',
			}}
		/>
		<path d='M58.45 93.11q5.55 4.71 11.1 0' className='avatar8_svg__cls-6' />
		<circle cx={74.85} cy={68.51} r={5.61} className='avatar8_svg__cls-3' />
		<circle cx={74.85} cy={68.51} r={3.15} className='avatar8_svg__cls-8' />
		<path d='M80.46 68.51a5.61 5.61 0 1 1-11.21 0' className='avatar8_svg__cls-5' />
		<circle cx={53.15} cy={68.51} r={5.61} className='avatar8_svg__cls-3' />
		<circle cx={53.15} cy={68.51} r={3.15} className='avatar8_svg__cls-8' />
		<path
			d='M29 84.65A15.75 15.75 0 0 0 42.2 74a15.73 15.73 0 0 1-22.33.1A18.88 18.88 0 0 0 29 84.65ZM99.17 84.65A15.76 15.76 0 0 1 85.93 74a15.73 15.73 0 0 0 22.33.1 18.84 18.84 0 0 1-9.09 10.55ZM91.08 104.63a12.29 12.29 0 0 1-6.7-2c0 .4.06.81.06 1.22a12.4 12.4 0 0 1-11 12.31 10.09 10.09 0 0 0 14.81-5.06 10.1 10.1 0 0 0 13.59-9.51 10 10 0 0 0-.36-2.65 12.38 12.38 0 0 1-10.4 5.69Z'
			className='avatar8_svg__cls-7'
		/>
	</svg>
);
export default SvgAvatar8;
