import * as React from 'react';
import { SVGProps } from 'react';
const SvgCdt = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#272731' />
			<path
				fill='#fff'
				d='M27 16.03c0 2.91-1.145 5.7-3.186 7.758A10.832 10.832 0 0 1 16.12 27v-2.802c3.948.012 7.325-2.857 7.984-6.782h-3.89c.154-.456.233-.934.234-1.416a4.78 4.78 0 0 0-.234-1.416h3.89c-.668-3.92-4.04-6.784-7.984-6.782V5c6.008.058 10.855 4.972 10.88 11.03zm-7.254 2.359h3.072c-1.136 3.305-4.472 5.3-7.893 4.72C11.505 22.527 9 19.541 9 16.043s2.505-6.483 5.925-7.064c3.421-.58 6.757 1.415 7.893 4.72h-3.072a4.72 4.72 0 0 0-1.316-1.327 4.299 4.299 0 0 0-5.965 1.327c-1.284 2.027-.695 4.72 1.316 6.015a4.299 4.299 0 0 0 5.965-1.326z'
			/>
		</g>
	</svg>
);
export default SvgCdt;
