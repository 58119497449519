import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionBosniaandHerzegovina = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		viewBox='0 0 16 8'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<defs>
			<clipPath id='regionBosniaandHerzegovina_svg__a'>
				<path d='M0 0h16v8H0z' />
			</clipPath>
		</defs>
		<g clipPath='url(#regionBosniaandHerzegovina_svg__a)'>
			<path fill='#002395' d='M0 0h16v8H0z' />
			<path fill='#fecb00' d='M4.24 0h8v8z' />
			<g id='regionBosniaandHerzegovina_svg__c'>
				<path
					id='regionBosniaandHerzegovina_svg__b'
					fill='#fff'
					d='M2.353.525 2.8-.85 3.247.525l-1.17-.85h1.446z'
				/>
				<use xlinkHref='#regionBosniaandHerzegovina_svg__b' x={1} y={1} />
				<use xlinkHref='#regionBosniaandHerzegovina_svg__b' x={2} y={2} />
			</g>
			<use xlinkHref='#regionBosniaandHerzegovina_svg__c' x={3} y={3} />
			<use xlinkHref='#regionBosniaandHerzegovina_svg__c' x={6} y={6} />
		</g>
	</svg>
);
export default SvgRegionBosniaandHerzegovina;
