import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomSecond = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		fill='none'
		viewBox='0 0 20 21'
		className='svg-icon'
		{...props}>
		<path
			fill='#55626F'
			fillRule='evenodd'
			d='m17 6.333-7-4.166-7 4.166v8.334l7 4.166 7-4.166V6.333Z'
			clipRule='evenodd'
		/>
		<path
			fill='#DEE1EF'
			fillRule='evenodd'
			d='M10 2.167 3 6.333v8.334l7 4.166 7-4.166V6.333l-7-4.166Zm6.583 4.403L10 2.652 3.417 6.57v7.86L10 18.348l6.583-3.918V6.57Z'
			clipRule='evenodd'
		/>
		<path
			fill='#DEE1EF'
			d='M7.417 14.28c0 .116.093.22.221.22h4.665a.229.229 0 0 0 .222-.22v-1.044a.229.229 0 0 0-.222-.22h-2.052c.548-.765 1.504-2.006 1.878-2.667.28-.545.454-.892.454-1.507 0-1.287-.98-2.342-2.647-2.342-1.388 0-2.333 1.136-2.333 1.136-.081.093-.07.232.012.302l.711.719a.226.226 0 0 0 .327 0c.222-.244.653-.58 1.131-.58.653 0 1.027.394 1.027.835 0 .324-.152.649-.304.88-.688 1.044-2.414 3.375-3.09 4.256v.232Z'
		/>
		<path fill='#B7BDCA' d='m20 7.667-2.083.583v2.333A2.852 2.852 0 0 0 20 7.837v-.17Z' />
		<path fill='#9099A6' d='m20 10.333-2.083.584v2.333A2.852 2.852 0 0 0 20 10.503v-.17Z' />
		<path fill='#B7BDCA' d='m0 7.667 2.083.583v2.333A2.852 2.852 0 0 1 0 7.837v-.17Z' />
		<path fill='#9099A6' d='m0 10.333 2.083.584v2.333A2.852 2.852 0 0 1 0 10.503v-.17Z' />
	</svg>
);
export default SvgCustomSecond;
