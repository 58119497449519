import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionIsrael = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 1100 800'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#fff' d='M0 0h1100v800H0z' />
		<path fill='#0038b8' d='M0 75h1100v125H0zm0 525h1100v125H0z' />
		<path
			fill='none'
			stroke='#0038b8'
			strokeWidth={27.5}
			d='M423.816 472.853h252.368L550 254.295zM550 545.705l126.184-218.558H423.816z'
		/>
	</svg>
);
export default SvgRegionIsrael;
