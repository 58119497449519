import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionSaintLucia = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 600 300'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#6Cf' d='M0 0h600v300H0z' />
		<path fill='#FFF' d='m200 274 100-14 100 14L300 27z' />
		<path d='m213.5 274 86.5-14 86.5 14L300 60z' />
		<path fill='#FCD116' d='M200 274h200L300 150z' />
	</svg>
);
export default SvgRegionSaintLucia;
