import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import SocketContext from '../../context/SocketContext';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from '../../component/LoadingSpinner';
import useCombine from '../../hooks/useCombine';
import CopyButton from '../../component/CopyButton';
import classNames from 'classnames';
import ThemeContext from '../../context/ThemeContext';
import { useLocation } from 'react-router-dom';
import FilterComponent from '../../component/Filter';

interface BetHistoryItem {
	_id: string;
	betId: number;
	currencyId: any;
	couponType: string;
	createdAt: string;
	betAmount: number;
	betRatio: number;
	betData: any;
	betAmountUSD: number;
	maxWin: number;
	gameName: string;
	marketName: string;
	status: string;
}

const SportHistoryLoading: React.FC<{ count?: number }> = ({ count = 10 }) => {
	return (
		<>
			{Array.from({ length: count }).map((_, index) => (
				<div className='betHistoryList-table' key={index}>
					<div className='betHistoryList-tbody'>
						<ul className='betHistoryListEl'>
							{Array.from({ length: 6 }).map((_, itemIndex) => (
								<div className='betHistoryListEl-list-item' key={itemIndex}>
									<span className='skeleton-loader-full-text'></span>
								</div>
							))}
							<div className='betHistoryListEl-list-item'>
								<i className='competition-icon-arrow-bc bc-i-small-arrow-down'></i>
							</div>
						</ul>
					</div>
				</div>
			))}
		</>
	);
};

const SportHistoryShow: React.FC<{ sportData: any }> = ({ sportData }) => {
	const { t } = useTranslation();

	return (
		<div className='dataListEl-open'>
			{sportData.map((data: any, index: number) => (
				<div className='bet-history-t-holder-hk-bc' key={index}>
					<div className='bet-history-teams-match'>
						<div className='bet-history-teams-block'>
							<time className='bet-history-day'>
								{dayjs(data.pendingMarketRef.gameStart).format('DD.MM.YYYY, HH:mm')}
							</time>
							<div className='bet-history-league ellipsis'>{data.compName}</div>
							<div className='bet-history-league-block'>
								<i className={'bet-history-sport-icon bc-i-' + data.sportAlias}></i>
								<div className='bet-history-teams'>
									<div className='bet-history-team-name-wrapper'>
										<p className='bet-history-team-name ellipsis'>
											{data.gameTeam1}
										</p>
										{data.gameTeam2 && <span>-</span>}
										<p className='bet-history-team-name ellipsis'>
											{data.gameTeam2}
										</p>
									</div>
								</div>
							</div>
							<SportHistoryResult sportData={data.pendingMarketRef} />
						</div>
						<div className='bet-history-match-result'>
							<p className='bet-history-title'>{data.marketName}</p>
							<div className='bet-history-stake-odds'>
								<span className='bet-history-title'>
									{t('Pick')}:
									<div className='bet-history-match-result-teams'>
										<span className="ellipsis"> {data.eventName} </span>
										
										 {data.eventBase}
									</div>
								</span>
							</div>
						</div>
					</div>
					<b className='bet-history-match-odds-coeff'>{data.event.price.toFixed(2)}</b>
					<div className='bet-history-match-info-all'>
						<div className='bet-history-match-info-all'>
							{data.pendingMarketRef.gameFinished && (
								<div className='bet-history-match-info-text'>
									<i className='bc-i-info bc-i-match-info'></i>
									<span>{t('gameFinishedAlready')}</span>
								</div>
							)}
							<div className='bet-history-match-info'>
								{t('Result')}:{' '}
								<span className='bet-history-match-info-score'>
									{data.pendingMarketRef.marketScore}
								</span>
							</div>
						</div>
					</div>
				</div>
			))}
			<div className='bet-history-t-holder-hk-bc' />
		</div>
	);
};

const SportHistoryResult = ({ sportData, onlyIcon, onlyText }: any) => {
	const { t } = useTranslation();

	let status: any;
	let icon: any;
	if (sportData.marketStatus === 'WON') {
		status = 1;
		icon = 'bc-i-won';
	}
	if (sportData.marketStatus === 'UNSETTLED') {
		status = 2;
		icon = 'bc-i-unsettled';
	}
	if (sportData.marketStatus === 'LOST') {
		status = 0;
		icon = 'bc-i-lost';
	}
	if (sportData.marketStatus === 'CANCELLED') {
		status = 4;
		icon = 'bc-i-won';
	}

	return (
		<p className='bet-history-status' data-status={status}>
			{!onlyText && <i className={classNames(icon, 'bc-status-change-icon')}></i>}
			{!onlyIcon && <span>{t(sportData.marketStatus)}</span>}
		</p>
	);
};

const SportCouponResult = ({ historyData, onlyIcon, onlyText }: any) => {
	const { t } = useTranslation();

	let status: any;
	let icon: any;
	let text: any;
	if (historyData.betWin === true) {
		status = 1;
		icon = 'bc-i-won';
		text = t('WON');
	}
	if (historyData.betSet === false) {
		status = 2;
		icon = 'bc-i-unsettled';
		text = t('UNSETTLED');
	}
	if (historyData.betSet === true && historyData.betWin === false) {
		status = 0;
		icon = 'bc-i-lost';
		text = t('LOST');
	}

	if (onlyIcon) {
		return <i className={icon} data-status={status}></i>;
	}

	if (onlyText) {
		return (
			<span className='ellipsis' data-status={status}>
				{text}
			</span>
		);
	}

	return (
		<p className='bet-history-status' data-status={status}>
			<i className={classNames(icon, 'bc-status-change-icon')}></i>
			<span>{text}</span>
		</p>
	);
};

const SportHistory: React.FC<any> = ({ showFilter = true, onlyMobile = false }: any) => {
	const [betHistory, setBetHistory] = useState<BetHistoryItem[]>([]);
	const [loading, setLoading] = useState(true);
	const { t, i18n } = useTranslation();
	const { sendRequest } = useContext(SocketContext);
	const { mobileDesign } = useContext(ThemeContext);
	const { parseCurrencyAmount } = useCombine();
	const [perPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalDocuments, setTotalDocuments] = useState(0);
	const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
	const location = useLocation();
	const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
	const account = searchParams.get('account');
	const pageType = searchParams.get('page');

	const todayDate = dayjs().format('YYYY-MM-DD');
	const tomorrowDate = dayjs().add(1, 'day').format('YYYY-MM-DD');

	const [filters, setFilters] = useState({
		betId: '',
		sportAlias: '',
		pageType: pageType,
		bet_type: 'all',
		period: '7d',
		// startDate: todayDate,
		// endDate: tomorrowDate,
	});

	useEffect(() => {
		if (pageType) {
			setFilters((prev) => ({ ...prev, pageType }));
		}
	}, [account, pageType]);

	const loadBetHistory = useCallback(
		(page: number, filters: any) => {
			if (page === 1) setLoading(true);
			const cloneFilters = _.cloneDeep(filters);
			if (cloneFilters.startDate) {
				cloneFilters.startDate = dayjs(cloneFilters.startDate).unix();
				cloneFilters.endDate = dayjs(cloneFilters.endDate).unix();
			}
			sendRequest(
				{ A: 'getSportHistory', D: { filter: cloneFilters, page, perPage } },
				(response: any) => {
					const { mySportHistory, totalDocuments } = response.R;
					setTotalDocuments(totalDocuments);
					setBetHistory((prev) =>
						page === 1 ? mySportHistory : [...prev, ...mySportHistory],
					);
					setLoading(false);
				},
			);
		},
		[sendRequest, perPage],
	);

	useEffect(() => {
		loadBetHistory(1, filters);
	}, [filters, loadBetHistory]);

	const handleFilterChange = (name: string, value: any) => {
		setFilters((prev) => ({ ...prev, [name]: value }));
		setCurrentPage(1);
		setBetHistory([]);
	};

	const handleSubmit = (values: any) => {
		setFilters(values);
		setCurrentPage(1);
		setBetHistory([]);
		loadBetHistory(1, values);
	};

	const fetchMoreHistory = () => {
		const nextPage = currentPage + 1;
		setCurrentPage(nextPage);
		loadBetHistory(nextPage, filters);
	};

	const toggleDetails = (id: string) => {
		setExpandedRows((prev) => {
			const newSet = new Set(prev);
			if (newSet.has(id)) newSet.delete(id);
			else newSet.add(id);
			return newSet;
		});
	};

	const betTypeOptions = [
		{ value: 'all', label: t('ALL') },
		{ value: 'single', label: t('SINGLE') },
		{ value: 'multiple', label: t('MULTIPLE') },
		{ value: 'system', label: t('SYSTEM') },
	];

	const periodOptions = [
		{ value: '24h', label: t('24HOURS') },
		{ value: '7d', label: t('7DAYS') },
		{ value: '1m', label: t('1MONTH') },
	];

	const winOptions = [
		{ value: 'bets', label: t('All') },
		{ value: 'open-bets', label: t('PENDING') },
		{ value: 'won', label: t('WON') },
		{ value: 'lose', label: t('LOST') },
		
		// { value: 'refunded', label: t('REFUNDED') },
	];

	const defaultFilter: any = [
		{
			name: 'pageType',
			label: t('winStatus'),
			type: 'select',
			options: winOptions,
			value: filters.pageType,
		},
		{
			name: 'bet_type',
			label: t('BETTYPE'),
			type: 'select',
			options: betTypeOptions,
			value: filters.bet_type,
		},
		{
			name: 'period',
			label: t('PERIOD'),
			type: 'select',
			options: periodOptions,
			value: filters.period,
		},
		// {
		// 	name: 'startDate',
		// 	label: t('startDate'),
		// 	type: 'date',
		// 	placeholder: '...from',
		// 	value: filters.startDate,
		// },
		// {
		// 	name: 'endDate',
		// 	label: t('endDate'),
		// 	type: 'date',
		// 	placeholder: '...to',
		// 	value: filters.endDate,
		// },
	];

	if (mobileDesign || onlyMobile) {
		return (
			<>
				<div className='componentFilterWrapper-bc'>
					<FilterComponent
						items={defaultFilter}
						onSubmit={handleSubmit}
						onChange={handleFilterChange}
					/>
				</div>
				<div className='profits-items-container profits-items-sport'>
					{loading ? (
						<LoadingSpinner loading={loading} />
					) : (
						betHistory.map((item, index) => (
							<div key={index} className='bet-history-t-holder-hk-bc'>
								<div className='bet-history-t-b-row-hk-bc'>
									<div className='bet-history-t-b-r-cell-hk-bc'>
										<i
											className={`bet-history-express-icon bc-i-${item.couponType.toLocaleLowerCase()}-bet`}></i>
										<div className='bet-history-info-block'>
											<h3 className='bet-history-info-title-block'>
												<span className='ellipsis'>
													{t(item.couponType)}
												</span>
												<div className='bet-history-bonus-bet-icons'></div>
											</h3>
											<p className='bet-history-id'>
												<span>ID:</span>
												{item.betId}
												<CopyButton copyText={item.betId} />
											</p>
										</div>
									</div>
									<div className='bet-history-t-b-r-cell-hk-bc'>
										<div className='bet-history-info'>
											<p className='bet-history-status'>
												<SportCouponResult historyData={item} onlyText />
											</p>
											<time className='bet-history-day'>
												{dayjs(item.createdAt).format('DD.MM.YYYY, HH:mm')}
											</time>
										</div>
									</div>
								</div>
								<div>
									<div className='bet-history-stake-odds'>
										<span className='bet-history-title'>{t('betAmount')}</span>
										<b className='bet-history-stake-money'>
											{
												parseCurrencyAmount(
													item.currencyId,
													item.betAmount,
													i18n.language,
												).str
											}
										</b>
									</div>
									<div className='bet-history-stake-odds'>
										<span className='bet-history-title'>
											{t('Odds')} ({t('Decimal')})
										</span>
										<b className='bet-history-odds-coeff'>
											{item.betRatio.toFixed(2)}
										</b>
									</div>
								</div>
								<div className='bet-history-cashout-block'>
									<div className='bet-history-possible-win-block'>
										<span className='bet-history-title'>
											{t('possibleWin')}
										</span>
										<span className='bet-history-possible-win'>
											{
												parseCurrencyAmount(
													item.currencyId,
													item.maxWin,
													i18n.language,
												).str
											}
										</span>
									</div>
									<div className='bet-history-cashout'></div>
								</div>
								<div
									className='competition-wrapper-bc'
									data-scroll-lock-scrollable=''
									onClick={() => toggleDetails(item._id)}>
									<div className='competition-header-bc'>
										<span className='competition-title-bc ellipsis'>
											{t('betDetails')}
										</span>
										<span className='competition-group-count'>
											{item.betData.length}
										</span>
										<i
											className={`competition-icon-arrow-bc bc-i-small-arrow-${
												expandedRows.has(item._id) ? 'up' : 'down'
											}`}></i>
									</div>
								</div>
								{expandedRows.has(item._id) && (
									<SportHistoryShow sportData={item.betData} />
								)}
								<div className='betHistoryListEl-list-item-actions'></div>
							</div>
						))
					)}
				</div>
			</>
		);
	}

	return (
		<>
			{!mobileDesign && <div className='overlay-header'>{t('Coupon History')}</div>}
			<div className='u-i-e-p-p-content-bc u-i-common-content'>
				<div className='componentFilterWrapper-bc'>
					<FilterComponent
						items={defaultFilter}
						onSubmit={handleSubmit}
						onChange={handleFilterChange}
					/>
				</div>

				<div className='betHistoryList-table'>
					<div className='betHistoryList-thead text-center'>
						<div className='betHistoryListEl'>
							{[
								'DATEANDID',
								'BETTYPE',
								'STAKE',
								'ODDS',
								'OUTCOME',
								'ACTIONS',
								'',
							].map((label, index) => (
								<div className='betHistoryListEl-list-item' key={index}>
									<p className='ellipsis'>{t(label)}</p>
								</div>
							))}
						</div>
					</div>
				</div>

				<div
					className=''
					id='scrollableDiv'
					style={{
						overflowY: 'scroll',
					}}>
					{loading ? (
						<LoadingSpinner loading={loading} />
					) : (
						<>
							{betHistory.length === 0 ? (
								<span className='empty-b-text-v-bc'> {t('noBetHistory')} </span>
							) : (
								<InfiniteScroll
									dataLength={betHistory.length}
									next={fetchMoreHistory}
									className='betHistoryList-tbody'
									hasMore={totalDocuments > betHistory.length}
									loader={<SportHistoryLoading />}
									scrollableTarget='scrollableDiv'>
									{betHistory.map((bet) => (
										<>
											<div className='betHistoryListEl' key={bet._id}>
												<div className='betHistoryListEl-list-item'>
													<div className='betHistoryListElId'>
														<SportCouponResult
															historyData={bet}
															onlyIcon
														/>
														<div className='betHistoryListEl-info'>
															<p className='betHistory-day'>
																<time>
																	{dayjs(bet.createdAt).format(
																		'YYYY-MM-DD HH:mm',
																	)}
																</time>
															</p>
															<p className='betHistory-Id'>
																<span>ID:</span>
																{bet.betId}
																<CopyButton copyText={bet.betId} />
															</p>
															<SportCouponResult
																historyData={bet}
																onlyText
															/>
														</div>
													</div>
												</div>
												<div className='betHistoryListEl-list-item'>
													<div className='betHistoryListEl-types'>
														<i
															className={`bet-history-express-icon bc-i-${bet.couponType.toLowerCase()}-bet`}></i>
														&nbsp; {t(bet.couponType)}
													</div>
												</div>
												<div className='betHistoryListEl-list-item'>
													<div className='bet-history-stake-odds'>
														<b className='bet-history-stake-money'>
															{
																parseCurrencyAmount(
																	bet.currencyId,
																	bet.betAmount,
																	i18n.language,
																).str
															}
														</b>
													</div>
												</div>
												<div className='betHistoryListEl-list-item'>
													<b className='bet-history-odds-coeff'>
														{bet.betRatio.toFixed(2)}
													</b>
												</div>
												<div className='betHistoryListEl-list-item'>
													<div className='bet-history-cashout-block'>
														<div className='bet-history-possible-win-block'>
															<span className='bet-history-title'></span>
															<span className='bet-history-possible-win'>
																{
																	parseCurrencyAmount(
																		bet.currencyId,
																		bet.maxWin,
																		i18n.language,
																	).str
																}
															</span>
														</div>
													</div>
												</div>
												<div className='betHistoryListEl-list-item'>
													<div className='betHistoryListEl-types'>
														<div className='betHistoryListEl-list-item-actions'>
															<div className='bet-history-t-b-row-hk-bc bet-history-actions'>
																<i
																	className='bet-history-function-icon bc-i-repeat'
																	title='Repeat bet'></i>
															</div>
														</div>
													</div>
												</div>
												<div
													className='betHistoryListEl-list-item'
													onClick={() => toggleDetails(bet._id)}>
													<i
														className={`competition-icon-arrow-bc bc-i-small-arrow-${
															expandedRows.has(bet._id)
																? 'up'
																: 'down'
														}`}></i>
												</div>
											</div>
											{expandedRows.has(bet._id) && (
												<SportHistoryShow sportData={bet.betData} />
											)}
										</>
									))}
								</InfiniteScroll>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default SportHistory;
