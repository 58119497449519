import * as React from 'react';
import { SVGProps } from 'react';
const SvgSportIconTableTennis = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 512 512'
		className='svg-icon'
		{...props}>
		<path
			d='M314.479 47.943c-11.14-7.888-22.281-13.987-32.364-18.215-51.229-21.63-158.486-31.063-230.126 56.19-5.367 6.505-10.327 13.01-14.881 19.516a285.166 285.166 0 0 0-8.457 12.604c-6.831 10.733-12.604 21.223-17.158 31.632-2.439 5.286-4.635 10.572-6.424 15.857 2.359-10.002 5.855-20.329 10.327-30.819 4.229-10.409 9.595-20.898 15.938-31.632 2.439-4.229 5.123-8.375 7.888-12.685 4.229-6.424 8.782-12.93 13.824-19.516 66.517-87.253 166.211-77.739 213.862-56.108 9.677 4.391 20.573 10.815 31.388 19.191 5.449 4.145 10.897 8.78 16.183 13.985z'
			style={{
				opacity: 0.1,
				fill: '#fff',
			}}
		/>
		<path
			d='M365.695 212.696c-1.708 8.376-3.659 16.508-5.855 24.151a243.215 243.215 0 0 1-3.903 12.929c-9.107 27.648-21.224 50.498-35.454 69.038v.081c-1.139 1.382-2.196 2.765-3.253 4.066-10.571 12.93-22.281 23.419-34.803 31.795 0 0 0 .081-.081.081-2.602 1.789-5.204 3.497-7.888 4.961h-.081a143.897 143.897 0 0 1-43.992 16.507c-2.602.488-5.204.894-7.888 1.22l.244-.488-30.087-13.661-30.168-13.661-139.54-63.427c-.976-1.464-1.87-3.009-2.846-4.472-16.67-27.973-23.826-61.638-18.215-98.962.569-3.415 1.138-6.831 1.87-10.246 2.521-12.197 6.505-24.964 12.035-37.893 4.31-10.409 9.595-20.898 15.938-31.632 2.521-4.229 5.123-8.376 7.887-12.685 4.229-6.424 8.864-12.929 13.824-19.516C119.956-16.37 219.65-6.857 267.301 14.774c1.708.813 3.497 1.626 5.286 2.521 8.294 4.228 17.158 9.758 26.103 16.588 12.848 9.92 25.777 22.525 36.999 37.731 5.367 7.237 10.327 14.962 14.555 23.338 9.352 17.646 16.1 37.812 18.54 60.418 1.871 17.644 1.058 36.834-3.089 57.326z'
			style={{
				fill: '#b84955',
			}}
		/>
		<path
			d='m222.741 377.037-.244.488c-2.033.325-4.066.569-6.18.813-30.25 2.765-63.427 2.196-84.325 16.101-.081 0-.163 0-.163.081-4.472 3.09-8.376 6.587-11.628 11.14-28.705 63.264-47.163 91.319-58.954 102.703-5.042-1.382-10.327-3.334-16.02-5.936v.081l-.244-.081a93.35 93.35 0 0 1-6.993-3.496c-.651-.325-1.22-.732-1.871-1.057a138.397 138.397 0 0 1-5.042-3.009c.081-.813.081-1.708.163-2.684.406-3.74 1.139-8.213 2.358-13.58 4.31-19.028 14.718-48.953 36.023-95.872 1.383-5.936 1.464-11.791.569-17.646v-.162c-4.066-24.395-25.777-48.383-43.098-72.535a134.53 134.53 0 0 1-4.147-6.099l139.539 63.427 30.168 13.661 30.089 13.662z'
			style={{
				fill: '#e5c294',
			}}
		/>
		<path
			d='M122.64 332.15 74.826 437.374l-.081.081-29.518 64.972v.081l-.244-.081a93.35 93.35 0 0 1-6.993-3.496c-.651-.325-1.22-.732-1.871-1.057a138.397 138.397 0 0 1-5.042-3.009c-.651-.406-1.301-.813-1.789-1.22-2.277-1.464-4.31-3.009-6.262-4.472-4.635-3.578-8.376-7.156-11.222-10.49l2.033-3.904 4.147-7.969 52.205-101.808v-.081l1.057-2.033 22.443-43.83 28.951 13.092z'
			style={{
				fill: '#f5dda8',
			}}
		/>
		<path
			d='M85.143 512c-6.441.022-14.516-.877-23.899-3.62-5.001-1.378-10.352-3.362-15.979-5.917l77.339-170.289 28.949 13.148-19.528 49.093L85.143 512z'
			style={{
				fill: '#f5dda8',
			}}
		/>
		<g
			style={{
				opacity: 0.04,
			}}>
			<ellipse
				cx={188.602}
				cy={19.785}
				rx={8}
				ry={7.736}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 188.608 19.785)'
			/>
			<ellipse
				cx={254.159}
				cy={49.559}
				rx={8}
				ry={7.735}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 254.17 49.56)'
			/>
			<ellipse
				cx={319.715}
				cy={79.332}
				rx={8}
				ry={7.735}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 319.728 79.335)'
			/>
			<ellipse
				cx={136.135}
				cy={44.383}
				rx={8}
				ry={7.736}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 136.138 44.384)'
			/>
			<ellipse
				cx={201.691}
				cy={74.156}
				rx={8}
				ry={7.736}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 201.698 74.159)'
			/>
			<ellipse
				cx={267.247}
				cy={103.929}
				rx={8}
				ry={7.736}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 267.257 103.933)'
			/>
			<ellipse
				cx={332.803}
				cy={133.703}
				rx={8}
				ry={7.736}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 332.815 133.707)'
			/>
			<ellipse
				cx={86.261}
				cy={71.009}
				rx={8}
				ry={7.735}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 86.263 71.01)'
			/>
			<ellipse
				cx={151.817}
				cy={100.782}
				rx={8}
				ry={7.736}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 151.822 100.784)'
			/>
			<ellipse
				cx={217.373}
				cy={130.555}
				rx={8}
				ry={7.736}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 217.38 130.558)'
			/>
			<ellipse
				cx={282.93}
				cy={160.328}
				rx={8}
				ry={7.735}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 282.941 160.335)'
			/>
			<ellipse
				cx={348.486}
				cy={190.101}
				rx={8}
				ry={7.735}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 348.5 190.109)'
			/>
			<ellipse
				cx={99.349}
				cy={125.38}
				rx={8}
				ry={7.736}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 99.352 125.383)'
			/>
			<ellipse
				cx={164.906}
				cy={155.153}
				rx={8}
				ry={7.736}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 164.91 155.156)'
			/>
			<ellipse
				cx={230.462}
				cy={184.926}
				rx={8}
				ry={7.735}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 230.471 184.933)'
			/>
			<ellipse
				cx={296.018}
				cy={214.699}
				rx={8}
				ry={7.736}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 296.029 214.706)'
			/>
			<ellipse
				cx={49.475}
				cy={152.005}
				rx={8}
				ry={7.736}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 49.478 152.011)'
			/>
			<ellipse
				cx={115.032}
				cy={181.778}
				rx={8}
				ry={7.735}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 115.035 181.783)'
			/>
			<ellipse
				cx={180.588}
				cy={211.551}
				rx={8}
				ry={7.736}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 180.593 211.556)'
			/>
			<ellipse
				cx={246.144}
				cy={241.324}
				rx={8}
				ry={7.735}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 246.154 241.334)'
			/>
			<ellipse
				cx={311.7}
				cy={271.097}
				rx={8}
				ry={7.735}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 311.713 271.109)'
			/>
			<ellipse
				cx={62.564}
				cy={206.376}
				rx={8}
				ry={7.735}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 62.566 206.381)'
			/>
			<ellipse
				cx={128.12}
				cy={236.149}
				rx={8}
				ry={7.736}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 128.125 236.155)'
			/>
			<ellipse
				cx={193.676}
				cy={265.922}
				rx={8}
				ry={7.735}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 193.684 265.933)'
			/>
			<ellipse
				cx={259.233}
				cy={295.695}
				rx={8}
				ry={7.735}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 259.243 295.707)'
			/>
			<path
				d='M320.465 318.853a187.998 187.998 0 0 1-3.28 4.1c.093-.253.194-.523.313-.786a7.32 7.32 0 0 1 2.967-3.314z'
				style={{
					fill: '#040000',
				}}
			/>
			<ellipse
				cx={12.69}
				cy={233.001}
				rx={8}
				ry={7.735}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 12.69 233.01)'
			/>
			<ellipse
				cx={78.246}
				cy={262.774}
				rx={8}
				ry={7.735}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 78.25 262.78)'
			/>
			<ellipse
				cx={143.802}
				cy={292.547}
				rx={8}
				ry={7.736}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 143.807 292.553)'
			/>
			<ellipse
				cx={209.359}
				cy={322.32}
				rx={8}
				ry={7.736}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 209.365 322.329)'
			/>
			<ellipse
				cx={274.915}
				cy={352.094}
				rx={8}
				ry={7.736}
				style={{
					fill: '#040000',
				}}
				transform='rotate(24.425 274.926 352.108)'
			/>
		</g>
		<path
			d='m367.109 145.097-344.07 344.07a100.92 100.92 0 0 0 6.214 4.505c.559.343 1.158.794 1.791 1.171 1.602.995 3.352 2.058 5.061 3.013 2.753 1.607 5.648 3.101 8.905 4.581l.222.101.034-.074c5.627 2.556 10.978 4.539 15.978 5.917 9.382 2.743 17.458 3.642 23.899 3.62l46.883-117.584c20.878-13.917 54.016-13.335 84.299-16.105 2.066-.223 4.132-.446 6.157-.776l.235-.518-30.044-13.645 30.076 13.66-.235.518c2.639-.32 5.236-.749 7.865-1.251 15.218-3.002 30.052-8.411 43.965-16.471.026-.014.05-.033.076-.048 3.237.278 6.4-1.34 7.779-4.378.086-.19.062-.394.132-.587l.022-.014c.076.035.11-.039.11-.039 12.477-8.355 24.172-18.852 34.768-31.811a463.677 463.677 0 0 0 3.233-4.069c14.239-18.54 26.387-41.424 35.448-69.103a195.365 195.365 0 0 0 3.94-12.946c2.19-7.669 4.098-15.734 5.876-24.124 4.152-20.531 4.897-39.662 3.025-57.303-.374-3.539-1.063-6.893-1.644-10.31z'
			style={{
				opacity: 0.06,
				fill: '#040000',
			}}
		/>
		<circle
			cx={340.761}
			cy={422.087}
			r={45.259}
			style={{
				fill: '#e88a3e',
			}}
		/>
		<path
			d='M340.761 376.829c-24.996 0-45.259 20.263-45.259 45.258 0 12.498 5.065 23.812 13.256 32.003l64.005-64.005c-8.19-8.191-19.504-13.256-32.002-13.256z'
			style={{
				opacity: 0.1,
				fill: '#fbdcc5',
			}}
		/>
	</svg>
);
export default SvgSportIconTableTennis;
