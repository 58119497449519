import * as React from 'react';
import { SVGProps } from 'react';
const SvgDeez = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#939393' fillRule='nonzero' />
			<path
				fill='#FFF'
				d='M13.707 19.86c.365-.237 2.818-1.74 5.208-3.204 1.723-1.054 1.723-1.054 1.693-1.157-.03-.125-10.7-6.609-10.809-6.674a21.481 21.481 0 0 1-.602-.369c.196-.136.577-.396 1.033-.706L11.34 7l.762.456c.94.562 11.26 6.892 12.706 7.793-.59.355-2.68 1.592-5.49 3.247l-5.583 3.29a36.218 36.218 0 0 1-.02-.874 46.32 46.32 0 0 1-.007-1.051zm-2.368-6.296c.185.11.505.303.899.548l1.029.638.022 1.93c.065 5.577.065 5.577.146 5.634l.054.037.06-.022c.03-.009.041-.013 10.871-6.402l.58-.342-.191 2.683-.07.04a5095.12 5095.12 0 0 1-13.389 7.664c-.004-.699-.01-3.085-.011-6.196v-6.212zM9 24.8l.025-15.967.388.229c.312.182 2.416 1.465 4.793 2.919a1210.99 1210.99 0 0 0 5.114 3.118l.715.43-2.364 1.446-.472-.275a279.81 279.81 0 0 1-2.83-1.732 260.976 260.976 0 0 0-2.76-1.688l-.652-.38-.005 6.769c-.002 3.054-.005 5.592-.007 6.332-.193-.117-.522-.32-.908-.56L9 24.8z'
			/>
		</g>
	</svg>
);
export default SvgDeez;
