import * as React from 'react';
import { SVGProps } from 'react';
const SvgQlc = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#610089' fillRule='nonzero' />
			<path
				fill='#fff'
				d='M16.49 5.003c5.13.276 9.282 3.836 10.301 8.868.134.664.17 1.351.207 2.03.033.6-.358 1.005-.899 1.004-.53 0-.877-.384-.89-.98-.097-4.387-3.06-8.005-7.338-8.92-.64-.136-1.308-.138-1.962-.212-.498-.056-.828-.439-.814-.925.014-.481.374-.839.876-.864.173-.008.346-.001.519-.001zM15.532 27c-4.923-.148-9.351-3.88-10.285-8.712-.142-.74-.196-1.499-.245-2.252-.03-.46.263-.79.69-.9.354-.092.766.059.946.396.09.171.133.387.137.584.073 4.204 2.916 7.822 6.996 8.828.736.182 1.512.211 2.272.292.434.045.782.34.84.763.055.4-.197.847-.58.942-.247.061-.514.042-.771.059zm.452-15.003c2.177-.007 4.005 1.778 4.02 3.925.005.74-.673 1.206-1.3.887-.34-.172-.486-.46-.5-.843a2.202 2.202 0 0 0-1.867-2.132c-1.152-.196-2.272.586-2.496 1.743-.229 1.182.511 2.333 1.663 2.582.158.034.32.053.48.062.536.028.912.397.908.9-.004.51-.407.888-.953.893-1.716.015-3.334-1.237-3.802-2.941-.699-2.545 1.213-5.068 3.847-5.076zm7.555 3.757c-.02.17-.013.383-.075.573-.13.404-.56.638-.98.57-.437-.072-.748-.43-.753-.92-.007-.746-.128-1.47-.434-2.147-1.013-2.235-2.746-3.434-5.204-3.55-.601-.03-1-.382-.996-.92.005-.538.407-.889 1.017-.887 3.67.007 6.877 2.847 7.342 6.504.032.247.053.494.083.777zm-15.061.461c-.051-.407.053-.808.518-1.022.612-.283 1.253.155 1.265.874.012.689.13 1.352.378 1.994a5.712 5.712 0 0 0 5.208 3.682c.776.02 1.227.582.98 1.22-.138.36-.442.569-.858.565-2.202-.02-4.059-.845-5.544-2.462a7.326 7.326 0 0 1-1.947-4.85zm11.534 2.82c-.037.447-.19.73-.526.887-.334.156-.673.132-.94-.115a27.145 27.145 0 0 1-1.401-1.397c-.31-.332-.28-.855.024-1.182.31-.333.864-.402 1.203-.091.502.458.977.948 1.44 1.447.122.132.156.345.2.45z'
			/>
		</g>
	</svg>
);
export default SvgQlc;
