import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomRocketBlue = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		className='svg-icon'
		data-name='Flat Color'
		viewBox='0 0 24 24'
		{...props}>
		<path
			d='m9.15 15.41.85 5.44A1 1 0 0 1 9 22H5a1 1 0 0 1-.79-.38 1 1 0 0 1-.21-.86l.81-3.25a3.05 3.05 0 0 1 1.24-1.77l1.53-1a1 1 0 0 1 1-.08 1 1 0 0 1 .57.75ZM20 20.76l-.81-3.25a3 3 0 0 0-1.25-1.77l-1.52-1a1 1 0 0 0-1-.08 1 1 0 0 0-.59.76L14 20.85A1 1 0 0 0 15 22h4a1 1 0 0 0 .79-.38 1 1 0 0 0 .21-.86Z'
			style={{
				fill: '#2ca9bc',
			}}
		/>
		<path
			d='M14.12 3.71 13.41 3a2 2 0 0 0-2.82 0l-.71.71a11 11 0 0 0-3.1 9.45l1.23 8A1 1 0 0 0 9 22h6a1 1 0 0 0 1-.85l1.23-8a11 11 0 0 0-3.11-9.44Z'
			style={{
				fill: '#000',
			}}
		/>
		<path
			d='M12 14a1 1 0 0 1-1-1v-3a1 1 0 0 1 2 0v3a1 1 0 0 1-1 1Z'
			data-name='secondary'
			style={{
				fill: '#2ca9bc',
			}}
		/>
	</svg>
);
export default SvgCustomRocketBlue;
