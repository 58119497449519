import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionBelgium = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 45 39'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#ED2939' d='M0 0h45v39H0z' />
		<path fill='#FAE042' d='M0 0h30v39H0z' />
		<path d='M0 0h15v39H0z' />
	</svg>
);
export default SvgRegionBelgium;
