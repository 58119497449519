import * as React from 'react';
import { SVGProps } from 'react';
const SvgFun = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#ed1968' />
			<path
				fill='#fff'
				fillRule='nonzero'
				d='M12.7 26.9a9.68 9.68 0 0 1-3.5-1.8 11.56 11.56 0 0 1-2.6-2.8A11.23 11.23 0 0 1 5 18.6a11.1 11.1 0 0 1 0-4.8A13.11 13.11 0 0 1 6.5 10a10.32 10.32 0 0 1 4.2-3.8 10.84 10.84 0 0 1 1.9-.8c1 1.2 2 2.5 3 3.8.1.2.3.3.4.5a7.7 7.7 0 0 0-2.9.7 6.84 6.84 0 0 0-2.1 1.7 8.42 8.42 0 0 0-1.3 2.5 6.17 6.17 0 0 0 .1 3.3l4.3-3.4c1 1.3 2 2.5 3 3.8-1.4 1.1-2.9 2.2-4.3 3.4 1.4 1.8 2.9 3.7 4.3 5.6a10.68 10.68 0 0 1-4.4-.4zm9.53-12.114a6.17 6.17 0 0 0-.13-.486c-.135.106-3.11 2.38-4.3 3.4-1-1.3-2-2.5-3-3.8 1.4-1.1 2.9-2.2 4.3-3.4-1.4-1.8-2.8-3.6-4.3-5.5 0-.1-.1-.1-.1-.2h1.4a11 11 0 0 1 7 2.6 10.8 10.8 0 0 1 2.4 2.8 9.1 9.1 0 0 1 1.4 3.8 12.35 12.35 0 0 1-.1 4.7 9.88 9.88 0 0 1-1.6 3.6 10.73 10.73 0 0 1-2.5 2.7 8.77 8.77 0 0 1-3.3 1.8c-.9-1.1-1.7-2.1-2.5-3.2a7.63 7.63 0 0 1-.8-1.1 6.16 6.16 0 0 0 4.9-2.4 6.47 6.47 0 0 0 1.2-2.5c.186-.759.196-1.697.03-2.814zm-4.723 8.373a62.41 62.41 0 0 0 2.15 2.77 8.143 8.143 0 0 0 2.588-1.525 10.05 10.05 0 0 0 2.344-2.54 9.126 9.126 0 0 0 1.478-3.322c.314-1.452.345-2.951.09-4.446a8.392 8.392 0 0 0-1.301-3.512 10.063 10.063 0 0 0-2.24-2.611 10.246 10.246 0 0 0-6.427-2.422c.927 1.18 1.866 2.383 3.503 4.489l.44.564-.544.465a56.326 56.326 0 0 1-2.178 1.761c-.07.055-.92.708-1.558 1.202.263.335.568.717 1.034 1.3.46.575.765.958 1.028 1.293.464-.383.97-.785 1.576-1.255.098-.076 1.696-1.306 2.147-1.66l.856-.673.323 1.04a6.914 6.914 0 0 1 .114 3.695 7.214 7.214 0 0 1-1.336 2.786 6.91 6.91 0 0 1-4.123 2.559l.036.042z'
			/>
		</g>
	</svg>
);
export default SvgFun;
