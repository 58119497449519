import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomCharge = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<path
			d='M29 12h-1V9c0-1.654-1.346-3-3-3H4C2.346 6 1 7.346 1 9v14c0 1.654 1.346 3 3 3h21c1.654 0 3-1.346 3-3v-4h1c1.1 0 2-.9 2-2v-3c0-1.1-.9-2-2-2zm-3 11a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h21a1 1 0 0 1 1 1v14zM24 9H5c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h19c.55 0 1-.45 1-1V10c0-.55-.45-1-1-1z'
			style={{
				fill: '#111918',
			}}
		/>
	</svg>
);
export default SvgCustomCharge;
