import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomTicketa = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		viewBox='0 0 512.001 512.001'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			d='M161.268 44.545c9.044 31.873 1.029 67.569-24.064 92.661s-60.787 33.108-92.661 24.064L0 205.812 306.188 512l44.544-44.544c-9.046-31.874-1.029-67.57 24.064-92.661 25.091-25.091 60.787-33.108 92.661-24.064l44.544-44.544L205.812.001l-44.544 44.544z'
			style={{
				fill: '#ffd782',
			}}
		/>
		<path
			d='M185.544 166.23h179.838v218.441H185.544z'
			style={{
				fill: '#ff6465',
			}}
			transform='rotate(134.999 275.465 275.451)'
		/>
		<path
			d='m232.97 232.971 37.79 12.814 31.981-23.866-.51 39.902 32.581 23.041-38.106 11.844-11.845 38.106-23.041-32.581-39.902.511 23.867-31.981z'
			style={{
				fill: '#fff',
			}}
		/>
		<path
			d='m57.499 263.311 13.246 13.246 15.454-15.454c3.658-3.658 3.658-9.589 0-13.246-3.658-3.658-9.589-3.658-13.246 0l-15.454 15.454zM114.557 228.865a9.333 9.333 0 0 1-6.623-2.744c-3.658-3.658-3.658-9.589 0-13.246l34.98-34.98c3.658-3.658 9.589-3.658 13.246 0 3.658 3.658 3.658 9.589 0 13.246l-34.98 34.981a9.335 9.335 0 0 1-6.623 2.743zm69.961-69.961a9.333 9.333 0 0 1-6.623-2.744c-3.658-3.658-3.658-9.589 0-13.246l34.98-34.98c3.658-3.658 9.589-3.658 13.246 0 3.658 3.658 3.658 9.589 0 13.246l-34.98 34.98a9.33 9.33 0 0 1-6.623 2.744zM263.31 57.499l-15.455 15.454a9.366 9.366 0 0 0 0 13.246 9.332 9.332 0 0 0 6.623 2.744 9.333 9.333 0 0 0 6.623-2.744l15.455-15.454-13.246-13.246z'
			style={{
				fill: '#ff6465',
			}}
		/>
		<path
			d='M64.85 164.69a94.675 94.675 0 0 1-20.306-3.422L0 205.812 306.188 512l44.544-44.544a94.757 94.757 0 0 1-3.422-20.306L64.85 164.69z'
			style={{
				opacity: 0.1,
			}}
		/>
	</svg>
);
export default SvgCustomTicketa;
