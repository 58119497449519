import * as React from 'react';
import { SVGProps } from 'react';
const SvgZcl = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#c87035' />
			<path
				fill='#fff'
				fillRule='nonzero'
				d='M15.992 27C9.917 26.996 5 22.07 5 15.986 5 9.915 9.932 4.996 16.015 5 22.08 5.004 27.008 9.934 27 16.002c-.012 6.002-4.836 10.99-11.008 10.998zm-4.483-5.97c.031-.059.055-.106.078-.144.984-1.736 1.97-3.47 2.956-5.202.864-1.518 1.726-3.038 2.585-4.558a.146.146 0 0 1 .15-.09c1.004.005 2.012.005 3.015.005h.143c-1.394 2.46-2.778 4.903-4.173 7.36.05.004.078.004.105.004 2.519 0 5.036.001 7.552.004.085 0 .112-.031.131-.105a8.318 8.318 0 0 0 .105-4.127c-.376-1.65-1.201-3.043-2.418-4.208a7.657 7.657 0 0 0-1.84-1.297c-1.674-.854-3.437-1.125-5.285-.8-1.84.327-3.39 1.196-4.661 2.57-.167.18-.318.373-.477.56.004.011.012.02.016.03h7.18c-.04.075-.067.129-.098.18-.437.776-.88 1.548-1.313 2.325a.198.198 0 0 1-.202.116c-2.262-.004-4.524-.005-6.784-.004h-.136c-1.146 3.603.473 8.122 4.534 9.93 3.932 1.756 8.055.14 9.988-2.557-3.715.007-7.432.01-11.15.007zm12.643 1.898c1.395-1.55 2.635-4.1 2.557-7.163-.062-2.523-.926-4.74-2.541-6.7-.504.508-.996 1.001-1.484 1.494 2.56 3.079 2.67 7.632.027 10.901z'
			/>
		</g>
	</svg>
);
export default SvgZcl;
