import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { IGame, ISiteContextProps } from "../type/socket-type";
import { ICurrency } from '../type/socket-type';
import AuthContext from "./AuthContext";

export const SiteContext = createContext<ISiteContextProps>(
  {} as ISiteContextProps
);
interface SiteContextProviderProps {
  children: React.ReactNode;
}

export const SiteContextProvider: React.FC<SiteContextProviderProps> = ({
  children,
}) => {
  const [gameList, setGameList] = useState<IGame[]>([]);
  const [currencyList, setCurrencyList] = useState<ICurrency[]>([]);
  const [selectedCurrencyId, setSelectedCurrencyId] = useState(
    localStorage.getItem("selectedCurrencyId") || ""
  );
  const [selectedFiatId, setSelectedFiatId] = useState(
    localStorage.getItem("selectedFiatId") || ""
  );
  const [selectedUser, setSelectedUser] = useState<any>();
  const [sportLoaded, setSportLoaded] = useState(false);

  const [sportsMenu, setSportsMenu] = useState<any>({});

  const storedValue = localStorage.getItem("showFiat");
  const initialShowFiat = storedValue === "true";
  const storedValueHidden = localStorage.getItem("hideLow");
  const initialHideLowBalance = storedValueHidden === "true";
  const [showFiat, setShowFiat] = useState<boolean>(initialShowFiat);
  const [hideLowBalance, setHideLowBalance] = useState<boolean>(
    initialHideLowBalance
  );
  const [treasuryList, setTreasuryList] = useState<any[]>([]);
  const [gameHistory, setGameHistory] = useState<any[]>([]);
  const [myGameHistory, setMyGameHistory] = useState<any[]>([]);
  const [networkList, setNetworkList] = useState<any[]>([]);
  const [defaultCurrency, setDefaultCurrency] = useState<any>();
  const [wagerList, setWagerList] = useState<any[]>([]);
  const [latency, setLatency] = useState<number>(0);
  const { userAccounts, userProfile } = useContext(AuthContext);

  useEffect(() => {
    localStorage.setItem("selectedCurrencyId", selectedCurrencyId);
  }, [selectedCurrencyId]);

  useEffect(() => {
    localStorage.setItem("selectedFiatId", selectedFiatId);
  }, [selectedFiatId]);

  useEffect(() => {
    localStorage.setItem("showFiat", showFiat.toString());
  }, [showFiat]);

  useEffect(() => {
    localStorage.setItem("hideLow", hideLowBalance.toString());
  }, [hideLowBalance]);

  const value = useMemo(
    () => ({
      gameList,
      currencyList,
      selectedCurrencyId,
      showFiat,
      hideLowBalance,
      treasuryList,
      gameHistory,
      userAccounts,
      userProfile,
      myGameHistory,
      wagerList,
      selectedFiatId,
      setGameHistory,
      setMyGameHistory,
      setWagerList,
      setShowFiat,
      setHideLowBalance,
      setGameList,
      setCurrencyList,
      setSelectedCurrencyId,
      setTreasuryList,
      setSelectedFiatId,
      latency,
      setLatency,
      selectedUser,
      setSelectedUser,
      networkList,
      setNetworkList,
      sportsMenu,
      setSportsMenu,
      sportLoaded,
      setSportLoaded,
      defaultCurrency,
      setDefaultCurrency,
    }),
    [
      gameList,
      currencyList,
      sportLoaded,
      setSportLoaded,
      networkList,
      sportsMenu,
      setSportsMenu,
      setNetworkList,
      selectedCurrencyId,
      selectedUser,
      defaultCurrency,
      setSelectedUser,
      latency,
      setLatency,
      setGameList,
      setCurrencyList,
      setSelectedCurrencyId,
      showFiat,
      treasuryList,
      hideLowBalance,
      setShowFiat,
      setHideLowBalance,
      setTreasuryList,
      gameHistory,
      myGameHistory,
      wagerList,
      userAccounts,
      userProfile,
      setGameHistory,
      setMyGameHistory,
      setWagerList,
      selectedFiatId,
      setSelectedFiatId,
    ]
  );

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>;
};

export default SiteContext;
