import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar24 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar24_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar24_svg__cls-3{fill:#fff}.avatar24_svg__cls-4{fill:#356cb6}.avatar24_svg__cls-17,.avatar24_svg__cls-4{opacity:.1}.avatar24_svg__cls-5{fill:#fbc0aa}.avatar24_svg__cls-15{fill:#00adfe}.avatar24_svg__cls-12,.avatar24_svg__cls-14,.avatar24_svg__cls-16,.avatar24_svg__cls-17{fill:none;stroke-linecap:round;stroke-linejoin:round}.avatar24_svg__cls-12,.avatar24_svg__cls-17{stroke-width:2px}.avatar24_svg__cls-12,.avatar24_svg__cls-14,.avatar24_svg__cls-16,.avatar24_svg__cls-17{stroke:#515570}.avatar24_svg__cls-12,.avatar24_svg__cls-15{opacity:.2}.avatar24_svg__cls-14,.avatar24_svg__cls-16{stroke-width:2.33px}.avatar24_svg__cls-14{opacity:.7}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#ff8475',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.4,
				fill: '#f85565',
			}}
		/>
		<circle cx={64} cy={25} r={14.92} className='avatar24_svg__cls-3' />
		<circle cx={64} cy={25} r={14.92} className='avatar24_svg__cls-4' />
		<path
			d='M64 19.4a30.33 30.33 0 0 1 30.33 30.33v4.46a20.46 20.46 0 0 1-20.46 20.46H54.13a20.46 20.46 0 0 1-20.46-20.46v-4.46A30.33 30.33 0 0 1 64 19.4Z'
			className='avatar24_svg__cls-3'
		/>
		<circle cx={89} cy={65} r={7} className='avatar24_svg__cls-5' />
		<path
			d='M64 124a59.7 59.7 0 0 0 34.7-11.07l-3.33-10.29A10 10 0 0 0 86 96H42.05a10 10 0 0 0-9.42 6.64l-3.33 10.29A59.7 59.7 0 0 0 64 124Z'
			style={{
				fill: '#00adfe',
			}}
		/>
		<path
			d='M46.54 121.41a60.15 60.15 0 0 0 34.92 0L79 96H49Z'
			style={{
				fill: '#356cb6',
			}}
		/>
		<circle cx={56} cy={98} r={3} className='avatar24_svg__cls-3' />
		<circle cx={57} cy={102} r={3} className='avatar24_svg__cls-3' />
		<circle cx={72} cy={98} r={3} className='avatar24_svg__cls-3' />
		<circle cx={71} cy={102} r={3} className='avatar24_svg__cls-3' />
		<circle cx={60} cy={105} r={3} className='avatar24_svg__cls-3' />
		<circle cx={64} cy={106} r={3} className='avatar24_svg__cls-3' />
		<circle cx={68} cy={105} r={3} className='avatar24_svg__cls-3' />
		<path
			d='M64 88v10'
			style={{
				strokeWidth: 14,
				strokeLinejoin: 'round',
				stroke: '#fbc0aa',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={39} cy={65} r={7} className='avatar24_svg__cls-5' />
		<path
			d='M64 95a25 25 0 0 1-25-25V53.52a25 25 0 1 1 50 0V70a25 25 0 0 1-25 25Z'
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<path
			d='M91.49 53.12V51.4c0-14.95-11.71-27.61-26.66-28a27.51 27.51 0 0 0-28.32 27.42v-.24a2 2 0 0 0 2 2h9.87a9.79 9.79 0 0 0 8.34-4.67c2 4.15 6.05 6 8.41 6.75a10 10 0 0 0 3 .46h21.32a2 2 0 0 0 2.04-2Z'
			className='avatar24_svg__cls-3'
		/>
		<path
			d='M91.49 53.12V51.4c0-14.95-11.71-27.61-26.66-28a27.51 27.51 0 0 0-28.32 27.42v-.24a2 2 0 0 0 2 2h9.87a9.79 9.79 0 0 0 8.34-4.67c2 4.15 6.05 6 8.41 6.75a10 10 0 0 0 3 .46h21.32a2 2 0 0 0 2.04-2Z'
			className='avatar24_svg__cls-4'
		/>
		<path
			d='M63 43c2.52 5.22 6.39 6.09 9.6 6.09h12'
			style={{
				strokeWidth: 2,
				stroke: '#fff',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M64 69.75v6.5'
			style={{
				strokeWidth: 5,
				strokeLinejoin: 'round',
				stroke: '#fbc0aa',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path d='M53.63 57h-9.25M83.63 57h-9.25' className='avatar24_svg__cls-12' />
		<path
			d='M64 86c5 0 7-3 7-3H57s2 3 7 3Z'
			style={{
				fill: '#f85565',
			}}
		/>
		<path d='M74 68h4M50 68h4' className='avatar24_svg__cls-14' />
		<circle cx={52} cy={65} r={7} className='avatar24_svg__cls-15' />
		<circle cx={52} cy={65} r={7} className='avatar24_svg__cls-16' />
		<circle cx={76} cy={65} r={7} className='avatar24_svg__cls-15' />
		<circle cx={76} cy={65} r={7} className='avatar24_svg__cls-16' />
		<path d='M59 65h10' className='avatar24_svg__cls-16' />
		<path d='m52 84 5-7M75 84l-5-7' className='avatar24_svg__cls-17' />
	</svg>
);
export default SvgAvatar24;
