import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar9 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar9_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar9_svg__cls-3{fill:#393c54}.avatar9_svg__cls-5,.avatar9_svg__cls-6{fill:none;stroke:#393c54;stroke-linecap:round;stroke-miterlimit:10}.avatar9_svg__cls-5{stroke-width:5.51px}.avatar9_svg__cls-6{stroke-width:2.21px}.avatar9_svg__cls-7{fill:#a7aece}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#00adfe',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				fill: '#356cb6',
				opacity: 0.3,
			}}
		/>
		<path
			d='M64 124a59.8 59.8 0 0 0 41.54-16.72c-1-22.43-3.94-55.49-12.65-75.18C88.06 21.18 76.74 13.88 64 13.88c-12.74 0-24.65 7-28.89 18.22C27.58 51.93 24.35 85.33 23 107.76A59.74 59.74 0 0 0 64 124Z'
			className='avatar9_svg__cls-3'
		/>
		<path
			d='M84.13 36.13c-3.52-8.48-10.48-12.82-19.74-13h-.78c-9.26.22-16.22 4.56-19.74 13-3.63 8.71-4.83 21.77 0 39.19 4.69 17 10.54 20.49 19.74 20.67h.78c9.2-.18 15-3.72 19.74-20.67 4.87-17.42 3.63-30.48 0-39.19Z'
			style={{
				fill: '#fff',
			}}
		/>
		<path d='M77.58 52.83h4.41' className='avatar9_svg__cls-5' />
		<path
			d='M68.5 88a30.85 30.85 0 0 1-9 0c-1.25-.33-2.5-1.12-2.5-2.5s1.2-2.13 2.5-2.5a20.4 20.4 0 0 1 9 0c1.21.31 2.5 1.12 2.5 2.5s-1.27 2.18-2.5 2.5Z'
			className='avatar9_svg__cls-3'
		/>
		<path d='M82.05 58.11a9.91 9.91 0 0 1-5.73-.37' className='avatar9_svg__cls-6' />
		<path
			d='M75.42 65A3.31 3.31 0 0 1 82 65c0 1.83-3.31 14.33-5.51 14.33-1.07-.04-1.07-12.54-1.07-14.33ZM75.37 45A3.31 3.31 0 0 0 82 45c0-1.82-2.59-9.92-4.41-9.92s-2.22 8.11-2.22 9.92Z'
			className='avatar9_svg__cls-7'
		/>
		<path d='M46.01 52.83h4.41' className='avatar9_svg__cls-5' />
		<path d='M51.68 57.76a10 10 0 0 1-5.91.29' className='avatar9_svg__cls-6' />
		<path
			d='M52.63 45A3.31 3.31 0 0 1 46 45c0-1.82 2.59-9.92 4.41-9.92s2.22 8.11 2.22 9.92ZM52.58 65A3.31 3.31 0 0 0 46 65c0 1.83 3.31 14.33 5.51 14.33 1.07-.04 1.07-12.54 1.07-14.33ZM68.41 90.32c0 1.22-2 2.21-4.41 2.21s-4.41-1-4.41-2.21c0-.62 2-.27 4.41-.27s4.41-.35 4.41.27Z'
			className='avatar9_svg__cls-7'
		/>
		<path
			d='M84.13 36.13a21.8 21.8 0 0 0-4.48-6.94c1.45 10.32.63 23.64-1.36 41.86-2.09 19.1-11.43 23.3-21 24a21.16 21.16 0 0 0 6.35.94h.78c9.2-.18 15-3.72 19.74-20.67 4.84-17.42 3.6-30.48-.03-39.19Z'
			style={{
				opacity: 0.2,
				fill: '#393c54',
			}}
		/>
	</svg>
);
export default SvgAvatar9;
