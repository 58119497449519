import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionArmenia = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 6 3'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#F2A800' d='M0 0h6v3H0z' />
		<path fill='#0033A0' d='M0 0h6v2H0z' />
		<path fill='#D90012' d='M0 0h6v1H0z' />
	</svg>
);
export default SvgRegionArmenia;
