import * as React from 'react';
import { SVGProps } from 'react';
const SvgCnx = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#4c6bae' />
			<g fill='#fff'>
				<path
					d='M6.383 17.01 5 17.153c.74-2.07 2.203-3.726 4.391-4.968.207 1.716 1.02 3.135 2.44 4.259l-1.3.135a5.503 5.503 0 0 0 4.553 4.845 6.084 6.084 0 0 1-2.987 1.185c.681 1.2 1.487 2.183 2.417 2.947a9.674 9.674 0 0 1-8.131-8.546zm19.234-2.02L27 14.847c-.74 2.07-2.203 3.726-4.391 4.968-.207-1.716-1.02-3.135-2.44-4.259l1.3-.135a5.503 5.503 0 0 0-4.626-4.857 6.085 6.085 0 0 1 2.972-1.173c-.687-1.209-1.499-2.196-2.438-2.963a9.674 9.674 0 0 1 8.24 8.562z'
					opacity={0.5}
				/>
				<path d='M16.922 25.626 17.065 27c-2.07-.74-3.726-2.203-4.968-4.391 1.715-.207 3.134-1.02 4.258-2.44l.136 1.31a5.503 5.503 0 0 0 4.945-4.636 6.085 6.085 0 0 1 1.173 2.972c1.209-.687 2.196-1.499 2.963-2.438a9.674 9.674 0 0 1-8.65 8.249zM14.99 6.383 14.847 5c2.07.74 3.726 2.203 4.968 4.391-1.716.207-3.135 1.02-4.259 2.44l-.135-1.3a5.503 5.503 0 0 0-4.857 4.626 6.085 6.085 0 0 1-1.173-2.972c-1.209.687-2.196 1.499-2.963 2.438a9.674 9.674 0 0 1 8.562-8.24z' />
			</g>
		</g>
	</svg>
);
export default SvgCnx;
