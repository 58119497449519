import * as React from 'react';
import { SVGProps } from 'react';
const SvgElf = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#2b5ebb' />
			<path
				fill='#fff'
				d='M26 15.962c-.001 2.336-1.81 4.158-4.122 4.151-2.264-.007-4.102-1.879-4.09-4.166.012-2.286 1.87-4.15 4.122-4.135 2.299.015 4.091 1.834 4.09 4.15zM9.09 23.92a3.019 3.019 0 0 1-3.021-3.054c.005-1.706 1.339-3.085 3.016-3.046.482.011.966.191 1.436.338.668.209 1.32.468 1.986.686.413.136.808.04 1.173-.186.53-.33.98-.728 1.267-1.312.29-.594.95-.815 1.537-.563.544.234.827.827.67 1.403-.177.647-.71.995-1.374.879-.589-.103-1.173-.293-1.771-.076-1.07.389-1.54 1.275-1.892 2.286-.192.553-.396 1.134-.74 1.59-.552.736-1.364 1.059-2.287 1.055zM9.026 8.082c1.367 0 2.432.8 2.896 2.117.184.52.46 1.007.696 1.508.583 1.24 2.046 1.362 2.974 1.023.822-.3 1.614.228 1.633 1.08.013.59-.29 1.02-.844 1.194-.49.152-1.042-.055-1.342-.502-.107-.16-.207-.326-.326-.477-.816-1.03-1.722-1.3-2.95-.838-.428.16-.829.4-1.231.623a3.018 3.018 0 0 1-4.234-1.358c-.956-2.03.504-4.37 2.728-4.37zm9.53 15.908c-.012 1.119-.925 2.03-2.015 2.01-1.053-.02-1.933-.927-1.96-1.947-.029-1.154.819-2.093 1.998-2.104 1.128-.011 1.99.919 1.978 2.04zM14.594 8.02c0-1.132.886-2.03 1.995-2.02 1.078.01 1.98.933 1.971 2.018-.008 1.141-.862 2.056-1.986 2.035-1.279-.024-2.032-1.055-1.98-2.033z'
			/>
		</g>
	</svg>
);
export default SvgElf;
