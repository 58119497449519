import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: true,
        },
    });

i18n.on('languageChanged', (lng: any) => {
    if (lng.includes('-')) {
        const shortLanguage = lng.split('-')[0];
        i18n.changeLanguage(shortLanguage);
        localStorage.setItem('locale', shortLanguage);
        localStorage.setItem('lang', shortLanguage);
        localStorage.setItem('i18nextLng', shortLanguage);
    }
});

export default i18n;
