import * as React from 'react';
import { SVGProps } from 'react';
const SvgBcio = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#3F43AD' />
			<path
				fill='#FFF'
				d='M11.94 7.436a.877.877 0 0 0 0 1.751.877.877 0 0 0 0-1.751m4.05-2.43a.877.877 0 0 0 0 1.752.877.877 0 0 0 0-1.752m0 9.719a.877.877 0 0 0 0 1.751.877.877 0 0 0 0-1.751m4.049-2.43a.876.876 0 1 0 .002 1.753.876.876 0 0 0-.002-1.753m4.049-2.429a.877.877 0 1 0 .002 1.753.877.877 0 0 0-.002-1.753M11.94 17.155a.877.877 0 0 0 0 1.751.877.877 0 0 0 0-1.751M7.89 9.866a.876.876 0 1 0 .002 1.752.876.876 0 0 0-.002-1.752m0 9.718a.876.876 0 1 0 .002 1.753.876.876 0 0 0-.002-1.753m0-4.859a.877.877 0 0 0 0 1.751.877.877 0 0 0 0-1.751m8.1-4.859a.877.877 0 1 0 0 1.753.877.877 0 0 0 0-1.753m4.049-2.43a.877.877 0 1 0 .002 1.753.877.877 0 0 0-.002-1.753m0 9.719a.876.876 0 1 0 .001 1.752.876.876 0 0 0-.001-1.752m4.049-2.43a.877.877 0 0 0 0 1.751.877.877 0 0 0 0-1.751m-4.049 7.289a.876.876 0 1 0 .001 1.753.876.876 0 0 0-.001-1.753m4.049-2.43a.877.877 0 0 0 0 1.752.877.877 0 0 0 0-1.752m-8.098 4.86a.877.877 0 0 0 0 1.751.877.877 0 0 0 .876-.875.877.877 0 0 0-.876-.876m0-4.86a.877.877 0 1 0 .001 1.754.877.877 0 0 0-.001-1.754m-4.05-7.289a.877.877 0 0 0 0 1.751.877.877 0 0 0 0-1.75m0 9.718a.877.877 0 0 0 0 1.751.877.877 0 0 0 0-1.751'
			/>
		</g>
	</svg>
);
export default SvgBcio;
