// SportWebSocketProvider.tsx
import React, { createContext, useContext, useCallback, useEffect, useState, useRef, FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  setSportConnected,
  setSessionLoad,
  receiveMessage,
  setSocketError,
  deleteResponseData
} from '../features/sportWebSocketSlice';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { ILang, getLangWithKey } from '../lang';
import SocketContext from './SocketContext';
interface SportWebSocketProviderProps {
  children: React.ReactNode;
  url: string;
}

const WebSocketContext = createContext<{
    sendSportMessage: (message: any, callback?: (response: any) => void) => void,
    unsubscribeSub: (subid: string) => void,
    unsubscribeBulk: (subids: string[]) => void
} | undefined>(undefined);

export const SportWebSocketProvider: FC<SportWebSocketProviderProps> = ({ children, url }) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { welcomeMessage, sessionLoaded } = useContext(SocketContext);

  const callbacks = useRef<Map<string, (data: any) => void>>(new Map());

  const connectWebSocket = useCallback(() => {
    dispatch(setSessionLoad(false));
    const ws = new WebSocket(url);
    setSocket(ws);

    ws.onopen = () => {
      dispatch(setSportConnected(true));
      dispatch(setSocketError(false));
      requestSession(ws);
    };

    ws.onmessage = (event: MessageEvent) => {
      const response = JSON.parse(event.data);
      const callback = callbacks.current.get(response.rid);
      if (callback) {
        callback(response.data);
        callbacks.current.delete(response.rid);
      }
      dispatch(receiveMessage({ rid: response.rid, data: response.data }));
    };

    ws.onerror = (e: any) => {
      console.error('WebSocket Error', e);
      dispatch(setSportConnected(false));
      dispatch(setSessionLoad(false));
      dispatch(setSocketError(true));
    };

    ws.onclose = (e: any) => {
      console.error('WebSocket Disconnected', e);
      dispatch(setSportConnected(false));
      dispatch(setSessionLoad(false));
      setTimeout(connectWebSocket, 5000);
    };

    

    return () => {
      ws.close();
    };
  }, 
  [url, dispatch]);

  useEffect(() => {
   
    connectWebSocket();
  }, [connectWebSocket, i18n.language]);

  const requestSession = (ws: WebSocket) => {

    const sessLang = getLangWithKey(i18n.language as ILang["key"]["lng"]);
 
    const msg = {
      command: 'request_session',
      params: {
        language: sessLang.lngIso,
        site_id: welcomeMessage?.SportId,
        source: welcomeMessage?.SourceId,
      },
    };
    if (ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify(msg));
      dispatch(setSessionLoad(true));
    }
  };

  const sendSportMessage = useCallback(
    (message: any, callback?: (response: any) => void) => {
      const requestId = uuidv4();
      message.rid = requestId;
      if (callback) {
        callbacks.current.set(requestId, callback);
      }
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(message));
      }
    },
    [socket]);

  const unsubscribeSub = useCallback(
    (subid: string) => {
      const message = {
        command: 'unsubscribe',
        params: { subid }
      };
      sendSportMessage(message);
      dispatch(deleteResponseData(subid));
    },
    [sendSportMessage, dispatch]
  );

  const unsubscribeBulk = useCallback(
    (subids: string[]) => {
      const message = {
        command: 'unsubscribe_bulk',
        params: { subids }
      };
      sendSportMessage(message);
      subids.forEach(subid => dispatch(deleteResponseData(subid)));
    },
    [sendSportMessage, dispatch]
  );

  

  return (
    <WebSocketContext.Provider value={{ sendSportMessage, unsubscribeSub, unsubscribeBulk }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useSportWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useSportWebSocket must be used within a SportWebSocketProvider');
  }
  return context;
};
