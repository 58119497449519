import * as React from 'react';
import { SVGProps } from 'react';
const SvgBtch = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#4700C2' fillRule='nonzero' />
			<g fill='#FFF'>
				<path d='M13.682 14.51c-.31.202-.665.416-1.033.64v2.021c.334-.169.678-.34 1.033-.509v-2.153zm-5.167 3.737a.779.779 0 0 0 1.04.34l.966-.487c.006-.812.01-1.429.01-1.674v-.065c-.82.408-1.442.721-1.681.84a.777.777 0 0 0-.335 1.046zm5.459-.205a.77.77 0 0 0 .77.773h.009a.773.773 0 0 0 .772-.773v-6.403a.774.774 0 0 0-.874-.767.768.768 0 0 0-.674.764v3.198c-.003 1.074-.003 2.14-.003 3.208z' />
				<path d='M24.985 13.083c-.049-.374-.297-.582-.674-.68-.3-.077-.61-.092-.926-.095-.736-.003-1.466.116-2.19.257-1.046.203-2.08.463-3.097.78-.307.094-.61.192-.917.29l-.003-.012.055-.046c.592-.506 1.18-1.012 1.776-1.512 1.306-1.104 2.484-2.315 3.578-3.593.298-.347.555-.718.687-1.141.2-.635-.024-1.03-.668-1.208a3.298 3.298 0 0 0-.834-.092c-.614-.003-1.22.098-1.819.22-.625.13-1.53.36-2.143.546-.981.3-2.193.816-3.275 1.254-.552.215-1.14.491-1.69.706l-2.079.846c-2.76 1.187-3.563.334-3.563.334 0 .004.001.007.003.01a.092.092 0 0 1-.012-.025c-.537 1.058-.09 2.98 3.014 1.607.488-.19.984-.396 1.469-.583.935-.365 1.861-.816 2.8-1.177a52.25 52.25 0 0 1 1.953-.758l.592-.23c.607-.2 1.214-.398 1.833-.552.675-.174 1.264-.279 1.62-.233.036.003.073.006.107.013.162.021.248.107.233.22-.006.175-.288.755-2.855 2.892-.187.16-.374.322-.561.481-.288.249-.567.494-.85.74l-.236.208-.003.003-.11.098a.223.223 0 0 1-.028.025l-.027.024-.215.163a.615.615 0 0 1-.046.04v2.713c.908-.31 5.326-1.784 5.802-1.352.037.05.598.399-4.539 3.913-.202.153-.398.306-.6.46l-.743.567c-.929.672-1.852 1.346-2.78 2.018l-.403.291c-.006.733-.009 1.435-.012 2.08l9.8-7.232a19.196 19.196 0 0 0 1.696-1.616c.3-.321.57-.662.748-1.051.092-.203.16-.399.132-.61zM9.018 24.205A.828.828 0 0 0 9.07 25.3a.821.821 0 0 0 1.019.141l.392-.288c.003-.589.01-1.306.015-2.08l-1.324.982a.89.89 0 0 0-.154.15z' />
				<path d='M12.364 12.945a.774.774 0 0 0-.85-.567.768.768 0 0 0-.674.764v3.198c0 .647-.028 3.858-.052 6.522-.006.764-.013 1.481-.016 2.08-.006.76-.009 1.33-.009 1.563a.77.77 0 0 0 .77.773h.009a.773.773 0 0 0 .773-.773c0-.536.006-1.515.012-2.714.003-.64.01-1.346.012-2.079.022-3.333.052-7.26.05-8.57a.859.859 0 0 0-.025-.197z' />
			</g>
		</g>
	</svg>
);
export default SvgBtch;
