import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionLesotho = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 450 300'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#009543' d='M0 0h450v300H0z' />
		<path fill='#fff' d='M0 0h450v210H0z' />
		<path fill='#00209f' d='M0 0h450v90H0z' />
		<g fill='#fff'>
			<path
				fill='currentColor'
				stroke='currentColor'
				d='M224.76 95.569c-1.71.037-3.378 1.93-3.378 1.93l.147 20.234-6.388 6.692h5.172l-.044 11.603-30.591 41.193-4.522-1.563-7.904 16.86s19.55 12.243 47.925 11.908c31.144-.37 48.064-12.471 48.064-12.471l-8.168-16.644-4 1.74-30.983-40.98-.043-11.949h5.17l-7.037-6.609.041-20.159s-1.751-1.82-3.46-1.783z'
			/>
			<path
				fill='none'
				stroke='currentColor'
				strokeWidth={5}
				d='M235.43 144.01h-21.153s-7.678-16.176-6.433-27.485c1.267-11.515 7.848-16.963 16.598-17.059 10.345-.115 15.767 5.07 17.405 16.598 1.623 11.424-6.417 27.947-6.417 27.947z'
			/>
			<path d='M187.79 182.57c-.346.461-2.882 5.533-2.882 5.533l4.38-.923-1.498-4.61zM190.32 189.14l-4.61 1.498 5.532 2.19-.922-3.688zM192.4 182.68l2.305 6.8 5.648-1.613-1.498-3.227-6.455-1.96zM195.97 191.91l.807 2.766 7.492 1.845-2.997-6.34-5.302 1.729zM204.27 186.14l2.651 6.34 5.648-2.19-1.844-2.767-6.455-1.383zM207.96 194.79l.922 2.42 8.76 1.268-3.688-5.763-5.994 2.075zM216.37 187.99l3.227 5.648 8.184-2.997-.922-2.19-10.489-.461zM221.1 195.71l1.729 2.997 10.143-.346-3.804-5.648-8.068 2.997zM231.82 188.22l2.766 4.726 6.455-3.112-1.729-2.536-7.492.922zM242.42 191.79l-6.455 3.227 1.73 2.767 8.644-1.384-3.919-4.61zM244.5 186.83l3.343 4.15 5.417-4.265-1.844-1.96-6.916 2.075zM254.41 189.02l-5.187 3.919 1.383 2.42 7.147-1.844-3.343-4.495zM261.56 180.84l1.268 1.844-3.573 5.302-3.689-4.725 5.994-2.421zM263.98 185.45l2.19 4.38-4.38 1.498-.346-2.074 2.536-3.804z' />
		</g>
	</svg>
);
export default SvgRegionLesotho;
