import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { useSportWebSocket } from "./SportWebSocketProvider";
import SiteContext from "./SiteContext";
import { toast } from "react-toastify";

interface Game {
  id: number;
  team1_name: string;
  team2_name: string;
  start_ts: number;
  [key: string]: any;
}

interface Market {
  id: number;
  name: string;
  [key: string]: any;
}

interface Event {
  id: number;
  price: number;
  name: string;
  [key: string]: any;
}

interface Bet {
  gameId: number;
  marketId: number;
  eventId: number;
  game: Game;
  market: Market;
  systemEnabled: boolean;
  event: Event;
  loading: boolean;
  subId: string | null;
  data?: any;
}

interface CouponContextType {
  marketOptions: any[];
  betSlip: Bet[];
  marketOptionsValues: any[];
  setBetSlip: React.Dispatch<React.SetStateAction<Bet[]>>;
  addToCoupon: (game: Game, market: Market, event: Event) => void;
  checkOnCoupon: (eventId: number) => boolean;
  deleteFromCoupon: (eventId: number) => void;
  acceptOddsChanges: () => void;
  clearBetSlip: () => void;
  setBetAmount: (amount: number) => void;
  betAmount: number;
  sendCoupon: () => void;
  couponType: string;
  setCouponType: React.Dispatch<React.SetStateAction<string>>;
  oddsChanged: boolean;
  setOddsChanged: React.Dispatch<React.SetStateAction<boolean>>;
  betSlipConfig: any;
  setBetSlipConfig: (config: any) => void;
  systemConfig: any;
  setSystemConfig: (config: any) => void;
}

const CouponContext = createContext<CouponContextType | null>(null);

interface CouponContextProviderProps {
  children: ReactNode;
}

export const CouponContextProvider: React.FC<CouponContextProviderProps> = ({
  children,
}) => {
  const { t } = useTranslation();
  const marketOptions = [
    {
      displayKey: "WINNER",
      eventOptions: ["W1", "X", "W2"],
      marketCmd: { display_key: "WINNER", display_sub_key: "MATCH" },
    },
    {
      displayKey: "TOTALS",
      eventOptions: ["Over", "Under"],
      marketCmd: {
        display_key: "TOTALS",
        display_sub_key: "MATCH",
        main_order: 1,
      },
    },
    {
      displayKey: "HANDICAP",
      eventOptions: ["Home", "Away"],
      marketCmd: {
        display_key: "HANDICAP",
        display_sub_key: "MATCH",
        main_order: 1,
      },
    },
  ];

  const marketOptionsValues = marketOptions.map((item) => ({
    value: item.displayKey,
    label: t(item.displayKey),
  }));

  const [betSlip, setBetSlip] = useState<Bet[]>([]);
  const [betAmount, setBetAmount] = useState<number>(10);
  const { sendSportMessage, unsubscribeSub } = useSportWebSocket();
  const [couponType, setCouponType] = useState<string>("SINGLE");
  const [oddsChanged, setOddsChanged] = useState<boolean>(false);

  const [betSlipConfig, setBetSlipConfig] = useState<any>();
  const [systemConfig, setSystemConfig] = useState<any>();



  const addToCoupon = (game: Game, market: Market, event: Event) => {

    
    if (!game || !market || !event) {
      toast.error(t("eventNotFound"));
      return;
    }
    if (betSlip.length > 14) {
      toast.error(t("maxEventCount"));
      return;
    }

    let addNew = false;

    setBetSlip((old) => {
      const foundGame = old.find((b) => b.gameId === game.id);
      if (foundGame) {
        unsubscribeSub(foundGame.subId!);
        return old.filter((b) => b.gameId !== game.id);
      } else {
        addNew = true;

        return [
          ...old,
          {
            gameId: Number(game.id),
            marketId: Number(market.id),
            eventId: Number(event.id),
            game,
            market,
            event,
            betAmount: betAmount,
            systemEnabled: true,
            loading: true,
            subId: null,
          },
        ];
      }
    });

    if (addNew) {
      getOnlineForCoupon(game, market, event, (data: any) => {
        setBetSlip((old) =>
          old.map((b) =>
            b.gameId === game.id
              ? {
                  ...b,
                  loading: false,
                  subId: data.subid,
                }
              : b
          )
        );
      });
    }
  };

  const getOnlineForCoupon = (
    game: Game,
    market: Market,
    event: Event,
    cb: (data: any) => void
  ) => {
    const cmdAddCoupon = {
      command: "get",
      params: {
        source: "betting",
        what: {
          game: [
            "team1_name",
            "team2_name",
            "is_blocked",
            "is_live",
            "sport_alias",
            "region_alias",
            "express_min_len",
            "_parent_id",
            "start_ts",
            "visible_in_prematch",
          ],
          market: ["id", "name", "express_id", "type", "extra_info"],
          event: [
            "id",
            "price",
            "base",
            "name",
            "type_1",
            "ew_allowed",
            "sp_enabled",
          ],
        },
        where: {
          game: { id: game.id },
          market: { id: market.id },
          event: { id: event.id },
        },
        subscribe: true,
      },
    };

    sendSportMessage(cmdAddCoupon, (data: any) => {
      cb(data);
    });
  };

  const checkOnCoupon = (eventId: number) => {
    return betSlip.some((b) => b.eventId === eventId);
  };

  const deleteFromCoupon = (eventId: number) => {
    setBetSlip((old) => old.filter((b) => b.eventId !== eventId));
  };

  const acceptOddsChanges = () => {
    console.log("lastBetSlio", betSlip);
    setOddsChanged(false);
    setBetSlip((old: any) => {
      return old.map((b: any) => {
        if (b.event?.oldPrice) {
          delete b.event.oldPrice;
        }
        return b;
      });
    });
  };

  const clearBetSlip = () => {
    setBetSlip([]);
  };

  const { showFiat, selectedCurrencyId, selectedFiatId } =
    useContext(SiteContext);

  useEffect(() => {
    if (betSlip.length > 1 && couponType === "SINGLE") {
      setCouponType("MULTIPLE");
    }
  }, [betSlip.length, couponType]);

  const sendCoupon = useCallback(() => {
    const couponData = {
      cId: selectedCurrencyId,
      f: showFiat,
      fId: selectedFiatId,
      bA: betAmount,
      betCoupon: betSlip.map((item) => {
       console.log('item', item);
        return {
          gameId: item.game.id,
          gameName: item.game.team1_name + " vs " + item.game.team2_name,
          gameTeam1: item.game.team1_name,
          gameTeam2: item.game.team2_name,
          marketName: item.market.name,
          compName: item.game.competitionName,
          eventName: item.event.name,
          event: item.event,
          eventBase: item.event.displayBase,
          eventId: item.event.id,
          systemEnabled: item.systemEnabled,
        };
      }),
      couponType: couponType,
      systemConfig: couponType === "SYSTEM" ? systemConfig : null,
    };
    return couponData;
  }, [
    betSlip,
    betAmount,
    systemConfig,
    couponType,
    showFiat,
    selectedFiatId,
    selectedCurrencyId,
  ]);

  const contextValue: CouponContextType = {
    betSlip,
    setBetSlip,
    addToCoupon,
    checkOnCoupon,
    deleteFromCoupon,
    marketOptions,
    marketOptionsValues,
    acceptOddsChanges,
    clearBetSlip,
    setBetAmount,
    betAmount,
    sendCoupon,
    couponType,
    setCouponType,
    oddsChanged,
    setOddsChanged,
    betSlipConfig,
    setBetSlipConfig,
    systemConfig,
    setSystemConfig,
  };

  return (
    <CouponContext.Provider value={contextValue}>
      {children}
    </CouponContext.Provider>
  );
};

export const useCouponContext = () => {
  const context = useContext(CouponContext);

  if (!context) {
    throw new Error(
      "useCouponContext must be used within a CouponContextProvider"
    );
  }

  return context;
};
