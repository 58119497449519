import * as React from 'react';
import { SVGProps } from 'react';
const SvgLend = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#0fa9c9' />
			<path
				fill='#fff'
				d='M17.147 20.446 11.697 26l-1.554-1.705 3.777-3.85L10 16.452l1.673-1.706zm3.18-3.191L22 15.549l-3.92-3.995 3.777-3.849L20.303 6l-5.474 5.554zm-7.96-3.167 5.498 5.7 1.673-1.705-5.498-5.603z'
			/>
		</g>
	</svg>
);
export default SvgLend;
