import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionIsleofMan = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		viewBox='0 0 600 300'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#cf142b' d='M0 0h600v300H0z' />
		<g
			fill='#f9dd16'
			stroke='currentColor'
			transform='matrix(1.05678 .03432 -.03393 1.03889 -14.505 -31.147)'>
			<g id='regionIsleofMan_svg__a'>
				<path d='M225.97 126.346c.22.225 2.857 1.819 2.903 1.73.046-.088 6.903-1.67 6.857-1.581-.045.088-3.842 4.6-3.665 4.692.176.091 3.967 4.63 4.055 4.676.088.046-7.18-.597-7.18-.597l-4.807 3.876-1.076-5.03s-6.047-2.134-6.135-2.18c-.088-.045 5.175-1.785 5.175-1.785l1.57-4.103s2.036.387 2.303.302z' />
				<path
					fill='#fff'
					strokeWidth={1.3}
					d='M314.466 136.204c.014-4.24-8.335-23.424-9.71-26.374-2.557-3.2-5.263-8.27-6.426-12.647-.753-4.42-3.117-7.885-6.449-9.06-3.845-2.041-7.6-1.241-11.214-.927-1.772.377-3.289 1.334-4.145 2.744-1.952 2.792-3.013 2.58-4.363 3.782-11.113 1.732-21.623 7.467-29.618 11.376-4.234 2.939-7.828 4.646-9.493 5.005-4.514.017-7.797-2.122-9.365-3.26l-15.337-13.946c-4.43-3.307-5.527 1.467-4.676 2.196 1.48 3.18-1.723 5.115 10.438 16.57l4.606 9.465s-.255 2.588-.041 2.699c2.22 5.289 5.747 3.97 7.412 2.487l3.613-3.291s7.16-2.267 7.272-2.48c7.679 1.142 27.465-2.627 30.727-4.176 2.94-1.646 3.128-.252 5.675-2.326 2.13-1.41.959-.288 4.893-3.883 1.211-1.042 2.17.645 2.395 1.824.22 11.17 1.587 18.306 2.606 29.443l16.733 8.574z'
				/>
				<path
					stroke='none'
					d='M217.839 102.282c.153.135-2.764 9.631-2.764 9.631s-2.689-2.681-2.666-2.725l1.883-9.977z'
				/>
				<g fill='none'>
					<path
						strokeWidth={1.13}
						d='M217.922 101.929c-.133.642-.698 3.504-.961 4.287-.22.866-.417 1.74-.563 2.607-.27.948-.388 1.568-.688 2.543-.096.835-.271 1.619-.484 2.455m-1.851-16.246c.06.123.275.985.307 1.54-.102.582-.264 2.667-.487 3.373a26.764 26.764 0 0 0-.436 2.362c-.223.857-.31 1.42-.56 2.3-.058.76-.195 1.47-.367 2.226'
					/>
					<path d='M262.227 96.635c3.578 3.982 4.688 10.454 4.04 14.077m-17.883 9.51.065-.124c-.203.39-.046.167-.429-.698-.406-.448-.692-2.625-.067-3.828.24-.614.64-1.538.942-2.12.086-.165.33-.33.416-.496m-26.515-6.177-.065.124-.258.498c-.335.646-.787 1.234-1.094 1.804-.324.622-.823 1.153-1.147 1.776-.415.582-.78 1.355-1.21 1.957-.402.716-.85 1.226-1.244 1.985-.34.6-.614.877-.966 1.555l.966-1.555c-.34.6-.614.877-.966 1.555m-7.009-21.82c.062.117.304.93.354 1.449-.08.54-.167 2.48-.361 3.132a23.075 23.075 0 0 0-.347 2.19c-.19.793-.256 1.315-.472 2.127-.03.71-.14 1.367-.282 2.067m16.698 11.933c-.84-.883-1.333-.56-1.873-.6-.598.106-1.684.606-2.787 1.715-.403.439-.741.762-1.274 1.235-.24.373-.437.514-.702.743m69.719-32.889-.065.125c.203-.39.056-.17.24.757.242.64.271 1.083.539 1.702m-13.771.874c-.043.083-.152 9.118.222 10.283.112.745.313 1.37.417 2.261.001.537.127.987.165 1.51.306.576.37 1.155.684 1.618.153.638.664.664.938 1.259.382.412 1.01 1.139 1.388 1.465m-10.091 6.339c-.278-.095-.992-.66-1.601-.963-.687-.388-2.382-2.41-2.463-3.835' />
					<path d='M275.902 103.13c-.022.042-.942.948-2.448 2.479-1.927 1.783-7.17 4.852-7.8 5.1-.604.273-1.358.93-1.954 1.023-.685.273-1.368.38-2.016.534-.791.167-1.462.311-2.076.345-1.843.802-11.554.49-12.055.381a21.406 21.406 0 0 1-1.693-.088c-.462.11-1.009.064-1.633.1-.755-.061-1.188.084-1.823.161-.5.264-1.333.537-1.896.913-.772.312-1.24.737-1.773.976-.554.458-8.11 4.867-8.476 5.064-.793.35-3.64 1.195-4.353-.807-.183-.787-.546-1.6-.568-2.202-.229-.721-.099-1.492-.061-2.165.661-3.41 1.756-4.753 2.058-5.335l.323-.622m70.224-12.548-.065.124c.175-.336.094-.16-.383.434-.486.392-1.086 1.014-1.527 1.42-.446.441-.874 1.066-1.274 1.235-.402.526-.91.692-1.459.982a9.901 9.901 0 0 0-1.896.912c-.82.436-1.33.852-2.09 1.287-.47.597-.946.969-1.344 1.673-.394.653-.787 1.213-1.159 1.928l-.905 1.744.905-1.744-.905 1.744m5.789 36.046.13-.25c-.24.461-.156.34.069-.437.002-.933.223-2.061.233-2.884.14-.871.282-1.812.291-2.694-.053-1.032-.051-1.957-.205-2.952-.012-.916-.12-1.866-.147-2.764-.016-.889-.083-1.65-.276-2.514-.017-.646-.057-1.533-.215-2.325.085-.624-.093-1.198-.226-1.698-.241-.962-.335-1.523-.588-2.52-.153-.533-.304-1.216-.534-2.015-.083-.758-.489-1.742-.654-2.395-.225-.48-.452-1.13-.54-1.702-.235-.528-.571-1.171-.663-1.768-.248-.455-.513-1.162-.787-1.832-.237-.55-.411-1.228-.664-1.767-.254-.68-.564-1.262-.723-1.956-.243-.236-.298-.494-.429-.697' />
				</g>
				<path d='M289.89 91.736c-.845 1.932-3.022 4.754-3.054 4.816l-2.072 4.142c-.912 2.267-.33 3.175.068 3.83.825 1.455.97 2.242 1.58 3.35.23 1.383-2.224 1.849-2.257 1.911-5.11 1.773-7.702-3.13-7.729-3.381-.693-2.573-.603-3 .963-3.531 1.796-.412 2.517-.433 3.406-1.078 1.682-1.26 2.87-5.07 2.902-5.133.032-.063.252-2.161.252-2.161.131-1.013-.337-1.94.18-3.543.672-1.548 1.076-1.97 2.667-2.093 2.074-.188 3.684 1.28 3.094 2.871z' />
			</g>
			<use
				xlinkHref='#regionIsleofMan_svg__a'
				width='100%'
				height='100%'
				transform='rotate(120 300 150)'
			/>
			<use
				xlinkHref='#regionIsleofMan_svg__a'
				width='100%'
				height='100%'
				transform='rotate(-120 300 150)'
			/>
			<path
				strokeWidth={1.3}
				d='M304.618 169.37c-1.774-.903-3.127-1.083-3.774-.646-1.027-5.746-1.932-11.499-3.251-17.227-10.896-4.537-13.199-5.573-16.774-7.127 1.669-1.085 2.502-2.167 2.446-2.945 5.49 1.983 10.925 4.076 16.545 5.797 9.377-7.167 11.426-8.644 14.56-10.963.104 1.988.625 3.25 1.327 3.592-4.462 3.762-8.993 7.422-13.294 11.43 1.52 11.704 1.773 14.216 2.215 18.089z'
			/>
		</g>
	</svg>
);
export default SvgRegionIsleofMan;
