import * as React from 'react';
import { SVGProps } from 'react';
const SvgAeon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#134451' />
			<g fill='#FFF'>
				<path d='M13.999 16.322c-.497 0-.873.084-1.133.243-.25.155-.376.39-.376.698 0 .23.078.416.225.548.151.133.363.199.627.199.41 0 .752-.128 1.024-.38.273-.256.458-.614.55-1.073l.042-.235h-.96zm5.264-2.169c-.151-.132-.363-.198-.626-.198-.411 0-.753.128-1.025.38-.272.256-.458.614-.549 1.073l-.043.234.96-.004c.497 0 .873-.084 1.132-.243.25-.155.376-.389.376-.698 0-.23-.073-.411-.225-.544z' />
				<path d='M26.965 14.71c-.328-2.964-5.51-4.798-11.57-4.095-6.06.702-10.706 3.675-10.378 6.64.329 2.964 5.507 4.797 11.57 4.095 6.065-.703 10.707-3.676 10.378-6.64zm-6.34 1.484c-.614.438-1.509.654-2.684.659l-1.141.004-.035.163a.434.434 0 0 0-.017.11c-.005.023-.005.045-.005.063 0 .252.117.437.346.56.23.129.57.19 1.016.19.423 0 .83-.048 1.214-.136.38-.089.748-.221 1.098-.398l-.29 1.555c-.436.106-.855.19-1.27.243a8.807 8.807 0 0 1-1.206.089c-.553 0-1.011-.067-1.379-.208-.38-.146-.804-.464-1.015-.919a4.641 4.641 0 0 1-1.34.892 3.684 3.684 0 0 1-1.453.296c-.63.005-1.128-.15-1.482-.455-.359-.304-.536-.729-.54-1.263-.005-.822.302-1.445.912-1.878.613-.437 1.508-.653 2.684-.658l1.14-.004.035-.164a.434.434 0 0 0 .018-.11c.004-.022.004-.044.004-.062 0-.252-.117-.437-.346-.561-.229-.128-.57-.19-1.016-.19-.423 0-.83.049-1.214.137-.38.088-.748.22-1.098.398l.29-1.555c.436-.106.855-.19 1.27-.243a8.807 8.807 0 0 1 1.206-.089c.554 0 1.012.067 1.38.208.38.146.803.464 1.015.919a4.641 4.641 0 0 1 1.34-.893 3.684 3.684 0 0 1 1.452-.296c.631-.004 1.128.15 1.482.455.36.305.536.73.54 1.264.005.817-.297 1.444-.911 1.877z' />
				<path d='M16.523 21.703c-.877.102-1.746.15-2.589.15-2.006 0-3.881-.282-5.442-.835a8.81 8.81 0 0 1-1.84-.883c-.213 1.118-.07 2.103.479 2.836 1.577 2.107 5.99 1.436 10.356-1.4-.32.048-.64.092-.964.132zm-1.024-11.397c2.96-.345 5.813-.102 8.03.684a8.81 8.81 0 0 1 1.842.884c.211-1.118.069-2.103-.48-2.836-1.578-2.107-5.99-1.436-10.356 1.4.32-.053.644-.097.964-.132z' />
				<path d='M12.279 24.265a9.094 9.094 0 0 1-1.76.243c.446 1.18 1.142 2.006 2.062 2.315 2.222.747 5.096-1.7 7.132-5.752-.48.133-.977.252-1.478.354-1.989 1.405-4.063 2.399-5.956 2.84zm7.598-16.553a9.044 9.044 0 0 1 1.76-.234c-.442-1.18-1.133-2.01-2.054-2.323-2.217-.756-5.104 1.678-7.153 5.716.48-.132.977-.247 1.482-.344 1.989-1.4 4.068-2.382 5.965-2.815z' />
			</g>
		</g>
	</svg>
);
export default SvgAeon;
