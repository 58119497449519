import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionJapan = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 900 600'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#fff' d='M0 0h900v600H0z' />
		<circle cx={450} cy={300} r={180} fill='#bc002d' />
	</svg>
);
export default SvgRegionJapan;
