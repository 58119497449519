import * as React from 'react';
import { SVGProps } from 'react';
const SvgPpp = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#348f8d' />
			<path
				fill='#fff'
				fillRule='nonzero'
				d='M23.312 7.044a1.33 1.33 0 0 1 0 1.846l-3.13 3.209a1.29 1.29 0 0 1-.342.25 1.19 1.19 0 0 1-1.442-.211L15.28 8.944l-6.005 6.159 1.45 1.483a1.17 1.17 0 0 1-.007 1.62 1.105 1.105 0 0 1-1.582.007L6.83 15.86a1.174 1.174 0 0 1 0-1.632l.016-.015a.198.198 0 0 0 .014-.015l7.624-7.82h.001l.001-.001.025-.026A1.147 1.147 0 0 1 16 6.21c.072.049.14.104.2.167l2.984 3.053 2.327-2.386a1.255 1.255 0 0 1 1.802 0zm1.858 9.102a1.165 1.165 0 0 1 .001 1.627l-.016.015a.198.198 0 0 0-.015.015l-7.654 7.847a1.148 1.148 0 0 1-1.754-.121l-2.917-2.984-2.313 2.378c-.322.332-.792.463-1.233.342s-.786-.473-.904-.925c-.117-.451.01-.933.335-1.262l3.129-3.208c.178-.186.407-.312.657-.362.404-.09.826.036 1.119.336l3.126 3.198 5.994-6.144-1.45-1.483a1.17 1.17 0 0 1 .007-1.62 1.105 1.105 0 0 1 1.582-.007l2.273 2.325.002.002.003.002z'
			/>
		</g>
	</svg>
);
export default SvgPpp;
