import { useTranslation } from "react-i18next";

const MenuList = () => {
  const { t } = useTranslation();

  const items: any[] = [
    {
      title: t("MyProfile"),
      iconClass: "bc-i-user",
      page: "",
      account: "profile",
      subItems: [
        {
          title: t("PersonalDetails"),
          iconClass: "bc-i-user",
          page: "details",
          account: "profile",
        },
        {
          title: t("ChangePassword"),
          iconClass: "bc-i-change-password",
          page: "change-password",
          account: "profile",
        },
        // {
        //   title: t("SelfExclusion"),
        //   iconClass: "",
        //   page: "self-exclusion",
        //   account: "profile",
        // },
        // {
        //   title: t("VerifyAccount"),
        //   iconClass: "",
        //   page: "verify-account",
        //   account: "profile",
        // },
        // {
        //   title: t("TwoFactorAuthentication"),
        //   iconClass: "",
        //   page: "two-factor-authentication",
        //   account: "profile",
        // },
      ],
    },
    {
      title: t("BalanceManagement"),
      iconClass: "bc-i-balance-management",
      page: "",
      account: "balance",
      subItems: [
        {
          title: t("Deposit"),
          iconClass: "bc-i-circle-dollar",
          page: "deposit",
          account: "balance",
        },
        {
          title: t("Withdraw"),
          iconClass: "bc-i-withdraw",
          page: "withdraw",
          account: "balance",
        },
        {
          title: t("TransactionHistory"),
          iconClass: "bc-i-history",
          page: "history",
          account: "balance",
        },
        {
          title: t("WithdrawStatus"),
          iconClass: "bc-i-withdraws",
          page: "withdraws",
          account: "balance",
        },
      ],
    },
    {
      title: t("BetHistory"),
      iconClass: "bc-i-history",
      page: "",
      account: "sport",
      subItems: [
        { title: t("AllBets"), iconClass: "bc-i-bet-history", page: "bets", account: "sport" },
        {
          title: t("OpenBets"),
          iconClass: "bc-i-open-bets",
          page: "open-bets",
          account: "sport",
        },
        {
          title: t("Won"),
          iconClass: "bc-i-won",
          page: "won",
          account: "sport",
        },
        {
          title: t("Lost"),
          iconClass: "bc-i-lost",
          page: "lose",
          account: "sport",
        },

       
      ],
    },
    {
      title: t("Bonuses"),
      iconClass: "bc-i-promotion",
      page: "",
      account: "bonuses",
      subItems: [
        // {
        //   title: t("SportsBonus"),
        //   iconClass: "",
        //   page: "sport",
        //   account: "bonuses",
        // },
        {
          title: t("BonusHistory"),
          iconClass: "bc-i-history",
          page: "bonus-history",
          account: "bonuses",
        },
        // {
        //   title: t("CasinoBonus"),
        //   iconClass: "",
        //   page: "casino",
        //   account: "bonuses",
        // },
        // {
        //   title: t("BonusRequest"),
        //   iconClass: "",
        //   page: "bonus-request",
        //   account: "bonuses",
        // },
        {
          title: t("PromoCode"),
          iconClass: "bc-i-promo-code",
          page: "promo-code",
          account: "bonuses",
        },
        {
          title: t("CasinoFreeSpins"),
          iconClass: "bc-i-bonus",
          page: "casino-free-spins",
          account: "bonuses",
        },
      ],
    },

    {
      title: t("Messages"),
      iconClass: "bc-i-message",
      page: "",
      account: "messages",
      subItems: [
        {
          title: t("Inbox"),
          iconClass: "bc-i-inbox",
          page: "inbox",
          account: "messages",
        },
        // { title: t("Sent"), iconClass: "", page: "sent", account: "messages" },
        // {
        //   title: t("NewMessage"),
        //   iconClass: "bc-i-sent",
        //   page: "new",
        //   account: "messages",
        // },
      ],

    },
  ];

  return items;
};

export default MenuList;
