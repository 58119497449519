import path from "path";

export const atoxPagesMenu = {
  sports: {
    id: "sports",
    path: "/sports/live",
    text: "Sport",
    icon: "Sport",
  },
  casino: {
    id: "casino",
    path: "/casino/slots",
    text: "Casino",
    icon: "Casino",
  },
  liveCasino: {
    id: "liveCasino",
    path: "/casino/live",
    text: "LiveCasino",
    icon: "LiveCasino",
  },
  virtualSports: {
    id: "virtualSports",
    path: "/virtualSports",
    text: "VirtualSports",
    icon: "VirtualSports",
  },
  poker: {
    id: "poker",
    path: "/poker",
    text: "Poker",
    icon: "Poker",
  },
  slotGames: {
    id: "slotGames",
    path: "/slotGames",
    text: "SlotGames",
    icon: "SlotGames",
  },
  promotions: {
    id: "promotions",
    path: "/promotions",
    text: "Promotions",
    icon: "Promotions",
  },
  eSports: {
    id: "eSports",
    path: "/eSports",
    text: "ESports",
    icon: "ESports",
  },

  signUp: {
    id: "signUp",
    path: "/signUp",
    text: "signUp",
    icon: "Register",
  },
  login: {
    id: "login",
    path: "/login",
    text: "Login",
    icon: "Login",
  },
  forgotPassword: {
    id: "forgotPassword",
    path: "/forgotPassword",
    text: "ForgotPassword",
    icon: "ForgotPassword",
  },
  bonus: {
    id: "bonus",
    path: "/bonus",
    text: "Bonus",
    icon: "Bonus",
  },
};

export const sportPagesMenu = {
  sports: {
    id: "Sports",
    path: "/sports/",
    text: "Sports",
    icon: "Sports",
    subMenu: {
      home: {},
      live: {
        id: "live",
        path: "/sports/live/",
        text: "Live",
        icon: "Live",
        subMenu: {
          eventView: {
            id: "eventView",
            path: "/sports/live/eventView",
            text: "EventView",
            icon: "EventView",
          },
          liveCalendar: {
            id: "liveCalendar",
            path: "/sports/live/liveCalendar",
            text: "LiveCalendar",
            icon: "LiveCalendar",
          },
          statistics: {
            id: "statistics",
            href: "https://statistics.atox.app",
            text: "Statistics",
            icon: "Statistics",
          }
        },
      },
      prematch: {
        id: "prematch",
        path: "/sports/prematch/",
        text: "Prematch",
        icon: "Prematch",
        subMenu: {
            eventView: {
              id: "eventView",
              path: "/sports/prematch/eventView",
              text: "EventView",
              icon: "EventView",
            },
            results: {
              id: "results",
              path: "/sports/prematch/results",
              text: "Results",
              icon: "Results",
            },
            statistics: {
              id: "statistics",
              href: "https://www.google.com",
              text: "Statistics",
              icon: "Statistics",
            }
          },
      },
      calendar: {
        id: "calendar",
        path: "/sports/live/calendar",
        text: "Calendar",
        icon: "Calendar",
      },
      results: {
        id: "results",
        path: "/sports/prematch/results",
        text: "Results",
        icon: "Results",
      },
    },
  },
};
