import * as React from 'react';
import { SVGProps } from 'react';
const SvgNetworkLtc = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0.847 0.876 329.256 329.256'
		className='svg-icon'
		{...props}>
		<path
			fill='#bebebe'
			d='M330.102 165.503c0 90.922-73.705 164.629-164.626 164.629C74.554 330.132.848 256.425.848 165.503.848 74.582 74.554.876 165.476.876c90.92 0 164.626 73.706 164.626 164.627'
		/>
		<path
			fill='#bebebe'
			d='M295.15 165.505c0 71.613-58.057 129.675-129.674 129.675-71.616 0-129.677-58.062-129.677-129.675 0-71.619 58.061-129.677 129.677-129.677 71.618 0 129.674 58.057 129.674 129.677'
		/>
		<path
			fill='#fff'
			d='m155.854 209.482 10.693-40.264 25.316-9.249 6.297-23.663-.215-.587-24.92 9.104 17.955-67.608h-50.921l-23.481 88.23-19.605 7.162-6.478 24.395 19.59-7.156-13.839 51.998h135.521l8.688-32.362h-84.601'
		/>
	</svg>
);
export default SvgNetworkLtc;
