import * as React from 'react';
import { SVGProps } from 'react';
const SvgCall = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#FBB413' />
			<g fill='#FFF'>
				<path d='M16.017 21.804a4.573 4.573 0 0 0 1.941-.336c.244-.087.477-.202.695-.342a.181.181 0 0 1 .27.034l1.579 1.625c.113.118.109.156-.026.249a7.524 7.524 0 0 1-1.772.897 8.396 8.396 0 0 1-2.409.446 8.354 8.354 0 0 1-3.082-.421 7.579 7.579 0 0 1-2.345-1.318 8.69 8.69 0 0 1-1.642-1.802 7.133 7.133 0 0 1-.67-1.17 12.4 12.4 0 0 1-.379-.99 8.808 8.808 0 0 1-.353-1.89 5.701 5.701 0 0 1 .02-1.415c.05-.464.132-.924.245-1.377a8.636 8.636 0 0 1 .817-1.983 8.084 8.084 0 0 1 3.217-3.1 8 8 0 0 1 1.604-.656 7.183 7.183 0 0 1 1.385-.27 5.356 5.356 0 0 1 1.09-.025c.065.004.13.004.194 0a6.783 6.783 0 0 1 1.756.278c.487.13.958.314 1.402.552.469.232.91.514 1.318.842.08.071.143.038.206-.03l.973-1.027 1.402-1.49c.164-.177.206-.182.383-.022.54.487 1.04 1.018 1.49 1.588a11.79 11.79 0 0 1 1.453 2.307c.315.68.574 1.384.775 2.105.169.594.223 1.2.354 1.794a.143.143 0 0 1 0 .034c0 .551.122 1.103.063 1.654-.067.628-.101 1.264-.232 1.883-.111.52-.252 1.032-.42 1.536a11.832 11.832 0 0 1-1.832 3.47c-.122.16-.16.164-.3 0-.462-.468-.925-.943-1.393-1.406-.097-.097-.084-.173 0-.278a9.634 9.634 0 0 0 1.188-2.24c.265-.747.447-1.521.543-2.308.058-.603.07-1.21.038-1.814a6.585 6.585 0 0 0-.123-1.024 9.482 9.482 0 0 0-1.642-3.84c-.088-.126-.198-.24-.278-.374-.08-.135-.088-.097-.164-.021-.446.45-.905.888-1.339 1.347-.151.16-.324.286-.467.45-.143.165-.316.32-.476.48l-.488.489c-.076.076-.135.046-.186-.025a5.44 5.44 0 0 0-1.562-1.516 5.196 5.196 0 0 0-1.966-.758 5.92 5.92 0 0 0-1.65-.021 5.086 5.086 0 0 0-1.92.606 5.356 5.356 0 0 0-1.643 1.398 5.414 5.414 0 0 0-.888 1.646 6.514 6.514 0 0 0-.299 2.186 5.267 5.267 0 0 0 1.381 3.591 5.592 5.592 0 0 0 1.937 1.386c.7.308 1.463.451 2.227.416z' />
				<path d='m25.037 23.711-4.26-4.337c-.093-.097-.17-.1-.245 0a5.36 5.36 0 0 1-1.082 1.162c-.101.08-.101.143 0 .236.383.383.762.775 1.141 1.158.493.501.985 1.002 1.482 1.503.088.088.084.13 0 .206-.198.148-.392.3-.598.421a9.288 9.288 0 0 1-3.596 1.491 7.158 7.158 0 0 1-1.528.177h-.206c-.364.025-.73.014-1.09-.034-.422-.063-.818-.114-1.218-.202a8.981 8.981 0 0 1-1.482-.476 9.638 9.638 0 0 1-2.27-1.335 9.941 9.941 0 0 1-1.814-1.86 9.196 9.196 0 0 1-1.297-2.396 7.924 7.924 0 0 1-.573-2.64 1.916 1.916 0 0 1 0-.287v-.993a.775.775 0 0 1 0-.325c0-.08.034-.155.047-.235.042-.373.108-.743.198-1.108.115-.49.268-.97.459-1.436a9.684 9.684 0 0 1 4.362-4.859 9.293 9.293 0 0 1 3.79-1.14h.243a9.444 9.444 0 0 1 4.064.64l.362.151a9.933 9.933 0 0 1 1.579.88.173.173 0 0 0 .256-.025c.447-.51.918-.994 1.386-1.482.088-.093.092-.177 0-.253a12.03 12.03 0 0 0-5.217-2.156 13.158 13.158 0 0 0-1.634-.156 11.663 11.663 0 0 0-2.526.236 12.21 12.21 0 0 0-1.566.421c-.912.305-1.782.723-2.59 1.242-.97.62-1.847 1.372-2.606 2.236a11.907 11.907 0 0 0-2.303 3.933c-.212.612-.38 1.238-.501 1.874-.093.557-.156 1.12-.19 1.684a8.842 8.842 0 0 0 .084 1.814c.066.597.18 1.187.341 1.765.114.391.228.783.375 1.162.294.76.663 1.49 1.103 2.177a12.707 12.707 0 0 0 1.924 2.315c.473.436.98.833 1.516 1.188.718.491 1.49.9 2.3 1.217.806.32 1.647.548 2.504.682.643.096 1.292.152 1.942.168.42 0 .842-.067 1.237-.076.3-.022.597-.067.889-.134.324-.055.644-.14.964-.224a11.368 11.368 0 0 0 3.368-1.499c.937-.6 1.787-1.325 2.527-2.155.122-.14.118-.164-.05-.316z' />
			</g>
		</g>
	</svg>
);
export default SvgCall;
