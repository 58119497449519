import { useEffect, useState } from 'react';
import { hasNotch } from './helper';

function getProperties() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    portrait: window.matchMedia('(orientation: portrait)').matches,
    landscape: window.matchMedia('(orientation: landscape)').matches,
    notch: hasNotch(),
  };
}

export default function useDeviceScreen() {
  const isClient = typeof window === 'object';

  const [deviceScreen, setDeviceScreen] = useState(getProperties);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setDeviceScreen(getProperties());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isClient]);

  return deviceScreen;
}
