export interface ILang {
	[key: string]: {
		text: string;
		lng: 'en' | 'de' | 'fr' | 'tr';
		lngIso: 'eng' | 'ger' | 'fra' | 'tur';
		icon: string;
	};
}

const LANG: any = {
	EN: {
		text: 'English',
		lng: 'en',
		lngIso: 'eng',
		icon: 'unitedkingdom',
	},

	DE: {
		text: 'Deutsche',
		lng: 'de',
		lngIso: 'ger',
		icon: 'germany',
	},
	FR: {
		text: 'Français',
		lng: 'fr',
		lngIso: 'fra',
		icon: 'france',
	},
	RU: {
		text: 'Русский',
		lng: 'ru',
		lngIso: 'rus',
		icon: 'russia',
	},
	TR: {
		text: 'Türkçe',
		lng: 'tr',
		lngIso: 'tur',
		icon: 'turkey',
	},
	AR: {
		text: 'العربية',
		lng: 'ar',
		lngIso: 'arb',
		icon: 'RegionUae',
	},
	ES: {
		text: 'Español',
		lng: 'es',
		lngIso: 'spa',
		icon: 'spain',
	},
	PT: {
		text: 'Português',
		lng: 'pt',
		lngIso: 'por',
		icon: 'portugal',
	},
	IT: {
		text: 'Italiano',
		lng: 'it',
		lngIso: 'ita',
		icon: 'italy',
	},
	PL: {
		text: 'Polski',
		lng: 'pl',
		lngIso: 'pol',
		icon: 'poland',
	},
	RO: {
		text: 'Română',
		lng: 'ro',
		lngIso: 'rom',
		icon: 'romania',
	},
	HU: {
		text: 'Magyar',
		lng: 'hu',
		lngIso: 'hun',
		icon: 'hungary',
	},
	GR: {
		text: 'Ελληνικά',
		lng: 'gr',
		lngIso: 'gre',
		icon: 'greece',
	},
	NL: {
		text: 'Nederlands',
		lng: 'nl',
		lngIso: 'dut',
		icon: 'netherlands',
	},
	CN: {
		text: '中文',
		lng: 'cn',
		lngIso: 'chi',
		icon: 'china',
	},
	JP: {
		text: '日本語',
		lng: 'jp',
		lngIso: 'jpn',
		icon: 'japan',
	},
	KR: {
		text: '한국어',
		lng: 'kr',
		lngIso: 'kor',
		icon: 'korea',
	},
	VN: {
		text: 'Tiếng Việt',
		lng: 'vn',
		lngIso: 'vie',
		icon: 'vietnam',
	},
	
};

export const getLangWithKey: any = (key: ILang['key']['lng']): ILang['key'] => {
	// @ts-ignore
	return LANG[Object.keys(LANG).filter((f) => key.includes(LANG[f].lng))];
};

export default LANG;
