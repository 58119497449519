import * as React from 'react';
import { SVGProps } from 'react';
const SvgBlk = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#181818' />
			<path
				fill='#fff'
				fillRule='nonzero'
				d='M23.1 11.13a3.803 3.803 0 0 1-2.436 3.732s1.012-1 .965-3.563c-.048-2.563-1.93-3.83-1.93-3.83s3.354.747 3.4 3.66zM11.936 24.819l-3.436.169v-.321s2.103-.217 2.103-3.99v-9.64C10.603 7.843 8.5 7.29 8.5 7.29V7l3.436.168zm5.492.18c-1.495.013-4.268-.196-4.268-.196V7.193c1.828-.025 2.467-.277 3.973-.06 1.507.216 2.801 1.721 3.154 3.226.353 1.505-.023 2.938-.506 3.72s-1.541 1.325-1.541 1.325 1.6.554 2.165 1.035c.565.482 1.66 1.794 1.236 4.648-.424 2.854-2.719 3.9-4.213 3.912zm3.971-.65s1.63-1.42 1.558-4.301-1.558-4.235-1.558-4.235 3.066.77 3.1 4.202c.036 3.431-3.1 4.335-3.1 4.335zm-3.1-10.334c1.098-1.461.753-4.21-.577-5.15-1.33-.94-2.73-.47-3-.205-.272.265-.26 6.43-.26 6.43s2.738.386 3.836-1.075zm.203 2.625c-1.028-.458-4.04-.338-4.04-.338s-.047 1.557.063 4.471 1.083 2.898 2.095 2.938c1.012.04 2.942-.032 3.577-2.545.636-2.512-.667-4.069-1.695-4.526z'
			/>
		</g>
	</svg>
);
export default SvgBlk;
