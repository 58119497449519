import * as React from 'react';
import { SVGProps } from 'react';
const SvgSportIconGolf = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 390.853 390.853'
		className='svg-icon'
		{...props}>
		<path
			d='M342.794 21.657h22.756c-26.57 87.79-59.087 179.911-69.883 189.156-7.564 5.43-26.57 17.325-56.372 25.471 0-9.244-2.198-18.424-5.43-26.57h3.814c5.947 0 10.861-4.848 10.861-10.861 0-5.947-4.848-10.861-10.861-10.861h-16.291c-14.093-16.226-34.651-27.087-57.988-27.087-23.273 0-44.477 10.279-57.988 27.087H72.895c-5.947 0-10.861 4.913-10.861 10.861 0 5.947 4.848 10.861 10.861 10.861h19.523c-3.232 8.663-5.43 17.907-5.43 27.669 0 2.198 0 4.848.517 7.046-20.622-3.232-35.749-8.145-44.994-14.093-12.477-8.663-20.622-32.517-20.622-60.121 0-27.669 8.145-51.523 20.622-60.186C84.79 81.326 237.614 88.89 286.939 122.506c5.947 3.814 12.994 1.616 16.291-4.848l39.564-96.001z'
			style={{
				fill: '#ffc10d',
			}}
		/>
		<path
			d='M217.638 237.382c0 29.802-24.372 54.174-54.756 54.174-24.372 0-44.994-16.226-52.04-37.947a61.974 61.974 0 0 1-1.939-8.145c-.453-2.715-.776-5.43-.776-8.145 0-30.384 24.372-54.756 54.756-54.756s54.755 24.435 54.755 54.819z'
			style={{
				fill: '#56ace0',
			}}
		/>
		<path
			d='M379.642 0H335.23c-4.331 0-8.145 2.715-10.279 6.529l-37.366 91.604C223.069 65.616 76.192 60.768 30.164 92.186 8.507 107.378.362 142.61.362 170.214s8.145 62.836 30.319 78.028c13.576 9.244 34.133 15.192 62.319 18.941 8.145 19.523 24.954 35.232 44.994 42.279h-2.198c-5.947 0-10.861 4.848-10.861 10.861 0 5.947 4.848 10.861 10.861 10.861h16.291v48.808c0 5.947 4.848 10.861 10.861 10.861s10.861-4.848 10.861-10.861v-48.808h17.325c5.947 0 10.861-4.848 10.861-10.861 0-5.947-4.848-10.861-10.861-10.861h-2.715c22.756-8.145 40.663-26.57 47.709-49.842 38.4-8.663 63.354-24.436 72.016-30.901C329.8 213.01 376.41 60.703 389.986 14.093 392.119 6.529 387.271 0 379.642 0zM162.883 291.556c-24.372 0-44.994-16.291-52.04-37.947-1.616-5.43-2.715-10.861-2.715-16.291 0-30.319 24.372-54.756 54.756-54.756s54.756 24.372 54.756 54.756c-.002 29.866-24.438 54.238-54.757 54.238zm132.784-80.744c-7.564 5.43-26.57 17.325-56.372 25.471 0-9.244-2.198-18.424-5.43-26.57h3.814c5.947 0 10.861-4.848 10.861-10.861 0-5.947-4.848-10.861-10.861-10.861h-16.291c-14.093-16.291-34.715-27.087-57.988-27.087s-44.412 10.279-57.988 27.087H72.895c-5.947 0-10.861 4.848-10.861 10.861 0 5.947 4.848 10.861 10.861 10.861h19.523c-3.232 8.663-5.43 17.907-5.43 27.669 0 2.198 0 4.848.517 7.046-20.622-3.232-35.749-8.145-44.994-14.093-12.477-8.663-20.622-32.517-20.622-60.186s8.145-51.459 20.622-60.186C84.79 81.261 237.614 88.824 286.939 122.44c5.947 3.814 12.994 1.616 16.291-4.848l39.564-95.935h22.756c-26.506 87.789-59.023 179.975-69.883 189.155z'
			style={{
				fill: '#194f82',
			}}
		/>
		<path
			d='M237.679 131.685H72.378c-5.947 0-10.861 4.848-10.861 10.861s4.848 10.861 10.861 10.861h165.301c5.947 0 10.861-4.848 10.861-10.861s-4.914-10.861-10.861-10.861z'
			style={{
				fill: '#194f82',
			}}
		/>
	</svg>
);
export default SvgSportIconGolf;
