import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar47 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar47_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar47_svg__cls-15{fill:#f85565}.avatar47_svg__cls-3{fill:#356cb6}.avatar47_svg__cls-9{fill:none;stroke-linecap:round;stroke-linejoin:round}.avatar47_svg__cls-6{fill:#ffbb94}.avatar47_svg__cls-7{fill:#00adfe}.avatar47_svg__cls-8{opacity:.5}.avatar47_svg__cls-9{stroke:#393c54;stroke-width:2px}.avatar47_svg__cls-12{fill:#393c54}.avatar47_svg__cls-14{fill:#fff}.avatar47_svg__cls-16{fill:#f8dc25}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#ff8475',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.4,
				fill: '#f85565',
			}}
		/>
		<circle cx={91.32} cy={60.43} r={7.93} className='avatar47_svg__cls-3' />
		<path
			d='M28.14 96.5a21.9 21.9 0 0 0-12.24 3.38 60 60 0 0 0 96.2 0 21.9 21.9 0 0 0-12.24-3.38Z'
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<path
			d='M64 83.75V97.5'
			style={{
				stroke: '#fbc0aa',
				strokeWidth: 26,
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
		<circle cx={36.68} cy={60.43} r={7.93} className='avatar47_svg__cls-3' />
		<path
			d='M64 94.37a28.31 28.31 0 0 1-28.32-28.32V47.43a28.32 28.32 0 1 1 56.64 0v18.62A28.31 28.31 0 0 1 64 94.37Z'
			className='avatar47_svg__cls-6'
		/>
		<path
			d='M64 96.38a30.36 30.36 0 0 1-30.32-30.33V47.43a30.32 30.32 0 1 1 60.64 0v18.62A30.36 30.36 0 0 1 64 96.38Z'
			className='avatar47_svg__cls-7'
		/>
		<g className='avatar47_svg__cls-8'>
			<path d='M40.26 28.61h0' className='avatar47_svg__cls-9' />
			<path
				d='M43 29.61c3.4 1 10.08 2.39 21 2.39 12.63 0 19.59-1.87 22.34-2.84'
				style={{
					strokeDasharray: '.1 2.92',
					stroke: '#393c54',
					strokeWidth: 2,
					fill: 'none',
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
				}}
			/>
			<path d='M87.7 28.63h0' className='avatar47_svg__cls-9' />
		</g>
		<g className='avatar47_svg__cls-8'>
			<path d='M46 23h0' className='avatar47_svg__cls-9' />
			<path
				d='M48.87 24A59.58 59.58 0 0 0 64 25.59c9 0 14.25-1.27 16.54-2'
				style={{
					strokeDasharray: '.1 2.94',
					stroke: '#393c54',
					strokeWidth: 2,
					fill: 'none',
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
				}}
			/>
			<path d='M81.92 23h0' className='avatar47_svg__cls-9' />
		</g>
		<path d='M74 18.82a30.09 30.09 0 0 0-20 0V60h20Z' className='avatar47_svg__cls-12' />
		<path
			d='M64 17.11a29.3 29.3 0 0 0-4 .3V58h8V17.41a29.3 29.3 0 0 0-4-.3Z'
			style={{
				opacity: 0.3,
				fill: '#fff',
			}}
		/>
		<path
			d='M95 56.75V46a2 2 0 0 0-2-2H78.25a14.21 14.21 0 0 0-11.06 5.28A5.3 5.3 0 0 0 64 48.19a5.24 5.24 0 0 0-3.19 1.1A14.19 14.19 0 0 0 49.75 44H35a2 2 0 0 0-2 2v10.75a14.24 14.24 0 0 0 10.86 13.82A11.93 11.93 0 0 0 43 75v6a21.15 21.15 0 0 0 2.37 9 30.2 30.2 0 0 0 37.26 0A21.15 21.15 0 0 0 85 81v-6a11.93 11.93 0 0 0-.86-4.43A14.24 14.24 0 0 0 95 56.75Z'
			className='avatar47_svg__cls-14'
		/>
		<path d='M54 94.68a30.26 30.26 0 0 0 20 0v-7.86H54Z' className='avatar47_svg__cls-12' />
		<path
			d='M91 48v8.75A10.25 10.25 0 0 1 80.75 67H68v-8.75A10.25 10.25 0 0 1 78.25 48H91ZM60 58.25V67H47.25A10.25 10.25 0 0 1 37 56.75V48h12.75A10.25 10.25 0 0 1 60 58.25Z'
			className='avatar47_svg__cls-15'
		/>
		<path
			d='M78.25 52H87v4.75A6.25 6.25 0 0 1 80.75 63H72v-4.75A6.25 6.25 0 0 1 78.25 52ZM56 63h-8.75A6.25 6.25 0 0 1 41 56.75V52h8.75A6.25 6.25 0 0 1 56 58.25V63Z'
			className='avatar47_svg__cls-6'
		/>
		<circle cx={78.5} cy={58.28} r={3} className='avatar47_svg__cls-12' />
		<circle cx={49.5} cy={58.28} r={3} className='avatar47_svg__cls-12' />
		<rect
			width={22}
			height={34}
			x={53}
			y={61}
			className='avatar47_svg__cls-15'
			rx={8}
			transform='rotate(90 64 78)'
		/>
		<rect width={26} height={14} x={51} y={71} className='avatar47_svg__cls-6' rx={4} />
		<path
			d='m62.84 53-5.91 13.49a1.27 1.27 0 0 0 1.16 1.78h11.82a1.27 1.27 0 0 0 1.16-1.78L65.16 53a1.27 1.27 0 0 0-2.32 0Z'
			className='avatar47_svg__cls-12'
		/>
		<path
			d='M63.27 68.12a2.52 2.52 0 1 0-5 0s0 .1 0 .15h5a.77.77 0 0 0 0-.15ZM67.25 65.6a2.52 2.52 0 0 0-2.52 2.52.77.77 0 0 0 0 .15h5v-.15a2.52 2.52 0 0 0-2.48-2.52Z'
			className='avatar47_svg__cls-16'
		/>
		<path
			d='M69.5 81h-11a3.5 3.5 0 0 1 0-7h11a3.5 3.5 0 0 1 0 7Z'
			className='avatar47_svg__cls-12'
		/>
		<path
			d='M68 77h-8a2 2 0 0 1-2-2v-1h12v1a2 2 0 0 1-2 2ZM70 81H58v-1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2Z'
			className='avatar47_svg__cls-14'
		/>
		<path
			d='M88.32 96.5v.17A22 22 0 0 1 77.92 115l-14.65 9H64a59.72 59.72 0 0 0 31.5-9V96.5Z'
			className='avatar47_svg__cls-7'
		/>
		<path
			d='m80 118.39-8.14 5.08a59.68 59.68 0 0 0 25.6-9.7V96.5h-5.14v.19A25.89 25.89 0 0 1 80 118.39Z'
			className='avatar47_svg__cls-12'
		/>
	</svg>
);
export default SvgAvatar47;
