import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionSouthAfrica = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		viewBox='0 0 9 6'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<clipPath id='regionSouthAfrica_svg__c'>
			<path id='regionSouthAfrica_svg__b' d='m0 0 4.5 3L0 6' />
		</clipPath>
		<clipPath id='regionSouthAfrica_svg__a'>
			<path d='M0 0h9v6H0z' />
		</clipPath>
		<g clipPath='url(#regionSouthAfrica_svg__a)'>
			<path fill='#001489' d='M0 0v6h9V0z' />
			<path fill='#e03c31' d='M0 0v3h9V0z' />
			<g stroke='#fff' strokeWidth={2}>
				<path id='regionSouthAfrica_svg__d' d='m0 0 4.5 3L0 6m4.5-3H9' />
				<use
					xlinkHref='#regionSouthAfrica_svg__b'
					stroke='#ffb81c'
					clipPath='url(#regionSouthAfrica_svg__c)'
				/>
			</g>
			<use
				xlinkHref='#regionSouthAfrica_svg__d'
				fill='none'
				stroke='#007749'
				strokeWidth={1.2}
			/>
		</g>
	</svg>
);
export default SvgRegionSouthAfrica;
