import * as React from 'react';
import { SVGProps } from 'react';
const SvgSportIconSnooker = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 496 496'
		className='svg-icon'
		{...props}>
		<path
			d='M496 425.3c0 41.6-9.6 61.6-51.2 61.6H242.4c-41.6 0-50.4-20-50.4-61.6V64.5c0-40.8 8.8-57.6 50.4-57.6h202.4c41.6 0 51.2 16.8 51.2 57.6v360.8z'
			style={{
				fill: '#ffcd50',
			}}
		/>
		<path
			d='M240 6.9h204.8c41.6 0 51.2 16.8 51.2 57.6v360.8c0 41.6-9.6 61.6-51.2 61.6'
			style={{
				fill: '#ef8a11',
			}}
		/>
		<path
			d='M472 393.3c0 34.4-19.2 61.6-42.4 61.6H258.4c-23.2 0-42.4-28-42.4-61.6V92.5c0-33.6 19.2-61.6 42.4-61.6h171.2c23.2 0 42.4 28 42.4 61.6v300.8z'
			style={{
				fill: '#51d687',
			}}
		/>
		<path
			d='M216 92.5c0-33.6 9.6-61.6 44-61.6h168c34.4 0 44.8 28 44.8 62.4v304c0 34.4-8 57.6-42.4 57.6'
			style={{
				fill: '#39b56b',
			}}
		/>
		<circle
			cx={228}
			cy={44.5}
			r={28.8}
			style={{
				fill: '#ffcd50',
			}}
		/>
		<circle
			cx={228}
			cy={44.5}
			r={19.2}
			style={{
				fill: '#505454',
			}}
		/>
		<path
			d='M241.6 30.9c7.2 7.2 7.2 19.2 0 26.4-7.2 7.2-19.2 7.2-26.4 0'
			style={{
				fill: '#303535',
			}}
		/>
		<circle
			cx={459.2}
			cy={44.5}
			r={28.8}
			style={{
				fill: '#ffcd50',
			}}
		/>
		<path
			d='M478.4 44.5c0 10.4-8.8 19.2-19.2 19.2-10.4 0-19.2-8.8-19.2-19.2s8.8-19.2 19.2-19.2c10.4 0 19.2 8.8 19.2 19.2z'
			style={{
				fill: '#505454',
			}}
		/>
		<path
			d='M472.8 30.9c7.2 8 7.2 19.2 0 27.2-7.2 7.2-19.2 7.2-26.4 0'
			style={{
				fill: '#303535',
			}}
		/>
		<circle
			cx={228}
			cy={245.3}
			r={28.8}
			style={{
				fill: '#ffcd50',
			}}
		/>
		<circle
			cx={228}
			cy={245.3}
			r={19.2}
			style={{
				fill: '#505454',
			}}
		/>
		<path
			d='M241.6 231.7c7.2 7.2 7.2 19.2 0 26.4-7.2 7.2-19.2 7.2-26.4 0'
			style={{
				fill: '#303535',
			}}
		/>
		<circle
			cx={459.2}
			cy={245.3}
			r={28.8}
			style={{
				fill: '#ffcd50',
			}}
		/>
		<path
			d='M478.4 245.3c0 10.4-8.8 19.2-19.2 19.2-10.4 0-19.2-8.8-19.2-19.2 0-10.4 8.8-19.2 19.2-19.2 10.4 0 19.2 8.8 19.2 19.2z'
			style={{
				fill: '#505454',
			}}
		/>
		<path
			d='M472.8 231.7c7.2 7.2 7.2 19.2 0 26.4s-19.2 7.2-26.4 0'
			style={{
				fill: '#303535',
			}}
		/>
		<circle
			cx={228}
			cy={446.1}
			r={28.8}
			style={{
				fill: '#ffcd50',
			}}
		/>
		<circle
			cx={228}
			cy={446.1}
			r={19.2}
			style={{
				fill: '#505454',
			}}
		/>
		<path
			d='M241.6 432.5c7.2 7.2 7.2 19.2 0 26.4-7.2 7.2-19.2 7.2-26.4 0'
			style={{
				fill: '#303535',
			}}
		/>
		<circle
			cx={459.2}
			cy={446.1}
			r={28.8}
			style={{
				fill: '#ffcd50',
			}}
		/>
		<path
			d='M478.4 446.1c0 10.4-8.8 19.2-19.2 19.2-10.4 0-19.2-8.8-19.2-19.2 0-10.4 8.8-19.2 19.2-19.2s19.2 8.8 19.2 19.2z'
			style={{
				fill: '#505454',
			}}
		/>
		<path
			d='M472.8 432.5c7.2 7.2 7.2 19.2 0 26.4-7.2 7.2-19.2 7.2-26.4 0'
			style={{
				fill: '#303535',
			}}
		/>
		<circle
			cx={164.8}
			cy={322.9}
			r={164.8}
			style={{
				fill: '#505454',
			}}
		/>
		<path
			d='M281.6 206.9c64.8 64.8 64.8 168.8 0 233.6s-168.8 64.8-233.6 0'
			style={{
				fill: '#303535',
			}}
		/>
		<circle
			cx={164.8}
			cy={322.9}
			r={97.6}
			style={{
				fill: '#fff',
			}}
		/>
		<path
			d='M96 254.1c38.4-38.4 100-38.4 137.6 0 38.4 38.4 38.4 100 0 137.6'
			style={{
				fill: '#e5e5e5',
			}}
		/>
		<path
			d='M120 353.3c0-15.2 8-25.6 24-31.2v-.8c-16-6.4-20-16-20-27.2 0-20.8 18.4-35.2 43.2-35.2 29.6 0 42.4 16.8 42.4 32 0 10.4-9.6 21.6-17.6 27.2v.8c8 4.8 21.6 15.2 21.6 31.2 0 22.4-18.4 37.6-48 37.6-32 0-45.6-17.6-45.6-34.4zm62.4-1.6c0-11.2-8-17.6-20-20.8-9.6 2.4-15.2 9.6-15.2 18.4s6.4 17.6 17.6 17.6c11.2.8 17.6-6.4 17.6-15.2zm-32.8-59.2c0 8.8 8 13.6 17.6 16.8 6.4-1.6 12.8-8 12.8-16s-4.8-15.2-15.2-15.2c-10.4 0-15.2 6.4-15.2 14.4z'
			style={{
				fill: '#3f4242',
			}}
		/>
		<path d='m192 318.1-48 3.2c2.4 0 0 0 0 0v.8c-16 5.6-24 16-24 31.2 0 16.8 13.6 35.2 45.6 35.2 28.8 0 48.8-15.2 48.8-37.6-.8-16.8-10.4-28-22.4-32.8zm-26.4 49.6c-11.2 0-17.6-8.8-17.6-17.6s5.6-16 15.2-18.4c11.2 3.2 20 9.6 20 20.8-.8 8-7.2 15.2-17.6 15.2z' />
	</svg>
);
export default SvgSportIconSnooker;
