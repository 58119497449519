import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomKeno = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 512 512'
		className='svg-icon'
		{...props}>
		<path
			d='M172.761 441.379c-4.495 0-10.774-2.601-13.953-5.78L41.09 317.881c-3.179-3.178-5.78-9.458-5.78-13.953V137.451c0-4.495 2.601-10.774 5.78-13.953L158.807 5.78C161.986 2.601 168.265 0 172.761 0h166.478c4.495 0 10.774 2.601 13.953 5.78L470.91 123.498c3.179 3.179 5.78 9.458 5.78 13.953v166.478c0 4.495-2.601 10.774-5.78 13.953L353.192 435.599c-3.179 3.179-9.458 5.78-13.953 5.78H172.761z'
			style={{
				fill: '#eff2fa',
			}}
		/>
		<circle
			cx={255.999}
			cy={220.69}
			r={35.31}
			style={{
				fill: '#d7deed',
			}}
		/>
		<path
			d='M338.892 17.655c.582.126 1.562.531 2.062.854l117.227 117.228c.322.499.727 1.479.853 2.059V303.58c-.126.58-.531 1.56-.853 2.06L340.953 422.869c-.5.323-1.48.728-2.062.854H173.107c-.582-.126-1.562-.531-2.062-.854L53.818 305.642c-.322-.5-.727-1.48-.853-2.06V137.798c.126-.58.531-1.56.853-2.06L171.046 18.509c.5-.323 1.48-.728 2.062-.854h165.784M339.239 0H172.76c-4.495 0-10.774 2.601-13.953 5.78L41.09 123.498c-3.179 3.178-5.78 9.457-5.78 13.952v166.478c0 4.495 2.601 10.775 5.78 13.953l117.718 117.718c3.179 3.179 9.458 5.78 13.953 5.78h166.478c4.495 0 10.774-2.601 13.953-5.78l117.717-117.718c3.179-3.178 5.78-9.457 5.78-13.953V137.45c0-4.495-2.601-10.775-5.78-13.953L353.192 5.78C350.013 2.601 343.734 0 339.239 0z'
			style={{
				fill: '#d7deed',
			}}
		/>
		<circle
			cx={317.793}
			cy={97.103}
			r={26.483}
			style={{
				fill: '#ffd782',
			}}
		/>
		<path
			d='M335.448 114.759c-14.626 0-26.483-11.857-26.483-26.483 0-6.713 2.579-12.772 6.696-17.44-13.611 1.103-24.351 12.372-24.351 26.268 0 14.626 11.857 26.483 26.483 26.483 7.913 0 14.934-3.541 19.787-9.043-.715.057-1.402.215-2.132.215z'
			style={{
				fill: '#ffc36e',
			}}
		/>
		<circle
			cx={211.862}
			cy={79.448}
			r={26.483}
			style={{
				fill: '#ffd782',
			}}
		/>
		<path
			d='M229.517 97.103c-14.626 0-26.483-11.857-26.483-26.483 0-6.713 2.579-12.772 6.696-17.44-13.611 1.103-24.351 12.372-24.351 26.268 0 14.626 11.857 26.483 26.483 26.483 7.913 0 14.934-3.541 19.787-9.043-.715.058-1.402.215-2.132.215z'
			style={{
				fill: '#ffc36e',
			}}
		/>
		<circle
			cx={388.413}
			cy={185.379}
			r={26.483}
			style={{
				fill: '#ffd782',
			}}
		/>
		<path
			d='M406.068 203.034c-14.626 0-26.483-11.857-26.483-26.483 0-6.713 2.579-12.772 6.696-17.44-13.611 1.103-24.351 12.372-24.351 26.268 0 14.626 11.857 26.483 26.483 26.483 7.913 0 14.934-3.541 19.787-9.043-.715.058-1.401.215-2.132.215z'
			style={{
				fill: '#ffc36e',
			}}
		/>
		<circle
			cx={97.103}
			cy={264.828}
			r={26.483}
			style={{
				fill: '#ffd782',
			}}
		/>
		<path
			d='M114.758 282.483c-14.626 0-26.483-11.857-26.483-26.483 0-6.713 2.579-12.772 6.696-17.44-13.611 1.103-24.351 12.372-24.351 26.268 0 14.626 11.857 26.483 26.483 26.483 7.913 0 14.934-3.541 19.787-9.043-.715.057-1.401.215-2.132.215z'
			style={{
				fill: '#ffc36e',
			}}
		/>
		<circle
			cx={123.586}
			cy={326.621}
			r={26.483}
			style={{
				fill: '#ffd782',
			}}
		/>
		<path
			d='M141.241 344.276c-14.626 0-26.483-11.857-26.483-26.483 0-6.713 2.579-12.772 6.696-17.44-13.611 1.103-24.351 12.372-24.351 26.268 0 14.626 11.857 26.483 26.483 26.483 7.913 0 14.934-3.541 19.787-9.043-.715.057-1.402.215-2.132.215z'
			style={{
				fill: '#ffc36e',
			}}
		/>
		<circle
			cx={150.068}
			cy={220.69}
			r={26.483}
			style={{
				fill: '#ffd782',
			}}
		/>
		<path
			d='M167.724 238.345c-14.626 0-26.483-11.857-26.483-26.483 0-6.713 2.579-12.772 6.696-17.44-13.611 1.103-24.351 12.372-24.351 26.268 0 14.626 11.857 26.483 26.483 26.483 7.913 0 14.934-3.541 19.787-9.043-.716.057-1.402.215-2.132.215z'
			style={{
				fill: '#ffc36e',
			}}
		/>
		<circle
			cx={185.379}
			cy={150.069}
			r={26.483}
			style={{
				fill: '#ffd782',
			}}
		/>
		<path
			d='M203.034 167.724c-14.626 0-26.483-11.857-26.483-26.483 0-6.713 2.579-12.772 6.696-17.44-13.611 1.103-24.351 12.372-24.351 26.268 0 14.626 11.857 26.483 26.483 26.483 7.913 0 14.934-3.541 19.787-9.043-.715.057-1.402.215-2.132.215z'
			style={{
				fill: '#ffc36e',
			}}
		/>
		<circle
			cx={176.551}
			cy={300.138}
			r={26.483}
			style={{
				fill: '#ffd782',
			}}
		/>
		<path
			d='M194.206 317.793c-14.626 0-26.483-11.857-26.483-26.483 0-6.713 2.579-12.772 6.696-17.44-13.611 1.103-24.351 12.372-24.351 26.268 0 14.626 11.857 26.483 26.483 26.483 7.913 0 14.934-3.541 19.787-9.043-.715.057-1.401.215-2.132.215z'
			style={{
				fill: '#ffc36e',
			}}
		/>
		<circle
			cx={238.344}
			cy={326.621}
			r={26.483}
			style={{
				fill: '#ffd782',
			}}
		/>
		<path
			d='M255.999 344.276c-14.626 0-26.483-11.857-26.483-26.483 0-6.713 2.579-12.772 6.696-17.44-13.611 1.103-24.351 12.372-24.351 26.268 0 14.626 11.857 26.483 26.483 26.483 7.913 0 14.934-3.541 19.787-9.043-.715.057-1.401.215-2.132.215z'
			style={{
				fill: '#ffc36e',
			}}
		/>
		<circle
			cx={300.137}
			cy={361.931}
			r={26.483}
			style={{
				fill: '#ffd782',
			}}
		/>
		<path
			d='M317.793 379.586c-14.626 0-26.483-11.857-26.483-26.483 0-6.713 2.579-12.772 6.696-17.44-13.611 1.103-24.351 12.372-24.351 26.268 0 14.626 11.857 26.483 26.483 26.483 7.913 0 14.934-3.541 19.787-9.043-.716.057-1.402.215-2.132.215z'
			style={{
				fill: '#ffc36e',
			}}
		/>
		<circle
			cx={176.551}
			cy={370.759}
			r={26.483}
			style={{
				fill: '#ffd782',
			}}
		/>
		<path
			d='M194.206 388.414c-14.626 0-26.483-11.857-26.483-26.483 0-6.713 2.579-12.772 6.696-17.44-13.611 1.103-24.351 12.372-24.351 26.268 0 14.626 11.857 26.483 26.483 26.483 7.913 0 14.934-3.541 19.787-9.043-.715.057-1.401.215-2.132.215z'
			style={{
				fill: '#ffc36e',
			}}
		/>
		<circle
			cx={229.517}
			cy={388.414}
			r={26.483}
			style={{
				fill: '#ffd782',
			}}
		/>
		<path
			d='M247.172 406.069c-14.626 0-26.483-11.857-26.483-26.483 0-6.713 2.579-12.772 6.696-17.44-13.611 1.103-24.351 12.372-24.351 26.268 0 14.626 11.857 26.483 26.483 26.483 7.913 0 14.934-3.541 19.787-9.043-.715.057-1.402.215-2.132.215z'
			style={{
				fill: '#ffc36e',
			}}
		/>
		<circle
			cx={282.482}
			cy={397.241}
			r={26.483}
			style={{
				fill: '#ffd782',
			}}
		/>
		<path
			d='M300.137 414.897c-14.626 0-26.483-11.857-26.483-26.483 0-6.713 2.579-12.772 6.696-17.44-13.611 1.103-24.351 12.372-24.351 26.268 0 14.626 11.857 26.483 26.483 26.483 7.913 0 14.934-3.541 19.787-9.043-.715.057-1.401.215-2.132.215z'
			style={{
				fill: '#ffc36e',
			}}
		/>
		<circle
			cx={326.62}
			cy={388.414}
			r={26.483}
			style={{
				fill: '#ffd782',
			}}
		/>
		<path
			d='M344.275 406.069c-14.626 0-26.483-11.857-26.483-26.483 0-6.713 2.579-12.772 6.696-17.44-13.611 1.103-24.351 12.372-24.351 26.268 0 14.626 11.857 26.483 26.483 26.483 7.913 0 14.934-3.541 19.787-9.043-.715.057-1.401.215-2.132.215z'
			style={{
				fill: '#ffc36e',
			}}
		/>
		<circle
			cx={97.103}
			cy={176.552}
			r={26.483}
			style={{
				fill: '#ffd782',
			}}
		/>
		<path
			d='M114.758 194.207c-14.626 0-26.483-11.857-26.483-26.483 0-6.713 2.579-12.772 6.696-17.44-13.611 1.103-24.351 12.372-24.351 26.268 0 14.626 11.857 26.483 26.483 26.483 7.913 0 14.934-3.541 19.787-9.043-.715.057-1.401.215-2.132.215z'
			style={{
				fill: '#ffc36e',
			}}
		/>
		<path
			d='M405.14 499.224 263.899 216.742c-.036-.073-.081-.138-.118-.209a8.738 8.738 0 0 0-.949-1.425 10.677 10.677 0 0 0-.574-.635 9.488 9.488 0 0 0-.372-.35c-.086-.076-.171-.152-.259-.225a8.586 8.586 0 0 0-.688-.514 8.208 8.208 0 0 0-.733-.444c-.087-.047-.17-.102-.259-.147-.085-.042-.173-.074-.259-.113-.084-.039-.168-.074-.253-.11a9.181 9.181 0 0 0-.555-.214c-.081-.028-.161-.052-.243-.077a8.044 8.044 0 0 0-.579-.162c-.085-.02-.17-.036-.256-.055a8.715 8.715 0 0 0-.569-.103c-.104-.014-.209-.024-.313-.035a8.76 8.76 0 0 0-.51-.042c-.137-.007-.274-.006-.41-.006-.137 0-.274 0-.41.006a8.76 8.76 0 0 0-.51.042c-.105.011-.21.021-.313.035a7.99 7.99 0 0 0-.569.103c-.085.018-.171.034-.256.055a9.23 9.23 0 0 0-.579.162c-.081.025-.162.05-.243.077a9.181 9.181 0 0 0-.808.324c-.086.04-.174.071-.259.113-.089.045-.172.099-.259.147-.089.049-.178.098-.265.15a8.575 8.575 0 0 0-1.415 1.033c-.127.114-.253.23-.372.35a9.19 9.19 0 0 0-.253.268c-.11.12-.218.242-.321.367a10.778 10.778 0 0 0-.51.69 9.256 9.256 0 0 0-.439.735c-.038.071-.082.137-.118.209L106.858 499.224a8.825 8.825 0 0 0 7.887 12.775 8.826 8.826 0 0 0 7.905-4.879l133.346-266.693L389.342 507.12a8.827 8.827 0 0 0 11.844 3.948 8.822 8.822 0 0 0 3.954-11.844z'
			style={{
				fill: '#959cb3',
			}}
		/>
		<path
			d='M132.413 512H85.631c-4.217 0-7.196-4.132-5.863-8.134l2.092-6.278a17.658 17.658 0 0 1 16.75-12.072h33.802a8.829 8.829 0 0 1 8.828 8.828v8.828a8.827 8.827 0 0 1-8.827 8.828zM379.589 512h46.782c4.217 0 7.196-4.132 5.863-8.134l-2.092-6.278a17.656 17.656 0 0 0-16.749-12.072H379.59a8.829 8.829 0 0 0-8.828 8.828v8.828a8.827 8.827 0 0 0 8.827 8.828z'
			style={{
				fill: '#afb9d2',
			}}
		/>
		<circle
			cx={255.999}
			cy={220.69}
			r={17.655}
			style={{
				fill: '#afb9d2',
			}}
		/>
	</svg>
);
export default SvgCustomKeno;
