import * as React from 'react';
import { SVGProps } from 'react';
const SvgSportIconTennis = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 69.447 69.447'
		className='svg-icon'
		{...props}>
		<g data-name='tennis ball'>
			<path
				fill='#b9d613'
				d='M69.439 34.724A34.719 34.719 0 1 1 34.719 0a34.724 34.724 0 0 1 34.72 34.724Z'
				data-name='Path 85'
			/>
			<path
				fill='#f7f7f7'
				d='M39.375.345a35.139 35.139 0 0 0-4.61-.344A41.069 41.069 0 0 1 .396 29.736a34.3 34.3 0 0 0-.381 4.635l.183-.026A45.921 45.921 0 0 0 39.347.464Zm29.721 34.692a45.487 45.487 0 0 0-33.488 34.054l-.071.313a34.54 34.54 0 0 0 4.818-.455 41.218 41.218 0 0 1 28.686-29.194 36.059 36.059 0 0 0 .388-4.8Z'
				data-name='Path 86'
			/>
		</g>
	</svg>
);
export default SvgSportIconTennis;
