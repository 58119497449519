import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar10 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar10_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar10_svg__cls-7{fill:#fff}.avatar10_svg__cls-3{fill:#00adfe}.avatar10_svg__cls-4{fill:#393c54}.avatar10_svg__cls-14,.avatar10_svg__cls-15,.avatar10_svg__cls-8{fill:none;stroke-linecap:round}.avatar10_svg__cls-15,.avatar10_svg__cls-8{stroke-miterlimit:10}.avatar10_svg__cls-14,.avatar10_svg__cls-15{stroke-width:2px}.avatar10_svg__cls-15,.avatar10_svg__cls-8{stroke:#4bc190}.avatar10_svg__cls-8{stroke-width:4px}.avatar10_svg__cls-14{stroke-linejoin:round}.avatar10_svg__cls-13{fill:#356cb6}.avatar10_svg__cls-14{stroke:#356cb6}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.3,
				fill: '#fff',
			}}
		/>
		<path
			d='M64 4a60.12 60.12 0 0 0-18 2.75V31a6 6 0 0 1-6 6l.08 5h47.84l.08-5a6 6 0 0 1-6-6V6.75A60.12 60.12 0 0 0 64 4Z'
			className='avatar10_svg__cls-3'
		/>
		<path d='M94 86h1a1 1 0 0 0 1-1V70a1 1 0 0 0-1-1h-1Z' className='avatar10_svg__cls-4' />
		<path
			d='M40.08 93 40 98a6 6 0 0 1 6 6v17.29a60.28 60.28 0 0 0 36 0V104a6 6 0 0 1 6-6l-.08-5Z'
			className='avatar10_svg__cls-3'
		/>
		<rect
			width={60}
			height={63}
			x={34}
			y={36}
			rx={10.03}
			style={{
				fill: '#515570',
			}}
		/>
		<rect
			width={53}
			height={50}
			x={37.5}
			y={42.5}
			className='avatar10_svg__cls-4'
			rx={5.02}
			transform='rotate(90 64 67.5)'
		/>
		<path
			d='M43 54v-2.4a6.59 6.59 0 0 1 6.58-6.6H64'
			style={{
				strokeWidth: 2,
				strokeMiterlimit: 10,
				stroke: '#fff',
				opacity: 0.1,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<rect width={5} height={12} x={92} y={49} className='avatar10_svg__cls-4' rx={1} />
		<path
			d='M56.45 83a1 1 0 0 1-.94-1.06 8.56 8.56 0 0 1 17 0 1 1 0 0 1-.96 1.06Z'
			className='avatar10_svg__cls-7'
		/>
		<path d='M64 79.38V88M61 80.38V85' className='avatar10_svg__cls-8' />
		<path
			d='M63.38 76.75H38.94a6.48 6.48 0 0 0-6.12-4.53 6.6 6.6 0 0 0-6.57 6.41A6.5 6.5 0 0 0 38.36 82h25A2.63 2.63 0 0 0 66 79.38a2.63 2.63 0 0 0-2.62-2.63Z'
			style={{
				opacity: 0.5,
				fill: '#fff',
			}}
		/>
		<path
			d='M63.38 77.75H38.21l-.22-.7a5.5 5.5 0 0 0-2.12-2.85 13.48 13.48 0 0 1-3 4.52 21.84 21.84 0 0 1-4.53 3.2 5.67 5.67 0 0 0 .53.66 5.49 5.49 0 0 0 8.66-1.09l.29-.49h25.6a1.63 1.63 0 0 0 0-3.25Z'
			style={{
				fill: '#f85565',
			}}
		/>
		<path
			d='M63.38 79h-25.6l-.29.49a5.47 5.47 0 0 1-7.93 1.7c-.41.25-.83.5-1.26.73a5.67 5.67 0 0 0 .53.66 5.49 5.49 0 0 0 8.66-1.09l.29-.49h25.6a1.62 1.62 0 0 0 1.27-2.62 1.65 1.65 0 0 1-1.27.62Z'
			style={{
				opacity: 0.2,
				fill: '#515570',
			}}
		/>
		<ellipse
			cx={30.29}
			cy={76.29}
			className='avatar10_svg__cls-7'
			rx={2.79}
			ry={0.96}
			transform='rotate(-45 30.3 76.29)'
		/>
		<path d='M58.49 63h-10a5 5 0 0 1 10 0Z' className='avatar10_svg__cls-7' />
		<path d='M56.16 62.83h-5.33a2.67 2.67 0 0 1 5.33 0Z' className='avatar10_svg__cls-4' />
		<path
			d='M48 63h11'
			style={{
				strokeLinejoin: 'round',
				stroke: '#00adfe',
				strokeWidth: 2,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={75.83} cy={62.83} r={5.62} className='avatar10_svg__cls-13' />
		<path
			d='M77.83 72.83a2 2 0 0 1-4 0c0-1.1.9-3 2-3s2 1.9 2 3Z'
			className='avatar10_svg__cls-13'
		/>
		<circle cx={75.83} cy={60.83} r={4.92} className='avatar10_svg__cls-7' />
		<circle cx={75.83} cy={60.83} r={2.67} className='avatar10_svg__cls-4' />
		<path
			d='M93 18.07a3.61 3.61 0 0 1 0 5l-.17.17a3.6 3.6 0 0 0 0 5.07l.12.13a3.59 3.59 0 0 1 0 5.07l-.12.12'
			className='avatar10_svg__cls-14'
		/>
		<path
			d='M35.7 28.66a3.59 3.59 0 0 1 0-5l.17-.17a3.62 3.62 0 0 0 0-5.08l-.12-.12'
			className='avatar10_svg__cls-15'
		/>
		<path
			d='m100.64 39.64 2.72 2.72M100.64 42.36l2.72-2.72M20.64 32.64l2.72 2.72M20.64 35.36l2.72-2.72M87.64 106.64l2.72 2.72M87.64 109.36l2.72-2.72'
			className='avatar10_svg__cls-14'
		/>
		<circle cx={104} cy={31} r={2} className='avatar10_svg__cls-15' />
		<circle cx={27} cy={47} r={2} className='avatar10_svg__cls-15' />
	</svg>
);
export default SvgAvatar10;
