import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomFirst = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		fill='none'
		viewBox='0 0 20 21'
		className='svg-icon'
		{...props}>
		<path
			fill='#5D6559'
			fillRule='evenodd'
			d='m17 6.333-7-4.166-7 4.166v8.334l7 4.166 7-4.166V6.333Z'
			clipRule='evenodd'
		/>
		<path
			fill='#E9D18C'
			fillRule='evenodd'
			d='M10 2.167 3 6.333v8.334l7 4.166 7-4.166V6.333l-7-4.166Zm6.583 4.403L10 2.652 3.417 6.57v7.86L10 18.348l6.583-3.918V6.57Z'
			clipRule='evenodd'
		/>
		<path
			fill='#E9D18C'
			d='M9.258 14.277c0 .117.094.223.224.223h1.306c.118 0 .212-.106.212-.223V6.724c0-.118-.094-.224-.212-.224H9.705L7.987 7.841a.331.331 0 0 0-.07.177v.74c0 .118.094.224.212.224h1.13v5.295Z'
		/>
		<path fill='#CBC9B5' d='m20 5.667-2.083.583v2.333A2.852 2.852 0 0 0 20 5.837v-.17Z' />
		<path fill='#A1A498' d='m20 8.333-2.083.584v2.333A2.852 2.852 0 0 0 20 8.503v-.17Z' />
		<path fill='#5D6559' d='m20 11-2.083.583v2.334A2.852 2.852 0 0 0 20 11.17V11Z' />
		<path fill='#CBC9B5' d='m0 5.667 2.083.583v2.333A2.852 2.852 0 0 1 0 5.837v-.17Z' />
		<path fill='#A1A498' d='m0 8.333 2.083.584v2.333A2.852 2.852 0 0 1 0 8.503v-.17Z' />
		<path fill='#5D6559' d='m0 11 2.083.583v2.334A2.852 2.852 0 0 1 0 11.17V11Z' />
	</svg>
);
export default SvgCustomFirst;
