import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar33 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar33_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar33_svg__cls-3{fill:#515570}.avatar33_svg__cls-4{fill:#393c54}.avatar33_svg__cls-12,.avatar33_svg__cls-15,.avatar33_svg__cls-16,.avatar33_svg__cls-7{fill:none;stroke-linecap:round}.avatar33_svg__cls-15,.avatar33_svg__cls-16,.avatar33_svg__cls-7{stroke-width:2px}.avatar33_svg__cls-12,.avatar33_svg__cls-15,.avatar33_svg__cls-16,.avatar33_svg__cls-7{stroke-linejoin:round;stroke:#515570}.avatar33_svg__cls-11{fill:#8f5653;opacity:.7}.avatar33_svg__cls-12{stroke-width:2.22px}.avatar33_svg__cls-13{fill:#f85565}.avatar33_svg__cls-14{fill:#fff}.avatar33_svg__cls-15{opacity:.1}.avatar33_svg__cls-16{opacity:.5}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#a7aece',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.2,
				fill: '#515570',
			}}
		/>
		<path
			d='M31 101.76v-54a33 33 0 0 1 66 0v54c0 18.34-66 18.34-66 0Z'
			className='avatar33_svg__cls-3'
		/>
		<path
			d='M64 124a59.77 59.77 0 0 0 39.83-15.14l-2.23-5.22c-1.71-4-6.22-6.64-11.29-6.64H37.69c-5.07 0-9.58 2.66-11.29 6.64l-2.23 5.22A59.77 59.77 0 0 0 64 124Z'
			className='avatar33_svg__cls-4'
		/>
		<path
			d='M77.43 98.73a13.43 13.43 0 0 1-26.86 0c0-5 6-3.25 13.43-3.25s13.43-1.8 13.43 3.25Z'
			className='avatar33_svg__cls-4'
		/>
		<path
			d='M78.43 98.73c0 7.42-7 13.43-14.43 13.43s-14.43-6-14.43-13.43'
			style={{
				strokeWidth: 2,
				stroke: '#393c54',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M64 89.75v8.75'
			style={{
				strokeLinejoin: 'round',
				stroke: '#efb19e',
				strokeWidth: 20,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='m54 95.26 8.68 8a2 2 0 0 0 2.7 0l8.68-8 4.4 14.53-17.26 6.88-12.34-6.84-3.16-4.39Z'
			className='avatar33_svg__cls-4'
		/>
		<path d='m76.78 96.5-11.61 17.38M51.17 96.5l11.61 17.38' className='avatar33_svg__cls-7' />
		<path
			d='M64 94.37c-10 0-28.32-12.67-28.32-28.32V47.43a28.32 28.32 0 1 1 56.64 0v18.62C92.32 81.7 74 94.37 64 94.37Z'
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<path
			d='M67.5 113.88h-2.33v-2.34h-2.34v2.34H60.5v2.33h2.33v5.25h2.34v-5.25h2.33v-2.33z'
			style={{
				stroke: '#fff',
				strokeWidth: '1.5px',
				strokeLinejoin: 'round',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M94.72 102.72s-.06-37-.06-55.29c0-19.69-15.62-30.32-30.32-30.32a1.86 1.86 0 0 0-.34 0 1.86 1.86 0 0 0-.34 0c-14.7 0-30.32 10.63-30.32 30.32 0 18.25-.31 55.29-.31 55.29l4 8.53a1.56 1.56 0 0 0 3-.7V54c0-7.19 4.6-14.06 11.47-17.5L64 30.25l12.53 6.27C83.4 40 88 46.84 88 54v56.28a1.56 1.56 0 0 0 3 .67Z'
			className='avatar33_svg__cls-3'
		/>
		<path
			d='M68 69.21c0 3.4-1.79 6.16-4 6.16s-4-2.76-4-6.16 1.79-8.7 4-8.7 4 5.29 4 8.7Z'
			style={{
				fill: '#fbc0aa',
			}}
		/>
		<circle cx={75.5} cy={62.05} r={8} className='avatar33_svg__cls-11' />
		<circle cx={75.5} cy={62.05} r={8} className='avatar33_svg__cls-12' />
		<circle cx={52.5} cy={62.05} r={8} className='avatar33_svg__cls-11' />
		<circle cx={52.5} cy={62.05} r={8} className='avatar33_svg__cls-12' />
		<path
			d='M72 79H56v13.43a20.94 20.94 0 0 0 8 1.94 20.94 20.94 0 0 0 8-1.94Z'
			className='avatar33_svg__cls-13'
		/>
		<path
			d='M71.55 78a1 1 0 0 1 .94 1.07 8.56 8.56 0 0 1-17 0 1 1 0 0 1 .96-1.07Z'
			className='avatar33_svg__cls-4'
		/>
		<path d='M58 78h2v2a1 1 0 0 1-1 1 1 1 0 0 1-1-1v-2Z' className='avatar33_svg__cls-14' />
		<path d='M70 78h-2v4a1 1 0 0 0 1 1 1 1 0 0 0 1-1Z' className='avatar33_svg__cls-13' />
		<path d='M68 78h2v2a1 1 0 0 1-1 1 1 1 0 0 1-1-1v-2Z' className='avatar33_svg__cls-14' />
		<path d='M68 59h-8' className='avatar33_svg__cls-12' />
		<path d='m74 77-4-4M54 77l4-4' className='avatar33_svg__cls-15' />
		<path
			d='M70.48 52.63a5 5 0 0 1 4.11-3.45l6.93-1M57.52 52.63a5 5 0 0 0-4.11-3.45l-6.93-1'
			className='avatar33_svg__cls-16'
		/>
		<path
			d='M40 43v30a4 4 0 0 0 4 4h0'
			style={{
				strokeWidth: 4,
				stroke: '#515570',
				strokeLinejoin: 'round',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
	</svg>
);
export default SvgAvatar33;
