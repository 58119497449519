import * as React from 'react';
import { SVGProps } from 'react';
const SvgXbp = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#21AF67' />
			<path
				fill='#FFF'
				d='M16 28C9.373 28 4 22.627 4 16S9.373 4 16 4s12 5.373 12 12-5.373 12-12 12zm4.613-16.349c-.176-.24-.463-.435-.857-.587-.393-.151-.934-.227-1.62-.227h-1.45l1.177-3.235.785-2.157a10.867 10.867 0 0 0-2.41-.321c-.08 0-.16-.006-.24-.006a8.99 8.99 0 0 0-.368.01C9.801 5.322 5.118 10.122 5.118 16c0 3.133 1.352 6.113 3.71 8.174l.853-2.345A8.571 8.571 0 0 1 7.396 16c0-4.328 3.212-7.917 7.377-8.515.206-.03.419-.05.63-.068l-3.142 8.634 6.269-.617a.064.064 0 0 0-.01-.004c.195-.063.382-.146.56-.25a4.084 4.084 0 0 0 1.185-1.027c.178-.23.32-.488.418-.763.11-.294.173-.604.186-.917a1.277 1.277 0 0 0-.256-.822zm-2.674 2.105c-.137.372-.35.634-.645.785a1.943 1.943 0 0 1-.897.228h-1.13l.758-2.084h1.13c.135 0 .27.017.4.052a.727.727 0 0 1 .32.168.517.517 0 0 1 .151.33 1.16 1.16 0 0 1-.087.521zM19.2 5.598l-.781 2.147a8.603 8.603 0 1 1-5.76 16.183 8.703 8.703 0 0 1-.519-.243l.892-2.445h1.448c1.312 0 2.388-.222 3.23-.668.841-.445 1.44-1.156 1.797-2.134.131-.362.18-.68.141-.954a1.732 1.732 0 0 0-.242-.704 1.531 1.531 0 0 0-.46-.47 2.652 2.652 0 0 0-.46-.238l-6.326.624-2.035 5.581-.83 2.284a10.936 10.936 0 0 0 2.584 1.508c1.307.538 2.707.814 4.121.813 6 0 10.882-4.881 10.882-10.882 0-4.887-3.238-9.032-7.682-10.402zm-4.64 11.475h1.32c.158.001.315.016.47.045.15.024.295.079.424.16.12.082.206.204.242.344.042.151.02.349-.069.594a1.73 1.73 0 0 1-.376.631c-.142.147-.31.266-.496.352a1.91 1.91 0 0 1-.54.154 3.804 3.804 0 0 1-.497.036h-1.323l.845-2.316z'
			/>
		</g>
	</svg>
);
export default SvgXbp;
