import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomThird = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		fill='none'
		viewBox='0 0 20 21'
		className='svg-icon'
		{...props}>
		<path
			fill='#636B6B'
			fillRule='evenodd'
			d='m17 6.333-7-4.166-7 4.166v8.334l7 4.166 7-4.166V6.333Z'
			clipRule='evenodd'
		/>
		<path
			fill='#FDE6CA'
			fillRule='evenodd'
			d='M10 2.167 3 6.333v8.334l7 4.166 7-4.166V6.333l-7-4.166Zm6.583 4.403L10 2.652 3.417 6.57v7.86L10 18.348l6.583-3.918V6.57Z'
			clipRule='evenodd'
		/>
		<path
			fill='#D1C3AF'
			d='m20 9-2.083.583v2.334A2.852 2.852 0 0 0 20 9.17V9ZM0 9l2.083.583v2.334A2.853 2.853 0 0 1 0 9.17V9Z'
		/>
		<path
			fill='#FDE6CA'
			d='M10 14.5c1.876 0 3.083-.94 3.083-2.276 0-1.16-1.031-1.776-1.829-1.857.868-.152 1.712-.79 1.712-1.765 0-1.29-1.125-2.102-2.954-2.102-1.372 0-2.357.522-2.99 1.23l.833 1.034a2.828 2.828 0 0 1 1.98-.801c.821 0 1.455.302 1.455.917 0 .57-.575.825-1.442.825-.293 0-.833 0-.973-.012v1.486c.117-.011.644-.023.973-.023 1.09 0 1.57.279 1.57.894 0 .58-.527.987-1.477.987-.762 0-1.617-.325-2.157-.882l-.867 1.103C7.49 13.954 8.558 14.5 10 14.5Z'
		/>
	</svg>
);
export default SvgCustomThird;
