import * as React from 'react';
import { SVGProps } from 'react';
const SvgNetworkTrx = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		id='networkTRX_svg___x3C_Layer_x3E_'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<style>{'.networkTRX_svg__st11{fill:#fff}'}</style>
		<g id='networkTRX_svg__Tron_x2C__crypto'>
			<g id='networkTRX_svg__XMLID_140_'>
				<circle
					id='networkTRX_svg__XMLID_22_'
					cx={16}
					cy={16}
					r={14.5}
					style={{
						fill: '#ff5252',
					}}
				/>
			</g>
			<g id='networkTRX_svg__XMLID_128_'>
				<g id='networkTRX_svg__XMLID_138_'>
					<path
						id='networkTRX_svg__XMLID_750_'
						d='M16 31C7.73 31 1 24.27 1 16S7.73 1 16 1s15 6.73 15 15-6.73 15-15 15zm0-29C8.28 2 2 8.28 2 16s6.28 14 14 14 14-6.28 14-14S23.72 2 16 2z'
						style={{
							fill: '#455a64',
						}}
					/>
				</g>
				<g id='networkTRX_svg__XMLID_81_'>
					<g id='networkTRX_svg__XMLID_119_'>
						<path
							id='networkTRX_svg__XMLID_749_'
							d='M17 15.3c-.24 0-.46-.18-.49-.43-.04-.27.15-.53.42-.57l8.5-1.26c.27-.03.53.15.57.42.04.27-.15.53-.42.57l-8.5 1.26c-.03 0-.06.01-.08.01z'
							className='networkTRX_svg__st11'
						/>
					</g>
					<g id='networkTRX_svg__XMLID_118_'>
						<path
							id='networkTRX_svg__XMLID_748_'
							d='M17 15.3c-.11 0-.22-.04-.32-.11l-8.5-6.93a.494.494 0 0 1-.07-.7c.18-.21.49-.25.7-.07l8.5 6.93a.494.494 0 0 1-.31.88z'
							className='networkTRX_svg__st11'
						/>
					</g>
					<g id='networkTRX_svg__XMLID_117_'>
						<path
							id='networkTRX_svg__XMLID_747_'
							d='M15.43 26.63h-.07a.51.51 0 0 1-.43-.56l1.57-11.33c.02-.12.08-.23.17-.31l5.16-4.53c.21-.18.52-.16.71.05.18.21.16.52-.05.71l-5.02 4.41-1.55 11.13c-.03.25-.25.43-.49.43z'
							className='networkTRX_svg__st11'
						/>
					</g>
					<g id='networkTRX_svg__XMLID_116_'>
						<path
							id='networkTRX_svg__XMLID_744_'
							d='M15.43 26.63c-.03 0-.05 0-.08-.01a.495.495 0 0 1-.39-.32L8.03 8.05a.528.528 0 0 1 .08-.5c.11-.14.3-.2.47-.17l13.66 2.39c.1.02.19.06.26.14l3.34 3.27c.18.18.2.47.04.67L15.82 26.44c-.1.12-.24.19-.39.19zM9.28 8.51l6.3 16.62 9.24-11.56-2.9-2.85L9.28 8.51z'
							className='networkTRX_svg__st11'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
);
export default SvgNetworkTrx;
