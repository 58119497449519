import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionBelarus = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		viewBox='0 0 900 450'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<rect width='100%' height='100%' fill='#D22730' />
		<path fill='#fff' d='M5 0h100v450H5z' />
		<g id='regionBelarus_svg__b'>
			<path
				id='regionBelarus_svg__a'
				fill='#D22730'
				d='M572 0v163h-44V0zM10 285h79v-65h40v-79h56V72h46V0h139v72h45v69h56v79h40v65h61v101h-61v65h-40v79h-56v69h-45v74h-32v62h-75v-62h-32v-74h-46v-69h-56v-79H89v-65H10m0-352h79v90H10m310 27h-41v61h-39v77h-38v95h38v77h39v60h41v-60h39v-77h39v-95h-39v-77h-39zm4 139v90h-49v-90zM10 526h79v97H10m562-88v137h-44V535zM464 687v68h48v70h60v92h-60v71h-48v68h-56v-68h-48v-71h-40v-92h40v-70h48v-68zM10 825h79v-70h48v-68h55v68h48v70h40v92h-40v71h-48v68h-55v-68H89v-71H10m447-90h-41v87h41zm-271 0h-41v87h41zm16 1441v-47h-34v-71h-45v-43H89v-69H10v-160h79v46h34v67h45v80h64v-72h47v-53h34v-63h45v-67h33v-68h32v-70h43v-94h-57v-85h-62v-51H243v74h60v67h-40v82h-47v72h-59v-72h-49v-82H78v-67H10v-127h79v-82h30v-63h28v-72h40v-71h39v-47h47v-71h57v71h47v47h40v71h39v72h32v62h32v54h52v397h-59v67h-17v70h-44v68h-27v50h-28v53h-48v52h-69v145m292-1213v137h-44v-137zm-562 26h79v97H10m0 486h79v97H10zm560 246v88h-42v-88zm-58 358v-55h-26v-43h-38v-129h38v52h40v76h44v98m-214 0v-64h42v64'
				transform='scale(.1)'
			/>
			<use xlinkHref='#regionBelarus_svg__a' transform='matrix(-1 0 0 1 110 0)' />
		</g>
		<use xlinkHref='#regionBelarus_svg__b' transform='matrix(1 0 0 -1 0 450)' />
		<path fill='#009739' d='M105 300h795v150H105z' />
	</svg>
);
export default SvgRegionBelarus;
