import * as React from 'react';
import { SVGProps } from 'react';
const SvgPre = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#3A8CBD' />
			<g fill='#FFF'>
				<path d='M13.47 18.045h1.678a3.23 3.23 0 0 0 1.387-.308c.41-.203.776-.484 1.077-.828.299-.356.53-.763.684-1.201a3.92 3.92 0 0 0 .248-1.388 3.393 3.393 0 0 0-.27-1.346 4.143 4.143 0 0 0-.724-1.18 3.636 3.636 0 0 0-1.098-.848 2.974 2.974 0 0 0-1.384-.332h-4.41v10.703h2.816l-.004-3.272zm0-4.846h1.427a.77.77 0 0 1 .518.249c.166.165.27.456.27.911s-.103.725-.25.872c-.123.145-.289.248-.475.248h-1.49V13.2z' />
				<path d='M7.023 8.03v15.888c0 .57.461 1.03 1.03 1.03h15.888c.569 0 1.03-.46 1.03-1.03V8.03a1.03 1.03 0 0 0-1.03-1.031H8.053a1.03 1.03 0 0 0-1.03 1.03zm14.803 14.804h-11.68a1.03 1.03 0 0 1-1.031-1.03v-11.68a1.03 1.03 0 0 1 1.031-1.031h11.68c.57 0 1.03.461 1.03 1.03v11.68c0 .57-.46 1.031-1.03 1.031z' />
				<path d='M14.733 19.309h6.607v2.03h-6.607z' />
			</g>
		</g>
	</svg>
);
export default SvgPre;
