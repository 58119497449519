import * as React from 'react';
import { SVGProps } from 'react';
const SvgPac = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#F5EB16' />
			<path
				fill='#FFF'
				d='M15.935 4C9.342 4.001 4 9.347 4 15.94c.001 6.592 5.347 11.936 11.94 11.935 6.592-.002 11.936-5.347 11.935-11.94A11.937 11.937 0 0 0 15.935 4zm-4.847 5.23a.123.123 0 0 1 .125-.12h4.973a6.98 6.98 0 0 1 3.027.628c.18.088.357.186.528.292l-3.237 1.853h-1.689a.123.123 0 0 0-.12.126v.932l-3.607 2.086V9.23zM9.749 19.708l-2.162-2.184 7.12-4.108v2.662a.123.123 0 0 0 .125.12h1.017l-6.093 3.51h-.007zm10.662-2.03c-1.044.872-2.477 1.308-4.299 1.308h-1.297a.123.123 0 0 0-.123.123v2.688l-1.81 1.042-1.69.977-.107.06v-4.455l5.603-3.224a2.056 2.056 0 0 0 1.297-.513 1.74 1.74 0 0 0 .318-.432l3.578-2.071c.06.328.09.66.089.994.004 1.463-.516 2.63-1.56 3.503zm-1.92-3.019c.024-.153.036-.309.037-.464a2.417 2.417 0 0 0-.563-1.674A1.823 1.823 0 0 0 17.15 12l4.973-2.871 2.182 2.183-5.814 3.347z'
			/>
		</g>
	</svg>
);
export default SvgPac;
