import React, { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeContextProvider } from "./context/ThemeContext";
import { SportWebSocketProvider } from "./context/SportWebSocketProvider";
import "./i18n";
import { Provider } from "react-redux";
import { store } from "./store";

import { CouponContextProvider } from "./context/CouponContext";
import DynamicStyles from "./DynamicStyle";
import { SocketContextProvider } from "./context/SocketContext";
import { AuthContextProvider } from "./context/AuthContext";
import LoadingSpinner from "./component/LoadingSpinner";
import { SiteContextProvider } from "./context/SiteContext";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const LOADING = (
  <div className="vh-100 vw-100">
    <LoadingSpinner loading backdrop />
  </div>
);

root.render(
  <Provider store={store}>
    <SiteContextProvider>
      <SocketContextProvider>
        <SportWebSocketProvider url="wss://eu-swarm-newm.betcoswarm.com">
          <AuthContextProvider>
            <CouponContextProvider>
              <ThemeContextProvider>
                <Router>
                  <DynamicStyles />
                  {/* <StrictMode> */}
                  <Suspense fallback={LOADING}>
                   
                    <App />
                  </Suspense>
                  {/* </StrictMode> */}
                </Router>
              </ThemeContextProvider>
            </CouponContextProvider>
          </AuthContextProvider>
        </SportWebSocketProvider>
      </SocketContextProvider>
    </SiteContextProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
