import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomCup = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		preserveAspectRatio='xMidYMid'
		viewBox='0 0 256 256'
		className='svg-icon'
		{...props}>
		<defs>
			<linearGradient
				id='CustomCup_svg__linear-gradient-1'
				x1={128}
				x2={128}
				y1={172}
				y2={32}
				gradientUnits='userSpaceOnUse'>
				<stop offset={0} stopColor='#fc9502' />
				<stop offset={1} stopColor='#fcbe02' />
			</linearGradient>
			<style>
				{'.CustomCup_svg__cls-3{fill:#fcc402}.CustomCup_svg__cls-4{fill:#fce202}'}
			</style>
		</defs>
		<g id='CustomCup_svg__cup'>
			<path
				id='CustomCup_svg__path-1'
				fillRule='evenodd'
				d='M63 224c26.509 0 48-21.49 48-48v-22h34v22c0 26.51 22 48 48 48H63Z'
				className='CustomCup_svg__cls-3'
			/>
			<path
				id='CustomCup_svg__path-2'
				fillRule='evenodd'
				d='M193 256H63c-8.837 0-16-7.164-16-16 0-8.837 7.163-16 16-16h130c8.836 0 16 7.163 16 16 0 8.836-7.164 16-16 16Z'
				className='CustomCup_svg__cls-4'
			/>
			<path
				id='CustomCup_svg__path-3'
				fillRule='evenodd'
				d='M248 62h-12c-18.778 0-35 15.222-35 34h-6V62c2.78-13.693 14.486-24 29-24h24a8 8 0 0 1 8 8v8a8 8 0 0 1-8 8ZM20 62H8a8 8 0 0 1-8-8v-8a8 8 0 0 1 8-8h24c14.513 0 26.22 10.307 29 24v34h-6c0-18.778-16.222-34-35-34Z'
				className='CustomCup_svg__cls-4'
			/>
			<path
				id='CustomCup_svg__path-4'
				fillRule='evenodd'
				d='M54 32h148v66c0 40.869-33.131 74-74 74s-74-33.131-74-74V32Z'
				style={{
					fill: 'url(#CustomCup_svg__linear-gradient-1)',
				}}
			/>
			<path
				id='CustomCup_svg__path-5'
				fillRule='evenodd'
				d='M52 0h152a8 8 0 0 1 8 8v8a8 8 0 0 1-8 8H52a8 8 0 0 1-8-8V8a8 8 0 0 1 8-8Z'
				className='CustomCup_svg__cls-3'
			/>
			<path
				id='CustomCup_svg__path-6'
				fillRule='evenodd'
				d='M162.856 90.349c-5.67 5.904-12.992 13.875-12.992 13.875s1.257 9.931 2.24 17.919c1.018 8.265-3.568 7.51-8.553 5.264-7.182-3.236-15.551-7.295-15.551-7.295s-8.369 4.059-15.551 7.295c-4.985 2.246-9.572 3.001-8.554-5.264.984-7.988 2.241-17.919 2.241-17.919s-7.322-7.971-12.992-13.875c-2.872-2.99-3.642-7.065 2.495-8.214 8.065-1.511 18.848-3.622 18.848-3.622s4.636-10.552 8.774-17.728c2.125-3.686 7.353-3.686 9.478 0 4.138 7.176 8.774 17.728 8.774 17.728s10.783 2.111 18.848 3.622c6.136 1.149 5.367 5.224 2.495 8.214Z'
				style={{
					fill: '#fff',
				}}
			/>
		</g>
	</svg>
);
export default SvgCustomCup;
