import * as React from 'react';
import { SVGProps } from 'react';
const SvgSportIconIceHockey = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		aria-hidden='true'
		className='svg-icon'
		viewBox='0 0 36 36'
		{...props}>
		<path
			fill='#F4900C'
			d='M36 3c-1 1.295-3.027 3.803-4.391 5.671-3.816 5.225-7.156 6.454-10.328 7.632-3.172 1.178-10.407 1.029-13.88.854-3.473-.175-5.735-.579-6.42.415-2.102 3.053-.612 6.481 2.426 6.949 2.378.366 9.544-.32 12.899-.616 3.356-.297 7.024-1.301 8.283-1.785 1.259-.483 2.279-.88 2.597-1.644.318-.765 1.876-2.817 3.783-5.917C32.045 12.811 35 9.55 36 8V3z'
		/>
		<path
			fill='#31373D'
			d='M7 24.591c3-.114 7.055-.487 9.309-.686 2.289-.202 4.704-.732 6.419-1.203l-.424-6.784c-.344.132-.686.259-1.024.385-3.172 1.178-10.405 1.029-13.877.854-.141-.008-.403-.017-.403-.025v7.459zM17 28v4c0 1.104 3.135 2 7 2 3.866 0 7-.896 7-2v-4H17z'
		/>
		<ellipse cx={23.996} cy={28} fill='#66757F' rx={7} ry={2} />
	</svg>
);
export default SvgSportIconIceHockey;
