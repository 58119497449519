import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar18 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar18_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar18_svg__cls-15,.avatar18_svg__cls-5{opacity:.3}.avatar18_svg__cls-16,.avatar18_svg__cls-3,.avatar18_svg__cls-4,.avatar18_svg__cls-5{fill:none;stroke-linecap:round}.avatar18_svg__cls-3,.avatar18_svg__cls-4{stroke:#4bc190}.avatar18_svg__cls-16,.avatar18_svg__cls-3,.avatar18_svg__cls-4,.avatar18_svg__cls-5{stroke-linejoin:round}.avatar18_svg__cls-3,.avatar18_svg__cls-5{stroke-width:2.42px}.avatar18_svg__cls-4{stroke-width:2.5px}.avatar18_svg__cls-5{stroke:#356cb6}.avatar18_svg__cls-10,.avatar18_svg__cls-15{fill:#f85565}.avatar18_svg__cls-14{fill:#393c54}.avatar18_svg__cls-16{stroke:#393c54;stroke-width:2px}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.3,
				fill: '#fff',
			}}
		/>
		<path
			d='m38.77 76.14 4 2.86-4 2.86M38.77 60.14l4 2.86-4 2.86M38.77 44.14l4 2.86-4 2.86'
			className='avatar18_svg__cls-3'
		/>
		<path d='m46.67 29.5.83 5-5-.83' className='avatar18_svg__cls-4' />
		<path
			d='m89.53 76.14-4 2.86 4 2.86M89.53 60.14l-4 2.86 4 2.86M90.53 44.14l-4 2.86 4 2.86'
			className='avatar18_svg__cls-3'
		/>
		<path d='m81.63 29.5-.84 5 5-.83' className='avatar18_svg__cls-4' />
		<path
			d='m89.53 76.14-4 2.86 4 2.86M89.53 60.14l-4 2.86 4 2.86M90.53 44.14l-4 2.86 4 2.86'
			className='avatar18_svg__cls-5'
		/>
		<path
			d='m81.63 29.5-.84 5 5-.83'
			style={{
				stroke: '#356cb6',
				strokeWidth: '2.5px',
				strokeLinejoin: 'round',
				fill: 'none',
				strokeLinecap: 'round',
				opacity: 0.3,
			}}
		/>
		<path
			d='M64 109a22 22 0 0 1-22-22V49a22 22 0 0 1 44 0v38a22 22 0 0 1-22 22Z'
			style={{
				fill: '#4bc190',
			}}
		/>
		<path
			d='M48 49a16 16 0 0 1 16-16'
			style={{
				opacity: 0.2,
				stroke: '#fff',
				strokeMiterlimit: 10,
				strokeWidth: 4,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M76.27 30.73A22 22 0 0 1 80 43v31a22 22 0 0 1-34.27 18.27A22 22 0 0 0 64 102c12.15 0 22-2.85 22-15V49a22 22 0 0 0-9.73-18.27Z'
			style={{
				fill: '#356cb6',
				opacity: 0.3,
			}}
		/>
		<path
			d='M63.5 21.17a3.29 3.29 0 0 0-4.88.24 3.38 3.38 0 0 0 .31 4.52l4 4a.78.78 0 0 0 1.1 0l4-4a3.38 3.38 0 0 0 .31-4.52 3.29 3.29 0 0 0-4.84-.24Z'
			className='avatar18_svg__cls-10'
		/>
		<path
			d='M40 119a60 60 0 0 0 47 .45L90 93H37Z'
			style={{
				fill: '#8f5653',
			}}
		/>
		<rect
			width={62}
			height={11}
			x={33}
			y={88}
			rx={2}
			style={{
				fill: '#b56b63',
			}}
		/>
		<path
			d='M93 88H35a2 2 0 0 0-2 2v3a2 2 0 0 1 2-2h58a2 2 0 0 1 2 2v-3a2 2 0 0 0-2-2Z'
			style={{
				fill: '#db7a72',
				opacity: 0.5,
			}}
		/>
		<path
			d='M71.55 66.34a1 1 0 0 1 .94 1.08 8.54 8.54 0 0 1-17 0 1 1 0 0 1 .94-1.08Z'
			className='avatar18_svg__cls-14'
		/>
		<path
			d='M64 69a9.08 9.08 0 0 0-6.6 2.84 8.47 8.47 0 0 0 13.2 0A9.08 9.08 0 0 0 64 69Z'
			className='avatar18_svg__cls-10'
		/>
		<ellipse cx={53} cy={63.31} className='avatar18_svg__cls-15' rx={3} ry={2.02} />
		<ellipse cx={75} cy={63.31} className='avatar18_svg__cls-15' rx={3} ry={2.02} />
		<ellipse cx={75} cy={58.25} className='avatar18_svg__cls-14' rx={2} ry={2.02} />
		<path
			d='M57.65 57.16c-.61-2.73-2.84-2.46-5.54-1.84s-4.82 1.35-4.21 4.08a5 5 0 1 0 9.75-2.24Z'
			className='avatar18_svg__cls-14'
		/>
		<path d='m55 56.23 28-19.22M49 58.25h-7' className='avatar18_svg__cls-16' />
		<path
			d='M61 78h6'
			style={{
				stroke: '#515570',
				strokeWidth: 2,
				opacity: 0.2,
				strokeLinejoin: 'round',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
	</svg>
);
export default SvgAvatar18;
