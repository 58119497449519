import * as React from 'react';
import { SVGProps } from 'react';
const SvgEos = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='currentColor' fillRule='nonzero' />
			<path
				stroke='#FFF'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth={0.64}
				d='M10.886 11.61 16 27.667l-7.588-4.754 2.474-11.303L16 4.624v4.9L8.412 22.913h15.183L16.007 9.524v-4.9l5.113 6.986 2.475 11.303-7.588 4.754L21.12 11.61'
			/>
		</g>
	</svg>
);
export default SvgEos;
