import * as React from 'react';
import { SVGProps } from 'react';
const SvgSportIconAmericanFootball = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		aria-hidden='true'
		className='svg-icon'
		viewBox='0 0 64 64'
		{...props}>
		<g fill='#89664c'>
			<path d='M2.8 40.3C.7 52 3.2 57 5.1 58.9s7 4.4 18.6 2.3c-4.3-2.3-8.3-5.3-12-8.9-3.6-3.6-6.6-7.7-8.9-12M61.1 23.8c2.1-11.6.3-17.4-1.6-19.3-1.9-1.9-7.7-3.6-19.3-1.6 4.3 2.3 8.3 5.3 12 8.9 3.6 3.7 6.6 7.7 8.9 12M30.8 5.4c-5.6 2-11.2 5.1-15.9 9.7-4.6 4.7-7.7 10.3-9.7 15.9 1.9 6.1 5.6 12 10.7 17.1 5.1 5.1 11 8.8 17.1 10.7 5.6-2 11.2-5.1 15.9-9.7 4.7-4.7 7.7-10.2 9.7-15.9-1.9-6.1-5.6-12-10.7-17.1-5-5.2-11-8.8-17.1-10.7' />
		</g>
		<g fill='#fff'>
			<path d='M5.2 31c-1.1 3.1-1.9 6.3-2.5 9.4 2.3 4.3 5.3 8.3 8.9 12 3.6 3.6 7.7 6.6 12 8.9 3-.5 6.2-1.3 9.4-2.5-6.1-1.9-12.1-5.6-17.1-10.7C10.8 43 7.1 37.1 5.2 31M52.2 11.8c-3.6-3.6-7.7-6.6-12-8.9-3 .5-6.2 1.3-9.4 2.5C36.9 7.3 42.9 10.9 48 16s8.8 11 10.7 17.1c1.1-3.1 1.9-6.3 2.5-9.4-2.4-4.2-5.4-8.2-9-11.9M37.8 19.8l6.3 6.3c1 1 2.6-.6 1.6-1.6l-6.3-6.3c-1-1-2.6.6-1.6 1.6M33.1 24.6l6.3 6.3c1 1 2.6-.6 1.6-1.6L34.7 23c-1.1-1-2.6.6-1.6 1.6M28.3 29.3l6.3 6.3c1 1 2.6-.6 1.6-1.6l-6.3-6.3c-1-1-2.6.6-1.6 1.6M23.6 34.1l6.3 6.3c1 1 2.6-.6 1.6-1.6l-6.3-6.3c-1.1-1-2.7.6-1.6 1.6M18.8 38.8l6.3 6.3c1 1 2.6-.6 1.6-1.6l-6.3-6.3c-1-1-2.6.6-1.6 1.6' />
			<path d='m21.4 44.2 23.4-23.4c1-1-.6-2.6-1.6-1.6L19.8 42.6c-1 1.1.5 2.7 1.6 1.6' />
		</g>
	</svg>
);
export default SvgSportIconAmericanFootball;
