import * as React from 'react';
import { SVGProps } from 'react';
const SvgBurst = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#2d2d2d' />
			<path
				fill='#fff'
				fillRule='nonzero'
				d='M11.706 19.296 4 16.77h5.155l.51-2.443 4.578.003L15.385 8h4.606c3.118 0 4.382 1.255 3.915 3.845l-.1.555c-.273 1.509-.962 2.512-2.146 3.091 1.173.6 1.567 1.735 1.272 3.37l-.228 1.266C22.248 22.653 20.39 24 17.372 24h-4.875l1.263-6.993h-1.53zm5.066-2.747-.944 5.23h1.945c1.154 0 1.795-.512 2.013-1.72l.245-1.353c.278-1.543-.256-2.157-1.769-2.157zm1.143-6.329-.824 4.564h1.412c1.288 0 2.041-.55 2.272-1.83l.157-.866c.225-1.247-.241-1.868-1.422-1.868H18.1z'
			/>
		</g>
	</svg>
);
export default SvgBurst;
