import * as React from 'react';
import { SVGProps } from 'react';
const SvgWbtc = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#201A2D' />
			<g fill='#FFF'>
				<path d='m22.818 9.586-.6.6a8.494 8.494 0 0 1 0 11.464l.6.6a9.352 9.352 0 0 0 0-12.678v.014zM10.2 9.638a8.494 8.494 0 0 1 11.464 0l.6-.6a9.352 9.352 0 0 0-12.678 0l.614.6zm-.562 12.018a8.494 8.494 0 0 1 0-11.458l-.6-.6a9.352 9.352 0 0 0 0 12.678l.6-.62zm12.018.554a8.494 8.494 0 0 1-11.464 0l-.6.6a9.352 9.352 0 0 0 12.678 0l-.614-.6zm-1.942-8.286c-.12-1.252-1.2-1.672-2.566-1.8V10.4h-1.056v1.692h-.844V10.4H14.2v1.736h-2.142v1.13s.78-.014.768 0a.546.546 0 0 1 .6.464v4.752a.37.37 0 0 1-.128.258.366.366 0 0 1-.272.092c.014.012-.768 0-.768 0l-.2 1.262h2.122v1.764h1.056V20.12h.844v1.73h1.058v-1.744c1.784-.108 3.028-.548 3.184-2.218.126-1.344-.506-1.944-1.516-2.186.614-.302.994-.862.908-1.778zm-1.48 3.756c0 1.312-2.248 1.162-2.964 1.162v-2.328c.716.002 2.964-.204 2.964 1.166zm-.49-3.28c0 1.2-1.876 1.054-2.472 1.054v-2.116c.596 0 2.472-.188 2.472 1.062z' />
				<path d='M15.924 26.852C9.89 26.851 5 21.959 5 15.925 5 9.892 9.892 5 15.925 5c6.034 0 10.926 4.89 10.927 10.924a10.926 10.926 0 0 1-10.928 10.928zm0-21c-5.559.004-10.062 4.513-10.06 10.072.002 5.559 4.51 10.064 10.068 10.064 5.559 0 10.066-4.505 10.068-10.064A10.068 10.068 0 0 0 15.924 5.852z' />
			</g>
		</g>
	</svg>
);
export default SvgWbtc;
