import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionBrazil = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		viewBox='-2100 -1470 4200 2940'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<defs>
			<path
				id='regionBrazil_svg__i'
				fillRule='evenodd'
				d='M-31.5 0h33a30 30 0 0 0 30-30v-10a30 30 0 0 0-30-30h-33zm13-13h19a19 19 0 0 0 19-19v-6a19 19 0 0 0-19-19h-19z'
			/>
			<path
				id='regionBrazil_svg__n'
				d='M-15.75-22C-15.75-15-9-11.5 1-11.5s14.74-3.25 14.75-7.75c0-14.25-46.75-5.25-46.5-30.25C-30.5-71-6-70 3-70s26 4 25.75 21.25H13.5c0-7.5-7-10.25-15-10.25-7.75 0-13.25 1.25-13.25 8.5-.25 11.75 46.25 4 46.25 28.75C31.5-3.5 13.5 0 0 0c-11.5 0-31.55-4.5-31.5-22z'
			/>
			<path
				id='regionBrazil_svg__l'
				d='M-26.25 0h52.5v-12h-40.5v-16h33v-12h-33v-11H25v-12h-51.25z'
			/>
			<path
				id='regionBrazil_svg__k'
				d='M-31.5 0h12v-48l14 48h11l14-48V0h12v-70H14L0-22l-14-48h-17.5z'
			/>
			<path
				id='regionBrazil_svg__d'
				fillRule='evenodd'
				d='M0 0a31.5 35 0 0 0 0-70A31.5 35 0 0 0 0 0m0-13a18.5 22 0 0 0 0-44 18.5 22 0 0 0 0 44'
			/>
			<path
				id='regionBrazil_svg__f'
				fillRule='evenodd'
				d='M-31.5 0h13v-26h28a22 22 0 0 0 0-44h-40zm13-39h27a9 9 0 0 0 0-18h-27z'
			/>
			<path
				id='regionBrazil_svg__j'
				d='M0 0h63v-13H12v-18h40v-12H12v-14h48v-13H0z'
				transform='translate(-31.5)'
			/>
			<use xlinkHref='#regionBrazil_svg__a' id='regionBrazil_svg__q' transform='scale(15)' />
			<use
				xlinkHref='#regionBrazil_svg__a'
				id='regionBrazil_svg__s'
				transform='scale(10.5)'
			/>
			<use xlinkHref='#regionBrazil_svg__a' id='regionBrazil_svg__r' transform='scale(21)' />
			<use
				xlinkHref='#regionBrazil_svg__a'
				id='regionBrazil_svg__o'
				transform='scale(31.5)'
			/>
			<use
				xlinkHref='#regionBrazil_svg__a'
				id='regionBrazil_svg__p'
				transform='scale(26.25)'
			/>
			<g id='regionBrazil_svg__a' fill='#fff'>
				<g id='regionBrazil_svg__c'>
					<path id='regionBrazil_svg__b' d='M0-1v1h.5' transform='rotate(18 0 -1)' />
					<use xlinkHref='#regionBrazil_svg__b' transform='scale(-1 1)' />
				</g>
				<use xlinkHref='#regionBrazil_svg__c' transform='rotate(72)' />
				<use xlinkHref='#regionBrazil_svg__c' transform='rotate(-72)' />
				<use xlinkHref='#regionBrazil_svg__c' transform='rotate(144)' />
				<use xlinkHref='#regionBrazil_svg__c' transform='rotate(216)' />
			</g>
			<g id='regionBrazil_svg__m'>
				<clipPath id='regionBrazil_svg__e'>
					<path d='M-31.5 0v-70h63V0zM0-47v12h31.5v-12z' />
				</clipPath>
				<use xlinkHref='#regionBrazil_svg__d' clipPath='url(#regionBrazil_svg__e)' />
				<path d='M5-35h26.5v10H5z' />
				<path d='M21.5-35h10V0h-10z' />
			</g>
			<g id='regionBrazil_svg__h'>
				<use xlinkHref='#regionBrazil_svg__f' />
				<path d='M28 0c0-10 0-32-15-32H-6c22 0 22 22 22 32' />
			</g>
		</defs>
		<rect width='100%' height='100%' x='-50%' y='-50%' fill='#009b3a' />
		<path fill='#fedf00' d='M-1743 0 0 1113 1743 0 0-1113z' />
		<circle r={735} fill='#002776' />
		<clipPath id='regionBrazil_svg__g'>
			<circle r={735} />
		</clipPath>
		<path
			fill='#fff'
			d='M-2205 1470a1785 1785 0 0 1 3570 0h-105a1680 1680 0 1 0-3360 0z'
			clipPath='url(#regionBrazil_svg__g)'
		/>
		<g fill='#009b3a' transform='translate(-420 1470)'>
			<use xlinkHref='#regionBrazil_svg__d' y={-1697.5} transform='rotate(-7)' />
			<use xlinkHref='#regionBrazil_svg__h' y={-1697.5} transform='rotate(-4)' />
			<use xlinkHref='#regionBrazil_svg__i' y={-1697.5} transform='rotate(-1)' />
			<use xlinkHref='#regionBrazil_svg__j' y={-1697.5} transform='rotate(2)' />
			<use xlinkHref='#regionBrazil_svg__k' y={-1697.5} transform='rotate(5)' />
			<use xlinkHref='#regionBrazil_svg__l' y={-1697.5} transform='rotate(9.75)' />
			<use xlinkHref='#regionBrazil_svg__f' y={-1697.5} transform='rotate(14.5)' />
			<use xlinkHref='#regionBrazil_svg__h' y={-1697.5} transform='rotate(17.5)' />
			<use xlinkHref='#regionBrazil_svg__d' y={-1697.5} transform='rotate(20.5)' />
			<use xlinkHref='#regionBrazil_svg__m' y={-1697.5} transform='rotate(23.5)' />
			<use xlinkHref='#regionBrazil_svg__h' y={-1697.5} transform='rotate(26.5)' />
			<use xlinkHref='#regionBrazil_svg__j' y={-1697.5} transform='rotate(29.5)' />
			<use xlinkHref='#regionBrazil_svg__n' y={-1697.5} transform='rotate(32.5)' />
			<use xlinkHref='#regionBrazil_svg__n' y={-1697.5} transform='rotate(35.5)' />
			<use xlinkHref='#regionBrazil_svg__d' y={-1697.5} transform='rotate(38.5)' />
		</g>
		<use xlinkHref='#regionBrazil_svg__o' x={-600} y={-132} />
		<use xlinkHref='#regionBrazil_svg__o' x={-535} y={177} />
		<use xlinkHref='#regionBrazil_svg__p' x={-625} y={243} />
		<use xlinkHref='#regionBrazil_svg__q' x={-463} y={132} />
		<use xlinkHref='#regionBrazil_svg__p' x={-382} y={250} />
		<use xlinkHref='#regionBrazil_svg__r' x={-404} y={323} />
		<use xlinkHref='#regionBrazil_svg__o' x={228} y={-228} />
		<use xlinkHref='#regionBrazil_svg__o' x={515} y={258} />
		<use xlinkHref='#regionBrazil_svg__r' x={617} y={265} />
		<use xlinkHref='#regionBrazil_svg__p' x={545} y={323} />
		<use xlinkHref='#regionBrazil_svg__p' x={368} y={477} />
		<use xlinkHref='#regionBrazil_svg__r' x={367} y={551} />
		<use xlinkHref='#regionBrazil_svg__r' x={441} y={419} />
		<use xlinkHref='#regionBrazil_svg__p' x={500} y={382} />
		<use xlinkHref='#regionBrazil_svg__r' x={365} y={405} />
		<use xlinkHref='#regionBrazil_svg__p' x={-280} y={30} />
		<use xlinkHref='#regionBrazil_svg__r' x={200} y={-37} />
		<use xlinkHref='#regionBrazil_svg__o' y={330} />
		<use xlinkHref='#regionBrazil_svg__p' x={85} y={184} />
		<use xlinkHref='#regionBrazil_svg__p' y={118} />
		<use xlinkHref='#regionBrazil_svg__r' x={-74} y={184} />
		<use xlinkHref='#regionBrazil_svg__q' x={-37} y={235} />
		<use xlinkHref='#regionBrazil_svg__p' x={220} y={495} />
		<use xlinkHref='#regionBrazil_svg__r' x={283} y={430} />
		<use xlinkHref='#regionBrazil_svg__r' x={162} y={412} />
		<use xlinkHref='#regionBrazil_svg__o' x={-295} y={390} />
		<use xlinkHref='#regionBrazil_svg__s' y={575} />
	</svg>
);
export default SvgRegionBrazil;
