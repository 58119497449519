import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionSaintVincentandtheGrenadines = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 192 128'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#fcd022' d='M0 0h192v128H0z' />
		<path fill='#007c2e' d='M144 0h48v128h-48z' />
		<path fill='#002674' d='M0 0h48v128H0z' />
		<path
			fill='#007c2e'
			d='M83.911 92.44 96 115.196l12.089-22.756L96 69.685zM68.267 64l12.089 22.755L92.444 64 80.356 41.244zm31.289 0 12.088 22.756L123.734 64l-12.09-22.756z'
		/>
	</svg>
);
export default SvgRegionSaintVincentandtheGrenadines;
