import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar1 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar1_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar1_svg__cls-4{fill:#393c54;opacity:.1}.avatar1_svg__cls-10,.avatar1_svg__cls-9{fill:#f85565}.avatar1_svg__cls-13,.avatar1_svg__cls-9{opacity:.2}.avatar1_svg__cls-11,.avatar1_svg__cls-13{fill:none;stroke-linecap:round}.avatar1_svg__cls-11{stroke:#fff;stroke-miterlimit:10;stroke-width:4px;opacity:.7}.avatar1_svg__cls-13{stroke:#515570;stroke-linejoin:round;stroke-width:2px}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#4bc190',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				fill: '#356cb6',
				opacity: 0.3,
			}}
		/>
		<path
			d='M91 46 64 73 37 46l24.17-30.17a4 4 0 0 1 5.66 0Z'
			style={{
				fill: '#fff',
			}}
		/>
		<path
			d='M64.72 14.73 64 16l14 37 4.5 1.5L91 46 66.83 15.83a4 4 0 0 0-2.11-1.1Z'
			className='avatar1_svg__cls-4'
		/>
		<path
			d='m50 53 29.5 4.5L91 46 66.83 15.83a3.89 3.89 0 0 0-1.68-1L64 16Z'
			className='avatar1_svg__cls-4'
		/>
		<path
			d='m64 46-14 6-13-6v71.58a60 60 0 0 0 54 0V46l-13 6Z'
			style={{
				fill: '#f2bc0f',
			}}
		/>
		<path
			d='m50 52-13-6v71.58a59.95 59.95 0 0 0 41 4.76V52l-14-6Z'
			style={{
				fill: '#f8dc25',
			}}
		/>
		<path
			d='M37 117.58a59.77 59.77 0 0 0 13 4.76V52l-13-6Z'
			style={{
				opacity: 0.5,
				fill: '#fff',
			}}
		/>
		<path
			d='m70.17 20-3.34-4.17a4 4 0 0 0-5.66 0L57.83 20Z'
			style={{
				fill: '#515570',
			}}
		/>
		<ellipse cx={51} cy={74.85} className='avatar1_svg__cls-9' rx={5.08} ry={2.85} />
		<ellipse cx={77} cy={74.85} className='avatar1_svg__cls-9' rx={5.08} ry={2.85} />
		<path
			d='M77.24 63.75a3.9 3.9 0 0 0-5.77.28 4 4 0 0 0 .38 5.34l4.74 4.74a.92.92 0 0 0 1.3 0l4.74-4.74A4 4 0 0 0 83 64a3.89 3.89 0 0 0-5.76-.25ZM50.76 63.75A3.89 3.89 0 0 0 45 64a4 4 0 0 0 .37 5.34l4.74 4.74a.92.92 0 0 0 1.3 0l4.74-4.74a4 4 0 0 0 .38-5.34 3.9 3.9 0 0 0-5.77-.25Z'
			className='avatar1_svg__cls-10'
		/>
		<path
			d='M57.24 89.54v6.79M55.24 85.54v8.79M71.24 90.54v5.79M73.24 88.54v4.79'
			className='avatar1_svg__cls-11'
		/>
		<path
			d='M73 83a1.93 1.93 0 0 1 3 1.6c0 5.4-4.3 9.49-11 9.49A13.09 13.09 0 0 1 52 82.6a1.65 1.65 0 0 1 2.45-1.6c7.2 3.89 13.77 4.39 18.55 2Z'
			style={{
				fill: '#393c54',
			}}
		/>
		<path
			d='M65 94.09a12 12 0 0 0 7.77-2.53C71 89.43 68.21 89.13 65 89.13s-6 .3-7.76 2.41A13.07 13.07 0 0 0 65 94.09Z'
			className='avatar1_svg__cls-10'
		/>
		<path
			d='M80.67 57.41 86 59.52a4.62 4.62 0 0 1 2.86 3.73l.14 1.42M47.54 59l-2.71.09a4.61 4.61 0 0 0-3.75 2.84l-.54 1.32'
			className='avatar1_svg__cls-13'
		/>
	</svg>
);
export default SvgAvatar1;
