import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import PropTypes from "prop-types";
import {
  IAuthContextProps,
  IUserProps,
  IUserAccount,
  ILoginResponse,
  ISocketResponse,
} from "../type/socket-type";
import SocketContext from "./SocketContext";

const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
  children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({
  children,
}) => {
  const [userProfile, setUserProfile] = useState<Partial<IUserProps>>({});
  const [userAccounts, setUserAccounts] = useState<IUserAccount[]>([]);
  const [token, setToken] = useState<string | any>(
    localStorage.getItem("atox_token") || ""
  );
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const { sendRequest, socket } = useContext(SocketContext);

  useEffect(() => {
    socket.on("balanceUpdate", (data: any) => {
      console.log("balanceUpdate", data);

      const accountUpdateData = data.accountUpdate;
      setUserAccounts((prevAccounts) => {
        const updatedAccounts = prevAccounts.map((account: any) => {
          console.log("prevAccount", account);
          if (account._id === accountUpdateData._id) {
            return {
              ...account,
              balance: accountUpdateData.balance,
            };
          }
          return account;
        });

        return updatedAccounts;
      });
    });

    return () => {
      socket.off("balanceUpdate");
    };
  }, [socket]);

  const sendLoginRequest = useCallback((
    loginData: any,
    callback: (response: ISocketResponse) => void
  ) => {
    sendRequest(
      {
        A: "loginUser",
        D: {
          U: loginData.loginMail,
          P: loginData.loginPassword,
          token: loginData.token,
        },
      },
      (response: any) => {
        callback(response);
      }
    );
  }, [sendRequest]);

  const sendRegisterRequest = useCallback(
    (
    loginData: any,
    callback: (response: ISocketResponse) => void
  ) => {
    sendRequest(
      {
        A: "registerUser",
        D: {
          E: loginData.registerMail,
          P: loginData.loginPassword,
          name: loginData.name,
          surname: loginData.surname,
        },
      },
      (response: any) => {
        callback(response);
      }
    );
  }, [sendRequest]);

  const handleUserLogin = (loginResponse: ILoginResponse) => {
    if (loginResponse.userProfile) {
      console.log('loginResult', loginResponse)
      setUserProfile(loginResponse.userProfile);
      setUserAccounts(loginResponse.userAccounts);
      setToken(loginResponse.token);
      localStorage.setItem("atox_token", loginResponse.token);
      setIsAuthenticated(true);
    }
  };

  const handleUserLogout = () => {
    setUserProfile({});
    setUserAccounts([]);
    setIsAuthenticated(false);
    setToken(null);
    localStorage.removeItem("atox_username");
    localStorage.removeItem("atox_token");
  };

  const sendLogoutRequest = useCallback(() => {
    sendRequest({ A: "userLogout" }, (response: any) => {
      handleUserLogout();
      console.log("User logut response", response);
      if (response.E) {
        // showNotification('Error', response.E, 'danger');
      }
    });
  }, [sendRequest]);

  const sendCheckLoginRequest = useCallback(() => {
    sendRequest({ A: "checkLogin" }, (response: any) => {
      if (response.E) {
        // showNotification('Error', response.E, 'danger');
        if (response.E === "NO_AUTH" || response.E === "USER_BLOCKED") {
          handleUserLogout();
          return;
        }
      }

      if (response.R && response.R.userProfile) {
        handleUserLogin(response.R);
      }
    });
  }, [sendRequest]);

  useEffect(() => {
    if (token && !isAuthenticated) {
      sendCheckLoginRequest();
    }
  }, [sendCheckLoginRequest, token, isAuthenticated]);

  const value = useMemo(
    () => ({
      userProfile,
      userAccounts,
      isAuthenticated,
      setUserProfile,
      setUserAccounts,
      setIsAuthenticated,
      sendLoginRequest,
      handleUserLogin,
      sendCheckLoginRequest,
      handleUserLogout,
      sendLogoutRequest,
      sendRegisterRequest,
      token,
      setToken,
    }),
    [userAccounts, userProfile, isAuthenticated, token,sendLoginRequest, sendLogoutRequest, setUserAccounts, sendRegisterRequest, sendCheckLoginRequest]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
