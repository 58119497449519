import React, { useContext, useEffect, useMemo } from "react";
import AvatarComponent from "./UserAvatar";
import BalanceComponent from "./Balance";
import UserProfileNavComponent from "./ProfileNav";
import { useLocation } from "react-router-dom";
import PaymentMethodsComponent from "./PaymentMethods";
import HistoryComponent from "./History";
import GameHistoryComponent from "./GameHistory";
import ThemeContext from "../../context/ThemeContext";
import ProfileDetails from "./ProfileDetails";
import ChangePassword from "./ChangePassword";
import SportHistory from "./SportHistory";
import UserRouter from "./UserRouter";
import { useTranslation } from "react-i18next";

const UserControl: React.FC = (toggle: any) => {
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const account = searchParams.get("account");
  const pageType = searchParams.get("page");
  const profile = searchParams.get("profile");
  const { t } = useTranslation();
  const { mobileDesign } = useContext(ThemeContext);

  return (
    <div className="u-i-p-c-body-bc">
      <div className="u-i-profile-page-container">
        <div className="u-i-profile-page-bc">
          <div
            className="u-i-profile-page-content"
            data-scroll-lock-scrollable=""
          >
            <AvatarComponent />
            <BalanceComponent />
            <UserProfileNavComponent />
          </div>
        </div>
      </div>
      {mobileDesign ? (
        <UserRouter />
      ) : (
        <>
          <div className="my-profile-info-block">
       
            <UserRouter />
          </div>
        </>
      )}
    </div>
  );
};

export default UserControl;
