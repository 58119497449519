import React from 'react';
import { Route, Routes } from 'react-router-dom';
import navigations from '../../routes/NavigationRoutes';

const NavigationRoutes = () => {
	return (
		<Routes>
			{navigations.map((page) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Route key={page.path} {...page} />
			))}
		</Routes>
	);
};

export default NavigationRoutes;
