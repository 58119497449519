import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar20 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar20_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar20_svg__cls-3{fill:#fff}.avatar20_svg__cls-4{fill:#393c54;opacity:.1}.avatar20_svg__cls-5{fill:#f85565}.avatar20_svg__cls-8{fill:#356cb6}.avatar20_svg__cls-9{fill:none;stroke:#515570;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#00adfe',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.3,
				fill: '#fff',
			}}
		/>
		<path
			d='M109.39 68.76A17.56 17.56 0 0 0 91.82 51.2a18.1 18.1 0 0 0-3.85.43 26.19 26.19 0 0 0-40-10A16.33 16.33 0 0 0 24.82 59a8.78 8.78 0 0 0-3.37 15.75 21 21 0 0 0 24.86 28.09 11.37 11.37 0 0 0 19.21 3.8 21 21 0 0 0 33.61-16.83 21.27 21.27 0 0 0-.57-4.81 17.56 17.56 0 0 0 10.83-16.24Z'
			className='avatar20_svg__cls-3'
		/>
		<path
			d='M109.39 68.76A17.56 17.56 0 0 0 91.82 51.2a18.1 18.1 0 0 0-3.85.43 26.19 26.19 0 0 0-40-10A16.33 16.33 0 0 0 24.82 59a8.78 8.78 0 0 0-3.37 15.75 21 21 0 0 0 24.86 28.09 11.37 11.37 0 0 0 19.21 3.8 21 21 0 0 0 33.61-16.83 21.27 21.27 0 0 0-.57-4.81 17.56 17.56 0 0 0 10.83-16.24Z'
			className='avatar20_svg__cls-4'
		/>
		<path
			d='M106.53 60.49c-.07-.48-.18-1.09-.32-1.78a17.51 17.51 0 0 0-14.39-7.51 18.1 18.1 0 0 0-3.85.43 26.19 26.19 0 0 0-40-10A16.33 16.33 0 0 0 24.82 59a8.8 8.8 0 0 0-6.47 5.5A9.88 9.88 0 0 0 29.41 71a17.89 17.89 0 0 0 29.67 17.19 17.77 17.77 0 0 0 32.73-9.6 18 18 0 0 0-.35-3.47 12.93 12.93 0 0 0 15.07-14.63Z'
			className='avatar20_svg__cls-3'
		/>
		<path
			d='M97.22 14a59.64 59.64 0 0 0-16.08-7.5 72.93 72.93 0 0 0-37.45 51.68l13.07 2.32A59.72 59.72 0 0 1 97.22 14Z'
			className='avatar20_svg__cls-5'
		/>
		<path
			d='M97.23 14.05A60 60 0 0 0 56.76 60.5l13.08 2.32a46.53 46.53 0 0 1 39.61-38 60 60 0 0 0-12.22-10.77Z'
			style={{
				fill: '#f8dc25',
			}}
		/>
		<path
			d='M118 37.83a60 60 0 0 0-8.55-13 46.42 46.42 0 0 0-39.61 38l13.08 2.32a33.13 33.13 0 0 1 32.63-27.41c.81-.01 1.63.03 2.45.09Z'
			style={{
				fill: '#4bc190',
			}}
		/>
		<path
			d='M82.92 65.14a33.14 33.14 0 0 1 4.63-12 13 13 0 0 0-16.55.97 16.49 16.49 0 0 0-10.21-3.56h-.33a12.94 12.94 0 0 0-8-8.74A12.63 12.63 0 0 0 49 41a73.4 73.4 0 0 0-5.26 17.14l13.02 2.36 13.08 2.32Z'
			className='avatar20_svg__cls-4'
		/>
		<path
			d='M70 123.7a59.86 59.86 0 0 0 10-1.87V76H70ZM40 119a59.62 59.62 0 0 0 10 3.35V76H40Z'
			className='avatar20_svg__cls-8'
		/>
		<path
			d='M83 55.21a9 9 0 0 0-11.6 5.29 12.42 12.42 0 0 0-14.55-5.3A9 9 0 1 0 49 63a12.44 12.44 0 0 0 23.42 8.41 12.7 12.7 0 0 0 .58-1.92 9 9 0 1 0 10-14.28Z'
			className='avatar20_svg__cls-3'
		/>
		<path
			d='M66 72a4 4 0 0 0 4 4h10M53.67 72a4 4 0 0 1-4 4H40'
			className='avatar20_svg__cls-9'
		/>
		<path
			d='M52.45 94a1 1 0 0 1-.94-1.06 8.56 8.56 0 0 1 17 0 1 1 0 0 1-.96 1.06Z'
			style={{
				fill: '#393c54',
			}}
		/>
		<path d='M65.75 94a6.06 6.06 0 0 0-11.5 0Z' className='avatar20_svg__cls-5' />
		<path
			d='M36 110a2 2 0 0 1-4 0c0-1.1.9-3.41 2-3.41s2 2.31 2 3.41ZM58.67 119a2 2 0 0 1-4 0c0-1.1.89-3.41 2-3.41s2 2.31 2 3.41ZM66 115.41a2 2 0 0 1-4 0c0-1.1.9-3.41 2-3.41s2 2.31 2 3.41ZM87.55 115.41a2 2 0 0 1-4 0c0-1.1.9-3.41 2-3.41s2 2.31 2 3.41Z'
			className='avatar20_svg__cls-8'
		/>
	</svg>
);
export default SvgAvatar20;
