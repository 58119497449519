import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionMali = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 450 300'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#CE1126' d='M0 0h450v300H0z' />
		<path fill='#FCD116' d='M0 0h300v300H0z' />
		<path fill='#14B53A' d='M0 0h150v300H0z' />
	</svg>
);
export default SvgRegionMali;
