import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ThemeContext from '../../context/ThemeContext';
import SocketContext from '../../context/SocketContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from '../../component/LoadingSpinner';
import dayjs from 'dayjs';

interface Message {
  _id: string;
  sender: string;
  subject: string;
  body: string;
  receivedAt: string;
}

const InboxMessage: React.FC = () => {
  const { t } = useTranslation();
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(true);
  const { sendRequest } = useContext(SocketContext);
  const [perPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const loadMessages = useCallback(
    (page: number) => {
      if (page === 1) setLoading(true);

      sendRequest(
        { A: 'getMessages', D: { page, perPage } },
        (response: any) => {
          const { messages: newMessages, totalDocuments } = response.R;

          setTotalDocuments(totalDocuments);
          setMessages((prev) =>
            page === 1 ? newMessages : [...prev, ...newMessages],
          );
          setLoading(false);
        },
      );
    },
    [sendRequest, perPage],
  );

  useEffect(() => {
    loadMessages(1);
  }, [loadMessages]);

  const fetchMoreMessages = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    loadMessages(nextPage);
  };

  const LoadingSkeleton = () => {
    return (
      <>
        {[...Array(10)].map((_, index) => (
          <div className='messageListEl' key={index}>
            <div className='messageListEl-list-item'>
              <div className='messageListEl-info'>
                <span className='skeleton-loader-text'></span>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <div className='overlay-header'>{t('inboxMessages')}</div>
      <div className='u-i-e-p-p-content-bc u-i-common-content'>
        <div className='messageList-table-details' id='scrollableDiv'>
          <div className='messageList-thead'>
            <div className='messageListEl'>
              <div className='messageListEl-list-item'>
                <div className='messageListEl-info'>{t('Sender')}</div>
              </div>
              <div className='messageListEl-list-item'>
                <div className='messageListEl-info'>{t('Subject')}</div>
              </div>
              <div className='messageListEl-list-item'>
                <div className='messageListEl-info'>{t('ReceivedAt')}</div>
              </div>
            </div>
          </div>

          {loading ? (
            <LoadingSpinner loading={loading} />
          ) : (
            <InfiniteScroll
              dataLength={messages.length}
              next={fetchMoreMessages}
              hasMore={totalDocuments > messages.length}
              loader={<LoadingSkeleton />}
              className='messageList-tbody'
              style={{ overflow: 'hidden' }}
              scrollableTarget='scrollableDiv'>
              {messages.length > 0 ? (
                messages.map((message) => (
                  <div className='messageListEl' key={message._id}>
                    <div className='messageListEl-list-item'>
                      <div className='messageListEl-info'>
                        {message.sender}
                      </div>
                    </div>
                    <div className='messageListEl-list-item'>
                      <div className='messageListEl-info'>
                        {message.subject}
                      </div>
                    </div>
                    <div className='messageListEl-list-item'>
                      <div className='messageListEl-info'>
                        <time className='messageListEl-date'>
                          {dayjs(message.receivedAt).format(
                            'DD.MM.YYYY HH:mm:ss',
                          )}
                        </time>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className='empty-b-text-v-bc'>{t('NO_MESSAGES')}</p>
              )}
            </InfiniteScroll>
          )}
        </div>
      </div>
    </>
  );
};

export default InboxMessage;
