import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionMozambique = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 900 600'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path d='M0 0h900v600H0z' />
		<path fill='#fff' d='M0 0h900v206.25H0z' />
		<path fill='#007168' d='M0 0h900v187.5H0z' />
		<path fill='#fff' d='M0 393.5h900V600H0z' />
		<path fill='#fce100' d='M0 412.5h900V600H0z' />
		<path fill='#d21034' d='M0 0v600l393.75-300z' />
		<path
			fill='#fce100'
			d='m204.37 412.485-73.154-53.56L58.16 412.5l28.332-86.124-73.528-52.924 90.664.332 27.613-86.284 27.7 86.33 90.594-.403-73.544 53.023z'
		/>
		<path
			fill='#fff'
			fillRule='evenodd'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5746499999999999}
			d='M67.724 352.427h52.699c4.24 4.757 13.7 6.697 22.711-.056 16.518-9.045 48.473.056 48.473.056l6.292-6.685-15.338-50.34-5.506-5.899s-11.797-7.078-34.215-4.719c-22.417 2.36-30.283-.786-30.283-.786s-19.663 2.36-25.17 5.112c-.604.49-6.292 6.292-6.292 6.292z'
		/>
		<path
			fill='none'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5746499999999999}
			d='M78.343 339.45s50.339-6.293 64.89 12.977c-8.218 5.624-15.453 6.087-23.203.395 1.234-2.057 18.09-19.665 60.958-13.765'
		/>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5746499999999999}
			d='m132.221 289.11-.393 55.453m45.227-54.667 9.439 44.048m-98.587-44.74-5.238 22.716'
		/>
		<path
			fillRule='evenodd'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5746499999999999}
			d='m33.497 358.465 12.393 14.531c1.456.868 2.748.813 4.041 0l18.367-22.04 7.715-9.551c1.194-1.415 1.575-2.993 1.469-4.408l14.748-13.105c1.206.083 1.925.22 3.131.301-1.425-.369-2.47-1.063-1.349-2.625l3.306-2.571 2.571 3.305s-3.672 4.776-4.04 4.776h-4.04l-7.715 6.98 3.372 2.98 5.077 13.918 6.245-4.409-4.04-14.325 8.816-9.552-3.307-5.143 2.205-2.939s30.513 19.21 42.268 14.068c.318.115.71-13.7.71-13.7s-31.59-3.306-32.326-9.551c-.734-6.245 6.98-6.98 6.98-6.98l-3.307-4.775.736-2.572 5.51 6.98 12.49-10.654 73.468 83.755c4.011-1.626 4.868-2.607 5.144-6.612-.104-.1-72-82.653-72-82.653l5.51-5.876c1.086-1.225 1.468-1.745 1.47-3.675l8.448-7.346c2.541.873 4.161 2.396 5.51 4.407l23.228-19.685c.612.612 2.472 1.224 3.734.536l38.402-36.861-41.854 29.562-1.469-1.102c0-1.225 1.518-1.527 0-3.673-1.626-1.952-4.04 1.836-4.407 1.836-.368 0-6.058-2.01-7.305-4.558l-.41 6.762-10.653 9.919-8.081-.368-11.756 11.388-1.469 4.408 1.837 3.674s-6.246 5.51-6.246 5.142c0-.366-1.262-1.623-1.316-1.786l5.357-4.826.735-3.306-1.788-2.791c-.542.394-7.396 7.567-7.764 6.832-.612-.686-19.835-22.408-19.835-22.408l1.101-4.04-12.489-13.593c-4.553-1.572-11.755-1.836-13.225 8.082-1.144 2.33-10.653.367-10.653.367l-5.143 1.102-29.02 41.143 16.163 19.469 33.061-41.877.982-11.864 6.937 7.757c2.313.297 4.516.323 6.612-.735l19.588 21.863-3.262 3.183c1.102 1.224 1.86 2.006 2.963 3.23 1.102-.734 2.154-1.615 3.256-2.351.368.491.98 1.422 1.348 1.912-1.641.893-2.793 2.082-4.434 2.976-2.626-1.712-5.162-3.844-4.97-7.236l-11.02 9.183-.367 1.837-32.694 27.183-2.939.368-.734 8.449 21.306-17.632v-2.573l2.204 1.837 16.53-13.223s1.102 1.469.736 1.469c-.368 0-14.695 13.224-14.695 13.224l-.367 1.469-2.571 2.204-1.47-1.102-19.837 17.632h-2.938l-11.02 11.022c-2.843.247-5.306.548-7.715 2.203z'
		/>
	</svg>
);
export default SvgRegionMozambique;
