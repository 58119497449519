import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar11 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar11_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar11_svg__cls-1,.avatar11_svg__cls-11{fill:#f85565}.avatar11_svg__cls-14{fill:#fff}.avatar11_svg__cls-10,.avatar11_svg__cls-11{opacity:.2}.avatar11_svg__cls-3{fill:#f2bc0f}.avatar11_svg__cls-4{fill:#fba875}.avatar11_svg__cls-5{fill:#ffbb94}.avatar11_svg__cls-10{fill:none;stroke-linecap:round}.avatar11_svg__cls-7{fill:#515570}.avatar11_svg__cls-10{stroke:#515570;stroke-linejoin:round;stroke-width:2px}.avatar11_svg__cls-12{fill:#f8dc25}'
				}
			</style>
		</defs>
		<circle cx={64} cy={64} r={60} className='avatar11_svg__cls-1' />
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.2,
				fill: '#fff',
			}}
		/>
		<circle cx={89} cy={60} r={15} className='avatar11_svg__cls-3' />
		<circle cx={82} cy={77} r={10} className='avatar11_svg__cls-3' />
		<circle cx={39} cy={60} r={15} className='avatar11_svg__cls-3' />
		<circle cx={46} cy={77} r={10} className='avatar11_svg__cls-3' />
		<path
			d='M64 14.88a32 32 0 0 1 32 32v4.71a21.59 21.59 0 0 1-21.59 21.58H53.59A21.59 21.59 0 0 1 32 51.59v-4.71a32 32 0 0 1 32-32Z'
			className='avatar11_svg__cls-3'
		/>
		<circle cx={89} cy={60} r={7} className='avatar11_svg__cls-4' />
		<path
			d='M64 124a59.69 59.69 0 0 0 32.55-9.61l-3.18-10.75A10 10 0 0 0 84 97H44.05a10 10 0 0 0-9.42 6.64l-3.18 10.75A59.69 59.69 0 0 0 64 124Z'
			className='avatar11_svg__cls-5'
		/>
		<path
			d='M64 88.75v7.75'
			style={{
				strokeWidth: 14,
				strokeMiterlimit: 10,
				stroke: '#fba875',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={39} cy={60} r={7} className='avatar11_svg__cls-4' />
		<path
			d='M64 90a25 25 0 0 1-25-25V47.52a25 25 0 1 1 50 0V65a25 25 0 0 1-25 25Z'
			className='avatar11_svg__cls-5'
		/>
		<circle cx={76} cy={58.28} r={3} className='avatar11_svg__cls-7' />
		<path
			d='M70.5 59.37A6.61 6.61 0 0 1 82 58.06'
			style={{
				stroke: '#515570',
				strokeLinejoin: 'round',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={52} cy={58.28} r={3} className='avatar11_svg__cls-7' />
		<path
			d='M61.75 69a5.29 5.29 0 0 0 4.5 0'
			style={{
				strokeWidth: 3,
				strokeMiterlimit: 10,
				stroke: '#fba875',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path d='M55 52h-9.25' className='avatar11_svg__cls-10' />
		<circle cx={51} cy={67} r={5.08} className='avatar11_svg__cls-11' />
		<path
			d='M36.51 58.15V47.4c0-14.95 11.71-27.61 26.66-28a27.51 27.51 0 0 1 28.32 27.42v-.24a2 2 0 0 1-2 2 7.11 7.11 0 0 1-6.31-3.85l-5.6-10.81-7.4 13.73a31.43 31.43 0 0 1-27.67 16.5 6 6 0 0 1-6-6Z'
			className='avatar11_svg__cls-12'
		/>
		<circle cx={41.61} cy={49.55} r={14.61} className='avatar11_svg__cls-12' />
		<circle cx={44} cy={33.94} r={7.64} className='avatar11_svg__cls-12' />
		<path
			d='M70.22 34.94 64.9 44.8a25.41 25.41 0 0 1-22.31 13.35h0c-5.28 0-9.59-3.89-9.59-8.6'
			style={{
				stroke: '#f2bc0f',
				opacity: 0.3,
				strokeWidth: 3,
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={89.81} cy={41.13} r={7.64} className='avatar11_svg__cls-12' />
		<circle cx={77} cy={67} r={5.08} className='avatar11_svg__cls-11' />
		<circle cx={74.5} cy={71.5} r={1} className='avatar11_svg__cls-7' />
		<path
			d='M83.32 120.9 76 96.5h-2l-8.76 27.57a59.72 59.72 0 0 0 18.08-3.17ZM61.52 124 54 96.5h-2l-7.32 24.4a59.66 59.66 0 0 0 16.84 3.1Z'
			className='avatar11_svg__cls-14'
		/>
		<path
			d='M71.12 78.48a.49.49 0 0 0-.19-.7c-.71-.39-2-1.08-2.75-1.61a2.51 2.51 0 0 0-2.76 0 2.42 2.42 0 0 1-2.84 0 2.51 2.51 0 0 0-2.76 0c-.78.51-2 1.18-2.69 1.58a.49.49 0 0 0-.17.72c.77 1.11 2 4.35 6.82 4.47h.44c4.78-.14 6.19-3.33 6.9-4.46Z'
			className='avatar11_svg__cls-1'
		/>
		<path d='M61 78.85h6s-.6 1.3-3 1.3-3-1.3-3-1.3Z' className='avatar11_svg__cls-14' />
		<circle cx={90} cy={68} r={3} className='avatar11_svg__cls-14' />
		<circle cx={38} cy={68} r={3} className='avatar11_svg__cls-14' />
		<path d='m72 51 5.18-2.36a4.6 4.6 0 0 1 4.67.5L83 50' className='avatar11_svg__cls-10' />
	</svg>
);
export default SvgAvatar11;
