import * as React from 'react';
import { SVGProps } from 'react';
const SvgCtxc = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='currentColor' />
			<g fill='#FFF'>
				<path d='M15.897 4.398c-.146-.33.382-.564.528-.25 3.432 5.912 6.864 11.827 10.296 17.748-.183 0-.359-.06-.535-.088l-9.16-1.826c-.3-.015-.366-.5-.073-.572l3.594-.99a.3.3 0 0 0 .198-.418L15.897 4.39v.007z' />
				<path d='M15.37 4.428c.033.048.062.1.087.154 1.086 3.08 2.2 6.16 3.278 9.24.096.256-.264.469-.44.278-.924-.968-1.804-1.965-2.713-2.933-.147-.132-.36-.044-.455.095L5.682 22.358c-.11.117-.205.293-.389.315-.132 0-.22-.103-.293-.205v-.17c3.461-5.954 6.918-11.91 10.37-17.87z' />
				<path d='M12.48 14.995c.176-.051.41.073.352.286-.367 1.276-.755 2.545-1.137 3.813-.058.162.088.308.242.316 4.943.99 9.886 2.016 14.828 3.006.096.015.162.088.235.147v.205l-.14.147H5.88c2.2-2.64 4.393-5.295 6.6-7.92z' />
			</g>
		</g>
	</svg>
);
export default SvgCtxc;
