import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionFrenchPolynesia = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		viewBox='0 0 600 400'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<clipPath id='regionFrenchPolynesia_svg__a'>
			<ellipse cx={300} cy={201} rx={85.849} ry={88.189} />
		</clipPath>
		<path fill='#ce1126' d='M0 0h600v400H0z' />
		<path fill='#fff' d='M0 100h600v200H0z' />
		<g
			strokeWidth={0.998}
			clipPath='url(#regionFrenchPolynesia_svg__a)'
			transform='translate(-.528 -.947) scale(1.00176)'>
			<path
				fill='#ff9c10'
				d='M262.715 190.6v-5.762l14.575 6.182zm0-8.643v-9.602s-1.7.24-1.458-1.681c.08-4.001 10.667 7.303 16.093 10.984l-.12 7.682zm-17.739 32.653v-18.966l-34.98-1.67 4.374 20.4zm.485-23.29-.242-13.443-31.822-12.484-.487 22.807zm-.97-17.997 3.573-3.686s-2.301-1.567-2.187-3.009c.043-1.458 2.314-1.68 2.358-3.262.041-1.458-2.573-1.633-2.601-3.092-.171-1.581 2.015-3.277 2.015-3.277l-22.591-19.446-5.588 23.769zm106.882 41.287-.242-18.485 35.464-2.641-2.915 21.126zm33.097-26.7c-.408-3.001-.836-5.995-1.531-8.938a91.583 91.583 0 0 0-3.563-11.594c-.106-.278-.265-.534-.375-.812l-27.625 11.562.25 13.688zm-18.88-44.44-16.531 12.5c.027 1.196 1.22 2.116 1.548 3.26.763 1.607-.753 3.027-1.692 4.162-.76 1.047.879 1.58 1.424 2.199 1.2 1.232.154 3.162-1.124 3.879 1.54.92 2.318 2.645 2.656 4.344l24.75-12.875c-2.96-6.236-6.651-12.133-11.031-17.469zm-32.185 47.37.242-5.523-14.087 6.123zm-13.915-8.641s13.116-11.703 15.91-13.864c0 2.4-1.518 4.26-1.518 4.26v9.124l-14.332 6.721zm-84.95-46.329 14.718 16.14c.406-1.507 3.744-1.736 7.144-1.495 3.402.24 6.113-.226 6.113 2.175 0 2.4-1.726 2.06-1.726 3.74s2.586 1.54 2.586 3.699c0 2.16-1.875 1.72-1.885 3.393-.006 1.383 1.956 1.456 1.956 1.456l13.785 13.205.06-14.105-28.42-44.052zm22.74-16.68 19.25 38.906s.216-35.913 3.46-37.837l-5.406-9.834zm62.13-4.13-7.188 24.844a38.91 38.91 0 0 0 6.756.214c2.025.174.448 2.072.556 3.195l-.03 15.278 20.312-35.875c-4.67-2.438-9.627-4.248-14.616-5.905a67.163 67.163 0 0 0-5.79-1.751zm25.28 10.38-25.125 38.625-.031 14.875c5.594-5.322 11.217-10.617 16.794-15.957 1.058-1.499-3.36-2.233-1.152-4.059 2.001-.63 2.34-3.863.233-4.484-1.735-2.548 1.57-3.899 3.654-3.62 2.9.133 5.888-.631 8.725.174 1.561 1.493 3-2.255 4.532-3.099 3.03-3.047 6.063-6.092 9.058-9.173a80.56 80.56 0 0 0-16.688-13.282z'
			/>
			<g fill='#083d9c' stroke='#083d9c' strokeWidth={1.929}>
				<path
					strokeWidth={2.053}
					d='M279.88 275.41c-6.178.349-12.334 4.518-15.219 6.75 5.18 2.267 10.71 4.037 16.594 5.25 9.375 1.574 18.75 1.563 28.125 1.563 1.53-.193 3.045-.411 4.531-.657l19.75-6.656s-9.5-6.271-17.03-6c-7.532.271-12.616 7.638-18.689 7.094-6.072-.544-8.505-7.344-17.25-7.344-.273 0-.539-.015-.812 0z'
				/>
				<path d='M356.24 270.79s-9.716-10.323-21.134-10.563c-11.417-.241-8.258 4.802-17.003 5.52-8.744.722-10.932-5.28-18.948-5.04-8.015.239-12.632 5.04-18.703 5.282-6.073.24-13.846-6.003-18.462-5.762-4.616.24-21.134 7.202-21.134 7.202l3.154 2.881zM229.68 257.1l138.46.481c2.186-3.12-6.802-10.563-15.06-11.284-6.804.24-11.66 6.962-17.247 7.203-5.587.24-11.902-6.963-18.219-6.721-6.315.24-12.874 6.721-19.19 6.721-6.315 0-10.931-6.963-18.946-6.963-8.016 0-11.66 7.682-17.733 7.203-6.074-.48-11.418-7.682-17.247-7.682-5.83 0-15.546 8.642-17.489 8.161-1.943-.48 2.671 2.881 2.671 2.881zm53.53-13.24 30.908-.018s-6.92-10.55-15.449-9.823c-9.502.246-15.459 9.84-15.459 9.841zm98.56-.74h-42.294s5.474-3.197 6.966-6.147c2.736 1.475 1.99 2.95 7.464 3.196 5.473.246 10.697-6.146 15.923-5.9 5.224.245 11.941 8.851 11.941 8.851zm-166.67 0h42.294s-5.474-3.197-6.966-6.147c-2.736 1.475-1.99 2.95-7.464 3.196-5.472.246-10.697-6.146-15.923-5.9-5.224.245-11.941 8.851-11.941 8.851zm33.281-12.9s-1.943-4.08-2.186-9.122c-7.775-2.64-14.09 5.761-19.676 6.001-5.586.24-11.415-6.001-11.415-6.001l3.156 8.882zm130.689-.24-30.122.24s1.944-4.08 2.186-9.122c7.775-2.64 14.09 5.761 19.677 6.001 5.587.24 11.416-6.001 11.416-6.001zm-88.18.24 15.302.008s.244-5.049-7.773-5.049c-8.016 0-7.529 5.041-7.529 5.041z' />
			</g>
			<g stroke='#630810'>
				<path
					fill='#ce1126'
					strokeLinejoin='round'
					strokeWidth={1.929}
					d='m282.87 181 31.823 8.403v-44.895c-14.576.721-26.72-27.368-.972-29.288-25.263-3.601-28.179 2.881-31.093 9.843l.243 55.937zm7.07 40.58c-6.921 11.33-25.425 7.97-29.752.06-1.296-.363-.53-48.8-.53-48.8s-2.067-.93-2.163-2.42c-.094-1.504 2.812-1.64 2.812-3.565 0-1.924-2.968-1.17-3.027-3.106.012-1.852 3.168-1.597 3.027-3.308-.167-1.928-3.534-1.643-3.677-3.42-.11-1.413 2.408-2.644 3.108-3.28l-2.35-.033-5.3.107c-3.766.004.064.811.01 2.958-.035 1.403-1.91 2.336-2.078 3.556-.06 1.252 2.683 2.131 2.717 3.634.032 1.34-2.694 1.432-2.596 2.677.17 2.109 2.414 2.573 2.38 3.85-.034 1.274-3.02 1.76-3.028 2.778.104 1.968.432 49.807.432 49.807 4.759 24.37 32.228 30.57 40.015-1.495zm18.11 0c6.921 11.33 25.424 7.97 29.751.06 1.296-.363.53-48.8.53-48.8s2.068-.93 2.163-2.42c.094-1.504-2.63-1.64-2.63-3.565 0-1.924 2.787-1.17 2.845-3.106-.012-1.852-2.925-1.716-2.784-3.428.166-1.928 2.454-1.703 2.584-3.48.109-1.531-1.436-2.464-2.136-3.099l2.23-.034 5.299.107c3.766.004-.065.811-.01 2.958.035 1.403 1.91 2.336 2.078 3.556.059 1.252-2.684 2.131-2.718 3.634-.03 1.34 2.695 1.432 2.596 2.678-.17 2.108-2.414 2.572-2.38 3.848.034 1.275 3.021 1.761 3.028 2.779-.103 1.968-.431 49.807-.431 49.807-4.76 24.37-32.228 30.57-40.015-1.495z'
				/>
				<g fill='#630810' strokeLinecap='round' strokeWidth={2.787}>
					<path d='M281.66 223.73c2.672-1.442 5.101-2.88 6.802-6.243l-10.446.24s-4.856 2.882-7.287 6.003zm33.76 0c-2.672-1.442-5.1-2.88-6.801-6.243l10.445.24s4.857 2.882 7.286 6.003zm-52.71-10.08 71.903.24' />
					<path
						id='regionFrenchPolynesia_svg__b'
						strokeWidth={2.787}
						d='m266.11 196.61 8.502 11.284m-8.502-.004 9.232-11.043m-4.372-.967-.243 6.962'
					/>
					<use
						xlinkHref='#regionFrenchPolynesia_svg__b'
						width='100%'
						height='100%'
						x={13.604}
					/>
					<use
						xlinkHref='#regionFrenchPolynesia_svg__b'
						width='100%'
						height='100%'
						x={27.45}
					/>
					<use
						xlinkHref='#regionFrenchPolynesia_svg__b'
						width='100%'
						height='100%'
						x={41.782}
					/>
					<use
						xlinkHref='#regionFrenchPolynesia_svg__b'
						width='100%'
						height='100%'
						x={55.628}
					/>
				</g>
			</g>
		</g>
	</svg>
);
export default SvgRegionFrenchPolynesia;
