import * as React from 'react';
import { SVGProps } from 'react';
const SvgSer = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#6DC6C1' />
			<g fill='#FFF'>
				<path d='M16 22.651c-3.263 0-5.912-2.589-5.912-5.777 0-3.1 5.352-8.573 5.579-8.803l.28-.288.288.28c.234.23 5.677 5.704 5.677 8.804 0 3.195-2.65 5.784-5.912 5.784zM15.955 8.9a39.9 39.9 0 0 0-2.43 2.826c-1.734 2.234-2.65 4.009-2.65 5.14 0 2.76 2.301 5.009 5.125 5.009s5.125-2.25 5.125-5.008c0-1.132-.931-2.907-2.695-5.141A41.394 41.394 0 0 0 15.955 8.9z' />
				<path d='M16 24.5c-4.307 0-7.804-3.424-7.804-7.626 0-4.113 5.874-9.853 6.124-10.09a.409.409 0 0 1 .56 0c.151.148.151.4 0 .548-.06.059-5.89 5.755-5.89 9.542 0 3.78 3.15 6.857 7.018 6.857s7.017-3.077 7.017-6.857c0-1.553-1.03-3.699-2.983-6.221-1.499-1.93-3.005-3.395-3.02-3.41a.374.374 0 0 1 0-.548.395.395 0 0 1 .56 0c.257.245 6.238 6.059 6.238 10.18-.016 4.2-3.513 7.626-7.82 7.626z' />
				<path d='M16 26.35c-5.238 0-9.5-4.165-9.5-9.284 0-2.115 1.09-4.72 3.232-7.73a34.564 34.564 0 0 1 3.202-3.869.402.402 0 0 1 .56-.007.38.38 0 0 1 .008.547 34.761 34.761 0 0 0-3.126 3.78c-2.021 2.834-3.089 5.349-3.089 7.28 0 4.69 3.906 8.514 8.713 8.514 4.807 0 8.713-3.817 8.713-8.515 0-1.93-1.075-4.445-3.111-7.279a34.89 34.89 0 0 0-3.157-3.78.38.38 0 0 1 .008-.547.402.402 0 0 1 .56.007c.265.267 6.487 6.584 6.487 11.6 0 5.118-4.262 9.283-9.5 9.283z' />
			</g>
		</g>
	</svg>
);
export default SvgSer;
