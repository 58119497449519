import * as React from 'react';
import { SVGProps } from 'react';
const SvgCc = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#36B0F3' fillRule='nonzero' />
			<g fill='#FFF'>
				<path d='M14.09 4.261c.8-.16 1.615-.229 2.43-.206.508 0 1.016.046 1.517.137.975.134 1.915.444 2.832.792.667.298 1.366.578 1.908 1.084.151.11.07.33-.064.417-.31.199-.683.256-1.037.334-1.596.274-3.22.397-4.788.822-.703.158-1.379.416-2.048.683-1.243.556-2.386 1.373-3.213 2.465a16.31 16.31 0 0 0-.456.705c-.131.255-.263.51-.37.777-.08.191-.154.384-.224.578-.141.413-.21.844-.315 1.268-.033.278-.052.558-.11.833-.037.63-.019 1.26-.014 1.89 0 .21.057.416.06.627.003.194.03.387.082.574.1.525.222 1.057.445 1.548.289.697.739 1.35 1.379 1.765.531.394 1.188.562 1.832.662.86.112 1.733.01 2.58-.144.443-.08.883-.18 1.32-.297.277-.078.562-.13.836-.223a2.08 2.08 0 0 1 .318-.03c-.343.306-.688.61-1.076.857-1.322.852-2.859 1.296-4.392 1.582-.693.142-1.398.226-2.096.346-.795.115-1.619.21-2.336.6-.214.15-.38.354-.529.566-.045.092-.13 0-.18-.033-.163-.187-.372-.324-.549-.497a20.82 20.82 0 0 1-.556-.556c-.291-.339-.59-.673-.847-1.038-.512-.694-.965-1.437-1.297-2.233l-.058-.026c-.042-.229-.158-.431-.235-.648a10.585 10.585 0 0 1-.44-1.46 3.77 3.77 0 0 1-.103-.462 2.268 2.268 0 0 1-.096-.54c-.098-.43-.08-.874-.142-1.308 0-.362-.024-.727.02-1.088.048-.3.028-.607.09-.906.061-.243.049-.497.126-.736a9.775 9.775 0 0 1 .42-1.572c.072-.188.137-.378.204-.567.04-.087.073-.176.1-.267l.053-.063a2.97 2.97 0 0 1 .247-.54 8.124 8.124 0 0 1 .325-.612c.034-.071.072-.14.112-.21.63-.987 1.366-1.922 2.252-2.694a12.59 12.59 0 0 1 3.729-2.299 11.95 11.95 0 0 1 2.355-.657z' />
				<path d='M23.241 7.39c.22-.128.371-.335.523-.532a12.003 12.003 0 0 1 1.3 16.95c-1.837 2.158-4.475 3.624-7.286 4.013-1.293.19-2.61.14-3.902-.037-.99-.14-1.958-.41-2.877-.805-.5-.22-.997-.478-1.388-.868-.101-.088-.165-.234-.098-.361.1-.155.28-.232.45-.287.485-.14.984-.222 1.483-.299.902-.157 1.813-.258 2.708-.455 1.487-.321 2.974-.776 4.26-1.61.874-.558 1.644-1.284 2.211-2.154.814-1.228 1.19-2.699 1.28-4.157.045-1.085.02-2.18-.207-3.246-.207-.92-.57-1.847-1.257-2.517-.525-.539-1.24-.874-1.978-1.002-1.225-.229-2.476-.008-3.676.262-.562.133-1.116.3-1.684.41.808-.761 1.797-1.305 2.836-1.677.597-.221 1.217-.368 1.834-.513 1.352-.304 2.736-.42 4.1-.654.476-.1.96-.196 1.368-.461z' />
			</g>
		</g>
	</svg>
);
export default SvgCc;
