import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionSlovakia = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 900 600'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#ee1c25' d='M0 0h900v600H0z' />
		<path fill='#0b4ea2' d='M0 0h900v400H0z' />
		<path fill='#fff' d='M0 0h900v200H0z' />
		<path
			fill='#fff'
			d='M393.479 141H146.358l-.847 8.059c-.184 1.752-4.511 43.971-4.511 136.69 0 43.563 14.726 82 43.77 114.23 20.349 22.585 46.923 41.6 81.242 58.129l3.9 1.881 3.906-1.881c34.32-16.53 60.9-35.544 81.246-58.129 29.044-32.234 43.771-70.667 43.771-114.23 0-92.718-4.328-134.938-4.512-136.69l-.844-8.059z'
		/>
		<path
			fill='#ee1c25'
			d='M269.917 450C220.577 426.234 150 379.062 150 285.749S154.461 150 154.461 150h230.915s4.463 42.437 4.463 135.749S319.261 426.234 269.917 450z'
		/>
		<path
			fill='#fff'
			d='M280.484 261.284c13.36.216 39.425.739 62.635-7.027 0 0-.613 8.306-.613 17.98s.613 17.982.613 17.982c-21.289-7.125-47.58-7.273-62.635-7.082v51.539h-21.1v-51.539c-15.055-.191-41.345-.043-62.635 7.082 0 0 .613-8.305.613-17.982s-.613-17.98-.613-17.98c23.21 7.766 49.275 7.243 62.635 7.027v-32.368c-12.177-.108-29.723.473-49.563 7.113 0 0 .613-8.305.613-17.982s-.613-17.981-.613-17.981c19.81 6.628 37.336 7.219 49.509 7.114-.626-20.5-6.6-46.332-6.6-46.332s12.289.959 17.2.959 17.2-.959 17.2-.959-5.969 25.835-6.6 46.33c12.174.106 29.7-.485 49.509-7.114 0 0-.613 8.305-.613 17.981s.613 17.982.613 17.982c-19.839-6.639-37.386-7.221-49.563-7.113v32.37z'
		/>
		<path
			fill='#0b4ea2'
			d='M269.9 329.094c-24.852 0-38.163 34.469-38.163 34.469a30.466 30.466 0 0 0-27.661-16.344c-13.719 0-23.829 12.2-30.256 23.5 24.959 39.7 64.78 64.207 96.079 79.281 31.305-15.075 71.152-39.571 96.112-79.281-6.427-11.3-16.537-23.5-30.256-23.5a30.539 30.539 0 0 0-27.693 16.344s-13.308-34.469-38.162-34.469z'
		/>
	</svg>
);
export default SvgRegionSlovakia;
