import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar37 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar37_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>{'.avatar37_svg__cls-3{fill:#515570}.avatar37_svg__cls-5{fill:#393c54}'}</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#a7aece',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.2,
				fill: '#515570',
			}}
		/>
		<path
			d='M64 124a59.67 59.67 0 0 0 34.69-11.06l-3.32-9.3A10 10 0 0 0 86 97H42.05a10 10 0 0 0-9.42 6.64l-3.32 9.3A59.67 59.67 0 0 0 64 124Z'
			className='avatar37_svg__cls-3'
		/>
		<path
			d='M64 84.75V98.5'
			style={{
				strokeWidth: 14,
				strokeLinejoin: 'round',
				stroke: '#fbc0aa',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M64 124a59.77 59.77 0 0 0 41.6-16.79c-2.28-7.34-7.31-21-7.31-24.28V48.32A34.32 34.32 0 0 0 64 14a34.31 34.31 0 0 0-34.35 34.32v34.61c0 3.39-4.62 17.82-6.54 25A59.78 59.78 0 0 0 64 124Z'
			className='avatar37_svg__cls-5'
		/>
		<path
			d='M64 91a25 25 0 0 1-25-25V49.52a25 25 0 1 1 50 0V66a25 25 0 0 1-25 25Z'
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<path
			d='M35.67 49.19a28.33 28.33 0 0 1 56.66 0L92 51.48l-56 .27Z'
			className='avatar37_svg__cls-5'
		/>
		<path
			d='M44.22 38.85a21.67 21.67 0 0 1 19.13-13.51'
			style={{
				strokeWidth: 4,
				opacity: 0.1,
				stroke: '#fff',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={76} cy={58.28} r={3} className='avatar37_svg__cls-3' />
		<circle cx={52} cy={58.28} r={3} className='avatar37_svg__cls-3' />
		<path
			d='M64 63.75v4.5'
			style={{
				strokeWidth: 5,
				strokeLinejoin: 'round',
				stroke: '#fbc0aa',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M69.15 78.94c0 2-2.3 3.16-5.15 3.16s-5.15-1.52-5.15-3.16c0-2.84 2.48-3.94 5.15-3.15 2.58-.87 5.15.31 5.15 3.15Z'
			style={{
				fill: '#f85565',
				opacity: 0.5,
			}}
		/>
		<path
			d='M58 77.87a16.83 16.83 0 0 0 12 0'
			style={{
				strokeWidth: 2,
				stroke: '#f85565',
				strokeLinejoin: 'round',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M86.42 48.48a118.24 118.24 0 0 0-44.84 0A3.21 3.21 0 0 0 39 51.64V55a105.67 105.67 0 0 1 50 0v-3.36a3.21 3.21 0 0 0-2.58-3.16Z'
			className='avatar37_svg__cls-3'
		/>
		<path
			d='M92.36 65.68a28.34 28.34 0 1 1-56.67 0s19.19-1.29 26.16-3.83a6.23 6.23 0 0 1 4.3 0c6.98 2.54 26.21 3.83 26.21 3.83Z'
			className='avatar37_svg__cls-5'
		/>
		<path
			d='M35.69 116.89v-67.7a28.33 28.33 0 0 1 56.66 0v67.67'
			style={{
				opacity: 0.7,
				strokeWidth: 2,
				stroke: '#fff',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
	</svg>
);
export default SvgAvatar37;
