import * as React from 'react';
import { SVGProps } from 'react';
const SvgSportIconSoccer = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		aria-hidden='true'
		className='svg-icon'
		viewBox='0 0 64 64'
		{...props}>
		<circle cx={32} cy={32} r={29.3} fill='#fff' />
		<path
			fill='#4a4e51'
			d='M61.9 32c0-.7.2-10.9-5.8-17.5-.3-.6-1.5-3-5.6-5.9C47.8 6.5 45 5 44.7 4.8 44.4 4.6 39.4 2 33.4 2c-.5 0-.9 0-1.4.1-4.6-.1-8.8 1.1-11.9 2.5-3.2 1.4-5.3 2.8-5.5 3-3.4 1.9-9.9 9.5-10.4 13.6-2.1 2.6-3.8 14.5 0 21.7 2.7 10 12.7 15 13.5 15.4.5.3 5.9 3.7 12.6 3.7h.9c.6.1 1.1.1 1.7.1 7.2 0 18-5.1 20.2-9.1 6.2-4.6 9.4-16.2 8.8-21M17.8 47.1c-2.9-4.6-4.5-10.7-4.9-12.1.9-1.4 5.4-8 7.9-10 1.4.3 7.5 1.4 13.2 2.4.7 1.9 3.9 10 4.8 13.2-1 1.2-4.9 5.7-8.7 9.2-4.1.1-11-2.3-12.3-2.7m36-32.5c0 .4-.1 2-.9 3.9-1.5-.8-5.3-2.4-10.6-2.7-.8-1.2-3.8-5.3-8.5-8.1.6-1.3 1.5-2.8 2.1-3.3.2 0 .4-.1.8-.1 2.5 0 6.9 1.7 7.3 1.8.4.2 8.3 4.4 9.8 8.5M11.8 34c-3.4-.6-5.5-1.6-6.1-2-1.3-4.6-.2-9.6-.1-10.3 1.3-2.2 4.8-8 7.2-9.1 2.4-.5 5.5.1 6.7.4-.1 1.6-.3 6.1.3 10.9-2.6 2.2-6.9 8.5-8 10.1M31.7 3.5c.8.1 1.9.2 2.7.5-.8 1-1.6 2.5-1.9 3.3-1.6.3-7.5 1.4-12.2 4.4-.9-.2-3.8-.9-6.5-.7.7-1.3 1.7-2.2 1.8-2.3.3-.3 7.4-5.3 16.1-5.2m19.1 38.1c-1.2 0-5.7-.3-10.6-1.5-.9-3.3-4.1-11.4-4.8-13.3 3.1-4.4 6.1-8.5 6.9-9.7 5.7.4 9.7 2.5 10.5 2.9 3.3 5.3 4 10.7 4.1 11.6-1.8 5.5-5.2 9.2-6.1 10M3.7 28.5c.1 1.3.3 2.6.7 3.9-.3.9-.6 1.8-.7 2.7-.3-2.3-.3-4.6 0-6.6M18.5 57l-.4.6.4-.6c-2.5-1.2-4.4-4-5.2-5.1 1.5-1.5 3.4-2.9 4.1-3.4 1.6.6 8.3 2.8 12.6 2.8.7 1 3.1 4 6 6.4-1.8 1.8-4.4 2.6-4.9 2.8-6.8.2-12.6-3.5-12.6-3.5m16.3 3.4c.9-.5 1.9-1.2 2.7-2.1 1.3-.2 6.9-1.1 11.9-4.8.3 0 .9.1 1.5.1-3.1 2.9-10.5 6.2-16.1 6.8M50.2 52c1.8-4.7 1.7-8.3 1.6-9.4 1-1 4.4-4.6 6.3-10.1 1 .2 1.7.4 2 .6.1.4.3 1.3.2 2.7-.8 5-3.4 12.6-8.1 15.9-.5.3-1.3.4-2 .3'
		/>
	</svg>
);
export default SvgSportIconSoccer;
