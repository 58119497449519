import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import LANG from '../lang';

const LangSelector = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState(i18n.language);

  const languages = Object.values(LANG);

  const changeLanguage = (langCode: string) => {
    i18n.changeLanguage(langCode);
    setSelectedLang(langCode);
    setIsOpen(false);
  };

  const selectedLangData: any =  useMemo(() =>languages.find((lang: any) => lang.lng === i18n.language), [i18n.language]);

  return (
    <div className={`form-control-bc select compact-mode filled ${isOpen ? 'open' : ''}`} tabIndex={0} onClick={() => setIsOpen(!isOpen)}>
      <div className="form-control-label-bc form-control-select-bc inputs">
        <i className={`select-icon-bc ftr-lang-bar-flag-bc flag-bc ${selectedLangData?.icon}`}></i>
        <span className="ellipsis">{selectedLangData?.text}</span>
        <i className={`form-control-icon-bc ${!isOpen ? 'bc-i-small-arrow-down' : 'bc-i-small-arrow-up'}`}></i>
        <i className="form-control-input-stroke-bc"></i>
      </div>
      {isOpen && (
        <div className="multi-select-label-bc" data-scroll-lock-scrollable="">
          {languages.map((lang: any) => (
            <label key={lang.lng} className={`checkbox-control-content-bc ${selectedLang === lang.lng ? 'active' : ''}`} onClick={() => changeLanguage(lang.lng)}>
              <i className={`multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc ${lang.icon}`}></i>
              <p className="checkbox-control-text-bc ellipsis" style={{ pointerEvents: 'none' }}>{lang.text}</p>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default LangSelector;
