import React, { useCallback, useContext, useMemo, useState } from "react";
import BalanceComponent from "./Balance";
import CarouselComponent from "../../component/Carosel";
import UserAvatar from "./UserAvatar";
import AuthContext from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import MenuList from "./menuItems";
import { useLocation, useNavigate } from "react-router-dom";
import UserRouter from "./UserRouter";

const MobileUserControl = () => {
  const [activeMenu, setActiveMenu] = useState(null);

  const [promoCode, setPromoCode] = useState("");
  const { sendLogoutRequest } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const menuItems: any = MenuList();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const account = searchParams.get("account");
  const pageType = searchParams.get("page");

  const toggleMenu = (index: any) => {
    setActiveMenu(activeMenu === index ? null : index);
  };

  const { t } = useTranslation();
  console.log("account", menuItems);
  const activatedMenu = useCallback(() => {
   
    const findMenu = menuItems.find((item: any) => item.account === account);
    const findSubMenu = findMenu?.subItems.find(
      (item: any) => item.page === pageType
    );
    return findSubMenu || findMenu;
  }, [account, pageType])();
  // get active submenu details 

  

  console.log("activatedMenu", activatedMenu);

  return (
    <div className="u-i-p-c-body-bc">
      {!pageType ? (
        <div className="u-i-profile-page-bc">
          <BalanceComponent />

          {/*         
                <a className="u-i-p-a-loyaltyPoint-bc" href="/?profile=open&account=bonuses&page=loyalty-points">
                    <p className="ellipsis">Loyalty Points</p>
                    <span>0/0</span>
                </a> */}
          <UserAvatar />
          <div className="u-i-p-p-u-i-edit-button-bc">
            <i className="account-verified-edit-icon bc-i-verified"></i>
            <span className="account-verified-edit-title ellipsis">
              Account Verified
            </span>
          </div>
          <div className="u-i-p-links-lists-holder-bc">
            {menuItems.map((item: any, index: any) => {
              if (activeMenu !== null && index !== activeMenu) return null;
              return (
                <div key={index}>
                  <div
                   
                    className="u-i-p-l-head-bc"
                    onClick={() => toggleMenu(index)}
                  >
                    {activeMenu === index ? (
                      <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-left"></i>
                    ) : (
                      <i className={`user-nav-icon ${item.iconClass}`}></i>
                    )}

                    <span className="u-i-p-l-h-title-bc ellipsis">
                      {item.title}
                    </span>
                    {item.badge && (
                      <i
                        className="count-odd-animation count-blink-even"
                        data-badge={item.badge}
                      ></i>
                    )}
                    {activeMenu === null && (
                      <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right"></i>
                    )}
                  </div>
                  {activeMenu === index && item.subItems && (
                    <div className="u-i-p-l-body-bc">
                      {item.subItems.map((subItem: any, subIndex: any) => (
                        <span
                          key={subIndex}
                          className="u-i-p-l-head-bc"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate({
                              search: `?profile=open&account=${subItem.account}&page=${subItem.page}`,
                            });
                          }}
                        >
                          <i className={`user-nav-icon ${subItem.iconClass}`}></i>
                          <span className="u-i-p-l-h-title-bc ellipsis">
                            {subItem.title}
                          </span>
                          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right"></i>
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="promoCodeWrapper-bc profile-panel-promo-code">
            <form>
              <div className="u-i-p-control-item-holder-bc">
                <div className="form-control-bc default ">
                  <label className="form-control-label-bc inputs">
                    <input
                      type="text"
                      className="form-control-input-bc"
                      name="promoCode"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                      
                    />
                    <i className="form-control-input-stroke-bc"></i>
                    <span className="form-control-title-bc ellipsis">
                      {t('PromoCode')}
                    </span>
                  </label>
                </div>
              </div>
              <div className="u-i-p-c-footer-bc">
                <button
                  className="btn a-color big-btn "
                  type="submit"
                  title="Apply"
                >
                  <span>{t('Apply')}</span>
                </button>
              </div>
            </form>
          </div>
          <div className="user-logout-bc">
            <button
              className="btn "
              type="button"
              title={t("Logout")}
              onClick={sendLogoutRequest}
            >
              <span>{t("Logout")}</span>
            </button>
          </div>
        </div>
      ) : (

      <>
        <div
          className="back-nav-bc"
          onClick={() =>
            navigate({
              search: `?profile=open`,
            })
          }
        >
          <i className="back-nav-icon-bc bc-i-round-arrow-left"></i>
          <span className="back-nav-title-bc ellipsis">
            {activatedMenu?.title}
          </span>
        </div>
        <UserRouter />
      </>
      
      )}
    </div>
  );
};

export default MobileUserControl;
