import React, { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import Content from '../Content/Content';
import HeaderRoutes from '../Header/HeaderRoutes';
import FooterRoutes from '../Footer/FooterRoutes';
import NavigationRoutes from '../Navigation/NavigationRoutes';


interface IWrapperContainerProps {
    children: ReactNode;
    className?: string;
}
export const WrapperContainer: FC<IWrapperContainerProps> = ({ children, className, ...props }) => {

    return (
        <>
            {children}
        </>
    );
};
WrapperContainer.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
WrapperContainer.defaultProps = {
    className: undefined,
};

const Wrapper = () => {
    return (
      
            <WrapperContainer>
                <HeaderRoutes />
                <Content />
                <FooterRoutes />
                <NavigationRoutes />
         
            </WrapperContainer>
          
     
    );
};

export default Wrapper;
