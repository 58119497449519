import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionNetherlands = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 9 6'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#21468B' d='M0 0h9v6H0z' />
		<path fill='#FFF' d='M0 0h9v4H0z' />
		<path fill='#AE1C28' d='M0 0h9v2H0z' />
	</svg>
);
export default SvgRegionNetherlands;
