import * as React from 'react';
import { SVGProps } from 'react';
const SvgSportIconDarts = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 511.974 511.974'
		className='svg-icon'
		{...props}>
		<path
			d='M450.867 185.987c-11.828-27.952-28.732-53.044-50.295-74.59-21.545-21.546-46.638-38.467-74.574-50.279-28.936-12.249-59.676-18.452-91.346-18.452-31.678 0-62.403 6.203-91.339 18.452-27.944 11.812-53.044 28.733-74.59 50.279-21.546 21.546-38.459 46.639-50.278 74.59C6.203 214.924 0 245.656 0 277.326c0 31.671 6.203 62.403 18.444 91.34 11.82 27.936 28.733 53.044 50.279 74.59 21.545 21.545 46.646 38.451 74.59 50.278 28.936 12.233 59.661 18.437 91.339 18.437 31.67 0 62.41-6.203 91.346-18.437 27.937-11.827 53.029-28.733 74.574-50.278 21.562-21.546 38.467-46.654 50.295-74.59 12.233-28.937 18.437-59.669 18.437-91.34-.001-31.67-6.204-62.402-18.437-91.339z'
			style={{
				fill: '#ed5564',
			}}
		/>
		<path
			d='M347.779 164.192c-30.218-30.217-70.388-46.857-113.127-46.857-42.74 0-82.917 16.64-113.135 46.857S74.66 234.595 74.66 277.326c0 42.732 16.64 82.902 46.857 113.135 30.217 30.218 70.395 46.857 113.135 46.857 42.739 0 82.909-16.64 113.127-46.857 30.232-30.232 46.872-70.402 46.872-113.135 0-42.732-16.64-82.917-46.872-113.134z'
			style={{
				fill: '#e6e9ed',
			}}
		/>
		<path
			d='M234.652 191.987c-47.052 0-85.332 38.279-85.332 85.339 0 47.045 38.279 85.324 85.332 85.324 47.052 0 85.331-38.279 85.331-85.324 0-47.06-38.279-85.339-85.331-85.339z'
			style={{
				fill: '#ed5564',
			}}
		/>
		<path
			d='M511.379 82.555a10.676 10.676 0 0 0-8.89-7.062l-59.403-6.608-6.594-59.388a10.67 10.67 0 0 0-7.077-8.891 10.691 10.691 0 0 0-11.077 2.516L342.92 78.556a10.616 10.616 0 0 0-3.062 6.484l-5.453 54.888a10.7 10.7 0 0 0 5.25 10.28 10.667 10.667 0 0 0 5.375 1.438c2.156 0 4.312-.641 6.155-1.953l37.779-26.686-26.687 37.795a10.645 10.645 0 0 0-.5 11.515 10.628 10.628 0 0 0 9.203 5.297c.359 0 .703-.016 1.062-.047l54.903-5.438a10.672 10.672 0 0 0 6.483-3.078l75.418-75.417c2.908-2.892 3.876-7.204 2.533-11.079z'
			style={{
				fill: '#f6bb42',
			}}
		/>
		<path
			d='M234.652 287.982a10.592 10.592 0 0 1-7.546-3.125c-4.164-4.156-4.164-10.905.008-15.077l32.841-32.842c4.164-4.172 10.922-4.172 15.093 0 4.156 4.156 4.156 10.921 0 15.077l-32.857 32.842a10.58 10.58 0 0 1-7.539 3.125z'
			style={{
				fill: '#434a54',
			}}
		/>
		<path
			d='M440.976 71.009c-1.812-1.812-5.016-3.968-9.999-3.968-9.812 0-28.452 8.608-97.761 71.043-37.623 33.904-72.824 68.246-73.168 68.59a10.705 10.705 0 0 0-3.218 7.641v30.154c0 5.891 4.781 10.672 10.671 10.672h30.17a10.61 10.61 0 0 0 7.625-3.219c.375-.375 37.842-38.764 73.199-78.293 20.889-23.358 36.998-42.576 47.888-57.138 6.812-9.108 11.562-16.327 14.53-22.108 1.97-3.828 7.954-15.484.063-23.374z'
			style={{
				fill: '#ffce54',
			}}
		/>
	</svg>
);
export default SvgSportIconDarts;
