import React, { useState } from "react";

interface PaymentMethod {
  id: number;
  name: string;
  fee: string;
  processingTime: string;
  minAmount: string;
  maxAmount: string;
  logo: string;
  info: string;
}

interface Field {
  name: string;
  label: string;
  type: string;
}

interface PaymentMethodComponentProps {
  type?: "deposit" | "withdraw";
  fields?: Field[];
}

const defaultFields: Field[] = [
  { name: "field1", label: "Adı", type: "text" },
  { name: "field2", label: "Soyadı", type: "text" },
  { name: "field3", label: "Kullanıcı Adı", type: "text" },
  { name: "field4", label: "Doğum Tarihi", type: "text" },
  { name: "field5", label: "IBAN", type: "text" },
  { name: "field6", label: "Banka Hesap No / Şube Kodu", type: "text" },
  { name: "field7", label: "Cep No", type: "text" },
  { name: "field8", label: "Kimlik No", type: "text" },
  { name: "amount", label: "Tutar", type: "decimal" },
];

const depositMethods: PaymentMethod[] = [
  {
    id: 1,
    name: "Aninda_Papara",
    fee: "Ücretsiz",
    processingTime: "Anlık",
    minAmount: "1 ₺",
    maxAmount: "100000 ₺",
    logo: "https://cmsbetconstruct.com/content/images/payments/custom/750/13143.png",
    info: "deposit_info_Aninda_Papara",
  },
  {
    id: 2,
    name: "Aninda_Havale",
    fee: "Ücretsiz",
    processingTime: "Anlık",
    minAmount: "10 ₺",
    maxAmount: "50000 ₺",
    logo: "https://cmsbetconstruct.com/content/images/payments/custom/750/2.png",
    info: "deposit_info_Aninda_Havale",
  },
];

const withdrawMethods: PaymentMethod[] = [
  {
    id: 1,
    name: "Havale",
    fee: "Ücretsiz",
    processingTime: "1-45 Dakika",
    minAmount: "200 ₺",
    maxAmount: "100000 ₺",
    logo: "https://cmsbetconstruct.com/content/images/payments/custom/750/2.png",
    info: "withdraw_info_Havale",
  },
  {
    id: 2,
    name: "Papara",
    fee: "Ücretsiz",
    processingTime: "Anlık",
    minAmount: "50 ₺",
    maxAmount: "50000 ₺",
    logo: "https://cmsbetconstruct.com/content/images/payments/custom/750/13140.png",
    info: "withdraw_info_Papara",
  },
];

const PaymentMethodComponent: React.FC<PaymentMethodComponentProps> = ({
  type,
  fields = defaultFields,
}) => {
  const methods = type === "deposit" ? depositMethods : withdrawMethods;
  const [selectedMethod, setSelectedMethod] = useState<PaymentMethod | null>(
    methods[0]
  );

  return (
    <>
      <div className="dep-w-info-bc" data-scroll-lock-scrollable="">
        <div className="m-block-nav-items-bc">
          {methods.map((method) => (
            <div
              key={method.id}
              className={`m-nav-items-list-item-bc ${
                selectedMethod?.id === method.id ? "active" : ""
              }`}
              onClick={() => setSelectedMethod(method)}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  setSelectedMethod(method);
                }
              }}
            >
              <div className="nav-ico-w-row-bc">
                <img
                  className="payment-logo"
                  src={method.logo}
                  alt={method.name}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedMethod && (
        <div
          className="payment-details-scrollable-container"
          data-scroll-lock-scrollable=""
        >
          <div className="payment-info-bc" data-scroll-lock-scrollable="">
            <div className={`description-c-row-bc ${type}`}>
              <div className="description-c-row-column-bc texts">
                <div className="description-c-row-c-title-bc">
                  <div className="description-c-r-c-t-column-bc">
                    <span
                      className="description-title ellipsis"
                      title="Yatırım Yöntemi"
                    >
                      Yatırım Yöntemi
                    </span>
                    <span className="description-value">
                      {selectedMethod.name}
                    </span>
                  </div>
                  <div className="description-c-r-c-t-column-bc">
                    <span className="description-title ellipsis" title="Ücret">
                      Ücret
                    </span>
                    <span className="description-value">
                      {selectedMethod.fee}
                    </span>
                  </div>
                  <div className="description-c-r-c-t-column-bc">
                    <span
                      className="description-title ellipsis"
                      title="İşlem Süresi"
                    >
                      İşlem Süresi
                    </span>
                    <span className="description-value">
                      {selectedMethod.processingTime}
                    </span>
                  </div>
                  <div className="description-c-r-c-t-column-bc">
                    <span className="description-title ellipsis" title="Min.">
                      Min.
                    </span>
                    <span className="description-value">
                      {selectedMethod.minAmount}
                    </span>
                  </div>
                  <div className="description-c-r-c-t-column-bc">
                    <span className="description-title ellipsis" title="Maks.">
                      Maks.
                    </span>
                    <span className="description-value">
                      {selectedMethod.maxAmount}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                data-scroll-lock-scrollable=""
                className={`expandable-info-body-content ${selectedMethod.name} payment-content not-expandable`}
              >
                {selectedMethod.info}
              </div>
            </div>
            {type === "withdraw" && (
              <div className="WithdrawAmount-All">
                <ul className="WithdrawAmount-Row">
                  <li className="WithdrawAmount-Row-Title">
                    Çekilebilir Tutar
                  </li>
                  <li className="WithdrawAmount-Row-Value">0.00 ₺</li>
                </ul>
                <ul className="WithdrawAmount-Row">
                  <li className="WithdrawAmount-Row-Title">Bakiye</li>
                  <li className="WithdrawAmount-Row-ValueBalance">0.00 ₺</li>
                </ul>
                <ul className="WithdrawAmount-Row">
                  <li className="WithdrawAmount-Row-Title">Oynanmamış tutar</li>
                  <li className="WithdrawAmount-Row-Value">0.00 ₺</li>
                </ul>
              </div>
            )}
            <div className="withdraw-form-l-bc">
              <form>
                {fields.map((field) => (
                  <div
                    key={field.name}
                    className="u-i-p-control-item-holder-bc"
                  >
                    <div
                      className={`form-control-bc ${
                        field.type === "text" ? "default" : "valid filled"
                      }`}
                    >
                      <label className="form-control-label-bc inputs">
                        <input
                          type={field.type}
                          className="form-control-input-bc"
                          name={field.name}
                          step="0"
                        />
                        <i className="form-control-input-stroke-bc"></i>
                        <span className="form-control-title-bc ellipsis">
                          {field.label}
                        </span>
                      </label>
                    </div>
                  </div>
                ))}
                <div className="u-i-p-c-footer-bc">
                  <button
                    className="btn a-color deposit"
                    type="submit"
                    title={type === "deposit" ? "PARA YATIR" : "ÇEKİM YAP"}
                  >
                    <span>
                      {type === "deposit" ? "PARA YATIR" : "ÇEKİM YAP"}
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentMethodComponent;
