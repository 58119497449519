import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar14 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar14_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar14_svg__cls-8{fill:#fff}.avatar14_svg__cls-3{fill:#515570}.avatar14_svg__cls-4{fill:#393c54}.avatar14_svg__cls-10,.avatar14_svg__cls-7,.avatar14_svg__cls-9{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;stroke:#515570}.avatar14_svg__cls-7{opacity:.1}.avatar14_svg__cls-9{opacity:.4}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#f2bc0f',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.3,
				fill: '#fff',
			}}
		/>
		<path
			d='M64 124.1a59.78 59.78 0 0 0 40-15.28l-2.39-5.68c-1.71-4-6.22-6.64-11.29-6.64H37.69c-5.07 0-9.58 2.66-11.29 6.64l-2.4 5.68a59.78 59.78 0 0 0 40 15.28Z'
			className='avatar14_svg__cls-3'
		/>
		<path
			d='M95.25 60.43c0 4.38.45 7.93-3.93 7.93a7.93 7.93 0 1 1 0-15.86c4.38 0 3.93 3.55 3.93 7.93ZM44.61 60.43a7.93 7.93 0 0 1-7.93 7.93c-4.38 0-3.93-3.55-3.93-7.93s-.45-7.93 3.93-7.93a7.93 7.93 0 0 1 7.93 7.93Z'
			className='avatar14_svg__cls-4'
		/>
		<path
			d='M64 95.37a28.31 28.31 0 0 1-28.32-28.32V52.43c0-15.64 12.68-25.32 28.32-25.32s28.32 9.68 28.32 25.32v14.62A28.31 28.31 0 0 1 64 95.37Z'
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<path
			d='M56.41 82.07a.91.91 0 0 1 .84-1.07h13.5a.91.91 0 0 1 .84 1.07'
			style={{
				stroke: '#393c54',
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: 2,
			}}
		/>
		<path
			d='M90.91 67a13.34 13.34 0 0 0-11.82 13.34v4.49M37.09 67a13.34 13.34 0 0 1 11.82 13.34v4.49'
			className='avatar14_svg__cls-7'
		/>
		<path
			d='m35 67.53 27.68 6.22a6.14 6.14 0 0 0 2.63 0L93 67.53V22.7a1 1 0 0 0-1.6-.8l-10 7.45s-6.12-5.8-17.39-5.8A27.83 27.83 0 0 0 45.92 30l-9.27-7.9a1 1 0 0 0-1.65.76Z'
			className='avatar14_svg__cls-4'
		/>
		<path d='M46.13 57.75 55.88 60a5 5 0 1 1-9.75-2.21Z' className='avatar14_svg__cls-8' />
		<circle cx={51} cy={59.02} r={2.72} className='avatar14_svg__cls-4' />
		<path d='m56.36 60.07-10.72-2.44' className='avatar14_svg__cls-9' />
		<path d='m56.36 59.07-10.72-2.44' className='avatar14_svg__cls-10' />
		<path
			d='M78 111.82a.5.5 0 0 0-.26-.93h-8.09a.7.7 0 0 0-.65.43 3.15 3.15 0 0 1-2.3 1.89l-1 .21a.36.36 0 0 1-.43-.29l-.27-1.41a.08.08 0 0 0-.15 0l-.28.66a2.37 2.37 0 0 0-1.06 0l-.28-.66a.08.08 0 0 0-.15 0l-.27 1.41a.36.36 0 0 1-.43.29l-1-.21a3.15 3.15 0 0 1-2.3-1.89.7.7 0 0 0-.65-.43h-8.14a.5.5 0 0 0-.26.93 5.67 5.67 0 0 1 2.85 4.72.49.49 0 0 0 .56.49 13.27 13.27 0 0 1 5.89.46 9.19 9.19 0 0 1 4.26 3.41.5.5 0 0 0 .82 0 9.19 9.19 0 0 1 4.26-3.41 13.27 13.27 0 0 1 5.89-.46.49.49 0 0 0 .56-.49 5.67 5.67 0 0 1 2.88-4.72Z'
			style={{
				fill: '#f8dc25',
			}}
		/>
		<path d='M81.87 57.75 72.12 60a5 5 0 1 0 9.75-2.21Z' className='avatar14_svg__cls-8' />
		<circle cx={77.06} cy={59.01} r={2.63} className='avatar14_svg__cls-4' />
		<path d='m71.64 60.07 10.72-2.44' className='avatar14_svg__cls-9' />
		<path d='m71.64 59.07 10.72-2.44' className='avatar14_svg__cls-10' />
		<path
			d='M93 96.5V67l-5 1.36-2.75 12.29a15.8 15.8 0 0 1-7.39 10.16l-2.14.93a29.26 29.26 0 0 1-23.44 0l-2.14-.93a15.8 15.8 0 0 1-7.39-10.16L40 68.36 35 67v29.5l-16.46 6.64 1.37 1.55a60.21 60.21 0 0 0 17.24 13l19.35-13a14 14 0 0 1 7.5-2.19 16.37 16.37 0 0 1 7.5 1.81l19.37 13.34a60.39 60.39 0 0 0 17-12.72c.56-.61 1.1-1.21 1.61-1.79Z'
			className='avatar14_svg__cls-4'
		/>
		<path
			d='M60 90a7 7 0 0 1 8 0'
			style={{
				stroke: '#fbc0aa',
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: 2,
			}}
		/>
		<path
			d='M64 63.12v8.21a.51.51 0 0 1-.63.49l-3.62-1a.51.51 0 0 1-.31-.71l3.61-7.23a.5.5 0 0 1 .95.24Z'
			className='avatar14_svg__cls-3'
		/>
	</svg>
);
export default SvgAvatar14;
