import { useContext } from "react";
import {
  ICurrency,
  IUserAccount,
} from "../type/socket-type";
import AuthContext from "../context/AuthContext";
import SiteContext from "../context/SiteContext";
import { useTranslation } from "react-i18next";
import { decreaseLastDecimal } from "./helper";
import ThemeContext from "../context/ThemeContext";

const useCombine = () => {

    const { currencyList, selectedFiatId, selectedCurrencyId, showFiat, defaultCurrency, setSelectedCurrencyId  } = useContext(SiteContext);
    const { userAccounts, userProfile, setUserProfile } = useContext(AuthContext);
    const { i18n } = useTranslation();

    const { mobileDesign } = useContext(ThemeContext);

  const getCurrencyListWithAccount = () => {
    const currencyListWithAccount = currencyList.map((currency: any) => {
      const account = userAccounts?.find(
        (exists) => exists.cId === currency.id
      );
      return { ...currency, account };
    });
    return currencyListWithAccount;
  };

  const getCurrentAccount = (currencyIdR?: String) => {
    const findCurrency = currencyList.find(
      (currency: any) => currency.id === (currencyIdR || selectedCurrencyId)
    );

    if (!findCurrency) return null;
    const account = userAccounts?.find(
      (exists: IUserAccount) => exists.cId === findCurrency._id
    );
    return { ...findCurrency, account };
  };

  const parseAmountCurrencyAccount = (amount: number) => {
    const account = getCurrentAccount();
    if (!account) return null;
    const parsed = parseCurrencyAmount(account, amount, i18n.language);
    return parsed;
  };

  const parseCurrencyAmount = (
    Currency: ICurrency,
    Amount: number,
    Language: string,
    showOnlyFiat?: boolean,
    showOnlyMain?: boolean
  ) => {
    if (!Amount) {
      Amount = 0;
    }
    const Result: any = { num: Amount, str: "", exact: Amount };
    const formatQuery: any = {};
    if (!Currency) {
      return Result;
    }

    if ((!showFiat && !showOnlyFiat) || showOnlyMain) {
      formatQuery.minimumFractionDigits = Currency.minDecimals || 2;
      formatQuery.maximumFractionDigits = Currency.maxDecimals || 2;
      Amount = decreaseLastDecimal(Amount, formatQuery.maximumFractionDigits);
      Result.exact = Amount;
      Result.num = Amount.toLocaleString(Language, formatQuery);
      if (Currency.isFiat) {
        formatQuery.style = "currency";
        formatQuery.currency = Currency.symbol;
      }
      Result.symbol = Currency.symbol;
      Result.str = Currency.isFiat
        ? Amount.toLocaleString(Language, formatQuery)
        : `${Result.num} ${Currency.symbol.toUpperCase()}`;
    } else {
      let exchangedAmount = Amount;
      const findSelectedFiat: any = getCurrency({
        key: "_id",
        value: selectedFiatId,
      });

      exchangedAmount = calculateExchangeAmount(
        Currency,
        findSelectedFiat,
        Amount
      );
      const modifyAmount = decreaseLastDecimal(
        exchangedAmount,
        findSelectedFiat.maxDecimals
      );
      Result.exact = modifyAmount;

      formatQuery.minimumFractionDigits = findSelectedFiat.minDecimals;
      formatQuery.maximumFractionDigits = findSelectedFiat.maxDecimals;
      Result.num = modifyAmount.toLocaleString(Language, formatQuery);
      formatQuery.style = "currency";
      formatQuery.currency = findSelectedFiat.symbol;
      findSelectedFiat &&
        formatQuery.style === "currency" &&
        (formatQuery.currency = findSelectedFiat.symbol);
      Result.str = modifyAmount.toLocaleString(Language, formatQuery);
      Result.symbol = findSelectedFiat.symbol;
    }
    return Result;
  };

  // const parseFiatAmount = (Amount: number, Language: string) => {
  //   const formatQuery: any = {};
  //   const findSelectedFiat: any = getCurrency({
  //     key: "_id",
  //     value: selectedFiatId,
  //   });
  //   formatQuery.minimumFractionDigits = findSelectedFiat.minDecimals;
  //   formatQuery.maximumFractionDigits = findSelectedFiat.maxDecimals;
  //   formatQuery.style = "currency";
  //   formatQuery.currency = findSelectedFiat.symbol;
  //   return Amount.toLocaleString(Language, formatQuery);
  // };

  const calculateExchangeAmount = (
    fromCurrency: ICurrency,
    toCurrency: ICurrency,
    fromAmount: number
  ) => {
    if (!fromCurrency || !toCurrency || !fromAmount) return 0;

    if (fromCurrency._id === toCurrency._id) return fromAmount;
    const calculateAmount = (fromAmount * fromCurrency.rate) / toCurrency.rate;
    return calculateAmount;
  };

  // const exchangeUSDtoCurrency = (toCurrency: ICurrency, usdAmount: number) => {
  //   const USDCurrency: any = currencyList.find(
  //     (currency: ICurrency) => currency.symbol === "USD"
  //   );
  //   if (!toCurrency.rate) {
  //     toCurrency.rate = 0;
  //   }
  //   const calculateAmount = (usdAmount * USDCurrency.rate) / toCurrency.rate;
  //   return calculateAmount;
  // };

  const getUSDAmount = (Amount: number) => {
    const findSelectedFiat: any = getCurrency({ key: "symbol", value: "USD" });
    const parsed = parseCurrencyAmount(findSelectedFiat, Amount, i18n.language);
    return parsed;
  };

  // const exchangeFiatToCurrency = (fromAmount: number) => {
  //   const findSelectedFiat: any = getCurrency({
  //     key: "_id",
  //     value: selectedFiatId,
  //   });
  //   const calculateAmount = fromAmount / findSelectedFiat.rate;
  //   return calculateAmount;
  // };

  const getCurrency = (filter: any) => {
    const foundCurrency = currencyList.find(
      (currency: any) => currency[filter.key] === filter.value
    );
    return foundCurrency;
  };

  // const getCurrencyById = (curId: string) => {
  //   // console.log('curList', currencyList);
  //   const findCur = currencyList.find((x: any) => x._id === curId);
  //   return findCur;
  // };

  // const getCurrencyList = (filter?: any) => {
  //   const filteredCurrencyList = currencyList.filter((currency: any) => {
  //     if (filter) {
  //       return currency[filter.key] === filter.value;
  //     }
  //     return true;
  //   });
  //   return filteredCurrencyList;
  // };

  return {
    getCurrentAccount,
    getCurrencyListWithAccount,
    parseCurrencyAmount,
    setSelectedCurrencyId,
    setUserProfile,
    getUSDAmount,
    userProfile,
    selectedCurrencyId,
    defaultCurrency,
    parseAmountCurrencyAccount,
    userAccounts,
    mobileDesign
  };
};

export default useCombine;
