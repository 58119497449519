import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionGabon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 400 300'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#3a75c4' d='M0 0h400v300H0z' />
		<path fill='#fcd116' d='M0 0h400v200H0z' />
		<path fill='#009e60' d='M0 0h400v100H0z' />
	</svg>
);
export default SvgRegionGabon;
