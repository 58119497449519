import * as React from 'react';
import { SVGProps } from 'react';
const SvgXmy = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 32 32'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#ec1076' fillRule='nonzero' />
			<path
				fill='#fff'
				d='M21.05 21c-.602 0-1.263-.303-1.263-1.212 0-.448.35-1.825 1.048-4.132.143-.356.214-.676.214-.959 0-.545-.42-1.09-1.202-1.09-.454 0-1.443.18-1.743 1.15-.2.647-.661 2.364-1.383 5.152-.28.727-.781 1.091-1.502 1.091-1.082 0-1.263-.788-1.263-1.273 0-.192.288-1.353.865-3.48.225-.71.337-1.227.337-1.55 0-.485-.36-1.09-1.202-1.09-.841 0-1.623.605-1.983 1.938-.24.89-.581 2.223-1.022 4-.24.97-.782 1.455-1.623 1.455-.762 0-1.162-.424-1.202-1.273l1.382-5.757H6.443a1.425 1.425 0 1 1-.03-2.849h5.139c.721 0 1.162.344 1.322 1.03C13.596 11.243 14.557 11 15.4 11s1.923.485 2.404 1.576c.66-.728 1.803-1.576 3.426-1.576 1.502 0 2.765 1.03 2.765 2.848 0 .687-.341 2.122-1.022 4.304l2.637-.018c.771 0 1.39.642 1.39 1.433S26.381 21 25.617 21z'
			/>
		</g>
	</svg>
);
export default SvgXmy;
