import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar0 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar0_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar0_svg__cls-7{fill:#515570}.avatar0_svg__cls-3{fill:#393c54}.avatar0_svg__cls-4{fill:#fbc0aa}.avatar0_svg__cls-5{fill:#fff}.avatar0_svg__cls-10,.avatar0_svg__cls-11{fill:none;stroke-linecap:round}.avatar0_svg__cls-11{stroke:#393c54;stroke-miterlimit:10}.avatar0_svg__cls-10{stroke-width:2px;stroke-linejoin:round;stroke:#515570;opacity:.4}.avatar0_svg__cls-11{stroke-width:4px}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#a7aece',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.2,
				fill: '#515570',
			}}
		/>
		<circle cx={93} cy={60} r={6} className='avatar0_svg__cls-3' />
		<circle cx={93} cy={52} r={5} className='avatar0_svg__cls-3' />
		<circle cx={89.32} cy={65.43} r={7.93} className='avatar0_svg__cls-4' />
		<path
			d='M64 124a59.77 59.77 0 0 0 39.83-15.14l-2.23-5.22c-1.71-4-6.22-6.64-11.29-6.64H37.69c-5.07 0-9.58 2.66-11.29 6.64l-2.23 5.22A59.77 59.77 0 0 0 64 124Z'
			className='avatar0_svg__cls-3'
		/>
		<path
			d='M77.43 98.73a13.43 13.43 0 0 1-26.86 0c0-5 6-3.25 13.43-3.25s13.43-1.8 13.43 3.25Z'
			className='avatar0_svg__cls-5'
		/>
		<path d='M50 122.44a59.84 59.84 0 0 0 27 .23V99.1H50Z' className='avatar0_svg__cls-5' />
		<path
			d='M78.43 98.73c0 7.42-7 13.43-14.43 13.43s-14.43-6-14.43-13.43'
			style={{
				strokeWidth: 2,
				stroke: '#393c54',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M55.72 123.42 51 96.5l-12.38 10.25A2 2 0 0 0 39 110l5.55 2.92-.37 7.72a59.68 59.68 0 0 0 11.54 2.78ZM77 96.5l-4.72 26.92a59.64 59.64 0 0 0 11.51-2.76l-.37-7.74 5.51-2.92a2 2 0 0 0 .41-3.25Z'
			className='avatar0_svg__cls-7'
		/>
		<path
			d='M64 89.75v8.75'
			style={{
				strokeLinejoin: 'round',
				stroke: '#fbc0aa',
				strokeWidth: 20,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={35} cy={60} r={6} className='avatar0_svg__cls-3' />
		<circle cx={35} cy={52} r={5} className='avatar0_svg__cls-3' />
		<circle cx={38.68} cy={65.43} r={7.93} className='avatar0_svg__cls-4' />
		<path
			d='M64 97.62c-11 0-25-12.09-25-27l-2-17.75a27 27 0 1 1 54 0l-2 17.75c0 14.91-14 27-25 27Z'
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<circle cx={77.5} cy={64.28} r={3} className='avatar0_svg__cls-3' />
		<circle cx={50.5} cy={64.28} r={3} className='avatar0_svg__cls-3' />
		<path
			d='M53.81 56a16.53 16.53 0 0 0-9.62 1.75M74.19 56a16.53 16.53 0 0 1 9.62 1.75'
			className='avatar0_svg__cls-10'
		/>
		<path
			d='M58.08 83a.77.77 0 0 1-.74-.89C57.76 78.64 64 76 64 76s6.24 2.69 6.66 6.16a.77.77 0 0 1-.74.89ZM91 47V30.41C91 14.46 77.5 9.2 64 9.2c-14.4 0-27 5.26-27 21.21V47Z'
			className='avatar0_svg__cls-3'
		/>
		<path d='M37 47v12M91 47v12' className='avatar0_svg__cls-11' />
		<path
			d='M80 47v3a2 2 0 0 0 2 2h2a2 2 0 0 1 2 2 2 2 0 0 0 2 2h2v-9Z'
			className='avatar0_svg__cls-3'
		/>
		<path d='M37 39h54v8H37z' className='avatar0_svg__cls-5' />
		<path
			d='M32 47h64'
			style={{
				strokeWidth: 4,
				stroke: '#515570',
				strokeLinejoin: 'round',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M68 73c0 3.4-1.79 6.16-4 6.16s-4-2.75-4-6.16 1.79-8.7 4-8.7 4 5.3 4 8.7Z'
			className='avatar0_svg__cls-4'
		/>
		<path
			d='M64 113v10'
			style={{
				strokeWidth: 3,
				stroke: '#393c54',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M59 86h10'
			style={{
				stroke: '#f85565',
				strokeLinejoin: 'round',
				strokeWidth: 2,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M60.46 92.94a5 5 0 0 1 7.08 0'
			style={{
				stroke: '#515570',
				strokeLinejoin: 'round',
				strokeWidth: 2,
				fill: 'none',
				strokeLinecap: 'round',
				opacity: 0.2,
			}}
		/>
	</svg>
);
export default SvgAvatar0;
