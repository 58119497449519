import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomWalletConnect = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		fill='none'
		viewBox='0 0 200 200'
		className='svg-icon'
		{...props}>
		<g clipPath='url(#customWalletConnect_svg__a)'>
			<circle cx={100} cy={100} r={99.5} fill='#3396FF' stroke='#66B1FF' />
			<path
				fill='#fff'
				d='M61.26 74.482c21.395-20.864 56.085-20.864 77.481 0l2.575 2.511a2.624 2.624 0 0 1 0 3.778l-8.809 8.59a1.394 1.394 0 0 1-1.937 0l-3.543-3.456C112.1 71.35 87.9 71.35 72.974 85.906l-3.795 3.701a1.394 1.394 0 0 1-1.937 0l-8.809-8.59a2.625 2.625 0 0 1 0-3.778l2.826-2.756Zm95.698 17.765 7.84 7.645a2.626 2.626 0 0 1 0 3.778l-35.35 34.472a2.79 2.79 0 0 1-3.874 0l-25.09-24.466a.696.696 0 0 0-.968 0l-25.089 24.466a2.79 2.79 0 0 1-3.874 0l-35.35-34.473a2.624 2.624 0 0 1 0-3.778l7.84-7.644a2.789 2.789 0 0 1 3.873 0l25.09 24.466c.267.26.7.26.968 0l25.089-24.466a2.79 2.79 0 0 1 3.874 0l25.089 24.466a.7.7 0 0 0 .969 0l25.089-24.466a2.789 2.789 0 0 1 3.874 0Z'
			/>
		</g>
		<defs>
			<clipPath id='customWalletConnect_svg__a'>
				<path fill='#fff' d='M0 0h200v200H0z' />
			</clipPath>
		</defs>
	</svg>
);
export default SvgCustomWalletConnect;
