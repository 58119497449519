import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar49 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar49_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar49_svg__cls-2,.avatar49_svg__cls-5{fill:#fff}.avatar49_svg__cls-2{opacity:.3}.avatar49_svg__cls-3{fill:#515570}.avatar49_svg__cls-4{fill:#fba875}.avatar49_svg__cls-6{fill:#a7aece}.avatar49_svg__cls-14,.avatar49_svg__cls-9{fill:none;stroke-linecap:round}.avatar49_svg__cls-9{stroke:#515570;opacity:.4}.avatar49_svg__cls-14,.avatar49_svg__cls-9{stroke-linejoin:round;stroke-width:2px}.avatar49_svg__cls-11{fill:#393c54}.avatar49_svg__cls-14{stroke:#393c54}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#00adfe',
			}}
		/>
		<circle cx={64} cy={64} r={48} className='avatar49_svg__cls-2' />
		<path
			d='M91.34 25.88c-7.8-7.18-16-10.77-23.71-11.61h-.24l-.93-.08h-4.92l-.93.08h-.24c-7.72.84-15.91 4.43-23.71 11.61a17.91 17.91 0 0 0-5 11.47V59c0 17.83 64.64 17.83 64.64 0V37.35a17.91 17.91 0 0 0-4.96-11.47Z'
			className='avatar49_svg__cls-3'
		/>
		<circle cx={91.32} cy={60.38} r={7.93} className='avatar49_svg__cls-4' />
		<path
			d='M64 124.1a59.73 59.73 0 0 0 39.8-15.1l-2.2-5.41c-1.71-4-6.22-6.64-11.29-6.64H37.69c-5.07 0-9.58 2.66-11.29 6.64L24.2 109A59.73 59.73 0 0 0 64 124.1Z'
			className='avatar49_svg__cls-5'
		/>
		<path d='M74 99.95H54l-.78-5.48h21.56L74 99.95z' className='avatar49_svg__cls-6' />
		<path
			d='M64 84.7v13.75'
			style={{
				strokeWidth: 18,
				stroke: '#fba875',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path d='M64 124.05h1V106a1 1 0 1 0-2 0v18Z' className='avatar49_svg__cls-6' />
		<circle cx={36.68} cy={60.38} r={7.93} className='avatar49_svg__cls-4' />
		<path
			d='M64 94.32A28.31 28.31 0 0 1 35.68 66V53.38a28.32 28.32 0 1 1 56.64 0V66A28.31 28.31 0 0 1 64 94.32Z'
			style={{
				fill: '#ffbb94',
			}}
		/>
		<path d='M74.39 52.95 84 52.7M53 52.95l-9.61-.25' className='avatar49_svg__cls-9' />
		<path
			d='M64 103.25a11 11 0 0 1-10.78-8.8 11.4 11.4 0 0 0-.22 2.2 11 11 0 0 0 22 0 11.4 11.4 0 0 0-.22-2.2 11 11 0 0 1-10.78 8.8Z'
			className='avatar49_svg__cls-5'
		/>
		<path
			d='M64 103.25a11 11 0 0 1-10.78-8.8 11.4 11.4 0 0 0-.22 2.2 11 11 0 0 0 22 0 11.4 11.4 0 0 0-.22-2.2 11 11 0 0 1-10.78 8.8Z'
			style={{
				opacity: 0.1,
				fill: '#393c54',
			}}
		/>
		<path
			d='M71.55 76a1 1 0 0 1 1 1.06 8.55 8.55 0 0 1-17 0 1 1 0 0 1 1-1.06Z'
			className='avatar49_svg__cls-11'
		/>
		<path
			d='M64 84.61a8.51 8.51 0 0 0 6.29-2.79 9.06 9.06 0 0 0-12.58 0A8.51 8.51 0 0 0 64 84.61Z'
			style={{
				fill: '#f85565',
			}}
		/>
		<path
			d='M64 86.09a8.33 8.33 0 0 1-3.24-.65.5.5 0 0 0-.59.75l2.28 3.41a1.86 1.86 0 0 0 3.1 0l2.28-3.41a.5.5 0 0 0-.59-.75 8.33 8.33 0 0 1-3.24.65Z'
			className='avatar49_svg__cls-11'
		/>
		<path
			d='M58 76h5v2h-3a2 2 0 0 1-2-2ZM65 76h5a2 2 0 0 1-2 2h-3v-2Z'
			className='avatar49_svg__cls-5'
		/>
		<path
			d='M63.56 73.13a2.59 2.59 0 0 1-2.15 3l-2.33.37a19 19 0 0 1-13.67-3.07L60.6 71a2.59 2.59 0 0 1 2.96 2.13ZM64.44 73.13a2.59 2.59 0 0 0 2.15 3l2.33.37a19 19 0 0 0 13.67-3.07L67.4 71a2.59 2.59 0 0 0-2.96 2.13Z'
			className='avatar49_svg__cls-11'
		/>
		<circle cx={78} cy={59.23} r={3} className='avatar49_svg__cls-11' />
		<circle cx={50} cy={59.23} r={3} className='avatar49_svg__cls-11' />
		<path
			d='M64 60.7v8.5'
			style={{
				strokeWidth: 6,
				stroke: '#fba875',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M82.1 65h-7.92a4.77 4.77 0 0 1-4.65-3.73L69 58.64a2.64 2.64 0 0 1 2.16-3.19 45.34 45.34 0 0 1 13.76 0 2.64 2.64 0 0 1 2.21 2.9l-.26 2.31A4.77 4.77 0 0 1 82.1 65ZM45.9 65h7.92a4.77 4.77 0 0 0 4.65-3.73l.53-2.63a2.64 2.64 0 0 0-2.16-3.19 45.34 45.34 0 0 0-13.76 0 2.64 2.64 0 0 0-2.21 2.9l.26 2.31A4.77 4.77 0 0 0 45.9 65Z'
			className='avatar49_svg__cls-2'
		/>
		<path
			d='m73.64 55.16-4.44 4.43a.41.41 0 0 1 0 .11l.34 1.52a4.72 4.72 0 0 0 1.34 2.36l8.6-8.6a43.63 43.63 0 0 0-5.84.18ZM82.12 55.16l-9.52 9.52a4.9 4.9 0 0 0 1.58.27h1l9.48-9.49q-1.28-.18-2.54-.3Z'
			className='avatar49_svg__cls-2'
		/>
		<path
			d='M82.1 65h-7.92a4.77 4.77 0 0 1-4.65-3.73L69 58.64a2.64 2.64 0 0 1 2.16-3.19 45.34 45.34 0 0 1 13.76 0 2.64 2.64 0 0 1 2.21 2.9l-.26 2.31A4.77 4.77 0 0 1 82.1 65Z'
			className='avatar49_svg__cls-14'
		/>
		<path
			d='M42.72 63.73 51.48 55a46 46 0 0 0-5.87.22l-4.41 4.4v1.37a4.74 4.74 0 0 0 1.52 2.74ZM54.14 55.14l-9.59 9.59a4.65 4.65 0 0 0 1.35.27h1.26l9.52-9.52c-.85-.18-1.68-.27-2.54-.34Z'
			className='avatar49_svg__cls-2'
		/>
		<path
			d='M45.9 65h7.92a4.77 4.77 0 0 0 4.65-3.73l.53-2.63a2.64 2.64 0 0 0-2.16-3.19 45.34 45.34 0 0 0-13.76 0 2.64 2.64 0 0 0-2.21 2.9l.26 2.31A4.77 4.77 0 0 0 45.9 65ZM59 58.95h10'
			className='avatar49_svg__cls-14'
		/>
		<circle cx={68} cy={112.95} r={1.5} className='avatar49_svg__cls-3' />
		<circle cx={68} cy={119.95} r={1.5} className='avatar49_svg__cls-3' />
		<path
			d='M92.24 36a12.58 12.58 0 0 0-4.86-8l-.38-.4v-.65c0-7.73-10.3-14-23-14S41 19.22 41 27a7.73 7.73 0 0 0 .06 1 12.53 12.53 0 0 0-4.86 8l-2.76 17.15 2.24 9.08s1-25.37 10.64-25.33a10.52 10.52 0 0 0 1.39-.07A31.71 31.71 0 0 0 64 41a31.76 31.76 0 0 0 16.35-4.2 12.54 12.54 0 0 0 1.76.1c9.68 0 10.65 25.33 10.65 25.33L95 53.15Z'
			className='avatar49_svg__cls-11'
		/>
		<path
			d='M69.64 96a31.9 31.9 0 0 1-11.28 0c-1.3 0-2.36-1.51-2.36-3.3V92a48.27 48.27 0 0 0 16 0v.75c0 1.74-1.06 3.25-2.36 3.25Z'
			style={{
				opacity: 0.5,
				fill: '#393c54',
			}}
		/>
	</svg>
);
export default SvgAvatar49;
