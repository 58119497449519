import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from './icon/Icon';

interface SelectInputProps {
	name: string;
	value: any;

	options: { value: string; label: string; icon?: string }[];
	onChange: (value: string) => void;
	label: string;
	id: string;
}

const SelectInput: React.FC<SelectInputProps> = ({ name, value, options, onChange, label, id }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { t } = useTranslation();

	const handleSelectClick = () => {
		setIsOpen(!isOpen);
	};

	const handleOptionClick = (optionValue: string) => {
		onChange(optionValue);
		setIsOpen(false);
	};

	const selectedLabel = options.find((option) => option.value === value)?.label || t('Select');

	return (
		<div className='u-i-p-control-item-holder-bc'>
			<div className='multi-select-bc'>
				<div className={`form-control-bc ${isOpen ? 'focused' : ''} valid filled`}>
					<div className='form-control-label-bc inputs' onClick={handleSelectClick}>
						<div className='form-control-select-bc ellipsis'>{selectedLabel}</div>
						<i
							className={`form-control-icon-bc bc-i-small-arrow-${
								isOpen ? 'up' : 'down'
							}`}></i>
						<i className='form-control-input-stroke-bc'></i>
						<span className='form-control-title-bc ellipsis'>{label}</span>
					</div>
					{isOpen && (
						<div className='multi-select-label-bc'>
							{options.map((option) => (
								<label
									key={option.value}
									className={`checkbox-control-content-bc ${
										option.value === value ? 'active' : ''
									}`}
									data-option-value={option.value}
									onClick={() => handleOptionClick(option.value)}>
									{option.icon && (
										<Icon
											icon={option?.icon}
											className='currencyMenuIcon'
                      style={{ marginRight: "5px" }}
										/>
									)}
									<p
										className='checkbox-control-text-bc ellipsis'
										style={{ pointerEvents: 'none' }}>
										{option.label}
									</p>
								</label>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default SelectInput;
