import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomVipi = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		className='svg-icon'
		viewBox='0 0 1024 1024'
		{...props}>
		<path
			fill='#FCB814'
			d='m287.434 135.586 44.297 161.358-265.566-18.282c-.766 0-1.395-1.953-2.165-1.892l223.434-141.184zM960 282.437H693.833l43.3-146.851z'
		/>
		<path fill='#FCB814' d='M692.837 296.944H331.731l-44.297-161.358h449.699z' />
		<path d='M692.837 296.944H331.731l-44.297-161.358h449.699z' />
		<path fill='#FCB814' d='M693.833 282.437 515.577 888.414 332.349 278.662z' />
		<path fill='#FCB814' d='m332.349 278.662 183.228 609.752L66.165 278.662z' />
		<path d='m332.349 278.662 183.228 609.752L66.165 278.662z' />
		<path fill='#FCB814' d='M693.833 282.437H960L515.577 888.414z' />
		<path d='M693.833 282.437H960L515.577 888.414z' />
	</svg>
);
export default SvgCustomVipi;
