import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar38 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar38_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar38_svg__cls-6{fill:#fff}.avatar38_svg__cls-3{fill:#393c54}.avatar38_svg__cls-4{fill:#fbc0aa}.avatar38_svg__cls-10,.avatar38_svg__cls-12{fill:none;stroke-linecap:round}.avatar38_svg__cls-9{fill:#515570}.avatar38_svg__cls-10,.avatar38_svg__cls-12{stroke:#515570;stroke-linejoin:round}.avatar38_svg__cls-11{fill:#f85565;opacity:.2}.avatar38_svg__cls-12{opacity:.2;stroke-width:2px}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#f2bc0f',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.3,
				fill: '#fff',
			}}
		/>
		<path
			d='M64 14.88a32 32 0 0 1 32 32v42.71a21.59 21.59 0 0 1-21.59 21.59H53.59A21.59 21.59 0 0 1 32 89.59V46.88a32 32 0 0 1 32-32Z'
			className='avatar38_svg__cls-3'
		/>
		<circle cx={88} cy={60} r={7} className='avatar38_svg__cls-4' />
		<path
			d='M64 124a59.69 59.69 0 0 0 32.55-9.61l-3.18-10.75A10 10 0 0 0 84 97H44.05a10 10 0 0 0-9.42 6.64l-3.18 10.75A59.69 59.69 0 0 0 64 124Z'
			className='avatar38_svg__cls-3'
		/>
		<path
			d='M64 88.75v7.75'
			style={{
				strokeMiterlimit: 10,
				stroke: '#fba875',
				strokeWidth: 14,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<path
			d='M71 90v-4H57v4a7 7 0 0 1-7 7v8h28v-8a7 7 0 0 1-7-7Z'
			className='avatar38_svg__cls-6'
		/>
		<circle cx={40} cy={60} r={7} className='avatar38_svg__cls-4' />
		<path
			d='M64 90a25 25 0 0 1-25-25V47.52a25 25 0 1 1 50 0V65a25 25 0 0 1-25 25Z'
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<path d='M37 46a27 27 0 0 1 54 0Z' className='avatar38_svg__cls-3' />
		<path
			d='M45.49 33.07A21 21 0 0 1 64 22'
			style={{
				stroke: '#fff',
				strokeWidth: 4,
				opacity: 0.1,
				strokeMiterlimit: 10,
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
		<circle cx={76} cy={58.28} r={3} className='avatar38_svg__cls-9' />
		<path d='M70.5 59.37A6.61 6.61 0 0 1 82 58.06' className='avatar38_svg__cls-10' />
		<path
			d='m63.51 59.28-2.19 10.24A1 1 0 0 0 62 70.7a6.62 6.62 0 0 0 3.94 0 1 1 0 0 0 .71-1.18l-2.16-10.25a.5.5 0 0 0-.98.01Z'
			className='avatar38_svg__cls-4'
		/>
		<ellipse cx={51} cy={66.54} className='avatar38_svg__cls-11' rx={5.08} ry={3.54} />
		<ellipse cx={77} cy={66.54} className='avatar38_svg__cls-11' rx={5.08} ry={3.54} />
		<path d='m72 52 5.18-2.36a4.6 4.6 0 0 1 4.67.5L83 51' className='avatar38_svg__cls-12' />
		<circle cx={51.5} cy={58.28} r={3} className='avatar38_svg__cls-9' />
		<path d='M57 59.37a6.62 6.62 0 0 0-11.51-1.31' className='avatar38_svg__cls-10' />
		<path
			d='m55.5 52-5.18-2.36a4.62 4.62 0 0 0-4.68.5L44.5 51'
			className='avatar38_svg__cls-12'
		/>
		<path
			d='M39 41h50v9H39zM93.37 103.64A10 10 0 0 0 84 97H44.05a10 10 0 0 0-9.42 6.64l-.1.34a38 38 0 0 0 58.94 0Z'
			className='avatar38_svg__cls-6'
		/>
		<path d='m76.78 95.5-9.61 12.38M51.17 95.5l9.61 12.38' className='avatar38_svg__cls-12' />
		<path
			d='M68.59 106.06h-3.06V103h-3.06v3.06h-3.06v3.06h3.06V116h3.06v-6.88h3.06v-3.06z'
			style={{
				fill: '#f85565',
			}}
		/>
		<path
			d='M59 79h10'
			style={{
				stroke: '#f85565',
				strokeWidth: 2,
				strokeLinejoin: 'round',
				fill: 'none',
				strokeLinecap: 'round',
			}}
		/>
	</svg>
);
export default SvgAvatar38;
