import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomBonusb = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		viewBox='0 0 393.568 393.568'
		className='svg-icon'
		{...props}>
		<circle
			cx={196.784}
			cy={196.784}
			r={196.784}
			style={{
				fill: '#886c60',
			}}
		/>
		<path
			d='m192.711 246.756-72.663 132.137-20.687-64.194-65.551 16.743 71.37-129.422c20.493 26.311 51.976 43.507 87.531 44.736z'
			style={{
				fill: '#d54232',
			}}
		/>
		<path
			d='m359.758 331.442-65.487-16.743-20.687 64.194-72.727-132.137c35.556-1.228 67.038-18.424 87.531-44.671l71.37 129.357z'
			style={{
				fill: '#f0582f',
			}}
		/>
		<circle
			cx={196.784}
			cy={130.78}
			r={116.04}
			style={{
				fill: '#fbd303',
			}}
		/>
		<path
			d='m196.784 33.81 21.721 66.586h70.012l-56.63 41.374 21.721 66.521-56.824-41.115-56.824 41.115 21.721-66.521-56.63-41.374h70.012z'
			style={{
				fill: '#fffffc',
			}}
		/>
	</svg>
);
export default SvgCustomBonusb;
