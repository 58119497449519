import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionNepal = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		viewBox='-17.582 -4.664 71.571 87.246'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<use xlinkHref='#regionNepal_svg__a' stroke='#003893' strokeWidth={5.165} />
		<path id='regionNepal_svg__a' fill='#DC143C' d='M-15 37.574h60L-15 0v80h60l-60-60z' />
		<g fill='#fff'>
			<path d='M-11.95 23.483a12.84 12.84 0 0 0 23.9 0 11.95 11.95 0 0 1-23.9 0' />
			<g transform='translate(0 29.045) scale(5.56106)'>
				<circle r={1} />
				<g id='regionNepal_svg__d'>
					<g id='regionNepal_svg__c'>
						<path
							id='regionNepal_svg__b'
							d='M.195-.98 0-1.39l-.195.408'
							transform='rotate(11.25)'
						/>
						<use xlinkHref='#regionNepal_svg__b' transform='rotate(22.5)' />
						<use xlinkHref='#regionNepal_svg__b' transform='rotate(45)' />
					</g>
					<use xlinkHref='#regionNepal_svg__c' transform='rotate(67.5)' />
				</g>
				<use xlinkHref='#regionNepal_svg__d' transform='scale(-1 1)' />
			</g>
			<g transform='matrix(8.1434 0 0 8.1434 0 58.787)'>
				<circle r={1} />
				<g id='regionNepal_svg__g'>
					<g id='regionNepal_svg__f'>
						<path id='regionNepal_svg__e' d='M.259.966 0 1.576l-.259-.61' />
						<use xlinkHref='#regionNepal_svg__e' transform='rotate(180)' />
					</g>
					<use xlinkHref='#regionNepal_svg__f' transform='rotate(90)' />
				</g>
				<use xlinkHref='#regionNepal_svg__g' transform='rotate(30)' />
				<use xlinkHref='#regionNepal_svg__g' transform='rotate(60)' />
			</g>
		</g>
	</svg>
);
export default SvgRegionNepal;
