import { lazy } from "react";

const ATOX = {
 
  SPORT: lazy(() => import("../pages/sport/Main")),
  CASINO: lazy(() => import("../pages/casino/Main")),
  HOME: lazy(() => import("../pages/home/Main")),
  LOGIN: lazy(() => import("../pages/auth/Login")),
  CMS: lazy(() => import("../pages/home/CMS")),
};

const presentation = [
  {
    path: "",
    element: <ATOX.HOME />,
  },
  {
    path: "Home",
    element: <ATOX.HOME />,
  },
  {
    path: "/cms/:cmsId",
    element: <ATOX.CMS />,
  },
  {
    path: "/login",
    element: <ATOX.LOGIN />,
  },
  {
    path: "/signUp",
    element: <ATOX.LOGIN isSignUp />,
  },
  {
    path: "/sports/:pageType/:gamePageName?/:sportAlias?/:regionAlias?/:competitionId?/:competitionName?/:gameId?/:teamName?/:search?",
    element: <ATOX.SPORT />,
  },
  {
    path: "/casino/:casinoType?/:categoryType?/:gameId?",
    element: <ATOX.CASINO />,
  },
];

const contents = [...presentation];

export default contents;
