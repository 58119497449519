import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ThemeContext from '../../../context/ThemeContext';
import './DefaultHeader.scss';
import { atoxPagesMenu, sportPagesMenu } from '../../../menu';
import SymbolLogo from '../../../component/SymbolLogo';
import LangSelector from '../../../layout/LangSelector';
import CurrentTime from '../../../layout/CurrentTime';
import AuthContext from '../../../context/AuthContext';
import Icon from '../../../component/icon/Icon';
import SiteContext from '../../../context/SiteContext';
import useCombine from '../../../hooks/useCombine';
import classNames from 'classnames';

const SubMenu = ({ menuList }: { menuList: any }) => {
	const { mobileDesign } = useContext(ThemeContext);
	const location = useLocation();
	const isActive = (path: string, exact: boolean = false) =>
		exact ? location.pathname === path : location.pathname.startsWith(path);
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { pageType, gamePageName } = useParams();
	const [hiddenItems, setHiddenItems] = useState<number[]>([]);
	const [showMore, setShowMore] = useState(false);
	const menuRef = useRef<HTMLDivElement>(null);

	const [foundMenu, setFoundMenu] = useState<any>(null);

	useEffect(() => {
		const foundMenu = menuList.find((menu: any) => {
			if (location.pathname.startsWith(menu.link)) {
				return menu;
			}
			if (menu.subMenuItems) {
				return menu.subMenuItems.find((subMenu: any) => {
					return subMenu.link === location.pathname;
				});
			}
		});
		setFoundMenu(foundMenu);
	}, [location]);

	const updateMenu = () => {
		if (!menuRef.current) return;

		const containerWidth = menuRef.current.offsetWidth;
		let totalWidth = 0;
		let newHiddenItems: number[] = [];

		Array.from(menuRef.current.childNodes).forEach((node, index) => {
			const element = node as HTMLElement;
			totalWidth += element.offsetWidth;

			if (totalWidth > containerWidth) {
				newHiddenItems.push(index);
			}
		});

		setHiddenItems(newHiddenItems);
	};

	useEffect(() => {
		updateMenu();
		window.addEventListener('resize', updateMenu);
		return () => window.removeEventListener('resize', updateMenu);
	}, []);

	const showList =
		foundMenu && foundMenu.subMenuItems.length > 1 ? foundMenu.subMenuItems : menuList;

	return (
		<>
			{mobileDesign ? (
				<>
					<div className='hdr-navigation-scrollable-bc-holder'>
						<div
							className='hdr-navigation-scrollable-bc scroll-start'
							data-scroll-lock-scrollable=''>
							<nav className='hdr-navigation-scrollable-content'>
								{showList.map((subItem: any, index: number) => {
									if (subItem.href) {
										return (
											<a
												target='_blank'
												href={subItem.href}
												key={index}
												className={classnames('hdr-navigation-link-bc')}>
												{/* <i className={subItem.icon}></i> */}
												<span className='nav-menu-title'>
													{subItem.name}
												</span>
											</a>
										);
									} else {
										return (
											<Link
												to={subItem.link}
												key={index}
												className={classnames('hdr-navigation-link-bc', {
													active: isActive(subItem.link),
												})}>
												<span className='nav-menu-title'>
													{subItem.name}
												</span>
											</Link>
										);
									}
								})}
							</nav>
						</div>
					</div>
				</>
			) : (
				<div className='nav-content-bc'>
					<div className='nav-content-menu'>
						<nav className='nav-menu-container header-navigation '>
							<ul className='nav-menu nav-menu-hide-items'>
								<>
									{menuList.map((subItem: any) => {
										return (
											<li key={subItem.name} className=''>
												<button
													className={classnames('nav-menu-item', {
														active: isActive(subItem.link),
													})}
													onClick={() => {
														navigate(subItem.link);
													}}>
													<i className={subItem.icon}></i>
													<span>{subItem.name}</span>
												</button>
												{subItem.subMenuItems && (
													<ul className='nav-menu-sub'>
														{subItem.subMenuItems.map((item: any) => {
															return (
																<li key={item.name}>
																	{item.href ? (
																		<a
																			href={item.href}
																			target='_blank'
																			className='nav-menu-item'>
																			<i
																				className={
																					item.icon
																				}></i>
																			<span>{item.name}</span>
																		</a>
																	) : (
																		<button
																			className={classnames(
																				'nav-menu-item',
																				{
																					active: isActive(
																						item.link,
																					),
																				},
																			)}
																			onClick={() => {
																				navigate(item.link);
																			}}>
																			<i
																				className={
																					item.icon
																				}></i>
																			<span>{item.name}</span>
																		</button>
																	)}
																</li>
															);
														})}
													</ul>
												)}
											</li>
										);
									})}
								</>
							</ul>
						</nav>
					</div>
					{foundMenu && foundMenu.subMenuItems.length > 1 && (
						<nav className='nav-menu-container header-navigation '>
							<ul className='nav-menu nav-menu-hide-items'>
								{foundMenu.subMenuItems.map((subItem: any) => {
									if (subItem.href) {
										return (
											<li key={subItem.name} className=''>
												<a
													className='nav-menu-item'
													target='_blank'
													href={subItem.href}>
													<i className={subItem.icon}></i>
													<span>{subItem.name}</span>
												</a>
											</li>
										);
									} else {
										return (
											<li key={subItem.name} className=''>
												<a
													className={classnames('nav-menu-item', {
														active: isActive(subItem.link),
													})}
													onClick={() => {
														navigate(subItem.link);
													}}>
													<i className={subItem.icon}></i>
													<span>{subItem.name}</span>
												</a>
											</li>
										);
									}
								})}
							</ul>
							<ul className='nav-menu-other hidden-global'>
								<li className='nav-menu-item '>
									<i className='bc-i-apps'></i>More
									<ul className='nav-menu-sub'></ul>
								</li>
							</ul>
						</nav>
					)}
				</div>
			)}
		</>
	);
};

const ProfileMenu = () => {
	const { t } = useTranslation();
	const { sendLogoutRequest, userProfile } = useContext(AuthContext);
	const navigate = useNavigate();
	const { mobileDesign } = useContext(ThemeContext);

	const handleNavigation = (searchParams: string) => {
		navigate({
			search: searchParams,
		});
	};

	return (
		<ul className='nav-menu-other'>
			<li>
				<a className='' onClick={() => handleNavigation('?profile=open')}>
					<i
						className={classNames([
							'hdr-user-avatar-icon-bc bc-i-user',
							{
								'user-not-verified': !userProfile.isVerified,
							},
							{
								'user-verified': userProfile.isVerified,
							},
						])}></i>
				</a>
				{!mobileDesign && (
					<ul className='nav-menu-sub'>
						<li>
							<span
								className='nav-menu-item'
								onClick={() =>
									handleNavigation('?profile=open&account=balance&page=deposit')
								}>
								<i className='bc-i-balance-management'></i>
								<span className='checkbox-control-text-bc ellipsis'>
									{t('Balance Management')}
								</span>
							</span>
						</li>

						<li>
							<span
								className='nav-menu-item'
								onClick={() =>
									handleNavigation('?profile=open&account=bonuses&page=sport')
								}>
								<i className='bc-i-promotion'></i>
								<span className='checkbox-control-text-bc ellipsis'>
									{t('Bonuses')}
								</span>
							</span>
						</li>
						<li>
							<span
								className='nav-menu-item'
								onClick={() =>
									handleNavigation('?profile=open&account=profile&page=details')
								}>
								<i className='bc-i-user'></i>
								<span className='checkbox-control-text-bc ellipsis'>
									{t('Profile Details')}
								</span>
							</span>
						</li>
						<li>
							<span
								className='nav-menu-item'
								onClick={() =>
									handleNavigation('?profile=open&account=sport&page=bets')
								}>
								<i className='bc-i-history'></i>
								<span className='checkbox-control-text-bc ellipsis'>
									{t('Coupon History')}
								</span>
							</span>
						</li>
						<li>
							<span
								className='nav-menu-item'
								onClick={() =>
									handleNavigation('?profile=open&account=messages&page=inbox')
								}>
								<i className='bc-i-message'></i>
								<span className='checkbox-control-text-bc ellipsis'>
									{t('Messages')}
								</span>
							</span>
						</li>
						<li>
							<span
								className='nav-menu-item'
								onClick={() =>
									handleNavigation('?profile=open&account=rewards&page=rewards')
								}>
								<i className='bc-i-rewards'></i>
								<span className='checkbox-control-text-bc ellipsis'>
									{t('Rewards')}
								</span>
							</span>
						</li>
						<li className='logout-profile'>
							<button
								className='btn ellipsis'
								type='button'
								title={t('Logout')}
								onClick={sendLogoutRequest}>
								<span>{t('Logout')}</span>
							</button>
						</li>
					</ul>
				)}
			</li>
		</ul>
	);
};

const BalanceMenu = () => {
	const [financeOpen, setFinanceOpen] = useState(false);

	const { setSelectedCurrencyId, setSelectedFiatId } = useContext(SiteContext);
	const toggleFinance = (e: any) => {
		setFinanceOpen(!financeOpen);
	};
	const {
		getCurrencyListWithAccount,
		getCurrentAccount,
		parseCurrencyAmount,
		defaultCurrency,
		selectedCurrencyId,
	} = useCombine();
	const { t, i18n } = useTranslation();
	const refBalance = useRef<HTMLDivElement>(null);
	const [HideZeroBalance, setHideZeroBalance] = useState(false);
	const currentAccount = getCurrentAccount();
	const accountWithBalance = getCurrencyListWithAccount().filter(
		(currency: any) => currency.account?.balance.available > 0 || !HideZeroBalance,
	);

	const totalHiddenAccounts = getCurrencyListWithAccount().filter(
		(currency: any) => !currency.account || currency.account?.balance.available === 0,
	).length;

	return (
		<div className='hdr-selectCurrency'>
			<p className='hdr-selectCurrencyInfo  parent-currency ' onClick={toggleFinance}>
				<Icon icon={currentAccount.icon} className='currencyMenuIcon' />
				<i className='wallet-button-arrow  bc-i-small-arrow-down'></i>
			</p>
			{financeOpen && (
				<div className='wallet-menu' ref={refBalance}>
					<p className='wallet-top-arrow'></p>
					<div className='wallet-menu-content'>
						<div className='wallet-menu-top'>
							<p
								className='wallet-menu-btn'
								onClick={() => {
									setHideZeroBalance(!HideZeroBalance);
								}}>
								<i
									className={classNames([
										'hide-zero-balances',
										HideZeroBalance ? 'bc-i-eye-hidden' : 'bc-i-eye',
									])}></i>

								{HideZeroBalance ? (
									<span>
										{totalHiddenAccounts} {t('HiddenAccounts')}
									</span>
								) : (
									<span className=''>{t('HideZeroBalance')}</span>
								)}
							</p>
						</div>
						<ul className='wallet-currency'>
							{accountWithBalance.map((currency: any) => (
								<li
									className={classNames([
										'wallet-currency-item',
										{
											'parent-currency': currency.id === selectedCurrencyId,
										},
									])}
									key={'c' + currency.id}
									onClick={() => {
										setSelectedCurrencyId(currency.id);
										setFinanceOpen(false);
										if (currency.isFiat) {
											setSelectedFiatId(currency.id);
										}
									}}>
									<Icon icon={currency.icon} className='menuIcon' />
									<span className='wallet-currency-id'>{currency.name}</span>
									<p className='wallet-currency-value'>
										{defaultCurrency === currency.id && (
											<span className='wallet-main-wallet'>
												{t('MainWallet')}
											</span>
										)}
										<span>
											{
												parseCurrencyAmount(
													currency,
													currency.account?.balance.available,
													i18n.language,
												).str
											}
										</span>
										{!currency.isFiat && (
											<span className='wallet-main-wallet'>
												{
													parseCurrencyAmount(
														currency,
														currency.account?.balance.available,
														i18n.language,
														true,
													).str
												}
											</span>
										)}
									</p>
								</li>
							))}
						</ul>
					</div>
				</div>
			)}
		</div>
	);
};

const DefaultHeader = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const { mobileDesign } = useContext(ThemeContext);
	const { isAuthenticated } = useContext(AuthContext);

	const location = useLocation();

	const handleNavigation = (searchParams: any) => {
		navigate({ search: searchParams });
	};

	const { getCurrentAccount, parseCurrencyAmount } = useCombine();
	const currentAccount = getCurrentAccount();
	const parsedAccount = parseCurrencyAmount(
		currentAccount,
		currentAccount.account?.balance.available + currentAccount.account?.balance.bonus,
		i18n.language,
	);

	const searchParams = new URLSearchParams(location.search);
	const profilOpen = searchParams.get('profile');
	const toggleProfile = () => {
		if (profilOpen === 'open') {
			searchParams.delete('profile');
			navigate({ search: searchParams.toString() });
		} else {
			searchParams.set('profile', 'open');
			navigate({ search: searchParams.toString() });
		}
	};

	// const handleClickOutside = useCallback((event: MouseEvent) => {
	//   if (
	//     refBalance.current &&
	//     !refBalance.current.contains(event.target as Node)
	//   ) {
	//     setFinanceOpen(false);
	//   }
	// }, []);

	// useEffect(() => {
	//   document.addEventListener("mousedown", handleClickOutside);
	//   return () => {
	//     document.removeEventListener("mousedown", handleClickOutside);
	//   };
	// }, [handleClickOutside]);

	const menuItems = [
		{
			name: t('Live'),
			link: sportPagesMenu.sports.subMenu.live.path,
			icon: 'bc-i-live',
			subMenuItems: [
				{
					name: t('EventView'),
					link: sportPagesMenu.sports.subMenu.live.subMenu.eventView.path,
					icon: 'bc-i-sport',
				},
				{
					name: t('liveCalendar'),
					link: sportPagesMenu.sports.subMenu.live.subMenu.liveCalendar.path,
					icon: 'bc-i-two-column',
				},
				{
					name: t('Statistics'),
					href: sportPagesMenu.sports.subMenu.live.subMenu.statistics.href,
					icon: 'bc-i-statistics',
				},
			],
		},
		{
			name: t('Sport'),
			link: sportPagesMenu.sports.subMenu.prematch.path,
			icon: 'bc-i-prematch',
			subMenuItems: [
				{
					name: t('EventView'),
					link: sportPagesMenu.sports.subMenu.prematch.subMenu.eventView.path,
					icon: 'bc-i-sport',
				},
				{
					name: t('Results'),
					link: sportPagesMenu.sports.subMenu.prematch.subMenu.results.path,
					icon: 'bc-i-two-column',
				},
				{
					name: t('Statistics'),
					href: sportPagesMenu.sports.subMenu.live.subMenu.statistics.href,
					icon: 'bc-i-statistics',
				},
			],
		},
		{
			name: t('Casino'),
			link: atoxPagesMenu.casino.path,
			icon: 'bc-i-BestNetentSlots',
			subMenuItems: [
				{
					name: t('HomePage'),
					link: atoxPagesMenu.casino.path,
					icon: 'bc-i-BestNetentSlots',
				},
			],
		},
		{
			name: t('LiveCasino'),
			link: atoxPagesMenu.liveCasino.path,
			icon: 'bc-i-live-casino',
			subMenuItems: [
				{
					name: t('HomePage'),
					link: atoxPagesMenu.liveCasino.path,
					icon: 'bc-i-live-casino',
				},
			],
		},
	];

	return (
		<div className='layout-header-holder-bc'>
			<div
				className={classnames('header-bc', {
					'header-bc-isMobile': mobileDesign,
				})}>
				<div className='hdr-main-content-bc'>
					<div className='logo-container'>
						<Link to='/'>
							<SymbolLogo style={{ fontSize: '48px' }} />
						</Link>
					</div>

					{profilOpen && mobileDesign ? (
						<i
							onClick={() => toggleProfile()}
							className='hdr-user-close bc-i-close-remove'></i>
					) : (
						<>
							<div className='hdr-user-bc'>
								{!mobileDesign && (
									<div className='header-custom-buttons'>
										<button
											className='btn a-color header-icon-text bc-i-circle-dollar'
											onClick={() => {
												navigate({
													search: `?profile=open&account=balance&page=deposit`,
												});
											}}>
											<span>{t('Deposit')}</span>
										</button>

										<button
											className='btn a-color  header-icon-text bc-i-promotions-3'
											onClick={() => {
												navigate({
													search: `?profile=open&account=bonus&page=`,
												});
											}}></button>
									</div>
								)}

								<div className='header-buttons-wallet'>
									<button
										className='btn metamaskButton'
										type='button'
										title='CONNECT TO WALLET'>
										<Icon icon='CustomFox' style={{ fontSize: '24px' }} />
									</button>
								</div>

								{!isAuthenticated ? (
									<>
										<button
											className='btn s-small sign-in '
											type='button'
											onClick={() => navigate('/login')}
											title={t('Login')}>
											<span>{t('Login')}</span>
										</button>
										<button
											className='btn s-small register '
											type='button'
											onClick={() => navigate('/SignUp')}
											title={t('Register')}>
											<span>{t('Register')}</span>
										</button>
									</>
								) : (
									<div className='nav-menu-container header-user-nav'>
										<ul className='nav-menu-other'>
											<li>
												<a className='nav-menu-item'>
													<div className='hdr-user-info-content-bc '>
														<span className='hdr-user-info-texts-bc ext-1 ellipsis'>
															<span className='balanceAmount'>
																{parsedAccount?.str}
															</span>
															{!currentAccount.isFiat &&
																!mobileDesign && (
																	<span className='hdr-user-info-output'>
																		(
																		{
																			parseCurrencyAmount(
																				currentAccount,
																				currentAccount
																					.account
																					?.balance
																					.available,
																				i18n.language,
																				true,
																			).str
																		}
																		)
																	</span>
																)}
														</span>
													</div>
												</a>

												<ul className='nav-menu-sub'>
													<li>
														<span
															className='nav-menu-item'
															onClick={() =>
																handleNavigation(
																	'?profile=open&account=balance&page=deposit',
																)
															}>
															<i className='bc-i-circle-dollar'></i>
															<span className='checkbox-control-text-bc ellipsis'>
																{t('Deposit')}
															</span>
														</span>
													</li>
													<li>
														<span
															className='nav-menu-item'
															onClick={() =>
																handleNavigation(
																	'?profile=open&account=balance&page=withdraw',
																)
															}>
															<i className='bc-i-withdraw'></i>
															<span className='checkbox-control-text-bc ellipsis'>
																{t('Withdraw')}
															</span>
														</span>
													</li>
													<li>
														<span
															className='nav-menu-item'
															onClick={() =>
																handleNavigation(
																	'?profile=open&account=balance&page=history',
																)
															}>
															<i className='bc-i-history'></i>
															<span className='checkbox-control-text-bc ellipsis'>
																{t('transactionHistory')}
															</span>
														</span>
													</li>
													<li>
														<span
															className='nav-menu-item'
															onClick={() =>
																handleNavigation(
																	'?profile=open&account=balance&page=withdraws',
																)
															}>
															<i className='bc-i-withdraws'></i>
															<span className='checkbox-control-text-bc ellipsis'>
																{t('WithdrawStatus')}
															</span>
														</span>
													</li>
												</ul>
											</li>
										</ul>

										<ul className='nav-menu-other'>
											<li>
												<BalanceMenu />
											</li>
										</ul>
										{isAuthenticated && <ProfileMenu />}
									</div>
								)}
							</div>

							{!mobileDesign && (
								<>
									<LangSelector />
									<span className='hdr-time-bc'>
										<CurrentTime />
									</span>
								</>
							)}
						</>
					)}
				</div>

				{mobileDesign && (
					<div className='hdr-additional-info'>
						<div className='crypto-currency-data' style={{ display: 'flex' }}>
							<i className='bc-i-ATX'></i>
							<a
								href='https://exchange.atox.app/?refId=4&amp;lang=en'
								target='_blank'
								className='crypto-currency-info'>
								<span className='crypto-currency-item'>1 ATX = 2.2139 USDT</span>
								<span>exchange.atox.app</span>
							</a>
						</div>
						<div className='hdr-user-bc'>
							<a
								className='user-nav-icon bc-i-wallet'
								onClick={() => {
									navigate({
										search: `?profile=open&account=balance&page=deposit`,
									});
								}}></a>
						</div>
					</div>
				)}
				<SubMenu menuList={menuItems} />
			</div>
		</div>
	);
};

export default DefaultHeader;
