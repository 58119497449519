import * as React from 'react';
import { SVGProps } from 'react';
const SvgChz = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none' fillRule='evenodd'>
			<circle cx={16} cy={16} r={16} fill='#CD0124' fillRule='nonzero' />
			<path
				fill='#FFF'
				d='M23.319 7.25c.652 0 1.181.546 1.181 1.22 0 .674-.529 1.22-1.181 1.22l-.073-.001-2.544 6.192c.145.203.231.454.231.725 0 .62-.447 1.132-1.026 1.21l-2.623 6.385c.195.217.314.509.314.829 0 .674-.529 1.22-1.181 1.22s-1.181-.546-1.181-1.22c0-.324.121-.618.32-.836l-3.007-6.371-.07.004c-.611 0-1.114-.48-1.175-1.096l-.006-.125c0-.309.112-.591.295-.807L8.71 9.69l-.028.002c-.611 0-1.114-.48-1.175-1.096L7.5 8.47c0-.674.529-1.22 1.181-1.22.497 0 .922.317 1.096.765h12.446c.174-.448.6-.765 1.096-.765zm-7.077 7.141-2.607 1.96a1.238 1.238 0 0 1-.29 1.086l3.01 6.375.062-.003c.023 0 .047 0 .07.002l2.558-6.227a1.252 1.252 0 0 1-.385-1.444l-2.418-1.749zM9.66 9.63l2.719 5.761.1-.005c.247 0 .475.078.664.21l2.337-1.756-5.82-4.21zm12.531.296-5.196 3.9 2.291 1.659c.108-.048.224-.08.345-.093l.121-.006c.065 0 .128.005.19.016l2.249-5.476zm-.164-1.001H10.22l6.013 4.35 5.794-4.35z'
			/>
		</g>
	</svg>
);
export default SvgChz;
