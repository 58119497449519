import * as React from 'react';
import { SVGProps } from 'react';
const SvgRegionSomalia = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		viewBox='0 0 81 54'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path fill='#4189DD' d='M0 0h81v54H0z' />
		<g transform='matrix(13 0 0 13 40.5 27)'>
			<g id='regionSomalia_svg__b'>
				<path
					id='regionSomalia_svg__a'
					fill='#FFF'
					d='M0 0v1h.5z'
					transform='rotate(18 3.157 -.5)'
				/>
				<use xlinkHref='#regionSomalia_svg__a' transform='scale(-1 1)' />
			</g>
			<use xlinkHref='#regionSomalia_svg__b' transform='rotate(72)' />
			<use xlinkHref='#regionSomalia_svg__b' transform='rotate(-72)' />
			<use xlinkHref='#regionSomalia_svg__b' transform='rotate(144)' />
			<use xlinkHref='#regionSomalia_svg__b' transform='rotate(-144)' />
		</g>
	</svg>
);
export default SvgRegionSomalia;
