import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar17 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar17_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar17_svg__cls-6{fill:#fff}.avatar17_svg__cls-3{fill:#8f5653}.avatar17_svg__cls-12,.avatar17_svg__cls-5{fill:#393c54}.avatar17_svg__cls-14,.avatar17_svg__cls-5{opacity:.5}.avatar17_svg__cls-13,.avatar17_svg__cls-19,.avatar17_svg__cls-22,.avatar17_svg__cls-6,.avatar17_svg__cls-8{opacity:.1}.avatar17_svg__cls-10,.avatar17_svg__cls-11,.avatar17_svg__cls-13,.avatar17_svg__cls-14,.avatar17_svg__cls-16,.avatar17_svg__cls-18,.avatar17_svg__cls-19,.avatar17_svg__cls-20,.avatar17_svg__cls-21,.avatar17_svg__cls-22,.avatar17_svg__cls-23,.avatar17_svg__cls-7,.avatar17_svg__cls-8,.avatar17_svg__cls-9{fill:none;stroke-linecap:round}.avatar17_svg__cls-11,.avatar17_svg__cls-18,.avatar17_svg__cls-20,.avatar17_svg__cls-21,.avatar17_svg__cls-23,.avatar17_svg__cls-7{stroke:#8f5653}.avatar17_svg__cls-11,.avatar17_svg__cls-13,.avatar17_svg__cls-14,.avatar17_svg__cls-16,.avatar17_svg__cls-18,.avatar17_svg__cls-19,.avatar17_svg__cls-20,.avatar17_svg__cls-21,.avatar17_svg__cls-22,.avatar17_svg__cls-23,.avatar17_svg__cls-7,.avatar17_svg__cls-8{stroke-miterlimit:10}.avatar17_svg__cls-7,.avatar17_svg__cls-8,.avatar17_svg__cls-9{stroke-width:18px}.avatar17_svg__cls-13,.avatar17_svg__cls-14,.avatar17_svg__cls-19,.avatar17_svg__cls-22,.avatar17_svg__cls-8{stroke:#fff}.avatar17_svg__cls-10,.avatar17_svg__cls-9{stroke:#7f3838;stroke-linejoin:round}.avatar17_svg__cls-10,.avatar17_svg__cls-11{stroke-width:14px}.avatar17_svg__cls-13{stroke-width:4px}.avatar17_svg__cls-14,.avatar17_svg__cls-16{stroke-width:2px}.avatar17_svg__cls-16{stroke:#f8dc25}.avatar17_svg__cls-17{fill:#7f3838}.avatar17_svg__cls-18,.avatar17_svg__cls-19{stroke-width:10px}.avatar17_svg__cls-20{stroke-width:8px}.avatar17_svg__cls-21,.avatar17_svg__cls-22{stroke-width:6px}.avatar17_svg__cls-23{stroke-width:5px}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#00adfe',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.3,
				fill: '#fff',
			}}
		/>
		<path
			d='M64 4a60 60 0 0 0-51.18 28.68 55.32 55.32 0 0 0 102.36 0A60 60 0 0 0 64 4Z'
			className='avatar17_svg__cls-3'
		/>
		<path
			d='M64 4a60 60 0 0 0-51.18 28.68 55.32 55.32 0 0 0 102.36 0A60 60 0 0 0 64 4Z'
			style={{
				fill: '#356cb6',
				opacity: 0.3,
			}}
		/>
		<path
			d='M44.37 7.29A60 60 0 0 0 35.8 11c5.35 33.28 16 56 28.2 56-8.66 0-16.13-24.4-19.63-59.71ZM92.2 11a60 60 0 0 0-8.57-3.74C80.13 42.6 72.66 67 64 67c12.24 0 22.85-22.69 28.2-56Z'
			className='avatar17_svg__cls-5'
		/>
		<path
			d='M64 48.78c-5.89 0-11-17.67-13.37-43.28a60.13 60.13 0 0 0-6 1.69C48.27 31.88 55.57 48.78 64 48.78ZM77.37 5.5C75 31.11 69.89 48.78 64 48.78c8.43 0 15.73-16.9 19.35-41.59a60.13 60.13 0 0 0-5.98-1.69Z'
			className='avatar17_svg__cls-6'
		/>
		<path d='m93.46 55.6 13.17-18.19' className='avatar17_svg__cls-7' />
		<path d='m93.46 55.6 13.17-18.19' className='avatar17_svg__cls-8' />
		<path d='M85.38 55.6h8.08' className='avatar17_svg__cls-9' />
		<path
			d='M114.77 32a9 9 0 0 0-12.42 12.68L122.94 73c.1.14.21.26.32.39A61 61 0 0 0 124 64a59.74 59.74 0 0 0-9.23-32Z'
			className='avatar17_svg__cls-3'
		/>
		<path d='M34.54 55.6 21.37 37.41' className='avatar17_svg__cls-7' />
		<path d='M34.54 55.6 21.37 37.41' className='avatar17_svg__cls-8' />
		<path d='M42.62 55.6h-8.08' className='avatar17_svg__cls-9' />
		<path
			d='M23.79 32.23A9 9 0 0 0 13.24 32 59.69 59.69 0 0 0 4 64a60.56 60.56 0 0 0 .62 8.57c.11-.14.24-.27.35-.41l20.59-27.33a9 9 0 0 0-1.77-12.6Z'
			className='avatar17_svg__cls-3'
		/>
		<path d='m94.25 91.56 6.16-27.19' className='avatar17_svg__cls-10' />
		<path d='M86.16 91.56h8.09M100.41 64.37l10.17 13.62' className='avatar17_svg__cls-11' />
		<path
			d='m77.75 77.37 8.41 14.19M33.75 91.56l-6.16-27.19'
			className='avatar17_svg__cls-10'
		/>
		<path d='M41.84 91.56h-8.09M27.59 64.37 17.42 77.99' className='avatar17_svg__cls-11' />
		<path d='m50.25 77.37-8.41 14.19' className='avatar17_svg__cls-10' />
		<path
			d='M80.93 87.33H47.07a10 10 0 0 1-10-10.39l1.29-27.87a9.94 9.94 0 0 1 7.76-9.35 83.48 83.48 0 0 1 35.74 0 9.94 9.94 0 0 1 7.76 9.35l1.29 27.87a10 10 0 0 1-9.98 10.39Z'
			className='avatar17_svg__cls-3'
		/>
		<circle cx={55.6} cy={53.35} r={7.4} className='avatar17_svg__cls-12' />
		<path d='M51.2 53.35A4.4 4.4 0 0 1 55.6 49' className='avatar17_svg__cls-13' />
		<circle cx={44.5} cy={47.35} r={3.75} className='avatar17_svg__cls-12' />
		<path d='M42.75 47.35a1.75 1.75 0 0 1 1.75-1.75' className='avatar17_svg__cls-14' />
		<circle cx={83.5} cy={47.35} r={3.75} className='avatar17_svg__cls-12' />
		<path d='M81.75 47.35a1.75 1.75 0 0 1 1.75-1.75' className='avatar17_svg__cls-14' />
		<g
			style={{
				opacity: 0.2,
			}}>
			<path
				d='M49.16 61.51a10.4 10.4 0 0 1-4-8.16M61.41 62a10.36 10.36 0 0 1-5.81 1.78M44.5 54.1a6.67 6.67 0 0 1-4.29-1.55M87.8 52.55a6.72 6.72 0 0 1-4.3 1.55M72.4 63.75a10.42 10.42 0 0 1-8.4-4.28M82.8 53.35a10.44 10.44 0 0 1-.81 4'
				className='avatar17_svg__cls-16'
			/>
		</g>
		<circle cx={72.4} cy={53.35} r={7.4} className='avatar17_svg__cls-12' />
		<path d='M68 53.35A4.4 4.4 0 0 1 72.4 49' className='avatar17_svg__cls-13' />
		<path
			d='M53.14 49.7a4.33 4.33 0 0 1 2.46-.7M69.94 49.7a4.36 4.36 0 0 1 2.46-.7'
			className='avatar17_svg__cls-14'
		/>
		<rect width={16} height={24} x={66} y={73} className='avatar17_svg__cls-17' rx={4} />
		<rect width={16} height={24} x={46} y={73} className='avatar17_svg__cls-17' rx={4} />
		<path d='m83.34 75.44-3 9' className='avatar17_svg__cls-18' />
		<path d='m83.34 75.44-3 9' className='avatar17_svg__cls-19' />
		<path d='m79.53 86.17-1.39 6.66' className='avatar17_svg__cls-20' />
		<path d='m77.66 94.11.35 6.78' className='avatar17_svg__cls-21' />
		<path d='m77.66 94.11.35 6.78' className='avatar17_svg__cls-22' />
		<path d='m77.66 94.11.35 6.78' className='avatar17_svg__cls-21' />
		<path d='m77.66 94.11.35 6.78' className='avatar17_svg__cls-22' />
		<path d='m77.66 101.11 1.25 4.74' className='avatar17_svg__cls-23' />
		<path d='m44.66 75.44 3 9' className='avatar17_svg__cls-18' />
		<path d='m44.66 75.44 3 9' className='avatar17_svg__cls-19' />
		<path d='m48.47 86.17 1.39 6.66' className='avatar17_svg__cls-20' />
		<path d='m50.34 94.11-.35 6.78' className='avatar17_svg__cls-21' />
		<path d='m50.34 94.11-.35 6.78' className='avatar17_svg__cls-22' />
		<path d='m50.34 94.11-.35 6.78' className='avatar17_svg__cls-21' />
		<path d='m50.34 94.11-.35 6.78' className='avatar17_svg__cls-22' />
		<path d='m50.34 101.11-1.25 4.74' className='avatar17_svg__cls-23' />
		<path
			d='M116.29 76.14a6 6 0 1 0-11.42 3.7l5.67 17.49a5.94 5.94 0 0 0 1.59 2.5 60.16 60.16 0 0 0 7.51-13.36Z'
			className='avatar17_svg__cls-3'
		/>
		<path
			d='M116.29 76.14a6 6 0 1 0-11.42 3.7l5.67 17.49a5.94 5.94 0 0 0 1.59 2.5 60.16 60.16 0 0 0 7.51-13.36Z'
			className='avatar17_svg__cls-6'
		/>
		<path
			d='M19.27 72.28a6 6 0 0 0-7.56 3.86L8.36 86.47a60.16 60.16 0 0 0 7.51 13.36 5.94 5.94 0 0 0 1.59-2.5l5.67-17.49a6 6 0 0 0-3.86-7.56Z'
			className='avatar17_svg__cls-3'
		/>
		<path
			d='M19.27 72.28a6 6 0 0 0-7.56 3.86L8.36 86.47a60.16 60.16 0 0 0 7.51 13.36 5.94 5.94 0 0 0 1.59-2.5l5.67-17.49a6 6 0 0 0-3.86-7.56Z'
			className='avatar17_svg__cls-6'
		/>
	</svg>
);
export default SvgAvatar17;
