import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar30 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar30_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<linearGradient
				id='avatar30_svg__linear-gradient'
				x1={-28.47}
				x2={-28.47}
				y1={40.35}
				y2={94}
				gradientTransform='matrix(-1 0 0 1 67.62 0)'
				gradientUnits='userSpaceOnUse'>
				<stop offset={0} stopColor='#fff' />
				<stop offset={0.99} stopColor='#00adfe' />
			</linearGradient>
			<linearGradient
				id='avatar30_svg__linear-gradient-2'
				x1={31.91}
				x2={31.91}
				y1={40.35}
				y2={94}
				gradientUnits='userSpaceOnUse'>
				<stop offset={0} stopColor='#fff' />
				<stop offset={0.99} stopColor='#f85565' />
			</linearGradient>
			<style>
				{
					'.avatar30_svg__cls-1{fill:#515570}.avatar30_svg__cls-3{fill:#fff}.avatar30_svg__cls-5{fill:#fbc0aa}.avatar30_svg__cls-7{fill:#f85565}.avatar30_svg__cls-8{fill:#ffd8c9}.avatar30_svg__cls-12,.avatar30_svg__cls-13,.avatar30_svg__cls-15{fill:none;stroke-linecap:round;stroke-linejoin:round}.avatar30_svg__cls-12,.avatar30_svg__cls-15{stroke:#515570}.avatar30_svg__cls-12,.avatar30_svg__cls-13{stroke-width:2px}.avatar30_svg__cls-12{opacity:.2}.avatar30_svg__cls-13{stroke:#f85565}.avatar30_svg__cls-17{fill:#393c54}'
				}
			</style>
		</defs>
		<circle cx={64} cy={64} r={60} className='avatar30_svg__cls-1' />
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				fill: '#6b718e',
			}}
		/>
		<path
			d='M64 21a29.69 29.69 0 0 1 29.69 29.69v8.93a15.93 15.93 0 0 1-15.93 15.93H50.24a15.93 15.93 0 0 1-15.93-15.93v-8.93A29.69 29.69 0 0 1 64 21Z'
			className='avatar30_svg__cls-3'
		/>
		<path
			d='M89.81 34c1.56 5 .46 41.91.82 53.58A6.62 6.62 0 0 0 97.25 94a6.62 6.62 0 0 0 6.62-6.59c.06-12.28 1.86-51.18-.62-59.09a10 10 0 0 0-12.54-6.54c-5.27 1.66-2.56 6.95-.9 12.22Z'
			style={{
				fill: 'url(#avatar30_svg__linear-gradient)',
			}}
		/>
		<circle cx={89} cy={63} r={7} className='avatar30_svg__cls-5' />
		<path
			d='M38.19 34c-1.56 5-.46 41.91-.82 53.58A6.62 6.62 0 0 1 30.75 94a6.62 6.62 0 0 1-6.62-6.59c-.06-12.28-1.86-51.18.62-59.09a10 10 0 0 1 12.54-6.54c5.27 1.66 2.56 6.95.9 12.22Z'
			style={{
				fill: 'url(#avatar30_svg__linear-gradient-2)',
			}}
		/>
		<path
			d='M64 124.05a59.66 59.66 0 0 0 34.69-11l-3.32-9.36A10 10 0 0 0 86 97H42.05a10 10 0 0 0-9.42 6.64L29.31 113A59.66 59.66 0 0 0 64 124.05Z'
			className='avatar30_svg__cls-3'
		/>
		<path
			d='M86 97H42.05a10 10 0 0 0-8.2 4.29l9.25 12.5a3 3 0 0 0 2.41 1.21h37a3 3 0 0 0 2.41-1.21l9.25-12.5A10 10 0 0 0 86 97Z'
			className='avatar30_svg__cls-7'
		/>
		<path
			d='M50.5 98.19a13.5 13.5 0 0 0 27 0 10.74 10.74 0 0 0-.07-1.19H50.57a10.74 10.74 0 0 0-.07 1.19Z'
			className='avatar30_svg__cls-8'
		/>
		<path
			d='M64 86.75V97.5'
			style={{
				strokeWidth: 14,
				stroke: '#fbc0aa',
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
		<circle cx={39} cy={63} r={7} className='avatar30_svg__cls-5' />
		<path
			d='M64 93a25 25 0 0 1-25-25V51.52a25 25 0 1 1 50 0V68a25 25 0 0 1-25 25Z'
			className='avatar30_svg__cls-8'
		/>
		<path
			d='M74.79 57.39A3.79 3.79 0 0 0 71 61.56 3.89 3.89 0 0 0 75 65h4.58a6.63 6.63 0 0 1 6.63 6.64H89v-5.52a8.73 8.73 0 0 0-8.72-8.73Z'
			style={{
				opacity: 0.5,
				fill: '#00adfe',
			}}
		/>
		<circle cx={75} cy={61} r={2.5} className='avatar30_svg__cls-1' />
		<path
			d='M64 65.75v4.5'
			style={{
				strokeWidth: 5,
				stroke: '#fbc0aa',
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
		<path d='M72.39 55 81 54' className='avatar30_svg__cls-12' />
		<path d='M58 84v4M57 81v4' className='avatar30_svg__cls-13' />
		<path
			d='M54.22 57.39A3.78 3.78 0 0 1 58 61.55 3.88 3.88 0 0 1 54.06 65h-4.58a6.64 6.64 0 0 0-6.64 6.64H40v-5.52a8.73 8.73 0 0 1 8.73-8.73Z'
			style={{
				opacity: 0.5,
				fill: '#f85565',
			}}
		/>
		<circle cx={54.01} cy={61} r={2.5} className='avatar30_svg__cls-1' />
		<path d='M58.49 63.33a6.62 6.62 0 0 0-10.72-4.39' className='avatar30_svg__cls-15' />
		<path
			d='M32.5 86.75c3.87 0 9-3.13 9-7V69.43c0-16 6.58-30.81 20.49-38.76l2-1.15 2 1.15C79.92 38.62 87.5 53.41 87.5 69.43v10.32c0 3.87 5.13 7 9 7'
			style={{
				stroke: '#fff',
				strokeWidth: 4,
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
		<path
			d='M40 71.82v-2.39c0-15.29 7.82-24.9 20.61-32.65a7.56 7.56 0 0 1 3.89-1.11 7.56 7.56 0 0 1 3.89 1.11C81.18 44.53 89 54.14 89 69.43v1.48a15.87 15.87 0 0 0 4.69-11.29v-8.93A29.69 29.69 0 0 0 64 21a29.69 29.69 0 0 0-29.69 29.69v8.93A15.88 15.88 0 0 0 40 71.82Z'
			className='avatar30_svg__cls-3'
		/>
		<path d='M56 53s-1-2-5-2-5 2-5 2' className='avatar30_svg__cls-12' />
		<path
			d='M64 87.56a9.58 9.58 0 0 1-9.49-8.37A2 2 0 0 1 56.45 77h15.1a2 2 0 0 1 1.94 2.19A9.58 9.58 0 0 1 64 87.56Z'
			className='avatar30_svg__cls-7'
		/>
		<path
			d='M71.1 78.25a.89.89 0 0 1 .89 1 8.05 8.05 0 0 1-16 0 .89.89 0 0 1 .89-1Z'
			className='avatar30_svg__cls-17'
		/>
		<path
			d='M56 79.26a6.08 6.08 0 0 0 .16.87h15.67a6.21 6.21 0 0 0 .16-.86.89.89 0 0 0-.89-1H56.9a.89.89 0 0 0-.9.99Z'
			className='avatar30_svg__cls-3'
		/>
		<path d='M70.52 63.33a6.62 6.62 0 0 1 10.72-4.39' className='avatar30_svg__cls-15' />
		<path d='M56 94h16v6H56z' className='avatar30_svg__cls-17' />
		<path
			d='m88.83 34.74.79-2.74-2-8-4.37 5.17 5.58 5.57z'
			style={{
				fill: '#356cb6',
			}}
		/>
		<path d='M38.79 34.74 38 32l2-8 4.37 5.17-5.58 5.57z' className='avatar30_svg__cls-7' />
	</svg>
);
export default SvgAvatar30;
