import * as React from 'react';
import { SVGProps } from 'react';
const SvgGzr = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 32 32'
		className='svg-icon'
		{...props}>
		<g fill='none'>
			<circle cx={16} cy={16} r={16} fill='#56C9E9' />
			<g fill='#FFF'>
				<path d='M23.88 9.443c-.754-.278-2.42.138-2.5.257.318.06.417.1.893.179.51.093 1 .274 1.448.536.476.277.674-.1.674-.1.08-.178-.138-.734-.515-.872zM10.333 6.15a6.093 6.093 0 0 0-.397-.555c-.377-.397-1.11-.595-1.508-.397-.218.1-.317.238-.138.476.217.215.493.36.793.417.178.04.972.218 1.17.257.2 0 .08-.198.08-.198zm.793 2.003a.155.155 0 0 0-.08-.079 2.2 2.2 0 0 1-1.408-.297.567.567 0 0 0-.654 0c-.258.218.119.476.139.476.158.099.337.06.496.099a6.01 6.01 0 0 0 1.19.218c.337 0 .416-.12.317-.417zm13.131-.733c.04-.12 0-.516-.715-.417-.595.1-1.011.258-.991.337.02.08.674.199.813.218.555.1.873-.06.893-.138zm-7.22-3.313a.397.397 0 0 0-.516-.02c-.595.377-.159 1.666-.1 1.666.06 0 .12-.456.715-1.13a.365.365 0 0 0-.1-.516zm-1.891 3.831c0-.05-.05-.05 0 0z' />
				<path d='M14.577 7.3c-.377-.198-.06-1.17-.198-1.527a.343.343 0 0 0-.536-.198.38.38 0 0 0-.178.555c.08.159.555 1.071.178 1.23-.139.06-.436-.06-.634-.179-.714-.317-.833-1.408-1.052-1.547a.563.563 0 0 0-.694.02.458.458 0 0 0-.198.575c.099.179.476.278.555.893l.238.178c.12.1.238.199.377.298.357.357.278.417 1.012.298.238-.08.198-.397.436-.476a.837.837 0 0 1 .436.059c.219.116.403.287.536.496.317.377.456.853.734 1.27.096.174.216.334.357.475l.297.238c.417.278.794.258 1.091-.06a.728.728 0 0 0 .298-.495 8.398 8.398 0 0 1 .6-1.383.02.02 0 0 1 .015-.005.044.044 0 0 1 .04-.04.327.327 0 0 1 .376 0c.06.08-.06.813 0 .892.04.16.337.06.595.04a.434.434 0 0 0 .06-.1c.436-.138.853-.356 1.289-.475h.238c.243-.04.49-.04.734 0 .238 0 .317.139.297.357.397-.02.794-.02 1.19-.06.179-.02.457-.02.437-.357-.04-.198-.357-.753-1.805-.416-.139.04-.932.278-1.21.139.423-.274.86-.525 1.309-.754.198-.159.357-.377.238-.615s-.357-.218-.575-.139a2.683 2.683 0 0 0-1.091.655c-.201.204-.42.39-.655.555-.575.278-.575.278-.714.238-.178-.08-.377-.218-.238-.416.081-.129.141-.27.179-.417.06-.258-.02-.337-.199-.337-.357.02-.476.535-.535.615-.038.078-.085.15-.139.218a.345.345 0 0 1-.357.08c-.119-.02-.218-.08-.119-.239.179-.277.734-.714.893-1.289 0 0 .277-.754-.496-.773-.417-.02-.754.277-.873.733-.119.476.139 1.131 0 1.448-.05.134-.117.26-.198.377a.728.728 0 0 1-.972 0 .793.793 0 0 1-.179-.654 7.15 7.15 0 0 0 .159-.794c.02-.198-.337-.555-.635-.317-.139.1-.08.258-.02.397.1.297.278 1.15-.694.733zm.556.615c-.02-.02 0-.02 0 0z' />
				<path d='M15.787 8.81h1.31c.118-.026.235.066.317.248.006.1-.018.198-.06.248a1.633 1.633 0 0 0-1.388 0h-.06l-.119-.496z' />
				<path d='M20.587 8.272a13.4 13.4 0 0 0-1.487.516.216.216 0 0 1-.239-.08.337.337 0 0 1 0-.277.337.337 0 0 0 0-.198.659.659 0 0 0-.198-.278.34.34 0 0 0-.516.1 4.824 4.824 0 0 0-.595 1.348.992.992 0 0 1-.297.496c-.298.258-.575.278-.972.06a3.622 3.622 0 0 1-.317-.259 2.85 2.85 0 0 1-.357-.495c-.16-.298-.318-.615-.476-.893-.219-.357-.437-.694-.833-.873a.678.678 0 0 0-.397-.02.41.41 0 0 0-.278.199c-.06.099-.853.119-1.13-.04-.14-.08-.536-.476-.675-.436-.119 0-.178-.08-.258-.139a1.36 1.36 0 0 0-1.23-.159.446.446 0 0 0-.337.357.448.448 0 0 0 .298.417c.197.074.389.16.575.258.1.04.258.06.238.238a.397.397 0 0 0 .02.139c.02.257-.159.277-.337.277a4.959 4.959 0 0 1-.754-.119 2.063 2.063 0 0 0-.853-.099c-.08.1-.178.04-.278.06-.357.06-.634.317-.614.614.02.338.297.417.575.476.674.16 1.349.1 2.023.12.813 0 1.27.396 1.388 1.21.08.594 1.865 16.224 1.885 16.561a.315.315 0 0 0 .238.298c.674.205 1.378.3 2.082.278.791.02 1.58-.08 2.34-.298.12-.04.219-.08.219-.238.02-.416 1.15-15.352 1.15-15.59a3.074 3.074 0 0 1 .635-1.924c.337-.397.714-.754 1.051-1.15.1-.556-.615-.556-1.289-.457z' />
			</g>
		</g>
	</svg>
);
export default SvgGzr;
