import React, { useContext, useEffect, useState } from "react";
import SocketContext from "./context/SocketContext";

const DynamicStyles = () => {
  const [styles, setStyles] = useState("");
  const { styleString } = useContext(SocketContext);

  useEffect(() => {
    const dat = `:root {
      ${styleString}
    }`;

    setStyles(dat);
  }, [styleString]);

  return <style>{styles}</style>;
};

export default DynamicStyles;
