import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar23 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar23_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar23_svg__cls-3{fill:#fff}.avatar23_svg__cls-4{fill:#fbc0aa}.avatar23_svg__cls-10{fill:none;stroke:#fbc0aa;stroke-linecap:round;stroke-linejoin:round}.avatar23_svg__cls-7,.avatar23_svg__cls-8{fill:#393c54}.avatar23_svg__cls-8{opacity:.1}.avatar23_svg__cls-10{stroke-width:2px}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#4bc190',
			}}
		/>
		<circle
			cx={64}
			cy={64}
			r={48}
			style={{
				opacity: 0.3,
				fill: '#356cb6',
			}}
		/>
		<circle cx={30.76} cy={59.37} r={10} className='avatar23_svg__cls-3' />
		<circle cx={97.24} cy={59.37} r={10} className='avatar23_svg__cls-3' />
		<circle cx={32.76} cy={41.77} r={12.43} className='avatar23_svg__cls-3' />
		<circle cx={95.24} cy={41.77} r={12.43} className='avatar23_svg__cls-3' />
		<circle cx={44.5} cy={25.34} r={12.43} className='avatar23_svg__cls-3' />
		<circle cx={83.5} cy={25.34} r={12.43} className='avatar23_svg__cls-3' />
		<circle cx={91.32} cy={64.43} r={7.93} className='avatar23_svg__cls-4' />
		<path
			d='M64 124.1a59.78 59.78 0 0 0 40-15.28l-2.39-5.68c-1.71-4-6.22-6.64-11.29-6.64H37.69c-5.07 0-9.58 2.66-11.29 6.64l-2.4 5.68a59.78 59.78 0 0 0 40 15.28Z'
			className='avatar23_svg__cls-3'
		/>
		<path
			d='M79 96.5H48.85a15.07 15.07 0 0 0 30.15 0Z'
			style={{
				fill: '#515570',
			}}
		/>
		<path
			d='M64 84.75V98.5'
			style={{
				strokeWidth: 20,
				fill: 'none',
				stroke: '#fbc0aa',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
		<path
			d='m64 113 9.66 3.51a1 1 0 0 0 1.34-.94v-11.14a1 1 0 0 0-1.34-.94L64 107ZM64 113l-9.66 3.51a1 1 0 0 1-1.34-.94v-11.14a1 1 0 0 1 1.34-.94L64 107Z'
			className='avatar23_svg__cls-7'
		/>
		<circle cx={64} cy={110} r={5} className='avatar23_svg__cls-7' />
		<path
			d='M64 103.25a11 11 0 0 1-10.78-8.8 11.4 11.4 0 0 0-.22 2.2 11 11 0 0 0 22 0 11.4 11.4 0 0 0-.22-2.2 11 11 0 0 1-10.78 8.8Z'
			className='avatar23_svg__cls-3'
		/>
		<path
			d='M64 103.25a11 11 0 0 1-10.78-8.8 11.4 11.4 0 0 0-.22 2.2 11 11 0 0 0 22 0 11.4 11.4 0 0 0-.22-2.2 11 11 0 0 1-10.78 8.8Z'
			className='avatar23_svg__cls-8'
		/>
		<path
			d='M72.78 101.1 64 105.74l-8.78-4.64a.9.9 0 0 0-1.22.7v8.4a.9.9 0 0 0 1.22.7l8.78-4.64 8.78 4.64a.9.9 0 0 0 1.22-.7v-8.4a.9.9 0 0 0-1.22-.7Z'
			className='avatar23_svg__cls-3'
		/>
		<path
			d='M72.78 101.1 64 105.74l-8.78-4.64a.9.9 0 0 0-1.22.7v8.4a.9.9 0 0 0 1.22.7l8.78-4.64 8.78 4.64a.9.9 0 0 0 1.22-.7v-8.4a.9.9 0 0 0-1.22-.7Z'
			className='avatar23_svg__cls-8'
		/>
		<circle cx={36.68} cy={64.43} r={7.93} className='avatar23_svg__cls-4' />
		<path
			d='M64 98.37a28.31 28.31 0 0 1-28.32-28.32V55.43a28.32 28.32 0 1 1 56.64 0v14.62A28.31 28.31 0 0 1 64 98.37Z'
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<path d='M60 94a7 7 0 0 1 8 0' className='avatar23_svg__cls-10' />
		<path
			d='M71.55 80a1 1 0 0 1 .94 1.07 8.56 8.56 0 0 1-17 0 1 1 0 0 1 .96-1.07Z'
			className='avatar23_svg__cls-7'
		/>
		<path
			d='M64 92a4 4 0 0 1-4-4V77h8v11a4 4 0 0 1-4 4Z'
			style={{
				fill: '#f85565',
			}}
		/>
		<path
			d='M51.25 82.26c0-5.67 5.68-10.26 12.67-10.26s12.67 4.59 12.67 10.26'
			className='avatar23_svg__cls-3'
		/>
		<path d='M64 77a5 5 0 0 1-5-5V57h10v15a5 5 0 0 1-5 5Z' className='avatar23_svg__cls-4' />
		<circle cx={50} cy={62.51} r={3.15} className='avatar23_svg__cls-7' />
		<path d='M56 45h16' className='avatar23_svg__cls-10' />
		<ellipse cx={63.91} cy={24.95} className='avatar23_svg__cls-3' rx={18.28} ry={16.82} />
		<path
			d='M80.83 18.58c-.41 10.66-9.9 19.19-21.58 19.19A23.05 23.05 0 0 1 50 35.83a18.9 18.9 0 0 0 13.93 5.94C74 41.77 82.19 34.24 82.19 25a15.72 15.72 0 0 0-1.36-6.42Z'
			style={{
				opacity: 0.1,
				fill: '#356cb6',
			}}
		/>
		<circle cx={78} cy={62.51} r={3.15} className='avatar23_svg__cls-7' />
		<path
			d='M87 53.77c-.4 1.69-10.86.65-12.55.25a3.15 3.15 0 1 1 1.46-6.12c1.67.4 11.47 4.18 11.09 5.87ZM41 53.77c.4 1.69 10.86.65 12.55.25a3.15 3.15 0 0 0-1.46-6.12c-1.67.4-11.47 4.18-11.09 5.87Z'
			className='avatar23_svg__cls-3'
		/>
		<path d='M75 69h6M47 69h6' className='avatar23_svg__cls-10' />
		<path
			d='m104 108.82-2.39-5.68c-1.71-4-6.22-6.64-11.29-6.64H77l-.4 26.26a59.82 59.82 0 0 0 27.4-13.94ZM23.59 108.82l2.41-5.68c1.71-4 6.23-6.64 11.29-6.64h13.3l.4 26.26a59.82 59.82 0 0 1-27.4-13.94Z'
			className='avatar23_svg__cls-7'
		/>
	</svg>
);
export default SvgAvatar23;
