import * as React from 'react';
import { SVGProps } from 'react';
const SvgSportIconShooting = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		viewBox='0 0 512 512'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			d='M53.335 10.669H458.66v490.664H53.335z'
			style={{
				fill: '#f3ddb9',
			}}
		/>
		<path
			d='M255.999 394.667c76.375 0 138.666-62.291 138.666-138.666 0-76.373-62.291-138.664-138.666-138.664s-138.666 62.291-138.666 138.664c0 76.375 62.291 138.666 138.666 138.666z'
			style={{
				fill: '#2c5a7e',
			}}
		/>
		<path
			d='M256 298.665c23.5 0 42.665-19.164 42.665-42.664 0-23.498-19.165-42.667-42.665-42.667-23.499 0-42.666 19.168-42.666 42.667 0 23.499 19.167 42.664 42.666 42.664z'
			style={{
				fill: '#bf2345',
			}}
		/>
		<path
			d='M458.665 0H53.335c-5.892 0-10.669 4.776-10.669 10.669v490.663c0 5.892 4.776 10.669 10.669 10.669h405.329c5.892 0 10.669-4.776 10.669-10.669V10.669C469.333 4.776 464.557 0 458.665 0zm-10.669 490.663H64.004V21.337h383.992v469.326z'
			style={{
				fill: '#000003',
			}}
		/>
		<path
			d='M196.627 266.669h7.113c4.254 20.865 20.726 37.337 41.59 41.59v7.113c0 5.892 4.777 10.669 10.669 10.669s10.669-4.776 10.669-10.669v-7.112c20.867-4.253 37.34-20.726 41.593-41.591h7.111c5.891 0 10.669-4.776 10.669-10.669 0-5.892-4.777-10.669-10.669-10.669h-7.111c-4.253-20.867-20.727-37.34-41.593-41.593v-7.111c0-5.892-4.776-10.669-10.669-10.669-5.891 0-10.669 4.776-10.669 10.669v7.111c-20.865 4.254-37.337 20.728-41.59 41.593h-7.113c-5.892 0-10.669 4.776-10.669 10.669 0 5.893 4.776 10.669 10.669 10.669zm29.212 0h19.492v19.491a32.148 32.148 0 0 1-19.492-19.491zm40.829 19.493V266.67h19.494a32.156 32.156 0 0 1-19.494 19.492zm19.494-40.83h-19.495v-19.494a32.148 32.148 0 0 1 19.495 19.494zm-40.832-19.493v19.493h-19.492a32.154 32.154 0 0 1 19.492-19.493z'
			style={{
				fill: '#000003',
			}}
		/>
		<path
			d='M96.002 266.669h11.047c5.239 73.858 64.423 133.042 138.28 138.28v11.05c0 5.892 4.777 10.669 10.669 10.669s10.669-4.776 10.669-10.669v-11.05c73.858-5.239 133.042-64.423 138.281-138.28h11.048c5.892 0 10.669-4.776 10.669-10.669 0-5.892-4.776-10.669-10.669-10.669h-11.048c-5.239-73.857-64.424-133.04-138.281-138.278V96.002c0-5.892-4.776-10.669-10.669-10.669-5.891 0-10.669 4.776-10.669 10.669v11.052c-73.858 5.238-133.041 64.422-138.28 138.278H96.002c-5.892 0-10.669 4.776-10.669 10.669 0 5.892 4.775 10.668 10.669 10.668zm32.448 0h21.415c5.02 50.317 45.149 90.447 95.463 95.467v21.413c-62.083-5.148-111.73-54.794-116.878-116.88zm255.097-21.337h-21.412c-5.02-50.317-45.15-90.448-95.468-95.468v-21.411c62.087 5.149 111.733 54.795 116.88 116.879zM245.33 128.453v21.417a106.885 106.885 0 0 0-16.937 3.095c-5.691 1.525-9.069 7.374-7.545 13.066 1.526 5.692 7.374 9.071 13.065 7.546a85.409 85.409 0 0 1 22.085-2.907c47.053 0 85.33 38.279 85.33 85.33 0 5.892 4.777 10.669 10.669 10.669.505 0 .995-.047 1.48-.114.484.068.975.114 1.48.114h28.591c-5.148 62.085-54.793 111.732-116.881 116.881v-21.42a106.868 106.868 0 0 0 16.939-3.095c5.693-1.523 9.071-7.373 7.547-13.065-1.525-5.692-7.374-9.072-13.065-7.546a85.43 85.43 0 0 1-22.09 2.907c-47.05 0-85.327-38.279-85.327-85.33 0-5.892-4.777-10.669-10.669-10.669-.504 0-.995.047-1.479.114a10.617 10.617 0 0 0-1.48-.114H128.45c5.148-62.084 54.795-111.73 116.88-116.879z'
			style={{
				fill: '#000003',
			}}
		/>
		<path
			d='M203.794 186.099c2.045 0 4.113-.581 5.935-1.794l.26-.172c4.895-3.28 6.16-9.877 2.881-14.773-3.278-4.895-9.951-6.175-14.845-2.895l-.194.128c-4.908 3.262-6.197 9.857-2.934 14.763 2.05 3.082 5.441 4.743 8.897 4.743zM302.413 327.603l-.373.244c-4.907 3.263-6.2 9.86-2.937 14.765 2.051 3.083 5.443 4.744 8.898 4.744 2.044 0 4.11-.581 5.934-1.793.006-.005.102-.067.111-.071 4.947-3.191 6.331-9.758 3.146-14.709-3.186-4.952-9.824-6.357-14.779-3.18z'
			style={{
				fill: '#000003',
			}}
		/>
	</svg>
);
export default SvgSportIconShooting;
