import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvatar39 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		id='avatar39_svg__Layer_1'
		width='1em'
		height='1em'
		data-name='Layer 1'
		viewBox='0 0 128 128'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
				{
					'.avatar39_svg__cls-2,.avatar39_svg__cls-5{fill:#fff}.avatar39_svg__cls-2{opacity:.3}.avatar39_svg__cls-3{fill:#356cb6}.avatar39_svg__cls-4{fill:#8f5653}.avatar39_svg__cls-10,.avatar39_svg__cls-17{fill:none;stroke-linecap:round;stroke-linejoin:round}.avatar39_svg__cls-9{fill:#393c54}.avatar39_svg__cls-10,.avatar39_svg__cls-17{stroke:#515570}.avatar39_svg__cls-10{stroke-width:2px;opacity:.4}.avatar39_svg__cls-12{fill:#db7a72}.avatar39_svg__cls-13{fill:#bc5b57}.avatar39_svg__cls-14{fill:#ff6d00}.avatar39_svg__cls-16{fill:#a7aece}.avatar39_svg__cls-17{stroke-width:2.22px}'
				}
			</style>
		</defs>
		<circle
			cx={64}
			cy={64}
			r={60}
			style={{
				fill: '#4bc190',
			}}
		/>
		<circle cx={64} cy={64} r={48} className='avatar39_svg__cls-2' />
		<path d='M120.57 84H67v16h45a60 60 0 0 0 8.57-16Z' className='avatar39_svg__cls-3' />
		<path d='M50 95h29v15H50z' className='avatar39_svg__cls-4' />
		<path
			d='M64 124.1a59.73 59.73 0 0 0 39.8-15.1l-2.2-5.36c-1.71-4-6.22-6.64-11.29-6.64H37.69c-5.07 0-9.58 2.66-11.29 6.64L24.2 109A59.73 59.73 0 0 0 64 124.1Z'
			className='avatar39_svg__cls-5'
		/>
		<path
			d='M64 95.75v2.75'
			style={{
				stroke: '#356cb6',
				strokeWidth: 24,
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
		<path
			d='M64 77.75V90.5'
			style={{
				stroke: '#fbc0aa',
				strokeWidth: 20,
				fill: 'none',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
		<path
			d='M64 94.37a28.31 28.31 0 0 1-28.32-28.32V47.43a28.32 28.32 0 1 1 56.64 0v18.62A28.31 28.31 0 0 1 64 94.37Z'
			style={{
				fill: '#ffd8c9',
			}}
		/>
		<circle cx={76.67} cy={59.28} r={3} className='avatar39_svg__cls-9' />
		<circle cx={49.67} cy={59.28} r={3} className='avatar39_svg__cls-9' />
		<path d='m74.39 53 9.61-.25M53 53l-9.61-.25M60 86h8' className='avatar39_svg__cls-10' />
		<path
			d='M69.5 82h-11a3.5 3.5 0 0 1 0-7h11a3.5 3.5 0 0 1 0 7Z'
			className='avatar39_svg__cls-9'
		/>
		<path d='M58 75h12a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2Z' className='avatar39_svg__cls-5' />
		<path
			d='M60 82h9.5a3.5 3.5 0 0 0 3.5-3.5 4 4 0 0 0 0-.5h-7a6.5 6.5 0 0 0-6 4Z'
			style={{
				fill: '#f85565',
			}}
		/>
		<path
			d='m103.8 109-2.2-5.36c-1.71-4-6.22-6.64-11.29-6.64H78v25.2c9.34-2.41 18.8-6.99 25.8-13.2ZM26.4 103.64 24.2 109A59.86 59.86 0 0 0 50 122.46V97H37.69c-5.07 0-9.58 2.66-11.29 6.64Z'
			className='avatar39_svg__cls-12'
		/>
		<path d='M78 95h5a3 3 0 0 1 3 3v9a8 8 0 0 1-8 8V95Z' className='avatar39_svg__cls-13' />
		<path
			d='M64 70h17a5 5 0 0 1-5 5h-7a5 5 0 0 1-5-5ZM47 70h17a5 5 0 0 1-5 5h-7a5 5 0 0 1-5-5Z'
			className='avatar39_svg__cls-14'
		/>
		<path
			d='m93 74 3.79-1.89A4 4 0 0 0 99 68.53V52.88a4 4 0 0 0-2.74-3.79L93 48Z'
			className='avatar39_svg__cls-12'
		/>
		<path
			d='M64 72a3 3 0 0 1-3-3V50h6v19a3 3 0 0 1-3 3Z'
			style={{
				fill: '#fbc0aa',
			}}
		/>
		<path
			d='m35 74-3.79-1.89A4 4 0 0 1 29 68.53V52.88a4 4 0 0 1 2.74-3.79L35 48Z'
			className='avatar39_svg__cls-12'
		/>
		<path d='M63.78 52.56a7.78 7.78 0 0 1 0-15.56' className='avatar39_svg__cls-14' />
		<path d='M70.44 57a7.78 7.78 0 0 1 0-15.56' className='avatar39_svg__cls-14' />
		<path
			d='M50 115a8 8 0 0 1-8-8v-9a3 3 0 0 1 3-3h5v20ZM63.08 15.13c-16.49.49-29.4 14.45-29.4 30.95V87.5c.13 2.76 4.29 2.62 4.29-.15V55A10 10 0 0 1 48 45h32a10 10 0 0 1 10 10v32.32c0 2.77 4.16 2.91 4.29.15V45.43a30.35 30.35 0 0 0-31.21-30.3Z'
			className='avatar39_svg__cls-13'
		/>
		<path d='M87 35h7a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-7v-6Z' className='avatar39_svg__cls-4' />
		<path d='M87 34h3a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-3v-8Z' className='avatar39_svg__cls-5' />
		<path d='M34 35h7v6h-7a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2Z' className='avatar39_svg__cls-4' />
		<path d='M41 42h-3a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h3v8Z' className='avatar39_svg__cls-5' />
		<path d='M58 35h10v6H58z' className='avatar39_svg__cls-4' />
		<circle cx={77} cy={39.28} r={11} className='avatar39_svg__cls-3' />
		<circle cx={77} cy={39.28} r={6.88} className='avatar39_svg__cls-16' />
		<path
			d='M77 32.4a5.49 5.49 0 0 0-.58 0l-6.27 6.3v.58a6.9 6.9 0 0 0 1 3.6l9.46-9.45A6.85 6.85 0 0 0 77 32.4ZM73.84 45.38l9.26-9.26A6.82 6.82 0 0 0 82 34.6l-9.71 9.71a7.31 7.31 0 0 0 1.55 1.07Z'
			className='avatar39_svg__cls-2'
		/>
		<circle cx={77} cy={39.28} r={9.98} className='avatar39_svg__cls-17' />
		<circle cx={51} cy={39.28} r={11} className='avatar39_svg__cls-3' />
		<circle cx={51} cy={39.28} r={9.98} className='avatar39_svg__cls-17' />
		<circle cx={51} cy={39.28} r={6.88} className='avatar39_svg__cls-16' />
		<path
			d='M51 32.4a5.49 5.49 0 0 0-.58 0l-6.27 6.3v.58a6.9 6.9 0 0 0 1 3.6l9.46-9.45A6.85 6.85 0 0 0 51 32.4ZM47.84 45.38l9.26-9.26A6.82 6.82 0 0 0 56 34.6l-9.71 9.71a7.31 7.31 0 0 0 1.55 1.07Z'
			className='avatar39_svg__cls-2'
		/>
	</svg>
);
export default SvgAvatar39;
